import { NebPropsWithStd } from '@villageco/nebula/core';
import { NavbarLink } from '@villageco/nebula/internal';
import { FC } from 'react';
import { AiOutlineLink } from 'react-icons/ai';
import { Link, useMatch } from 'react-router-dom';

export type BiteyNavLinkProps = {};

export const BiteyNavLink: FC<NebPropsWithStd<BiteyNavLinkProps>> = ({ className }) => {
  const isActive = useMatch('/bitey/*');

  return (
    <NavbarLink
      isActive={!!isActive}
      linkComponent={Link}
      className={className}
      icon={AiOutlineLink}
      to="/bitey"
      styles={{
        bgColor: isActive ? 'bg-th-bitey-500' : 'bg-th-bitey-200',
        textColor: isActive ? 'text-th-bitey-200' : 'text-th-bitey-700',
      }}
    >
      Bitey
    </NavbarLink>
  );
};
