import { THH3 } from '@components/ui/th-h3';
import {
  ProductVariant,
  ProductVariantCreate,
  ProductVariantUpdate,
} from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import {
  useDeleteProductVariantById,
  usePatchProductVariantById,
  usePatchRestoreProductVariantById,
  usePostProductVariant,
} from '@core/clients/ecommerce-services-admin/product-variants/product-variants';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { pick } from 'lodash';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { Form, FormOutput, notFoundAuthHandler, ResetButton, SubmitButton, Text } from '@villageco/nebula/forms';
import { FC, MouseEvent } from 'react';
import { FaTrashAlt, FaTrashRestoreAlt } from 'react-icons/fa';
import { ecommerceFormConfig } from '../../libs/form-config';
import { ItemDetailsEditFields } from './item-details-edit-fields';

export type ProductVariantEditFormProps = {
  productId: string;
  variant?: ProductVariant;
  invalidateQueries: QueryKey[];
  onRemove?: () => void;
  onRestore?: (id: string) => void;
  onCreate?: (variant: ProductVariant) => Promise<void>;
  onUpdate?: (variant: ProductVariant) => Promise<void>;
};

export const ProductVariantEditForm: FC<NebPropsWithStd<ProductVariantEditFormProps>> = ({
  variant,
  productId,
  className,
  invalidateQueries,
  onRemove,
  onRestore,
  onCreate,
  onUpdate,
}) => {
  const queryClient = useQueryClient();

  const mutation = {
    onSuccess: () => {
      invalidateQueries.forEach((q) => queryClient.invalidateQueries(q));
    },
  };

  const { mutateAsync: deleteVariantMut, isLoading: deleteLoading } = useDeleteProductVariantById({
    mutation,
  });

  const { mutateAsync: restoreVariantMut, isLoading: restoreLoading } = usePatchRestoreProductVariantById({
    mutation,
  });

  const { mutateAsync: updateVariantMut } = usePatchProductVariantById({
    mutation,
  });

  const { mutateAsync: createVariantMut } = usePostProductVariant({
    mutation,
  });

  const deleteClicked = async (evt: MouseEvent) => {
    evt.preventDefault();

    if (variant?.id) {
      await deleteVariantMut({ id: variant.id, data: {} });
    }

    onRemove && onRemove();
  };

  const restoreClicked = async (evt: MouseEvent) => {
    evt.preventDefault();

    const id = variant?.id as string;

    await restoreVariantMut({ id, data: {} });

    onRestore && onRestore(id);
  };

  const formSubmitted = async (rawData: ProductVariant) => {
    if (variant?.id) {
      const data = {
        ...rawData,
        selectedOptions: {},
        item: { update: { ...(rawData.item ?? {}) } },
      } as ProductVariantUpdate;

      const res = await updateVariantMut({ id: variant.id, data: { data } });
      onUpdate && (await onUpdate(res));
    } else {
      const data = {
        ...rawData,
        selectedOptions: {},
        item: { create: { ...(rawData.item ?? {}) } },
        product: { connect: { id: productId } },
      } as ProductVariantCreate;

      const res = await createVariantMut({ data: { data } });
      onCreate && (await onCreate(res));
    }
  };

  const defaultData = pick(variant ?? {}, ['name']);

  return (
    <Form
      defaultData={defaultData}
      config={{ ...ecommerceFormConfig, formErrorHandler: notFoundAuthHandler }}
      onSubmit={formSubmitted}
      className={classNames('product-variant-edit-form', className)}
    >
      {variant?.deletedAt ? (
        <div className="h-32 flex flex-col justify-center items-center space-y-4">
          <THH3 className="w-full text-center">Restore this variant to edit</THH3>
          <button
            onClick={restoreClicked}
            className={classNames('bg-teal-600 text-white p-2 items-center justify-evenly w-28 rounded flex', {
              'bg-teal-300 animate-pulse': restoreLoading,
            })}
          >
            <FaTrashRestoreAlt />
            Restore
          </button>
        </div>
      ) : (
        <>
          <div className="flex">
            <p className="font-medium text-slate-700">Variant Details</p>

            <button
              onClick={deleteClicked}
              className={classNames('bg-red-600 text-white p-2 items-center justify-evenly w-28 rounded ml-auto flex', {
                'bg-red-300 animate-pulse': deleteLoading,
              })}
            >
              <FaTrashAlt />
              Remove
            </button>
          </div>
          <Text<typeof defaultData> className="w-full" name={`name`} label="Variant name"></Text>

          <p className="font-medium text-slate-700 mt-4">Purchasing Details</p>

          <ItemDetailsEditFields fieldNamePrefix={`item.`} item={variant?.item}></ItemDetailsEditFields>

          <div className="flex justify-end mt-4 space-x-4">
            <FormOutput className="mr-auto"></FormOutput>
            <SubmitButton></SubmitButton>
            <ResetButton></ResetButton>
          </div>
        </>
      )}
    </Form>
  );
};
