import { get, isEqual, set } from 'lodash-es';
import { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  FieldClassConfig,
  FormConfig,
  FormContextProps,
  FormEvent,
  FormEventHandler,
  FormState,
  ValidationErrors,
} from './types';

export const formContext = createContext<FormState<any>>(undefined!);

export const FormContextProvider = <T,>({
  children,
  defaultData,
  config: defaultConfig,
  onChange,
  usesHtml,
}: PropsWithChildren<FormContextProps<T>>) => {
  const [data, setData] = useState<T>(defaultData ?? ({} as T));
  const [validationErrors, setValidationErrors] = useState<ValidationErrors<T> | undefined>({});
  const [config, setConfig] = useState<FormConfig<T>>(defaultConfig ?? {});
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState<string | undefined>();
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [lastEvent, setLastEvent] = useState<FormEvent | undefined>();

  // useEffect(() => {
  //   setData(defaultData ?? ({} as T));
  // }, [defaultData]);

  useEffect(() => {
    console.log('Loading');
    console.log(loading);
  }, [loading]);

  useEffect(() => {
    onChange && onChange(data);
  }, [data]);

  const _initialData = defaultData ?? ({} as T);

  const setFieldValue = <K extends keyof T>(fieldPath: K, value: T[K]) => {
    const newData = structuredClone(data);
    set(newData as any, fieldPath, value);
    setData(newData);
  };

  const getFieldErrors = <K extends keyof T>(fieldPath: K): string[] | undefined =>
    validationErrors && get(validationErrors, fieldPath);

  const getFieldValue = <K extends keyof T>(fieldPath: K): T[K] => get(data, fieldPath);

  const hasFieldChanged = useCallback(
    <K extends keyof T>(fieldPath: K) => !isEqual(get(data, fieldPath), get(_initialData, fieldPath)),
    [data, _initialData],
  );

  const hasChanges = useMemo(() => !isEqual(data, _initialData), [data, _initialData]);

  useEffect(() => {
    setHasChanged(hasChanges);
  }, [hasChanges]);

  const eventBus = useRef(new Map<FormEvent, Set<FormEventHandler>>());

  const registerFormEventListener = (event: FormEvent, handler: FormEventHandler) => {
    let listeners = eventBus.current.get(event);

    if (!listeners) {
      listeners = new Set();
      eventBus.current.set(event, listeners);
    }

    listeners.add(handler);

    return () => {
      listeners!.delete(handler);
    };
  };

  const dispatchFormEvent = (event: FormEvent, eventData: any) => {
    console.log(data);

    const listeners = eventBus.current.get(event);

    if (!listeners?.size) return;

    listeners.forEach((l) => l(eventData));
    setLastEvent(event);
  };

  const value: FormState<T> = {
    data,
    _initialData,
    validationErrors,
    config,
    loading,
    lastEvent,
    formError,
    hasChanged,
    usesHtml: usesHtml ?? false,

    setFieldValue,
    setValidationErrors,
    setConfig,
    setLoading,
    setLastEvent,
    setFormError,
    setData,
    setHasChanged,
    hasFieldChanged,
    getFieldErrors,
    getFieldValue,

    dispatchFormEvent,
    registerFormEventListener,
  };

  return <formContext.Provider value={value}>{children}</formContext.Provider>;
};
