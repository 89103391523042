import { SelectBox } from '@components/ui/select-box';
import { THH3 } from '@components/ui/th-h3';
import { Account, Membership, Memberships } from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import { QueryKey } from '@tanstack/react-query';
import classNames from 'classnames';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { FC, MouseEvent, useState } from 'react';
import { BsStar } from 'react-icons/bs';
import { FaSync } from 'react-icons/fa';
import { FiEdit, FiExternalLink } from 'react-icons/fi';
import { IoPeopleCircleOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import crmLogoUrl from '../../../../assets/zoho-crm-icon.png';
import invLogoUrl from '../../../../assets/zoho-inventory-icon.png';
import { MembershipRoutePaths } from '../../router/membership-routes';
import { AccountContactManagerPanel } from './account-contact-manager-panel';
import { AccountEditForm } from './account-edit-form';

export type AccountCardProps = {
  account: Account & { memberships?: Memberships };
  accountsQueryKey: QueryKey;
  selected?: boolean;
  onSelectClicked: (evt: MouseEvent) => void;
  onCardClicked?: () => void;
};

export const AccountCard: FC<NebPropsWithStd<AccountCardProps>> = ({
  account,
  selected,
  className,
  accountsQueryKey,
  onSelectClicked,
}) => {
  const [editOpen, setEditOpen] = useState(false);
  const [contactsOpen, setContactsOpen] = useState(false);

  const editClicked = (evt: MouseEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    setContactsOpen(false);
    setEditOpen(!editOpen);
  };

  const contactsClicked = (evt: MouseEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    setEditOpen(false);
    setContactsOpen(!contactsOpen);
  };

  const syncClicked = (evt: MouseEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  const needsAttention = !account.zohoCrmAccountRef || !account.zohoInvContactRef || !account.stripeCustomerRef;

  return (
    <div
      className={classNames(className, 'account-card bg-gray-200 rounded p-2', {
        'bg-th-membership-300': needsAttention,
        'bg-gray-200': !needsAttention,
      })}
    >
      <div className="flex items-center" onClick={onSelectClicked}>
        <div className="w-20 h-20 flex items-center">
          <SelectBox selected={selected}></SelectBox>
        </div>

        <div className="">
          <div className="flex items-center mb-2">
            <p className="font-semibold text-lg">{account.name}</p>
            <p
              className={classNames(
                { 'bg-green-300 text-green-700': account.status === 'ACTIVE' },
                'rounded p-1 lowercase font-medium ml-4 text-xs',
              )}
            >
              {account.status}
            </p>
            {needsAttention && (
              <p className="bg-red-300 text-red-700 rounded p-1 lowercase font-medium ml-4 text-xs">Needs Attention</p>
            )}
          </div>
          <div className="flex items-stretch mt-2">
            <div className="flex items-center">
              <button
                onClick={editClicked}
                className={classNames(
                  {
                    'bg-th-membership-500 text-th-membership-100': editOpen,
                    'bg-th-membership-100 hover:text-th-membership-700 hover:bg-th-membership-200 text-th-membership-600':
                      !editOpen,
                  },
                  'text-sm transition-colors duration-150 w-16 h-16 rounded flex flex-col items-center justify-center',
                )}
              >
                <FiEdit className="text-xl" />
                <span className="mt-2 -mb-1 font-semibold ">Edit</span>
              </button>
              <button
                onClick={contactsClicked}
                className={classNames(
                  {
                    'bg-th-membership-500 text-th-membership-100': contactsOpen,
                    'bg-th-membership-100 hover:text-th-membership-700 hover:bg-th-membership-200 text-th-membership-600':
                      !contactsOpen,
                  },
                  'text-sm transition-colors ml-4 duration-150 w-36 h-16 rounded flex flex-col items-center justify-center',
                )}
              >
                <IoPeopleCircleOutline className="text-2xl" />
                <span className="mt-2 -mb-1 font-semibold ">Manage Contacts</span>
              </button>
              <button
                onClick={syncClicked}
                className={classNames(
                  {
                    'bg-th-membership-500 text-th-membership-100': contactsOpen,
                    'bg-th-membership-100 hover:text-th-membership-700 hover:bg-th-membership-200 text-th-membership-600':
                      !contactsOpen,
                  },
                  'text-sm transition-colors ml-4 duration-150 w-36 h-16 rounded flex flex-col items-center justify-center',
                )}
              >
                <FaSync className="text-xl" />
                <span className="mt-2 -mb-1 font-semibold ">Sync</span>
              </button>
              {account?.memberships?.items?.length ? (
                <Link
                  to={{
                    pathname: `${MembershipRoutePaths.MEMBERSHIP_DETAILS}${account.memberships.items[0].id}`,
                  }}
                  className={classNames(
                    'text-sm bg-th-membership-100 text-th-membership-600 hover:text-th-membership-700 hover:bg-th-membership-200 transition-colors duration-150 w-36 h-16 rounded flex flex-col items-center justify-center ml-4',
                  )}
                >
                  <BsStar className="text-2xl "></BsStar>
                  <span className="mt-2 -mb-1 font-semibold ">Go to Membership</span>
                </Link>
              ) : (
                <Link
                  to={{ pathname: `${MembershipRoutePaths.MEMBERSHIP_DETAILS}new`, search: `accountId=${account.id}` }}
                  className={classNames(
                    'text-sm bg-th-membership-300 text-th-membership-700 hover:text-th-membership-800 hover:bg-th-membership-400 transition-colors duration-150 w-36 h-16 rounded flex flex-col items-center justify-center ml-4',
                  )}
                >
                  <BsStar className="text-2xl "></BsStar>
                  <span className="mt-2 -mb-1 font-semibold ">Add Membership</span>
                </Link>
              )}
            </div>
            <div className="ml-4">
              {account.zohoCrmAccountRef ? (
                <a
                  onClick={(e) => e.stopPropagation()}
                  href={`https://crm.zoho.com.au/crm/org7000730364/tab/Accounts/${account.zohoCrmAccountRef}`}
                  target="_blank"
                  className="flex bg-white text-gray-600 hover:bg-gray-100 hover:text-gray-700 transition-colors duration-150 h-6 text-sm px-1 items-center font-medium  rounded w-64"
                >
                  <img className="w-4 rounded mr-2" src={crmLogoUrl} />
                  Go to Account in CRM
                  <FiExternalLink className="ml-auto mr-2" />
                </a>
              ) : (
                <div className="flex bg-gray-100 h-6 text-sm px-1  items-center text-medium text-gray-600 rounded w-64">
                  <img className="opacity-30 w-4 rounded mr-2" src={crmLogoUrl} />
                  No Zoho CRM Account linked
                </div>
              )}

              {account.zohoInvContactRef ? (
                <a
                  onClick={(e) => e.stopPropagation()}
                  href={`https://inventory.zoho.com.au/app#/contacts/${account.zohoInvContactRef}`}
                  target="_blank"
                  className="flex bg-white text-gray-600 hover:bg-gray-100 hover:text-gray-700 transition-colors duration-150 h-6 text-sm px-1  items-center font-medium rounded mt-4 w-64"
                >
                  <img className="w-4 rounded mr-2" src={invLogoUrl} />
                  Go to Contact in Inventory
                  <FiExternalLink className="ml-auto mr-2" />
                </a>
              ) : (
                <div className="flex bg-gray-100 h-6 text-sm px-1  items-center text-medium text-gray-600 rounded mt-4 w-64">
                  <img className="opacity-30 w-4 rounded mr-2" src={invLogoUrl} />
                  No Zoho Inventory Contact linked
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          { 'h-0': !editOpen, 'h-100 p-4': editOpen },
          'transition-all duration-200 account-edit-form mt-4 bg-gray-100 -m-4 rounded overflow-hidden',
        )}
      >
        <THH3 className="text-left ml-2 mb-4">Edit</THH3>
        <AccountEditForm accountsQueryKey={accountsQueryKey} account={account}></AccountEditForm>
      </div>
      <div
        className={classNames(
          { 'h-0': !contactsOpen, 'p-4 max-h-96': contactsOpen },
          'transition-all duration-200 account-edit-form mt-4 bg-gray-100 -m-4 rounded overflow-hidden',
        )}
      >
        <THH3 className="text-left ml-2 mb-4">Manage Contacts</THH3>
        <div className="overflow-auto h-72">
          <AccountContactManagerPanel
            accountsQueryKey={accountsQueryKey}
            account={account}
            fetchData={contactsOpen}
          ></AccountContactManagerPanel>
        </div>
      </div>
    </div>
  );
};
