import { NebPropsWithStd } from '@villageco/nebula/core';
import { FC } from 'react';

export type LinkViewFormSkeletonProps = {};

export const LinkViewFormSkeleton: FC<NebPropsWithStd<LinkViewFormSkeletonProps>> = () => {
  return (
    <div className="link-view-form-skeleton bg-th-bitey-100 w-full p-4 rounded">
      <div className="animate-pulse flex justify-between">
        <div className="w-96 h-8 bg-th-bitey-200 rounded"></div>
        <div className="w-24 h-8 bg-th-bitey-200 rounded"></div>
      </div>
      <div className="animate-pulse flex justify-between mt-4">
        <div className="w-96 h-12 bg-th-bitey-200 rounded"></div>
        <div className="w-72 h-12 bg-th-bitey-200 rounded"></div>
        <div className="w-96 h-12 bg-th-bitey-200 rounded"></div>
      </div>
      <div className="animate-pulse flex justify-between mt-4">
        <div className="w-80 h-12 bg-th-bitey-200 rounded"></div>
      </div>
      <div className="animate-pulse flex justify-between mt-4 space-x-4">
        <div className="w-1/2 h-16 bg-th-bitey-200 rounded"></div>
        <div className="w-1/2 h-40 bg-th-bitey-200 rounded"></div>
      </div>
    </div>
  );
};
