import { RouteObject } from 'react-router';
import { RequireAuthGuard } from '../../../components/guards/require-auth-guard';

export enum FoyerRoutePaths {
  ROOT = '/',
}

export const foyerRoutes: RouteObject[] = [
  {
    path: '/*',
    element: (
      <RequireAuthGuard>
        <></>
      </RequireAuthGuard>
    ),
  },
];
