import { ThAddLink } from '@components/ui/th-add-link';
import { ThScrollPage } from '@components/ui/th-scroll-page';
import { useGetManyShipmentRuns } from '@core/clients/ecommerce-services-admin/shipment-runs/shipment-runs';
import { setPageMeta } from '@core/stores/core-store';
import { FunctionComponent } from 'react';
import { ShipmentRunCard } from '../../components/shipment-runs/shipment-run-card';
import { EcommerceRoutePaths } from '../../router/ecommerce-routes';
// import { useProducts } from '../../hooks/useProducts';

export type ShipmentRunsIndexPageProps = {};

export const ShipmentRunsIndexPage: FunctionComponent<ShipmentRunsIndexPageProps> = () => {
  setPageMeta('Shipment Runs', false);

  const { data, status } = useGetManyShipmentRuns({ include: { salesOrders: { select: true } } });

  return (
    <ThScrollPage id="shipment-runs-index-page">
      <div className="rounded w-full h-20 bg-th-ecommerce-50 p-2 ">
        <ThAddLink
          to={`${EcommerceRoutePaths.SHIPMENT_RUN_DETAILS}new`}
          className="bg-th-ecommerce-400 text-white"
        ></ThAddLink>
      </div>
      <div className="h-full w-full rounded bg-th-ecommerce-100 p-2 mt-4">
        {data?.items?.map((r) => (
          <ShipmentRunCard run={r as any}></ShipmentRunCard>
        ))}
      </div>
    </ThScrollPage>
  );
};
