import { THH3 } from '@components/ui/th-h3';
import { THP } from '@components/ui/th-p';
import { useGetManyAccounts } from '@core/clients/ecommerce-services-admin/accounts/accounts';
import {
  GetCalculateMembershipOrderPricingByIdItemsQueryParamParameterItem,
  GetCalculateMembershipOrderPricingByIdResponseBodySchema,
  MembershipOffering,
  MembershipOrder,
  MembershipOrderCreate,
  MembershipOrderItem,
  MembershipOrderItemCreate,
  MembershipOrderItemType,
  MembershipOrderMonth,
} from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import { useGetOneMembershipOffering } from '@core/clients/ecommerce-services-admin/membership-offerings/membership-offerings';
import {
  postMembershipOrderItem,
  useDeleteDestroyMembershipOrderItemById,
  usePatchMembershipOrderItemById,
  usePostMembershipOrderItem,
} from '@core/clients/ecommerce-services-admin/membership-order-items/membership-order-items';
import {
  useGetCalculateMembershipOrderPricingById,
  usePatchMembershipOrderById,
  usePostMembershipOrder,
} from '@core/clients/ecommerce-services-admin/membership-orders/membership-orders';
import { useGetManyProducts } from '@core/clients/ecommerce-services-admin/products/products';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { NebPropsWithStd } from '@villageco/nebula/core';
import {
  Form,
  FormEvent,
  FormOutput,
  List,
  ListOptionConfig,
  notFoundAuthHandler,
  Numeric,
  ResetButton,
  SubmitButton,
  useFormState,
} from '@villageco/nebula/forms';
import classNames from 'classnames';
import Decimal from 'decimal.js';
import { omit } from 'lodash-es';
import { FC, useCallback, useEffect, useMemo, useRef, useState, MouseEvent } from 'react';
import { FaCheck, FaPlus, FaTimes, FaTrashAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { sortMembershipsByUpdatedAtDesc } from '../../helpers/sort-memberships-by-updated-at';
import { membershipFormConfig } from '../../libs/form-config';
import { MembershipRoutePaths } from '../../router/membership-routes';
import { OrderData } from '../../types';
import { IncludedProductCard } from './included-product-card';

export type MembershipOrderEditFormProps = {
  membershipOrder?: MembershipOrder;
  membershipOrderQueryKey: QueryKey;
};

const currentDate = new Date();
const currentYear = currentDate?.getFullYear();

export const MembershipOrderEditForm: FC<NebPropsWithStd<MembershipOrderEditFormProps>> = ({
  membershipOrder,
  membershipOrderQueryKey,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [monthFilter, setMonthFilter] = useState<MembershipOrderMonth | undefined>();
  const [yearFilter, setYearFilter] = useState<string | undefined>();
  const [accountId, setAccountId] = useState<string | undefined>();

  const [selectedCategoryId, setSelectedCategoryId] = useState<string | undefined>(
    (membershipOrder?.orderData as any)?.selectedOfferCategoryId,
  );
  const [oldSelectedCategoryId, setOldSelectedCategoryId] = useState<string | undefined>();

  const [additionItems, setAdditionItems] = useState<MembershipOrderItem[]>(
    membershipOrder?.membershipOrderItems?.items?.filter((i) => i.type === 'ADDITION') ?? [],
  );

  const resetData = (
    membershipOrder: MembershipOrder,
    setSelectedCategoryId: (value: React.SetStateAction<string | undefined>) => void,
    setAdditionItems: (value: React.SetStateAction<MembershipOrderItem[]>) => void,
  ) => {
    setSelectedCategoryId((membershipOrder?.orderData as any)?.selectedOfferCategoryId);

    setAdditionItems((val) => {
      return [
        ...(membershipOrder?.membershipOrderItems?.items
          ?.filter((i) => i.deletedAt === null && i.type === 'ADDITION')
          ?.map((i) => {
            return {
              ...i,
            };
          }) ?? []),
      ];
    });
  };

  useEffect(() => {
    if (membershipOrder) {
      resetData(membershipOrder, setSelectedCategoryId, setAdditionItems);
    }
  }, [membershipOrder]);

  useEffect(() => {
    if (!oldSelectedCategoryId) {
      setOldSelectedCategoryId(selectedCategoryId);
    }
  }, [selectedCategoryId]);

  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  const { data: offering, isLoading: offerLoading } = useGetOneMembershipOffering(
    {
      include: {
        membershipOfferingItems: {
          include: {
            membershipOfferingCategory: true,
            item: {
              include: {
                product: { include: { files: true } },
                variant: { include: { product: { include: { files: true } } } },
              },
            },
          },
        },
      },
      where: {
        month: membershipOrder?.month,
        year: { equals: membershipOrder?.year },
      },
    },
    {
      query: {
        enabled: !!membershipOrder,
      },
    },
  );

  const offeringItemCategories = useMemo(
    () => [
      ...new Map(
        offering?.membershipOfferingItems?.items?.map((moi) => [
          moi.membershipOfferingCategoryId,
          moi.membershipOfferingCategory!,
        ]),
      ).values(),
    ],
    [offering],
  );

  const selectedCategoryItems = offering?.membershipOfferingItems?.items?.filter(
    (moi) => moi.membershipOfferingCategoryId === selectedCategoryId,
  );

  const { data: accounts, isLoading: accLoading } = useGetManyAccounts({
    select: {
      id: true,
      name: true,
    },
    include: {
      memberships: true,
    },
    where: {
      memberships: {
        some: {
          OR: [{ endAt: { equals: null } as any }, { endAt: { gt: currentDate } }],
          membershipOrders: { none: { month: monthFilter, year: yearFilter } },
        },
      },
    },
  });

  const { mutateAsync: createMembershipOrderMut } = usePostMembershipOrder({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(membershipOrderQueryKey);
      },
    },
  });
  const { mutateAsync: updateMembershipOrderMut } = usePatchMembershipOrderById({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(membershipOrderQueryKey);
      },
    },
  });

  const { mutateAsync: createMembershipOrderItemMut } = usePostMembershipOrderItem();
  const { mutateAsync: updateMembershipOrderItemMut } = usePatchMembershipOrderItemById();
  const { mutateAsync: destroyMembershipOrderItemMut } = useDeleteDestroyMembershipOrderItemById();

  const { data: products, isLoading: productsLoading } = useGetManyProducts(
    {
      include: {
        item: true,
      },
      where: {
        type: 'PURCHASE',
        status: 'ACTIVE',
      },
    },
    {
      query: {
        enabled: !!membershipOrder,
        keepPreviousData: true,
      },
    },
  );

  // useEffect(() => {
  //   const includeItems =
  //     offering?.membershipOfferingItems?.items
  //       ?.filter((moi) => moi.membershipOfferingCategoryId === selectedCategoryId)
  //       ?.map((moi) => {
  //         return {
  //           membershipOrderId: membershipOrder?.id,
  //           quantity: +(moi.quantity ?? 1),
  //           itemId: moi.itemId,
  //           type: MembershipOrderItemType.INCLUSION,
  //         };
  //       }) ?? [];

  //   setInclusionItems((val) => [...includeItems]);
  // }, [selectedCategoryId, offering]);

  const defaultData = membershipOrder ?? {};

  const yearOptions: ListOptionConfig[] = [
    { label: currentYear?.toString(), value: currentYear?.toString() },
    { label: (currentYear + 1)?.toString(), value: (currentYear + 1)?.toString() },
    { label: (currentYear + 2)?.toString(), value: (currentYear + 2)?.toString() },
    { label: (currentYear + 3)?.toString(), value: (currentYear + 3)?.toString() },
  ];

  const monthOptions: ListOptionConfig[] = Object.keys(MembershipOrderMonth)?.map((m) => {
    return { label: m, value: m };
  });

  const accountOptions = accounts?.items?.map<ListOptionConfig>((a) => ({ label: a.name!, value: a.id! })) ?? [];

  const offeringOptions =
    offeringItemCategories?.map<ListOptionConfig>((oic) => ({
      label: oic.name ?? 'Unknown Category',
      value: oic.id!,
    })) ?? [];

  const productOptions =
    products?.items
      ?.filter((p) => !additionItems?.some((moi) => moi.itemId === p.itemId))
      ?.map<ListOptionConfig>((p) => ({
        label: p.name ?? 'Unknown Product',
        value: p.itemId!,
      })) ?? [];

  const handleAddClick = () => {
    const items = [...additionItems];

    const newItem: MembershipOrderItem = {
      membershipOrderId: membershipOrder?.id,
      itemId: '',
      type: 'ADDITION',
    };

    items.push(newItem);

    setAdditionItems((val) => {
      return [...items];
    });
  };

  const handleDeleteClick = (index: number) => {
    setAdditionItems((val) => {
      const items = [...val];

      items.splice(index, 1);
      return Object.assign([], [...items]);
    });
    setDeleteIndex(null);
  };

  const handleItemSelect = (index: number, itemId: string | null | undefined) => {
    const item = products?.items?.find((p) => p.itemId === itemId)?.item;

    if (item) {
      setAdditionItems((val) => {
        const memOrderItems = [...val];
        memOrderItems[index].itemId = itemId;
        memOrderItems[index].item = item;
        memOrderItems[index].quantity = memOrderItems[index].quantity ?? 1;
        return [...memOrderItems];
      });
    }
  };

  const handleQuantityChange = (index: number, quantity: number) => {
    setAdditionItems((val) => {
      const memOrderItems = [...val];

      memOrderItems[index].quantity = quantity ?? 0;
      return [...memOrderItems];
    });
  };

  const handleCancelDelete = () => {
    setDeleteIndex(null);
  };

  const updateOrder = async (additionalItems: MembershipOrderItem[], currentOffering: MembershipOffering) => {
    console.log('Inside Update Order');
    console.log(additionItems);

    if (!membershipOrder) {
      return;
    }

    const deletedItems =
      membershipOrder?.membershipOrderItems?.items?.filter(
        (moi) =>
          moi.deletedAt === null &&
          !additionalItems?.some((i) => i.id === moi.id && i.quantity && i.quantity > 0) &&
          moi.type === 'ADDITION',
      ) ?? [];

    for (let orderItem of deletedItems) {
      if (orderItem?.id) {
        await destroyMembershipOrderItemMut({ id: orderItem?.id, data: {} });
      }
    }

    for (let orderItem of additionalItems) {
      if (orderItem?.id) {
        if (orderItem?.quantity) {
          console.log('Update Item');
          console.log(orderItem);
          await updateMembershipOrderItemMut({
            id: orderItem.id,
            data: {
              data: {
                item: { connect: { id: orderItem.itemId } },
                quantity: +orderItem?.quantity?.toString() ?? 1,
              },
            },
          });
        }
      } else {
        if (orderItem?.itemId && orderItem.quantity) {
          console.log('Create Item');
          console.log(orderItem);
          await createMembershipOrderItemMut({
            data: {
              data: {
                item: { connect: { id: orderItem.itemId } },
                membershipOrder: { connect: { id: orderItem.membershipOrderId } },
                quantity: +orderItem?.quantity?.toString() ?? 1,
                type: 'ADDITION',
              },
            },
          });
        }
      }
    }

    const oldCatId = (membershipOrder?.orderData as any).selectedOfferCategoryId;

    if (selectedCategoryId !== oldCatId) {
      const includedItems = membershipOrder?.membershipOrderItems?.items?.filter(
        (i) => i.deletedAt === null && i.type === 'INCLUSION',
      );

      if (includedItems) {
        for (let item of includedItems) {
          if (item.id) {
            await destroyMembershipOrderItemMut({ id: item?.id, data: {} });
          }
        }
      }

      const selectedOfferingItems = currentOffering?.membershipOfferingItems?.items?.filter(
        (moi) => moi.membershipOfferingCategoryId === selectedCategoryId,
      );
      if (selectedOfferingItems?.length) {
        const selectedOfferOrderItems: MembershipOrderItemCreate[] = selectedOfferingItems?.map((selItem) => {
          const quantity = selItem?.quantity ?? 1;
          return {
            membershipOrder: { connect: { id: membershipOrder.id } },
            item: { connect: { id: selItem.itemId } },
            quantity: +quantity,
            type: MembershipOrderItemType.INCLUSION,
          };
        });

        for (let item of selectedOfferOrderItems) {
          await postMembershipOrderItem({ data: item });
        }
      }
    }
  };

  const formSubmitted = async (
    rawData: MembershipOrderCreate,
    setLoading: (val: boolean) => void,
    dispatchFormEvent: (event: FormEvent, eventData: any) => void,
    event: MouseEvent,
    additionalItems: MembershipOrderItem[],
    currentOffering?: MembershipOffering,
  ) => {
    setLoading(true);
    try {
      const memberships =
        accounts?.items?.find((a) => a.id === accountId)?.memberships?.items?.sort(sortMembershipsByUpdatedAtDesc) ??
        [];

      const membership = memberships?.length ? memberships[0] : null;
      const membershipId = membershipOrder?.membershipId ?? membership?.id ?? null;

      if (membershipId) {
        const data = {
          ...omit(rawData, [
            'accountId',
            'membershipId',
            'salesOrderId',
            'deletedAt',
            'selectedOfferingId',
            'membershipOrderItems',
            'item-select',
            'item-quantity',
          ]),
          membership: {
            connect: { id: membershipId },
          },
          year: yearFilter!,
          month: monthFilter!,
        };

        console.log('formSubmitted');
        if (membershipOrder?.id) {
          console.log('Has membership order');
          console.log(selectedCategoryId);
          console.log(currentOffering);
          if (currentOffering && selectedCategoryId) {
            console.log('Update Order');
            await updateOrder(additionalItems, currentOffering);
          }

          let orderData = membershipOrder.orderData as OrderData;
          if (selectedCategoryId) {
            orderData.selectedOfferCategoryId = selectedCategoryId;
          }

          await updateMembershipOrderMut({ id: membershipOrder.id, data: { data: { ...data, orderData: orderData } } });
        } else {
          const res = await createMembershipOrderMut({ data: { data } });
          navigate(`${MembershipRoutePaths.MEMBERSHIP_ORDER_DETAILS}${res.id}`);
        }
      }
    } finally {
      dispatchFormEvent(FormEvent.SUBMIT_SUCCESS, event);
    }

    setLoading(false);
  };

  const selectedOfferingItems =
    offering?.membershipOfferingItems?.items?.filter(
      (moi) => moi.membershipOfferingCategoryId === selectedCategoryId,
    ) ?? [];

  const selectedOfferCostItems: GetCalculateMembershipOrderPricingByIdItemsQueryParamParameterItem[] =
    selectedOfferingItems?.map((selItem) => {
      const quantity = selItem?.quantity ?? 1;
      return {
        id: selItem.itemId!,
        type: MembershipOrderItemType.INCLUSION,
        quantity: +quantity,
        // membershipOrderId: membershipOrder.id,
        // item: { id: selItem.itemId },
        // quantity: +quantity,
        // type:,
      };
    }) ?? [];

  const additionCostItems: GetCalculateMembershipOrderPricingByIdItemsQueryParamParameterItem[] =
    additionItems
      ?.filter((i) => i.quantity && i.itemId)
      ?.map((item) => {
        const quantity = item?.quantity ?? 1;
        return {
          id: item.itemId!,
          type: MembershipOrderItemType.ADDITION,
          quantity: +quantity,
          // membershipOrderId: membershipOrder.id,
          // item: { id: selItem.itemId },
          // quantity: +quantity,
          // type: ,
        };
      }) ?? [];

  const itemList = [...selectedOfferCostItems, ...additionCostItems];

  const {
    data: costResponse,
    queryKey: costQueryKey,
    isLoading: costIsLoading,
  } = useGetCalculateMembershipOrderPricingById(
    membershipOrder?.id!,
    {
      items: itemList.filter((i) => i),
    },
    { query: { keepPreviousData: true, enabled: !!membershipOrder?.id } },
  );

  const costData: GetCalculateMembershipOrderPricingByIdResponseBodySchema | null = useMemo(() => {
    if (costResponse) {
      return {
        additionsTotal: {
          amountEx: +costResponse?.additionsTotal?.amountEx,
          amountGst: +costResponse?.additionsTotal?.amountGst,
        },
        discount: {
          labels: costResponse?.discount?.labels,
          amountEx: +costResponse?.discount?.amountEx,
          amountGst: +costResponse?.discount?.amountGst,
        },
        shipping: {
          amountEx: +costResponse?.shipping?.amountEx,
          amountGst: +costResponse?.shipping?.amountGst,
        },
        subscription: {
          amountEx: +costResponse?.subscription?.amountEx,
          amountGst: +costResponse?.subscription?.amountGst,
        },
        total: { amountEx: +costResponse?.total?.amountEx, amountGst: +costResponse?.total?.amountGst },
      };
    }
    return null;
  }, [costResponse]);

  const subTotal = useMemo(() => {
    return new Decimal(costData?.subscription.amountEx ?? 0)
      .add(new Decimal(costData?.additionsTotal.amountEx ?? 0))
      .add(new Decimal(costData?.shipping.amountEx ?? 0))
      .sub(new Decimal(costData?.discount.amountEx ?? 0));
  }, [costData]);

  const handleResetClicked = () => {
    if (membershipOrder) {
      resetData(membershipOrder, setSelectedCategoryId, setAdditionItems);
    }
  };

  return (
    <Form
      defaultData={defaultData}
      config={{ ...membershipFormConfig, formErrorHandler: notFoundAuthHandler }}
      //onSubmit={(val) => formSubmitted(val, additionItems, offering)}
    >
      <div className="flex space-x-4">
        {!membershipOrder?.id && (
          <>
            <div className="w-1/3">
              <List
                disabled={!!membershipOrder?.id}
                onChange={setYearFilter}
                name="year"
                label="Year"
                options={yearOptions}
              ></List>
            </div>
            <div className="w-1/3">
              <List
                disabled={!!membershipOrder?.id}
                onChange={setMonthFilter}
                name="month"
                label="Month"
                options={monthOptions}
              ></List>
            </div>
          </>
        )}

        {yearFilter && monthFilter && !membershipOrder?.id && (
          <div className="w-1/3">
            <List
              disabled={accLoading || !!membershipOrder?.id}
              onChange={setAccountId}
              value={accountId}
              name="accountId"
              label="Account"
              options={accountOptions}
              placeholder={accLoading ? 'Loading' : undefined}
            ></List>
          </div>
        )}
      </div>

      {membershipOrder && (
        <>
          {!offerLoading && !offeringOptions?.length ? (
            <div className="flex space-x-4 mt-2">
              <div className="w-1/3 text-gray-700">
                No offering available for{' '}
                <span className="capitalize">
                  {membershipOrder?.month?.toLowerCase()} {membershipOrder?.year}
                </span>
              </div>
            </div>
          ) : (
            <div className="flex space-x-4 mt-2">
              <div className="w-1/3">
                <List
                  disabled={offerLoading || !offeringOptions?.length}
                  value={selectedCategoryId}
                  onChange={setSelectedCategoryId}
                  name="selectedOfferingId"
                  label="Offering"
                  options={offeringOptions}
                  placeholder={offerLoading ? 'Loading' : undefined}
                ></List>
              </div>
              {!!selectedCategoryId && !!selectedCategoryItems?.length && (
                <div className="w-1/3">
                  <div>Included Items</div>
                  <div className="mt-3 border border-th-membership-500 rounded p-2">
                    {selectedCategoryItems?.map((ci) => (
                      <IncludedProductCard key={ci.id} className="my-1" offeringItem={ci} />
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {!!selectedCategoryId && (
            <div className="flex flex-col mt-4">
              <span className="text-xl text-gray-600 mb-2">Additional Items</span>
              <span className="text-normal text-gray-600 mb-4">Select additional items to add to the order</span>
              {!productsLoading && !products && <div>Error Loading Products</div>}

              {!productsLoading && products && (
                <>
                  {additionItems?.map((moi, index) => {
                    const currentProd = products?.items?.find((p) => p.itemId === moi?.itemId);
                    const options: ListOptionConfig[] = currentProd
                      ? ([
                          { label: currentProd.name, value: currentProd.itemId },
                          ...productOptions,
                        ] as ListOptionConfig[])
                      : productOptions;
                    return (
                      <div key={`product-select-${index}`} className="h-12 my-2 flex space-x-4 items-center">
                        <div className="w-1/3">
                          <List
                            disabled={productsLoading}
                            onChange={(val) => handleItemSelect(index, val)}
                            value={additionItems[index].itemId}
                            name={`item-select`}
                            options={options}
                          ></List>
                        </div>
                        <div className="w-1/3 flex">
                          <div className="w-1/4">
                            <Numeric
                              onChange={(evt) => handleQuantityChange(index, evt.parsedValue)}
                              value={additionItems[index].quantity ?? 0}
                              min={0}
                              name={`item-quantity`}
                            />
                          </div>
                          <div className="w-fit ml-4">
                            {deleteIndex === index ? (
                              <button
                                onClick={handleCancelDelete}
                                className={classNames(
                                  'border-2 h-10 w-10 flex items-center justify-center rounded text-purple-500 border-purple-500 hover:border-purple-400 hover:text-purple-400 ',
                                )}
                              >
                                <FaTimes size={24} />
                              </button>
                            ) : (
                              <button
                                onClick={() => setDeleteIndex(index)}
                                className={classNames(
                                  'border-2 h-10 w-10 flex items-center justify-center rounded text-red-500 border-red-500 hover:border-red-400 hover:text-red-400 ',
                                )}
                              >
                                <FaTrashAlt size={20} />
                              </button>
                            )}
                          </div>
                          {deleteIndex === index && (
                            <div className="w-1/4 ml-4">
                              <button
                                onClick={() => handleDeleteClick(deleteIndex)}
                                className={classNames(
                                  'border-2 h-10 w-10 flex items-center justify-center rounded text-green-500 border-green-500 hover:border-green-400 hover:text-green-400 ',
                                )}
                              >
                                <FaCheck size={20} />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  <div className="w-full flex">
                    <div className="w-1/3"></div>
                    <div className="w-1/3 flex">
                      <div className="w-1/4 ml-4"></div>
                      <div className="mt-4 ml-4 w-1/4">
                        <button
                          onClick={handleAddClick}
                          className={classNames(
                            'border-2 h-10 w-10 flex items-center justify-center rounded text-th-membership-500 border-th-membership-500 hover:border-th-membership-400 hover:text-th-membership-400 ',
                          )}
                        >
                          <FaPlus size={20} />
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {costData && !costIsLoading && (
                <div className="flex flex-col max-w-xl mt-16">
                  <div className="w-full">
                    <THH3 className="font-light">Order Cost</THH3>
                  </div>
                  <div className="flex justify-between mt-6 w-full">
                    <table className="w-full">
                      <tbody>
                        {!!costData?.subscription?.amountEx && (
                          <tr className="bg-gray-50">
                            <td>
                              <THP className="font-bold">Membership:</THP>
                            </td>
                            <td className="text-right">
                              <THP> ${costData?.subscription.amountEx.toFixed(2)}</THP>
                            </td>
                          </tr>
                        )}
                        {!!costData?.additionsTotal?.amountEx && (
                          <tr className="">
                            <td>
                              <THP className="font-bold">Additional Items:</THP>
                            </td>
                            <td className="text-right">
                              <THP> ${costData?.additionsTotal?.amountEx?.toFixed(2)}</THP>
                            </td>
                          </tr>
                        )}

                        {!!costData?.shipping?.amountEx && (
                          <tr className="bg-gray-50">
                            <td>
                              <THP className="font-bold">Shipping:</THP>
                            </td>
                            <td className="text-right">
                              <THP> ${costData?.shipping?.amountEx?.toFixed(2)}</THP>
                            </td>
                          </tr>
                        )}
                        {!!costData?.discount?.amountEx && (
                          <tr className="">
                            <td>
                              <THP className="font-bold">
                                {costData?.discount?.labels?.length ? `${costData?.discount?.labels[0]}:` : 'Discount:'}
                              </THP>
                            </td>
                            <td className="text-right">
                              <THP>
                                <span className="text-red-600">- ${costData?.discount?.amountEx?.toFixed(2)}</span>
                              </THP>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>
                            <THP className="font-bold mt-8">Subtotal (ex):</THP>
                          </td>
                          <td className="text-right">
                            <div className="mt-8">
                              {subTotal.toNumber() == 0 ? <THP>Free</THP> : <THP> ${subTotal?.toFixed(2)}</THP>}
                            </div>
                          </td>
                        </tr>

                        <tr className="bg-gray-50">
                          <td>
                            <THP className="font-bold">GST:</THP>
                          </td>
                          <td className="text-right">
                            <THP> ${costData?.total?.amountGst?.toFixed(2)}</THP>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <THP className="font-bold mt-8">Total:</THP>
                          </td>
                          <td className="text-right">
                            <div className="mt-8">
                              {costData?.total?.amountEx == 0 ? (
                                <THP>Free</THP>
                              ) : (
                                <THP>
                                  {' '}
                                  ${new Decimal(costData?.total.amountEx).add(costData?.total?.amountGst)?.toFixed(2)}
                                </THP>
                              )}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}

      <MembershipOrderEditInnerForm
        additionItems={additionItems}
        monthFilter={monthFilter}
        yearFilter={yearFilter}
        offering={offering}
        accountId={accountId}
        membershipOrder={membershipOrder}
        handleResetClicked={handleResetClicked}
        submit={formSubmitted}
      />
    </Form>
  );
};

type MembershipOrderEditInnerFormProps = {
  additionItems: MembershipOrderItem[];
  monthFilter?: MembershipOrderMonth;
  yearFilter?: string;
  offering?: MembershipOffering;
  accountId?: string;
  membershipOrder?: MembershipOrder;
  handleResetClicked: () => void;
  submit: (
    rawData: MembershipOrderCreate,
    setLoading: (val: boolean) => void,
    dispatchFormEvent: (event: FormEvent, eventData: any) => void,
    event: MouseEvent,
    additionalItems: MembershipOrderItem[],
    currentOffering?: MembershipOffering,
  ) => void;
};

const MembershipOrderEditInnerForm: FC<NebPropsWithStd<MembershipOrderEditInnerFormProps>> = ({
  additionItems,
  monthFilter,
  yearFilter,
  offering,
  accountId,
  membershipOrder,
  handleResetClicked,
  submit,
}) => {
  const { setHasChanged, hasChanged, data, setLoading, dispatchFormEvent } = useFormState();

  const handleSubmitClicked = (event: MouseEvent) => {
    submit(data, setLoading, dispatchFormEvent, event, additionItems, offering);
  };

  useEffect(() => {
    if (!hasChanged) {
      setHasChanged(true);
    }
  }, [JSON.stringify(additionItems)]);

  return (
    <div className="flex justify-end mt-4 space-x-4">
      <FormOutput className="mr-auto"></FormOutput>
      <SubmitButton
        onClick={handleSubmitClicked}
        disabled={!(monthFilter && yearFilter && accountId) && !membershipOrder}
        classes={{ element: 'w-fit px-4 whitespace-nowrap rounded hover:cursor-pointer' }}
      >
        {!membershipOrder ? 'Create Order' : undefined}
      </SubmitButton>
      <ResetButton
        onClick={handleResetClicked}
        classes={{ element: 'h-12 w-24 whitespace-nowrap rounded hover:cursor-pointer' }}
        disabled={!(monthFilter && yearFilter && accountId) && !membershipOrder}
      ></ResetButton>
    </div>
  );
};
