import { FunctionComponent } from 'react';
import { BsStar } from 'react-icons/bs';
import { MdOutlineStore } from 'react-icons/md';
import { TbPaperBag } from 'react-icons/tb';
import { IoIosPaper } from 'react-icons/io';
import { MembershipRoutePaths } from '../../router/membership-routes';
import { MembershipSidebarLink } from './membership-sidebar-link';

export type MembershipSidebarMenuProps = {};

export const MembershipSidebarMenu: FunctionComponent<MembershipSidebarMenuProps> = () => {
  return (
    <div className="memberships-sidebar-menu px-2 w-full">
      <MembershipSidebarLink
        to={MembershipRoutePaths.ACCOUNTS_INDEX}
        icon={MdOutlineStore}
        styles={{ textColor: 'text-4xl' }}
      >
        Accounts
      </MembershipSidebarLink>
      <MembershipSidebarLink
        to={MembershipRoutePaths.MEMBERSHIPS_INDEX}
        icon={BsStar}
        styles={{ textColor: 'text-4xl' }}
      >
        Account Memberships
      </MembershipSidebarLink>
      <MembershipSidebarLink
        to={MembershipRoutePaths.MEMBERSHIP_ORDERS_INDEX}
        icon={IoIosPaper}
        styles={{ textColor: 'text-4xl' }}
      >
        Membership Orders
      </MembershipSidebarLink>
      <MembershipSidebarLink
        to={MembershipRoutePaths.OFFERINGS_INDEX}
        icon={TbPaperBag}
        styles={{ textColor: 'text-4xl' }}
      >
        Offerings
      </MembershipSidebarLink>

      {/* <SidebarGroup icon={BsStar} href="/memberships/products" className="mt-2" label="Products">
      </SidebarGroup> */}
    </div>
  );
};
