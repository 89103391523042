import { MembershipOfferingItem } from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import { default as classNames } from 'classnames';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { FC } from 'react';

export type OfferingItemCardProps = {
  offeringItem?: MembershipOfferingItem;
};

export const OfferingItemCard: FC<NebPropsWithStd<OfferingItemCardProps>> = ({ className, offeringItem }) => {
  return (
    <div className={classNames('product-offeringItem-card flex items-center', className)}>
      <p className="font-semibold text-slate-800 w-56 text-left truncate">
        {offeringItem?.item?.variant?.name ?? offeringItem?.item?.product?.name}
      </p>

      <span className="bg-th-membership-50 rounded p-1 font-medium text-th-membership-600 ml-2 w-28">
        x{offeringItem?.quantity}
      </span>
      <span className="bg-th-membership-50 rounded p-1 font-medium text-th-membership-600 ml-2 w-40">
        {offeringItem?.membershipOfferingCategory?.name}
      </span>
      {offeringItem?.item?.variant && (
        <span className="bg-blue-500 rounded p-1 font-medium text-white ml-2">Variant</span>
      )}
      {offeringItem?.item?.product && (
        <span className="bg-indigo-500 rounded p-1 font-medium text-white ml-2">Product</span>
      )}

      {offeringItem?.deletedAt && (
        <span className="font-light text-sm ml-2 italic bg-slate-400 rounded px-2 text-white">trashed</span>
      )}

      <span
        className={classNames('ml-4 rounded bg-slate-200 text-slate-900 px-2 font-medium w-52', {
          invisible: !offeringItem?.item?.sku,
        })}
      >
        {offeringItem?.item?.sku}
      </span>
    </div>
  );
};
