/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * admin-rest-api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Contact,
  ValidationErrors,
  GetContactByIdParams,
  PatchContactByIdBody,
  DeleteContactByIdBody,
  PatchRestoreContactByIdBody,
  DeleteDestroyContactByIdBody,
  GetOneContactParams,
  Contacts,
  GetManyContactsParams,
  PostContactBody
} from '../ecommerce-services.schemas'
import { ecommerceAdminApiInstance } from '../../../apis/ecommerce-admin-api-instance'
import type { ErrorType } from '../../../apis/ecommerce-admin-api-instance'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Gets one Contact by Id in path
 */
export const getContactById = (
    id: string,
    params?: GetContactByIdParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<Contact>(
      {url: `/contact/${id}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetContactByIdQueryKey = (id: string,
    params?: GetContactByIdParams,) => [`/contact/${id}`, ...(params ? [params]: [])];

    
export type GetContactByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getContactById>>>
export type GetContactByIdQueryError = ErrorType<void | ValidationErrors>

export const useGetContactById = <TData = Awaited<ReturnType<typeof getContactById>>, TError = ErrorType<void | ValidationErrors>>(
 id: string,
    params?: GetContactByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getContactById>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetContactByIdQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getContactById>>> = ({ signal }) => getContactById(id,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getContactById>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Patches one Contact by Id in path
 */
export const patchContactById = (
    id: string,
    patchContactByIdBody: PatchContactByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Contact>(
      {url: `/contact/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchContactByIdBody
    },
      options);
    }
  


    export type PatchContactByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchContactById>>>
    export type PatchContactByIdMutationBody = PatchContactByIdBody
    export type PatchContactByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchContactById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchContactById>>, TError,{id: string;data: PatchContactByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchContactById>>, {id: string;data: PatchContactByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchContactById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchContactById>>, TError, {id: string;data: PatchContactByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Deletes one Contact by Id in path
 */
export const deleteContactById = (
    id: string,
    deleteContactByIdBody: DeleteContactByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Contact>(
      {url: `/contact/${id}`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteContactByIdBody
    },
      options);
    }
  


    export type DeleteContactByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteContactById>>>
    export type DeleteContactByIdMutationBody = DeleteContactByIdBody
    export type DeleteContactByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteContactById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteContactById>>, TError,{id: string;data: DeleteContactByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteContactById>>, {id: string;data: DeleteContactByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteContactById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteContactById>>, TError, {id: string;data: DeleteContactByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Imports accounts from Zoho CRM
 */
export const postEnablePortalById = (
    id: string,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<void>(
      {url: `/contact/${id}/register`, method: 'post'
    },
      options);
    }
  


    export type PostEnablePortalByIdMutationResult = NonNullable<Awaited<ReturnType<typeof postEnablePortalById>>>
    
    export type PostEnablePortalByIdMutationError = ErrorType<ValidationErrors>

    export const usePostEnablePortalById = <TError = ErrorType<ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postEnablePortalById>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postEnablePortalById>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  postEnablePortalById(id,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postEnablePortalById>>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Restores one Contact by Id in path
 */
export const patchRestoreContactById = (
    id: string,
    patchRestoreContactByIdBody: PatchRestoreContactByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Contact>(
      {url: `/contact/${id}/restore`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchRestoreContactByIdBody
    },
      options);
    }
  


    export type PatchRestoreContactByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchRestoreContactById>>>
    export type PatchRestoreContactByIdMutationBody = PatchRestoreContactByIdBody
    export type PatchRestoreContactByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchRestoreContactById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchRestoreContactById>>, TError,{id: string;data: PatchRestoreContactByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchRestoreContactById>>, {id: string;data: PatchRestoreContactByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchRestoreContactById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchRestoreContactById>>, TError, {id: string;data: PatchRestoreContactByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Destroys one Contact by Id in path
 */
export const deleteDestroyContactById = (
    id: string,
    deleteDestroyContactByIdBody: DeleteDestroyContactByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Contact>(
      {url: `/contact/${id}/destroy`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteDestroyContactByIdBody
    },
      options);
    }
  


    export type DeleteDestroyContactByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDestroyContactById>>>
    export type DeleteDestroyContactByIdMutationBody = DeleteDestroyContactByIdBody
    export type DeleteDestroyContactByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteDestroyContactById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDestroyContactById>>, TError,{id: string;data: DeleteDestroyContactByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDestroyContactById>>, {id: string;data: DeleteDestroyContactByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteDestroyContactById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteDestroyContactById>>, TError, {id: string;data: DeleteDestroyContactByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Get one Contacts
 */
export const getOneContact = (
    params?: GetOneContactParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<Contact>(
      {url: `/contact`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetOneContactQueryKey = (params?: GetOneContactParams,) => [`/contact`, ...(params ? [params]: [])];

    
export type GetOneContactQueryResult = NonNullable<Awaited<ReturnType<typeof getOneContact>>>
export type GetOneContactQueryError = ErrorType<void | ValidationErrors>

export const useGetOneContact = <TData = Awaited<ReturnType<typeof getOneContact>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetOneContactParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOneContact>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOneContactQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneContact>>> = ({ signal }) => getOneContact(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOneContact>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Get many Contacts
 */
export const getManyContacts = (
    params?: GetManyContactsParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<Contacts>(
      {url: `/contacts`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetManyContactsQueryKey = (params?: GetManyContactsParams,) => [`/contacts`, ...(params ? [params]: [])];

    
export type GetManyContactsQueryResult = NonNullable<Awaited<ReturnType<typeof getManyContacts>>>
export type GetManyContactsQueryError = ErrorType<void | ValidationErrors>

export const useGetManyContacts = <TData = Awaited<ReturnType<typeof getManyContacts>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetManyContactsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getManyContacts>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetManyContactsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getManyContacts>>> = ({ signal }) => getManyContacts(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getManyContacts>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Posts a new Contact
 */
export const postContact = (
    postContactBody: PostContactBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Contact>(
      {url: `/contacts`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postContactBody
    },
      options);
    }
  


    export type PostContactMutationResult = NonNullable<Awaited<ReturnType<typeof postContact>>>
    export type PostContactMutationBody = PostContactBody
    export type PostContactMutationError = ErrorType<void | ValidationErrors>

    export const usePostContact = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postContact>>, TError,{data: PostContactBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postContact>>, {data: PostContactBody}> = (props) => {
          const {data} = props ?? {};

          return  postContact(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postContact>>, TError, {data: PostContactBody}, TContext>(mutationFn, mutationOptions)
    }
    