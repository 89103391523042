import { NebPropsWithStd } from '@villageco/nebula/core';
import classNames from 'classnames';
import {
  ElementType,
  FC,
  InputHTMLAttributes,
  MouseEvent,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
  KeyboardEvent,
} from 'react';
import { IoMdClose } from 'react-icons/io';

export type TagClassNameFunction = ({ focused }: { focused: boolean }) => string;

type TagProps = {
  label: string;
  focused: boolean;
  tagCloseIcon?: ElementType;
  tagLabelClassName?: string;
  tagIconClassName?: string;
  tagClassName?: string | TagClassNameFunction;
  onClick?: (evt: MouseEvent) => void;
  onMouseDown?: (evt: MouseEvent) => void;
  onCloseClicked: (evt: MouseEvent) => void;
};

const Tag: FC<NebPropsWithStd<TagProps>> = ({
  label,
  focused,
  tagCloseIcon: CloseIcon = IoMdClose,
  tagLabelClassName = 'px-1 whitespace-nowrap overflow-hidden overflow-ellipsis max-w-full',
  tagIconClassName = 'px-1',
  tagClassName,
  onClick,
  onMouseDown,
  onCloseClicked,
}) => {
  const calcClassName = useMemo(
    () =>
      tagClassName
        ? typeof tagClassName === 'function'
          ? tagClassName({ focused })
          : tagClassName
        : `cursor-pointer flex items-center rounded text-white transition-color duration-200 max-w-full mr-2 ${
            focused ? 'bg-purple-500' : 'bg-blue-500 hover:bg-blue-400'
          }`,
    [tagClassName, focused],
  );
  return (
    <div onClick={onClick} onMouseDown={onMouseDown} className={calcClassName}>
      <span className={tagLabelClassName}>{label}</span>

      <span onClick={onCloseClicked} className={tagIconClassName}>
        <CloseIcon />
      </span>
    </div>
  );
};

export type TagOption = {
  label: string;
  value: string;
};

export type TagsInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'onChange' | 'value'> & {
  disabled?: boolean;
  inputClassName?: string;
  containerClassName?: string;
  tagsContainerClassName?: string;
  tagClassName?: string | TagClassNameFunction;
  tagCloseIcon?: ElementType;
  tagLabelClassName?: string;
  tagIconClassName?: string;
  onChange?: (tags: string[]) => void;
} & (
    | {
        initialTags?: string[];
        value?: string[];
      }
    | {
        initialTags?: TagOption[];
        value?: TagOption[];
      }
    | {
        initialTags?: undefined;
        value?: TagOption[];
      }
  );

export const TagsInput: FC<NebPropsWithStd<TagsInputProps>> = ({
  className = 'flex bg-white p-1 rounded max-w-full overflow-x-scroll',
  initialTags,
  disabled,
  inputClassName = 'outline-none ml-2 bg-transparent',
  containerClassName = 'w-full',
  tagsContainerClassName = 'flex',
  tagClassName,
  tagCloseIcon,
  tagLabelClassName,
  tagIconClassName,
  onChange,
  value,
  ...rest
}) => {
  const [internalValue, setInternalValue] = useState<string[] | TagOption[]>(value ?? []);
  const [currentTagIndex, setCurrentTagIndex] = useState<number | undefined>(undefined);

  const inputRef = useRef<HTMLInputElement>(null);
  const typingInput = useRef<HTMLInputElement>(null);
  const container = useRef<HTMLDivElement>(null);
  const tagsContainer = useRef<HTMLDivElement>(null);

  const inputValue = useMemo(
    () =>
      (internalValue as any[]).every((v: any) => Object.hasOwnProperty.call(v, 'value'))
        ? (internalValue as TagOption[]).map((v) => v.value).join(',')
        : (internalValue as string[]).join(','),
    [internalValue],
  );

  useEffect(() => {
    const focEvtListener = () => {
      container.current?.scrollTo({ left: container.current?.scrollWidth });
      // setCurrentTagIndex(undefined);
    };
    const blurEvtListener = () => {
      container.current?.scrollTo({ left: 0 });
    };

    if (typingInput.current) {
      typingInput.current.addEventListener('focus', focEvtListener);
      typingInput.current.addEventListener('blur', blurEvtListener);
    }

    return () => {
      if (typingInput.current) typingInput.current.removeEventListener('focus', focEvtListener);
      if (typingInput.current) typingInput.current.removeEventListener('blur', blurEvtListener);
    };
  }, [typingInput.current]);

  useEffect(() => {
    if (
      currentTagIndex !== undefined &&
      container.current &&
      (tagsContainer.current?.childNodes[currentTagIndex] as any)?.offsetLeft < container.current.scrollLeft
    ) {
      container.current?.scrollTo({ left: (tagsContainer.current?.childNodes[currentTagIndex] as any).offsetLeft });
    }

    if (
      currentTagIndex !== undefined &&
      container.current &&
      (tagsContainer.current?.childNodes[currentTagIndex] as any)?.offsetLeft >
        container.current.scrollLeft + container.current.offsetWidth
    ) {
      container.current?.scrollTo({
        left:
          (tagsContainer.current?.childNodes[currentTagIndex] as any).offsetLeft +
          (tagsContainer.current?.childNodes[currentTagIndex] as any).offsetWidth,
      });
    }
  }, [currentTagIndex]);

  const inputKeyDowned = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && typingInput.current?.value !== '') {
      e.preventDefault();
      const newTags = typingInput.current?.value.split(',').map((v) => v.trim()) ?? [];
      setInternalValue((prev) => [...prev, ...newTags] as any[]);
      if (typingInput.current) typingInput.current.value = '';
      onChange && onChange([...(internalValue as string[]), ...newTags]);
      tagsContainer.current?.scrollTo({ left: tagsContainer.current?.scrollWidth });
    }

    if ((e.key === 'Backspace' || e.key === 'ArrowLeft') && typingInput.current?.value === '') {
      e.preventDefault();
      setCurrentTagIndex(internalValue.length - 1);
      tagsContainer.current?.focus();
    }
  };

  const tagsClicked = (e: MouseEvent) => {
    if (e.target === typingInput.current) return;
    e.preventDefault();
    typingInput.current?.focus();
    setCurrentTagIndex(undefined);
  };

  const tagsKeyDowned = (e: KeyboardEvent<HTMLDivElement>) => {
    console.log(e.target);

    if (e.target === typingInput.current) return;

    if (e.key === 'Backspace') {
      e.preventDefault();
      e.stopPropagation();
      if (currentTagIndex === undefined) {
        setCurrentTagIndex(internalValue.length - 1);
      } else {
        onChange && onChange((internalValue as string[]).filter((_, i) => i !== currentTagIndex) as string[]);
        setInternalValue((prev) => (prev as string[]).filter((_, i) => i !== currentTagIndex) as any[]);
        setCurrentTagIndex((prev) => (prev as number) - 1);
      }
    }

    if (e.key === 'ArrowLeft') {
      e.preventDefault();
      e.stopPropagation();
      setCurrentTagIndex((prev) => (prev !== undefined && prev > 0 ? prev - 1 : 0));
    }

    if (e.key === 'ArrowRight') {
      e.preventDefault();
      e.stopPropagation();

      if (currentTagIndex === internalValue.length - 1) {
        typingInput.current?.focus();
        setCurrentTagIndex(undefined);
        return;
      }
      setCurrentTagIndex((prev) => (prev !== undefined && prev >= 0 && prev < internalValue.length ? prev + 1 : 0));
    }
  };

  const tagClicked = (index: number) => (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setCurrentTagIndex(index);
  };

  const closeClicked = (index: number) => (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setInternalValue((prev) => (prev as string[]).filter((_, i) => i !== index) as any[]);
    onChange && onChange((internalValue as string[]).filter((_, i) => i !== index) as string[]);
    setCurrentTagIndex(undefined);
  };

  const inputClicked = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setCurrentTagIndex(undefined);
  };

  return (
    <div
      ref={inputRef}
      className={classNames('tags-input', containerClassName)}
      onMouseDown={tagsClicked}
      onKeyDownCapture={tagsKeyDowned}
    >
      <input {...rest} hidden value={inputValue}></input>

      <div ref={container} className={classNames(className)}>
        <div ref={tagsContainer} tabIndex={-1} className={classNames(tagsContainerClassName, 'relative outline-none')}>
          {internalValue.map((v, i) => (
            <Tag
              key={i}
              label={typeof v === 'string' ? v : v.label}
              focused={currentTagIndex === i}
              tagCloseIcon={tagCloseIcon}
              tagLabelClassName={tagLabelClassName}
              tagIconClassName={tagIconClassName}
              tagClassName={tagClassName}
              onMouseDown={tagClicked(i)}
              onCloseClicked={closeClicked(i)}
            ></Tag>
          ))}
        </div>
        <input ref={typingInput} onKeyDown={inputKeyDowned} onClick={inputClicked} className={inputClassName}></input>
      </div>
    </div>
  );
};
