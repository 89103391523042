import { FC, MouseEvent } from 'react';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { useFormState } from '../../hooks';
import classNames from 'classnames';
import { FieldClassConfig, FormConfig, FormEvent } from '../../contexts';
import { merge } from 'lodash-es';

export type ResetButtonProps = {
  classes?: FieldClassConfig['resetButton'];
  disabled?: boolean;
  onClick?: () => void;
};

export const ResetButton: FC<NebPropsWithStd<ResetButtonProps>> = ({
  children,
  className,
  classes,
  disabled,
  onClick,
}) => {
  const { loading, hasChanged, config, setData, _initialData, dispatchFormEvent } = useFormState();

  const { defaultClassConfig } = config ? config : ({} as FormConfig<any>);

  const cc = merge({}, defaultClassConfig, { resetButton: classes });

  const resetClicked = (evt: MouseEvent) => {
    evt.preventDefault();
    if (onClick) {
      onClick();
    }
    dispatchFormEvent(FormEvent.RESET_CLICKED, evt);
    setData(_initialData);
  };

  return (
    <button
      disabled={disabled || loading || !hasChanged}
      type="button"
      onClick={resetClicked}
      className={classNames(
        className,
        {
          [cc.resetButton?.loading ?? '']: loading,
          [cc.resetButton?.disabled ?? '']: disabled,
          [cc.resetButton?.unchanged ?? '']: !hasChanged,
          [cc.resetButton?.ready ?? '']: !loading && hasChanged,
        },
        cc.resetButton?.element,
      )}
    >
      {children ?? 'Reset'}
    </button>
  );
};
