import { FunctionComponent } from 'react';
import { AiOutlineLink } from 'react-icons/ai';
import { MdWeb } from 'react-icons/md';
import { BiteyRoutePaths } from '../../router/bitey-routes';
import { BiteySidebarLink } from './bitey-sidebar-link';

export type BiteySidebarMenuProps = {};

export const BiteySidebarMenu: FunctionComponent<BiteySidebarMenuProps> = () => {
  return (
    <div className="bitey-sidebar-menu px-2 w-full">
      <BiteySidebarLink to={BiteyRoutePaths.LINKS_INDEX} icon={AiOutlineLink} styles={{ textColor: 'text-4xl' }}>
        Links
      </BiteySidebarLink>
      <BiteySidebarLink to={BiteyRoutePaths.PAGES_INDEX} icon={MdWeb} styles={{ textColor: 'text-4xl' }}>
        Pages
      </BiteySidebarLink>
    </div>
  );
};
