import { FunctionComponent, useEffect, useState } from 'react';
import classNames, { Argument } from 'classnames';
import { MouseEvent } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';

export type ButtonProps = {
  className?: Argument;
  onClick?: (event: MouseEvent) => void;
  variant?: 'solid' | 'outlined';
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  loading?: boolean;
};

export const Button: FunctionComponent<ButtonProps> = ({
  className,
  children,
  onClick,
  variant,
  disabled,
  loading,
}) => {
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (loading && !buttonLoading) {
        setButtonLoading(true);
      } else {
        setTimeout(() => {
          setButtonLoading(false);
        }, 500);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [loading]);

  const buttonClasses =
    variant === 'outlined'
      ? classNames('bg-white border-th-ecommerce-500 text-th-ecommerce-500', {
          'hover:border-th-ecommerce-300 hover:text-th-ecommerce-300 hover:bg-gray-100': !(disabled && loading),
          'border-th-ecommerce-300 text-th-ecommerce-300 bg-gray-100': disabled,
        })
      : classNames('bg-th-ecommerce-500 text-white border-th-ecommerce-500', {
          'hover:bg-th-ecommerce-300 hover:border-th-ecommerce-300': !(disabled && loading),
          'border-th-ecommerce-300 bg-th-ecommerce-300': disabled,
        });

  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        'py-2 px-4 w-full rounded-md button border-2 border-th-ecommerce-500 hover:border-th-ecommerce-300',
        className,
        buttonClasses,
      )}
      disabled={(disabled || loading) ?? false}
    >
      {buttonLoading && (
        <div className="w-full flex justify-center">
          <AiOutlineLoading className="animate-spin" size={28} />
        </div>
      )}
      {!buttonLoading && children}
    </button>
  );
};
