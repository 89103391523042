import { FunctionComponent, Props, PropsWithChildren, useState } from 'react';
import classNames, { Argument } from 'classnames';
import { FaPen, FaTimes, FaCheck } from 'react-icons/fa';

type Field<T> = T extends void ? string : keyof T;

export type EditableFieldProps<T> = {
  fieldName: Field<T>;
  className?: Argument;
  inputNode?: React.ReactNode;
  displayNode?: React.ReactNode;
  onChangeConfirmed?: (fieldName: Field<T>) => void;
  onCancel?: (fieldName: Field<T>) => void;
};

export const EditableField = <T = void,>({
  fieldName,
  className,
  inputNode,
  displayNode,
  onChangeConfirmed,
  onCancel,
}: EditableFieldProps<T>) => {
  const [edit, setEdit] = useState<boolean>(false);

  const handleEditClick = () => {
    setEdit(true);
  };

  const handleConfirm = () => {
    if (onChangeConfirmed) {
      onChangeConfirmed(fieldName);
    }
    setEdit(false);
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel(fieldName);
    }
    setEdit(false);
  };

  return (
    <div className={classNames('editable-field w-full relative', className)}>
      {edit ? (
        <div className="flex items-center p-1">
          {inputNode}
          <div className="flex flex-col pl-1">
            <div
              className="text-green-500 hover:text-blue-400 hover:active:text-blue-300 border-green-500 hover:border-blue-400 hover:active:border-blue-300 w-7 h-7 ml-2 flex justify-center items-center hover:cursor-pointer border-2 rounded-md mb-1"
              onClick={handleConfirm}
            >
              <FaCheck />
            </div>
            <div
              className="text-red-500 hover:text-blue-400 hover:active:text-blue-300 border-red-500 hover:border-blue-400 hover:active:border-blue-300 w-7 h-7 ml-2 flex justify-center items-center hover:cursor-pointer border-2 rounded-md"
              onClick={handleCancel}
            >
              <FaTimes />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-start w-full items-center ">
          {displayNode}
          <div className="pl-2">
            <div
              className="text-purple-500 hover:text-blue-400 hover:active:text-blue-300 border-purple-500 hover:border-blue-400 hover:active:border-blue-300 w-7 h-7 flex justify-center items-center hover:cursor-pointer border-2 rounded-md"
              onClick={handleEditClick}
            >
              <FaPen className="" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
