import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { GoogleLogin } from '@react-oauth/google';
import { Auth } from 'aws-amplify';
import { FC, MouseEvent } from 'react';
import logoUrl from '../../../assets/TownHall-03.png';

export type LoginProps = {};

export const Login: FC<LoginProps> = () => {
  const signInClicked = (evt: MouseEvent) => {
    evt.stopPropagation();
    evt.preventDefault();
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  };

  return (
    <div className="login-page w-screen h-screen flex flex-col items-center justify-center  bg-vc-light-brown ">
      <img src={logoUrl} className="h-24" />
      <h2 className="font-title text-3xl font-bold text-vc-dark-green my-8 h-">Sign in to begin.</h2>
      <div className="relative flex shrink-0">
        <GoogleLogin
          size="large"
          onSuccess={() => {}}
          width="350px"
          onError={() => {
            console.log('Login Failed');
          }}
        />
        <button
          className="absolute z-50 inset-0 w-full h-full hover:opacity-20 hover:bg-gray-500 rounded"
          onClick={signInClicked}
        ></button>
      </div>

      {/* {error && <p className="text-red-400 font-medium">{error.details}</p>} */}
    </div>
  );
};
