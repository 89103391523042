import { FC, MouseEventHandler, PropsWithChildren } from 'react';
import { NebPropsWithStd } from '@villageco/nebula/core';
import classNames, { Argument } from 'classnames';

export type SelectBoxProps = {
  selected?: boolean;
  onClick?: MouseEventHandler;
  selectedContainerClass?: string;
  deselectedContainerClass?: string;
  selectedInnerClass?: string;
  deselectedInnerClass?: string;
};

export const SelectBox: FC<NebPropsWithStd<SelectBoxProps>> = ({
  selected,
  onClick,
  className,
  selectedContainerClass,
  deselectedContainerClass,
  selectedInnerClass,
  deselectedInnerClass,
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        {
          [selectedContainerClass ?? 'border-blue-300 border']: selected,
          [deselectedContainerClass ?? 'border border-slate-800']: !selected,
        },
        className,
        'select-box bg-white h-6 w-6 rounded flex items-center justify-center  transition-colors duration-100 hover:cursor-pointer',
      )}
    >
      <p
        className={classNames(
          { [selectedInnerClass ?? 'bg-blue-300']: selected, [deselectedInnerClass ?? '']: !selected },
          'w-4 h-4 rounded transition-colors duration-100',
        )}
      ></p>
    </div>
  );
};
