import { SlimButton } from '@components/ui/slim-button';
import { THH2 } from '@components/ui/th-h2';
import { ThScrollPage } from '@components/ui/th-scroll-page';
import { useGetManyItems } from '@core/clients/ecommerce-services-admin/items/items';
import { useSelectedSet } from '@core/hooks/useSelectedSet';
import { setPageMeta } from '@core/stores/core-store';
import { FunctionComponent } from 'react';
import { ItemCard } from '../../components/items/item-card';
// import { useItems } from '../../hooks/useItems';

export type ItemsIndexPageProps = {};

export const ItemsIndexPage: FunctionComponent<ItemsIndexPageProps> = () => {
  setPageMeta('Items', false);

  const { selectedSet, selectClickHandler } = useSelectedSet();

  const { data, status } = useGetManyItems();

  return (
    <ThScrollPage id="items-index-page">
      {/* <div>Controls</div> */}
      <div className="min-h-full w-full rounded bg-th-ecommerce-100 p-2 ">
        {data?.items?.map((o) => (
          <ItemCard
            item={o}
            selected={selectedSet.has(o.id!)}
            onSelectClicked={selectClickHandler(o.id!)}
            className="bg-white rounded mb-2 p-2"
          ></ItemCard>
        ))}
      </div>
    </ThScrollPage>
  );
};
