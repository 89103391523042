import { QueryKey } from '@tanstack/react-query';
import { proxy } from 'valtio';

export type MembershipStoreState = {
  membershipsIndexQueryKey?: QueryKey;
  membershipOrdersIndexQueryKey?: QueryKey;
};

export const membershipState = proxy<MembershipStoreState>({
  membershipsIndexQueryKey: undefined,
  membershipOrdersIndexQueryKey: undefined,
});
