import { FC, PropsWithChildren } from 'react';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { Membership } from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import { MembershipCard } from './membership-card';
import classNames from 'classnames';

export type MembershipListProps = {
  memberships: Membership[];
};

export const MembershipList: FC<NebPropsWithStd<MembershipListProps>> = ({ memberships, className }) => {
  return (
    <div className={classNames(className, 'membership-list flex flex-col space-y-2')}>
      {memberships.map((m) => (
        <MembershipCard membership={m as any} key={m.id}></MembershipCard>
      ))}
    </div>
  );
};
