import { RouteObject } from 'react-router';
import { BiteySidebarMenu } from '../components/core/bitey-sidebar-menu';
import { BiteyHomePage } from '../pages';
import { LinksIndexPage } from '../pages/links';
import { LinkDetailsPage } from '../pages/links/[id]';
import { PagesIndexPage } from '../pages/pages';
import { PageDetailsPage } from '../pages/pages/[id]';

export enum BiteyRoutePaths {
  ROOT = '/bitey',

  LINKS_INDEX = '/bitey/links',
  LINK_DETAILS = '/bitey/link/',
  PAGES_INDEX = '/bitey/pages',
  PAGE_DETAILS = '/bitey/page/',
}

export const biteyRoutes: RouteObject[] = [
  {
    path: '/bitey',
    children: [
      { path: 'links', element: <LinksIndexPage /> },
      {
        path: 'link/:id',
        element: <LinkDetailsPage />,
      },

      { path: 'pages', element: <PagesIndexPage /> },
      {
        path: 'page/:id',
        element: <PageDetailsPage />,
      },

      { index: true, element: <BiteyHomePage /> },
    ],
  },
];

export const biteySidebarRoutes: RouteObject[] = [{ path: '/bitey/*', element: <BiteySidebarMenu /> }];
