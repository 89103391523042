import { SelectBox } from '@components/ui/select-box';
import { MembershipWhere } from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import { usePostBulkGenerateMembershipOrder } from '@core/clients/ecommerce-services-admin/membership-orders/membership-orders';
import { useGetManyMemberships } from '@core/clients/ecommerce-services-admin/memberships/memberships';
import { MONTH_SELECT_OPTIONS, YEAR_SELECT_OPTIONS } from '@core/helpers/select-constants';
import { Dialog, Transition } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { List } from '@villageco/nebula/forms';
import classNames from 'classnames';
import { FC, MouseEvent, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { LIST_OPTION_CONFIG } from '../../libs/form-config';

export type GenerateOrdersDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const GenerateOrdersDialog: FC<NebPropsWithStd<GenerateOrdersDialogProps>> = ({ isOpen, onClose }) => {
  const [selectedIds, setSelectedIds] = useState<Record<string, boolean>>({});
  const [showCopied, setShowCopied] = useState(false);
  const [month, setMonth] = useState<string | undefined>();
  const [year, setYear] = useState<string | undefined>();
  const [where, setWhere] = useState<MembershipWhere | undefined>(undefined);

  const queryClient = useQueryClient();

  const {
    data: memberships,
    isLoading: mLoading,
    queryKey,
  } = useGetManyMemberships(
    {
      where,
      include: { account: true, membershipFrequency: { include: { productVariant: true } } },
    },
    {
      query: {
        enabled: !!where,
      },
    },
  );

  const { mutateAsync, isLoading, data, error, isError, reset } = usePostBulkGenerateMembershipOrder();

  const searchClicked = (evt: MouseEvent) => {
    evt.preventDefault();
    setWhere({
      membershipOrders: {
        none: {
          month,
          year,
        },
      },
    } as MembershipWhere);
  };

  const dialogClosed = () => {
    queryClient.resetQueries(queryKey);
    setMonth(undefined);
    setYear(undefined);
    setSelectedIds({});
    setWhere(undefined);
    reset();
    onClose();
  };

  const closeClicked = (evt: MouseEvent) => {
    evt.preventDefault();
    dialogClosed();
  };

  const startGenerateClicked = async (evt: MouseEvent) => {
    evt.preventDefault();

    const membershipIds = Object.keys(selectedIds);

    if (!month || !year || !membershipIds.length) return;

    await mutateAsync({ data: { membershipIds, month: month as any, year } });
  };

  const outputClicked = (evt: MouseEvent) => {
    evt.preventDefault();
    if (canCopy) {
      navigator.clipboard.writeText(JSON.stringify({}));
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 2000);
    }
  };

  const selectAllClicked = (evt: MouseEvent) => {
    evt.preventDefault();
    const newSelectedIds =
      memberships?.items?.reduce<Record<string, boolean>>((acc, m) => ({ ...acc, [m.id!]: true }), {}) ?? {};
    setSelectedIds(newSelectedIds);
  };

  const selectMembershipClicked = (id: string) => (evt: MouseEvent) => {
    evt.preventDefault();
    setSelectedIds({ ...selectedIds, [id]: selectedIds[id] ? !selectedIds[id] : true });
  };

  const canCopy = {} && navigator.clipboard;

  const canGenerate = !!month && !!year && Object.keys(selectedIds).length > 0;

  const displayError =
    (error?.response?.status ?? 0) >= 500
      ? 'An error has occurrred on the server. Get Mitch or Chris P. ERROR CODE: FUCK'
      : JSON.stringify(error?.response?.data, null, 2);

  return (
    <div className="generate-orders-dialog">
      <Dialog onClose={dialogClosed} open={isOpen}>
        <div className="fixed inset-0 bg-black/30 z-40" aria-hidden="true" />

        <div className="z-50 fixed inset-0 p-16 py-32 flex justify-center items-center">
          <Dialog.Panel className=" bg-white rounded w-full p-8 flex flex-col">
            <Dialog.Title className="mb-4 font-semibold text-2xl text-gray-700">
              Generate membership orders
            </Dialog.Title>
            <Dialog.Description className="text-gray-400">Select memberships below to convert</Dialog.Description>
            <div className="mt-4 flex flex-col h-[70vh]">
              <div className="flex">
                <List
                  label="Month"
                  name="month"
                  classes={{
                    field: { element: 'rounded px-2 h-10 border border-gray-300 border-b-2 w-56' },
                    label: { container: 'mb-0', element: 'text-xs' },
                    listOptions: LIST_OPTION_CONFIG,
                  }}
                  onChange={(val: string | undefined) => setMonth(val)}
                  options={MONTH_SELECT_OPTIONS}
                  value={month}
                ></List>
                <List
                  label="Year"
                  name="year"
                  className="ml-4"
                  classes={{
                    field: { element: 'rounded px-2 h-10 border border-gray-300 border-b-2 w-56' },
                    label: { container: 'mb-0', element: 'text-xs' },
                    listOptions: LIST_OPTION_CONFIG,
                  }}
                  onChange={(val: string | undefined) => setYear(val)}
                  options={YEAR_SELECT_OPTIONS}
                  value={year}
                ></List>
                <button
                  onClick={searchClicked}
                  className={classNames('font-medium ml-4 rounded  w-28 justify-evenly flex flex-col items-center', {
                    'animate-pulse bg-th-membership-100 text-th-membership-300': !(month && year),
                    'bg-th-membership-300 text-th-membership-700 ': month && year,
                  })}
                  disabled={!(month && year)}
                >
                  <BiSearch className="" />
                  Search
                </button>
              </div>
              <div>
                <button
                  onClick={selectAllClicked}
                  disabled={!memberships?.items?.length}
                  className={classNames(
                    'font-medium mt-4 rounded border w-32 p-1 justify-evenly flex flex-col items-center',
                    {
                      'border-gray-300 text-gray-300': !memberships?.items?.length,
                    },
                  )}
                >
                  Select all
                </button>
              </div>

              <div className="flex h-full w-full space-x-4 mt-4">
                <div className="bg-slate-100 rounded p-2 text-slate-800 h-full overflow-auto space-y-2 w-3/4">
                  {where ? (
                    mLoading ? (
                      <p className="text-slate-600">Loading memberships</p>
                    ) : memberships?.items?.length ? (
                      memberships.items.map((m) => (
                        <div className="flex items-center rounded bg-white p-2" key={m.id}>
                          <SelectBox onClick={selectMembershipClicked(m.id!)} selected={selectedIds[m.id!]}></SelectBox>
                          <span className="ml-4">{m.account?.name}</span>
                          <span
                            className={classNames('ml-4 rounded p-1', {
                              'bg-blue-500 text-white': m.membershipFrequency?.productVariant?.name
                                ?.toLocaleLowerCase()
                                .includes('freemium'),
                              'bg-green-500 text-white': m.membershipFrequency?.productVariant?.name
                                ?.toLocaleLowerCase()
                                .includes('top shelf'),
                            })}
                          >
                            {' '}
                            {m.membershipFrequency?.productVariant?.name}
                          </span>
                        </div>
                      ))
                    ) : (
                      <p className="text-slate-600">No memberships without orders found for this month</p>
                    )
                  ) : (
                    <p className="text-slate-600">Select a month and year to continue</p>
                  )}
                </div>
                <div className="relative w-1/4" onClick={outputClicked}>
                  <div
                    className={classNames(
                      {
                        'active:bg-blue-100 hover:cursor-pointer': canCopy,
                        'text-gray-500': !isError,
                        'text-red-600': isError,
                      },
                      'w-full h-full bg-slate-50 rounded p-4  whitespace-pre flex flex-col',
                    )}
                  >
                    <p className="font-thin text-slate-500">Generation output</p>
                    <div className="overflow-auto w-full h-full">
                      {data && JSON.stringify(data, null, 2)}
                      {error && displayError}
                    </div>
                    {canCopy && <p className=" font-thin text-slate-500 mt-auto ml-auto">Click output to copy</p>}
                  </div>
                  <Transition
                    show={showCopied}
                    enter="transition-opacity duration-150"
                    enterFrom="opacity-0"
                    enterTo="opacity-30"
                    leave="transition-opacity duration-200"
                    leaveFrom="opacity-30"
                    leaveTo="opacity-0"
                  >
                    <span
                      className={classNames(
                        'transition-[display] duration-300 absolute  bg-black text-white py-1 px-2 rounded bottom-2 -right-2 mx-auto',
                      )}
                    >
                      Copied to clipboard
                    </span>
                  </Transition>
                </div>
              </div>

              <div className="w-full flex mt-8">
                <button
                  className={classNames(
                    // {
                    //   'border-gray-300 text-gray-300': isLoading && !isError,
                    //   'border-gray-600': !isLoading,
                    // },
                    'h-10 w-52 rounded border text-gray-600 font-medium',
                  )}
                  // disabled={isLoading && !isError}
                  onClick={closeClicked}
                >
                  Close
                </button>
                <button
                  disabled={!canGenerate}
                  className={classNames(
                    { 'animate-pulse': isLoading && !isError, 'bg-blue-500': canGenerate, 'bg-blue-200': !canGenerate },
                    'ml-auto h-10 w-52 rounded  text-white font-medium',
                  )}
                  onClick={startGenerateClicked}
                >
                  {isLoading && !isError ? 'Generating...' : 'Generate'}
                </button>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
};
