import { SalesOrder, ShipmentRun } from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import classNames from 'classnames';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { FC } from 'react';

export type ShipmentRunCardProps = {
  run: ShipmentRun & { salesOrders: SalesOrder[] };
};

export const ShipmentRunCard: FC<NebPropsWithStd<ShipmentRunCardProps>> = ({ className, run }) => {
  return (
    <div className={classNames('shipment-runs-card flex', className)}>
      <div>
        <div className="flex">
          <p>{run.account.name}</p>
        </div>
      </div>
    </div>
  );
};
