import axios from 'axios';
import { FormErrorHandler } from '../../contexts';
import { defaultErrorHandler } from './default-error-handler';

export const notFoundAuthHandler: FormErrorHandler<any> = (error, context) => {
  if (axios.isAxiosError(error) && error.response?.status === 400) {
    console.error(`Non auth user error on ${error.config.baseURL}${error.config.url}`);
    context.setFormError(JSON.stringify(error.response.data));
    return;
  }

  if (!(axios.isAxiosError(error) && error.response?.status === 404)) return defaultErrorHandler(error, context);

  if (axios.isAxiosError(error)) {
    console.error(`User not authorized to access ${error.config.baseURL}${error.config.url}`);
    context.setFormError('You are not authorized to save this item');
  }
};
