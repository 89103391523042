import { SelectBox } from '@components/ui/select-box';
import {
  File,
  Item,
  MembershipOffering,
  MembershipTier,
} from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import classNames from 'classnames';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { FC, MouseEvent } from 'react';

export type OfferingCardProps = {
  offering: MembershipOffering & { membershipTier: MembershipTier };
  selected?: boolean;
  onSelectClicked: (evt: MouseEvent) => void;
};

export const OfferingCard: FC<NebPropsWithStd<OfferingCardProps>> = ({
  className,
  selected,
  offering,
  onSelectClicked,
}) => {
  return (
    <div className={classNames('offering-card flex', className)}>
      <div className="w-16 h-16 flex items-center" onClick={onSelectClicked}>
        <SelectBox selected={selected} deselectedContainerClass="border border-gray-200">
          {' '}
        </SelectBox>
      </div>
      <div>
        <div className="flex space-x-4">
          <div className="flex space-x-4">
            <p className="font-bold text-slate-600">{offering.month}</p>
            <p className="font-bold text-slate-600">{offering.year}</p>
          </div>
          <p
            className={classNames('rounded py-1 px-2 text-white font-semibold', {
              'bg-blue-500': offering.membershipTier.name === 'Freemium',
              'bg-green-500': offering.membershipTier.name === 'Top Shelf',
              'bg-orange-500': offering.membershipTier.name === 'Enterprise',
            })}
          >
            {offering.membershipTier.name}
          </p>
        </div>
        {/* <div className="flex">
          <p>{offering.item.sellEx}</p>
          <p>{offering.item.costEx}</p>
        </div> */}
      </div>
    </div>
  );
};
