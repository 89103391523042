import { useGetManyContacts, usePostEnablePortalById } from '@core/clients/ecommerce-services-admin/contacts/contacts';
import { Account } from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { pick } from 'lodash-es';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { FC, MouseEvent } from 'react';

export type AccountContactManagerPanelProps = {
  account: Account;
  accountsQueryKey: QueryKey;
  fetchData: boolean;
};

export const AccountContactManagerPanel: FC<NebPropsWithStd<AccountContactManagerPanelProps>> = ({
  account,
  accountsQueryKey,
  fetchData = false,
}) => {
  const queryClient = useQueryClient();

  const { data: contacts, isLoading: contactsLoading } = useGetManyContacts(
    {
      select: {
        id: true,
        name: true,
        email: true,
        userSub: true,
      },
      where: {
        accountId: account.id!,
      },
    },
    { query: { enabled: fetchData } },
  );

  const { mutateAsync, isLoading: activateLoading } = usePostEnablePortalById({
    mutation: {
      onSuccess: async () => {
        // console.log(accountsQueryKey);

        await queryClient.invalidateQueries(accountsQueryKey);
      },
    },
  });

  const activateClicked = (id: string) => (evt: MouseEvent) => {
    evt.preventDefault();
    mutateAsync({ id });
  };

  return (
    <div className="space-y-2">
      {contactsLoading && (
        <div className="h-16 w-full rounded bg-white p-2">
          <div className="animate-pulse flex items-center h-full w-full">
            <div className="bg-slate-200 w-32 rounded h-10"></div>
            <div className="bg-slate-200 w-60 rounded h-10 ml-4"></div>
            <div className="bg-slate-200 w-32 rounded h-10 ml-auto"></div>
          </div>
        </div>
      )}
      {!contactsLoading && !contacts?.items?.length && <p>No contacts found for this account.</p>}
      {contacts?.items &&
        contacts.items.map((c) => (
          <div className="bg-white rounded w-full p-2 font-medium flex items-center">
            <p>{c.name}</p>
            <p className="bg-slate-100 rounded p-1 ml-4">{c.email}</p>
            {c.userSub && <p className="bg-green-200 rounded p-1 ml-4 text-green-800">portal enabled</p>}
            {!c.userSub && (
              <button
                disabled={activateLoading}
                onClick={activateClicked(c.id!)}
                className={classNames(' text-white font-medium rounded p-2 ml-auto', {
                  'bg-green-200 animate-pulse': activateLoading,
                  'bg-green-400': !activateLoading,
                })}
              >
                Activate portal
              </button>
            )}
          </div>
        ))}
    </div>
  );
};
