/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * admin-rest-api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  MembershipOrder,
  ValidationErrors,
  GetMembershipOrderByIdParams,
  PatchMembershipOrderByIdBody,
  DeleteMembershipOrderByIdBody,
  PatchRestoreMembershipOrderByIdBody,
  DeleteDestroyMembershipOrderByIdBody,
  GetCalculateMembershipOrderPricingByIdResponseBodySchema,
  GetCalculateMembershipOrderPricingByIdParams,
  GetOneMembershipOrderParams,
  MembershipOrders,
  GetManyMembershipOrdersParams,
  PostMembershipOrderBody,
  PostBulkGenerateMembershipOrdersResponseBodySchema,
  PostBulkGenerateMembershipOrderBody
} from '../ecommerce-services.schemas'
import { ecommerceAdminApiInstance } from '../../../apis/ecommerce-admin-api-instance'
import type { ErrorType } from '../../../apis/ecommerce-admin-api-instance'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Gets one Membership Order by Id in path
 */
export const getMembershipOrderById = (
    id: string,
    params?: GetMembershipOrderByIdParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<MembershipOrder>(
      {url: `/membership-order/${id}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetMembershipOrderByIdQueryKey = (id: string,
    params?: GetMembershipOrderByIdParams,) => [`/membership-order/${id}`, ...(params ? [params]: [])];

    
export type GetMembershipOrderByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getMembershipOrderById>>>
export type GetMembershipOrderByIdQueryError = ErrorType<void | ValidationErrors>

export const useGetMembershipOrderById = <TData = Awaited<ReturnType<typeof getMembershipOrderById>>, TError = ErrorType<void | ValidationErrors>>(
 id: string,
    params?: GetMembershipOrderByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMembershipOrderById>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMembershipOrderByIdQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMembershipOrderById>>> = ({ signal }) => getMembershipOrderById(id,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMembershipOrderById>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Patches one Membership Order by Id in path
 */
export const patchMembershipOrderById = (
    id: string,
    patchMembershipOrderByIdBody: PatchMembershipOrderByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipOrder>(
      {url: `/membership-order/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchMembershipOrderByIdBody
    },
      options);
    }
  


    export type PatchMembershipOrderByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchMembershipOrderById>>>
    export type PatchMembershipOrderByIdMutationBody = PatchMembershipOrderByIdBody
    export type PatchMembershipOrderByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchMembershipOrderById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchMembershipOrderById>>, TError,{id: string;data: PatchMembershipOrderByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchMembershipOrderById>>, {id: string;data: PatchMembershipOrderByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchMembershipOrderById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchMembershipOrderById>>, TError, {id: string;data: PatchMembershipOrderByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Deletes one Membership Order by Id in path
 */
export const deleteMembershipOrderById = (
    id: string,
    deleteMembershipOrderByIdBody: DeleteMembershipOrderByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipOrder>(
      {url: `/membership-order/${id}`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteMembershipOrderByIdBody
    },
      options);
    }
  


    export type DeleteMembershipOrderByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMembershipOrderById>>>
    export type DeleteMembershipOrderByIdMutationBody = DeleteMembershipOrderByIdBody
    export type DeleteMembershipOrderByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteMembershipOrderById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMembershipOrderById>>, TError,{id: string;data: DeleteMembershipOrderByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMembershipOrderById>>, {id: string;data: DeleteMembershipOrderByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteMembershipOrderById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteMembershipOrderById>>, TError, {id: string;data: DeleteMembershipOrderByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Restores one Membership Order by Id in path
 */
export const patchRestoreMembershipOrderById = (
    id: string,
    patchRestoreMembershipOrderByIdBody: PatchRestoreMembershipOrderByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipOrder>(
      {url: `/membership-order/${id}/restore`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchRestoreMembershipOrderByIdBody
    },
      options);
    }
  


    export type PatchRestoreMembershipOrderByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchRestoreMembershipOrderById>>>
    export type PatchRestoreMembershipOrderByIdMutationBody = PatchRestoreMembershipOrderByIdBody
    export type PatchRestoreMembershipOrderByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchRestoreMembershipOrderById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchRestoreMembershipOrderById>>, TError,{id: string;data: PatchRestoreMembershipOrderByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchRestoreMembershipOrderById>>, {id: string;data: PatchRestoreMembershipOrderByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchRestoreMembershipOrderById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchRestoreMembershipOrderById>>, TError, {id: string;data: PatchRestoreMembershipOrderByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Destroys one Membership Order by Id in path
 */
export const deleteDestroyMembershipOrderById = (
    id: string,
    deleteDestroyMembershipOrderByIdBody: DeleteDestroyMembershipOrderByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipOrder>(
      {url: `/membership-order/${id}/destroy`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteDestroyMembershipOrderByIdBody
    },
      options);
    }
  


    export type DeleteDestroyMembershipOrderByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDestroyMembershipOrderById>>>
    export type DeleteDestroyMembershipOrderByIdMutationBody = DeleteDestroyMembershipOrderByIdBody
    export type DeleteDestroyMembershipOrderByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteDestroyMembershipOrderById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDestroyMembershipOrderById>>, TError,{id: string;data: DeleteDestroyMembershipOrderByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDestroyMembershipOrderById>>, {id: string;data: DeleteDestroyMembershipOrderByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteDestroyMembershipOrderById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteDestroyMembershipOrderById>>, TError, {id: string;data: DeleteDestroyMembershipOrderByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Calculates the pricing for a Membership Order with the items parsed as params
 */
export const getCalculateMembershipOrderPricingById = (
    id: string,
    params?: GetCalculateMembershipOrderPricingByIdParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<GetCalculateMembershipOrderPricingByIdResponseBodySchema>(
      {url: `/membership-order/${id}/calculate`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetCalculateMembershipOrderPricingByIdQueryKey = (id: string,
    params?: GetCalculateMembershipOrderPricingByIdParams,) => [`/membership-order/${id}/calculate`, ...(params ? [params]: [])];

    
export type GetCalculateMembershipOrderPricingByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getCalculateMembershipOrderPricingById>>>
export type GetCalculateMembershipOrderPricingByIdQueryError = ErrorType<void | ValidationErrors>

export const useGetCalculateMembershipOrderPricingById = <TData = Awaited<ReturnType<typeof getCalculateMembershipOrderPricingById>>, TError = ErrorType<void | ValidationErrors>>(
 id: string,
    params?: GetCalculateMembershipOrderPricingByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCalculateMembershipOrderPricingById>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCalculateMembershipOrderPricingByIdQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCalculateMembershipOrderPricingById>>> = ({ signal }) => getCalculateMembershipOrderPricingById(id,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCalculateMembershipOrderPricingById>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Get one Membership Orders
 */
export const getOneMembershipOrder = (
    params?: GetOneMembershipOrderParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<MembershipOrder>(
      {url: `/membership-order`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetOneMembershipOrderQueryKey = (params?: GetOneMembershipOrderParams,) => [`/membership-order`, ...(params ? [params]: [])];

    
export type GetOneMembershipOrderQueryResult = NonNullable<Awaited<ReturnType<typeof getOneMembershipOrder>>>
export type GetOneMembershipOrderQueryError = ErrorType<void | ValidationErrors>

export const useGetOneMembershipOrder = <TData = Awaited<ReturnType<typeof getOneMembershipOrder>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetOneMembershipOrderParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOneMembershipOrder>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOneMembershipOrderQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneMembershipOrder>>> = ({ signal }) => getOneMembershipOrder(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOneMembershipOrder>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Get many Membership Orders
 */
export const getManyMembershipOrders = (
    params?: GetManyMembershipOrdersParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<MembershipOrders>(
      {url: `/membership-orders`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetManyMembershipOrdersQueryKey = (params?: GetManyMembershipOrdersParams,) => [`/membership-orders`, ...(params ? [params]: [])];

    
export type GetManyMembershipOrdersQueryResult = NonNullable<Awaited<ReturnType<typeof getManyMembershipOrders>>>
export type GetManyMembershipOrdersQueryError = ErrorType<void | ValidationErrors>

export const useGetManyMembershipOrders = <TData = Awaited<ReturnType<typeof getManyMembershipOrders>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetManyMembershipOrdersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getManyMembershipOrders>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetManyMembershipOrdersQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getManyMembershipOrders>>> = ({ signal }) => getManyMembershipOrders(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getManyMembershipOrders>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Posts a new Membership Order
 */
export const postMembershipOrder = (
    postMembershipOrderBody: PostMembershipOrderBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipOrder>(
      {url: `/membership-orders`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postMembershipOrderBody
    },
      options);
    }
  


    export type PostMembershipOrderMutationResult = NonNullable<Awaited<ReturnType<typeof postMembershipOrder>>>
    export type PostMembershipOrderMutationBody = PostMembershipOrderBody
    export type PostMembershipOrderMutationError = ErrorType<void | ValidationErrors>

    export const usePostMembershipOrder = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMembershipOrder>>, TError,{data: PostMembershipOrderBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMembershipOrder>>, {data: PostMembershipOrderBody}> = (props) => {
          const {data} = props ?? {};

          return  postMembershipOrder(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMembershipOrder>>, TError, {data: PostMembershipOrderBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Bulk generate default membership orders
 */
export const postBulkGenerateMembershipOrder = (
    postBulkGenerateMembershipOrderBody: PostBulkGenerateMembershipOrderBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<PostBulkGenerateMembershipOrdersResponseBodySchema>(
      {url: `/membership-orders/bulk`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postBulkGenerateMembershipOrderBody
    },
      options);
    }
  


    export type PostBulkGenerateMembershipOrderMutationResult = NonNullable<Awaited<ReturnType<typeof postBulkGenerateMembershipOrder>>>
    export type PostBulkGenerateMembershipOrderMutationBody = PostBulkGenerateMembershipOrderBody
    export type PostBulkGenerateMembershipOrderMutationError = ErrorType<void | ValidationErrors>

    export const usePostBulkGenerateMembershipOrder = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postBulkGenerateMembershipOrder>>, TError,{data: PostBulkGenerateMembershipOrderBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postBulkGenerateMembershipOrder>>, {data: PostBulkGenerateMembershipOrderBody}> = (props) => {
          const {data} = props ?? {};

          return  postBulkGenerateMembershipOrder(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postBulkGenerateMembershipOrder>>, TError, {data: PostBulkGenerateMembershipOrderBody}, TContext>(mutationFn, mutationOptions)
    }
    