import dayjs from 'dayjs';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { FC, useEffect, useState } from 'react';

export type ClockProps = {};

const Clock: FC<NebPropsWithStd<ClockProps>> = () => {
  const [date, setDate] = useState(dayjs());

  const tick = () => {
    setDate(dayjs());
  };

  useEffect(() => {
    const tickInterval = setInterval(tick, 1000);

    return () => {
      clearInterval(tickInterval);
    };
  }, []);

  return (
    <div className="clock flex items-end text-vc-dark-green font-title">
      <div className="flex items-end w-72 -mb-1 mr-6">
        <span className="text-9xl font-semibold">{date.format('HH')}</span>
        <span className="text-7xl font-light mb-8">:</span>
        <span className="text-9xl pl-0 font-thin">{date.format('mm')}</span>
        {/* <span className="text-2xl font-light pb-1 pl-2">{date.format('ss')}</span> */}
      </div>
      <div className="pl-4 flex flex-col">
        <span className="text-right text-xl font-medium">{date.format('dddd')}</span>
        <span className="text-right text-7xl font-bold -my-1 -mr-1">{date.format('DD')}</span>
        <span className="text-right text-xl font-medium -mt-1">{date.format('MMMM')}</span>
      </div>
    </div>
  );
};

export default Clock;
