import { setPageMeta } from '@core/stores/core-store';
import { FC } from 'react';

export type BiteyHomePageProps = {};

export const BiteyHomePage: FC<BiteyHomePageProps> = () => {
  setPageMeta('Bitey Summary', true);

  return (
    <div className="bitey-home-page w-full h-full p-4">
      <div className="bg-th-bitey-100 rounded w-full h-full">Bitey Home</div>
    </div>
  );
};
