import classNames from 'classnames';
import { merge } from 'lodash-es';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { FC, MouseEvent } from 'react';
import { FieldClassConfig, FormConfig, FormEvent } from '../../contexts';
import { useFormState } from '../../hooks';

export type SubmitButtonProps = {
  classes?: FieldClassConfig['submitButton'];
  disabled?: boolean;
  onClick?: (event: MouseEvent) => void;
};

export const SubmitButton: FC<NebPropsWithStd<SubmitButtonProps>> = ({
  children,
  className,
  classes,
  disabled,
  onClick,
}) => {
  const { loading, hasChanged, config, usesHtml, dispatchFormEvent, data } = useFormState();

  const { defaultClassConfig } = config ? config : ({} as FormConfig<any>);

  const cc = merge({}, defaultClassConfig, { submitButton: classes });

  const submitClicked = (evt: MouseEvent) => {
    console.log(data);

    if (onClick) {
      onClick(evt);
    } else {
      dispatchFormEvent(FormEvent.SUBMIT_CLICKED, evt);
    }
  };

  return (
    <button
      disabled={disabled || loading || !hasChanged}
      type={usesHtml ? 'submit' : 'button'}
      onClick={submitClicked}
      className={classNames(
        className,
        {
          [cc?.submitButton?.loading ?? '']: loading,
          [cc?.submitButton?.disabled ?? '']: disabled,
          [cc?.submitButton?.unchanged ?? '']: !hasChanged,
          [cc?.submitButton?.ready ?? '']: !loading && hasChanged,
        },
        cc?.submitButton?.element,
      )}
    >
      {children ?? 'Submit'}
    </button>
  );
};
