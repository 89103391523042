import { THH5 } from '@components/ui/th-h5';
import { useGetManyCategories } from '@core/clients/ecommerce-services-admin/categories/categories';
import {
  Product,
  ProductCreate,
  ProductStatus,
  ProductType,
  ProductUpdate,
  ProductVariant,
} from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import { usePatchProductById, usePostProduct } from '@core/clients/ecommerce-services-admin/products/products';
import { Disclosure, Tab } from '@headlessui/react';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { capitalCase } from 'change-case';
import { default as classNames } from 'classnames';
import { kebabCase, pick } from 'lodash';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { Form, FormOutput, List, notFoundAuthHandler, ResetButton, SubmitButton, Text } from '@villageco/nebula/forms';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { BsChevronUp, BsPlus } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { ecommerceFormConfig } from '../../libs/form-config';
import { EcommerceRoutePaths } from '../../router/ecommerce-routes';
import { ItemDetailsEditFields } from './item-details-edit-fields';
import { ProductVariantCard } from './product-variant-card';
import { ProductVariantEditForm } from './product-variant-edit-form';

export type ProductEditFormProps = {
  product?: Product;
  invalidateQueries: QueryKey[];
  onCreate?: (product: Product) => Promise<void>;
  onUpdate?: (product: Product) => Promise<void>;
};

export const ProductEditForm: FC<NebPropsWithStd<ProductEditFormProps>> = ({
  product,
  invalidateQueries,
  onCreate,
  onUpdate,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [variants, setVariants] = useState<ProductVariant[]>([]);
  const [useVariants, setUseVariants] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(product?.variants?.items?.length ? 1 : 0);
  const [productName, setProductName] = useState('');

  useEffect(() => {
    if (product?.variants?.items?.length) {
      setUseVariants(true);
      setVariants(product.variants.items ?? []);
      setSelectedIndex(1);
    }
  }, [product]);

  const { data: categories, isLoading: catLoading } = useGetManyCategories({
    select: {
      id: true,
      name: true,
    },
  });

  const mutation = {
    onSuccess: () => {
      invalidateQueries.forEach((q) => queryClient.invalidateQueries(q));
    },
  };

  const { mutateAsync: createProductMut } = usePostProduct({
    mutation,
  });
  const { mutateAsync: updateProductMut } = usePatchProductById({
    mutation,
  });

  const getProductSlug = (name?: string) => kebabCase(name);

  const formSubmitted = async (rawData: Product) => {
    if (useVariants) {
      delete rawData.item;
    }

    if (product?.id) {
      const data = {
        ...rawData,
        item: rawData.item ? { update: { ...rawData.item } } : undefined,
        categories: rawData?.categories && {
          connect: ((rawData?.categories as string[]) ?? []).map((c) => ({ id: c })),
        },
      } as ProductUpdate;

      const res = await updateProductMut({ id: product.id, data: { data } });
      onUpdate && (await onUpdate(res));
    } else {
      const data = {
        ...rawData,
        item: rawData.item ? { create: { ...rawData.item } } : undefined,
        slug: rawData.slug ?? getProductSlug(rawData.name!),
        categories: rawData?.categories && {
          connect: ((rawData?.categories as string[]) ?? []).map((c) => ({ id: c })),
        },
      } as ProductCreate;

      const res = await createProductMut({ data: { data } });
      onCreate && (await onCreate(res));
      navigate(`${EcommerceRoutePaths.PRODUCT_DETAILS}${res.id}`);
    }
  };

  const defaultData = pick(product ?? {}, [
    'name',
    'type',
    'description',
    'status',
    'categories',
    'relatedProducts',
    'slug',
  ]);

  defaultData.categories = (defaultData.categories?.items ?? []).map((c) => c.id) as any;

  const productTypeOptions = Object.values(ProductType).map((t) => ({
    value: t,
    label: capitalCase(t),
  }));

  const productStatusOptions = Object.values(ProductStatus).map((s) => ({
    value: s,
    label: capitalCase(s),
  }));

  const categoryOptions = (categories?.items ?? []).map((c) => ({
    value: c.id!,
    label: c.name!,
  }));

  console.log(categoryOptions);

  const addVariantClicked = (evt: MouseEvent) => {
    evt.preventDefault();

    setVariants([...variants, {}]);
  };

  const tabGroupChanged = (index: number) => {
    setUseVariants(index === 1);
    setSelectedIndex(index);
  };

  const variantRemoved = (index: number) => () => {
    const variant = variants[index];

    if (!variant.id) {
      variants.splice(index, 1);
      setVariants([...variants]);
    }
  };

  return (
    <Form
      defaultData={defaultData}
      config={{ ...ecommerceFormConfig, formErrorHandler: notFoundAuthHandler }}
      onSubmit={formSubmitted}
    >
      <div>
        <div>
          <div className="flex w-full mt-6 space-x-4">
            <Text<typeof defaultData>
              className="w-full"
              name="name"
              label="Product name"
              onChange={(evt) => setProductName(evt.target.value)}
            ></Text>
            <Text<typeof defaultData>
              className="w-full"
              name="slug"
              label="Product Slug"
              placeholder={getProductSlug(productName)}
            ></Text>
          </div>
          <div className="flex w-full mt-4 space-x-4">
            <Text<typeof defaultData>
              className="w-full"
              name="description"
              optional={true}
              label="Product description"
            ></Text>
            <List<typeof defaultData>
              options={productTypeOptions}
              className="w-full"
              name="type"
              label="Product type"
              placeholder="Select product type"
            ></List>
          </div>
          <div className="flex w-full mt-4 space-x-4">
            <List<typeof defaultData>
              options={productStatusOptions}
              className="w-full"
              name="status"
              label="Product status"
              placeholder="Select product status"
            ></List>
            <List<typeof defaultData>
              options={categoryOptions}
              multiple={true}
              className="w-full pr-2"
              name="categories"
              label="Categories"
              placeholder={catLoading ? 'Loading...' : 'Select categories'}
            ></List>
          </div>
        </div>
        {product?.id && (
          <div className="mt-6">
            <Tab.Group onChange={tabGroupChanged} selectedIndex={selectedIndex} defaultIndex={selectedIndex}>
              <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
                <Tab
                  disabled={!!product}
                  className={({ selected }) =>
                    classNames(
                      'w-full rounded-lg py-2.5 text-sm font-semibold leading-5',
                      'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 ',
                      selected ? 'bg-white shadow text-blue-700' : 'text-white hover:bg-white/[0.22]',
                    )
                  }
                >
                  Single Product
                </Tab>
                <Tab
                  disabled={!!product}
                  className={({ selected }) =>
                    classNames(
                      'w-full rounded-lg py-2.5 text-sm font-semibold leading-5 ',
                      'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 ',
                      selected ? 'bg-white shadow text-blue-700' : 'text-white hover:bg-white/[0.22]',
                    )
                  }
                >
                  Has variants
                </Tab>
              </Tab.List>
              <Tab.Panels className="mt-2">
                <Tab.Panel className={classNames('rounded bg-slate-100 p-3')}>
                  <THH5>Purchasing Details</THH5>
                  <ItemDetailsEditFields fieldNamePrefix="item." item={product?.item}></ItemDetailsEditFields>
                </Tab.Panel>
                <Tab.Panel className={classNames('rounded bg-slate-100 p-3')}>
                  <div className="flex items-center">
                    <THH5 className="text-slate-800">Variants</THH5>
                    <button
                      onClick={addVariantClicked}
                      className="flex bg-th-ecommerce-500 rounded text-white font-semibold h-10 items-center justify-center w-32 ml-auto"
                    >
                      <BsPlus />
                      Add Variant
                    </button>
                  </div>
                  <div className="space-y-2 mt-4">
                    {variants.map((v, index) => (
                      <Disclosure key={index} defaultOpen={!v.id}>
                        {({ open }) => (
                          <>
                            {v.id && (
                              <Disclosure.Button
                                className={classNames('rounded w-full flex p-2 items-center', {
                                  'bg-white': !v.deletedAt,
                                  'bg-slate-200': v.deletedAt,
                                })}
                              >
                                <ProductVariantCard variant={v}></ProductVariantCard>
                                <BsChevronUp
                                  className={classNames('h-4 w-4 ml-auto mr-4', {
                                    'rotate-180 transform': !open,
                                  })}
                                />
                              </Disclosure.Button>
                            )}
                            <Disclosure.Panel className="bg-white rounded p-2">
                              <ProductVariantEditForm
                                productId={product?.id!}
                                variant={v}
                                invalidateQueries={invalidateQueries}
                                onRemove={variantRemoved(index)}
                              ></ProductVariantEditForm>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        )}
      </div>

      <div className="flex justify-end mt-4 space-x-4">
        <FormOutput className="mr-auto"></FormOutput>
        <SubmitButton></SubmitButton>
        <ResetButton></ResetButton>
      </div>
    </Form>
  );
};
