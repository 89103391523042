import { THH3 } from '@components/ui/th-h3';
import {
  Account,
  Membership,
  MembershipFrequency,
  MembershipOrders,
  Memberships,
  ProductVariant,
} from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import { useGetMembershipById } from '@core/clients/ecommerce-services-admin/memberships/memberships';
import { setPageMeta } from '@core/stores/core-store';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { useStringDateTimeFormatter } from '@villageco/nebula';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { FC } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { MembershipEditForm } from '../../components/memberships/membership-edit-form';
import { membershipState } from '../../stores';
import { ThScrollPage } from '@components/ui/th-scroll-page';

export type MembershipDetailsPageProps = {};

export const MembershipDetailsPage: FC<NebPropsWithStd<MembershipDetailsPageProps>> = () => {
  setPageMeta('Memberships', false);

  const { id: membershipId } = useParams();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const storeSnap = useSnapshot(membershipState);

  const accountId = searchParams.get('accountId') ?? undefined;

  const isNew = membershipId === 'new';

  const {
    data: membership,
    status,
    queryKey,
    error,
  } = useGetMembershipById(
    membershipId!,
    {
      include: {
        membershipFrequency: {
          include: { productVariant: true },
        },
        account: true,
        membershipOrders: true,
      },
    },
    {
      query: {
        enabled: !isNew,
        initialData: () => {
          return (
            storeSnap.membershipsIndexQueryKey &&
            queryClient
              .getQueryData<Memberships>(storeSnap.membershipsIndexQueryKey)
              ?.items?.find((m) => m.id === membershipId)
          );
        },
      },
    },
  );

  const { formattedDate: memberSince } = useStringDateTimeFormatter(membership?.createdAt, 'en-AU', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });

  return (
    <ThScrollPage id="membership-details-page">
      {(status === 'success' || isNew) && (
        <div>
          <THH3>
            {isNew ? (
              'Add a new membership'
            ) : (
              <>
                {`Membership details for `}
                <span className="text-gray-700">{membership?.account?.name}</span>
              </>
            )}
          </THH3>
          <div className="mt-4 bg-th-membership-100 rounded p-4 w-full">
            {membership && (
              <>
                <div className="flex">
                  <p className="font-medium text-gray-600 text-lg">Member since {memberSince}</p>
                </div>
                <div className="flex mt-2">
                  <p
                    className={classNames(
                      {
                        'bg-green-300 text-green-700': !membership.endAt,
                        'bg-orange-400 text-orange-700': membership.endAt,
                      },
                      'p-1 rounded font-medium',
                    )}
                  >
                    {membership.endAt ? 'cancelled' : 'active'}
                  </p>
                  <p className="p-1 rounded font-medium ml-4 bg-blue-500 text-white">
                    {membership.membershipFrequency?.productVariant?.name}
                  </p>
                </div>
                <hr className="border-th-membership-500 my-4"></hr>
                <p className="text-xl font-semibold text-th-membership-600 mb-2">Change membership</p>
              </>
            )}

            <MembershipEditForm
              membership={membership}
              membershipsQueryKey={queryKey}
              defaultAccountId={accountId}
            ></MembershipEditForm>
          </div>
        </div>
      )}
      {status === 'loading' && !isNew && (
        <div>
          <div className="bg-gray-200 animate-pulse rounded w-full h-10"></div>
          <div className="bg-gray-200 animate-pulse rounded w-full h-64 mt-4"></div>
        </div>
      )}
      {status === 'error' && (
        <div>
          <THH3>An error has occurred trying to retrieve membership</THH3>
          <p>{error}</p>
        </div>
      )}
    </ThScrollPage>
  );
};
