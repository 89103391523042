/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * admin-rest-api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Item,
  ValidationErrors,
  GetItemByIdParams,
  PatchItemByIdBody,
  DeleteItemByIdBody,
  PatchRestoreItemByIdBody,
  DeleteDestroyItemByIdBody,
  GetOneItemParams,
  Items,
  GetManyItemsParams,
  PostItemBody
} from '../ecommerce-services.schemas'
import { ecommerceAdminApiInstance } from '../../../apis/ecommerce-admin-api-instance'
import type { ErrorType } from '../../../apis/ecommerce-admin-api-instance'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Gets one Item by Id in path
 */
export const getItemById = (
    id: string,
    params?: GetItemByIdParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<Item>(
      {url: `/item/${id}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetItemByIdQueryKey = (id: string,
    params?: GetItemByIdParams,) => [`/item/${id}`, ...(params ? [params]: [])];

    
export type GetItemByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getItemById>>>
export type GetItemByIdQueryError = ErrorType<void | ValidationErrors>

export const useGetItemById = <TData = Awaited<ReturnType<typeof getItemById>>, TError = ErrorType<void | ValidationErrors>>(
 id: string,
    params?: GetItemByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getItemById>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetItemByIdQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getItemById>>> = ({ signal }) => getItemById(id,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getItemById>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Patches one Item by Id in path
 */
export const patchItemById = (
    id: string,
    patchItemByIdBody: PatchItemByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Item>(
      {url: `/item/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchItemByIdBody
    },
      options);
    }
  


    export type PatchItemByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchItemById>>>
    export type PatchItemByIdMutationBody = PatchItemByIdBody
    export type PatchItemByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchItemById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchItemById>>, TError,{id: string;data: PatchItemByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchItemById>>, {id: string;data: PatchItemByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchItemById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchItemById>>, TError, {id: string;data: PatchItemByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Deletes one Item by Id in path
 */
export const deleteItemById = (
    id: string,
    deleteItemByIdBody: DeleteItemByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Item>(
      {url: `/item/${id}`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteItemByIdBody
    },
      options);
    }
  


    export type DeleteItemByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteItemById>>>
    export type DeleteItemByIdMutationBody = DeleteItemByIdBody
    export type DeleteItemByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteItemById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteItemById>>, TError,{id: string;data: DeleteItemByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteItemById>>, {id: string;data: DeleteItemByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteItemById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteItemById>>, TError, {id: string;data: DeleteItemByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Restores one Item by Id in path
 */
export const patchRestoreItemById = (
    id: string,
    patchRestoreItemByIdBody: PatchRestoreItemByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Item>(
      {url: `/item/${id}/restore`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchRestoreItemByIdBody
    },
      options);
    }
  


    export type PatchRestoreItemByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchRestoreItemById>>>
    export type PatchRestoreItemByIdMutationBody = PatchRestoreItemByIdBody
    export type PatchRestoreItemByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchRestoreItemById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchRestoreItemById>>, TError,{id: string;data: PatchRestoreItemByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchRestoreItemById>>, {id: string;data: PatchRestoreItemByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchRestoreItemById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchRestoreItemById>>, TError, {id: string;data: PatchRestoreItemByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Destroys one Item by Id in path
 */
export const deleteDestroyItemById = (
    id: string,
    deleteDestroyItemByIdBody: DeleteDestroyItemByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Item>(
      {url: `/item/${id}/destroy`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteDestroyItemByIdBody
    },
      options);
    }
  


    export type DeleteDestroyItemByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDestroyItemById>>>
    export type DeleteDestroyItemByIdMutationBody = DeleteDestroyItemByIdBody
    export type DeleteDestroyItemByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteDestroyItemById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDestroyItemById>>, TError,{id: string;data: DeleteDestroyItemByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDestroyItemById>>, {id: string;data: DeleteDestroyItemByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteDestroyItemById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteDestroyItemById>>, TError, {id: string;data: DeleteDestroyItemByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Get one Items
 */
export const getOneItem = (
    params?: GetOneItemParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<Item>(
      {url: `/item`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetOneItemQueryKey = (params?: GetOneItemParams,) => [`/item`, ...(params ? [params]: [])];

    
export type GetOneItemQueryResult = NonNullable<Awaited<ReturnType<typeof getOneItem>>>
export type GetOneItemQueryError = ErrorType<void | ValidationErrors>

export const useGetOneItem = <TData = Awaited<ReturnType<typeof getOneItem>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetOneItemParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOneItem>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOneItemQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneItem>>> = ({ signal }) => getOneItem(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOneItem>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Get many Items
 */
export const getManyItems = (
    params?: GetManyItemsParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<Items>(
      {url: `/items`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetManyItemsQueryKey = (params?: GetManyItemsParams,) => [`/items`, ...(params ? [params]: [])];

    
export type GetManyItemsQueryResult = NonNullable<Awaited<ReturnType<typeof getManyItems>>>
export type GetManyItemsQueryError = ErrorType<void | ValidationErrors>

export const useGetManyItems = <TData = Awaited<ReturnType<typeof getManyItems>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetManyItemsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getManyItems>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetManyItemsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getManyItems>>> = ({ signal }) => getManyItems(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getManyItems>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Posts a new Item
 */
export const postItem = (
    postItemBody: PostItemBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Item>(
      {url: `/items`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postItemBody
    },
      options);
    }
  


    export type PostItemMutationResult = NonNullable<Awaited<ReturnType<typeof postItem>>>
    export type PostItemMutationBody = PostItemBody
    export type PostItemMutationError = ErrorType<void | ValidationErrors>

    export const usePostItem = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postItem>>, TError,{data: PostItemBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postItem>>, {data: PostItemBody}> = (props) => {
          const {data} = props ?? {};

          return  postItem(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postItem>>, TError, {data: PostItemBody}, TContext>(mutationFn, mutationOptions)
    }
    