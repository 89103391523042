import { SelectOption } from '@villageco/nebula/forms';
import { capitalCase } from 'change-case';
import { MembershipOrderCreateMonth } from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';

export const MONTH_SELECT_OPTIONS: SelectOption[] = Object.values(MembershipOrderCreateMonth).map((m) => ({
  label: capitalCase(m),
  value: m,
}));

export const YEAR_SELECT_OPTIONS: SelectOption[] = [
  {
    value: '2022',
  },
  {
    value: '2023',
  },
];
