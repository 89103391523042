/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * admin-rest-api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  MembershipOfferingItem,
  ValidationErrors,
  GetMembershipOfferingItemByIdParams,
  PatchMembershipOfferingItemByIdBody,
  DeleteMembershipOfferingItemByIdBody,
  PatchRestoreMembershipOfferingItemByIdBody,
  DeleteDestroyMembershipOfferingItemByIdBody,
  GetOneMembershipOfferingItemParams,
  MembershipOfferingItems,
  GetManyMembershipOfferingItemsParams,
  PostMembershipOfferingItemBody
} from '../ecommerce-services.schemas'
import { ecommerceAdminApiInstance } from '../../../apis/ecommerce-admin-api-instance'
import type { ErrorType } from '../../../apis/ecommerce-admin-api-instance'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Gets one Membership Offering Item by Id in path
 */
export const getMembershipOfferingItemById = (
    id: string,
    params?: GetMembershipOfferingItemByIdParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<MembershipOfferingItem>(
      {url: `/membership-offering-item/${id}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetMembershipOfferingItemByIdQueryKey = (id: string,
    params?: GetMembershipOfferingItemByIdParams,) => [`/membership-offering-item/${id}`, ...(params ? [params]: [])];

    
export type GetMembershipOfferingItemByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getMembershipOfferingItemById>>>
export type GetMembershipOfferingItemByIdQueryError = ErrorType<void | ValidationErrors>

export const useGetMembershipOfferingItemById = <TData = Awaited<ReturnType<typeof getMembershipOfferingItemById>>, TError = ErrorType<void | ValidationErrors>>(
 id: string,
    params?: GetMembershipOfferingItemByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMembershipOfferingItemById>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMembershipOfferingItemByIdQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMembershipOfferingItemById>>> = ({ signal }) => getMembershipOfferingItemById(id,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMembershipOfferingItemById>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Patches one Membership Offering Item by Id in path
 */
export const patchMembershipOfferingItemById = (
    id: string,
    patchMembershipOfferingItemByIdBody: PatchMembershipOfferingItemByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipOfferingItem>(
      {url: `/membership-offering-item/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchMembershipOfferingItemByIdBody
    },
      options);
    }
  


    export type PatchMembershipOfferingItemByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchMembershipOfferingItemById>>>
    export type PatchMembershipOfferingItemByIdMutationBody = PatchMembershipOfferingItemByIdBody
    export type PatchMembershipOfferingItemByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchMembershipOfferingItemById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchMembershipOfferingItemById>>, TError,{id: string;data: PatchMembershipOfferingItemByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchMembershipOfferingItemById>>, {id: string;data: PatchMembershipOfferingItemByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchMembershipOfferingItemById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchMembershipOfferingItemById>>, TError, {id: string;data: PatchMembershipOfferingItemByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Deletes one Membership Offering Item by Id in path
 */
export const deleteMembershipOfferingItemById = (
    id: string,
    deleteMembershipOfferingItemByIdBody: DeleteMembershipOfferingItemByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipOfferingItem>(
      {url: `/membership-offering-item/${id}`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteMembershipOfferingItemByIdBody
    },
      options);
    }
  


    export type DeleteMembershipOfferingItemByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMembershipOfferingItemById>>>
    export type DeleteMembershipOfferingItemByIdMutationBody = DeleteMembershipOfferingItemByIdBody
    export type DeleteMembershipOfferingItemByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteMembershipOfferingItemById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMembershipOfferingItemById>>, TError,{id: string;data: DeleteMembershipOfferingItemByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMembershipOfferingItemById>>, {id: string;data: DeleteMembershipOfferingItemByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteMembershipOfferingItemById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteMembershipOfferingItemById>>, TError, {id: string;data: DeleteMembershipOfferingItemByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Restores one Membership Offering Item by Id in path
 */
export const patchRestoreMembershipOfferingItemById = (
    id: string,
    patchRestoreMembershipOfferingItemByIdBody: PatchRestoreMembershipOfferingItemByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipOfferingItem>(
      {url: `/membership-offering-item/${id}/restore`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchRestoreMembershipOfferingItemByIdBody
    },
      options);
    }
  


    export type PatchRestoreMembershipOfferingItemByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchRestoreMembershipOfferingItemById>>>
    export type PatchRestoreMembershipOfferingItemByIdMutationBody = PatchRestoreMembershipOfferingItemByIdBody
    export type PatchRestoreMembershipOfferingItemByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchRestoreMembershipOfferingItemById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchRestoreMembershipOfferingItemById>>, TError,{id: string;data: PatchRestoreMembershipOfferingItemByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchRestoreMembershipOfferingItemById>>, {id: string;data: PatchRestoreMembershipOfferingItemByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchRestoreMembershipOfferingItemById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchRestoreMembershipOfferingItemById>>, TError, {id: string;data: PatchRestoreMembershipOfferingItemByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Destroys one Membership Offering Item by Id in path
 */
export const deleteDestroyMembershipOfferingItemById = (
    id: string,
    deleteDestroyMembershipOfferingItemByIdBody: DeleteDestroyMembershipOfferingItemByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipOfferingItem>(
      {url: `/membership-offering-item/${id}/destroy`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteDestroyMembershipOfferingItemByIdBody
    },
      options);
    }
  


    export type DeleteDestroyMembershipOfferingItemByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDestroyMembershipOfferingItemById>>>
    export type DeleteDestroyMembershipOfferingItemByIdMutationBody = DeleteDestroyMembershipOfferingItemByIdBody
    export type DeleteDestroyMembershipOfferingItemByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteDestroyMembershipOfferingItemById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDestroyMembershipOfferingItemById>>, TError,{id: string;data: DeleteDestroyMembershipOfferingItemByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDestroyMembershipOfferingItemById>>, {id: string;data: DeleteDestroyMembershipOfferingItemByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteDestroyMembershipOfferingItemById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteDestroyMembershipOfferingItemById>>, TError, {id: string;data: DeleteDestroyMembershipOfferingItemByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Get one Membership Offering Items
 */
export const getOneMembershipOfferingItem = (
    params?: GetOneMembershipOfferingItemParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<MembershipOfferingItem>(
      {url: `/membership-offering-item`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetOneMembershipOfferingItemQueryKey = (params?: GetOneMembershipOfferingItemParams,) => [`/membership-offering-item`, ...(params ? [params]: [])];

    
export type GetOneMembershipOfferingItemQueryResult = NonNullable<Awaited<ReturnType<typeof getOneMembershipOfferingItem>>>
export type GetOneMembershipOfferingItemQueryError = ErrorType<void | ValidationErrors>

export const useGetOneMembershipOfferingItem = <TData = Awaited<ReturnType<typeof getOneMembershipOfferingItem>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetOneMembershipOfferingItemParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOneMembershipOfferingItem>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOneMembershipOfferingItemQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneMembershipOfferingItem>>> = ({ signal }) => getOneMembershipOfferingItem(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOneMembershipOfferingItem>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Get many Membership Offering Items
 */
export const getManyMembershipOfferingItems = (
    params?: GetManyMembershipOfferingItemsParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<MembershipOfferingItems>(
      {url: `/membership-offering-items`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetManyMembershipOfferingItemsQueryKey = (params?: GetManyMembershipOfferingItemsParams,) => [`/membership-offering-items`, ...(params ? [params]: [])];

    
export type GetManyMembershipOfferingItemsQueryResult = NonNullable<Awaited<ReturnType<typeof getManyMembershipOfferingItems>>>
export type GetManyMembershipOfferingItemsQueryError = ErrorType<void | ValidationErrors>

export const useGetManyMembershipOfferingItems = <TData = Awaited<ReturnType<typeof getManyMembershipOfferingItems>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetManyMembershipOfferingItemsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getManyMembershipOfferingItems>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetManyMembershipOfferingItemsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getManyMembershipOfferingItems>>> = ({ signal }) => getManyMembershipOfferingItems(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getManyMembershipOfferingItems>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Posts a new Membership Offering Item
 */
export const postMembershipOfferingItem = (
    postMembershipOfferingItemBody: PostMembershipOfferingItemBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipOfferingItem>(
      {url: `/membership-offering-items`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postMembershipOfferingItemBody
    },
      options);
    }
  


    export type PostMembershipOfferingItemMutationResult = NonNullable<Awaited<ReturnType<typeof postMembershipOfferingItem>>>
    export type PostMembershipOfferingItemMutationBody = PostMembershipOfferingItemBody
    export type PostMembershipOfferingItemMutationError = ErrorType<void | ValidationErrors>

    export const usePostMembershipOfferingItem = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMembershipOfferingItem>>, TError,{data: PostMembershipOfferingItemBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMembershipOfferingItem>>, {data: PostMembershipOfferingItemBody}> = (props) => {
          const {data} = props ?? {};

          return  postMembershipOfferingItem(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMembershipOfferingItem>>, TError, {data: PostMembershipOfferingItemBody}, TContext>(mutationFn, mutationOptions)
    }
    