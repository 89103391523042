import { Argument } from 'classnames';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { FC } from 'react';

export type THVideoProps = {
  className?: Argument;
  src?: string;
  extension?: string;
};

export const THVideo: FC<NebPropsWithStd<THVideoProps>> = ({ className, src, extension }) => {
  return (
    <video controls>
      <source src={src} type={extension} />
      Your browser does not support the video tag.
    </video>
  );
};
