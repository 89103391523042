import { Navigate, useLocation } from 'react-router-dom';
import { createRequireGuestGuard } from '@villageco/nebula/auth';
import { FC } from 'react';

const AuthedNavigate: FC<{}> = () => {
  const location = useLocation();
  return <Navigate to="/" state={{ from: location }} replace />;
};

const { RequireGuestGuard } = createRequireGuestGuard({ authenticatedElement: AuthedNavigate });

export { RequireGuestGuard };
