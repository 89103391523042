import classNames, { Argument } from 'classnames';
import { SidebarLink } from '@villageco/nebula/internal';
import { ElementType, FunctionComponent } from 'react';
import { Link, useMatch } from 'react-router-dom';

export type BiteySidebarLinkProps = {
  icon?: ElementType;
  to: string;
  // className?: Argument;
  inGroup?: boolean;
  styles?: {
    bgColor?: Argument;
    textColor?: Argument;
  };
};

export const BiteySidebarLink: FunctionComponent<BiteySidebarLinkProps> = ({ icon, to, inGroup, styles, children }) => {
  const match = useMatch(to);

  return (
    <SidebarLink
      to={to}
      icon={icon}
      inGroup={inGroup}
      linkComponent={Link}
      styles={{
        textColor: classNames(styles?.textColor, { 'text-gray-600': !match, 'text-th-bitey-500': match }),
      }}
    >
      {children}
    </SidebarLink>
  );
};
