import classNames from 'classnames';
import { merge } from 'lodash-es';
import { useMemo } from 'react';
import { NebPropsWithStd } from '../../../core';
import { FormConfig } from '../../contexts';
import { useFormState } from '../../hooks';
import { FormErrorMessage } from '../common/form-error-message';
import { Label } from '../common/label';
import { TagsInput, TagsInputProps } from '../inputs/tags-input';
import { BaseFieldProps } from './types';

export type TagsProps<T> = TagsInputProps &
  BaseFieldProps<T> & {
    hideInput?: boolean;
    iconSize?: number;
    inline?: boolean;
  };

export const Tags = <T,>({
  name,
  inline,
  className,
  classes,
  label,
  optional,
  hideInput,
  children,
  disabled,
  prefix,
  initialTags,
  onChange,
  value,
  validationErrorKey,
  iconSize = 20,
  ...rest
}: NebPropsWithStd<TagsProps<T>>) => {
  const { getFieldErrors, getFieldValue, setFieldValue, validationErrors, config, hasFieldChanged } =
    useFormState() ?? {};

  const { defaultClassConfig } = config ? config : ({} as FormConfig<any>);

  const handleChange = (value: string[]) => {
    onChange && onChange(value);
    setFieldValue && setFieldValue(name, value);
  };

  const cc = merge({}, defaultClassConfig, classes);

  const fieldErrors = getFieldErrors && useMemo(() => getFieldErrors(validationErrorKey ?? name), [validationErrors]);
  const hasErrors = useMemo(() => fieldErrors && !!fieldErrors.length, [fieldErrors]);

  const hasChanged = hasFieldChanged ? hasFieldChanged(name) : false;

  const fieldValue = (getFieldValue && getFieldValue(name)) ?? [];

  return (
    <div className={classNames(className, cc?.container, 'tags-field')}>
      <div aria-live="polite" className="w-full">
        <Label label={label} optional={optional} labelClassConfig={cc?.label} />
        <div className={cc?.field?.container ?? 'w-full flex flex-col'}>
          {prefix && <>{prefix}</>}

          <TagsInput
            className={classNames(
              cc?.override?.tags?.element ??
                (inline === undefined
                  ? cc?.field?.element
                  : inline
                  ? 'flex items-center group w-full grow-0 overflow-x-scroll'
                  : 'flex flex-col group p-2'),

              {
                [cc?.field?.ready ?? '']: !hasErrors && !disabled && !hasChanged,
                [cc?.field?.changed ?? '']: hasChanged && !disabled && !hasErrors,
                [cc?.field?.error ?? '']: hasErrors,
                'invisible absolute': hideInput,
                [cc?.field?.disabled ?? '']: disabled,
              },
            )}
            tagsContainerClassName={
              cc?.override?.tags?.tagsContainer ?? (inline ? undefined : 'flex flex-wrap space-y-1 pt-1 items-end')
            }
            inputClassName={
              cc?.override?.tags?.input ?? (inline ? undefined : 'my-2 bg-transparent w-full outline-none')
            }
            tagCloseIcon={cc.tagOptions?.closeIconElement}
            tagLabelClassName={cc.tagOptions?.label}
            tagIconClassName={cc.tagOptions?.closeIcon}
            tagClassName={cc.tagOptions?.tag}
            onChange={handleChange}
            initialTags={initialTags}
            value={value ?? fieldValue}
            {...rest}
          />
        </div>
      </div>
      {fieldErrors &&
        Array.isArray(fieldErrors) &&
        fieldErrors.map((e) => <FormErrorMessage className={cc?.error}>{e}</FormErrorMessage>)}
    </div>
  );
};
