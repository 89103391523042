import { FC } from 'react';
import classNames from 'classnames';
import { useFormState } from '../../hooks';
import { HiCheckCircle } from 'react-icons/hi';
import { FormEvent } from '../../contexts';
import { NebPropsWithStd } from '@villageco/nebula/core';

export type FormSuccessMessageProps = {
  className?: string;
};

export const FormSuccessMessage: FC<NebPropsWithStd<FormSuccessMessageProps>> = ({ children, className }) => {
  const { config } = useFormState();

  return (
    <p
      className={classNames(
        'text-md font-semibold text-left text-green-600 bg-green-200 rounded p-2 flex items-center',
        className ?? config.defaultClassConfig?.success,
      )}
    >
      <>
        <HiCheckCircle className="text-xl mr-3"></HiCheckCircle>
        {children}
      </>
    </p>
  );
};
