import { FC, ReactElement } from 'react';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { DashLayout as NebDashLayout, DashLayoutProps as NebDashLayoutProps } from '@villageco/nebula/internal';
import { NavLogo } from '@components/dashboard/nav-logo';
import { EcommerceNavLink } from '@ecommerce';
import { MembershipNavLink } from '@membership';
import { Outlet } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { coreStore } from '@core/stores/core-store';
import { ModuleHeader } from '@components/dashboard/module-header';
import { BiteyNavLink } from '@bitey';

export type DashLayoutProps = {
  sidebarContent: ReactElement;
  sidebarStyles?: NebDashLayoutProps['sidebarStyles'];
};

export const DashLayout: FC<NebPropsWithStd<DashLayoutProps>> = ({ sidebarContent, sidebarStyles }) => {
  const { dashboardHeader, isModuleHome, headerPanelClassName } = useSnapshot(coreStore);

  return (
    <NebDashLayout
      navbarLogo={<NavLogo></NavLogo>}
      navbarChildren={
        <>
          <EcommerceNavLink className="ml-4"></EcommerceNavLink>
          <MembershipNavLink className="ml-2"></MembershipNavLink>
          <BiteyNavLink className="ml-2"></BiteyNavLink>
        </>
      }
      sidebarChildren={sidebarContent}
      sidebarStyles={sidebarStyles}
    >
      <div className="flex flex-col w-full h-full">
        {dashboardHeader ? (
          <ModuleHeader isHome={isModuleHome} panelClassName={headerPanelClassName}>
            {dashboardHeader}
          </ModuleHeader>
        ) : null}
        <div className="flex-1 min-h-0 flex">
          <Outlet />
        </div>
      </div>
    </NebDashLayout>
  );
};
