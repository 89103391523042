import classNames, { Argument } from 'classnames';
import { ElementType, FunctionComponent } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { foyerState } from '../stores';

export type PageLinkTileProps = {
  className?: Argument;
  styles?: {
    bgColor?: Argument;
    textColor?: Argument;
    fontSize?: Argument;
  };
  icon: ElementType;
  href: string;
  external?: boolean;
};

export const PageLinkTile: FunctionComponent<PageLinkTileProps> = ({
  className,
  styles,
  icon: Icon,
  href,
  children,
  external,
}) => {
  const link = (
    <div
      className={classNames(
        className,
        styles?.bgColor,
        'page-link-tile w-32 h-32 bg-gray-200 rounded-lg flex flex-col justify-around items-center relative group transition-colors duration-300',
      )}
    >
      <Icon className={classNames(styles?.textColor, 'text-7xl -mb-4')} />
      <span
        className={classNames(
          styles?.textColor,
          { 'text-xl': !styles?.fontSize },
          styles?.fontSize,
          'font-medium w-full -mb-1 text-center',
        )}
      >
        {children}
      </span>
      {external ? <FiExternalLink className={classNames(styles?.textColor, 'absolute top-2 right-2')} /> : null}
    </div>
  );

  return external ? (
    <a target={external ? '_blank' : undefined} href={href}>
      {link}
    </a>
  ) : (
    <Link
      to={href}
      onClick={() => {
        foyerState.showFoyer = false;
      }}
    >
      {link}
    </Link>
  );
};
