export const useStringDateTimeFormatter = <U extends string | null | undefined>(
  date?: U,
  locales?: string,
  format?: Intl.DateTimeFormatOptions,
): {
  rawDate: U extends string ? Date : undefined;
  formattedDate: U extends string ? string : undefined;
} => {
  const rawDate = date ? new Date(Date.parse(date)) : undefined;

  const formattedDate = rawDate && `${new Intl.DateTimeFormat(locales, format).format(rawDate)}`;

  return { rawDate, formattedDate } as any;
};
