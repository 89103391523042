import { useTimeGreeting } from '@villageco/nebula';
import { FC, useEffect } from 'react';
import { BsShop, BsStar } from 'react-icons/bs';
import { MdSupport } from 'react-icons/md';

import { useCurrentAuthenticatedUser } from '@villageco/nebula/auth';
import { useMatch } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import logoUrl from '../../../assets/TownHall-03.png';
import crmLogoUrl from '../../../assets/zoho-crm-icon.png';
import invLogoUrl from '../../../assets/zoho-inventory-icon.png';
import { Fade } from '../../../components/transitions/fade';
import { foyerState } from '../stores';
import { PageLinkTile } from './page-link-tile';
import Clock from './clock';
import { MembershipRoutePaths } from '@membership';
import { BiteyRoutePaths } from '@bitey';
import { AiOutlineLink } from 'react-icons/ai';

export type FoyerProps = {};

export const Foyer: FC<FoyerProps> = () => {
  const matchFoyer = useMatch('/');
  const { user } = useCurrentAuthenticatedUser();

  const foyerSnap = useSnapshot(foyerState);

  useEffect(() => {
    foyerState.showFoyer = !!matchFoyer;
  }, [matchFoyer]);

  const greeting = useTimeGreeting();

  const name = (user as any)?.attributes?.name ? (user as any)?.attributes?.name.split(' ')[0] : '';

  return (
    <Fade in={foyerSnap.showFoyer && !!user} duration={300} className="absolute z-40 w-screen h-screen">
      <div className="foyer bg-vc-light-brown w-full h-full overflow-hidden flex flex-col">
        <div className="h-36">
          <div className="flex h-full items-center px-10">
            {/* {matchFoyer ? null : (
            <button
              onClick={(e) => {
                e.preventDefault;
                foyerState.showFoyer = false;
              }}
              className="pt-16 h-12 flex items-center  underline text-green-800 "
            >
              <FiArrowLeftCircle className="mr-1 font-semibold text-3xl"></FiArrowLeftCircle>
            </button>
          )} */}
          </div>
        </div>

        <main className="px-10">
          <h1 className="font-title text-5xl font-bold text-vc-dark-green">
            {greeting} {name}
          </h1>
          <div className="mt-8 flex">
            {/* <PageLinkTile
            icon={FiLink}
            href="/bitey"
            styles={{
              bgColor: 'shadow bg-orange-100 hover:bg-orange-200',
              textColor: 'text-orange-500 group-hover:text-orange-700',
            }}
          >
            Bitey
          </PageLinkTile> */}
            <PageLinkTile
              // className="ml-4"
              icon={BsShop}
              href="/ecommerce"
              styles={{
                bgColor: 'shadow bg-th-ecommerce-100 hover:bg-th-ecommerce-200',
                textColor: 'text-th-ecommerce-500 group-hover:text-th-ecommerce-700',
              }}
            >
              Ecommerce
            </PageLinkTile>
            <PageLinkTile
              className="ml-4"
              icon={BsStar}
              href={MembershipRoutePaths.ROOT}
              styles={{
                fontSize: 'text-lg',

                bgColor: 'shadow bg-th-membership-100 hover:bg-th-membership-200',
                textColor: 'text-th-membership-500 group-hover:text-th-membership-700',
              }}
            >
              Membership
            </PageLinkTile>
            <PageLinkTile
              className="ml-4"
              icon={AiOutlineLink}
              href={BiteyRoutePaths.ROOT}
              styles={{
                fontSize: 'text-lg',

                bgColor: 'shadow bg-th-bitey-100 hover:bg-th-bitey-200',
                textColor: 'text-th-bitey-500 group-hover:text-th-bitey-700',
              }}
            >
              Bitey
            </PageLinkTile>
            {/* <PageLinkTile
            className="ml-4"
            icon={FiLink}
            href="/lument"
            styles={{
              bgColor: 'shadow bg-fuchsia-100 hover:bg-fuchsia-200',
              textColor: 'text-fuchsia-500 group-hover:text-fuchsia-700',
            }}
          >
            Lument
          </PageLinkTile> */}
          </div>

          <div className="mt-8">
            <h3 className="font-title font-medium text-xl text-vc-dark-green">External Services</h3>
            <div className="flex mt-4">
              <PageLinkTile
                icon={MdSupport}
                href="https://villageco.atlassian.net/servicedesk/customer/portal/2"
                external={true}
                styles={{
                  bgColor: 'bg-white hover:bg-blue-200',
                  textColor: 'text-blue-700 group-hover:text-blue-800',
                  fontSize: 'text-md',
                }}
              >
                Support Portal
              </PageLinkTile>
              <PageLinkTile
                className="ml-4"
                icon={() => <img className="w-16 rounded" src={crmLogoUrl}></img>}
                href="https://crm.zoho.com.au/crm/org7000730364/tab/Home/begin"
                external={true}
                styles={{
                  bgColor: 'bg-white hover:bg-sky-200',
                  textColor: 'text-sky-600 group-hover:text-sky-700',
                }}
              >
                CRM
              </PageLinkTile>
              <PageLinkTile
                className="ml-4"
                icon={() => <img className="w-16 rounded" src={invLogoUrl}></img>}
                href="https://inventory.zoho.com.au/app/7000671603#/home/inventory-dashboard"
                external={true}
                styles={{
                  bgColor: 'bg-white hover:bg-red-200',
                  textColor: 'text-red-700 group-hover:text-red-800',
                  // fontSize: 'text-lg',
                }}
              >
                Inventory
              </PageLinkTile>
            </div>
          </div>
        </main>
        <footer className="mt-auto flex w-full p-10">
          <div className="w-full flex items-end">
            <img src={logoUrl} className="h-16 ml-2" />
          </div>
          <div className="ml-auto">
            <Clock></Clock>
          </div>
        </footer>
      </div>
    </Fade>
  );
};
