import { FC, useEffect, useState } from 'react';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { setPageMeta } from '@core/stores/core-store';
import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import { useGetManyMemberships } from '@core/clients/ecommerce-services-admin/memberships/memberships';
import { Membership } from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import { MembershipList } from '../../components/memberships/membership-list';
import { membershipState } from '../../stores';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { BiPlus } from 'react-icons/bi';
import { MembershipRoutePaths } from '../../router/membership-routes';
import { ThScrollPage } from '@components/ui/th-scroll-page';

export type MembershipsIndexPageProps = {};

export const MembershipsIndexPage: FC<NebPropsWithStd<MembershipsIndexPageProps>> = () => {
  setPageMeta('Memberships', false);

  const [searchParams] = useSearchParams();

  const activeTab = searchParams.get('activeTab') ?? undefined;

  const { data, status, queryKey } = useGetManyMemberships({
    include: { account: true, membershipFrequency: { include: { productVariant: true } } },
  });

  membershipState.membershipsIndexQueryKey = queryKey;

  const categories = {
    'Active Memberships': data?.items?.filter((m) => !m.endAt || Date.parse(m.endAt) > Date.now()) ?? [],
    'Expired Memberships': data?.items?.filter((m) => m.endAt && Date.parse(m.endAt) < Date.now()) ?? [],
  };

  return (
    <ThScrollPage id="membership-index-page">
      <div className="rounded w-full bg-th-membership-50 p-2 flex mb-2">
        <Link
          to={`${MembershipRoutePaths.MEMBERSHIP_DETAILS}new`}
          className={classNames(
            'ml-auto font-medium rounded bg-th-membership-500 text-white w-28 justify-evenly flex items-center h-10',
          )}
        >
          <BiPlus className="" />
          Add
        </Link>
      </div>
      <Tab.Group defaultIndex={activeTab === 'expired' ? 1 : 0}>
        <Tab.List className="flex space-x-1 rounded-xl bg-th-membership-100 p-1">
          {Object.keys(categories).map((cat) => (
            <Tab
              key={cat}
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5 transition-all duration-300 focus:outline-none',
                  selected
                    ? 'bg-th-membership-200 text-th-membership-600 font-black shadow-sm'
                    : 'text-th-membership-500 hover:bg-white/[0.12] hover:text-th-membership-900',
                )
              }
            >
              {cat}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="w-full h-full flex mt-2">
          {Object.values(categories).map((mems, index) => (
            <Tab.Panel key={`memberships-${index}`} className="w-full flex">
              <div className="flex p-2 rounded bg-th-membership-50 h-full w-full">
                <MembershipList memberships={mems} className="w-full"></MembershipList>
              </div>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </ThScrollPage>
  );
};
