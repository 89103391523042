import { PropsWithChildren, ReactElement, useState } from 'react';
import { FC } from 'react';
import { useCurrentAuthenticatedUser } from '../hooks/use-current-authenticated-user';

export type CreateRequireAuthGuardProps = {
  unauthenticatedElement: FC;
};

export type RequireAuthGuardProps = {
  unauthenticatedElement?: FC;
};

export const createRequireAuthGuard = ({ unauthenticatedElement: defaultUnauth }: CreateRequireAuthGuardProps) => {
  const RequireAuthGuard: FC<PropsWithChildren<RequireAuthGuardProps>> = ({ children, unauthenticatedElement }) => {
    const UnauthEl = unauthenticatedElement ?? defaultUnauth;

    const { loading, user } = useCurrentAuthenticatedUser();

    return loading ? <></> : user ? (children as ReactElement) : <UnauthEl></UnauthEl>;
  };

  return {
    RequireAuthGuard,
  };
};
