import { isUnauthedError } from './../helpers/isUnauthedError';
import { CognitoUser, Auth } from '@aws-amplify/auth';
import { HubCapsule } from '@aws-amplify/core';
import { Hub } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { CognitoUserAttributes } from '../og/types';
import { CognitoIdentityUser } from '../types';

export const useCurrentAuthenticatedUser = <
  U extends CognitoUser | CognitoIdentityUser = CognitoUser | CognitoIdentityUser,
>() => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<U | null>(null);
  const [attributes, setAttributes] = useState<CognitoUserAttributes | null>(null);

  useEffect(() => {
    const syncCurrentUser = async () => {
      setLoading(true);

      try {
        const user = await Auth.currentAuthenticatedUser();

        // console.log(`USER`);
        // console.log(user);

        // const session = await Auth.currentSession();

        // console.log(session);

        setUser(user);
        setAttributes(user.attributes);
        setLoading(false);
      } catch (error) {
        if (!isUnauthedError(error)) throw error;
        setUser(null);
        setAttributes(null);
        setLoading(false);
      }
    };

    const authListener = (cap: HubCapsule) => {
      if (cap.channel !== 'auth') {
        throw new Error('Auth listener expects auth channel, probably misconfigured');
      }

      switch (cap.payload.event) {
        case 'signIn':
          syncCurrentUser();
          break;
        case 'signUp':
          syncCurrentUser();
          break;
        case 'signOut':
          setUser(null);
          setAttributes(null);
          break;
        default:
          break;
      }
    };

    Hub.listen('auth', authListener);

    syncCurrentUser();

    return () => {
      Hub.remove('auth', authListener);
    };
  }, []);

  return { loading, user, attributes };
};
