/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * admin-rest-api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  SalesOrder,
  ValidationErrors,
  GetSalesOrderByIdParams,
  PatchSalesOrderByIdBody,
  DeleteSalesOrderByIdBody,
  PatchRestoreSalesOrderByIdBody,
  DeleteDestroySalesOrderByIdBody,
  GetOneSalesOrderParams,
  SalesOrders,
  GetManySalesOrdersParams,
  PostSalesOrderBody
} from '../ecommerce-services.schemas'
import { ecommerceAdminApiInstance } from '../../../apis/ecommerce-admin-api-instance'
import type { ErrorType } from '../../../apis/ecommerce-admin-api-instance'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Gets one Sales Order by Id in path
 */
export const getSalesOrderById = (
    id: string,
    params?: GetSalesOrderByIdParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<SalesOrder>(
      {url: `/sales-order/${id}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetSalesOrderByIdQueryKey = (id: string,
    params?: GetSalesOrderByIdParams,) => [`/sales-order/${id}`, ...(params ? [params]: [])];

    
export type GetSalesOrderByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getSalesOrderById>>>
export type GetSalesOrderByIdQueryError = ErrorType<void | ValidationErrors>

export const useGetSalesOrderById = <TData = Awaited<ReturnType<typeof getSalesOrderById>>, TError = ErrorType<void | ValidationErrors>>(
 id: string,
    params?: GetSalesOrderByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSalesOrderById>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSalesOrderByIdQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalesOrderById>>> = ({ signal }) => getSalesOrderById(id,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSalesOrderById>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Patches one Sales Order by Id in path
 */
export const patchSalesOrderById = (
    id: string,
    patchSalesOrderByIdBody: PatchSalesOrderByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<SalesOrder>(
      {url: `/sales-order/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchSalesOrderByIdBody
    },
      options);
    }
  


    export type PatchSalesOrderByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchSalesOrderById>>>
    export type PatchSalesOrderByIdMutationBody = PatchSalesOrderByIdBody
    export type PatchSalesOrderByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchSalesOrderById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchSalesOrderById>>, TError,{id: string;data: PatchSalesOrderByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchSalesOrderById>>, {id: string;data: PatchSalesOrderByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchSalesOrderById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchSalesOrderById>>, TError, {id: string;data: PatchSalesOrderByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Deletes one Sales Order by Id in path
 */
export const deleteSalesOrderById = (
    id: string,
    deleteSalesOrderByIdBody: DeleteSalesOrderByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<SalesOrder>(
      {url: `/sales-order/${id}`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteSalesOrderByIdBody
    },
      options);
    }
  


    export type DeleteSalesOrderByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSalesOrderById>>>
    export type DeleteSalesOrderByIdMutationBody = DeleteSalesOrderByIdBody
    export type DeleteSalesOrderByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteSalesOrderById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSalesOrderById>>, TError,{id: string;data: DeleteSalesOrderByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSalesOrderById>>, {id: string;data: DeleteSalesOrderByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteSalesOrderById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteSalesOrderById>>, TError, {id: string;data: DeleteSalesOrderByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Restores one Sales Order by Id in path
 */
export const patchRestoreSalesOrderById = (
    id: string,
    patchRestoreSalesOrderByIdBody: PatchRestoreSalesOrderByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<SalesOrder>(
      {url: `/sales-order/${id}/restore`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchRestoreSalesOrderByIdBody
    },
      options);
    }
  


    export type PatchRestoreSalesOrderByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchRestoreSalesOrderById>>>
    export type PatchRestoreSalesOrderByIdMutationBody = PatchRestoreSalesOrderByIdBody
    export type PatchRestoreSalesOrderByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchRestoreSalesOrderById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchRestoreSalesOrderById>>, TError,{id: string;data: PatchRestoreSalesOrderByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchRestoreSalesOrderById>>, {id: string;data: PatchRestoreSalesOrderByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchRestoreSalesOrderById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchRestoreSalesOrderById>>, TError, {id: string;data: PatchRestoreSalesOrderByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Destroys one Sales Order by Id in path
 */
export const deleteDestroySalesOrderById = (
    id: string,
    deleteDestroySalesOrderByIdBody: DeleteDestroySalesOrderByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<SalesOrder>(
      {url: `/sales-order/${id}/destroy`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteDestroySalesOrderByIdBody
    },
      options);
    }
  


    export type DeleteDestroySalesOrderByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDestroySalesOrderById>>>
    export type DeleteDestroySalesOrderByIdMutationBody = DeleteDestroySalesOrderByIdBody
    export type DeleteDestroySalesOrderByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteDestroySalesOrderById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDestroySalesOrderById>>, TError,{id: string;data: DeleteDestroySalesOrderByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDestroySalesOrderById>>, {id: string;data: DeleteDestroySalesOrderByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteDestroySalesOrderById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteDestroySalesOrderById>>, TError, {id: string;data: DeleteDestroySalesOrderByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Get one Sales Orders
 */
export const getOneSalesOrder = (
    params?: GetOneSalesOrderParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<SalesOrder>(
      {url: `/sales-order`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetOneSalesOrderQueryKey = (params?: GetOneSalesOrderParams,) => [`/sales-order`, ...(params ? [params]: [])];

    
export type GetOneSalesOrderQueryResult = NonNullable<Awaited<ReturnType<typeof getOneSalesOrder>>>
export type GetOneSalesOrderQueryError = ErrorType<void | ValidationErrors>

export const useGetOneSalesOrder = <TData = Awaited<ReturnType<typeof getOneSalesOrder>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetOneSalesOrderParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOneSalesOrder>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOneSalesOrderQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneSalesOrder>>> = ({ signal }) => getOneSalesOrder(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOneSalesOrder>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Get many Sales Orders
 */
export const getManySalesOrders = (
    params?: GetManySalesOrdersParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<SalesOrders>(
      {url: `/sales-orders`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetManySalesOrdersQueryKey = (params?: GetManySalesOrdersParams,) => [`/sales-orders`, ...(params ? [params]: [])];

    
export type GetManySalesOrdersQueryResult = NonNullable<Awaited<ReturnType<typeof getManySalesOrders>>>
export type GetManySalesOrdersQueryError = ErrorType<void | ValidationErrors>

export const useGetManySalesOrders = <TData = Awaited<ReturnType<typeof getManySalesOrders>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetManySalesOrdersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getManySalesOrders>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetManySalesOrdersQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getManySalesOrders>>> = ({ signal }) => getManySalesOrders(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getManySalesOrders>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Posts a new Sales Order
 */
export const postSalesOrder = (
    postSalesOrderBody: PostSalesOrderBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<SalesOrder>(
      {url: `/sales-orders`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postSalesOrderBody
    },
      options);
    }
  


    export type PostSalesOrderMutationResult = NonNullable<Awaited<ReturnType<typeof postSalesOrder>>>
    export type PostSalesOrderMutationBody = PostSalesOrderBody
    export type PostSalesOrderMutationError = ErrorType<void | ValidationErrors>

    export const usePostSalesOrder = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postSalesOrder>>, TError,{data: PostSalesOrderBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postSalesOrder>>, {data: PostSalesOrderBody}> = (props) => {
          const {data} = props ?? {};

          return  postSalesOrder(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postSalesOrder>>, TError, {data: PostSalesOrderBody}, TContext>(mutationFn, mutationOptions)
    }
    