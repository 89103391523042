import { RouteObject } from 'react-router';
import { EcommerceSidebarMenu } from '../components/core/ecommerce-sidebar-menu';
import { EcommerceHomePage } from '../pages';
import { ShipmentRunsIndexPage } from '../pages/shipment-runs';
import { ShipmentRunDetailsPage } from '../pages/shipment-runs/[id]';
import { ItemsIndexPage } from '../pages/items';
import { ItemDetailsPage } from '../pages/items/[id]';
import { OrdersIndexPage } from '../pages/orders';
import { OrderDetailsPage } from '../pages/orders/[id]';
import { ProductsIndexPage } from '../pages/products';
import { ProductDetailsPage } from '../pages/products/[id]';

export enum EcommerceRoutePaths {
  ROOT = '/ecommerce',
  PRODUCTS_INDEX = '/ecommerce/products',
  PRODUCT_DETAILS = '/ecommerce/product/',
  ITEMS_INDEX = '/ecommerce/items',
  ITEM_DETAILS = '/ecommerce/item/',
  ORDERS_INDEX = '/ecommerce/orders',
  ORDER_DETAILS = '/ecommerce/order/',
  SHIPMENT_RUNS_INDEX = '/ecommerce/shipment-runs',
  SHIPMENT_RUN_DETAILS = '/ecommerce/shipment-run/',
}

export const ecommerceRoutes: RouteObject[] = [
  {
    path: '/ecommerce',
    children: [
      { path: 'products', element: <ProductsIndexPage /> },
      {
        path: 'product/:id',
        element: <ProductDetailsPage />,
      },

      { path: 'orders', element: <OrdersIndexPage /> },
      {
        path: 'order/:id',
        element: <OrderDetailsPage />,
      },

      { path: 'items', element: <ItemsIndexPage /> },
      {
        path: 'item/:id',
        element: <ItemDetailsPage />,
      },

      { path: 'shipment-runs', element: <ShipmentRunsIndexPage /> },
      {
        path: 'shipment-run/:id',
        element: <ShipmentRunDetailsPage />,
      },
      { index: true, element: <EcommerceHomePage /> },
    ],
  },
];

export const ecommerceSidebarRoutes: RouteObject[] = [{ path: '/ecommerce/*', element: <EcommerceSidebarMenu /> }];
