import { Login } from '../pages/login';
import { AuthLayout } from '../layouts/auth-layout';
import { FC, PropsWithChildren } from 'react';
import { RouteObject } from 'react-router';
import { useRoutes } from 'react-router-dom';
import { RequireGuestGuard } from '@components/guards/require-guest-guard';

export enum AuthRoutePaths {
  ROOT = '/auth',
  LOGIN = '/auth/login',
}

export const authRoutes: RouteObject[] = [
  {
    path: '/auth',
    element: (
      <RequireGuestGuard>
        <AuthLayout />
      </RequireGuestGuard>
    ),
    children: [{ path: 'login', element: <Login /> }],
  },
];
