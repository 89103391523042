import { useContext } from 'react';
import { FormState } from '../contexts';
import { formContext } from '../contexts/form-context';

export const useFormState = () => {
  const context = useContext(formContext);

  if (!context) return {} as FormState<any>; // throw new Error("Can only use 'useFormState' inside a 'Form' component sub-tree");

  return context;
};
