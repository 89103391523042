import React, { FC, forwardRef, DetailedHTMLProps, InputHTMLAttributes, Ref } from 'react';
import classNames, { Argument } from 'classnames';
import { createNoSubstitutionTemplateLiteral } from 'typescript';

export type InputProps = {
  id?: string;
  name: string;
  className?: Argument;
  hasError?: boolean;
  ariaLabel?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
} & Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'size' | 'onChange'>;

export type InputPropsRef = {
  ref?: Ref<HTMLInputElement>;
};

export const Input: FC<InputProps & InputPropsRef> = forwardRef<HTMLInputElement, InputProps>(
  ({ id, name, ariaLabel, type = 'text', className, placeholder, onChange, ...rest }, ref) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event.currentTarget.value);
      }
    };

    return (
      <input
        id={id}
        ref={ref}
        name={name}
        type={type}
        aria-label={ariaLabel}
        placeholder={placeholder}
        className={classNames('', className)}
        value={rest.value}
        key={rest?.key}
        onChange={handleChange}
      />
    );
  },
);
