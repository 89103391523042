import React from 'react';

export const useClickOutside = (ref?: HTMLDivElement | null, callback?: () => void) => {
  const handleClick = (e: any) => {
    if (ref && !ref.contains(e.target) && callback) {
      callback();
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};
