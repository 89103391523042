import { usePostImportAccountsFromZohoCrm } from '@core/clients/ecommerce-services-admin/accounts/accounts';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { ChangeEvent, FC, MouseEvent, useState } from 'react';
import { BiReset } from 'react-icons/bi';
import { BsPlus } from 'react-icons/bs';

export type AccountImportDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const AccountImportDialog: FC<NebPropsWithStd<AccountImportDialogProps>> = ({ isOpen, onClose }) => {
  const [ids, setIds] = useState<string[]>([]);
  const [currentId, setCurrentId] = useState('');
  const [invalidError, setInvalidError] = useState('');
  const [showCopied, setShowCopied] = useState(false);

  const { mutateAsync, isLoading, data, error, isError, reset } = usePostImportAccountsFromZohoCrm();

  const addClicked = (evt: MouseEvent) => {
    evt.preventDefault();
    setInvalidError('');

    if (currentId && !ids.includes(currentId) && currentId.length === 17 && /^[0-9]+$/.test(currentId)) {
      setIds([...ids, currentId]);
    } else if (currentId && !ids.includes(currentId)) {
      setInvalidError(`ID '${currentId}' is invalid. Only Zoho CRM IDs like '15949000000190029' are valid`);
    }
    setCurrentId('');
  };

  const resetClicked = (evt: MouseEvent) => {
    evt.preventDefault();
    reset();
    setIds([]);
    setInvalidError('');
  };

  const dialogClosed = () => {
    setInvalidError('');
    onClose();
  };

  const changedInput = (evt: ChangeEvent<HTMLInputElement>) => {
    setCurrentId(evt.target.value);
  };

  const closeClicked = (evt: MouseEvent) => {
    evt.preventDefault();
    dialogClosed();
  };

  const startImportClicked = async (evt: MouseEvent) => {
    evt.preventDefault();
    await mutateAsync({ data: { ids } });
  };

  const outputClicked = (evt: MouseEvent) => {
    evt.preventDefault();
    if (canCopy) {
      navigator.clipboard.writeText(JSON.stringify(data));
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 2000);
    }
  };

  const canCopy = data && navigator.clipboard;

  const displayError =
    (error?.response?.status ?? 0) >= 500
      ? 'An error has occurrred on the server. Get Mitch or Chris P. ERROR CODE: FUCK'
      : JSON.stringify(error?.response?.data, null, 2);

  return (
    <div className="account-import-modal">
      <Dialog onClose={dialogClosed} open={isOpen}>
        <div className="fixed inset-0 bg-black/30 z-40" aria-hidden="true" />

        <div className="z-50 fixed inset-0 p-16 py-32 flex justify-center items-center">
          <Dialog.Panel className=" bg-white rounded w-full h-full p-8 ">
            <Dialog.Title className="mb-4 font-semibold text-2xl text-gray-700">Import Accounts From CRM</Dialog.Title>
            <Dialog.Description className="text-gray-400">Add Zoho CRM IDs to import below</Dialog.Description>
            <div className="mt-4">
              <div>
                <div className="flex">
                  <div className="h-60 overflow-auto rounded border p-4 w-96 shrink-0">
                    {ids.map((i) => (
                      <p className="bg-blue-500 mb-1 text-white font-medium rounded p-1" key={i}>
                        {i}
                      </p>
                    ))}
                  </div>
                  <div className="relative w-full" onClick={outputClicked}>
                    <div
                      className={classNames(
                        {
                          'active:bg-blue-100 hover:cursor-pointer': canCopy,
                          'text-gray-500': !isError,
                          'text-red-600': isError,
                        },
                        'w-full h-60 border rounded ml-4 p-4 overflow-auto whitespace-pre',
                      )}
                    >
                      {data && JSON.stringify(data, null, 2)}
                      {error && displayError}
                    </div>
                    <Transition
                      show={showCopied}
                      enter="transition-opacity duration-150"
                      enterFrom="opacity-0"
                      enterTo="opacity-30"
                      leave="transition-opacity duration-200"
                      leaveFrom="opacity-30"
                      leaveTo="opacity-0"
                    >
                      <span
                        className={classNames(
                          'transition-[display] duration-300 absolute  bg-black text-white py-1 px-2 rounded bottom-2 -right-2 mx-auto',
                        )}
                      >
                        Copied to clipboard
                      </span>
                    </Transition>
                    {canCopy && (
                      <p className="absolute right-1 -bottom-7 font-thin text-gray-400">Click output to copy</p>
                    )}
                  </div>
                </div>

                <div className="flex mt-4">
                  <input
                    type="text"
                    className="border rounded w-72 h-10 "
                    disabled={isLoading && !isError}
                    value={currentId}
                    onChange={changedInput}
                  ></input>
                  <button
                    onClick={addClicked}
                    disabled={isLoading && !isError}
                    className={classNames(
                      { 'bg-th-membership-300': isLoading && !isError },
                      'h-10 bg-th-membership-500 text-white rounded w-24 ml-2 flex items-center justify-center',
                    )}
                  >
                    <BsPlus className="font-semibold text-xl mr-2"></BsPlus>
                    Add ID
                  </button>
                  <button
                    onClick={resetClicked}
                    disabled={isLoading && !isError}
                    className={classNames(
                      { 'bg-th-membership-300': isLoading && !isError },
                      'h-10 border border-th-membership-500 text-th-membership-500 rounded w-24 ml-2 flex items-center justify-center',
                    )}
                  >
                    <BiReset className="font-semibold text-xl mr-2"></BiReset>
                    Reset
                  </button>
                </div>
                <p className="h-4 text-red-700 mt-2">{!isLoading && invalidError}</p>
              </div>

              <div className="w-full flex mt-8">
                <button
                  className={classNames(
                    {
                      'border-gray-300 text-gray-300': isLoading && !isError,
                      'border-gray-600': !isLoading,
                    },
                    'h-10 w-52 rounded border text-gray-600 font-medium',
                  )}
                  disabled={isLoading && !isError}
                  onClick={closeClicked}
                >
                  Close
                </button>
                <button
                  className={classNames(
                    { 'animate-pulse': isLoading && !isError },
                    'ml-auto h-10 w-52 rounded bg-blue-500 text-white font-medium',
                  )}
                  onClick={startImportClicked}
                >
                  {isLoading && !isError ? 'Importing...' : 'Start import'}
                </button>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
};
