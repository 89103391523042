import { SelectBox } from '@components/ui/select-box';
import {
  Account,
  Contact,
  MembershipOrder,
  SalesOrderLineItems,
  ShipmentRun,
  SalesOrder,
} from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import classNames from 'classnames';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { FC, MouseEvent } from 'react';

export type OrderWithRelated = SalesOrder & {
  account: Account;
  contact: Contact;
  shipmentRun: ShipmentRun;
  lineItems: SalesOrderLineItems;
  membershipOrder: MembershipOrder;
};

export type OrderCardProps = {
  order: OrderWithRelated;
  selected?: boolean;
  onSelectClicked: (evt: MouseEvent) => void;
};

export const OrderCard: FC<NebPropsWithStd<OrderCardProps>> = ({ className, selected, order, onSelectClicked }) => {
  return (
    <div className={classNames('order-card flex', className)}>
      <div className="w-16 h-16 flex items-center" onClick={onSelectClicked}>
        <SelectBox selected={selected} deselectedContainerClass="border border-gray-200">
          {' '}
        </SelectBox>
      </div>
      <div>
        <div className="flex">
          <p>{order.name}</p>
          {/* <p
            className={classNames(
              { 'bg-green-300 text-green-700': order.status === 'ACTIVE' },
              { 'bg-gray-300 text-gray-700': order.status === 'DISABLED' },
              'rounded p-1 lowercase font-medium ml-4',
            )}
          >
            {order.status}
          </p> */}
          {/* <p className="rounded bg-gray-500 text-gray-800 p-1 ml-4">/{order.slug}</p> */}
        </div>
        {/* <div className="flex">
      <p>{order.item.sellEx}</p>
      <p>{order.item.costEx}</p>
    </div> */}
      </div>
    </div>
  );
};
