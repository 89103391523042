import { ThScrollPage } from '@components/ui/th-scroll-page';
import { useGetManyAccounts } from '@core/clients/ecommerce-services-admin/accounts/accounts';
import { useSelectedSet } from '@core/hooks/useSelectedSet';
import { setPageMeta } from '@core/stores/core-store';
import { FC, MouseEvent, useState } from 'react';
import { AiFillWarning } from 'react-icons/ai';
import { BiImport } from 'react-icons/bi';
import { AccountCard } from '../../components/accounts/account-card';
import { AccountImportDialog } from '../../components/accounts/account-import-dialog';
import { Button } from '../../components/button';

export type AccountsIndexPageProps = {};

export const AccountsIndexPage: FC<AccountsIndexPageProps> = () => {
  setPageMeta('Accounts', false);

  const { selectedSet, selectClickHandler } = useSelectedSet();

  const { data, status, queryKey, isLoading } = useGetManyAccounts({
    include: { memberships: true },
  });

  // const [accounts, setAccounts] = useState<Account[]>([]);
  const [importDialogOpen, setImportDialogOpen] = useState(false);

  // useEffect(() => {
  //   setAccounts(data?.items ?? []);
  // }, [data?.items]);

  const importAccountsClicked = (evt: MouseEvent) => {
    evt.preventDefault();
    setImportDialogOpen(true);
  };

  const importDialogOnClose = () => {
    setImportDialogOpen(false);
  };

  // const accountClicked = (id: string) => (evt: MouseEvent) => {
  //   evt.preventDefault();
  // };

  const needsAttentionCount = (data?.items ?? []).filter(
    (a) => !a.zohoCrmAccountRef || !a.zohoInvContactRef || !a.stripeCustomerRef,
  ).length;

  return (
    <ThScrollPage id="accounts-index-page">
      <div className="w-full flex">
        {needsAttentionCount > 0 && (
          <div className="flex items-center bg-th-membership-500 text-white p-2 rounded">
            <AiFillWarning className="w-6 h-6"></AiFillWarning>
            <p className="ml-4 font-semibold text-lg">{needsAttentionCount} accounts need attention</p>
          </div>
        )}
        <Button className="w-80 justify-around ml-auto flex items-center" onClick={importAccountsClicked}>
          <BiImport className="text-xl text-th-membership-500" />
          Import accounts from Zoho CRM
        </Button>
        <AccountImportDialog isOpen={importDialogOpen} onClose={importDialogOnClose}></AccountImportDialog>
      </div>
      <div className="mt-2">
        {data?.items?.length ? (
          data?.items?.map((a) => (
            <AccountCard
              accountsQueryKey={queryKey}
              account={a}
              key={a.id}
              selected={selectedSet.has(a.id!)}
              // onCardClicked={accountClicked(a.id!)}
              onSelectClicked={selectClickHandler(a.id!)}
              className="mt-4 w-full"
            />
          ))
        ) : (
          <>
            {isLoading ? (
              <div className="mt-4 w-full account-card bg-gray-200 rounded p-2 h-24 animate-pulse flex space-x-4">
                <div className="h-18 w-18"></div>
                <div className="h-18 w-18 bg-gray-100 animate-pulse"></div>
                <div className="h-18 w-24 bg-gray-100 animate-pulse"></div>
                <div className="h-18 w-24 bg-gray-100 animate-pulse"></div>
                <div className="h-18 w-24 bg-gray-100 animate-pulse"></div>
              </div>
            ) : (
              <p>No accounts</p>
            )}
          </>
        )}
      </div>
    </ThScrollPage>
  );
};
