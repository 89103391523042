import classnames, { Argument } from 'classnames';
import { isString } from 'lodash-es';
import { ElementType, FC, ReactNode } from 'react';

export type FallbackIconProps = {
  icon?: ElementType;
  iconClassName: Argument;
  text: ReactNode;
  textClassName: Argument;
};

export const FallbackIcon: FC<FallbackIconProps> = ({ icon: Icon, text, iconClassName, textClassName }) => {
  return Icon ? (
    <Icon className={classnames('text-3xl font-bold', iconClassName)} />
  ) : (
    <span className={classnames('text-2xl font-medium', textClassName)}>
      {isString(text) ? (text as string).substring(0, 1).toUpperCase() : '?'}
    </span>
  );
};
