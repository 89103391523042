import { authRoutes } from '@auth';
import { RequireAuthGuard } from '@components/guards/require-auth-guard';
import { foyerRoutes } from '@foyer';
import { DashLayout } from '@layouts/dash-layout';
import { membershipRoutes } from '@membership';
import { FC, PropsWithChildren } from 'react';
import { useRoutes } from 'react-router-dom';
import { ecommerceRoutes } from '@ecommerce';
import { SidebarRouter } from './sidebar-router';
import { biteyRoutes } from '@bitey';

export const AppRouter: FC<PropsWithChildren<{}>> = () =>
  useRoutes([
    ...foyerRoutes,
    ...authRoutes,
    {
      element: (
        <RequireAuthGuard>
          <DashLayout sidebarContent={<SidebarRouter></SidebarRouter>}></DashLayout>
        </RequireAuthGuard>
      ),
      children: [...membershipRoutes, ...ecommerceRoutes, ...biteyRoutes],
    },
  ]);
