import classnames, { Argument } from 'classnames';
import { omit } from 'lodash-es';
import { ElementType, FunctionComponent, PropsWithChildren, ReactElement, useEffect, useRef, useState } from 'react';
import { FallbackIcon } from '../../core/components/FallbackIcon';
import { BaseLinkProps, GenericLink } from '../../core/types';

export type NavbarLinkProps<L extends GenericLink> = BaseLinkProps<L> & {
  icon?: ElementType;
  className?: Argument;
  isActive: boolean;
  styles?: {
    bgColor?: Argument;
    textColor?: Argument;
  };
};

export const NavbarLink = <L extends GenericLink>(props: PropsWithChildren<NavbarLinkProps<L>>): ReactElement => {
  const [contentWidth, setContentWidth] = useState(0);
  const [linkWidth, setLinkWidth] = useState<number | string>();
  const rootEl = useRef<HTMLDivElement>(null);
  const spanEl = useRef<HTMLSpanElement>(null);
  const baseLinkWidth = useRef(0);

  const { className, icon, children, linkComponent: Link, isActive, styles } = props;

  const linkProps = omit(props, ['className', 'icon', 'styles', 'isActive', 'linkComponent']);

  useEffect(() => {
    const rootFontSize = parseInt(window.getComputedStyle(document.body).getPropertyValue('font-size').split('p')[0]);
    baseLinkWidth.current = 4 * rootFontSize;
    const totalLinkWidth = baseLinkWidth.current + (spanEl.current?.offsetWidth || 0);
    setContentWidth(totalLinkWidth);

    const timeout = setTimeout(() => {
      if (!rootEl.current?.matches(':hover')) {
        setLinkWidth(baseLinkWidth.current);
      }
    }, 1000);

    if (isActive) {
      setLinkWidth(totalLinkWidth);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [children, isActive]);

  const enter = () => {
    setLinkWidth(contentWidth);
  };

  const leave = () => {
    setLinkWidth(baseLinkWidth.current);
  };

  return (
    <Link {...linkProps} className="flex h-full w-full">
      {/* <a className="flex h-full w-full"> */}
      <div
        ref={rootEl}
        onMouseEnter={enter}
        onMouseLeave={leave}
        style={{ width: linkWidth }}
        className={classnames(
          className,
          styles?.bgColor,
          'group navbar-link flex rounded h-full min-w-16 w-16 items-center transition-all duration-500 overflow-hidden cursor-pointer',
        )}
      >
        <div className="w-16 flex justify-center shrink-0">
          <FallbackIcon
            icon={icon}
            text={children}
            textClassName={styles?.textColor}
            iconClassName={styles?.textColor}
          ></FallbackIcon>
        </div>
        <span ref={spanEl} className={classnames(styles?.textColor, 'text-xl font-semibold pr-4 font-title')}>
          {children}
        </span>
      </div>
      {/* </a> */}
    </Link>
  );
};
