import { createRequireAuthGuard } from '@villageco/nebula/auth';
import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthRoutePaths } from '@auth';

const UnauthedNavigate: FC<{}> = () => {
  const location = useLocation();
  return <Navigate to={AuthRoutePaths.LOGIN} state={{ from: location }} replace />;
};

const { RequireAuthGuard } = createRequireAuthGuard({ unauthenticatedElement: UnauthedNavigate });

export { RequireAuthGuard };
