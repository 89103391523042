import { Item, ItemCreate } from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import { useGetManyItems, usePatchItemById, usePostItem } from '@core/clients/ecommerce-services-admin/items/items';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { pick } from 'lodash';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { Currency, Form, notFoundAuthHandler, Numeric, ResetButton, SubmitButton, Text } from '@villageco/nebula/forms';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ecommerceFormConfig } from '../../libs/form-config';

export type ItemDetailsEditFieldsProps = {
  item?: Item;
  fieldNamePrefix: string;
};

export const ItemDetailsEditFields: FC<NebPropsWithStd<ItemDetailsEditFieldsProps>> = ({ item, fieldNamePrefix }) => {
  const queryClient = useQueryClient();

  const defaultData = pick(item ?? {}, [
    'sellEx',
    'sellGst',
    'costEx',
    'costGst',
    'stepQty',
    'minimumQty',
    'sku',
    'zohoInvItemRef',
  ]);

  return (
    <div className="w-full item-details-edit-fields">
      <div className="flex w-full mt-6 space-x-4">
        <Currency
          className="w-full"
          value={item?.sellEx ?? undefined}
          name={`${fieldNamePrefix}sellEx`}
          label="Sell Ex."
        />
        <Currency
          className="w-full"
          value={item?.sellGst ?? undefined}
          name={`${fieldNamePrefix}sellGst`}
          label="Sell GST."
        />
        <Numeric
          className="w-full"
          value={item?.minimumQty ?? undefined}
          name={`${fieldNamePrefix}minimumQty`}
          label="Minimum quantity"
        />
      </div>
      <div className="flex w-full mt-4 space-x-4">
        <Currency
          className="w-full"
          value={item?.costEx ?? undefined}
          name={`${fieldNamePrefix}costEx`}
          label="Cost Ex."
        />
        <Currency
          className="w-full"
          value={item?.costGst ?? undefined}
          name={`${fieldNamePrefix}costGst`}
          label="Cost GST."
        />
        <Numeric
          className="w-full"
          value={item?.stepQty ?? undefined}
          name={`${fieldNamePrefix}stepQty`}
          label="Step quantity"
        />
      </div>
      <div className="flex w-full mt-4 space-x-4">
        <Text
          className="w-full"
          name={`${fieldNamePrefix}sku`}
          optional={true}
          value={item?.sku ?? undefined}
          label="SKU"
        ></Text>
        <Text
          className="w-full"
          value={item?.zohoInvItemRef ?? undefined}
          name={`${fieldNamePrefix}zohoInvItemRef`}
          optional={true}
          label="Zoho Inventory Item ID"
        ></Text>
      </div>
    </div>
  );
};
