import classNames, { Argument } from 'classnames';
import { FC } from 'react';
import { NebPropsWithStd } from '@villageco/nebula/core';

export type THH3Props = { className?: Argument; light?: boolean };

export const THH3: FC<NebPropsWithStd<THH3Props>> = ({ className, light = false, children }) => {
  return (
    <h3
      className={classNames(
        'th-h3 text-2xl font-h3 text-gray-500',
        { 'font-bold': !light, 'font-light': light },
        className,
      )}
    >
      {children}
    </h3>
  );
};
