import { Menu, Transition } from '@headlessui/react';
import { FC, Fragment, MouseEvent, useState } from 'react';
import { useCurrentAuthenticatedUser } from '../../auth';
import { CognitoUser } from '@aws-amplify/auth';
import { UserProfileImage } from './user-profile-image';
import { FiLogOut } from 'react-icons/fi';
import { Auth } from 'aws-amplify';
import { CognitoUserAttributes } from '../../auth/amplify/og/types';
// import {} from 'react-icons/';

export type UserPinProps = {};

export const UserPin: FC<UserPinProps> = () => {
  const { user, attributes } = useCurrentAuthenticatedUser<CognitoUser>();

  const logoutOnClick = async (e: MouseEvent) => {
    e.preventDefault();

    await Auth.signOut();
  };

  const [devCounter, setDevCounter] = useState(0);

  const userPinClicked =
    (import.meta as any).env.MODE === 'development'
      ? async (evt: MouseEvent) => {
          if (devCounter < 2) {
            setDevCounter(devCounter + 1);
          } else {
            const user = await Auth.currentAuthenticatedUser();
            console.log(user);
            console.log(`Token: ${user.signInUserSession.accessToken.jwtToken}`);
            navigator.clipboard.writeText(user.signInUserSession.accessToken.jwtToken);
            console.log(await Auth.currentCredentials());

            setDevCounter(0);
          }
        }
      : undefined;

  return (
    <div className="user-pin" onClick={userPinClicked}>
      <Menu>
        <div>
          <Menu.Button>
            <UserProfileImage
              userAttributes={attributes as CognitoUserAttributes & { picture: string }}
              styles={{ fallbackClassName: 'bg-vc-light-brown text-vc-dark-green font-bold text-lg' }}
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-4 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              {/* <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-vc-light-brown text-vc-dark-green' : 'text-black'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    {active ? (
                      <EditActiveIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                    ) : (
                      <EditInactiveIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                    )} 
                    Edit
                  </button>
                )}
              </Menu.Item> */}
              <Menu.Item>
                {({ active }) => (
                  <button className="text-black group flex flex-col w-full justify-center rounded-md px-2 py-2 text-sm">
                    {/* {active ? (
                      <DuplicateActiveIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                    ) : (
                      <DuplicateInactiveIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                    )} */}
                    <span>Signed in as</span>
                    <span className="font-bold">{attributes?.email}</span>
                  </button>
                )}
              </Menu.Item>
            </div>
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={logoutOnClick}
                    className={`${
                      active ? 'bg-vc-light-brown text-vc-dark-green' : 'text-black'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm font-medium`}
                  >
                    <FiLogOut className="mr-4"></FiLogOut>
                    Logout
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
