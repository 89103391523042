import { useGetManyAccounts } from '@core/clients/ecommerce-services-admin/accounts/accounts';
import {
  Membership,
  MembershipCreate,
  ProductVariant,
} from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import { useGetManyMembershipFrequencies } from '@core/clients/ecommerce-services-admin/membership-frequencies/membership-frequencies';
import {
  usePatchMembershipById,
  usePostMembership,
} from '@core/clients/ecommerce-services-admin/memberships/memberships';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { omit, pick } from 'lodash-es';
import { NebPropsWithStd } from '@villageco/nebula/core';
import {
  Date,
  Form,
  FormOutput,
  List,
  ListOptionConfig,
  notFoundAuthHandler,
  ResetButton,
  SubmitButton,
} from '@villageco/nebula/forms';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { membershipFormConfig } from '../../libs/form-config';
import { MembershipRoutePaths } from '../../router/membership-routes';

export type MembershipEditFormProps = {
  membership?: Membership;
  membershipsQueryKey: QueryKey;
  defaultAccountId?: string;
};

export const MembershipEditForm: FC<NebPropsWithStd<MembershipEditFormProps>> = ({
  membership,
  membershipsQueryKey,
  defaultAccountId,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: frequencies, isLoading: freqLoading } = useGetManyMembershipFrequencies({
    select: {
      id: true,
    },
    include: {
      productVariant: true,
    },
  });
  const { data: accounts, isLoading: accLoading } = useGetManyAccounts({
    select: {
      id: true,
      name: true,
    },
    where: {
      memberships: {
        none: {},
      },
    },
  });

  const { mutateAsync: createMembershipMut } = usePostMembership({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(membershipsQueryKey);
      },
    },
  });

  const { mutateAsync: updateMembershipMut } = usePatchMembershipById({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(membershipsQueryKey);
      },
    },
  });

  const formSubmitted = async (rawData: MembershipCreate) => {
    const data = {
      ...omit(rawData, ['accountId', 'membershipFrequencyId']),
      membershipFrequency: {
        connect: { id: (rawData as any).membershipFrequencyId },
      },
      account: {
        connect: { id: (rawData as any).accountId },
      },
    };
    if (membership?.id) {
      await updateMembershipMut({ id: membership.id, data: { data } });
    } else {
      const res = await createMembershipMut({ data: { data } });
      navigate(`${MembershipRoutePaths.MEMBERSHIP_DETAILS}${res.id}`);
    }
  };

  const defaultData = pick(membership ?? {}, ['membershipFrequencyId', 'accountId', 'chargeFrom', 'endAt']);

  if (defaultAccountId) {
    defaultData.accountId = defaultAccountId;
  }

  const accountOptions = accounts?.items?.map<ListOptionConfig>((a) => ({ label: a.name!, value: a.id! })) ?? [];
  const frequencyOptions =
    frequencies?.items?.map<ListOptionConfig>((a) => ({
      label: (a.productVariant as ProductVariant).name!,
      value: a.id!,
    })) ?? [];

  return (
    <Form
      defaultData={defaultData}
      config={{ ...membershipFormConfig, formErrorHandler: notFoundAuthHandler }}
      onSubmit={formSubmitted}
    >
      <div className="flex space-x-4">
        {!membership?.id && (
          <div className="w-1/2">
            <List
              disabled={accLoading}
              name="accountId"
              label="Account"
              options={accountOptions}
              placeholder={accLoading ? 'Loading' : undefined}
            ></List>{' '}
          </div>
        )}
        <div className="w-1/2">
          <List
            disabled={freqLoading}
            name="membershipFrequencyId"
            label="Frequency"
            options={frequencyOptions}
            placeholder={freqLoading ? 'Loading' : undefined}
          ></List>
        </div>
        {!!membership?.id && <div className="w-1/2"></div>}
      </div>
      <div className="flex space-x-4">
        <div className="w-1/2">
          <Date<typeof defaultData>
            optional={true}
            name="chargeFrom"
            label="Start charging membership"
            className="mt-2"
          ></Date>
        </div>
        <div className="w-1/2">
          <Date<typeof defaultData> optional={true} name="endAt" label="Membership End Date" className="mt-2"></Date>
        </div>
      </div>

      <div className="flex justify-end mt-4 space-x-4">
        <FormOutput className="mr-auto"></FormOutput>
        <SubmitButton></SubmitButton>
        <ResetButton></ResetButton>
      </div>
    </Form>
  );
};
