import { THH3 } from '@components/ui/th-h3';
import THModal from '@components/ui/th-modal';
import { THP } from '@components/ui/th-p';
import { File } from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import { EmbeddedVideo, YoutubeEmbed } from '@villageco/nebula/core';
import { RichEditor, Text } from '@villageco/nebula/forms';
import classNames, { Argument } from 'classnames';
import { FunctionComponent, useState } from 'react';
import { FaPen } from 'react-icons/fa';
import { ActionButtons } from '../core/action-buttons';
import { Button } from '../core/button';
import { EditableField } from '../core/editable-field';
import { FileUpload } from '../files/file-upload';

type ProductBlockFields = {
  title: string;
  body: string;
};

export type ProductInfoBlockProps = {
  className?: Argument;
  bodyClassName?: Argument;
  mediaClassName?: Argument;
  title?: string;
  body?: string;
  mediaFile?: File;
  embeddedVideo?: EmbeddedVideo;
  index: number;
  name: string;
  mediaPosition?: 'left' | 'right' | 'top' | 'bottom';
  onUpdate?: (
    index?: number,
    title?: string,
    body?: string,
    file?: File,
    updateFile?: boolean,
    embeddedVideo?: EmbeddedVideo,
    updateVideo?: boolean,
  ) => void;
  onCancel?: () => void;
  hideTitle?: boolean;
  hideImage?: boolean;
  editable?: boolean;
  onDelete?: () => void;
  deleteMessage?: string;
};

export const ProductInfoBlock: FunctionComponent<ProductInfoBlockProps> = ({
  className,
  bodyClassName,
  mediaClassName,
  title,
  body,
  mediaFile,
  embeddedVideo,
  index,
  name,
  mediaPosition = 'right',
  onUpdate,
  onCancel,
  hideTitle,
  hideImage,
  editable,
  onDelete,
  deleteMessage,
}) => {
  const fullWidth = !mediaFile || mediaPosition === 'top' || mediaPosition === 'bottom';

  const [editTitle, setEditTitle] = useState(title);
  const [editBody, setEditBody] = useState(body);

  const [editEmbeddedVideo, setEditEmbeddedVideo] = useState<EmbeddedVideo | null>(embeddedVideo ?? null);
  const [showEmbedModal, setShowEmbedModal] = useState<boolean>(false);

  const [deleting, setDeleting] = useState(false);

  const handleFieldConfirm = (fieldName: keyof ProductBlockFields) => {
    if (onUpdate) {
      if (fieldName === 'title') {
        onUpdate(index, editTitle);
      } else if (fieldName === 'body') {
        onUpdate(index, undefined, editBody);
      }
    }
  };

  const handleFieldCancel = () => {
    setEditTitle(title);
    setEditBody(body);
  };

  const handleFileUpdate = (file: File, index?: number) => {
    if (onUpdate) {
      onUpdate(index, undefined, undefined, file, true);
    }
  };

  const handleFileDelete = (index: number) => {
    if (onUpdate) {
      onUpdate(index, undefined, undefined, undefined, true);
    }
  };

  const saveEmbeddedVideo = (ev: EmbeddedVideo, index?: number) => {
    if (onUpdate) {
      onUpdate(index, undefined, undefined, undefined, false, ev, true);
    }
  };

  const handleEmbeddedVideoDelete = (index: number) => {
    if (onUpdate) {
      onUpdate(index, undefined, undefined, undefined, false, undefined, true);
    }
    setEditEmbeddedVideo(null);
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete();
    }
  };

  const handleUploadClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!showEmbedModal && !mediaFile) {
      event.stopPropagation();
      event.preventDefault();
      setShowEmbedModal(true);
    }
  };

  const handleEmbeddedVideoSave = () => {
    if (editEmbeddedVideo) {
      saveEmbeddedVideo(editEmbeddedVideo, index);
    }
    setShowEmbedModal(false);
  };

  const handleEmbeddedVideoCancel = () => {
    if (embeddedVideo) {
      setEditEmbeddedVideo(embeddedVideo);
    } else {
      setEditEmbeddedVideo(null);
    }

    setShowEmbedModal(false);
  };

  const innerHtml = `<THP className="">${!!body ? body : '(Add Body)'}</THP>`;

  return (
    <div
      className={classNames('flex justify-between', {
        'hover:bg-gray-50': editable,
      })}
    >
      <div
        className={classNames('media-block flex relative w-full', className, {
          'flex-col lg:flex-row': mediaPosition === 'right',
          'flex-col lg:flex-row-reverse': mediaPosition === 'left',
          'flex-col-reverse': mediaPosition === 'top',
          'flex-col': mediaPosition === 'bottom',
        })}
      >
        <div
          className={classNames('w-full', bodyClassName, {
            'lg:w-2/5': !fullWidth && mediaFile,
            'lg:w-4/5': !fullWidth && !mediaFile,
          })}
        >
          {!hideTitle && (
            <EditableField<ProductBlockFields>
              fieldName="title"
              className="mb-6"
              inputNode={
                <Text
                  className="rounded bg-white border border-gray-300 border-b-purple-500 focus:border-b-blue-400 focus-within:border-b-blue-400 border-b-2 w-full p-2 text-lg outline-none"
                  value={editTitle ?? ''}
                  classes={{ field: { element: 'w-full outline-0' } }}
                  name="title"
                  id="title"
                  onChange={(event) => setEditTitle(event.target.value)}
                />
              }
              displayNode={
                <div>
                  <THP className="uppercase font-bold">{!!title ? title : '(Add Title)'}</THP>
                </div>
              }
              onChangeConfirmed={handleFieldConfirm}
              onCancel={handleFieldCancel}
            />
          )}

          <EditableField<ProductBlockFields>
            fieldName="body"
            inputNode={
              <RichEditor
                className="pl-0 w-full max-w-[26rem] h-fit"
                value={editBody}
                classes={{ field: { element: 'w-full outline-0' } }}
                name="body"
                id="body"
                rows={10}
                onChange={setEditBody}
              />
            }
            displayNode={
              <div
                className="whitespace-pre-wrap [&_a]:text-gl-pink-primary"
                dangerouslySetInnerHTML={{ __html: innerHtml }}
              />
            }
            onChangeConfirmed={handleFieldConfirm}
            onCancel={handleFieldCancel}
          />
        </div>
        {!hideImage && (
          <div
            className={classNames('flex justify-center', mediaClassName, {
              'lg:w-3/5': !fullWidth,
              'w-min': !mediaFile && !editEmbeddedVideo && !embeddedVideo,
              'w-full aspect-video': !hideImage,
            })}
          >
            {embeddedVideo?.videoLink ? (
              <div className="flex flex-col w-full p-2 relative h-fit">
                <YoutubeEmbed embeddedVideo={embeddedVideo}>
                  <THH3>{embeddedVideo?.title ?? 'Add Title'}</THH3>
                </YoutubeEmbed>
                <div className="flex justify-center space-x-4">
                  <ActionButtons
                    action={() => handleEmbeddedVideoDelete(index)}
                    className="flex justify-center"
                    standardIconClassName="!ml-0 mr-2"
                    confirmIconClassName="!ml-0 mr-2"
                    cancelIconClassName="!ml-0 mr-2 mb-1"
                    confirmationMessage="Remove Youtube video"
                  />
                  {embeddedVideo && (
                    <div
                      className="text-purple-500 hover:text-blue-400 hover:active:text-blue-300 border-purple-500 hover:border-blue-400 hover:active:border-blue-300 w-7 h-7 mt-1 bg-white flex justify-center items-center hover:cursor-pointer border-2 rounded-md  z-10"
                      onClick={() => setShowEmbedModal(true)}
                    >
                      <FaPen className="" />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="flex flex-col" onClick={handleUploadClick}>
                <FileUpload
                  value={mediaFile}
                  id={`prod-info-block-{${name}}`}
                  className="mr-2 w-full h-full z-10"
                  contentSectionUpload
                  onChange={(file: File) => handleFileUpdate(file, index)}
                />
                <div>
                  {mediaFile && (
                    <div className="flex justify-center">
                      <ActionButtons
                        action={() => handleFileDelete(index)}
                        className="flex justify-center"
                        standardIconClassName="!ml-0 mr-2"
                        confirmIconClassName="!ml-0 mr-2"
                        cancelIconClassName="!ml-0 mr-2 mb-1"
                        confirmationMessage="Remove file"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {editable && (
        <ActionButtons
          action={handleDelete}
          confirmationMessage={deleteMessage}
          confirmationMessageClassName="right-0"
        />
      )}
      <THModal open={showEmbedModal} onClose={() => setShowEmbedModal(false)} size="lg">
        <div className="flex items-center flex-col p-4">
          <div className="w-full text-left border-b-2 pb-2 flex items-center justify-between">
            <THH3>{embeddedVideo ? 'Edit' : 'Add'} YouTube video</THH3>
          </div>
          <div className="my-3 w-full">
            <Text
              className="rounded bg-white border border-gray-300 border-b-purple-500 focus:border-b-blue-400 focus-within:border-b-blue-400 border-b-2 w-full p-2 text-lg outline-none"
              value={editEmbeddedVideo?.title ?? ''}
              classes={{ field: { element: 'w-full outline-0' } }}
              name="title"
              id="title"
              placeholder="Title"
              onChange={(event) => setEditEmbeddedVideo({ ...editEmbeddedVideo, title: event.target.value })}
            />
          </div>
          <div className="my-2 w-full">
            <Text
              className="rounded bg-white border border-gray-300 border-b-purple-500 focus:border-b-blue-400 focus-within:border-b-blue-400 border-b-2 w-full p-2 text-lg outline-none"
              value={editEmbeddedVideo?.videoLink ?? ''}
              classes={{ field: { element: 'w-full outline-0' } }}
              name="embed-link"
              id="embed-link"
              placeholder="Youtube Video Link"
              onChange={(event) => setEditEmbeddedVideo({ ...editEmbeddedVideo, videoLink: event.target.value })}
            />
            <p className="mt-">E.g. https://www.youtube.com/watch?v=6uUWi59GBN4</p>
          </div>

          <div className="flex space-x-4 pt-4 w-full justify-end">
            <Button variant="solid" onClick={handleEmbeddedVideoSave} className="max-w-[100px]">
              Save
            </Button>
            <Button variant="outlined" onClick={handleEmbeddedVideoCancel} className="max-w-[100px]">
              Back
            </Button>
          </div>
        </div>
      </THModal>
    </div>
  );
};
