import classNames, { Argument } from 'classnames';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { FC } from 'react';
import { Fade } from '../transitions/fade';

export type PageShieldProps = {
  show: boolean;
  className?: Argument;
};

export const PageShield: FC<NebPropsWithStd<PageShieldProps>> = ({ show, className, children }) => {
  return (
    <Fade in={show} className={classNames(className, 'page-shield fixed z-50 inset-0 h-screen w-screen')}>
      <div className="flex justify-center items-center h-full w-full">{children}</div>
    </Fade>
  );
};
