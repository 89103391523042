import { THH3 } from '@components/ui/th-h3';
import {
  Membership,
  MembershipOrder,
  MembershipOrderItems,
  Memberships,
} from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import { useGetMembershipOrderById } from '@core/clients/ecommerce-services-admin/membership-orders/membership-orders';
import { setPageMeta } from '@core/stores/core-store';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { useStringDateTimeFormatter } from '@villageco/nebula';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { FC } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { MembershipEditForm } from '../../components/memberships/membership-edit-form';
import { membershipState } from '../../stores';
import { MembershipOrderEditForm } from '../../components/orders/membership-order-edit-form';

export type MembershipOrderDetailsPageProps = {};

export const MembershipOrderDetailsPage: FC<NebPropsWithStd<MembershipOrderDetailsPageProps>> = () => {
  setPageMeta('Membership Orders', false);

  const { id: membershipOrderId } = useParams();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const storeSnap = useSnapshot(membershipState);

  const isNew = membershipOrderId === 'new';

  const {
    data,
    isLoading: moLoading,
    queryKey,
    error,
  } = useGetMembershipOrderById<
    MembershipOrder & {
      membership: Membership;
      membershipOrderItems: MembershipOrderItems;
    }
  >(
    membershipOrderId!,
    {
      include: {
        membership: {
          include: {
            account: true,
          },
        },
        membershipOrderItems: {
          include: {
            item: {
              include: {
                variant: true,
                product: true,
              },
            },
          },
        },
      },
    },
    {
      query: {
        enabled: !isNew,
        initialData: () => {
          return (
            storeSnap.membershipOrdersIndexQueryKey &&
            queryClient
              .getQueryData<Memberships>(storeSnap.membershipOrdersIndexQueryKey)
              ?.items?.find((m) => m.id === membershipOrderId)
          );
        },
      },
    },
  );

  const { formattedDate: memberSince } = useStringDateTimeFormatter(data?.createdAt, 'en-AU', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });

  return (
    <div id="membership-order-page" className="p-4 w-full">
      {(!moLoading || isNew) && (
        <div>
          <THH3>
            {isNew ? (
              'Add a new membership order'
            ) : (
              <>
                {`Membership order details for `}
                <span className="text-gray-700">{data?.membership?.account?.name} </span>
                <span className="text-gray-700 font-normal">( No: {data?.membership?.account?.govBusinessNumber})</span>
              </>
            )}
          </THH3>
          <div className="mt-4 bg-th-membership-100 rounded p-4">
            {data && (
              <>
                <div className="flex">
                  <p className="font-medium text-gray-600 text-xl">
                    <span className="text-gray-700 font-bold capitalize">{data?.month?.toLowerCase()} </span>
                    <span className="text-gray-700 font-normal">{data?.year}</span>
                  </p>
                </div>

                <hr className="border-th-membership-500 my-4"></hr>
                <p className="text-xl font-semibold text-th-membership-600 mb-2">Edit membership order</p>
              </>
            )}

            <MembershipOrderEditForm
              membershipOrder={data}
              membershipOrderQueryKey={queryKey}
            ></MembershipOrderEditForm>
          </div>
        </div>
      )}
      {status === 'loading' && !isNew && (
        <div>
          <div className="bg-gray-200 animate-pulse rounded w-full h-10"></div>
          <div className="bg-gray-200 animate-pulse rounded w-full h-64 mt-4"></div>
        </div>
      )}
      {status === 'error' && (
        <div>
          <THH3>An error has occurred trying to retrieve membership</THH3>
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};
