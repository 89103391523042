import { FC } from 'react';
import classNames, { Argument } from 'classnames';
import { FiPlusCircle } from 'react-icons/fi';
import { IconType } from 'react-icons';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { useNavigate } from 'react-router-dom';

export type SlimButtonProps = {
  className?: Argument;
  icon?: IconType;
  iconSize?: number;
  iconPosition?: 'left' | 'right';
  onClick?: () => void;
  href?: string;
};

export const SlimButton: FC<NebPropsWithStd<SlimButtonProps>> = ({
  className,
  icon: Icon = FiPlusCircle,
  iconSize = 24,
  iconPosition = 'right',
  onClick,
  href,
  children,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    if (href && navigate) {
      navigate(href);
    }
  };

  return (
    <button
      className={classNames(
        'slim-button',
        {
          'flex-row': iconPosition === 'right',
          'flex-row-reverse': iconPosition === 'left',
        },
        className,
      )}
      onClick={handleClick}
    >
      <span className="underline underline-offset-2 mx-1">{children ?? 'Add'}</span>
      <Icon size={iconSize} className="mx-1" />
    </button>
  );
};
