import { ThScrollPage } from '@components/ui/th-scroll-page';
import { ProductInclude } from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import { useGetProductById } from '@core/clients/ecommerce-services-admin/products/products';
import { setPageMeta } from '@core/stores/core-store';
import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { ProductEditForm } from '../../components/products/product-edit-form';
import { ProductPageEditForm } from '../../components/products/product-page-edit-form';

export type ProductDetailsProps = {};

export const ProductDetailsPage: FunctionComponent<ProductDetailsProps> = () => {
  setPageMeta('Products', false);

  const { id: productId } = useParams();

  const isNew = productId === 'new';

  const {
    data: product,
    isLoading: productLoading,
    queryKey: productQueryKey,
  } = useGetProductById(
    productId!,
    {
      include: {
        item: true,
        variants: { include: { item: true } },
        files: true,
        categories: true,
      } as ProductInclude,
    },
    {
      query: {
        enabled: !isNew,
      },
    },
  );

  return (
    <ThScrollPage id="product-details-page">
      <div className="bg-th-ecommerce-200 min-h-full rounded p-2">
        {!isNew && productLoading ? (
          <div className="w-full h-48 bg-slate-100 p-2 rounded">
            <div className="animate-pulse">
              <div className="w-full h-8 bg-slate-200 rounded"></div>

              <div className="flex mt-4 space-x-4">
                <div className="w-full h-8 bg-slate-200 rounded"></div>
                <div className="w-full h-8 bg-slate-200 rounded"></div>
              </div>

              <div className="flex mt-2 space-x-4">
                <div className="w-full h-8 bg-slate-200 rounded"></div>
                <div className="w-full h-8 bg-slate-200 rounded"></div>
              </div>

              <div className="flex mt-4 w-full space-x-4">
                <div className="ml-auto w-32 h-8 bg-slate-200 rounded"></div>
                <div className="w-32 h-8 bg-slate-200 rounded"></div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="bg-white rounded p-4">
              <p className="font-semibold text-lg">{isNew ? 'New Product' : 'Product Details'}</p>

              <ProductEditForm product={product} invalidateQueries={[productQueryKey]}></ProductEditForm>
            </div>
            {!isNew && product && (
              <div className="bg-white p-4 rounded mt-4">
                <p className="font-semibold text-lg ">Product Page Preview</p>
                <ProductPageEditForm className="mt-4" product={product}></ProductPageEditForm>
              </div>
            )}
          </>
        )}
      </div>
    </ThScrollPage>
  );
};
