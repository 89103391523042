import { THH3 } from '@components/ui/th-h3';
import { THP } from '@components/ui/th-p';
import { Product } from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import classNames, { Argument } from 'classnames';
import { Decimal } from 'decimal.js';
import { FunctionComponent, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { usePrintMoney } from '../../hooks/use-print-money';

export type ProductBlockProps = {
  product: Product;
  className?: Argument;
  showDescription?: boolean;
  showPrice?: boolean;
};

export const ProductBlock: FunctionComponent<ProductBlockProps> = ({
  product,
  className,
  showDescription = true,
  showPrice = true,
}) => {
  const image = product?.files?.items && product?.files?.items.length > 0 && product?.files?.items[0];

  const printer = usePrintMoney();

  const [showLink, setShowLink] = useState<boolean>(false);

  const sellEx = new Decimal(product?.item?.sellEx ?? 0).toNumber();
  const sellGst = new Decimal(product?.item?.sellGst ?? 0).toNumber();

  return (
    <div className={classNames('product-block w-full', className)}>
      <Link to={`/product/${encodeURIComponent(product.slug as string)}`}>
        <div className="">{image && <img src={image?.url as string} alt="" />}</div>
      </Link>
      <div className="flex flex-col items-center">
        <Link className="mb-2 mt-4" to={`/product/${encodeURIComponent(product.slug as string)}`}>
          {<THH3 className="text-center">{product.name}</THH3>}
        </Link>

        <THP className="text-center">{product.description}</THP>
        <div className="flex mt-1">
          {showPrice && sellEx && sellGst && (
            <>
              <THP>{printer.format(sellEx)}</THP>
              <THP className="font-bold ml-2">{printer.format(sellGst)}</THP>
            </>
          )}
        </div>
        <Link
          to={`/product/${encodeURIComponent(product.slug as string)}`}
          className="underline uppercase text-gl-grey font-semibold mt-2 hover:decoration-gl-link hover:text-gl-pink-primary underline-offset-2"
        >
          View Product
        </Link>
      </div>
    </div>
  );
};
