import { MembershipOrderWhere } from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import { useGetManyMembershipOrders } from '@core/clients/ecommerce-services-admin/membership-orders/membership-orders';
import { usePostSalesOrder } from '@core/clients/ecommerce-services-admin/sales-orders/sales-orders';
import { MONTH_SELECT_OPTIONS, YEAR_SELECT_OPTIONS } from '@core/helpers/select-constants';
import { useSelectedSet } from '@core/hooks/useSelectedSet';
import { setPageMeta } from '@core/stores/core-store';
import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { List } from '@villageco/nebula/forms';
import { FC, MouseEvent, useState } from 'react';
import { BiPlus, BiSearch } from 'react-icons/bi';
import { BsCheckCircleFill, BsUnlockFill } from 'react-icons/bs';
import { IoDocumentAttachSharp } from 'react-icons/io5';
import { MdClear, MdLibraryAdd, MdOutlineNextPlan } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { LIST_OPTION_CONFIG } from '../../libs/form-config';
import { MembershipRoutePaths, membershipRoutes } from '../../router/membership-routes';
import { membershipState } from '../../stores';
import { ThScrollPage } from '@components/ui/th-scroll-page';
import { GenerateOrdersDialog } from '../../components/orders/generate-orders-dialog';
import { OrderCard } from '../../components/orders/order-card';

export type MembershipOrdersIndexPageProps = {};

export const MembershipOrdersIndexPage: FC<NebPropsWithStd<MembershipOrdersIndexPageProps>> = () => {
  setPageMeta('Membership Orders', false);

  const [month, setMonth] = useState<string | undefined>();
  const [year, setYear] = useState<string | undefined>();
  const [where, setWhere] = useState<MembershipOrderWhere>({});

  const [generateDialogOpen, setGenerateDialogOpen] = useState(false);

  const generateDialogOnClose = () => {
    setGenerateDialogOpen(false);
  };

  const {
    data,
    isLoading: moLoading,
    queryKey,
  } = useGetManyMembershipOrders({
    include: {
      salesOrder: true,
      membership: {
        include: {
          account: true,
        },
      },
    },
    where,
  });

  const { selectedSet, selectClickHandler } = useSelectedSet();

  const { mutateAsync } = usePostSalesOrder();

  membershipState.membershipOrdersIndexQueryKey = queryKey;

  const categories = {
    unlocked: {
      label: 'Unlocked Membership Orders',
      icon: BsUnlockFill,
      list: data?.items?.filter((m) => !m.salesOrder) ?? [],
    },
    converted: {
      label: 'Converted Membership Orders',
      icon: IoDocumentAttachSharp,
      list: data?.items?.filter((m) => m.salesOrder) ?? [],
    },
    completed: {
      label: 'Completed Membership Orders',
      icon: BsCheckCircleFill,
      list: data?.items?.filter((m) => (m.salesOrder as any)?.shipmentRunId) ?? [],
    },
  };

  const searchClicked = (evt: MouseEvent) => {
    evt.preventDefault();
    setWhere({ month, year } as MembershipOrderWhere);
  };

  const clearSearchClicked = (evt: MouseEvent) => {
    evt.preventDefault();
    setWhere({});
    setMonth(undefined);
    setYear(undefined);
  };

  const convertClicked = async (evt: MouseEvent) => {
    evt.preventDefault();
  };

  const generateClicked = async (evt: MouseEvent) => {
    evt.preventDefault();
    setGenerateDialogOpen(true);
  };

  return (
    <ThScrollPage id="membership-orders-index-page">
      <div className="rounded w-full bg-th-membership-50 p-2 flex">
        <List
          label="Month"
          name="month"
          classes={{
            field: { element: 'rounded px-2 h-10 border border-gray-300 border-b-2 w-56' },
            label: { container: 'mb-0', element: 'text-xs' },
            listOptions: LIST_OPTION_CONFIG,
          }}
          onChange={(val: string | undefined) => setMonth(val)}
          options={MONTH_SELECT_OPTIONS}
          value={month}
        ></List>
        <List
          label="Year"
          name="year"
          className="ml-4"
          classes={{
            field: { element: 'rounded px-2 h-10 border border-gray-300 border-b-2 w-56' },
            label: { container: 'mb-0', element: 'text-xs' },
            listOptions: LIST_OPTION_CONFIG,
          }}
          onChange={(val: string | undefined) => setYear(val)}
          options={YEAR_SELECT_OPTIONS}
          value={year}
        ></List>
        <button
          onClick={searchClicked}
          className={classNames('font-medium ml-4 rounded  w-28 justify-evenly flex flex-col items-center', {
            'animate-pulse bg-th-membership-100 text-th-membership-300': moLoading,
            'bg-th-membership-300 text-th-membership-700 ': !moLoading,
          })}
        >
          <BiSearch className="w-6 h-6" />
          Search
        </button>
        <button
          onClick={clearSearchClicked}
          className={classNames('font-medium ml-4 rounded  w-28 justify-evenly flex flex-col items-center', {
            'animate-pulse bg-th-membership-100 text-th-membership-300': moLoading,
            'bg-th-membership-300 text-th-membership-700 ': !moLoading,
          })}
        >
          <MdClear className="w-6 h-6" />
          Clear
        </button>
        <button
          onClick={generateClicked}
          className={classNames(
            'ml-auto font-medium rounded bg-th-membership-500 text-white w-28 justify-evenly flex flex-col items-center',
          )}
        >
          <MdLibraryAdd className="w-6 h-6" />
          Generate
        </button>
        <GenerateOrdersDialog isOpen={generateDialogOpen} onClose={generateDialogOnClose}></GenerateOrdersDialog>

        <button
          onClick={convertClicked}
          className={classNames(
            'ml-4 font-medium rounded bg-th-membership-500 text-white w-28 justify-evenly flex flex-col items-center',
            {
              'animate-pulse bg-th-membership-200': moLoading,
              '': !moLoading,
            },
          )}
        >
          <MdOutlineNextPlan className="w-6 h-6" />
          Convert
        </button>
        <Link
          to={`${MembershipRoutePaths.MEMBERSHIP_ORDER_DETAILS}new`}
          className={classNames(
            'ml-4 font-medium rounded bg-th-membership-500 text-white w-28 justify-evenly flex flex-col items-center',
          )}
        >
          <BiPlus className="w-6 h-6" />
          Add
        </Link>
      </div>
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-th-membership-100 p-1 mt-4">
          {Object.entries(categories).map(([key, val]) => (
            <Tab
              key={key}
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5 transition-all duration-300 focus:outline-none flex items-center justify-center',
                  selected
                    ? 'bg-th-membership-200 text-th-membership-600 font-black shadow-sm'
                    : 'text-th-membership-500 hover:bg-white/[0.12] hover:text-th-membership-900',
                )
              }
            >
              <val.icon className="text-xl font-semibold mr-4" />
              {val.label}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="w-full h-full flex mt-2">
          {Object.values(categories).map((cat, index) => (
            <Tab.Panel key={`memberships-${index}`} className="w-full flex">
              <div className="flex flex-col p-2 rounded bg-th-membership-50 h-full w-full">
                {cat.list.map((o) => (
                  <div className="rounded bg-white p-3" key={o.id}>
                    <OrderCard
                      order={o as any}
                      onSelectClicked={(evt) => {
                        evt.stopPropagation();
                      }}
                    ></OrderCard>{' '}
                  </div>
                ))}
              </div>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </ThScrollPage>
  );
};
