import { Promiseable } from '@villageco/helpers';
import { ElementType } from 'react';
import { TagClassNameFunction } from '../components';

export type ValidationErrors<T> = { [k in keyof T]?: string[] };

export type FormErrorHandler<T> = (error: any, context: FormState<T>) => Promiseable<void>;

export type FormConfig<T> = {
  defaultClassConfig?: FieldClassConfig;
  submitErrorFieldParser?: (response: any, config?: FormConfig<T>) => ValidationErrors<T>;
  formErrorHandler?: FormErrorHandler<T>;
};

export type FormContextProps<T> = {
  defaultData?: T;
  config?: FormConfig<T>;
  onChange?: (data: T) => void;
  usesHtml?: boolean;
};

export type FieldClassConfig = {
  container?: string;
  error?: string;
  success?: string;
  label?: {
    element?: string;
    container?: string;
    optional?: string;
  };
  field?: {
    element?: string;
    container?: string;
    changed?: string;
    error?: string;
    disabled?: string;
    ready?: string;
  };
  override?: {
    tags?: {
      element?: string;
      tagsContainer?: string;
      input?: string;
    };
  };
  listOptions?: {
    container?: string;
    element?: string;
    disabled?: string;
    selected?: string;
  };

  tagOptions?: {
    tag?: TagClassNameFunction;
    label?: string;
    closeIconElement?: ElementType;
    closeIcon?: string;
  };
  submitButton?: {
    element?: string;
    unchanged?: string;
    disabled?: string;
    loading?: string;
    ready?: string;
  };
  resetButton?: {
    element?: string;
    unchanged?: string;
    disabled?: string;
    loading?: string;
    ready?: string;
  };
};

export enum FormEvent {
  SUBMIT_CLICKED = 'SUBMIT_CLICKED',
  RESET_CLICKED = 'RESET_CLICKED',
  SUBMIT_SUCCESS = 'SUBMIT_SUCCESS',
  SUBMIT_ERROR = 'SUBMIT_ERROR',
}

export type FormEventHandler = (eventData: any) => Promiseable<void>;

export type FormState<T> = {
  data: T;
  validationErrors?: ValidationErrors<T>;
  config: FormConfig<T>;
  loading: boolean;
  lastEvent?: FormEvent;
  formError?: string;
  _initialData: T;
  hasChanged: boolean;
  usesHtml: boolean;

  setFieldValue: <K extends keyof T>(fieldKey: K, value: T[K]) => void;
  setValidationErrors: (value: ValidationErrors<T> | undefined) => void;
  setConfig: (value: FormConfig<T>) => void;
  setLoading: (value: boolean) => void;
  setLastEvent: (value?: FormEvent) => void;
  setData: (value: T) => void;
  setHasChanged: (value: boolean) => void;
  setFormError: (value: string | undefined) => void;
  hasFieldChanged: <K extends keyof T>(fieldKey: K) => boolean;
  getFieldErrors: <K extends keyof T>(fieldKey: K) => string[] | undefined;
  getFieldValue: <K extends keyof T>(fieldKey: K) => T[K];
  dispatchFormEvent: (event: FormEvent, eventData: any) => void;
  registerFormEventListener: (event: FormEvent, handler: FormEventHandler) => () => void;
};
