import { ECOMMERCE_SERVICES_ADMIN_API_NAME } from '@core/apis/ecommerce-admin-api-instance';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Amplify, Hub } from 'aws-amplify';
import { AmplifyConfiguration } from '@villageco/nebula/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { BITEY_SERVICES_ADMIN_API_NAME } from '@core/apis/bitey-admin-api-instance';

const {
  VITE_COGNITO_USER_POOL_ID,
  VITE_COGNITO_USER_POOL_CLIENT_ID,
  VITE_COGNITO_IDENTITY_POOL_ID,
  VITE_COGNITO_USER_POOL_DOMAIN,
  VITE_COGNITO_USER_POOL_REGION,
  VITE_S3_BUCKET_NAME,
  VITE_S3_BUCKET_REGION,
  VITE_BASE_URL,
  VITE_ECOMMERCE_ADMIN_API_URL,
  VITE_BITEY_ADMIN_API_URL,
} = import.meta.env;

const queryClient = new QueryClient();

console.log('CHANGE FILE');
console.log(VITE_S3_BUCKET_NAME);
console.log(VITE_S3_BUCKET_REGION);

const ampConfig: AmplifyConfiguration = {
  // region: (import.meta as any).env.VITE_AWS_REGION || process?.env?.AWS_REGION,
  aws_project_region: VITE_COGNITO_USER_POOL_REGION,
  Auth: {
    identityPoolId: VITE_COGNITO_IDENTITY_POOL_ID,
    region: VITE_COGNITO_USER_POOL_REGION,
    userPoolId: VITE_COGNITO_USER_POOL_ID,
    userPoolWebClientId: VITE_COGNITO_USER_POOL_CLIENT_ID,

    oauth: {
      domain: VITE_COGNITO_USER_POOL_DOMAIN,
      scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: VITE_BASE_URL,
      redirectSignOut: VITE_BASE_URL,
      responseType: 'code',
    },
  },
  API: {
    endpoints: [
      {
        name: ECOMMERCE_SERVICES_ADMIN_API_NAME,
        endpoint: VITE_ECOMMERCE_ADMIN_API_URL,
        region: VITE_COGNITO_USER_POOL_REGION,
      },
      {
        name: BITEY_SERVICES_ADMIN_API_NAME,
        endpoint: VITE_BITEY_ADMIN_API_URL,
        region: VITE_COGNITO_USER_POOL_REGION,
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: VITE_S3_BUCKET_NAME,
      region: VITE_S3_BUCKET_REGION,
    },
  },
};

Hub.listen('auth', (cap: any) => console.log(JSON.stringify(cap)));

Amplify.configure(ampConfig); //TODO

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="602838692433-skqud0oantsbf3e9pa43o1omdhkenj8q.apps.googleusercontent.com">
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
