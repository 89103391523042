/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * admin-rest-api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  MembershipOffering,
  ValidationErrors,
  GetMembershipOfferingByIdParams,
  PatchMembershipOfferingByIdBody,
  DeleteMembershipOfferingByIdBody,
  PatchRestoreMembershipOfferingByIdBody,
  DeleteDestroyMembershipOfferingByIdBody,
  GetOneMembershipOfferingParams,
  MembershipOfferings,
  GetManyMembershipOfferingsParams,
  PostMembershipOfferingBody
} from '../ecommerce-services.schemas'
import { ecommerceAdminApiInstance } from '../../../apis/ecommerce-admin-api-instance'
import type { ErrorType } from '../../../apis/ecommerce-admin-api-instance'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Gets one Membership Offering by Id in path
 */
export const getMembershipOfferingById = (
    id: string,
    params?: GetMembershipOfferingByIdParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<MembershipOffering>(
      {url: `/membership-offering/${id}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetMembershipOfferingByIdQueryKey = (id: string,
    params?: GetMembershipOfferingByIdParams,) => [`/membership-offering/${id}`, ...(params ? [params]: [])];

    
export type GetMembershipOfferingByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getMembershipOfferingById>>>
export type GetMembershipOfferingByIdQueryError = ErrorType<void | ValidationErrors>

export const useGetMembershipOfferingById = <TData = Awaited<ReturnType<typeof getMembershipOfferingById>>, TError = ErrorType<void | ValidationErrors>>(
 id: string,
    params?: GetMembershipOfferingByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMembershipOfferingById>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMembershipOfferingByIdQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMembershipOfferingById>>> = ({ signal }) => getMembershipOfferingById(id,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMembershipOfferingById>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Patches one Membership Offering by Id in path
 */
export const patchMembershipOfferingById = (
    id: string,
    patchMembershipOfferingByIdBody: PatchMembershipOfferingByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipOffering>(
      {url: `/membership-offering/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchMembershipOfferingByIdBody
    },
      options);
    }
  


    export type PatchMembershipOfferingByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchMembershipOfferingById>>>
    export type PatchMembershipOfferingByIdMutationBody = PatchMembershipOfferingByIdBody
    export type PatchMembershipOfferingByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchMembershipOfferingById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchMembershipOfferingById>>, TError,{id: string;data: PatchMembershipOfferingByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchMembershipOfferingById>>, {id: string;data: PatchMembershipOfferingByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchMembershipOfferingById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchMembershipOfferingById>>, TError, {id: string;data: PatchMembershipOfferingByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Deletes one Membership Offering by Id in path
 */
export const deleteMembershipOfferingById = (
    id: string,
    deleteMembershipOfferingByIdBody: DeleteMembershipOfferingByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipOffering>(
      {url: `/membership-offering/${id}`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteMembershipOfferingByIdBody
    },
      options);
    }
  


    export type DeleteMembershipOfferingByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMembershipOfferingById>>>
    export type DeleteMembershipOfferingByIdMutationBody = DeleteMembershipOfferingByIdBody
    export type DeleteMembershipOfferingByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteMembershipOfferingById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMembershipOfferingById>>, TError,{id: string;data: DeleteMembershipOfferingByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMembershipOfferingById>>, {id: string;data: DeleteMembershipOfferingByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteMembershipOfferingById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteMembershipOfferingById>>, TError, {id: string;data: DeleteMembershipOfferingByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Restores one Membership Offering by Id in path
 */
export const patchRestoreMembershipOfferingById = (
    id: string,
    patchRestoreMembershipOfferingByIdBody: PatchRestoreMembershipOfferingByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipOffering>(
      {url: `/membership-offering/${id}/restore`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchRestoreMembershipOfferingByIdBody
    },
      options);
    }
  


    export type PatchRestoreMembershipOfferingByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchRestoreMembershipOfferingById>>>
    export type PatchRestoreMembershipOfferingByIdMutationBody = PatchRestoreMembershipOfferingByIdBody
    export type PatchRestoreMembershipOfferingByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchRestoreMembershipOfferingById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchRestoreMembershipOfferingById>>, TError,{id: string;data: PatchRestoreMembershipOfferingByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchRestoreMembershipOfferingById>>, {id: string;data: PatchRestoreMembershipOfferingByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchRestoreMembershipOfferingById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchRestoreMembershipOfferingById>>, TError, {id: string;data: PatchRestoreMembershipOfferingByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Destroys one Membership Offering by Id in path
 */
export const deleteDestroyMembershipOfferingById = (
    id: string,
    deleteDestroyMembershipOfferingByIdBody: DeleteDestroyMembershipOfferingByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipOffering>(
      {url: `/membership-offering/${id}/destroy`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteDestroyMembershipOfferingByIdBody
    },
      options);
    }
  


    export type DeleteDestroyMembershipOfferingByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDestroyMembershipOfferingById>>>
    export type DeleteDestroyMembershipOfferingByIdMutationBody = DeleteDestroyMembershipOfferingByIdBody
    export type DeleteDestroyMembershipOfferingByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteDestroyMembershipOfferingById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDestroyMembershipOfferingById>>, TError,{id: string;data: DeleteDestroyMembershipOfferingByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDestroyMembershipOfferingById>>, {id: string;data: DeleteDestroyMembershipOfferingByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteDestroyMembershipOfferingById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteDestroyMembershipOfferingById>>, TError, {id: string;data: DeleteDestroyMembershipOfferingByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Get one Membership Offerings
 */
export const getOneMembershipOffering = (
    params?: GetOneMembershipOfferingParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<MembershipOffering>(
      {url: `/membership-offering`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetOneMembershipOfferingQueryKey = (params?: GetOneMembershipOfferingParams,) => [`/membership-offering`, ...(params ? [params]: [])];

    
export type GetOneMembershipOfferingQueryResult = NonNullable<Awaited<ReturnType<typeof getOneMembershipOffering>>>
export type GetOneMembershipOfferingQueryError = ErrorType<void | ValidationErrors>

export const useGetOneMembershipOffering = <TData = Awaited<ReturnType<typeof getOneMembershipOffering>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetOneMembershipOfferingParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOneMembershipOffering>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOneMembershipOfferingQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneMembershipOffering>>> = ({ signal }) => getOneMembershipOffering(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOneMembershipOffering>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Get many Membership Offerings
 */
export const getManyMembershipOfferings = (
    params?: GetManyMembershipOfferingsParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<MembershipOfferings>(
      {url: `/membership-offerings`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetManyMembershipOfferingsQueryKey = (params?: GetManyMembershipOfferingsParams,) => [`/membership-offerings`, ...(params ? [params]: [])];

    
export type GetManyMembershipOfferingsQueryResult = NonNullable<Awaited<ReturnType<typeof getManyMembershipOfferings>>>
export type GetManyMembershipOfferingsQueryError = ErrorType<void | ValidationErrors>

export const useGetManyMembershipOfferings = <TData = Awaited<ReturnType<typeof getManyMembershipOfferings>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetManyMembershipOfferingsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getManyMembershipOfferings>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetManyMembershipOfferingsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getManyMembershipOfferings>>> = ({ signal }) => getManyMembershipOfferings(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getManyMembershipOfferings>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Posts a new Membership Offering
 */
export const postMembershipOffering = (
    postMembershipOfferingBody: PostMembershipOfferingBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipOffering>(
      {url: `/membership-offerings`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postMembershipOfferingBody
    },
      options);
    }
  


    export type PostMembershipOfferingMutationResult = NonNullable<Awaited<ReturnType<typeof postMembershipOffering>>>
    export type PostMembershipOfferingMutationBody = PostMembershipOfferingBody
    export type PostMembershipOfferingMutationError = ErrorType<void | ValidationErrors>

    export const usePostMembershipOffering = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMembershipOffering>>, TError,{data: PostMembershipOfferingBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMembershipOffering>>, {data: PostMembershipOfferingBody}> = (props) => {
          const {data} = props ?? {};

          return  postMembershipOffering(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMembershipOffering>>, TError, {data: PostMembershipOfferingBody}, TContext>(mutationFn, mutationOptions)
    }
    