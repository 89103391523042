import classnames from 'classnames';
import { NebPropsWithStd } from '@villageco/nebula/core';
import React, { FC, PropsWithChildren } from 'react';
import { Transition } from 'react-transition-group';

export type FadeProps = {
  duration?: number;
  in: boolean;
};

// const duration = 300;

export const Fade: FC<NebPropsWithStd<FadeProps>> = ({ in: inProp, children, className, duration = 300 }) => {
  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  };

  const nodeRef = React.useRef(null);

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
    unmounted: {},
  };

  return (
    <Transition nodeRef={nodeRef} in={inProp} timeout={duration} unmountOnExit={true} appear={true}>
      {(state) => (
        <div
          ref={nodeRef}
          className={classnames(className)}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};
