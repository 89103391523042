import { THH3 } from '@components/ui/th-h3';
import { ThScrollPage } from '@components/ui/th-scroll-page';
import {
  SalesOrder,
  ShipmentRun,
  ShipmentRuns,
} from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import { useGetShipmentRunById } from '@core/clients/ecommerce-services-admin/shipment-runs/shipment-runs';
import { setPageMeta } from '@core/stores/core-store';
import { Disclosure } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import { FunctionComponent } from 'react';
import { BsChevronBarDown, BsChevronBarUp } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { ConvertMembershipOrdersPanel } from '../../components/shipment-runs/convert-membership-orders-panel';
import { ecommcerceState } from '../../stores';

export type ShipmentRunDetailsPageProps = {};

export const ShipmentRunDetailsPage: FunctionComponent<ShipmentRunDetailsPageProps> = () => {
  setPageMeta('Shipment Runs', false);

  const { id: runId } = useParams();
  const queryClient = useQueryClient();
  const ecommerceSnap = useSnapshot(ecommcerceState);

  const isNew = runId === 'new';

  const { data, status, queryKey, error } = useGetShipmentRunById<
    ShipmentRun & {
      salesOrders: SalesOrder[];
    }
  >(
    runId!,
    {
      include: {
        salesOrders: true,
      },
    },
    {
      query: {
        enabled: !isNew,
        initialData: () => {
          return (
            ecommerceSnap.shipmentRunIndexQueryKey &&
            queryClient
              .getQueryData<ShipmentRuns>(ecommerceSnap.shipmentRunIndexQueryKey)
              ?.items?.find((r) => r.id === runId)
          );
        },
      },
    },
  );

  // const { formattedDate: runDay } = useStringDateTimeFormatter(data?.createdAt, 'en-AU', {
  //   day: '2-digit',
  //   month: 'long',
  //   year: 'numeric',
  // });

  return (
    <ThScrollPage id="shipment-run-details-page">
      <div className="bg-th-ecommerce-100 w-full h-full rounded p-2">
        {(status === 'success' || isNew) && (
          <div>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between rounded-lg bg-th-ecommerce-50 px-4 py-2 text-left text-sm font-medium text-th-ecommerce-900 hover:bg-th-ecommerce-200 focus:outline-none focus-visible:ring focus-visible:ring-th-ecommerce-500 focus-visible:ring-opacity-75">
                    <THH3>Create Shipment Order</THH3>
                    <BsChevronBarDown
                      className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-th-ecommerce-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 bg-gray-100 rounded">
                    <ConvertMembershipOrdersPanel className=""></ConvertMembershipOrdersPanel>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <Disclosure as="div" className="mt-2">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between rounded-lg bg-th-ecommerce-50 px-4 py-2 text-left text-sm font-medium text-th-ecommerce-900 hover:bg-th-ecommerce-200 focus:outline-none focus-visible:ring focus-visible:ring-th-ecommerce-500 focus-visible:ring-opacity-75">
                    <THH3>Create Shipment Order</THH3>
                    <BsChevronBarUp className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-th-ecommerce-500`} />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">No.</Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        )}
        {status === 'loading' && !isNew && (
          <div>
            <div className="bg-gray-200 animate-pulse rounded w-full h-10"></div>
            <div className="bg-gray-200 animate-pulse rounded w-full h-64 mt-4"></div>
          </div>
        )}
        {status === 'error' && (
          <div>
            <THH3>An error has occurred trying to retrieve shipment run</THH3>
            <p>{error}</p>
          </div>
        )}
      </div>
    </ThScrollPage>
  );
};
