/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * admin-rest-api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Page,
  ValidationErrors,
  GetPageByIdParams,
  PatchPageByIdBody,
  DeletePageByIdBody,
  PatchRestorePageByIdBody,
  DeleteDestroyPageByIdBody,
  GetOnePageParams,
  Pages,
  GetManyPagesParams,
  PostPageBody
} from '../bitey-services.schemas'
import { biteyAdminApiInstance } from '../../../apis/bitey-admin-api-instance'
import type { ErrorType } from '../../../apis/bitey-admin-api-instance'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Gets one Page by Id in path
 */
export const getPageById = (
    id: string,
    params?: GetPageByIdParams,
 options?: SecondParameter<typeof biteyAdminApiInstance>,signal?: AbortSignal
) => {
      return biteyAdminApiInstance<Page>(
      {url: `/page/${id}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetPageByIdQueryKey = (id: string,
    params?: GetPageByIdParams,) => [`/page/${id}`, ...(params ? [params]: [])];

    
export type GetPageByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getPageById>>>
export type GetPageByIdQueryError = ErrorType<void | ValidationErrors>

export const useGetPageById = <TData = Awaited<ReturnType<typeof getPageById>>, TError = ErrorType<void | ValidationErrors>>(
 id: string,
    params?: GetPageByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPageById>>, TError, TData>, request?: SecondParameter<typeof biteyAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPageByIdQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPageById>>> = ({ signal }) => getPageById(id,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPageById>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Patches one Page by Id in path
 */
export const patchPageById = (
    id: string,
    patchPageByIdBody: PatchPageByIdBody,
 options?: SecondParameter<typeof biteyAdminApiInstance>,) => {
      return biteyAdminApiInstance<Page>(
      {url: `/page/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchPageByIdBody
    },
      options);
    }
  


    export type PatchPageByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchPageById>>>
    export type PatchPageByIdMutationBody = PatchPageByIdBody
    export type PatchPageByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchPageById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchPageById>>, TError,{id: string;data: PatchPageByIdBody}, TContext>, request?: SecondParameter<typeof biteyAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchPageById>>, {id: string;data: PatchPageByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchPageById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchPageById>>, TError, {id: string;data: PatchPageByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Deletes one Page by Id in path
 */
export const deletePageById = (
    id: string,
    deletePageByIdBody: DeletePageByIdBody,
 options?: SecondParameter<typeof biteyAdminApiInstance>,) => {
      return biteyAdminApiInstance<Page>(
      {url: `/page/${id}`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deletePageByIdBody
    },
      options);
    }
  


    export type DeletePageByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deletePageById>>>
    export type DeletePageByIdMutationBody = DeletePageByIdBody
    export type DeletePageByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeletePageById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePageById>>, TError,{id: string;data: DeletePageByIdBody}, TContext>, request?: SecondParameter<typeof biteyAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePageById>>, {id: string;data: DeletePageByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deletePageById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deletePageById>>, TError, {id: string;data: DeletePageByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Restores one Page by Id in path
 */
export const patchRestorePageById = (
    id: string,
    patchRestorePageByIdBody: PatchRestorePageByIdBody,
 options?: SecondParameter<typeof biteyAdminApiInstance>,) => {
      return biteyAdminApiInstance<Page>(
      {url: `/page/${id}/restore`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchRestorePageByIdBody
    },
      options);
    }
  


    export type PatchRestorePageByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchRestorePageById>>>
    export type PatchRestorePageByIdMutationBody = PatchRestorePageByIdBody
    export type PatchRestorePageByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchRestorePageById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchRestorePageById>>, TError,{id: string;data: PatchRestorePageByIdBody}, TContext>, request?: SecondParameter<typeof biteyAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchRestorePageById>>, {id: string;data: PatchRestorePageByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchRestorePageById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchRestorePageById>>, TError, {id: string;data: PatchRestorePageByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Destroys one Page by Id in path
 */
export const deleteDestroyPageById = (
    id: string,
    deleteDestroyPageByIdBody: DeleteDestroyPageByIdBody,
 options?: SecondParameter<typeof biteyAdminApiInstance>,) => {
      return biteyAdminApiInstance<Page>(
      {url: `/page/${id}/destroy`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteDestroyPageByIdBody
    },
      options);
    }
  


    export type DeleteDestroyPageByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDestroyPageById>>>
    export type DeleteDestroyPageByIdMutationBody = DeleteDestroyPageByIdBody
    export type DeleteDestroyPageByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteDestroyPageById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDestroyPageById>>, TError,{id: string;data: DeleteDestroyPageByIdBody}, TContext>, request?: SecondParameter<typeof biteyAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDestroyPageById>>, {id: string;data: DeleteDestroyPageByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteDestroyPageById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteDestroyPageById>>, TError, {id: string;data: DeleteDestroyPageByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Get one Pages
 */
export const getOnePage = (
    params?: GetOnePageParams,
 options?: SecondParameter<typeof biteyAdminApiInstance>,signal?: AbortSignal
) => {
      return biteyAdminApiInstance<Page>(
      {url: `/page`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetOnePageQueryKey = (params?: GetOnePageParams,) => [`/page`, ...(params ? [params]: [])];

    
export type GetOnePageQueryResult = NonNullable<Awaited<ReturnType<typeof getOnePage>>>
export type GetOnePageQueryError = ErrorType<void | ValidationErrors>

export const useGetOnePage = <TData = Awaited<ReturnType<typeof getOnePage>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetOnePageParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOnePage>>, TError, TData>, request?: SecondParameter<typeof biteyAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOnePageQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOnePage>>> = ({ signal }) => getOnePage(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOnePage>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Get many Pages
 */
export const getManyPages = (
    params?: GetManyPagesParams,
 options?: SecondParameter<typeof biteyAdminApiInstance>,signal?: AbortSignal
) => {
      return biteyAdminApiInstance<Pages>(
      {url: `/pages`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetManyPagesQueryKey = (params?: GetManyPagesParams,) => [`/pages`, ...(params ? [params]: [])];

    
export type GetManyPagesQueryResult = NonNullable<Awaited<ReturnType<typeof getManyPages>>>
export type GetManyPagesQueryError = ErrorType<void | ValidationErrors>

export const useGetManyPages = <TData = Awaited<ReturnType<typeof getManyPages>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetManyPagesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getManyPages>>, TError, TData>, request?: SecondParameter<typeof biteyAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetManyPagesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getManyPages>>> = ({ signal }) => getManyPages(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getManyPages>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Posts a new Page
 */
export const postPage = (
    postPageBody: PostPageBody,
 options?: SecondParameter<typeof biteyAdminApiInstance>,) => {
      return biteyAdminApiInstance<Page>(
      {url: `/pages`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postPageBody
    },
      options);
    }
  


    export type PostPageMutationResult = NonNullable<Awaited<ReturnType<typeof postPage>>>
    export type PostPageMutationBody = PostPageBody
    export type PostPageMutationError = ErrorType<void | ValidationErrors>

    export const usePostPage = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postPage>>, TError,{data: PostPageBody}, TContext>, request?: SecondParameter<typeof biteyAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postPage>>, {data: PostPageBody}> = (props) => {
          const {data} = props ?? {};

          return  postPage(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postPage>>, TError, {data: PostPageBody}, TContext>(mutationFn, mutationOptions)
    }
    