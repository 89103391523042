import { ThScrollPage } from '@components/ui/th-scroll-page';
import {
  useDeleteLinkById,
  useGetLinkById,
  usePatchRestoreLinkById,
} from '@core/clients/bitey-services-admin/links/links';
import { setPageMeta } from '@core/stores/core-store';
import { FC, useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { MdOutlineEdit } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { LinkEditForm } from '../../components/links/link-edit-form';
import { LinkViewFormSkeleton } from '../../components/links/link-view-form-skeleton';
import { LinkViewForm } from '../../components/links/link-view-form';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { FaSpinner, FaTrash, FaTrashRestore } from 'react-icons/fa';
import { BiTrash } from 'react-icons/bi';

export type LinkDetailsPageProps = {};

export const LinkDetailsPage: FC<LinkDetailsPageProps> = () => {
  setPageMeta('Links', false);

  const queryClient = useQueryClient();
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const { id: linkId } = useParams();

  const isNew = linkId === 'new';

  const {
    data: link,
    isLoading: linkLoading,
    queryKey: linkQueryKey,
  } = useGetLinkById(
    linkId!,
    {
      withTrashed: true,
    },
    {
      query: {
        enabled: !isNew,
      },
    },
  );

  const mutation = {
    onSuccess: () => {
      queryClient.invalidateQueries(linkQueryKey);
    },
  };

  const { mutateAsync: deleteLinkMut, isLoading: deleteLoading } = useDeleteLinkById({
    mutation,
  });

  const { mutateAsync: restoreLinkMut, isLoading: restoreLoading } = usePatchRestoreLinkById({
    mutation,
  });

  useEffect(() => {
    setEditing(!link);
    // setEditing(true);
  }, [link]);

  const editClicked = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    setEditing(true);
  };

  const resetClicked = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    setEditing(false);
  };

  const deleteClicked = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    setDeleting(true);
  };

  const deleteConfirmClicked = async (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    await deleteLinkMut({ id: linkId!, data: {} });
    setDeleting(false);
  };

  const restoreClicked = async (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    await restoreLinkMut({ id: linkId!, data: {} });
  };

  return (
    <ThScrollPage id="link-details-page">
      {!isNew && linkLoading ? (
        <LinkViewFormSkeleton></LinkViewFormSkeleton>
      ) : (
        <div className="bg-th-bitey-200 rounded p-4 relative">
          <div className="flex mb-4">
            {!!link && (
              <div className="bg-slate-200 text-slate-600 font-semibold self-start rounded p-1 px-2">
                ID: <span>{link.id}</span>
              </div>
            )}
            {link?.deletedAt && (
              <div className="bg-red-200 text-red-600 font-semibold self-start rounded p-1 px-2 ml-2">Deleted</div>
            )}
            {!!link &&
              (editing ? (
                <button
                  className="ml-auto h-12 rounded border-2 border-th-bitey-500 w-28 self-stretch text-th-bitey-600 font-semibold flex items-center justify-around px-2"
                  onClick={resetClicked}
                >
                  <IoMdClose />
                  Cancel
                </button>
              ) : (
                <>
                  {link.deletedAt && (
                    <button
                      className="ml-auto h-12 rounded bg-th-bitey-500 w-28 self-stretch text-white font-semibold flex items-center justify-around px-2"
                      onClick={restoreClicked}
                    >
                      <FaTrashRestore className="" />
                      Restore
                    </button>
                  )}
                  {!link.deletedAt && (
                    <>
                      <div className="ml-auto flex">
                        <button
                          className={classNames(
                            'h-12 rounded bg-red-500  self-stretch transition-width duration-200 text-white font-semibold flex items-center justify-around overflow-hidden',
                            {
                              'w-0': deleting,
                              'w-28 px-2': !deleting,
                            },
                          )}
                          onClick={deleteClicked}
                        >
                          <FaTrash className="" />
                          Trash
                        </button>
                        <button
                          className={classNames(
                            'h-12 rounded bg-red-100 self-stretch transition-width duration-200 text-red-600 font-semibold flex items-center justify-around overflow-hidden',
                            {
                              'w-0': !deleting,
                              'w-40 px-2': deleting,
                            },
                          )}
                          onClick={deleteConfirmClicked}
                        >
                          <FaTrash className="" />
                          Confirm Delete
                        </button>
                      </div>

                      <button
                        className="ml-4 h-12 rounded bg-th-bitey-500 w-28 self-stretch text-white font-semibold flex items-center justify-around px-2"
                        onClick={editClicked}
                      >
                        <MdOutlineEdit className="w-5 h-5" />
                        Edit
                      </button>
                    </>
                  )}
                </>
              ))}
          </div>
          {editing || !link ? (
            <LinkEditForm link={link} queryKey={linkQueryKey}></LinkEditForm>
          ) : (
            <LinkViewForm link={link}></LinkViewForm>
          )}
          {(deleteLoading || restoreLoading) && (
            <div className="absolute w-full h-full bg-white z-40 inset-0 rounded opacity-60 animate-pulse flex items-center justify-center text-2xl text-th-bitey-600 flex-col">
              <FaSpinner className="animate-spin mb-2"></FaSpinner>
              {deleteLoading && 'Deleting'}
              {restoreLoading && 'Restoring'}
            </div>
          )}
        </div>
      )}
    </ThScrollPage>
  );
};
