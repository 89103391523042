/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * admin-rest-api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  ProductVariant,
  ValidationErrors,
  GetProductVariantByIdParams,
  PatchProductVariantByIdBody,
  DeleteProductVariantByIdBody,
  PatchRestoreProductVariantByIdBody,
  DeleteDestroyProductVariantByIdBody,
  GetOneProductVariantParams,
  ProductVariants,
  GetManyProductVariantsParams,
  PostProductVariantBody
} from '../ecommerce-services.schemas'
import { ecommerceAdminApiInstance } from '../../../apis/ecommerce-admin-api-instance'
import type { ErrorType } from '../../../apis/ecommerce-admin-api-instance'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Gets one Product Variant by Id in path
 */
export const getProductVariantById = (
    id: string,
    params?: GetProductVariantByIdParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<ProductVariant>(
      {url: `/product-variant/${id}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetProductVariantByIdQueryKey = (id: string,
    params?: GetProductVariantByIdParams,) => [`/product-variant/${id}`, ...(params ? [params]: [])];

    
export type GetProductVariantByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getProductVariantById>>>
export type GetProductVariantByIdQueryError = ErrorType<void | ValidationErrors>

export const useGetProductVariantById = <TData = Awaited<ReturnType<typeof getProductVariantById>>, TError = ErrorType<void | ValidationErrors>>(
 id: string,
    params?: GetProductVariantByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductVariantById>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProductVariantByIdQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductVariantById>>> = ({ signal }) => getProductVariantById(id,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getProductVariantById>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Patches one Product Variant by Id in path
 */
export const patchProductVariantById = (
    id: string,
    patchProductVariantByIdBody: PatchProductVariantByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<ProductVariant>(
      {url: `/product-variant/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchProductVariantByIdBody
    },
      options);
    }
  


    export type PatchProductVariantByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchProductVariantById>>>
    export type PatchProductVariantByIdMutationBody = PatchProductVariantByIdBody
    export type PatchProductVariantByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchProductVariantById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchProductVariantById>>, TError,{id: string;data: PatchProductVariantByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchProductVariantById>>, {id: string;data: PatchProductVariantByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchProductVariantById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchProductVariantById>>, TError, {id: string;data: PatchProductVariantByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Deletes one Product Variant by Id in path
 */
export const deleteProductVariantById = (
    id: string,
    deleteProductVariantByIdBody: DeleteProductVariantByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<ProductVariant>(
      {url: `/product-variant/${id}`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteProductVariantByIdBody
    },
      options);
    }
  


    export type DeleteProductVariantByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProductVariantById>>>
    export type DeleteProductVariantByIdMutationBody = DeleteProductVariantByIdBody
    export type DeleteProductVariantByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteProductVariantById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProductVariantById>>, TError,{id: string;data: DeleteProductVariantByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProductVariantById>>, {id: string;data: DeleteProductVariantByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteProductVariantById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteProductVariantById>>, TError, {id: string;data: DeleteProductVariantByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Restores one Product Variant by Id in path
 */
export const patchRestoreProductVariantById = (
    id: string,
    patchRestoreProductVariantByIdBody: PatchRestoreProductVariantByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<ProductVariant>(
      {url: `/product-variant/${id}/restore`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchRestoreProductVariantByIdBody
    },
      options);
    }
  


    export type PatchRestoreProductVariantByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchRestoreProductVariantById>>>
    export type PatchRestoreProductVariantByIdMutationBody = PatchRestoreProductVariantByIdBody
    export type PatchRestoreProductVariantByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchRestoreProductVariantById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchRestoreProductVariantById>>, TError,{id: string;data: PatchRestoreProductVariantByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchRestoreProductVariantById>>, {id: string;data: PatchRestoreProductVariantByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchRestoreProductVariantById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchRestoreProductVariantById>>, TError, {id: string;data: PatchRestoreProductVariantByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Destroys one Product Variant by Id in path
 */
export const deleteDestroyProductVariantById = (
    id: string,
    deleteDestroyProductVariantByIdBody: DeleteDestroyProductVariantByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<ProductVariant>(
      {url: `/product-variant/${id}/destroy`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteDestroyProductVariantByIdBody
    },
      options);
    }
  


    export type DeleteDestroyProductVariantByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDestroyProductVariantById>>>
    export type DeleteDestroyProductVariantByIdMutationBody = DeleteDestroyProductVariantByIdBody
    export type DeleteDestroyProductVariantByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteDestroyProductVariantById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDestroyProductVariantById>>, TError,{id: string;data: DeleteDestroyProductVariantByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDestroyProductVariantById>>, {id: string;data: DeleteDestroyProductVariantByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteDestroyProductVariantById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteDestroyProductVariantById>>, TError, {id: string;data: DeleteDestroyProductVariantByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Get one Product Variants
 */
export const getOneProductVariant = (
    params?: GetOneProductVariantParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<ProductVariant>(
      {url: `/product-variant`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetOneProductVariantQueryKey = (params?: GetOneProductVariantParams,) => [`/product-variant`, ...(params ? [params]: [])];

    
export type GetOneProductVariantQueryResult = NonNullable<Awaited<ReturnType<typeof getOneProductVariant>>>
export type GetOneProductVariantQueryError = ErrorType<void | ValidationErrors>

export const useGetOneProductVariant = <TData = Awaited<ReturnType<typeof getOneProductVariant>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetOneProductVariantParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOneProductVariant>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOneProductVariantQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneProductVariant>>> = ({ signal }) => getOneProductVariant(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOneProductVariant>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Get many Product Variants
 */
export const getManyProductVariants = (
    params?: GetManyProductVariantsParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<ProductVariants>(
      {url: `/product-variants`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetManyProductVariantsQueryKey = (params?: GetManyProductVariantsParams,) => [`/product-variants`, ...(params ? [params]: [])];

    
export type GetManyProductVariantsQueryResult = NonNullable<Awaited<ReturnType<typeof getManyProductVariants>>>
export type GetManyProductVariantsQueryError = ErrorType<void | ValidationErrors>

export const useGetManyProductVariants = <TData = Awaited<ReturnType<typeof getManyProductVariants>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetManyProductVariantsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getManyProductVariants>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetManyProductVariantsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getManyProductVariants>>> = ({ signal }) => getManyProductVariants(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getManyProductVariants>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Posts a new Product Variant
 */
export const postProductVariant = (
    postProductVariantBody: PostProductVariantBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<ProductVariant>(
      {url: `/product-variants`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postProductVariantBody
    },
      options);
    }
  


    export type PostProductVariantMutationResult = NonNullable<Awaited<ReturnType<typeof postProductVariant>>>
    export type PostProductVariantMutationBody = PostProductVariantBody
    export type PostProductVariantMutationError = ErrorType<void | ValidationErrors>

    export const usePostProductVariant = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProductVariant>>, TError,{data: PostProductVariantBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProductVariant>>, {data: PostProductVariantBody}> = (props) => {
          const {data} = props ?? {};

          return  postProductVariant(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postProductVariant>>, TError, {data: PostProductVariantBody}, TContext>(mutationFn, mutationOptions)
    }
    