import { FC, PropsWithChildren } from 'react';
import { NebPropsWithStd, Tooltip } from '@villageco/nebula/core';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { link } from 'fs';
import { AiFillAndroid } from 'react-icons/ai';
import { BsApple } from 'react-icons/bs';
import { FiTag, FiExternalLink } from 'react-icons/fi';
import { GoBrowser } from 'react-icons/go';
import { Link } from '@core/clients/bitey-services-admin/bitey-services.schemas';

export type LinkViewFormProps = {
  link: Link;
};

export const LinkViewForm: FC<NebPropsWithStd<LinkViewFormProps>> = ({ link }) => {
  return (
    <div className="link-view-form">
      <div className="flex w-full">
        <h3 className="text-2xl font-semibold text-th-bitey-900 mr-4">{link.name}</h3>

        <Tooltip content={dayjs(link.createdAt).format('DD-MM-YYYY h:mma')} className="ml-auto">
          <div className=" rounded px-2 py-1 bg-th-bitey-100 text-th-bitey-900 font-semibold w-64 justify-around items-center flex">
            <span className="text-sm uppercase tracking-tighter text-th-bitey-700">created</span>
            <span className="ml-2">{dayjs(link.createdAt).format('DD MMMM YYYY')}</span>
          </div>
        </Tooltip>
        <Tooltip content={dayjs(link.updatedAt).format('DD-MM-YYYY h:mma')} className="ml-2">
          <div className="rounded px-2 py-1 bg-th-bitey-100 text-th-bitey-900 font-semibold w-64 justify-around items-center flex">
            <span className="text-sm uppercase tracking-tighter text-th-bitey-700">updated</span>
            <span className="ml-2">{dayjs(link.updatedAt).format('DD MMMM YYYY')}</span>
          </div>
        </Tooltip>
        {link.deletedAt && (
          <Tooltip content={dayjs(link.updatedAt).format('DD-MM-YYYY h:mma')} className="ml-2">
            <div className="rounded px-2 py-1 bg-th-bitey-100 text-th-bitey-900 font-semibold w-64 justify-around items-center flex">
              <span className="text-sm uppercase tracking-tighter text-th-bitey-700">deleted</span>
              <span className="ml-2">{dayjs(link.updatedAt).format('DD MMMM YYYY')}</span>
            </div>
          </Tooltip>
        )}
      </div>
      <div className="flex mt-2 flex-wrap space-y-2 items-end -ml-1">
        {(link?.tags?.length ? link.tags : ['nndasjk10', 'dbnjasbhj']).map((tag) => (
          <div
            key={tag}
            className="bg-slate-100 whitespace-nowrap text-sm text-slate-800 font-semibold rounded p-1 px-2 ml-1 flex items-center"
          >
            <FiTag className="mr-2" />
            {tag}
          </div>
        ))}
      </div>
      <div className="flex mt-4">
        <div className="flex items-center bg-th-bitey-100 p-2 rounded">
          <span className="text-th-bitey-700 font-semibold text-sm uppercase tracking-tighter">URL</span>
          <div className="font-thin text-sm text-th-bitey-900 ml-4  rounded py-1 px-2 truncate">
            https://grubl.ink/<span className="text-base font-semibold">{link.path}</span>
          </div>
        </div>
      </div>
      <div className="flex w-full mt-2 space-x-4">
        <div className="flex flex-col w-1/2">
          <div className="flex items-start bg-th-bitey-100 p-2 flex-col rounded w-full">
            <span className="text-th-bitey-700 font-semibold text-sm uppercase tracking-tighter">Description</span>
            <p className="mt-2 text-th-bitey-900">{link.description || '- No description -'} </p>
          </div>
        </div>
        <div className="flex flex-col w-1/2">
          <div className="flex items-start bg-th-bitey-100 p-2 flex-col rounded w-full">
            <span className="text-th-bitey-700 font-semibold text-sm uppercase tracking-tighter">Destinations</span>
            <div className="flex flex-col w-full mt-4 space-y-2">
              {link.page ? (
                <div>Page</div>
              ) : (
                <>
                  <div className="w-full flex">
                    <div className="flex w-32 items-center ml-2 text-th-bitey-800">
                      <BsApple className="w-5 h-5"></BsApple>
                      <span className="text-medium ml-2 text-sm mr-6">iOS/Mac</span>
                    </div>
                    <a
                      href={link.iosUrl ?? ''}
                      target="_blank"
                      onClick={(evt) => {
                        !link.iosUrl && evt.preventDefault();
                        evt.stopPropagation();
                      }}
                      className={classNames('flex w-full  rounded p-2  items-center', {
                        'bg-th-bitey-300 text-white cursor-pointer ': link.iosUrl,
                        'cursor-default text-th-bitey-900': !link.iosUrl,
                      })}
                    >
                      <span className="text-sm ">{link.iosUrl}</span>
                      {link.iosUrl && <FiExternalLink className="ml-auto" />}
                    </a>
                  </div>

                  <div className="w-full flex">
                    <div className="flex w-32 items-center ml-2 text-th-bitey-800">
                      <AiFillAndroid className="w-6 h-6"> </AiFillAndroid>
                      <span className="text-medium ml-2 text-sm mr-6">Android</span>
                    </div>
                    <a
                      href={link.androidUrl ?? ''}
                      target="_blank"
                      onClick={(evt) => {
                        !link.androidUrl && evt.preventDefault();
                        evt.stopPropagation();
                      }}
                      className={classNames('flex w-full  rounded p-2  items-center', {
                        'bg-th-bitey-300 text-white cursor-pointer': link.androidUrl,
                        'cursor-default text-th-bitey-900': !link.androidUrl,
                      })}
                    >
                      <span className="text-sm ">{link.androidUrl}</span>
                      {link.androidUrl && <FiExternalLink className="ml-auto" />}
                    </a>
                  </div>

                  <div className="w-full flex">
                    <div className="flex w-32 items-center ml-2 text-th-bitey-800">
                      <GoBrowser className="w-5 h-5"> </GoBrowser>
                      <span className="text-medium ml-2 text-sm mr-6">Default</span>
                    </div>
                    <a
                      href={link.defaultUrl ?? ''}
                      target="_blank"
                      onClick={(evt) => {
                        !link.defaultUrl && evt.preventDefault();
                        evt.stopPropagation();
                      }}
                      className={classNames('flex w-full rounded p-2 items-center', {
                        'bg-th-bitey-300 text-white cursor-pointer': link.defaultUrl,
                        'cursor-default text-th-bitey-900': !link.defaultUrl,
                      })}
                    >
                      <span className="text-sm ">{link.defaultUrl}</span>
                      {link.defaultUrl && <FiExternalLink className="ml-auto" />}
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
