import { FC } from 'react';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { foyerState } from '../../modules/foyer/stores';
import logoUrl from '../../assets/TownHall-02.png';

export type NavLogoProps = {};

export const NavLogo: FC<NebPropsWithStd<NavLogoProps>> = () => {
  return (
    <a
      type="button"
      href="/"
      onClick={(e) => {
        e.preventDefault();
        foyerState.showFoyer = true;
      }}
      className="h-full overflow-hidden flex items-center justify-center rounded"
    >
      <img className="object-cover w-full mb-2 h-36" src={logoUrl}></img>
    </a>
  );
};
