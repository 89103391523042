import { RouteObject } from 'react-router';
import { MembershipSidebarMenu } from '../components/core/membership-sidebar-menu';
import { MembershipHomePage } from '../pages';
import { AccountsIndexPage } from '../pages/accounts';
import { MembershipOrdersIndexPage } from '../pages/membership-orders';
import { MembershipOrderDetailsPage } from '../pages/membership-orders/[id]';
import { MembershipsIndexPage } from '../pages/memberships';
import { MembershipDetailsPage } from '../pages/memberships/[id]';
import { OfferingsIndexPage } from '../pages/offerings';
import { OfferingDetailsPage } from '../pages/offerings/[id]';

export enum MembershipRoutePaths {
  ROOT = '/membership',
  ACCOUNTS_INDEX = '/membership/accounts',
  ACCOUNT_DETAILS = '/membership/account/',
  MEMBERSHIPS_INDEX = '/membership/memberships',
  MEMBERSHIP_DETAILS = '/membership/membership/',
  OFFERINGS_INDEX = '/membership/offerings',
  OFFERING_DETAILS = '/membership/offering/',
  MEMBERSHIP_ORDERS_INDEX = '/membership/orders',
  MEMBERSHIP_ORDER_DETAILS = '/membership/order/',
}

export const membershipRoutes: RouteObject[] = [
  {
    path: '/membership/*',
    children: [
      { path: 'accounts', element: <AccountsIndexPage /> },

      { path: 'memberships', element: <MembershipsIndexPage /> },
      {
        path: 'membership/:id',
        element: <MembershipDetailsPage />,
      },
      { path: 'offerings', element: <OfferingsIndexPage /> },
      {
        path: 'offering/:id',
        element: <OfferingDetailsPage />,
      },
      { path: 'orders', element: <MembershipOrdersIndexPage /> },
      {
        path: 'order/:id',
        element: <MembershipOrderDetailsPage />,
      },
      { index: true, element: <MembershipHomePage /> },
    ],
  },
];

export const membershipSidebarRoutes: RouteObject[] = [{ path: '/membership/*', element: <MembershipSidebarMenu /> }];
