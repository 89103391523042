import { proxy } from 'valtio';
import { CoreStoreData } from './types';

export const coreStore = proxy<CoreStoreData>({
  dashboardHeader: '',
  isModuleHome: true,
  headerPanelClassName: '',
});

export const setPageMeta = (header?: string, isHome?: boolean, panelClass?: string) => {
  coreStore.dashboardHeader = header;
  coreStore.isModuleHome = isHome ?? false;
  coreStore.headerPanelClassName = panelClass;
};
