import { ThScrollPage } from '@components/ui/th-scroll-page';
import { useGetManySalesOrders } from '@core/clients/ecommerce-services-admin/sales-orders/sales-orders';
import { useSelectedSet } from '@core/hooks/useSelectedSet';
import { setPageMeta } from '@core/stores/core-store';
import { FunctionComponent } from 'react';
import { OrderCard, OrderWithRelated } from '../../components/orders/order-card';
// import { useOrders } from '../../hooks/useOrders';

export type OrdersIndexPageProps = {};

export const OrdersIndexPage: FunctionComponent<OrdersIndexPageProps> = () => {
  setPageMeta('Orders', false);

  const { selectedSet, selectClickHandler } = useSelectedSet();

  const { data, status } = useGetManySalesOrders<{ items: OrderWithRelated[] }>({
    include: { account: true, contact: true, shipmentRun: true, lineItems: true, membershipOrder: true },
  });

  return (
    <ThScrollPage id="orders-index-page">
      {/* <div>Controls</div> */}
      <div className="h-full w-full rounded bg-th-ecommerce-100 p-2">
        {data?.items?.map((o) => (
          <OrderCard
            order={o}
            selected={selectedSet.has(o.id!)}
            onSelectClicked={selectClickHandler(o.id!)}
            className="bg-white rounded mb-2 p-2"
          ></OrderCard>
        ))}
      </div>
    </ThScrollPage>
  );
};
