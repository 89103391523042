import API from '@aws-amplify/api';
import { Auth } from 'aws-amplify';
import type { AxiosError, AxiosRequestConfig } from 'axios';
import { isObject } from 'lodash';
import { resolve } from 'path';

export const ECOMMERCE_SERVICES_ADMIN_API_NAME = 'EcommerceServicesAdminAPI';

export const ecommerceAdminApiInstance = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
  const { method } = config;

  const init = generateInit(config);
  const promise = new Promise<T>(async (resolve, reject) => {
    let res: any;

    try {
      switch (method) {
        case 'get':
          res = await API.get(ECOMMERCE_SERVICES_ADMIN_API_NAME, config.url!, init);
          break;
        case 'post':
          res = await API.post(ECOMMERCE_SERVICES_ADMIN_API_NAME, config.url!, init);
          break;
        case 'put':
          res = await API.put(ECOMMERCE_SERVICES_ADMIN_API_NAME, config.url!, init);
          break;
        case 'patch':
          res = await API.patch(ECOMMERCE_SERVICES_ADMIN_API_NAME, config.url!, init);
          break;
        case 'delete':
          res = await API.del(ECOMMERCE_SERVICES_ADMIN_API_NAME, config.url!, init);
          break;
        default:
          reject(`Invalid API instance configuration. Invalid method ${method}`);
      }
    } catch (error) {
      reject(error);
    }
    resolve(res);
  });

  // @ts-ignore
  promise.cancel = () => {
    API.cancel(promise, 'Query was cancelled by React Query');
  };

  return promise;
};

const generateInit = (config: AxiosRequestConfig): any => {
  const queryStringParameters =
    config.params &&
    Object.entries(config.params).reduce((acc, [k, v]) => ({ ...acc, [k]: isObject(v) ? JSON.stringify(v) : v }), {});

  return {
    headers: config.headers,
    queryStringParameters,
    body: config.data,
  };
};

export type ErrorType<Error> = AxiosError<Error>;
