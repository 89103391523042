import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { useFormState } from '../../hooks';
import { FormErrorMessage } from './form-error-message';
import { FormEvent } from '../../contexts';
import { FormSuccessMessage } from './form-success-message';

export type FormOutputProps = {};

export const FormOutput: FC<NebPropsWithStd<FormOutputProps>> = ({ className }) => {
  const { formError, registerFormEventListener } = useFormState();

  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    const unsub = registerFormEventListener(FormEvent.SUBMIT_SUCCESS, () => {
      setShowSuccess(true);

      setTimeout(() => setShowSuccess(false), 3000);
    });

    return () => {
      unsub();
    };
  }, []);

  return (
    <div className={className}>
      {showSuccess && <FormSuccessMessage>Saved</FormSuccessMessage>}
      {formError && <FormErrorMessage>{formError}</FormErrorMessage>}
    </div>
  );
};
