import { GoogleOAuthProvider } from '@react-oauth/google';
import { createContext, FC, PropsWithChildren, useState } from 'react';
import { GoogleUser, OnAfterLoginFunction, OnAfterLogoutFunction, UnauthenticatedComponent } from '../types';
import { GoogleAuthContextData } from './types';

export type GoogleAuthContextProps = {
  clientId: string;
  unnauthenticatedElement: UnauthenticatedComponent;
  onAfterLogout: OnAfterLogoutFunction;
};

export const googleAuthContext = createContext<GoogleAuthContextData>(undefined!);

export const GoogleAuthProvider: FC<PropsWithChildren<GoogleAuthContextProps>> = ({
  children,
  clientId,
  onAfterLogout,
  unnauthenticatedElement,
}) => {
  const [user, setUser] = useState<GoogleUser | null>(null);
  const [token, setToken] = useState<string | null>(null);

  const value: GoogleAuthContextData = {
    user,
    setUser,
    token,
    setToken,
    onAfterLogout,
    unnauthenticatedElement,
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <googleAuthContext.Provider value={value}>{children}</googleAuthContext.Provider>
    </GoogleOAuthProvider>
  );
};
