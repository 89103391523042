import { FC } from 'react';
import classNames from 'classnames';
import { useFormState } from '../../hooks';
import { MdError } from 'react-icons/md';
import { NebPropsWithStd } from '@villageco/nebula/core';

export type FormErrorMessageProps = {
  className?: string;
};

export const FormErrorMessage: FC<NebPropsWithStd<FormErrorMessageProps>> = ({ children, className }) => {
  const { config } = useFormState();
  return (
    <p
      className={classNames(
        'text-sm text-left font-medium text-red-600 bg-red-200 rounded p-2 flex items-center',
        className ?? config.defaultClassConfig?.error,
      )}
    >
      <>
      <MdError className="mr-3 text-xl"></MdError>
      {children}
      </>
      
    </p>
  );
};
