import { SelectBox } from '@components/ui/select-box';
import {
  Account,
  Membership,
  MembershipOrder,
} from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import { NebPropsWithStd } from '@villageco/nebula/core';
import classNames from 'classnames';
import { startCase } from 'lodash';
import { FC, MouseEvent } from 'react';
import { FiEdit } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { MembershipRoutePaths } from '../../router/membership-routes';

export type OrderCardProps = {
  order: MembershipOrder & { membership: Membership & { account: Account } };
  selected?: boolean;
  onSelectClicked: (evt: MouseEvent) => void;
};

export const OrderCard: FC<NebPropsWithStd<OrderCardProps>> = ({ className, selected, order, onSelectClicked }) => {
  const month = startCase(order.month?.toLocaleLowerCase());

  return (
    <div className={classNames('offering-card flex', className)}>
      <div className="w-16 h-16 flex items-center" onClick={onSelectClicked}>
        <SelectBox selected={selected} deselectedContainerClass="border border-gray-200">
          {' '}
        </SelectBox>
      </div>
      <div className="flex w-full">
        <div className="flex flex-col h-full justify-around">
          <div className="flex space-x-4">
            <p className="font-semibold text-lg">{order.membership.account.name}</p>
          </div>
          <div className="flex">
            <p className="font-medium bg-slate-600 rounded text-white py-1 px-2 capitalize">
              {month} {order.year}
            </p>
          </div>
        </div>
        <div className="ml-auto">
          <Link
            to={`${MembershipRoutePaths.MEMBERSHIP_ORDER_DETAILS}${order.id}`}
            className={classNames(
              'bg-th-membership-100 hover:text-th-membership-700 hover:bg-th-membership-200 text-th-membership-600 text-sm transition-colors duration-150 w-16 h-16 rounded flex flex-col items-center justify-center',
            )}
          >
            <FiEdit className="text-xl" />
            <span className="mt-2 -mb-1 font-semibold ">Edit</span>
          </Link>
        </div>
      </div>
    </div>
  );
};
