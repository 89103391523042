import { ThScrollPage } from '@components/ui/th-scroll-page';
import {
  MembershipOffering,
  MembershipOfferingItem,
} from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import { useGetMembershipOfferingById } from '@core/clients/ecommerce-services-admin/membership-offerings/membership-offerings';
import { setPageMeta } from '@core/stores/core-store';
import { Disclosure } from '@headlessui/react';
import classNames from 'classnames';
import { FunctionComponent, MouseEvent, useEffect, useState } from 'react';
import { BsChevronUp, BsPlus } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import { OfferingDetailsSkeleton } from '../../components/offerings/offering-details-skeleton';
import { OfferingEditForm } from '../../components/offerings/offering-edit-form';
import { OfferingItemCard } from '../../components/offerings/offering-item-card';
import { OfferingItemEditForm } from '../../components/offerings/offering-item-edit-form';
import { MembershipRoutePaths } from '../../router/membership-routes';

export type OfferingDetailsProps = {};

export const OfferingDetailsPage: FunctionComponent<OfferingDetailsProps> = () => {
  setPageMeta('Offerings', false);

  const { id: offeringId } = useParams();
  const navigate = useNavigate();
  const [offeringItems, setOfferingItems] = useState<MembershipOfferingItem[]>([]);

  const isNew = offeringId === 'new';

  const {
    data: offering,
    isLoading: offeringLoading,
    queryKey: offeringQueryKey,
  } = useGetMembershipOfferingById(
    offeringId!,
    {
      include: {
        // membershipTier: true,
        membershipOfferingItems: {
          include: { item: { include: { product: true, variant: true } }, membershipOfferingCategory: true },
        },
      },
    },
    {
      query: {
        enabled: !isNew,
      },
    },
  );

  useEffect(() => {
    setOfferingItems(offering?.membershipOfferingItems?.items ?? []);
  }, [offering]);

  const offeringCreated = (offering: MembershipOffering) =>
    navigate(`${MembershipRoutePaths.OFFERING_DETAILS}${offering.id}`);

  const addOfferingItemClicked = (evt: MouseEvent) => {
    evt.preventDefault();

    setOfferingItems([...offeringItems, {}]);
  };

  const offeringItemRemoved = (index: number) => () => {
    const variant = offeringItems[index];

    if (!variant.id) {
      offeringItems.splice(index, 1);
      setOfferingItems([...offeringItems]);
    }
  };

  return (
    <ThScrollPage id="offering-details-page">
      <div className="bg-th-membership-200 min-h-full rounded p-2">
        {!isNew && offeringLoading ? (
          <OfferingDetailsSkeleton />
        ) : (
          <>
            <div className="bg-white rounded p-4">
              <p className="font-semibold text-lg">{isNew ? 'New Offering' : 'Offering Details'}</p>

              <OfferingEditForm
                offering={offering}
                invalidateQueries={[offeringQueryKey]}
                onCreate={offeringCreated}
              ></OfferingEditForm>
            </div>
            {offering && (
              <div className="bg-white rounded p-4 mt-4">
                <div className="flex items-center">
                  <p className="font-semibold text-lg">Offering Items</p>
                  <button
                    onClick={addOfferingItemClicked}
                    className="flex bg-th-membership-500 rounded text-white font-semibold h-10 items-center justify-center w-32 ml-auto"
                  >
                    <BsPlus />
                    Add item
                  </button>
                </div>

                <div className="space-y-2 mt-4">
                  {offeringItems.map((i, index) => (
                    <Disclosure key={index} defaultOpen={!i.id}>
                      {({ open }) => (
                        <>
                          {i.id && (
                            <Disclosure.Button
                              className={classNames('rounded w-full flex p-2 items-center', {
                                'bg-th-membership-100': !i.deletedAt,
                                'bg-th-membership-50': i.deletedAt,
                              })}
                            >
                              <OfferingItemCard offeringItem={i}></OfferingItemCard>
                              <BsChevronUp
                                className={classNames('h-4 w-4 ml-auto mr-4', {
                                  'rotate-180 transform': !open,
                                })}
                              />
                            </Disclosure.Button>
                          )}
                          <Disclosure.Panel className="bg-th-membership-50 rounded p-2">
                            <OfferingItemEditForm
                              offeringId={offering.id!}
                              item={i}
                              invalidateQueries={[offeringQueryKey]}
                              onRemove={offeringItemRemoved(index)}
                            ></OfferingItemEditForm>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </ThScrollPage>
  );
};
