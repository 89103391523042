export const getInitials = (name: string) => {
  const names = name.split(' ');

  if (names?.length === 0) {
    return '';
  }

  if (names?.length === 1) {
    if (names[0]?.length > 2) {
      return names[0]?.substring(0, 2);
    } else {
      return names[0];
    }
  }

  const initials = ((names.shift()?.[0] || '') + (names.pop()?.[0] || '')).toUpperCase();

  // const initials = names.reduce((prev, curr) => {
  //   const val = names?.length === 0 ? '' : names?.length === 1 ? names[0].substring(0, 2) : `${prev[0]} ${curr[0]}`;

  //   console.log('INITIALS');
  //   console.log(val);
  //   return val;
  // });

  return initials;
};
