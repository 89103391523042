export type AvailableCurrencies = 'AUD';

export const usePrintMoney = (options?: { locale?: string; currency?: AvailableCurrencies }) => {
  const opts = {
    locale: 'en-AU',
    ...options,
  };

  const formatter = new Intl.NumberFormat(opts.locale, {
    style: 'currency',
    currency: opts.currency ?? 'AUD',
  });

  return formatter;
};
