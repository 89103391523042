import classNames from 'classnames';
import { Promiseable } from '@villageco/helpers';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { MouseEvent, FormEvent, useEffect, useCallback, useRef } from 'react';
import { defaultErrorHandler } from '../../helpers/error-handlers/default-error-handler';
import { useFormState } from '../../hooks';
import { FormContextProvider } from '../../contexts/form-context';
import { FormContextProps, FormEvent as NebFormEvent, ValidationErrors } from '../../contexts/types';

type InnerFormProps<T> =
  // | { onSubmit?: never; onSubmitMutation?: MutateFunction<{ errors?: ValidationErrors<T> } | T, any, T, any> }
  {
    onSubmit?: (data: T) => Promiseable<{ errors?: ValidationErrors<T> } | unknown> | void;
    onChange?: (data: T) => void;
    useHtml?: boolean;
    // onSubmitMutation?: never;
  };

export type FormProps<T> = FormContextProps<T> & InnerFormProps<T>;

export const Form = <T,>({
  children,
  className,
  defaultData,
  config,
  onSubmit,
  onChange,
  useHtml,
}: NebPropsWithStd<FormProps<T>>) => {  
  return (
    <FormContextProvider defaultData={defaultData} config={config} onChange={onChange} usesHtml={useHtml ?? false}>
      <InnerForm onSubmit={onSubmit} className={className} useHtml={useHtml ?? false}>
        {children}
      </InnerForm>
    </FormContextProvider>
  );
};

const InnerForm = <T,>({ children, className, onSubmit, useHtml }: NebPropsWithStd<InnerFormProps<T>>) => {
  const context = useFormState();

  const { data, setValidationErrors, setLoading, config, setFormError, dispatchFormEvent, registerFormEventListener } =
    context;

  const errorHandler = config.formErrorHandler ?? defaultErrorHandler;

  const submitFormHandler = async () => {
    console.log(data);

    setLoading(true);
    setValidationErrors(undefined);
    setFormError(undefined);

    let errors: ValidationErrors<T> | undefined;

    try {
      let response: any;

      if (onSubmit) {
        response = await onSubmit(data);
      }

      if (response) {
        if (config.submitErrorFieldParser) {
          errors = config.submitErrorFieldParser(response, config);
        } else if (Object.prototype.hasOwnProperty.call(response, 'errors')) {
          errors = response;
        }
      }

      dispatchFormEvent(NebFormEvent.SUBMIT_SUCCESS, response);
    } catch (error) {
      try {
        if (config.submitErrorFieldParser) {
          errors = config.submitErrorFieldParser(error, config);
        } else if (Object.prototype.hasOwnProperty.call(error, 'errors')) {
          errors = error as any;
        } else {
          await errorHandler(error, context);
        }
        dispatchFormEvent(NebFormEvent.SUBMIT_ERROR, error);
      } catch (error) {
        await errorHandler(error, context);
        dispatchFormEvent(NebFormEvent.SUBMIT_ERROR, error);
      }
    } finally {
      setLoading(false);
    }

    setValidationErrors(errors);
  };

  let unsub = useRef<(() => void) | undefined>(undefined);

  useEffect(() => {
    if (unsub.current) {
      unsub.current();
    }

    if (!useHtml) {
      unsub.current = registerFormEventListener(NebFormEvent.SUBMIT_CLICKED, async (evt: MouseEvent) => {
        evt.preventDefault();
        await submitFormHandler();
      });
    }
    return () => {
      unsub.current && unsub.current();
    };
  }, [data, config, onSubmit]);

  const formSubmitted = async (evt: FormEvent) => {
    evt.preventDefault();
    await submitFormHandler();
  };

  return useHtml ? (
    <form onSubmit={formSubmitted} className={classNames('form', className)}>
      {children}
    </form>
  ) : (
    <div className={classNames('form', className)}>{children}</div>
  );
};
