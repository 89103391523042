import { THH3 } from '@components/ui/th-h3';
import {
  MembershipOfferingItem,
  MembershipOfferingItemCreate,
  MembershipOfferingItemUpdate,
} from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import {
  useDeleteMembershipOfferingItemById,
  usePatchRestoreMembershipOfferingItemById,
  usePatchMembershipOfferingItemById,
  usePostMembershipOfferingItem,
} from '@core/clients/ecommerce-services-admin/membership-offering-items/membership-offering-items';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { pick } from 'lodash';
import { NebPropsWithStd } from '@villageco/nebula/core';
import {
  Form,
  FormOutput,
  List,
  notFoundAuthHandler,
  Numeric,
  ResetButton,
  SubmitButton,
  Text,
} from '@villageco/nebula/forms';
import { FC, MouseEvent } from 'react';
import { FaTrashAlt, FaTrashRestoreAlt } from 'react-icons/fa';
import { ProductComboField } from '@ecommerce';
import { membershipFormConfig } from '../../libs/form-config';
import { useGetManyMembershipOfferingCategories } from '@core/clients/ecommerce-services-admin/membership-offering-categories/membership-offering-categories';

export type OfferingItemEditFormProps = {
  offeringId: string;
  item?: MembershipOfferingItem;
  invalidateQueries: QueryKey[];
  onRemove?: () => void;
  onRestore?: (id: string) => void;
  onCreate?: (item: MembershipOfferingItem) => Promise<void>;
  onUpdate?: (item: MembershipOfferingItem) => Promise<void>;
};

export const OfferingItemEditForm: FC<NebPropsWithStd<OfferingItemEditFormProps>> = ({
  offeringId,
  item,
  className,
  invalidateQueries,
  onRemove,
  onRestore,
  onCreate,
  onUpdate,
}) => {
  const queryClient = useQueryClient();

  const mutation = {
    onSuccess: () => {
      invalidateQueries.forEach((q) => queryClient.invalidateQueries(q));
    },
  };

  const { mutateAsync: deleteVariantMut, isLoading: deleteLoading } = useDeleteMembershipOfferingItemById({
    mutation,
  });

  const { mutateAsync: restoreVariantMut, isLoading: restoreLoading } = usePatchRestoreMembershipOfferingItemById({
    mutation,
  });

  const { mutateAsync: updateVariantMut } = usePatchMembershipOfferingItemById({
    mutation,
  });

  const { mutateAsync: createVariantMut } = usePostMembershipOfferingItem({
    mutation,
  });

  const { data: categories, isLoading: categoriesLoading } = useGetManyMembershipOfferingCategories({
    select: {
      id: true,
      name: true,
    },
  });

  const deleteClicked = async (evt: MouseEvent) => {
    evt.preventDefault();

    if (item?.id) {
      await deleteVariantMut({ id: item.id, data: {} });
    }

    onRemove && onRemove();
  };

  const restoreClicked = async (evt: MouseEvent) => {
    evt.preventDefault();

    const id = item?.id as string;

    await restoreVariantMut({ id, data: {} });

    onRestore && onRestore(id);
  };

  const formSubmitted = async (rawData: MembershipOfferingItem) => {
    const data = {
      membershipOfferingCategory: { connect: { id: rawData.membershipOfferingCategoryId } },
      quantity: rawData.quantity,
      item: { connect: { id: rawData.itemId } },
    } as MembershipOfferingItemCreate;

    if (item?.id) {
      const res = await updateVariantMut({ id: item.id, data: { data } });
      onUpdate && (await onUpdate(res));
    } else {
      const res = await createVariantMut({
        data: { data: { ...data, membershipOffering: { connect: { id: offeringId } } } },
      });
      onCreate && (await onCreate(res));
    }
  };

  const defaultData = pick(item ?? {}, ['quantity', 'membershipOfferingCategoryId', 'itemId']);

  const categoryOptions = (categories?.items ?? []).map((c) => ({
    value: c.id!,
    label: c.name!,
  }));

  return (
    <Form
      defaultData={defaultData}
      config={{ ...membershipFormConfig, formErrorHandler: notFoundAuthHandler }}
      onSubmit={formSubmitted}
      className={classNames('product-item-edit-form', className)}
    >
      {item?.deletedAt ? (
        <div className="h-32 flex flex-col justify-center items-center space-y-4">
          <THH3 className="w-full text-center">Restore this item to edit</THH3>
          <button
            onClick={restoreClicked}
            className={classNames('bg-teal-600 text-white p-2 items-center justify-evenly w-28 rounded flex', {
              'bg-teal-300 animate-pulse': restoreLoading,
            })}
          >
            <FaTrashRestoreAlt />
            Restore
          </button>
        </div>
      ) : (
        <>
          <div className="flex">
            <button
              onClick={deleteClicked}
              className={classNames('bg-red-600 text-white p-2 items-center justify-evenly w-28 rounded ml-auto flex', {
                'bg-red-300 animate-pulse': deleteLoading,
              })}
            >
              <FaTrashAlt />
              Remove
            </button>
          </div>

          <ProductComboField<typeof defaultData>
            name="itemId"
            className="mt-4"
            label="Product/Product variant"
            validationErrorKey="item"
            disableProductsWithVariants={true}
            whereQuery={{
              type: {
                not: 'MEMBERSHIP',
              },
            }}
          ></ProductComboField>
          <Numeric<typeof defaultData> className="w-full" name="quantity" label="Quantity"></Numeric>
          <List<typeof defaultData>
            options={categoryOptions}
            name="membershipOfferingCategoryId"
            label="Offering Category"
            disabled={categoriesLoading}
            placeholder={categoriesLoading ? `Loading` : undefined}
            validationErrorKey="membershipOfferingCategory"
          ></List>
          <div className="flex justify-end mt-4 space-x-4">
            <FormOutput className="mr-auto"></FormOutput>
            <SubmitButton></SubmitButton>
            <ResetButton></ResetButton>
          </div>
        </>
      )}
    </Form>
  );
};
