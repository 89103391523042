import { ajvAxiosErrorParser, FormConfig } from '@villageco/nebula/forms';

export const biteyFormConfig: FormConfig<any> = {
  defaultClassConfig: {
    container: 'mb-2',
    label: {
      element: 'text-th-bitey-700 font-semibold text-sm uppercase tracking-tighter',
    },
    listOptions: {
      container: 'bg-white w-full border rounded mt-1 p-1 space-y-1',
      element: 'py-1 px-2 hover:bg-gray-100 hover:rounded',
      disabled: 'text-gray-300',
      selected: 'rounded bg-blue-500 text-white',
    },
    field: {
      element: 'h-12 rounded border-b-2  px-2 w-full outline-none bg-th-bitey-50 focus:border-b-th-bitey-600',
      ready: 'border-b-th-bitey-300',
      disabled: 'bg-th-bitey-50 text-gray-400',
      changed: 'border-b-th-bitey-700',
      error: 'border-b-red-400',
    },
    override: {
      tags: {
        element: 'min-h-[3rem] rounded border-b-2  px-2 w-full outline-none bg-th-bitey-50 focus:border-b-th-bitey-600',
      },
    },
    submitButton: {
      element: 'rounded w-24 h-12 font-medium',
      unchanged: 'bg-th-bitey-100 text-white',
      ready: 'bg-th-bitey-500 text-white',
      loading: 'bg-th-bitey-300 text-white animate-pulse',
    },
    resetButton: {
      element: 'rounded w-24 h-12 border font-medium',
      unchanged: 'border text-th-bitey-300 border-th-bitey-300',
      ready: 'border text-th-bitey-600 border-th-bitey-500 border-2',
      loading: 'border-th-bitey-300 text-th-bitey-300 animate-pulse',
    },
  },
  submitErrorFieldParser: ajvAxiosErrorParser(),
};
