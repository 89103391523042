import { FC } from 'react';
import classNames, { Argument } from 'classnames';
import { EmbeddedVideo } from '../types';

export type YoutubeEmbedProps = {
  className?: Argument;
  embeddedVideo?: EmbeddedVideo;
};

const youtubeLinkRegex = new RegExp(/https:\/\/www.youtube.com\/watch\?v=(.*)/);

export const YoutubeEmbed: FC<YoutubeEmbedProps> = ({ className, embeddedVideo, children }) => {
  const videoCode = embeddedVideo?.videoLink?.match(youtubeLinkRegex);

  return (
    <div className="youtube-embed relative">
      <div className="border-b">
        {embeddedVideo?.videoLink ? (
          <a target="_blank" href={embeddedVideo?.videoLink}>
            {children}
          </a>
        ) : (
          <>{children}</>
        )}
      </div>
      <div className="relative  w-full min-w-[650px] h-auto aspect-video">
        {videoCode ? (
          <iframe
            className="w-full h-full absolute top-0 left-0"
            src={`https://www.youtube.com/embed/${videoCode[1]}`}
            title={embeddedVideo?.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        ) : (
          <div className="bg-gray-300 w-full h-full absolute top-0 left-0"></div>
        )}
      </div>
    </div>
  );
};
