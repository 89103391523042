import { useGetManyProducts } from '@core/clients/ecommerce-services-admin/products/products';
import { Combobox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { isArray } from 'lodash';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { ComboProps, Field } from '@villageco/nebula/forms';
import { Fragment, useEffect, useRef, useState } from 'react';
import { BsArrow90DegLeft, BsArrow90DegUp, BsCheck } from 'react-icons/bs';
import { HiChevronUpDown } from 'react-icons/hi2';
import { ProductWhere } from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';

export type ProductComboFieldProps<T> = Omit<ComboProps<T, string>, 'children' | 'options'> & {
  disableVariants?: boolean;
  hideVariants?: boolean;
  disableProductsWithVariants?: boolean;
  whereQuery?: ProductWhere;
};

export const ProductComboField = <T,>({
  className,
  name,
  placeholder,
  label,
  disabled,
  multiple,
  disableVariants,
  hideVariants,
  disableProductsWithVariants,
  validationErrorKey,
  whereQuery,
}: NebPropsWithStd<ProductComboFieldProps<T>>) => {
  const [inputFocused, setInputFocused] = useState(false);
  const [query, setQuery] = useState('');

  const { data: products, isLoading: productsLoading } = useGetManyProducts({
    select: {
      id: true,
      name: true,
      itemId: true,
    },
    include: {
      variants: { select: { id: true, name: true, itemId: true } },
    },
    where: whereQuery,
  });

  const productItems = products?.items ?? [];

  const productOptions =
    query === ''
      ? productItems
      : productItems.filter((p) => p.name?.toLocaleLowerCase().includes(query.toLocaleLowerCase()));

  const getDisplayValue = (items?: string | string[]) =>
    ((isArray(items) ? items : [items]) as string[])
      .filter((i) => i !== undefined)
      .map((i) => {
        for (const p of productOptions) {
          if (p.itemId === i) return p.name;

          if (p.variants?.items?.length) {
            for (const v of p.variants.items) {
              if (v.itemId === i) return v.name;
            }
          }
        }
      })
      .join(', ');

  const inputRef = useRef(null);

  const focusInHandler = () => {
    setInputFocused(true);
  };
  const blurHandler = () => {
    setInputFocused(false);
  };

  useEffect(() => {
    if (inputRef.current) {
      const el = inputRef.current as HTMLInputElement;

      el.removeEventListener('focusin', focusInHandler);
      el.removeEventListener('blur', blurHandler);

      el.addEventListener('focusin', focusInHandler);
      el.addEventListener('blur', blurHandler);
    }

    return () => {
      if (inputRef.current) {
        const el = inputRef.current as HTMLInputElement;

        el.removeEventListener('focusin', focusInHandler);
        el.removeEventListener('blur', blurHandler);
      }
    };
  }, [inputRef.current]);

  return (
    <Field<T, string>
      name={name}
      label={label}
      disabled={disabled}
      className={classNames('product-combo-field relative', className)}
      validationErrorKey={validationErrorKey}
    >
      {({ fieldValue, hasErrors, handleChange, label, calculatedFieldClassName }) => (
        <Combobox<string>
          disabled={disabled || productsLoading}
          onChange={handleChange as any}
          // displayValue={displayValue}
          multiple={multiple as any}
          value={(fieldValue ?? (multiple ? [] : {})) as any}
        >
          <div className="relative">
            <Combobox.Button as="div" className="flex relative">
              <Combobox.Input
                ref={inputRef}
                aria-label={label}
                aria-invalid={hasErrors}
                className={calculatedFieldClassName}
                displayValue={getDisplayValue}
                onChange={(event) => setQuery(event.target.value)}
              />
              {inputFocused || (multiple ? !!(fieldValue as any)?.length : !!fieldValue) || (
                <span className="absolute inset-0 text-slate-600 italic flex items-center w-full pl-2 pointer-events-none group-focus-within:invisible">
                  {productsLoading ? 'Loading products' : placeholder ?? 'Search products'}
                </span>
              )}
              <HiChevronUpDown className="h-5 w-5 text-gray-400 absolute right-2 inset-y-0 my-auto" />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-40 space-y-1 p-1">
              {productOptions.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">No products</div>
              ) : (
                productOptions.map((p) => (
                  <div className="border rounded">
                    <Combobox.Option
                      key={p.id}
                      disabled={(disableProductsWithVariants === true && !!p.variants?.items?.length) ?? false}
                      className={({ active }) =>
                        classNames('relative cursor-default select-none py-2 pl-10 pr-4 flex items-center rounded', {
                          'bg-blue-300 text-white': active,
                          'text-gray-900': !active,
                          'bg-slate-100': disableProductsWithVariants === true && p.variants?.items?.length,
                        })
                      }
                      value={p.itemId}
                    >
                      {({ selected, active }) => (
                        <>
                          <img
                            className="w-10 h-10"
                            src="https://cdn.shopify.com/s/files/1/0355/6058/7397/products/GL_Generic_shopify_product_all_books_2022_1080x.png?v=1652408768"
                          ></img>
                          <span className={`ml-4 block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                            {p.name}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? 'text-white' : 'text-teal-600'
                              }`}
                            >
                              <BsCheck className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                    {!hideVariants &&
                      !!p.variants?.items?.length &&
                      p.variants.items.map((v) => (
                        <Combobox.Option
                          key={v.id}
                          disabled={disableVariants === true}
                          className={({ active }) =>
                            classNames('relative cursor-default select-none py-2 pl-10 pr-4 flex items-center', {
                              'bg-blue-300 text-white': active,
                              'text-gray-900': !active,
                              'bg-slate-100': disableVariants === true,
                            })
                          }
                          value={v.itemId}
                        >
                          {({ selected, active }) => (
                            <>
                              <BsArrow90DegLeft className="transform rotate-180" />
                              <span className={`ml-4 block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                {v.name}
                              </span>
                              {selected ? (
                                <span
                                  className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                    active ? 'text-white' : 'text-teal-600'
                                  }`}
                                >
                                  <BsCheck className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                  </div>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </Combobox>
      )}
    </Field>
  );
};
