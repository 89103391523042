import classNames from 'classnames';
import { isArray, merge } from 'lodash-es';
import { ChangeEvent, InputHTMLAttributes, ReactElement, ReactNode, useMemo } from 'react';
import { NebPropsWithStd } from '../../../core';
import { FormConfig } from '../../contexts';
import { useFormState } from '../../hooks';
import { FormErrorMessage } from '../common/form-error-message';
import { Label } from '../common/label';
import { BaseFieldProps } from './types';

export type FieldProps<T, V> = BaseFieldProps<T> & {
  disabled?: boolean;
  className?: string;
  value?: V;
  onChange?: (value: V) => void;
  children: (props: {
    name: keyof T;
    label?: string;
    disabled?: boolean;
    hasErrors?: boolean;
    hasChanged: boolean;
    fieldValue: V;
    calculatedFieldClassName: string;
    handleChange: (value: V) => void;
  }) => ReactElement;
};

export const Field = <T, V>({
  name,
  className,
  classes,
  label,
  optional,
  children,
  disabled,
  prefix,
  onChange,
  value,
  validationErrorKey,
}: FieldProps<T, V>) => {
  const { getFieldErrors, getFieldValue, setFieldValue, validationErrors, config, hasFieldChanged } =
    useFormState() ?? {};

  const { defaultClassConfig } = config ? config : ({} as FormConfig<any>);

  const handleChange = (value: V) => {
    onChange && onChange(value);
    setFieldValue && setFieldValue(name, value);
  };

  const cc = merge({}, defaultClassConfig, classes);

  const fieldErrors = getFieldErrors && useMemo(() => getFieldErrors(validationErrorKey ?? name), [validationErrors]);
  const hasErrors = useMemo(() => fieldErrors && !!fieldErrors.length, [fieldErrors]);

  const hasChanged = hasFieldChanged ? hasFieldChanged(name) : false;

  const fieldValue: V = (getFieldValue && getFieldValue(name)) ?? value;

  const calculatedFieldClassName = classNames(cc?.field?.element, {
    [cc?.field?.ready ?? '']: !hasErrors && !disabled && !hasChanged,
    [cc?.field?.changed ?? '']: hasChanged && !disabled && !hasErrors,
    [cc?.field?.error ?? '']: hasErrors,
    [cc?.field?.disabled ?? '']: disabled,
  });

  return (
    <div className={classNames(className, cc?.container, 'text-field')}>
      <div aria-live="polite">
        <Label label={label} optional={optional} labelClassConfig={cc?.label} />
        <div className={cc?.field?.container}>
          {prefix && <>{prefix}</>}
          {children({
            calculatedFieldClassName,
            label,
            disabled,
            hasErrors,
            hasChanged,
            fieldValue,
            name,
            handleChange,
          })}
        </div>
      </div>
      {fieldErrors &&
        Array.isArray(fieldErrors) &&
        fieldErrors.map((e) => <FormErrorMessage className={cc?.error}>{e}</FormErrorMessage>)}
    </div>
  );
};
