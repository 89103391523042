/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * admin-rest-api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  File,
  ValidationErrors,
  GetFileByIdParams,
  PatchFileByIdBody,
  DeleteFileByIdBody,
  PatchRestoreFileByIdBody,
  DeleteDestroyFileByIdBody,
  GetOneFileParams,
  Files,
  GetManyFilesParams,
  PostFileBody
} from '../ecommerce-services.schemas'
import { ecommerceAdminApiInstance } from '../../../apis/ecommerce-admin-api-instance'
import type { ErrorType } from '../../../apis/ecommerce-admin-api-instance'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Gets one File by Id in path
 */
export const getFileById = (
    id: string,
    params?: GetFileByIdParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<File>(
      {url: `/file/${id}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetFileByIdQueryKey = (id: string,
    params?: GetFileByIdParams,) => [`/file/${id}`, ...(params ? [params]: [])];

    
export type GetFileByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getFileById>>>
export type GetFileByIdQueryError = ErrorType<void | ValidationErrors>

export const useGetFileById = <TData = Awaited<ReturnType<typeof getFileById>>, TError = ErrorType<void | ValidationErrors>>(
 id: string,
    params?: GetFileByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFileById>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFileByIdQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFileById>>> = ({ signal }) => getFileById(id,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getFileById>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Patches one File by Id in path
 */
export const patchFileById = (
    id: string,
    patchFileByIdBody: PatchFileByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<File>(
      {url: `/file/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchFileByIdBody
    },
      options);
    }
  


    export type PatchFileByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchFileById>>>
    export type PatchFileByIdMutationBody = PatchFileByIdBody
    export type PatchFileByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchFileById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchFileById>>, TError,{id: string;data: PatchFileByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchFileById>>, {id: string;data: PatchFileByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchFileById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchFileById>>, TError, {id: string;data: PatchFileByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Deletes one File by Id in path
 */
export const deleteFileById = (
    id: string,
    deleteFileByIdBody: DeleteFileByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<File>(
      {url: `/file/${id}`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteFileByIdBody
    },
      options);
    }
  


    export type DeleteFileByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFileById>>>
    export type DeleteFileByIdMutationBody = DeleteFileByIdBody
    export type DeleteFileByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteFileById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFileById>>, TError,{id: string;data: DeleteFileByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFileById>>, {id: string;data: DeleteFileByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteFileById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteFileById>>, TError, {id: string;data: DeleteFileByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Restores one File by Id in path
 */
export const patchRestoreFileById = (
    id: string,
    patchRestoreFileByIdBody: PatchRestoreFileByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<File>(
      {url: `/file/${id}/restore`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchRestoreFileByIdBody
    },
      options);
    }
  


    export type PatchRestoreFileByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchRestoreFileById>>>
    export type PatchRestoreFileByIdMutationBody = PatchRestoreFileByIdBody
    export type PatchRestoreFileByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchRestoreFileById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchRestoreFileById>>, TError,{id: string;data: PatchRestoreFileByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchRestoreFileById>>, {id: string;data: PatchRestoreFileByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchRestoreFileById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchRestoreFileById>>, TError, {id: string;data: PatchRestoreFileByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Destroys one File by Id in path
 */
export const deleteDestroyFileById = (
    id: string,
    deleteDestroyFileByIdBody: DeleteDestroyFileByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<File>(
      {url: `/file/${id}/destroy`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteDestroyFileByIdBody
    },
      options);
    }
  


    export type DeleteDestroyFileByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDestroyFileById>>>
    export type DeleteDestroyFileByIdMutationBody = DeleteDestroyFileByIdBody
    export type DeleteDestroyFileByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteDestroyFileById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDestroyFileById>>, TError,{id: string;data: DeleteDestroyFileByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDestroyFileById>>, {id: string;data: DeleteDestroyFileByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteDestroyFileById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteDestroyFileById>>, TError, {id: string;data: DeleteDestroyFileByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Get one Files
 */
export const getOneFile = (
    params?: GetOneFileParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<File>(
      {url: `/file`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetOneFileQueryKey = (params?: GetOneFileParams,) => [`/file`, ...(params ? [params]: [])];

    
export type GetOneFileQueryResult = NonNullable<Awaited<ReturnType<typeof getOneFile>>>
export type GetOneFileQueryError = ErrorType<void | ValidationErrors>

export const useGetOneFile = <TData = Awaited<ReturnType<typeof getOneFile>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetOneFileParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOneFile>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOneFileQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneFile>>> = ({ signal }) => getOneFile(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOneFile>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Get many Files
 */
export const getManyFiles = (
    params?: GetManyFilesParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<Files>(
      {url: `/files`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetManyFilesQueryKey = (params?: GetManyFilesParams,) => [`/files`, ...(params ? [params]: [])];

    
export type GetManyFilesQueryResult = NonNullable<Awaited<ReturnType<typeof getManyFiles>>>
export type GetManyFilesQueryError = ErrorType<void | ValidationErrors>

export const useGetManyFiles = <TData = Awaited<ReturnType<typeof getManyFiles>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetManyFilesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getManyFiles>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetManyFilesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getManyFiles>>> = ({ signal }) => getManyFiles(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getManyFiles>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Posts a new File
 */
export const postFile = (
    postFileBody: PostFileBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<File>(
      {url: `/files`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postFileBody
    },
      options);
    }
  


    export type PostFileMutationResult = NonNullable<Awaited<ReturnType<typeof postFile>>>
    export type PostFileMutationBody = PostFileBody
    export type PostFileMutationError = ErrorType<void | ValidationErrors>

    export const usePostFile = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postFile>>, TError,{data: PostFileBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postFile>>, {data: PostFileBody}> = (props) => {
          const {data} = props ?? {};

          return  postFile(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postFile>>, TError, {data: PostFileBody}, TContext>(mutationFn, mutationOptions)
    }
    