/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * admin-rest-api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Link,
  ValidationErrors,
  GetLinkByIdParams,
  PatchLinkByIdBody,
  DeleteLinkByIdBody,
  PatchRestoreLinkByIdBody,
  DeleteDestroyLinkByIdBody,
  GetOneLinkParams,
  Links,
  GetManyLinksParams,
  PostLinkBody
} from '../bitey-services.schemas'
import { biteyAdminApiInstance } from '../../../apis/bitey-admin-api-instance'
import type { ErrorType } from '../../../apis/bitey-admin-api-instance'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Gets one Link by Id in path
 */
export const getLinkById = (
    id: string,
    params?: GetLinkByIdParams,
 options?: SecondParameter<typeof biteyAdminApiInstance>,signal?: AbortSignal
) => {
      return biteyAdminApiInstance<Link>(
      {url: `/link/${id}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetLinkByIdQueryKey = (id: string,
    params?: GetLinkByIdParams,) => [`/link/${id}`, ...(params ? [params]: [])];

    
export type GetLinkByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getLinkById>>>
export type GetLinkByIdQueryError = ErrorType<void | ValidationErrors>

export const useGetLinkById = <TData = Awaited<ReturnType<typeof getLinkById>>, TError = ErrorType<void | ValidationErrors>>(
 id: string,
    params?: GetLinkByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLinkById>>, TError, TData>, request?: SecondParameter<typeof biteyAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLinkByIdQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLinkById>>> = ({ signal }) => getLinkById(id,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getLinkById>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Patches one Link by Id in path
 */
export const patchLinkById = (
    id: string,
    patchLinkByIdBody: PatchLinkByIdBody,
 options?: SecondParameter<typeof biteyAdminApiInstance>,) => {
      return biteyAdminApiInstance<Link>(
      {url: `/link/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchLinkByIdBody
    },
      options);
    }
  


    export type PatchLinkByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchLinkById>>>
    export type PatchLinkByIdMutationBody = PatchLinkByIdBody
    export type PatchLinkByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchLinkById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchLinkById>>, TError,{id: string;data: PatchLinkByIdBody}, TContext>, request?: SecondParameter<typeof biteyAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchLinkById>>, {id: string;data: PatchLinkByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchLinkById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchLinkById>>, TError, {id: string;data: PatchLinkByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Deletes one Link by Id in path
 */
export const deleteLinkById = (
    id: string,
    deleteLinkByIdBody: DeleteLinkByIdBody,
 options?: SecondParameter<typeof biteyAdminApiInstance>,) => {
      return biteyAdminApiInstance<Link>(
      {url: `/link/${id}`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteLinkByIdBody
    },
      options);
    }
  


    export type DeleteLinkByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteLinkById>>>
    export type DeleteLinkByIdMutationBody = DeleteLinkByIdBody
    export type DeleteLinkByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteLinkById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLinkById>>, TError,{id: string;data: DeleteLinkByIdBody}, TContext>, request?: SecondParameter<typeof biteyAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteLinkById>>, {id: string;data: DeleteLinkByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteLinkById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteLinkById>>, TError, {id: string;data: DeleteLinkByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Restores one Link by Id in path
 */
export const patchRestoreLinkById = (
    id: string,
    patchRestoreLinkByIdBody: PatchRestoreLinkByIdBody,
 options?: SecondParameter<typeof biteyAdminApiInstance>,) => {
      return biteyAdminApiInstance<Link>(
      {url: `/link/${id}/restore`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchRestoreLinkByIdBody
    },
      options);
    }
  


    export type PatchRestoreLinkByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchRestoreLinkById>>>
    export type PatchRestoreLinkByIdMutationBody = PatchRestoreLinkByIdBody
    export type PatchRestoreLinkByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchRestoreLinkById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchRestoreLinkById>>, TError,{id: string;data: PatchRestoreLinkByIdBody}, TContext>, request?: SecondParameter<typeof biteyAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchRestoreLinkById>>, {id: string;data: PatchRestoreLinkByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchRestoreLinkById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchRestoreLinkById>>, TError, {id: string;data: PatchRestoreLinkByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Destroys one Link by Id in path
 */
export const deleteDestroyLinkById = (
    id: string,
    deleteDestroyLinkByIdBody: DeleteDestroyLinkByIdBody,
 options?: SecondParameter<typeof biteyAdminApiInstance>,) => {
      return biteyAdminApiInstance<Link>(
      {url: `/link/${id}/destroy`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteDestroyLinkByIdBody
    },
      options);
    }
  


    export type DeleteDestroyLinkByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDestroyLinkById>>>
    export type DeleteDestroyLinkByIdMutationBody = DeleteDestroyLinkByIdBody
    export type DeleteDestroyLinkByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteDestroyLinkById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDestroyLinkById>>, TError,{id: string;data: DeleteDestroyLinkByIdBody}, TContext>, request?: SecondParameter<typeof biteyAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDestroyLinkById>>, {id: string;data: DeleteDestroyLinkByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteDestroyLinkById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteDestroyLinkById>>, TError, {id: string;data: DeleteDestroyLinkByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Get one Links
 */
export const getOneLink = (
    params?: GetOneLinkParams,
 options?: SecondParameter<typeof biteyAdminApiInstance>,signal?: AbortSignal
) => {
      return biteyAdminApiInstance<Link>(
      {url: `/link`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetOneLinkQueryKey = (params?: GetOneLinkParams,) => [`/link`, ...(params ? [params]: [])];

    
export type GetOneLinkQueryResult = NonNullable<Awaited<ReturnType<typeof getOneLink>>>
export type GetOneLinkQueryError = ErrorType<void | ValidationErrors>

export const useGetOneLink = <TData = Awaited<ReturnType<typeof getOneLink>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetOneLinkParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOneLink>>, TError, TData>, request?: SecondParameter<typeof biteyAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOneLinkQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneLink>>> = ({ signal }) => getOneLink(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOneLink>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Get many Links
 */
export const getManyLinks = (
    params?: GetManyLinksParams,
 options?: SecondParameter<typeof biteyAdminApiInstance>,signal?: AbortSignal
) => {
      return biteyAdminApiInstance<Links>(
      {url: `/links`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetManyLinksQueryKey = (params?: GetManyLinksParams,) => [`/links`, ...(params ? [params]: [])];

    
export type GetManyLinksQueryResult = NonNullable<Awaited<ReturnType<typeof getManyLinks>>>
export type GetManyLinksQueryError = ErrorType<void | ValidationErrors>

export const useGetManyLinks = <TData = Awaited<ReturnType<typeof getManyLinks>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetManyLinksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getManyLinks>>, TError, TData>, request?: SecondParameter<typeof biteyAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetManyLinksQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getManyLinks>>> = ({ signal }) => getManyLinks(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getManyLinks>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Posts a new Link
 */
export const postLink = (
    postLinkBody: PostLinkBody,
 options?: SecondParameter<typeof biteyAdminApiInstance>,) => {
      return biteyAdminApiInstance<Link>(
      {url: `/links`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postLinkBody
    },
      options);
    }
  


    export type PostLinkMutationResult = NonNullable<Awaited<ReturnType<typeof postLink>>>
    export type PostLinkMutationBody = PostLinkBody
    export type PostLinkMutationError = ErrorType<void | ValidationErrors>

    export const usePostLink = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postLink>>, TError,{data: PostLinkBody}, TContext>, request?: SecondParameter<typeof biteyAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postLink>>, {data: PostLinkBody}> = (props) => {
          const {data} = props ?? {};

          return  postLink(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postLink>>, TError, {data: PostLinkBody}, TContext>(mutationFn, mutationOptions)
    }
    