import { ProductVariant } from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import { default as classNames } from 'classnames';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { FC } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import invLogoUrl from '../../../../assets/zoho-inventory-icon.png';
import { usePrintMoney } from '../../hooks/use-print-money';
import { hasSellGst } from '../../libs/has-sell-gst';

export type ProductVariantCardProps = {
  variant?: ProductVariant;
};

export const ProductVariantCard: FC<NebPropsWithStd<ProductVariantCardProps>> = ({ className, variant }) => {
  const formatter = usePrintMoney();

  return (
    <div className={classNames('product-variant-card flex', className)}>
      <p className="font-semibold text-slate-800 w-56 text-left truncate">
        {variant?.name}
        {variant?.deletedAt && (
          <span className="font-light text-sm ml-2 italic bg-slate-400 rounded px-2 text-white">trashed</span>
        )}
      </p>

      <span
        className={classNames('ml-4 rounded bg-slate-200 text-slate-900 px-2 font-medium w-52', {
          invisible: !variant?.item?.sku,
        })}
      >
        {variant?.item?.sku}
      </span>

      <span className="ml-32 rounded bg-slate-200 text-slate-900 px-2 font-medium w-28">
        {formatter.format(variant?.item?.sellEx ?? 0)}
      </span>
      {hasSellGst(variant?.item) && (
        <span className="ml-2 rounded bg-blue-200 text-blue-900 px-2 font-medium w-24">GST Free</span>
      )}
      <a
        onClick={(e) => e.stopPropagation()}
        href={`https://inventory.zoho.com.au/app#/contacts/${variant?.item?.zohoInvItemRef}`}
        target="_blank"
        className={classNames(
          'flex  transition-colors duration-150 pr-2 pl-1 ml-8  items-center font-medium rounded w-40',
          {
            'bg-white text-slate-600 hover:bg-slate-100 hover:text-slate-700': variant?.item?.zohoInvItemRef,
            'bg-slate-300 text-slate-400 pointer-events-none': !variant?.item?.zohoInvItemRef,
          },
        )}
      >
        <img className="w-5 rounded mr-2" src={invLogoUrl} />
        {variant?.item?.zohoInvItemRef ? (
          <>
            <span>Zoho Inventory item linked</span>
            <FiExternalLink className="ml-4 mr-2" />
          </>
        ) : (
          'No linked item'
        )}
      </a>
    </div>
  );
};
