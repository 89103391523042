import { FunctionComponent } from 'react';
import { BsTags } from 'react-icons/bs';
import { FaShoppingBasket } from 'react-icons/fa';
import { MdOutlineLocalShipping } from 'react-icons/md';
import { TbBuildingWarehouse } from 'react-icons/tb';
import { EcommerceRoutePaths } from '../../router/ecommerce-routes';
import { EcommerceSidebarLink } from './ecommerce-sidebar-link';

export type EcommerceSidebarMenuProps = {};

export const EcommerceSidebarMenu: FunctionComponent<EcommerceSidebarMenuProps> = () => {
  return (
    <div className="ecommerce-sidebar-menu px-2 w-full">
      <EcommerceSidebarLink to={EcommerceRoutePaths.PRODUCTS_INDEX} icon={BsTags} styles={{ textColor: 'text-4xl' }}>
        Products
      </EcommerceSidebarLink>
      {/* <EcommerceSidebarLink
        to={EcommerceRoutePaths.ORDERS_INDEX}
        icon={FaShoppingBasket}
        styles={{ textColor: 'text-4xl' }}
      >
        Orders
      </EcommerceSidebarLink> */}
      {/* <EcommerceSidebarLink
        to={EcommerceRoutePaths.ITEMS_INDEX}
        icon={TbBuildingWarehouse}
        styles={{ textColor: 'text-4xl' }}
      >
        Items
      </EcommerceSidebarLink> */}
      <EcommerceSidebarLink
        to={EcommerceRoutePaths.SHIPMENT_RUNS_INDEX}
        icon={MdOutlineLocalShipping}
        styles={{ textColor: 'text-4xl' }}
      >
        Shipment Runs
      </EcommerceSidebarLink>

      {/* <SidebarGroup icon={BsStar} href="/memberships/products" className="mt-2" label="Products">
      </SidebarGroup> */}
    </div>
  );
};
