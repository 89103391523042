/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * admin-rest-api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  MembershipOfferingCategory,
  ValidationErrors,
  GetMembershipOfferingCategoryByIdParams,
  PatchMembershipOfferingCategoryByIdBody,
  DeleteMembershipOfferingCategoryByIdBody,
  PatchRestoreMembershipOfferingCategoryByIdBody,
  DeleteDestroyMembershipOfferingCategoryByIdBody,
  GetOneMembershipOfferingCategoryParams,
  MembershipOfferingCategories,
  GetManyMembershipOfferingCategoriesParams,
  PostMembershipOfferingCategoryBody
} from '../ecommerce-services.schemas'
import { ecommerceAdminApiInstance } from '../../../apis/ecommerce-admin-api-instance'
import type { ErrorType } from '../../../apis/ecommerce-admin-api-instance'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Gets one Membership Offering Category by Id in path
 */
export const getMembershipOfferingCategoryById = (
    id: string,
    params?: GetMembershipOfferingCategoryByIdParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<MembershipOfferingCategory>(
      {url: `/membership-offering-category/${id}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetMembershipOfferingCategoryByIdQueryKey = (id: string,
    params?: GetMembershipOfferingCategoryByIdParams,) => [`/membership-offering-category/${id}`, ...(params ? [params]: [])];

    
export type GetMembershipOfferingCategoryByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getMembershipOfferingCategoryById>>>
export type GetMembershipOfferingCategoryByIdQueryError = ErrorType<void | ValidationErrors>

export const useGetMembershipOfferingCategoryById = <TData = Awaited<ReturnType<typeof getMembershipOfferingCategoryById>>, TError = ErrorType<void | ValidationErrors>>(
 id: string,
    params?: GetMembershipOfferingCategoryByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMembershipOfferingCategoryById>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMembershipOfferingCategoryByIdQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMembershipOfferingCategoryById>>> = ({ signal }) => getMembershipOfferingCategoryById(id,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMembershipOfferingCategoryById>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Patches one Membership Offering Category by Id in path
 */
export const patchMembershipOfferingCategoryById = (
    id: string,
    patchMembershipOfferingCategoryByIdBody: PatchMembershipOfferingCategoryByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipOfferingCategory>(
      {url: `/membership-offering-category/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchMembershipOfferingCategoryByIdBody
    },
      options);
    }
  


    export type PatchMembershipOfferingCategoryByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchMembershipOfferingCategoryById>>>
    export type PatchMembershipOfferingCategoryByIdMutationBody = PatchMembershipOfferingCategoryByIdBody
    export type PatchMembershipOfferingCategoryByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchMembershipOfferingCategoryById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchMembershipOfferingCategoryById>>, TError,{id: string;data: PatchMembershipOfferingCategoryByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchMembershipOfferingCategoryById>>, {id: string;data: PatchMembershipOfferingCategoryByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchMembershipOfferingCategoryById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchMembershipOfferingCategoryById>>, TError, {id: string;data: PatchMembershipOfferingCategoryByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Deletes one Membership Offering Category by Id in path
 */
export const deleteMembershipOfferingCategoryById = (
    id: string,
    deleteMembershipOfferingCategoryByIdBody: DeleteMembershipOfferingCategoryByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipOfferingCategory>(
      {url: `/membership-offering-category/${id}`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteMembershipOfferingCategoryByIdBody
    },
      options);
    }
  


    export type DeleteMembershipOfferingCategoryByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMembershipOfferingCategoryById>>>
    export type DeleteMembershipOfferingCategoryByIdMutationBody = DeleteMembershipOfferingCategoryByIdBody
    export type DeleteMembershipOfferingCategoryByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteMembershipOfferingCategoryById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMembershipOfferingCategoryById>>, TError,{id: string;data: DeleteMembershipOfferingCategoryByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMembershipOfferingCategoryById>>, {id: string;data: DeleteMembershipOfferingCategoryByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteMembershipOfferingCategoryById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteMembershipOfferingCategoryById>>, TError, {id: string;data: DeleteMembershipOfferingCategoryByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Restores one Membership Offering Category by Id in path
 */
export const patchRestoreMembershipOfferingCategoryById = (
    id: string,
    patchRestoreMembershipOfferingCategoryByIdBody: PatchRestoreMembershipOfferingCategoryByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipOfferingCategory>(
      {url: `/membership-offering-category/${id}/restore`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchRestoreMembershipOfferingCategoryByIdBody
    },
      options);
    }
  


    export type PatchRestoreMembershipOfferingCategoryByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchRestoreMembershipOfferingCategoryById>>>
    export type PatchRestoreMembershipOfferingCategoryByIdMutationBody = PatchRestoreMembershipOfferingCategoryByIdBody
    export type PatchRestoreMembershipOfferingCategoryByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchRestoreMembershipOfferingCategoryById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchRestoreMembershipOfferingCategoryById>>, TError,{id: string;data: PatchRestoreMembershipOfferingCategoryByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchRestoreMembershipOfferingCategoryById>>, {id: string;data: PatchRestoreMembershipOfferingCategoryByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchRestoreMembershipOfferingCategoryById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchRestoreMembershipOfferingCategoryById>>, TError, {id: string;data: PatchRestoreMembershipOfferingCategoryByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Destroys one Membership Offering Category by Id in path
 */
export const deleteDestroyMembershipOfferingCategoryById = (
    id: string,
    deleteDestroyMembershipOfferingCategoryByIdBody: DeleteDestroyMembershipOfferingCategoryByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipOfferingCategory>(
      {url: `/membership-offering-category/${id}/destroy`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteDestroyMembershipOfferingCategoryByIdBody
    },
      options);
    }
  


    export type DeleteDestroyMembershipOfferingCategoryByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDestroyMembershipOfferingCategoryById>>>
    export type DeleteDestroyMembershipOfferingCategoryByIdMutationBody = DeleteDestroyMembershipOfferingCategoryByIdBody
    export type DeleteDestroyMembershipOfferingCategoryByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteDestroyMembershipOfferingCategoryById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDestroyMembershipOfferingCategoryById>>, TError,{id: string;data: DeleteDestroyMembershipOfferingCategoryByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDestroyMembershipOfferingCategoryById>>, {id: string;data: DeleteDestroyMembershipOfferingCategoryByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteDestroyMembershipOfferingCategoryById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteDestroyMembershipOfferingCategoryById>>, TError, {id: string;data: DeleteDestroyMembershipOfferingCategoryByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Get one Membership Offering Categories
 */
export const getOneMembershipOfferingCategory = (
    params?: GetOneMembershipOfferingCategoryParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<MembershipOfferingCategory>(
      {url: `/membership-offering-category`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetOneMembershipOfferingCategoryQueryKey = (params?: GetOneMembershipOfferingCategoryParams,) => [`/membership-offering-category`, ...(params ? [params]: [])];

    
export type GetOneMembershipOfferingCategoryQueryResult = NonNullable<Awaited<ReturnType<typeof getOneMembershipOfferingCategory>>>
export type GetOneMembershipOfferingCategoryQueryError = ErrorType<void | ValidationErrors>

export const useGetOneMembershipOfferingCategory = <TData = Awaited<ReturnType<typeof getOneMembershipOfferingCategory>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetOneMembershipOfferingCategoryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOneMembershipOfferingCategory>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOneMembershipOfferingCategoryQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneMembershipOfferingCategory>>> = ({ signal }) => getOneMembershipOfferingCategory(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOneMembershipOfferingCategory>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Get many Membership Offering Categories
 */
export const getManyMembershipOfferingCategories = (
    params?: GetManyMembershipOfferingCategoriesParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<MembershipOfferingCategories>(
      {url: `/membership-offering-categories`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetManyMembershipOfferingCategoriesQueryKey = (params?: GetManyMembershipOfferingCategoriesParams,) => [`/membership-offering-categories`, ...(params ? [params]: [])];

    
export type GetManyMembershipOfferingCategoriesQueryResult = NonNullable<Awaited<ReturnType<typeof getManyMembershipOfferingCategories>>>
export type GetManyMembershipOfferingCategoriesQueryError = ErrorType<void | ValidationErrors>

export const useGetManyMembershipOfferingCategories = <TData = Awaited<ReturnType<typeof getManyMembershipOfferingCategories>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetManyMembershipOfferingCategoriesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getManyMembershipOfferingCategories>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetManyMembershipOfferingCategoriesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getManyMembershipOfferingCategories>>> = ({ signal }) => getManyMembershipOfferingCategories(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getManyMembershipOfferingCategories>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Posts a new Membership Offering Category
 */
export const postMembershipOfferingCategory = (
    postMembershipOfferingCategoryBody: PostMembershipOfferingCategoryBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipOfferingCategory>(
      {url: `/membership-offering-categories`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postMembershipOfferingCategoryBody
    },
      options);
    }
  


    export type PostMembershipOfferingCategoryMutationResult = NonNullable<Awaited<ReturnType<typeof postMembershipOfferingCategory>>>
    export type PostMembershipOfferingCategoryMutationBody = PostMembershipOfferingCategoryBody
    export type PostMembershipOfferingCategoryMutationError = ErrorType<void | ValidationErrors>

    export const usePostMembershipOfferingCategory = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMembershipOfferingCategory>>, TError,{data: PostMembershipOfferingCategoryBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMembershipOfferingCategory>>, {data: PostMembershipOfferingCategoryBody}> = (props) => {
          const {data} = props ?? {};

          return  postMembershipOfferingCategory(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMembershipOfferingCategory>>, TError, {data: PostMembershipOfferingCategoryBody}, TContext>(mutationFn, mutationOptions)
    }
    