import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { isArray, merge } from 'lodash-es';
import { ChangeEvent, InputHTMLAttributes, useMemo } from 'react';
import NumberFormat, { NumberFormatValues, SourceInfo } from 'react-number-format';
import { NebPropsWithStd } from '../../../core';
import { FormConfig } from '../../contexts';
import { useFormState } from '../../hooks';
import { FormErrorMessage } from '../common/form-error-message';
import { Label } from '../common/label';
import { BaseFieldProps } from './types';

export type CurrencyProps<T> = BaseFieldProps<T> & {
  placeholder?: string;
  disabled?: boolean;
  value?: string | number;
  onChange?: (values: NumberFormatValues, sourceInfo?: SourceInfo) => void;
};

const mask = '^\\d{1,3}(,\\d{3})*(\\.\\d+)?$';

const currencyRegex = new RegExp('^\\d{1,3}(,\\d{3})*(\\.\\d+)?$');

export const Currency = <T,>({
  name,
  label,
  placeholder,
  classes,
  className,
  prefix,
  disabled,
  optional,
  onChange,
  value,
  validationErrorKey,
}: NebPropsWithStd<CurrencyProps<T>>) => {
  const { getFieldErrors, getFieldValue, setFieldValue, validationErrors, config, hasFieldChanged } =
    useFormState() ?? {};

  const { defaultClassConfig } = config ? config : ({} as FormConfig<any>);

  const handleChange = (values: NumberFormatValues, sourceInfo: SourceInfo) => {
    onChange && onChange(values, sourceInfo);
    setFieldValue && setFieldValue(name, values.floatValue);
  };

  const cc = merge({}, defaultClassConfig, classes);

  const fieldErrors = getFieldErrors && useMemo(() => getFieldErrors(validationErrorKey ?? name), [validationErrors]);
  const hasErrors = useMemo(() => fieldErrors && !!fieldErrors.length, [fieldErrors]);

  const hasChanged = hasFieldChanged ? hasFieldChanged(name) : false;

  const fieldValue = (getFieldValue && getFieldValue(name)) ?? '';

  return (
    <div className={classNames(className, cc?.container, 'currency-field')}>
      <div aria-live="polite">
        <Label label={label} optional={optional} labelClassConfig={cc?.label} />
        <div className={cc?.field?.container}>
          {prefix && <>{prefix}</>}

          <NumberFormat
            disabled={disabled}
            defaultValue={value ?? fieldValue}
            name={name as string}
            prefix="$"
            placeholder={placeholder}
            thousandSeparator={true}
            thousandsGroupStyle="thousand"
            displayType="input"
            type="text"
            decimalScale={2}
            fixedDecimalScale
            onValueChange={handleChange}
            className={classNames(cc?.field?.element, {
              [cc?.field?.ready ?? '']: !hasErrors && !disabled && !hasChanged,
              [cc?.field?.changed ?? '']: hasChanged && !disabled && !hasErrors,
              [cc?.field?.error ?? '']: hasErrors,
              [cc?.field?.disabled ?? '']: disabled,
            })}
          />
        </div>
      </div>
      {fieldErrors &&
        Array.isArray(fieldErrors) &&
        fieldErrors.map((e) => <FormErrorMessage className={cc?.error}>{e}</FormErrorMessage>)}
    </div>
  );
};
