import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames, { Argument } from 'classnames';

export type ItemDetailsPageProps = {};

const DEFUALT_CURRENCY = 'AUD';
const FEATURES = 'features';
const CONTENT_BLOCKS = 'contentBlocks';
const INFO_SNIPPETS = 'infoSnippets';
const RELATED_PRODUCT_IDS = 'relatedItemDetailsPagesIds';

export const ItemDetailsPage: FunctionComponent<ItemDetailsPageProps> = () => {
  return <></>;
  // const [match, params] = useRoute('/products/:id');
  // const [location, setLocation] = useLocation();
  // const product = useItemDetailsPage(params && params['id']);

  const imageContainer = useRef(null);

  // const products = useItemDetailsPages((p) => p.id !== product?.id);

  const newItem = {
    costEx: 0,
    costGst: 0,
    sellEx: 0,
    sellGst: 0,
    description: '',
    id: '',
    minimumQty: 0,
    name: 'New Item',
    isPurchased: true,
    stepQty: 0,
    zohoInvItemRef: '',
    stripeItemDetailsPageRef: '',
    createdAt: new Date(),
    deletedAt: new Date(),
    updatedAt: new Date(),
  };

  // const newItemDetailsPage: ItemDetailsPageType = {
  //   id: '',
  //   itemId: '',
  //   item: newItem,
  //   images: [],
  //   name: '',
  //   shortDescription: '',
  //   type: null,
  //   pageData: {},
  //   slug: '',
  //   createdAt: new Date(),
  //   deletedAt: new Date(),
  //   updatedAt: new Date(),
  // };

  // const [editableItemDetailsPage, setEditableItemDetailsPage] = useState<ItemDetailsPageType>(
  //   product ? { ...product } : newItemDetailsPage,
  // );
  // const [updatedItemDetailsPage, setUpdatedItemDetailsPage] = useState<ItemDetailsPageType>(
  //   product ? { ...product } : newItemDetailsPage,
  // );
  // const [deletingRelated, setDeletingRelated] = useState<string>();

  // useEffect(() => {
  //   setEditableItemDetailsPage({ ...updatedItemDetailsPage });
  // }, [updatedItemDetailsPage]);

  // useEffect(() => {
  //   imageContainer.current.scrollLeft = 5000;
  // }, [updatedItemDetailsPage?.images?.length]);

  // const { handleFieldChange: handleItemDetailsPageFieldChange } = useForm<ItemDetailsPageType>(
  //   editableItemDetailsPage,
  //   setEditableItemDetailsPage,
  // );

  // const pageData = useMemo(() => {
  //   return {
  //     contentBlocks:
  //       updatedItemDetailsPage?.pageData && updatedItemDetailsPage?.pageData[CONTENT_BLOCKS]
  //         ? (updatedItemDetailsPage?.pageData[CONTENT_BLOCKS] as Media[])
  //         : [],
  //     infoSnippets:
  //       updatedItemDetailsPage?.pageData && updatedItemDetailsPage?.pageData[INFO_SNIPPETS]
  //         ? (updatedItemDetailsPage?.pageData[INFO_SNIPPETS] as Media[])
  //         : [],
  //     features:
  //       updatedItemDetailsPage?.pageData && updatedItemDetailsPage?.pageData[FEATURES]
  //         ? (updatedItemDetailsPage?.pageData[FEATURES] as Media[])
  //         : [],
  //   };
  // }, [updatedItemDetailsPage]);

  // const relatedItemDetailsPageIds =
  //   editableItemDetailsPage?.pageData && editableItemDetailsPage?.pageData[RELATED_PRODUCT_IDS]
  //     ? (editableItemDetailsPage?.pageData[RELATED_PRODUCT_IDS] as string[])
  //     : [];

  // const relatedItemDetailsPages = useMemo(() => {
  //   return products?.filter((prod) => relatedItemDetailsPageIds?.includes(prod.id));
  // }, [relatedItemDetailsPageIds?.length]);

  // const [item, setItem] = useState<Item>(product?.item ?? newItem);

  // const { handleFieldChange: handleItemFieldChange } = useForm<Item>(item, setItem);

  // const relatedItemDetailsPageSelectOptions: AdvancedSelectOptions[] = useMemo(() => {
  //   return products
  //     ?.filter((p) => !relatedItemDetailsPageIds?.includes(p.id))
  //     ?.map((p) => ({
  //       value: p.id,
  //       searchableText: p.item.name,
  //       node: (
  //         <div className="flex">
  //           <div>
  //             {p.images[0]?.url ? (
  //               <THImage className="w-12 h-12" src={p.images[0]?.url} />
  //             ) : (
  //               <div className="w-12 h-12"></div>
  //             )}
  //           </div>
  //           <div className="flex items-center">
  //             <THP className="pl-4">{p.item.name}</THP>
  //           </div>
  //         </div>
  //       ),
  //     }));
  // }, [products]);

  // const handleAddRelatedItemDetailsPage = (value: string) => {
  //   let prod = { ...updatedItemDetailsPage };

  //   _.set(prod.pageData, RELATED_PRODUCT_IDS, [...relatedItemDetailsPageIds, value]);

  //   setUpdatedItemDetailsPage(prod);
  // };

  // const handleDeleteRelatedItemDetailsPage = (value: string) => {
  //   let prod = { ...updatedItemDetailsPage };

  //   _.set(prod.pageData, RELATED_PRODUCT_IDS, [...relatedItemDetailsPageIds.filter((rel) => rel !== value)]);

  //   setUpdatedItemDetailsPage(prod);
  //   setDeletingRelated(null);
  // };

  // const handleFieldConfirm = () => {
  //   setUpdatedItemDetailsPage({ ...editableItemDetailsPage });
  // };

  // const handleFieldCancel = () => {
  //   setEditableItemDetailsPage({ ...updatedItemDetailsPage });
  // };

  // const printer = usePrintMoney({ currency: DEFUALT_CURRENCY });

  // const updatePageData = (
  //   contentType: keyof typeof pageData,
  //   index?: number,
  //   title?: string,
  //   body?: string,
  //   file?: File,
  // ) => {
  //   let updatedPageData = [...pageData[contentType]];
  //   updatedPageData[index].title = title ?? updatedPageData[index].title;
  //   updatedPageData[index].body = body ?? updatedPageData[index].body;
  //   updatedPageData[index].file = file;

  //   let prod = { ...updatedItemDetailsPage };

  //   _.set(prod.pageData, contentType, updatedPageData);

  //   setUpdatedItemDetailsPage(prod);
  // };

  // const handleAddPageData = (contentType: keyof typeof pageData) => {
  //   let prod = { ...updatedItemDetailsPage };

  //   _.set(prod.pageData, contentType, [...pageData[contentType], {}]);

  //   setUpdatedItemDetailsPage(prod);
  // };

  // const handleDeletePageData = (contentType: keyof typeof pageData, index: number) => {
  //   let prod = { ...updatedItemDetailsPage };
  //   const tempContentlocks = pageData[contentType]?.filter((m: any, i: any) => i !== index);
  //   _.set(prod.pageData, contentType, [...tempContentlocks]);

  //   setUpdatedItemDetailsPage(prod);
  // };

  // const handleImageChange = (file: File, index: number) => {
  //   let updatedImages = [...updatedItemDetailsPage?.images];

  //   if (index >= 0) {
  //     updatedImages[index] = file;
  //   } else {
  //     updatedImages.push(file);
  //   }
  //   setUpdatedItemDetailsPage({ ...updatedItemDetailsPage, images: updatedImages });
  // };

  // const handleImageDelete = (imageIndex: number) => {
  //   let updatedImages = [...updatedItemDetailsPage?.images];
  //   if (imageIndex >= 0) {
  //     updatedImages = [...updatedImages?.filter((i, index) => index !== imageIndex)];
  //   }
  //   setUpdatedItemDetailsPage({ ...updatedItemDetailsPage, images: updatedImages });
  // };

  // const firstImage = updatedItemDetailsPage?.images ? updatedItemDetailsPage.images[0] : null;

  // const handleCancel = () => {
  //   console.log('Location');
  //   setLocation('/products');
  // };

  // return (
  //   <div id="product-page" className="container mx-auto pb-6 pt-16 mb-12 flex flex-col relative">
  //     <div className="flex flex-col w-full xl:w-[64rem] mx-auto">
  //       <div className="grid grid-cols-2 gap-8">
  //         <div>
  //           <div className="">
  //             <THP>Item Name</THP>
  //             <InputField
  //               className="pl-0"
  //               value={item?.name ?? ''}
  //               name="item.name"
  //               id="item.name"
  //               onChange={(val) => handleItemFieldChange('name', val)}
  //             />
  //           </div>
  //           <div className="mt-4">
  //             <THP>Url Slug</THP>
  //             <InputField
  //               className="pl-0"
  //               value={editableItemDetailsPage?.slug ?? ''}
  //               name="slug"
  //               id="slug"
  //               onChange={(val) => handleItemDetailsPageFieldChange('slug', val)}
  //             />
  //           </div>
  //           <div className="grid grid-cols-2 gap-8 mt-4">
  //             <div className="">
  //               <THP>Sell Ex</THP>
  //               <CurrencyField
  //                 className="pl-0"
  //                 value={item?.sellEx}
  //                 name="sellEx"
  //                 id="sellEx"
  //                 onChange={(val) => handleItemFieldChange('sellEx', val)}
  //               />
  //             </div>
  //             <div className="">
  //               <THP>Sell GST</THP>
  //               <CurrencyField
  //                 className="pl-0"
  //                 value={item?.sellGst}
  //                 name="sellGst"
  //                 id="sellGst"
  //                 onChange={(val) => handleItemFieldChange('sellGst', val)}
  //               />
  //             </div>
  //             <div className="">
  //               <THP>Cost Ex</THP>
  //               <CurrencyField
  //                 className="pl-0"
  //                 value={item?.costEx}
  //                 name="costEx"
  //                 id="costEx"
  //                 onChange={(val) => handleItemFieldChange('costEx', val)}
  //               />
  //             </div>
  //             <div className="">
  //               <THP>Cost GST</THP>
  //               <CurrencyField
  //                 className="pl-0"
  //                 value={item?.costGst}
  //                 name="costGst"
  //                 id="costGst"
  //                 onChange={(val) => handleItemFieldChange('costGst', val)}
  //               />
  //             </div>
  //           </div>
  //         </div>
  //         <div className="flex flex-col">
  //           <div className="pt-8">
  //             <AdvancedItemDetailsPageSelect
  //               options={relatedItemDetailsPageSelectOptions}
  //               title="Related ItemDetailsPages"
  //               onChange={handleAddRelatedItemDetailsPage}
  //             />
  //           </div>
  //           <div className="mt-12 flex overflow-x-auto w-full">
  //             {relatedItemDetailsPages?.map((rel, index) => {
  //               return (
  //                 <div key={rel.itemId}>
  //                   <div className="p-4 w-36">
  //                     <a className="flex flex-col" href={`products/${rel.slug}`} target="_blank">
  //                       <div>
  //                         <THImage src={rel.images[0]?.url} />
  //                       </div>
  //                       <THP className="py-1">{rel?.item?.name}</THP>
  //                     </a>
  //                     <div className="flex justify-end mr-2">
  //                       {deletingRelated === rel.id ? (
  //                         <>
  //                           <div
  //                             className="text-green-500 hover:text-blue-400 hover:active:text-blue-300 border-green-500 hover:border-blue-400 hover:active:border-blue-300 w-7 h-7 ml-2 flex justify-center items-center hover:cursor-pointer border-2 rounded-md mb-1"
  //                             onClick={() => handleDeleteRelatedItemDetailsPage(rel.id)}
  //                           >
  //                             <FaCheck />
  //                           </div>
  //                           <div
  //                             className="text-red-500 hover:text-blue-400 hover:active:text-blue-300 border-red-500 hover:border-blue-400 hover:active:border-blue-300 w-7 h-7 ml-2 flex justify-center items-center hover:cursor-pointer border-2 rounded-md"
  //                             onClick={() => setDeletingRelated('')}
  //                           >
  //                             <FaTimes />
  //                           </div>
  //                         </>
  //                       ) : (
  //                         <>
  //                           <div
  //                             className="text-red-500 hover:text-blue-400 hover:active:text-blue-300 border-red-500 hover:border-blue-400 hover:active:border-blue-300 w-7 h-7 ml-2 flex justify-center items-center hover:cursor-pointer border-2 rounded-md"
  //                             onClick={() => setDeletingRelated(rel.id)}
  //                           >
  //                             <FaTrashAlt />
  //                           </div>
  //                         </>
  //                       )}
  //                     </div>
  //                   </div>
  //                 </div>
  //               );
  //             })}
  //           </div>
  //         </div>
  //       </div>
  //       <div className="pb-16 border-b-2 mb-16 grid grid-cols-2 gap-8"></div>
  //       <THH2 className="text-center mb-16">Preview</THH2>
  //       <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start">
  //         <div className="flex flex-col w-full md:w-[31.25rem] mb-4">
  //           <div
  //             className={classNames('w-full', {
  //               'aspect-square': firstImage?.contentType === 'image',
  //               'aspect-video': firstImage?.contentType === 'video',
  //             })}
  //           >
  //             {firstImage?.url ? (
  //               <>
  //                 {firstImage.contentType === FileType.VIDEO ? (
  //                   <THVideo src={firstImage?.url} extension={firstImage.extension} className="w-full" />
  //                 ) : (
  //                   <THImage src={firstImage?.url} alt="" className="w-full" />
  //                 )}
  //               </>
  //             ) : (
  //               <div className="bg-gray-100 w-full h-[500px] flex justify-center items-center">
  //                 <FaImage size={72} className="text-gray-500" />
  //               </div>
  //             )}
  //           </div>
  //           <div className="flex mt-4 overflow-x-visible h-auto" ref={imageContainer}>
  //             {updatedItemDetailsPage?.images?.map((image, index) => {
  //               return (
  //                 <div key={`preview-image-${image.id ?? image.tempId}`} className="flex flex-col">
  //                   <FileUpload
  //                     value={image}
  //                     id={image.id ?? image.tempId}
  //                     className="mr-2 w-12 h-12"
  //                     onChange={handleImageChange}
  //                     index={index}
  //                   />
  //                   <div>
  //                     <ActionButtons
  //                       action={() => handleImageDelete(index)}
  //                       className="flex-col-reverse items-center"
  //                       standardIconClassName="!ml-0 mr-2"
  //                       confirmIconClassName="!ml-0 mr-2"
  //                       cancelIconClassName="!ml-0 mr-2 mb-1"
  //                       confirmationMessage="Remove image?"
  //                       confirmationMessageClassName="top-20"
  //                     />
  //                   </div>
  //                 </div>
  //               );
  //             })}
  //             <FileUpload className="h-12" onChange={handleImageChange} id="new-upload" />
  //           </div>
  //         </div>
  //         <div className="flex flex-col w-full md:w-[29.5rem]">
  //           <div className="flex justify-between">
  //             <div className="flex flex-col w-full">
  //               <EditableField
  //                 inputNode={
  //                   <InputField
  //                     className="p-0 w-full"
  //                     value={editableItemDetailsPage.name}
  //                     name="name"
  //                     id="name"
  //                     onChange={(val) => handleItemDetailsPageFieldChange('name', val)}
  //                   />
  //                 }
  //                 displayNode={
  //                   <THH3>{updatedItemDetailsPage?.name ? updatedItemDetailsPage?.name : '(No Name)'}</THH3>
  //                 }
  //                 onChangeConfirmed={handleFieldConfirm}
  //                 onCancel={handleFieldCancel}
  //               />
  //               <EditableField
  //                 inputNode={
  //                   <InputField
  //                     className="p-0 w-full"
  //                     value={editableItemDetailsPage.shortDescription}
  //                     name="shortDescription"
  //                     id="name"
  //                     onChange={(val) => handleItemDetailsPageFieldChange('shortDescription', val)}
  //                   />
  //                 }
  //                 displayNode={
  //                   <THH3 light={true}>
  //                     {updatedItemDetailsPage?.shortDescription
  //                       ? updatedItemDetailsPage?.shortDescription
  //                       : '(No Short Description)'}
  //                   </THH3>
  //                 }
  //                 onChangeConfirmed={handleFieldConfirm}
  //                 onCancel={handleFieldCancel}
  //               />
  //               <div className="flex mt-1">
  //                 <THP>
  //                   {printer.format(
  //                     editableItemDetailsPage?.item?.costEx + editableItemDetailsPage?.item?.costGst,
  //                   )}
  //                 </THP>
  //                 <THP className="font-bold ml-2">
  //                   {printer.format(
  //                     editableItemDetailsPage?.item?.costEx + editableItemDetailsPage?.item?.costGst,
  //                   )}
  //                 </THP>
  //               </div>
  //             </div>
  //           </div>
  //           <div className="flex mt-6 mb-8 justify-between"></div>
  //           <div className="w-full pb-8"></div>
  //           <CollapseSection title="What is it?">
  //             <EditableField
  //               inputNode={
  //                 <TextArea
  //                   className="pl-0 w-full h-full"
  //                   value={item?.description}
  //                   name="item.description"
  //                   id="item.description"
  //                   rows={5}
  //                   onChange={(val) => {
  //                     handleItemFieldChange('description', val);
  //                   }}
  //                 />
  //               }
  //               displayNode={
  //                 <THP className="">
  //                   {item?.description ? item?.description : '(No Description added - This section will be hidden)'}
  //                 </THP>
  //               }
  //               onChangeConfirmed={handleFieldConfirm}
  //               onCancel={handleFieldCancel}
  //             />
  //           </CollapseSection>
  //           <CollapseSection title="Features">
  //             {pageData?.features?.map((feature, index) => (
  //               <ItemDetailsPageInfoBlock
  //                 key={`feature-${index}`}
  //                 body={feature?.body}
  //                 name={`feature-${index}`}
  //                 title={feature.title}
  //                 mediaFile={feature.file}
  //                 className={classNames({ 'mt-8': index > 0 })}
  //                 index={index}
  //                 mediaPosition="bottom"
  //                 onUpdate={(index, title, body, file) => updatePageData(FEATURES, index, title, body, file)}
  //                 hideImage
  //                 editable
  //                 deleteMessage="Remove feature?"
  //                 onDelete={() => handleDeletePageData(FEATURES, index)}
  //               />
  //             ))}
  //             <div className="flex justify-end mt-8">
  //               <SlimButton
  //                 className="flex items-center text-gray-500 hover:text-gray-400"
  //                 onClick={() => handleAddPageData(FEATURES)}
  //               >
  //                 {pageData?.features?.length === 0 ? 'Add feature' : 'Add more features'}
  //               </SlimButton>
  //             </div>
  //           </CollapseSection>

  //           {pageData?.infoSnippets?.map((info, index) => {
  //             return (
  //               <CollapseSection
  //                 key={`info-snippet-${index}`}
  //                 title={info.title}
  //                 hideTitleWhenOpen
  //                 editable
  //                 onDelete={() => handleDeletePageData(INFO_SNIPPETS, index)}
  //               >
  //                 <ItemDetailsPageInfoBlock
  //                   key={`info-snippet-${index}`}
  //                   name={`info-snippet-${index}`}
  //                   hideImage
  //                   body={info?.body}
  //                   title={info.title}
  //                   mediaFile={info.file}
  //                   index={index}
  //                   mediaPosition="bottom"
  //                   onUpdate={(index, title, body, file) => updatePageData(INFO_SNIPPETS, index, title, body, file)}
  //                 />
  //               </CollapseSection>
  //             );
  //           })}
  //           <div className="flex justify-end pt-8 mb-6 border-t-2 border-gray-400">
  //             <SlimButton
  //               className="flex items-center text-gray-500 hover:text-gray-400"
  //               onClick={() => handleAddPageData(INFO_SNIPPETS)}
  //             >
  //               {pageData?.infoSnippets?.length === 0 ? 'Add expanding section' : 'Add more expanding sections'}
  //             </SlimButton>
  //           </div>

  //           <CollapseSection title="Similar items" shouldOpen={true} locked={true}>
  //             <div className="">Up to 3 items will be shown here from the selected related items above</div>
  //           </CollapseSection>
  //         </div>
  //       </div>
  //       <div className="flex flex-col mt-8">
  //         {pageData?.contentBlocks?.map((mb: any, index: any) => {
  //           return (
  //             <ItemDetailsPageInfoBlock
  //               key={`media-block-${index}`}
  //               name={`media-block-${index}`}
  //               title={mb.title}
  //               body={mb.body}
  //               mediaFile={mb.file}
  //               mediaPosition={index % 2 === 1 ? 'left' : 'right'}
  //               index={index}
  //               className="py-10"
  //               bodyClassName={classNames('mt-8', {
  //                 'px-4 lg:pl-0 lg:pr-12': mb.file && index % 2 === 0,
  //                 'px-4 lg:pr-0 lg:pl-12': mb.file && index % 2 === 1,
  //               })}
  //               mediaClassName="px-24 md:px-0"
  //               onUpdate={(index, title, body, file) => updatePageData(CONTENT_BLOCKS, index, title, body, file)}
  //               editable
  //               deleteMessage="Remove content block?"
  //               onDelete={() => handleDeletePageData(CONTENT_BLOCKS, index)}
  //             />
  //           );
  //         })}

  //         <div className="flex justify-end mt-8">
  //           <SlimButton
  //             className="flex items-center text-gray-500 hover:text-gray-400"
  //             onClick={() => handleAddPageData(CONTENT_BLOCKS)}
  //           >
  //             {pageData?.contentBlocks?.length === 0 ? 'Add content block' : 'Add more content blocks'}
  //           </SlimButton>
  //         </div>
  //       </div>
  //       <div className="grid grid-cols-2 my-8">
  //         <div></div>
  //         <div className="grid grid-cols-2 gap-8">
  //           <div>
  //             <Button varient="solid">Publish</Button>
  //           </div>
  //           <div>
  //             <Button onClick={handleCancel} varient="outlined">
  //               Close
  //             </Button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};
