import classnames, { Argument } from 'classnames';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { FC, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsFillArrowLeftSquareFill } from 'react-icons/bs';

export type ModuleHeaderProps = {
  panelClassName?: Argument;
  isHome?: boolean;
};

export const ModuleHeader: FC<NebPropsWithStd<ModuleHeaderProps>> = ({ children, panelClassName, isHome }) => {
  const navigate = useNavigate();

  const backClicked = (evt: MouseEvent) => {
    evt.preventDefault();
    navigate(-1);
  };

  return (
    <div className="module-header px-1 rounded bg-gray-100 py-1 mx-4 flex items-center ">
      {!isHome && (
        <button
          onClick={backClicked}
          className="flex rounded bg-gray-500 text-white items-center px-3 text-xs mr-2 h-full hover:bg-gray-400"
        >
          <BsFillArrowLeftSquareFill className="mr-2 h-4 w-4" />
          Back
        </button>
      )}
      <h1
        className={classnames(
          { 'text-4xl p-2': isHome, 'text-sm p-1': !isHome },
          'font-title font-bold w-full rounded transition-all duration-300 text-gray-700',
          panelClassName,
        )}
      >
        {children}
      </h1>
    </div>
  );
};
