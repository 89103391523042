import { ThScrollPage } from '@components/ui/th-scroll-page';
import { setPageMeta } from '@core/stores/core-store';
import { FC } from 'react';

export type PageDetailsPageProps = {};

export const PageDetailsPage: FC<PageDetailsPageProps> = () => {
  setPageMeta('Pages', false);

  return <ThScrollPage id="pages-index-page"></ThScrollPage>;
};
