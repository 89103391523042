import { Auth, Signer } from 'aws-amplify';
import { RestClient } from '@aws-amplify/api-rest';
import Axios, { AxiosRequestConfig } from 'axios';
import { omit } from 'lodash-es';
import { AmplifySignerServiceInfo } from './types';

const innerInstance = Axios.create();

export const axiosSignatureInterceptor =
  (serviceInfo: AmplifySignerServiceInfo) =>
  async (config: AxiosRequestConfig<any>): Promise<AxiosRequestConfig<any>> => {
    const rest = new RestClient({ credentials: {}, headers: {}, endpoints: {} });

    const { accessKeyId, sessionToken, secretAccessKey } = await Auth.currentCredentials();

    // Remove all the default Axios headers
    const {
      common,
      delete: _delete, // 'delete' is a reserved word
      get,
      head,
      post,
      put,
      patch,
      ...headersToSign
    } = config.headers as any;

    const request = {
      method: (config.method as any).toUpperCase(),
      url: innerInstance.getUri(config),
      data: config.data ?? {},
      headers: headersToSign,
    };

    const credentials = {
      access_key: accessKeyId,
      session_token: sessionToken,
      secret_key: secretAccessKey,
    };

    const svcInfo = { region: (import.meta as any).env.VITE_AWS_REGION || process?.env?.AWS_REGION, ...serviceInfo };

    const signedRequest = Signer.sign(request, credentials, svcInfo);

    const signedConfig = { ...config };

    if (signedConfig.data) {
      (signedConfig as any).body = signedConfig.data;
    }

    signedConfig.headers = { ...signedConfig.headers, ...signedRequest.headers };

    delete signedConfig.headers!['host'];

    return signedConfig;
  };
