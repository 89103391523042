/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * admin-rest-api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Account,
  ValidationErrors,
  GetAccountByIdParams,
  PatchAccountByIdBody,
  DeleteAccountByIdBody,
  PatchRestoreAccountByIdBody,
  DeleteDestroyAccountByIdBody,
  GetOneAccountParams,
  Accounts,
  GetManyAccountsParams,
  PostAccountBody,
  PostImportAccountsFromZohoCRMResponseBody,
  PostImportAccountsFromZohoCrmBody
} from '../ecommerce-services.schemas'
import { ecommerceAdminApiInstance } from '../../../apis/ecommerce-admin-api-instance'
import type { ErrorType } from '../../../apis/ecommerce-admin-api-instance'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Gets one Account by Id in path
 */
export const getAccountById = (
    id: string,
    params?: GetAccountByIdParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<Account>(
      {url: `/account/${id}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetAccountByIdQueryKey = (id: string,
    params?: GetAccountByIdParams,) => [`/account/${id}`, ...(params ? [params]: [])];

    
export type GetAccountByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getAccountById>>>
export type GetAccountByIdQueryError = ErrorType<void | ValidationErrors>

export const useGetAccountById = <TData = Awaited<ReturnType<typeof getAccountById>>, TError = ErrorType<void | ValidationErrors>>(
 id: string,
    params?: GetAccountByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAccountById>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAccountByIdQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccountById>>> = ({ signal }) => getAccountById(id,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAccountById>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Patches one Account by Id in path
 */
export const patchAccountById = (
    id: string,
    patchAccountByIdBody: PatchAccountByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Account>(
      {url: `/account/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchAccountByIdBody
    },
      options);
    }
  


    export type PatchAccountByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchAccountById>>>
    export type PatchAccountByIdMutationBody = PatchAccountByIdBody
    export type PatchAccountByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchAccountById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchAccountById>>, TError,{id: string;data: PatchAccountByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchAccountById>>, {id: string;data: PatchAccountByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchAccountById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchAccountById>>, TError, {id: string;data: PatchAccountByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Deletes one Account by Id in path
 */
export const deleteAccountById = (
    id: string,
    deleteAccountByIdBody: DeleteAccountByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Account>(
      {url: `/account/${id}`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteAccountByIdBody
    },
      options);
    }
  


    export type DeleteAccountByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAccountById>>>
    export type DeleteAccountByIdMutationBody = DeleteAccountByIdBody
    export type DeleteAccountByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteAccountById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAccountById>>, TError,{id: string;data: DeleteAccountByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAccountById>>, {id: string;data: DeleteAccountByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteAccountById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteAccountById>>, TError, {id: string;data: DeleteAccountByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Restores one Account by Id in path
 */
export const patchRestoreAccountById = (
    id: string,
    patchRestoreAccountByIdBody: PatchRestoreAccountByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Account>(
      {url: `/account/${id}/restore`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchRestoreAccountByIdBody
    },
      options);
    }
  


    export type PatchRestoreAccountByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchRestoreAccountById>>>
    export type PatchRestoreAccountByIdMutationBody = PatchRestoreAccountByIdBody
    export type PatchRestoreAccountByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchRestoreAccountById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchRestoreAccountById>>, TError,{id: string;data: PatchRestoreAccountByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchRestoreAccountById>>, {id: string;data: PatchRestoreAccountByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchRestoreAccountById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchRestoreAccountById>>, TError, {id: string;data: PatchRestoreAccountByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Destroys one Account by Id in path
 */
export const deleteDestroyAccountById = (
    id: string,
    deleteDestroyAccountByIdBody: DeleteDestroyAccountByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Account>(
      {url: `/account/${id}/destroy`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteDestroyAccountByIdBody
    },
      options);
    }
  


    export type DeleteDestroyAccountByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDestroyAccountById>>>
    export type DeleteDestroyAccountByIdMutationBody = DeleteDestroyAccountByIdBody
    export type DeleteDestroyAccountByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteDestroyAccountById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDestroyAccountById>>, TError,{id: string;data: DeleteDestroyAccountByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDestroyAccountById>>, {id: string;data: DeleteDestroyAccountByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteDestroyAccountById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteDestroyAccountById>>, TError, {id: string;data: DeleteDestroyAccountByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Get one Accounts
 */
export const getOneAccount = (
    params?: GetOneAccountParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<Account>(
      {url: `/account`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetOneAccountQueryKey = (params?: GetOneAccountParams,) => [`/account`, ...(params ? [params]: [])];

    
export type GetOneAccountQueryResult = NonNullable<Awaited<ReturnType<typeof getOneAccount>>>
export type GetOneAccountQueryError = ErrorType<void | ValidationErrors>

export const useGetOneAccount = <TData = Awaited<ReturnType<typeof getOneAccount>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetOneAccountParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOneAccount>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOneAccountQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneAccount>>> = ({ signal }) => getOneAccount(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOneAccount>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Get many Accounts
 */
export const getManyAccounts = (
    params?: GetManyAccountsParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<Accounts>(
      {url: `/accounts`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetManyAccountsQueryKey = (params?: GetManyAccountsParams,) => [`/accounts`, ...(params ? [params]: [])];

    
export type GetManyAccountsQueryResult = NonNullable<Awaited<ReturnType<typeof getManyAccounts>>>
export type GetManyAccountsQueryError = ErrorType<void | ValidationErrors>

export const useGetManyAccounts = <TData = Awaited<ReturnType<typeof getManyAccounts>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetManyAccountsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getManyAccounts>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetManyAccountsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getManyAccounts>>> = ({ signal }) => getManyAccounts(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getManyAccounts>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Posts a new Account
 */
export const postAccount = (
    postAccountBody: PostAccountBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Account>(
      {url: `/accounts`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postAccountBody
    },
      options);
    }
  


    export type PostAccountMutationResult = NonNullable<Awaited<ReturnType<typeof postAccount>>>
    export type PostAccountMutationBody = PostAccountBody
    export type PostAccountMutationError = ErrorType<void | ValidationErrors>

    export const usePostAccount = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAccount>>, TError,{data: PostAccountBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAccount>>, {data: PostAccountBody}> = (props) => {
          const {data} = props ?? {};

          return  postAccount(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postAccount>>, TError, {data: PostAccountBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Imports accounts from Zoho CRM
 */
export const postImportAccountsFromZohoCrm = (
    postImportAccountsFromZohoCrmBody: PostImportAccountsFromZohoCrmBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<PostImportAccountsFromZohoCRMResponseBody>(
      {url: `/accounts/import/zoho-crm`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postImportAccountsFromZohoCrmBody
    },
      options);
    }
  


    export type PostImportAccountsFromZohoCrmMutationResult = NonNullable<Awaited<ReturnType<typeof postImportAccountsFromZohoCrm>>>
    export type PostImportAccountsFromZohoCrmMutationBody = PostImportAccountsFromZohoCrmBody
    export type PostImportAccountsFromZohoCrmMutationError = ErrorType<void | ValidationErrors>

    export const usePostImportAccountsFromZohoCrm = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postImportAccountsFromZohoCrm>>, TError,{data: PostImportAccountsFromZohoCrmBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postImportAccountsFromZohoCrm>>, {data: PostImportAccountsFromZohoCrmBody}> = (props) => {
          const {data} = props ?? {};

          return  postImportAccountsFromZohoCrm(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postImportAccountsFromZohoCrm>>, TError, {data: PostImportAccountsFromZohoCrmBody}, TContext>(mutationFn, mutationOptions)
    }
    