import { Listbox, Transition, TransitionEvents } from '@headlessui/react';
import classNames from 'classnames';
import { merge } from 'lodash-es';
import { FC, Fragment, ReactElement, ReactNode, useMemo } from 'react';
import { HiChevronUpDown } from 'react-icons/hi2';
import { NebPropsWithStd } from '../../../../core';
import { FieldClassConfig, FormConfig } from '../../../contexts';
import { useFormState } from '../../../hooks';
import { FormErrorMessage } from '../../common/form-error-message';
import { Label } from '../../common/label';
import { BaseFieldProps } from '../types';
import { ListOption } from './list-option';
import { ListOptionConfig } from './types';

export const ListBoxFix: typeof Listbox = Listbox;

export type ListProps<T, V> = BaseFieldProps<T> & {
  multiple?: boolean;
  value?: V;
  disabled?: boolean;
  onChange?: (value?: V) => void;
} & (
    | {
        options: ListOptionConfig[];
        placeholder?: string;
        children?: never;
        buttonRender?:
          | ReactNode
          | ((props: { name?: keyof T; hasErrors?: boolean; hasChanged?: boolean }) => ReactElement);
        optionsRender?:
          | ReactNode
          | ((props: {
              children: ReactNode;
              name?: keyof T;
              hasErrors?: boolean;
              hasChanged?: boolean;
            }) => ReactElement);
        optionRender?:
          | ReactNode
          | ((props: {
              option: ListOptionConfig;
              name?: keyof T;
              hasErrors?: boolean;
              hasChanged?: boolean;
            }) => ReactElement);
        transitionEvents?: TransitionEvents;
      }
    | {
        options?: never;
        placeholder?: never;
        children: ReactNode | ((props: { name?: keyof T; hasErrors?: boolean; hasChanged?: boolean }) => ReactElement);
        buttonRender?: never;
        optionsRender?: never;
        optionRender?: never;
        transitionEvents?: never;
      }
  );

export const List = <T, V = string>({
  name,
  validationErrorKey,
  className,
  classes,
  label,
  optional,
  children,
  disabled,
  prefix,
  onChange,
  value,
  multiple,
  placeholder,
  options,
  buttonRender,
  optionsRender,
  optionRender,
  transitionEvents,
}: NebPropsWithStd<ListProps<T, V>>) => {
  const { getFieldErrors, getFieldValue, setFieldValue, validationErrors, config, hasFieldChanged } =
    useFormState() ?? {};

  const { defaultClassConfig } = config ? config : ({} as FormConfig<any>);

  const handleChange = (value: V) => {
    onChange && onChange(value);
    setFieldValue && setFieldValue(name, value);
  };

  const cc = merge({}, defaultClassConfig, classes);

  const fieldErrors = getFieldErrors && useMemo(() => getFieldErrors(validationErrorKey ?? name), [validationErrors]);
  const hasErrors = useMemo(() => fieldErrors && !!fieldErrors.length, [fieldErrors]);

  const hasChanged = hasFieldChanged ? hasFieldChanged(name) : false;

  const fieldValue = (getFieldValue && getFieldValue(name)) ?? ((multiple ? [] : '') as string | string[]);

  const resolvedValue = value ?? fieldValue;
  const values = multiple ? (resolvedValue as string[]) : [resolvedValue as string];

  const resolvedChildren =
    children &&
    ((typeof children === 'function' ? children({ name, hasErrors, hasChanged }) : children) as
      | ReactElement
      | ReactElement[]
      | undefined);

  const displayValue = useMemo(() => {
    const selectedOptions = options?.filter((o) => values.includes(o.value));
    return (selectedOptions?.length ? selectedOptions?.map((o) => o.label ?? o.value).join(', ') : placeholder) ?? '';
  }, [value, fieldValue, options, multiple, placeholder]);

  const ResolvedButton: FC = () => (
    <>
      {buttonRender ? (
        typeof buttonRender === 'function' ? (
          buttonRender({ name, hasErrors, hasChanged })
        ) : (
          buttonRender
        )
      ) : (
        <div className="relative w-full cursor-default bg-white">
          <Listbox.Button
            aria-label={label}
            disabled={disabled}
            aria-invalid={hasErrors}
            className={classNames(cc?.field?.element, {
              [cc?.field?.ready ?? '']: !hasErrors && !disabled && !hasChanged,
              [cc?.field?.changed ?? '']: hasChanged && !disabled && !hasErrors,
              [cc?.field?.error ?? '']: hasErrors,
              [cc?.field?.disabled ?? '']: disabled,
            })}
          >
            <div className="flex">
              <span>{displayValue}</span>
              <HiChevronUpDown className="h-5 w-5 text-gray-400 ml-auto" />
            </div>
          </Listbox.Button>
        </div>
      )}
    </>
  );

  const ResolvedOptions: FC = ({ children: optChildren }) => (
    <>
      {optionsRender ? (
        typeof optionsRender === 'function' ? (
          optionsRender({ children: optChildren, name, hasErrors, hasChanged })
        ) : (
          optionsRender
        )
      ) : (
        <div className={classNames('combo-options relative', className)}>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterEnter={transitionEvents?.afterEnter}
            afterLeave={transitionEvents?.afterLeave}
            beforeEnter={transitionEvents?.beforeEnter}
            beforeLeave={transitionEvents?.beforeLeave}
          >
            <Listbox.Options className={classNames('absolute z-50', cc?.listOptions?.container)}>
              {optChildren}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </>
  );

  const ResolvedOption: FC<{ option: ListOptionConfig }> = ({ option }) => (
    <>
      {optionRender ? (
        typeof optionRender === 'function' ? (
          optionRender({ option, name, hasErrors, hasChanged })
        ) : (
          optionRender
        )
      ) : (
        <ListOption value={option.value} disabled={option.disabled}>
          <li
            className={classNames(cc?.listOptions?.element, {
              [cc?.listOptions?.selected ?? '']: values.includes(option.value),
              [cc?.listOptions?.disabled ?? '']: option.disabled,
            })}
          >
            {option.label ?? option.value}
          </li>
        </ListOption>
      )}
    </>
  );

  return (
    <div className={classNames(className, cc?.container, 'text-field')}>
      <div aria-live="polite">
        <Label label={label} optional={optional} labelClassConfig={cc?.label} />
        <div className={cc?.field?.container}>
          {prefix && <>{prefix}</>}

          <Listbox name={name as string} value={resolvedValue} onChange={handleChange} multiple={multiple}>
            {children ? (
              resolvedChildren
            ) : (
              <>
                <ResolvedButton />
                <ResolvedOptions>
                  {options?.map((o) => (
                    <ResolvedOption key={o.value} option={o}></ResolvedOption>
                  ))}
                </ResolvedOptions>
              </>
            )}
          </Listbox>
        </div>
      </div>
      {fieldErrors &&
        Array.isArray(fieldErrors) &&
        fieldErrors.map((e) => <FormErrorMessage className={cc?.error}>{e}</FormErrorMessage>)}
    </div>
  );
};
