import { FC, useEffect, useRef } from 'react';
import classNames, { Argument } from 'classnames';
import { useClickOutside } from '@villageco/nebula/forms';

export type THModalProps = {
  className?: Argument;
  open?: boolean;
  size?: 'sm' | 'md' | 'lg';
  disableClickOutside?: boolean;
  onClose?: () => void;
};

const THModal: FC<THModalProps> = ({ className, open, size, disableClickOutside = false, onClose, children }) => {
  const previous = useRef({ open }).current;

  const modalRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    console.log('MODAL CLICK OUTSIDE');
    if (onClose && open) {
      onClose();
    }
  };

  useClickOutside(modalRef.current, handleClose, !open || disableClickOutside);

  useEffect(() => {
    if (previous?.open === true && open === false) {
      handleClose();
    }
  }, [open]);

  return (
    <div
      className={classNames(
        'th-modal modal fade w-0 h-full overflow-x-hidden overflow-y-auto',
        { hidden: !open, flex: open },
        className,
      )}
    >
      <div className="absolute bg-black opacity-20 top-0 left-0 h-full w-full z-40"></div>
      <div
        ref={modalRef}
        className={classNames('bg-slate-100 left-1/2 -translate-x-1/2 transition-all rounded-md shadow-md z-50', {
          'min-w-[700px]': size === 'lg',
          'min-w-[500px]': size === 'md',
          'min-w-[350px]': size === 'sm',
          'min-w-none': !size,
          'fixed -bottom-10': !open,
          'absolute -translate-y-1/2 top-1/2': open,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default THModal;
