import { Product } from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import { useGetManyFiles } from '@core/clients/ecommerce-services-admin/files/files';
import { useMemo } from 'react';
import { getPageDataFileIdsFromProduct } from '../helpers/getPageDataFileIdsForProduct';

export const useGetPageDataFiles = (product: Product) => {
  const fileIds = useMemo(() => {
    return getPageDataFileIdsFromProduct(product);
  }, [product]);

  const { data, isLoading, refetch, isFetching } = useGetManyFiles({
    where: { id: { in: fileIds } },
  });

  return { data, isLoading, isFetching, refetch };
};
