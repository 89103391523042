/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * admin-rest-api
 * OpenAPI spec version: 1.0
 */
export type GetManyContactsParams = {
  select?: GetManyContactsSelectQueryParamParameter;
  include?: GetManyContactsIncludeQueryParamParameter;
  where?: GetManyContactsWhereQueryParamParameter;
  withTrashed?: GetManyContactsWithTrashedQueryParamParameter;
};

export type GetOneContactParams = {
  select?: GetOneContactSelectQueryParamParameter;
  include?: GetOneContactIncludeQueryParamParameter;
  where?: GetOneContactWhereQueryParamParameter;
  withTrashed?: GetOneContactWithTrashedQueryParamParameter;
};

export type GetContactByIdParams = {
  select?: GetContactByIdSelectQueryParamParameter;
  include?: GetContactByIdIncludeQueryParamParameter;
  withTrashed?: GetContactByIdWithTrashedQueryParamParameter;
};

export type GetManyAddressesParams = {
  select?: GetManyAddressesSelectQueryParamParameter;
  include?: GetManyAddressesIncludeQueryParamParameter;
  where?: GetManyAddressesWhereQueryParamParameter;
  withTrashed?: GetManyAddressesWithTrashedQueryParamParameter;
};

export type GetOneAddressParams = {
  select?: GetOneAddressSelectQueryParamParameter;
  include?: GetOneAddressIncludeQueryParamParameter;
  where?: GetOneAddressWhereQueryParamParameter;
  withTrashed?: GetOneAddressWithTrashedQueryParamParameter;
};

export type GetAddressByIdParams = {
  select?: GetAddressByIdSelectQueryParamParameter;
  include?: GetAddressByIdIncludeQueryParamParameter;
  withTrashed?: GetAddressByIdWithTrashedQueryParamParameter;
};

export type GetManyAccountsParams = {
  select?: GetManyAccountsSelectQueryParamParameter;
  include?: GetManyAccountsIncludeQueryParamParameter;
  where?: GetManyAccountsWhereQueryParamParameter;
  withTrashed?: GetManyAccountsWithTrashedQueryParamParameter;
};

export type GetOneAccountParams = {
  select?: GetOneAccountSelectQueryParamParameter;
  include?: GetOneAccountIncludeQueryParamParameter;
  where?: GetOneAccountWhereQueryParamParameter;
  withTrashed?: GetOneAccountWithTrashedQueryParamParameter;
};

export type GetAccountByIdParams = {
  select?: GetAccountByIdSelectQueryParamParameter;
  include?: GetAccountByIdIncludeQueryParamParameter;
  withTrashed?: GetAccountByIdWithTrashedQueryParamParameter;
};

export type GetManyFilesParams = {
  select?: GetManyFilesSelectQueryParamParameter;
  include?: GetManyFilesIncludeQueryParamParameter;
  where?: GetManyFilesWhereQueryParamParameter;
  withTrashed?: GetManyFilesWithTrashedQueryParamParameter;
};

export type GetOneFileParams = {
  select?: GetOneFileSelectQueryParamParameter;
  include?: GetOneFileIncludeQueryParamParameter;
  where?: GetOneFileWhereQueryParamParameter;
  withTrashed?: GetOneFileWithTrashedQueryParamParameter;
};

export type GetFileByIdParams = {
  select?: GetFileByIdSelectQueryParamParameter;
  include?: GetFileByIdIncludeQueryParamParameter;
  withTrashed?: GetFileByIdWithTrashedQueryParamParameter;
};

export type GetManyShipmentRunsParams = {
  select?: GetManyShipmentRunsSelectQueryParamParameter;
  include?: GetManyShipmentRunsIncludeQueryParamParameter;
  where?: GetManyShipmentRunsWhereQueryParamParameter;
  withTrashed?: GetManyShipmentRunsWithTrashedQueryParamParameter;
};

export type GetOneShipmentRunParams = {
  select?: GetOneShipmentRunSelectQueryParamParameter;
  include?: GetOneShipmentRunIncludeQueryParamParameter;
  where?: GetOneShipmentRunWhereQueryParamParameter;
  withTrashed?: GetOneShipmentRunWithTrashedQueryParamParameter;
};

export type GetShipmentRunByIdParams = {
  select?: GetShipmentRunByIdSelectQueryParamParameter;
  include?: GetShipmentRunByIdIncludeQueryParamParameter;
  withTrashed?: GetShipmentRunByIdWithTrashedQueryParamParameter;
};

export type GetManySalesOrderLineItemsParams = {
  select?: GetManySalesOrderLineItemsSelectQueryParamParameter;
  include?: GetManySalesOrderLineItemsIncludeQueryParamParameter;
  where?: GetManySalesOrderLineItemsWhereQueryParamParameter;
  withTrashed?: GetManySalesOrderLineItemsWithTrashedQueryParamParameter;
};

export type GetOneSalesOrderLineItemParams = {
  select?: GetOneSalesOrderLineItemSelectQueryParamParameter;
  include?: GetOneSalesOrderLineItemIncludeQueryParamParameter;
  where?: GetOneSalesOrderLineItemWhereQueryParamParameter;
  withTrashed?: GetOneSalesOrderLineItemWithTrashedQueryParamParameter;
};

export type GetSalesOrderLineItemByIdParams = {
  select?: GetSalesOrderLineItemByIdSelectQueryParamParameter;
  include?: GetSalesOrderLineItemByIdIncludeQueryParamParameter;
  withTrashed?: GetSalesOrderLineItemByIdWithTrashedQueryParamParameter;
};

export type GetManySalesOrdersParams = {
  select?: GetManySalesOrdersSelectQueryParamParameter;
  include?: GetManySalesOrdersIncludeQueryParamParameter;
  where?: GetManySalesOrdersWhereQueryParamParameter;
  withTrashed?: GetManySalesOrdersWithTrashedQueryParamParameter;
};

export type GetOneSalesOrderParams = {
  select?: GetOneSalesOrderSelectQueryParamParameter;
  include?: GetOneSalesOrderIncludeQueryParamParameter;
  where?: GetOneSalesOrderWhereQueryParamParameter;
  withTrashed?: GetOneSalesOrderWithTrashedQueryParamParameter;
};

export type GetSalesOrderByIdParams = {
  select?: GetSalesOrderByIdSelectQueryParamParameter;
  include?: GetSalesOrderByIdIncludeQueryParamParameter;
  withTrashed?: GetSalesOrderByIdWithTrashedQueryParamParameter;
};

export type GetManyItemsParams = {
  select?: GetManyItemsSelectQueryParamParameter;
  include?: GetManyItemsIncludeQueryParamParameter;
  where?: GetManyItemsWhereQueryParamParameter;
  withTrashed?: GetManyItemsWithTrashedQueryParamParameter;
};

export type GetOneItemParams = {
  select?: GetOneItemSelectQueryParamParameter;
  include?: GetOneItemIncludeQueryParamParameter;
  where?: GetOneItemWhereQueryParamParameter;
  withTrashed?: GetOneItemWithTrashedQueryParamParameter;
};

export type GetItemByIdParams = {
  select?: GetItemByIdSelectQueryParamParameter;
  include?: GetItemByIdIncludeQueryParamParameter;
  withTrashed?: GetItemByIdWithTrashedQueryParamParameter;
};

export type GetCalculatedShippingParams = { itemIds?: GetCalculatedShippingItemIdsQueryParamParameter };

export type GetManyCategoriesParams = {
  select?: GetManyCategoriesSelectQueryParamParameter;
  include?: GetManyCategoriesIncludeQueryParamParameter;
  where?: GetManyCategoriesWhereQueryParamParameter;
  withTrashed?: GetManyCategoriesWithTrashedQueryParamParameter;
};

export type GetOneCategoryParams = {
  select?: GetOneCategorySelectQueryParamParameter;
  include?: GetOneCategoryIncludeQueryParamParameter;
  where?: GetOneCategoryWhereQueryParamParameter;
  withTrashed?: GetOneCategoryWithTrashedQueryParamParameter;
};

export type GetCategoryByIdParams = {
  select?: GetCategoryByIdSelectQueryParamParameter;
  include?: GetCategoryByIdIncludeQueryParamParameter;
  withTrashed?: GetCategoryByIdWithTrashedQueryParamParameter;
};

export type GetManyProductVariantsParams = {
  select?: GetManyProductVariantsSelectQueryParamParameter;
  include?: GetManyProductVariantsIncludeQueryParamParameter;
  where?: GetManyProductVariantsWhereQueryParamParameter;
  withTrashed?: GetManyProductVariantsWithTrashedQueryParamParameter;
};

export type GetOneProductVariantParams = {
  select?: GetOneProductVariantSelectQueryParamParameter;
  include?: GetOneProductVariantIncludeQueryParamParameter;
  where?: GetOneProductVariantWhereQueryParamParameter;
  withTrashed?: GetOneProductVariantWithTrashedQueryParamParameter;
};

export type GetProductVariantByIdParams = {
  select?: GetProductVariantByIdSelectQueryParamParameter;
  include?: GetProductVariantByIdIncludeQueryParamParameter;
  withTrashed?: GetProductVariantByIdWithTrashedQueryParamParameter;
};

export type GetManyProductsParams = {
  select?: GetManyProductsSelectQueryParamParameter;
  include?: GetManyProductsIncludeQueryParamParameter;
  where?: GetManyProductsWhereQueryParamParameter;
  withTrashed?: GetManyProductsWithTrashedQueryParamParameter;
};

export type GetOneProductParams = {
  select?: GetOneProductSelectQueryParamParameter;
  include?: GetOneProductIncludeQueryParamParameter;
  where?: GetOneProductWhereQueryParamParameter;
  withTrashed?: GetOneProductWithTrashedQueryParamParameter;
};

export type GetProductByIdParams = {
  select?: GetProductByIdSelectQueryParamParameter;
  include?: GetProductByIdIncludeQueryParamParameter;
  withTrashed?: GetProductByIdWithTrashedQueryParamParameter;
};

export type GetManyMembershipOfferingItemsParams = {
  select?: GetManyMembershipOfferingItemsSelectQueryParamParameter;
  include?: GetManyMembershipOfferingItemsIncludeQueryParamParameter;
  where?: GetManyMembershipOfferingItemsWhereQueryParamParameter;
  withTrashed?: GetManyMembershipOfferingItemsWithTrashedQueryParamParameter;
};

export type GetOneMembershipOfferingItemParams = {
  select?: GetOneMembershipOfferingItemSelectQueryParamParameter;
  include?: GetOneMembershipOfferingItemIncludeQueryParamParameter;
  where?: GetOneMembershipOfferingItemWhereQueryParamParameter;
  withTrashed?: GetOneMembershipOfferingItemWithTrashedQueryParamParameter;
};

export type GetMembershipOfferingItemByIdParams = {
  select?: GetMembershipOfferingItemByIdSelectQueryParamParameter;
  include?: GetMembershipOfferingItemByIdIncludeQueryParamParameter;
  withTrashed?: GetMembershipOfferingItemByIdWithTrashedQueryParamParameter;
};

export type GetManyMembershipOfferingCategoriesParams = {
  select?: GetManyMembershipOfferingCategoriesSelectQueryParamParameter;
  include?: GetManyMembershipOfferingCategoriesIncludeQueryParamParameter;
  where?: GetManyMembershipOfferingCategoriesWhereQueryParamParameter;
  withTrashed?: GetManyMembershipOfferingCategoriesWithTrashedQueryParamParameter;
};

export type GetOneMembershipOfferingCategoryParams = {
  select?: GetOneMembershipOfferingCategorySelectQueryParamParameter;
  include?: GetOneMembershipOfferingCategoryIncludeQueryParamParameter;
  where?: GetOneMembershipOfferingCategoryWhereQueryParamParameter;
  withTrashed?: GetOneMembershipOfferingCategoryWithTrashedQueryParamParameter;
};

export type GetMembershipOfferingCategoryByIdParams = {
  select?: GetMembershipOfferingCategoryByIdSelectQueryParamParameter;
  include?: GetMembershipOfferingCategoryByIdIncludeQueryParamParameter;
  withTrashed?: GetMembershipOfferingCategoryByIdWithTrashedQueryParamParameter;
};

export type GetManyMembershipOfferingsParams = {
  select?: GetManyMembershipOfferingsSelectQueryParamParameter;
  include?: GetManyMembershipOfferingsIncludeQueryParamParameter;
  where?: GetManyMembershipOfferingsWhereQueryParamParameter;
  withTrashed?: GetManyMembershipOfferingsWithTrashedQueryParamParameter;
};

export type GetOneMembershipOfferingParams = {
  select?: GetOneMembershipOfferingSelectQueryParamParameter;
  include?: GetOneMembershipOfferingIncludeQueryParamParameter;
  where?: GetOneMembershipOfferingWhereQueryParamParameter;
  withTrashed?: GetOneMembershipOfferingWithTrashedQueryParamParameter;
};

export type GetMembershipOfferingByIdParams = {
  select?: GetMembershipOfferingByIdSelectQueryParamParameter;
  include?: GetMembershipOfferingByIdIncludeQueryParamParameter;
  withTrashed?: GetMembershipOfferingByIdWithTrashedQueryParamParameter;
};

export type GetManyMembershipOrderItemsParams = {
  select?: GetManyMembershipOrderItemsSelectQueryParamParameter;
  include?: GetManyMembershipOrderItemsIncludeQueryParamParameter;
  where?: GetManyMembershipOrderItemsWhereQueryParamParameter;
  withTrashed?: GetManyMembershipOrderItemsWithTrashedQueryParamParameter;
};

export type GetOneMembershipOrderItemParams = {
  select?: GetOneMembershipOrderItemSelectQueryParamParameter;
  include?: GetOneMembershipOrderItemIncludeQueryParamParameter;
  where?: GetOneMembershipOrderItemWhereQueryParamParameter;
  withTrashed?: GetOneMembershipOrderItemWithTrashedQueryParamParameter;
};

export type GetMembershipOrderItemByIdParams = {
  select?: GetMembershipOrderItemByIdSelectQueryParamParameter;
  include?: GetMembershipOrderItemByIdIncludeQueryParamParameter;
  withTrashed?: GetMembershipOrderItemByIdWithTrashedQueryParamParameter;
};

export type GetManyMembershipOrdersParams = {
  select?: GetManyMembershipOrdersSelectQueryParamParameter;
  include?: GetManyMembershipOrdersIncludeQueryParamParameter;
  where?: GetManyMembershipOrdersWhereQueryParamParameter;
  withTrashed?: GetManyMembershipOrdersWithTrashedQueryParamParameter;
};

export type GetOneMembershipOrderParams = {
  select?: GetOneMembershipOrderSelectQueryParamParameter;
  include?: GetOneMembershipOrderIncludeQueryParamParameter;
  where?: GetOneMembershipOrderWhereQueryParamParameter;
  withTrashed?: GetOneMembershipOrderWithTrashedQueryParamParameter;
};

export type GetCalculateMembershipOrderPricingByIdParams = {
  items?: GetCalculateMembershipOrderPricingByIdItemsQueryParamParameter;
};

export type GetMembershipOrderByIdParams = {
  select?: GetMembershipOrderByIdSelectQueryParamParameter;
  include?: GetMembershipOrderByIdIncludeQueryParamParameter;
  withTrashed?: GetMembershipOrderByIdWithTrashedQueryParamParameter;
};

export type GetManyMembershipFrequenciesParams = {
  select?: GetManyMembershipFrequenciesSelectQueryParamParameter;
  include?: GetManyMembershipFrequenciesIncludeQueryParamParameter;
  where?: GetManyMembershipFrequenciesWhereQueryParamParameter;
  withTrashed?: GetManyMembershipFrequenciesWithTrashedQueryParamParameter;
};

export type GetOneMembershipFrequencyParams = {
  select?: GetOneMembershipFrequencySelectQueryParamParameter;
  include?: GetOneMembershipFrequencyIncludeQueryParamParameter;
  where?: GetOneMembershipFrequencyWhereQueryParamParameter;
  withTrashed?: GetOneMembershipFrequencyWithTrashedQueryParamParameter;
};

export type GetMembershipFrequencyByIdParams = {
  select?: GetMembershipFrequencyByIdSelectQueryParamParameter;
  include?: GetMembershipFrequencyByIdIncludeQueryParamParameter;
  withTrashed?: GetMembershipFrequencyByIdWithTrashedQueryParamParameter;
};

export type GetManyMembershipTiersParams = {
  select?: GetManyMembershipTiersSelectQueryParamParameter;
  include?: GetManyMembershipTiersIncludeQueryParamParameter;
  where?: GetManyMembershipTiersWhereQueryParamParameter;
  withTrashed?: GetManyMembershipTiersWithTrashedQueryParamParameter;
};

export type GetOneMembershipTierParams = {
  select?: GetOneMembershipTierSelectQueryParamParameter;
  include?: GetOneMembershipTierIncludeQueryParamParameter;
  where?: GetOneMembershipTierWhereQueryParamParameter;
  withTrashed?: GetOneMembershipTierWithTrashedQueryParamParameter;
};

export type GetMembershipTierByIdParams = {
  select?: GetMembershipTierByIdSelectQueryParamParameter;
  include?: GetMembershipTierByIdIncludeQueryParamParameter;
  withTrashed?: GetMembershipTierByIdWithTrashedQueryParamParameter;
};

export type GetManyMembershipsParams = {
  select?: GetManyMembershipsSelectQueryParamParameter;
  include?: GetManyMembershipsIncludeQueryParamParameter;
  where?: GetManyMembershipsWhereQueryParamParameter;
  withTrashed?: GetManyMembershipsWithTrashedQueryParamParameter;
};

export type GetOneMembershipParams = {
  select?: GetOneMembershipSelectQueryParamParameter;
  include?: GetOneMembershipIncludeQueryParamParameter;
  where?: GetOneMembershipWhereQueryParamParameter;
  withTrashed?: GetOneMembershipWithTrashedQueryParamParameter;
};

export type GetMembershipByIdParams = {
  select?: GetMembershipByIdSelectQueryParamParameter;
  include?: GetMembershipByIdIncludeQueryParamParameter;
  withTrashed?: GetMembershipByIdWithTrashedQueryParamParameter;
};

/**
 * Query params for get-many-contacts}
 */
export type GetManyContactsWithTrashedQueryParamParameter = boolean | null;

export type GetManyContactsWhereQueryParamParameterOneOf = null;

export type GetManyContactsWhereQueryParamParameter = GetManyContactsWhereQueryParamParameterOneOf | ContactWhere;

export type GetManyContactsIncludeQueryParamParameterOneOf = null;

export type GetManyContactsIncludeQueryParamParameter = GetManyContactsIncludeQueryParamParameterOneOf | ContactInclude;

export type GetManyContactsSelectQueryParamParameterOneOf = null;

export type GetManyContactsSelectQueryParamParameter = GetManyContactsSelectQueryParamParameterOneOf | ContactSelect;

/**
 * Query params for get-one-contact}
 */
export type GetOneContactWithTrashedQueryParamParameter = boolean | null;

export type GetOneContactWhereQueryParamParameter = {
  AND?: GetOneContactWhereQueryParamParameterAnd;
  OR?: GetOneContactWhereQueryParamParameterOr;
  NOT?: GetOneContactWhereQueryParamParameterNot;
  id?: GetOneContactWhereQueryParamParameterId;
  name?: GetOneContactWhereQueryParamParameterName;
  email?: GetOneContactWhereQueryParamParameterEmail;
  phone?: GetOneContactWhereQueryParamParameterPhone;
  status?: GetOneContactWhereQueryParamParameterStatus;
  userSub?: GetOneContactWhereQueryParamParameterUserSub;
  zohoCrmContactRef?: GetOneContactWhereQueryParamParameterZohoCrmContactRef;
  zohoInvContactPersonRef?: GetOneContactWhereQueryParamParameterZohoInvContactPersonRef;
  accountId?: GetOneContactWhereQueryParamParameterAccountId;
  jobTitle?: GetOneContactWhereQueryParamParameterJobTitle;
  createdAt?: GetOneContactWhereQueryParamParameterCreatedAt;
  updatedAt?: GetOneContactWhereQueryParamParameterUpdatedAt;
  deletedAt?: GetOneContactWhereQueryParamParameterDeletedAt;
  account?: GetOneContactWhereQueryParamParameterAccount;
  primaryForAccount?: GetOneContactWhereQueryParamParameterPrimaryForAccount;
  billingForAccount?: GetOneContactWhereQueryParamParameterBillingForAccount;
};

export type GetOneContactWhereQueryParamParameterBillingForAccountOneOfIsNot = AccountWhere;

export type GetOneContactWhereQueryParamParameterBillingForAccountOneOfIs = AccountWhere;

export type GetOneContactWhereQueryParamParameterBillingForAccountOneOf = {
  is?: GetOneContactWhereQueryParamParameterBillingForAccountOneOfIs;
  isNot?: GetOneContactWhereQueryParamParameterBillingForAccountOneOfIsNot;
};

export type GetOneContactWhereQueryParamParameterBillingForAccount =
  | AccountWhere
  | GetOneContactWhereQueryParamParameterBillingForAccountOneOf;

export type GetOneContactWhereQueryParamParameterPrimaryForAccountOneOfIsNot = AccountWhere;

export type GetOneContactWhereQueryParamParameterPrimaryForAccountOneOfIs = AccountWhere;

export type GetOneContactWhereQueryParamParameterPrimaryForAccountOneOf = {
  is?: GetOneContactWhereQueryParamParameterPrimaryForAccountOneOfIs;
  isNot?: GetOneContactWhereQueryParamParameterPrimaryForAccountOneOfIsNot;
};

export type GetOneContactWhereQueryParamParameterPrimaryForAccount =
  | AccountWhere
  | GetOneContactWhereQueryParamParameterPrimaryForAccountOneOf;

export type GetOneContactWhereQueryParamParameterAccount =
  | AccountWhere
  | GetOneContactWhereQueryParamParameterAccountOneOf;

export type GetOneContactWhereQueryParamParameterAccountOneOfIsNot = AccountWhere;

export type GetOneContactWhereQueryParamParameterAccountOneOfIs = AccountWhere;

export type GetOneContactWhereQueryParamParameterAccountOneOf = {
  is?: GetOneContactWhereQueryParamParameterAccountOneOfIs;
  isNot?: GetOneContactWhereQueryParamParameterAccountOneOfIsNot;
};

export type GetOneContactWhereQueryParamParameterDeletedAt =
  | string
  | GetOneContactWhereQueryParamParameterDeletedAtOneOf;

export type GetOneContactWhereQueryParamParameterDeletedAtOneOfNot = string | DeletedAt;

export type GetOneContactWhereQueryParamParameterDeletedAtOneOfNotIn = string | string[];

export type GetOneContactWhereQueryParamParameterDeletedAtOneOfIn = string | string[];

export type GetOneContactWhereQueryParamParameterDeletedAtOneOfEquals = string;

export type GetOneContactWhereQueryParamParameterDeletedAtOneOf = {
  equals?: GetOneContactWhereQueryParamParameterDeletedAtOneOfEquals;
  in?: GetOneContactWhereQueryParamParameterDeletedAtOneOfIn;
  notIn?: GetOneContactWhereQueryParamParameterDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneContactWhereQueryParamParameterDeletedAtOneOfNot;
};

export type GetOneContactWhereQueryParamParameterUpdatedAtOneOfNot = string | UpdatedAt;

export type GetOneContactWhereQueryParamParameterUpdatedAtOneOfNotIn = string | string[];

export type GetOneContactWhereQueryParamParameterUpdatedAtOneOfIn = string | string[];

export type GetOneContactWhereQueryParamParameterUpdatedAtOneOf = {
  equals?: string | null;
  in?: GetOneContactWhereQueryParamParameterUpdatedAtOneOfIn;
  notIn?: GetOneContactWhereQueryParamParameterUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneContactWhereQueryParamParameterUpdatedAtOneOfNot;
};

export type GetOneContactWhereQueryParamParameterUpdatedAt =
  | string
  | GetOneContactWhereQueryParamParameterUpdatedAtOneOf;

export type GetOneContactWhereQueryParamParameterCreatedAt =
  | string
  | GetOneContactWhereQueryParamParameterCreatedAtOneOf;

export type GetOneContactWhereQueryParamParameterCreatedAtOneOfNot = string | CreatedAt;

export type GetOneContactWhereQueryParamParameterCreatedAtOneOfNotIn = string | string[];

export type GetOneContactWhereQueryParamParameterCreatedAtOneOfIn = string | string[];

export type GetOneContactWhereQueryParamParameterCreatedAtOneOf = {
  equals?: string | null;
  in?: GetOneContactWhereQueryParamParameterCreatedAtOneOfIn;
  notIn?: GetOneContactWhereQueryParamParameterCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneContactWhereQueryParamParameterCreatedAtOneOfNot;
};

export type GetOneContactWhereQueryParamParameterJobTitle = string | GetOneContactWhereQueryParamParameterJobTitleOneOf;

export type GetOneContactWhereQueryParamParameterJobTitleOneOfNot = string | JobTitle;

export type GetOneContactWhereQueryParamParameterJobTitleOneOfMode =
  typeof GetOneContactWhereQueryParamParameterJobTitleOneOfMode[keyof typeof GetOneContactWhereQueryParamParameterJobTitleOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneContactWhereQueryParamParameterJobTitleOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneContactWhereQueryParamParameterJobTitleOneOfNotIn = string | string[];

export type GetOneContactWhereQueryParamParameterJobTitleOneOfIn = string | string[];

export type GetOneContactWhereQueryParamParameterJobTitleOneOfEquals = string;

export type GetOneContactWhereQueryParamParameterJobTitleOneOf = {
  equals?: GetOneContactWhereQueryParamParameterJobTitleOneOfEquals;
  in?: GetOneContactWhereQueryParamParameterJobTitleOneOfIn;
  notIn?: GetOneContactWhereQueryParamParameterJobTitleOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneContactWhereQueryParamParameterJobTitleOneOfMode;
  not?: GetOneContactWhereQueryParamParameterJobTitleOneOfNot;
};

export type GetOneContactWhereQueryParamParameterAccountId =
  | string
  | GetOneContactWhereQueryParamParameterAccountIdOneOf;

export type GetOneContactWhereQueryParamParameterAccountIdOneOfNot = string | AccountId;

export type GetOneContactWhereQueryParamParameterAccountIdOneOfMode =
  typeof GetOneContactWhereQueryParamParameterAccountIdOneOfMode[keyof typeof GetOneContactWhereQueryParamParameterAccountIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneContactWhereQueryParamParameterAccountIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneContactWhereQueryParamParameterAccountIdOneOfNotIn = string | string[];

export type GetOneContactWhereQueryParamParameterAccountIdOneOfIn = string | string[];

export type GetOneContactWhereQueryParamParameterAccountIdOneOf = {
  equals?: string | null;
  in?: GetOneContactWhereQueryParamParameterAccountIdOneOfIn;
  notIn?: GetOneContactWhereQueryParamParameterAccountIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneContactWhereQueryParamParameterAccountIdOneOfMode;
  not?: GetOneContactWhereQueryParamParameterAccountIdOneOfNot;
};

export type GetOneContactWhereQueryParamParameterZohoInvContactPersonRefOneOf = {
  equals?: GetOneContactWhereQueryParamParameterZohoInvContactPersonRefOneOfEquals;
  in?: GetOneContactWhereQueryParamParameterZohoInvContactPersonRefOneOfIn;
  notIn?: GetOneContactWhereQueryParamParameterZohoInvContactPersonRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneContactWhereQueryParamParameterZohoInvContactPersonRefOneOfMode;
  not?: GetOneContactWhereQueryParamParameterZohoInvContactPersonRefOneOfNot;
};

export type GetOneContactWhereQueryParamParameterZohoInvContactPersonRef =
  | string
  | GetOneContactWhereQueryParamParameterZohoInvContactPersonRefOneOf;

export type GetOneContactWhereQueryParamParameterZohoInvContactPersonRefOneOfNot = string | ZohoInvContactPersonRef;

export type GetOneContactWhereQueryParamParameterZohoInvContactPersonRefOneOfMode =
  typeof GetOneContactWhereQueryParamParameterZohoInvContactPersonRefOneOfMode[keyof typeof GetOneContactWhereQueryParamParameterZohoInvContactPersonRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneContactWhereQueryParamParameterZohoInvContactPersonRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneContactWhereQueryParamParameterZohoInvContactPersonRefOneOfNotIn = string | string[];

export type GetOneContactWhereQueryParamParameterZohoInvContactPersonRefOneOfIn = string | string[];

export type GetOneContactWhereQueryParamParameterZohoInvContactPersonRefOneOfEquals = string;

export type GetOneContactWhereQueryParamParameterZohoCrmContactRef =
  | string
  | GetOneContactWhereQueryParamParameterZohoCrmContactRefOneOf;

export type GetOneContactWhereQueryParamParameterZohoCrmContactRefOneOfNot = string | ZohoCrmContactRef;

export type GetOneContactWhereQueryParamParameterZohoCrmContactRefOneOfMode =
  typeof GetOneContactWhereQueryParamParameterZohoCrmContactRefOneOfMode[keyof typeof GetOneContactWhereQueryParamParameterZohoCrmContactRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneContactWhereQueryParamParameterZohoCrmContactRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneContactWhereQueryParamParameterZohoCrmContactRefOneOfNotIn = string | string[];

export type GetOneContactWhereQueryParamParameterZohoCrmContactRefOneOfIn = string | string[];

export type GetOneContactWhereQueryParamParameterZohoCrmContactRefOneOfEquals = string;

export type GetOneContactWhereQueryParamParameterZohoCrmContactRefOneOf = {
  equals?: GetOneContactWhereQueryParamParameterZohoCrmContactRefOneOfEquals;
  in?: GetOneContactWhereQueryParamParameterZohoCrmContactRefOneOfIn;
  notIn?: GetOneContactWhereQueryParamParameterZohoCrmContactRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneContactWhereQueryParamParameterZohoCrmContactRefOneOfMode;
  not?: GetOneContactWhereQueryParamParameterZohoCrmContactRefOneOfNot;
};

export type GetOneContactWhereQueryParamParameterUserSubOneOfNot = string | UserSub;

export type GetOneContactWhereQueryParamParameterUserSubOneOfMode =
  typeof GetOneContactWhereQueryParamParameterUserSubOneOfMode[keyof typeof GetOneContactWhereQueryParamParameterUserSubOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneContactWhereQueryParamParameterUserSubOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneContactWhereQueryParamParameterUserSubOneOfNotIn = string | string[];

export type GetOneContactWhereQueryParamParameterUserSubOneOfIn = string | string[];

export type GetOneContactWhereQueryParamParameterUserSubOneOfEquals = string;

export type GetOneContactWhereQueryParamParameterUserSubOneOf = {
  equals?: GetOneContactWhereQueryParamParameterUserSubOneOfEquals;
  in?: GetOneContactWhereQueryParamParameterUserSubOneOfIn;
  notIn?: GetOneContactWhereQueryParamParameterUserSubOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneContactWhereQueryParamParameterUserSubOneOfMode;
  not?: GetOneContactWhereQueryParamParameterUserSubOneOfNot;
};

export type GetOneContactWhereQueryParamParameterUserSub = string | GetOneContactWhereQueryParamParameterUserSubOneOf;

export type GetOneContactWhereQueryParamParameterStatusOneOfNot = 'ACTIVE' | 'DISABLED' | Status;

export type GetOneContactWhereQueryParamParameterStatusOneOf = {
  equals?: GetOneContactWhereQueryParamParameterStatusOneOfEquals;
  in?: GetOneContactWhereQueryParamParameterStatusOneOfIn;
  notIn?: GetOneContactWhereQueryParamParameterStatusOneOfNotIn;
  not?: GetOneContactWhereQueryParamParameterStatusOneOfNot;
} | null;

export type GetOneContactWhereQueryParamParameterStatus =
  | 'ACTIVE'
  | 'DISABLED'
  | GetOneContactWhereQueryParamParameterStatusOneOf;

export type GetOneContactWhereQueryParamParameterStatusOneOfNotInOneOfItem =
  typeof GetOneContactWhereQueryParamParameterStatusOneOfNotInOneOfItem[keyof typeof GetOneContactWhereQueryParamParameterStatusOneOfNotInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneContactWhereQueryParamParameterStatusOneOfNotInOneOfItem = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type GetOneContactWhereQueryParamParameterStatusOneOfNotIn =
  | 'ACTIVE'
  | 'DISABLED'
  | GetOneContactWhereQueryParamParameterStatusOneOfNotInOneOfItem[];

export type GetOneContactWhereQueryParamParameterStatusOneOfInOneOfItem =
  typeof GetOneContactWhereQueryParamParameterStatusOneOfInOneOfItem[keyof typeof GetOneContactWhereQueryParamParameterStatusOneOfInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneContactWhereQueryParamParameterStatusOneOfInOneOfItem = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type GetOneContactWhereQueryParamParameterStatusOneOfIn =
  | 'ACTIVE'
  | 'DISABLED'
  | GetOneContactWhereQueryParamParameterStatusOneOfInOneOfItem[];

export type GetOneContactWhereQueryParamParameterStatusOneOfEquals =
  typeof GetOneContactWhereQueryParamParameterStatusOneOfEquals[keyof typeof GetOneContactWhereQueryParamParameterStatusOneOfEquals];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneContactWhereQueryParamParameterStatusOneOfEquals = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type GetOneContactWhereQueryParamParameterPhone = string | GetOneContactWhereQueryParamParameterPhoneOneOf;

export type GetOneContactWhereQueryParamParameterPhoneOneOfNot = string | Phone;

export type GetOneContactWhereQueryParamParameterPhoneOneOfMode =
  typeof GetOneContactWhereQueryParamParameterPhoneOneOfMode[keyof typeof GetOneContactWhereQueryParamParameterPhoneOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneContactWhereQueryParamParameterPhoneOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneContactWhereQueryParamParameterPhoneOneOfNotIn = string | string[];

export type GetOneContactWhereQueryParamParameterPhoneOneOfIn = string | string[];

export type GetOneContactWhereQueryParamParameterPhoneOneOfEquals = string;

export type GetOneContactWhereQueryParamParameterPhoneOneOf = {
  equals?: GetOneContactWhereQueryParamParameterPhoneOneOfEquals;
  in?: GetOneContactWhereQueryParamParameterPhoneOneOfIn;
  notIn?: GetOneContactWhereQueryParamParameterPhoneOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneContactWhereQueryParamParameterPhoneOneOfMode;
  not?: GetOneContactWhereQueryParamParameterPhoneOneOfNot;
};

export type GetOneContactWhereQueryParamParameterEmailOneOfNot = string | Email;

export type GetOneContactWhereQueryParamParameterEmailOneOfMode =
  typeof GetOneContactWhereQueryParamParameterEmailOneOfMode[keyof typeof GetOneContactWhereQueryParamParameterEmailOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneContactWhereQueryParamParameterEmailOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneContactWhereQueryParamParameterEmailOneOfNotIn = string | string[];

export type GetOneContactWhereQueryParamParameterEmailOneOfIn = string | string[];

export type GetOneContactWhereQueryParamParameterEmailOneOf = {
  equals?: string | null;
  in?: GetOneContactWhereQueryParamParameterEmailOneOfIn;
  notIn?: GetOneContactWhereQueryParamParameterEmailOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneContactWhereQueryParamParameterEmailOneOfMode;
  not?: GetOneContactWhereQueryParamParameterEmailOneOfNot;
};

export type GetOneContactWhereQueryParamParameterEmail = string | GetOneContactWhereQueryParamParameterEmailOneOf;

export type GetOneContactWhereQueryParamParameterName = string | GetOneContactWhereQueryParamParameterNameOneOf;

export type GetOneContactWhereQueryParamParameterNameOneOfNot = string | Name;

export type GetOneContactWhereQueryParamParameterNameOneOfMode =
  typeof GetOneContactWhereQueryParamParameterNameOneOfMode[keyof typeof GetOneContactWhereQueryParamParameterNameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneContactWhereQueryParamParameterNameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneContactWhereQueryParamParameterNameOneOfNotIn = string | string[];

export type GetOneContactWhereQueryParamParameterNameOneOfIn = string | string[];

export type GetOneContactWhereQueryParamParameterNameOneOf = {
  equals?: string | null;
  in?: GetOneContactWhereQueryParamParameterNameOneOfIn;
  notIn?: GetOneContactWhereQueryParamParameterNameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneContactWhereQueryParamParameterNameOneOfMode;
  not?: GetOneContactWhereQueryParamParameterNameOneOfNot;
};

export type GetOneContactWhereQueryParamParameterId = string | GetOneContactWhereQueryParamParameterIdOneOf;

export type GetOneContactWhereQueryParamParameterIdOneOfNot = string | Id;

export type GetOneContactWhereQueryParamParameterIdOneOfMode =
  typeof GetOneContactWhereQueryParamParameterIdOneOfMode[keyof typeof GetOneContactWhereQueryParamParameterIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneContactWhereQueryParamParameterIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneContactWhereQueryParamParameterIdOneOfNotIn = string | string[];

export type GetOneContactWhereQueryParamParameterIdOneOfIn = string | string[];

export type GetOneContactWhereQueryParamParameterIdOneOf = {
  equals?: string | null;
  in?: GetOneContactWhereQueryParamParameterIdOneOfIn;
  notIn?: GetOneContactWhereQueryParamParameterIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneContactWhereQueryParamParameterIdOneOfMode;
  not?: GetOneContactWhereQueryParamParameterIdOneOfNot;
};

export type GetOneContactWhereQueryParamParameterNot = ContactWhere | ContactWhere[];

export type GetOneContactWhereQueryParamParameterOr = ContactWhere | ContactWhere[];

export type GetOneContactWhereQueryParamParameterAnd = ContactWhere | ContactWhere[];

export type GetOneContactIncludeQueryParamParameterOneOf = null;

export type GetOneContactIncludeQueryParamParameter = GetOneContactIncludeQueryParamParameterOneOf | ContactInclude;

export type GetOneContactSelectQueryParamParameterOneOf = null;

export type GetOneContactSelectQueryParamParameter = GetOneContactSelectQueryParamParameterOneOf | ContactSelect;

/**
 * Query params for get-contact-by-id}
 */
export type GetContactByIdWithTrashedQueryParamParameter = boolean | null;

export type GetContactByIdIncludeQueryParamParameterOneOf = null;

export type GetContactByIdIncludeQueryParamParameter = GetContactByIdIncludeQueryParamParameterOneOf | ContactInclude;

export type GetContactByIdSelectQueryParamParameterOneOf = null;

export type GetContactByIdSelectQueryParamParameter = GetContactByIdSelectQueryParamParameterOneOf | ContactSelect;

/**
 * Query params for get-many-addresses}
 */
export type GetManyAddressesWithTrashedQueryParamParameter = boolean | null;

export type GetManyAddressesWhereQueryParamParameterOneOf = null;

export type GetManyAddressesWhereQueryParamParameter = GetManyAddressesWhereQueryParamParameterOneOf | AddressWhere;

export type GetManyAddressesIncludeQueryParamParameterOneOf = null;

export type GetManyAddressesIncludeQueryParamParameter =
  | GetManyAddressesIncludeQueryParamParameterOneOf
  | AddressInclude;

export type GetManyAddressesSelectQueryParamParameterOneOf = null;

export type GetManyAddressesSelectQueryParamParameter = GetManyAddressesSelectQueryParamParameterOneOf | AddressSelect;

/**
 * Query params for get-one-address}
 */
export type GetOneAddressWithTrashedQueryParamParameter = boolean | null;

export type GetOneAddressWhereQueryParamParameterAccount =
  | AccountWhere
  | GetOneAddressWhereQueryParamParameterAccountOneOf;

export type GetOneAddressWhereQueryParamParameter = {
  AND?: GetOneAddressWhereQueryParamParameterAnd;
  OR?: GetOneAddressWhereQueryParamParameterOr;
  NOT?: GetOneAddressWhereQueryParamParameterNot;
  id?: GetOneAddressWhereQueryParamParameterId;
  name?: GetOneAddressWhereQueryParamParameterName;
  line1?: GetOneAddressWhereQueryParamParameterLine1;
  line2?: GetOneAddressWhereQueryParamParameterLine2;
  city?: GetOneAddressWhereQueryParamParameterCity;
  state?: GetOneAddressWhereQueryParamParameterState;
  country?: GetOneAddressWhereQueryParamParameterCountry;
  postalCode?: GetOneAddressWhereQueryParamParameterPostalCode;
  type?: GetOneAddressWhereQueryParamParameterType;
  accountId?: GetOneAddressWhereQueryParamParameterAccountId;
  createdAt?: GetOneAddressWhereQueryParamParameterCreatedAt;
  updatedAt?: GetOneAddressWhereQueryParamParameterUpdatedAt;
  deletedAt?: GetOneAddressWhereQueryParamParameterDeletedAt;
  account?: GetOneAddressWhereQueryParamParameterAccount;
};

export type GetOneAddressWhereQueryParamParameterAccountOneOfIsNot = AccountWhere;

export type GetOneAddressWhereQueryParamParameterAccountOneOfIs = AccountWhere;

export type GetOneAddressWhereQueryParamParameterAccountOneOf = {
  is?: GetOneAddressWhereQueryParamParameterAccountOneOfIs;
  isNot?: GetOneAddressWhereQueryParamParameterAccountOneOfIsNot;
};

export type GetOneAddressWhereQueryParamParameterDeletedAt =
  | string
  | GetOneAddressWhereQueryParamParameterDeletedAtOneOf;

export type GetOneAddressWhereQueryParamParameterDeletedAtOneOfNot = string | DeletedAt;

export type GetOneAddressWhereQueryParamParameterDeletedAtOneOfNotIn = string | string[];

export type GetOneAddressWhereQueryParamParameterDeletedAtOneOfIn = string | string[];

export type GetOneAddressWhereQueryParamParameterDeletedAtOneOfEquals = string;

export type GetOneAddressWhereQueryParamParameterDeletedAtOneOf = {
  equals?: GetOneAddressWhereQueryParamParameterDeletedAtOneOfEquals;
  in?: GetOneAddressWhereQueryParamParameterDeletedAtOneOfIn;
  notIn?: GetOneAddressWhereQueryParamParameterDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneAddressWhereQueryParamParameterDeletedAtOneOfNot;
};

export type GetOneAddressWhereQueryParamParameterUpdatedAt =
  | string
  | GetOneAddressWhereQueryParamParameterUpdatedAtOneOf;

export type GetOneAddressWhereQueryParamParameterUpdatedAtOneOfNot = string | UpdatedAt;

export type GetOneAddressWhereQueryParamParameterUpdatedAtOneOfNotIn = string | string[];

export type GetOneAddressWhereQueryParamParameterUpdatedAtOneOfIn = string | string[];

export type GetOneAddressWhereQueryParamParameterUpdatedAtOneOf = {
  equals?: string | null;
  in?: GetOneAddressWhereQueryParamParameterUpdatedAtOneOfIn;
  notIn?: GetOneAddressWhereQueryParamParameterUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneAddressWhereQueryParamParameterUpdatedAtOneOfNot;
};

export type GetOneAddressWhereQueryParamParameterCreatedAtOneOfNot = string | CreatedAt;

export type GetOneAddressWhereQueryParamParameterCreatedAtOneOfNotIn = string | string[];

export type GetOneAddressWhereQueryParamParameterCreatedAtOneOfIn = string | string[];

export type GetOneAddressWhereQueryParamParameterCreatedAtOneOf = {
  equals?: string | null;
  in?: GetOneAddressWhereQueryParamParameterCreatedAtOneOfIn;
  notIn?: GetOneAddressWhereQueryParamParameterCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneAddressWhereQueryParamParameterCreatedAtOneOfNot;
};

export type GetOneAddressWhereQueryParamParameterCreatedAt =
  | string
  | GetOneAddressWhereQueryParamParameterCreatedAtOneOf;

export type GetOneAddressWhereQueryParamParameterAccountIdOneOfNot = string | AccountId;

export type GetOneAddressWhereQueryParamParameterAccountIdOneOfMode =
  typeof GetOneAddressWhereQueryParamParameterAccountIdOneOfMode[keyof typeof GetOneAddressWhereQueryParamParameterAccountIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAddressWhereQueryParamParameterAccountIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneAddressWhereQueryParamParameterAccountIdOneOfNotIn = string | string[];

export type GetOneAddressWhereQueryParamParameterAccountIdOneOfIn = string | string[];

export type GetOneAddressWhereQueryParamParameterAccountIdOneOf = {
  equals?: string | null;
  in?: GetOneAddressWhereQueryParamParameterAccountIdOneOfIn;
  notIn?: GetOneAddressWhereQueryParamParameterAccountIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneAddressWhereQueryParamParameterAccountIdOneOfMode;
  not?: GetOneAddressWhereQueryParamParameterAccountIdOneOfNot;
};

export type GetOneAddressWhereQueryParamParameterAccountId =
  | string
  | GetOneAddressWhereQueryParamParameterAccountIdOneOf;

export type GetOneAddressWhereQueryParamParameterTypeOneOfNot = 'BILLING' | 'SHIPPING' | Type;

export type GetOneAddressWhereQueryParamParameterTypeOneOf = {
  equals?: GetOneAddressWhereQueryParamParameterTypeOneOfEquals;
  in?: GetOneAddressWhereQueryParamParameterTypeOneOfIn;
  notIn?: GetOneAddressWhereQueryParamParameterTypeOneOfNotIn;
  not?: GetOneAddressWhereQueryParamParameterTypeOneOfNot;
} | null;

export type GetOneAddressWhereQueryParamParameterType =
  | 'BILLING'
  | 'SHIPPING'
  | GetOneAddressWhereQueryParamParameterTypeOneOf;

export type GetOneAddressWhereQueryParamParameterTypeOneOfNotInOneOfItem =
  typeof GetOneAddressWhereQueryParamParameterTypeOneOfNotInOneOfItem[keyof typeof GetOneAddressWhereQueryParamParameterTypeOneOfNotInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAddressWhereQueryParamParameterTypeOneOfNotInOneOfItem = {
  BILLING: 'BILLING',
  SHIPPING: 'SHIPPING',
} as const;

export type GetOneAddressWhereQueryParamParameterTypeOneOfNotIn =
  | 'BILLING'
  | 'SHIPPING'
  | GetOneAddressWhereQueryParamParameterTypeOneOfNotInOneOfItem[];

export type GetOneAddressWhereQueryParamParameterTypeOneOfInOneOfItem =
  typeof GetOneAddressWhereQueryParamParameterTypeOneOfInOneOfItem[keyof typeof GetOneAddressWhereQueryParamParameterTypeOneOfInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAddressWhereQueryParamParameterTypeOneOfInOneOfItem = {
  BILLING: 'BILLING',
  SHIPPING: 'SHIPPING',
} as const;

export type GetOneAddressWhereQueryParamParameterTypeOneOfIn =
  | 'BILLING'
  | 'SHIPPING'
  | GetOneAddressWhereQueryParamParameterTypeOneOfInOneOfItem[];

export type GetOneAddressWhereQueryParamParameterTypeOneOfEquals =
  typeof GetOneAddressWhereQueryParamParameterTypeOneOfEquals[keyof typeof GetOneAddressWhereQueryParamParameterTypeOneOfEquals];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAddressWhereQueryParamParameterTypeOneOfEquals = {
  BILLING: 'BILLING',
  SHIPPING: 'SHIPPING',
} as const;

export type GetOneAddressWhereQueryParamParameterPostalCode =
  | string
  | GetOneAddressWhereQueryParamParameterPostalCodeOneOf;

export type GetOneAddressWhereQueryParamParameterPostalCodeOneOfNot = string | PostalCode;

export type GetOneAddressWhereQueryParamParameterPostalCodeOneOfMode =
  typeof GetOneAddressWhereQueryParamParameterPostalCodeOneOfMode[keyof typeof GetOneAddressWhereQueryParamParameterPostalCodeOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAddressWhereQueryParamParameterPostalCodeOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneAddressWhereQueryParamParameterPostalCodeOneOfNotIn = string | string[];

export type GetOneAddressWhereQueryParamParameterPostalCodeOneOfIn = string | string[];

export type GetOneAddressWhereQueryParamParameterPostalCodeOneOf = {
  equals?: string | null;
  in?: GetOneAddressWhereQueryParamParameterPostalCodeOneOfIn;
  notIn?: GetOneAddressWhereQueryParamParameterPostalCodeOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneAddressWhereQueryParamParameterPostalCodeOneOfMode;
  not?: GetOneAddressWhereQueryParamParameterPostalCodeOneOfNot;
};

export type GetOneAddressWhereQueryParamParameterCountryOneOfNot = string | Country;

export type GetOneAddressWhereQueryParamParameterCountryOneOfMode =
  typeof GetOneAddressWhereQueryParamParameterCountryOneOfMode[keyof typeof GetOneAddressWhereQueryParamParameterCountryOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAddressWhereQueryParamParameterCountryOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneAddressWhereQueryParamParameterCountryOneOfNotIn = string | string[];

export type GetOneAddressWhereQueryParamParameterCountryOneOfIn = string | string[];

export type GetOneAddressWhereQueryParamParameterCountryOneOfEquals = string;

export type GetOneAddressWhereQueryParamParameterCountryOneOf = {
  equals?: GetOneAddressWhereQueryParamParameterCountryOneOfEquals;
  in?: GetOneAddressWhereQueryParamParameterCountryOneOfIn;
  notIn?: GetOneAddressWhereQueryParamParameterCountryOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneAddressWhereQueryParamParameterCountryOneOfMode;
  not?: GetOneAddressWhereQueryParamParameterCountryOneOfNot;
};

export type GetOneAddressWhereQueryParamParameterCountry = string | GetOneAddressWhereQueryParamParameterCountryOneOf;

export type GetOneAddressWhereQueryParamParameterState = string | GetOneAddressWhereQueryParamParameterStateOneOf;

export type GetOneAddressWhereQueryParamParameterStateOneOfNot = string | State;

export type GetOneAddressWhereQueryParamParameterStateOneOfMode =
  typeof GetOneAddressWhereQueryParamParameterStateOneOfMode[keyof typeof GetOneAddressWhereQueryParamParameterStateOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAddressWhereQueryParamParameterStateOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneAddressWhereQueryParamParameterStateOneOfNotIn = string | string[];

export type GetOneAddressWhereQueryParamParameterStateOneOfIn = string | string[];

export type GetOneAddressWhereQueryParamParameterStateOneOf = {
  equals?: string | null;
  in?: GetOneAddressWhereQueryParamParameterStateOneOfIn;
  notIn?: GetOneAddressWhereQueryParamParameterStateOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneAddressWhereQueryParamParameterStateOneOfMode;
  not?: GetOneAddressWhereQueryParamParameterStateOneOfNot;
};

export type GetOneAddressWhereQueryParamParameterCityOneOfNot = string | City;

export type GetOneAddressWhereQueryParamParameterCityOneOfMode =
  typeof GetOneAddressWhereQueryParamParameterCityOneOfMode[keyof typeof GetOneAddressWhereQueryParamParameterCityOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAddressWhereQueryParamParameterCityOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneAddressWhereQueryParamParameterCityOneOfNotIn = string | string[];

export type GetOneAddressWhereQueryParamParameterCityOneOfIn = string | string[];

export type GetOneAddressWhereQueryParamParameterCityOneOf = {
  equals?: string | null;
  in?: GetOneAddressWhereQueryParamParameterCityOneOfIn;
  notIn?: GetOneAddressWhereQueryParamParameterCityOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneAddressWhereQueryParamParameterCityOneOfMode;
  not?: GetOneAddressWhereQueryParamParameterCityOneOfNot;
};

export type GetOneAddressWhereQueryParamParameterCity = string | GetOneAddressWhereQueryParamParameterCityOneOf;

export type GetOneAddressWhereQueryParamParameterLine2 = string | GetOneAddressWhereQueryParamParameterLine2OneOf;

export type GetOneAddressWhereQueryParamParameterLine2OneOfNot = string | Line2;

export type GetOneAddressWhereQueryParamParameterLine2OneOfMode =
  typeof GetOneAddressWhereQueryParamParameterLine2OneOfMode[keyof typeof GetOneAddressWhereQueryParamParameterLine2OneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAddressWhereQueryParamParameterLine2OneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneAddressWhereQueryParamParameterLine2OneOfNotIn = string | string[];

export type GetOneAddressWhereQueryParamParameterLine2OneOfIn = string | string[];

export type GetOneAddressWhereQueryParamParameterLine2OneOfEquals = string;

export type GetOneAddressWhereQueryParamParameterLine2OneOf = {
  equals?: GetOneAddressWhereQueryParamParameterLine2OneOfEquals;
  in?: GetOneAddressWhereQueryParamParameterLine2OneOfIn;
  notIn?: GetOneAddressWhereQueryParamParameterLine2OneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneAddressWhereQueryParamParameterLine2OneOfMode;
  not?: GetOneAddressWhereQueryParamParameterLine2OneOfNot;
};

export type GetOneAddressWhereQueryParamParameterLine1 = string | GetOneAddressWhereQueryParamParameterLine1OneOf;

export type GetOneAddressWhereQueryParamParameterLine1OneOfNot = string | Line1;

export type GetOneAddressWhereQueryParamParameterLine1OneOfMode =
  typeof GetOneAddressWhereQueryParamParameterLine1OneOfMode[keyof typeof GetOneAddressWhereQueryParamParameterLine1OneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAddressWhereQueryParamParameterLine1OneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneAddressWhereQueryParamParameterLine1OneOfNotIn = string | string[];

export type GetOneAddressWhereQueryParamParameterLine1OneOfIn = string | string[];

export type GetOneAddressWhereQueryParamParameterLine1OneOf = {
  equals?: string | null;
  in?: GetOneAddressWhereQueryParamParameterLine1OneOfIn;
  notIn?: GetOneAddressWhereQueryParamParameterLine1OneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneAddressWhereQueryParamParameterLine1OneOfMode;
  not?: GetOneAddressWhereQueryParamParameterLine1OneOfNot;
};

export type GetOneAddressWhereQueryParamParameterName = string | GetOneAddressWhereQueryParamParameterNameOneOf;

export type GetOneAddressWhereQueryParamParameterNameOneOfNot = string | Name;

export type GetOneAddressWhereQueryParamParameterNameOneOfMode =
  typeof GetOneAddressWhereQueryParamParameterNameOneOfMode[keyof typeof GetOneAddressWhereQueryParamParameterNameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAddressWhereQueryParamParameterNameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneAddressWhereQueryParamParameterNameOneOfNotIn = string | string[];

export type GetOneAddressWhereQueryParamParameterNameOneOfIn = string | string[];

export type GetOneAddressWhereQueryParamParameterNameOneOfEquals = string;

export type GetOneAddressWhereQueryParamParameterNameOneOf = {
  equals?: GetOneAddressWhereQueryParamParameterNameOneOfEquals;
  in?: GetOneAddressWhereQueryParamParameterNameOneOfIn;
  notIn?: GetOneAddressWhereQueryParamParameterNameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneAddressWhereQueryParamParameterNameOneOfMode;
  not?: GetOneAddressWhereQueryParamParameterNameOneOfNot;
};

export type GetOneAddressWhereQueryParamParameterIdOneOfNot = string | Id;

export type GetOneAddressWhereQueryParamParameterIdOneOfMode =
  typeof GetOneAddressWhereQueryParamParameterIdOneOfMode[keyof typeof GetOneAddressWhereQueryParamParameterIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAddressWhereQueryParamParameterIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneAddressWhereQueryParamParameterIdOneOfNotIn = string | string[];

export type GetOneAddressWhereQueryParamParameterIdOneOfIn = string | string[];

export type GetOneAddressWhereQueryParamParameterIdOneOf = {
  equals?: string | null;
  in?: GetOneAddressWhereQueryParamParameterIdOneOfIn;
  notIn?: GetOneAddressWhereQueryParamParameterIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneAddressWhereQueryParamParameterIdOneOfMode;
  not?: GetOneAddressWhereQueryParamParameterIdOneOfNot;
};

export type GetOneAddressWhereQueryParamParameterId = string | GetOneAddressWhereQueryParamParameterIdOneOf;

export type GetOneAddressWhereQueryParamParameterNot = AddressWhere | AddressWhere[];

export type GetOneAddressWhereQueryParamParameterOr = AddressWhere | AddressWhere[];

export type GetOneAddressWhereQueryParamParameterAnd = AddressWhere | AddressWhere[];

export type GetOneAddressIncludeQueryParamParameterOneOf = null;

export type GetOneAddressIncludeQueryParamParameter = GetOneAddressIncludeQueryParamParameterOneOf | AddressInclude;

export type GetOneAddressSelectQueryParamParameterOneOf = null;

export type GetOneAddressSelectQueryParamParameter = GetOneAddressSelectQueryParamParameterOneOf | AddressSelect;

/**
 * Query params for get-address-by-id}
 */
export type GetAddressByIdWithTrashedQueryParamParameter = boolean | null;

export type GetAddressByIdIncludeQueryParamParameterOneOf = null;

export type GetAddressByIdIncludeQueryParamParameter = GetAddressByIdIncludeQueryParamParameterOneOf | AddressInclude;

export type GetAddressByIdSelectQueryParamParameterOneOf = null;

export type GetAddressByIdSelectQueryParamParameter = GetAddressByIdSelectQueryParamParameterOneOf | AddressSelect;

/**
 * Query params for get-many-accounts}
 */
export type GetManyAccountsWithTrashedQueryParamParameter = boolean | null;

export type GetManyAccountsWhereQueryParamParameterOneOf = null;

export type GetManyAccountsWhereQueryParamParameter = GetManyAccountsWhereQueryParamParameterOneOf | AccountWhere;

export type GetManyAccountsIncludeQueryParamParameterOneOf = null;

export type GetManyAccountsIncludeQueryParamParameter = GetManyAccountsIncludeQueryParamParameterOneOf | AccountInclude;

export type GetManyAccountsSelectQueryParamParameterOneOf = null;

export type GetManyAccountsSelectQueryParamParameter = GetManyAccountsSelectQueryParamParameterOneOf | AccountSelect;

/**
 * Query params for get-one-account}
 */
export type GetOneAccountWithTrashedQueryParamParameter = boolean | null;

export type GetOneAccountWhereQueryParamParameterMembershipsNone = MembershipWhere;

export type GetOneAccountWhereQueryParamParameterMembershipsEvery = MembershipWhere;

export type GetOneAccountWhereQueryParamParameterMembershipsSome = MembershipWhere;

export type GetOneAccountWhereQueryParamParameterMemberships = {
  some?: GetOneAccountWhereQueryParamParameterMembershipsSome;
  every?: GetOneAccountWhereQueryParamParameterMembershipsEvery;
  none?: GetOneAccountWhereQueryParamParameterMembershipsNone;
} | null;

export type GetOneAccountWhereQueryParamParameter = {
  AND?: GetOneAccountWhereQueryParamParameterAnd;
  OR?: GetOneAccountWhereQueryParamParameterOr;
  NOT?: GetOneAccountWhereQueryParamParameterNot;
  id?: GetOneAccountWhereQueryParamParameterId;
  name?: GetOneAccountWhereQueryParamParameterName;
  phone?: GetOneAccountWhereQueryParamParameterPhone;
  status?: GetOneAccountWhereQueryParamParameterStatus;
  currency?: GetOneAccountWhereQueryParamParameterCurrency;
  govBusinessNumber?: GetOneAccountWhereQueryParamParameterGovBusinessNumber;
  paymentTermDays?: GetOneAccountWhereQueryParamParameterPaymentTermDays;
  zohoCrmAccountRef?: GetOneAccountWhereQueryParamParameterZohoCrmAccountRef;
  zohoCrmParentAccountRef?: GetOneAccountWhereQueryParamParameterZohoCrmParentAccountRef;
  zohoInvContactRef?: GetOneAccountWhereQueryParamParameterZohoInvContactRef;
  stripeCustomerRef?: GetOneAccountWhereQueryParamParameterStripeCustomerRef;
  universalCustomerId?: GetOneAccountWhereQueryParamParameterUniversalCustomerId;
  parentAccountId?: GetOneAccountWhereQueryParamParameterParentAccountId;
  primaryContactId?: GetOneAccountWhereQueryParamParameterPrimaryContactId;
  billingContactId?: GetOneAccountWhereQueryParamParameterBillingContactId;
  createdAt?: GetOneAccountWhereQueryParamParameterCreatedAt;
  updatedAt?: GetOneAccountWhereQueryParamParameterUpdatedAt;
  deletedAt?: GetOneAccountWhereQueryParamParameterDeletedAt;
  parentAccount?: GetOneAccountWhereQueryParamParameterParentAccount;
  primaryContact?: GetOneAccountWhereQueryParamParameterPrimaryContact;
  billingContact?: GetOneAccountWhereQueryParamParameterBillingContact;
  childAccounts?: GetOneAccountWhereQueryParamParameterChildAccounts;
  contacts?: GetOneAccountWhereQueryParamParameterContacts;
  salesOrders?: GetOneAccountWhereQueryParamParameterSalesOrders;
  addresses?: GetOneAccountWhereQueryParamParameterAddresses;
  memberships?: GetOneAccountWhereQueryParamParameterMemberships;
};

export type GetOneAccountWhereQueryParamParameterAddressesNone = AddressWhere;

export type GetOneAccountWhereQueryParamParameterAddressesEvery = AddressWhere;

export type GetOneAccountWhereQueryParamParameterAddressesSome = AddressWhere;

export type GetOneAccountWhereQueryParamParameterAddresses = {
  some?: GetOneAccountWhereQueryParamParameterAddressesSome;
  every?: GetOneAccountWhereQueryParamParameterAddressesEvery;
  none?: GetOneAccountWhereQueryParamParameterAddressesNone;
} | null;

export type GetOneAccountWhereQueryParamParameterSalesOrdersNone = SalesOrderWhere;

export type GetOneAccountWhereQueryParamParameterSalesOrdersEvery = SalesOrderWhere;

export type GetOneAccountWhereQueryParamParameterSalesOrdersSome = SalesOrderWhere;

export type GetOneAccountWhereQueryParamParameterSalesOrders = {
  some?: GetOneAccountWhereQueryParamParameterSalesOrdersSome;
  every?: GetOneAccountWhereQueryParamParameterSalesOrdersEvery;
  none?: GetOneAccountWhereQueryParamParameterSalesOrdersNone;
} | null;

export type GetOneAccountWhereQueryParamParameterContactsNone = ContactWhere;

export type GetOneAccountWhereQueryParamParameterContactsEvery = ContactWhere;

export type GetOneAccountWhereQueryParamParameterContactsSome = ContactWhere;

export type GetOneAccountWhereQueryParamParameterContacts = {
  some?: GetOneAccountWhereQueryParamParameterContactsSome;
  every?: GetOneAccountWhereQueryParamParameterContactsEvery;
  none?: GetOneAccountWhereQueryParamParameterContactsNone;
} | null;

export type GetOneAccountWhereQueryParamParameterChildAccountsNone = AccountWhere;

export type GetOneAccountWhereQueryParamParameterChildAccountsEvery = AccountWhere;

export type GetOneAccountWhereQueryParamParameterChildAccountsSome = AccountWhere;

export type GetOneAccountWhereQueryParamParameterChildAccounts = {
  some?: GetOneAccountWhereQueryParamParameterChildAccountsSome;
  every?: GetOneAccountWhereQueryParamParameterChildAccountsEvery;
  none?: GetOneAccountWhereQueryParamParameterChildAccountsNone;
} | null;

export type GetOneAccountWhereQueryParamParameterBillingContactOneOfIsNot = ContactWhere;

export type GetOneAccountWhereQueryParamParameterBillingContactOneOfIs = ContactWhere;

export type GetOneAccountWhereQueryParamParameterBillingContactOneOf = {
  is?: GetOneAccountWhereQueryParamParameterBillingContactOneOfIs;
  isNot?: GetOneAccountWhereQueryParamParameterBillingContactOneOfIsNot;
};

export type GetOneAccountWhereQueryParamParameterBillingContact =
  | ContactWhere
  | GetOneAccountWhereQueryParamParameterBillingContactOneOf;

export type GetOneAccountWhereQueryParamParameterPrimaryContact =
  | ContactWhere
  | GetOneAccountWhereQueryParamParameterPrimaryContactOneOf;

export type GetOneAccountWhereQueryParamParameterPrimaryContactOneOfIsNot = ContactWhere;

export type GetOneAccountWhereQueryParamParameterPrimaryContactOneOfIs = ContactWhere;

export type GetOneAccountWhereQueryParamParameterPrimaryContactOneOf = {
  is?: GetOneAccountWhereQueryParamParameterPrimaryContactOneOfIs;
  isNot?: GetOneAccountWhereQueryParamParameterPrimaryContactOneOfIsNot;
};

export type GetOneAccountWhereQueryParamParameterParentAccount =
  | AccountWhere
  | GetOneAccountWhereQueryParamParameterParentAccountOneOf;

export type GetOneAccountWhereQueryParamParameterParentAccountOneOfIsNot = AccountWhere;

export type GetOneAccountWhereQueryParamParameterParentAccountOneOfIs = AccountWhere;

export type GetOneAccountWhereQueryParamParameterParentAccountOneOf = {
  is?: GetOneAccountWhereQueryParamParameterParentAccountOneOfIs;
  isNot?: GetOneAccountWhereQueryParamParameterParentAccountOneOfIsNot;
};

export type GetOneAccountWhereQueryParamParameterDeletedAt =
  | string
  | GetOneAccountWhereQueryParamParameterDeletedAtOneOf;

export type GetOneAccountWhereQueryParamParameterDeletedAtOneOfNot = string | DeletedAt;

export type GetOneAccountWhereQueryParamParameterDeletedAtOneOfNotIn = string | string[];

export type GetOneAccountWhereQueryParamParameterDeletedAtOneOfIn = string | string[];

export type GetOneAccountWhereQueryParamParameterDeletedAtOneOfEquals = string;

export type GetOneAccountWhereQueryParamParameterDeletedAtOneOf = {
  equals?: GetOneAccountWhereQueryParamParameterDeletedAtOneOfEquals;
  in?: GetOneAccountWhereQueryParamParameterDeletedAtOneOfIn;
  notIn?: GetOneAccountWhereQueryParamParameterDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneAccountWhereQueryParamParameterDeletedAtOneOfNot;
};

export type GetOneAccountWhereQueryParamParameterUpdatedAt =
  | string
  | GetOneAccountWhereQueryParamParameterUpdatedAtOneOf;

export type GetOneAccountWhereQueryParamParameterUpdatedAtOneOfNot = string | UpdatedAt;

export type GetOneAccountWhereQueryParamParameterUpdatedAtOneOfNotIn = string | string[];

export type GetOneAccountWhereQueryParamParameterUpdatedAtOneOfIn = string | string[];

export type GetOneAccountWhereQueryParamParameterUpdatedAtOneOf = {
  equals?: string | null;
  in?: GetOneAccountWhereQueryParamParameterUpdatedAtOneOfIn;
  notIn?: GetOneAccountWhereQueryParamParameterUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneAccountWhereQueryParamParameterUpdatedAtOneOfNot;
};

export type GetOneAccountWhereQueryParamParameterCreatedAtOneOfNot = string | CreatedAt;

export type GetOneAccountWhereQueryParamParameterCreatedAtOneOfNotIn = string | string[];

export type GetOneAccountWhereQueryParamParameterCreatedAtOneOfIn = string | string[];

export type GetOneAccountWhereQueryParamParameterCreatedAtOneOf = {
  equals?: string | null;
  in?: GetOneAccountWhereQueryParamParameterCreatedAtOneOfIn;
  notIn?: GetOneAccountWhereQueryParamParameterCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneAccountWhereQueryParamParameterCreatedAtOneOfNot;
};

export type GetOneAccountWhereQueryParamParameterCreatedAt =
  | string
  | GetOneAccountWhereQueryParamParameterCreatedAtOneOf;

export type GetOneAccountWhereQueryParamParameterBillingContactId =
  | string
  | GetOneAccountWhereQueryParamParameterBillingContactIdOneOf;

export type GetOneAccountWhereQueryParamParameterBillingContactIdOneOfNot = string | BillingContactId;

export type GetOneAccountWhereQueryParamParameterBillingContactIdOneOfMode =
  typeof GetOneAccountWhereQueryParamParameterBillingContactIdOneOfMode[keyof typeof GetOneAccountWhereQueryParamParameterBillingContactIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAccountWhereQueryParamParameterBillingContactIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneAccountWhereQueryParamParameterBillingContactIdOneOfNotIn = string | string[];

export type GetOneAccountWhereQueryParamParameterBillingContactIdOneOfIn = string | string[];

export type GetOneAccountWhereQueryParamParameterBillingContactIdOneOf = {
  equals?: string | null;
  in?: GetOneAccountWhereQueryParamParameterBillingContactIdOneOfIn;
  notIn?: GetOneAccountWhereQueryParamParameterBillingContactIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneAccountWhereQueryParamParameterBillingContactIdOneOfMode;
  not?: GetOneAccountWhereQueryParamParameterBillingContactIdOneOfNot;
};

export type GetOneAccountWhereQueryParamParameterPrimaryContactId =
  | string
  | GetOneAccountWhereQueryParamParameterPrimaryContactIdOneOf;

export type GetOneAccountWhereQueryParamParameterPrimaryContactIdOneOfNot = string | PrimaryContactId;

export type GetOneAccountWhereQueryParamParameterPrimaryContactIdOneOfMode =
  typeof GetOneAccountWhereQueryParamParameterPrimaryContactIdOneOfMode[keyof typeof GetOneAccountWhereQueryParamParameterPrimaryContactIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAccountWhereQueryParamParameterPrimaryContactIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneAccountWhereQueryParamParameterPrimaryContactIdOneOfNotIn = string | string[];

export type GetOneAccountWhereQueryParamParameterPrimaryContactIdOneOfIn = string | string[];

export type GetOneAccountWhereQueryParamParameterPrimaryContactIdOneOf = {
  equals?: string | null;
  in?: GetOneAccountWhereQueryParamParameterPrimaryContactIdOneOfIn;
  notIn?: GetOneAccountWhereQueryParamParameterPrimaryContactIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneAccountWhereQueryParamParameterPrimaryContactIdOneOfMode;
  not?: GetOneAccountWhereQueryParamParameterPrimaryContactIdOneOfNot;
};

export type GetOneAccountWhereQueryParamParameterParentAccountIdOneOfNot = string | ParentAccountId;

export type GetOneAccountWhereQueryParamParameterParentAccountIdOneOfMode =
  typeof GetOneAccountWhereQueryParamParameterParentAccountIdOneOfMode[keyof typeof GetOneAccountWhereQueryParamParameterParentAccountIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAccountWhereQueryParamParameterParentAccountIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneAccountWhereQueryParamParameterParentAccountIdOneOfNotIn = string | string[];

export type GetOneAccountWhereQueryParamParameterParentAccountIdOneOfIn = string | string[];

export type GetOneAccountWhereQueryParamParameterParentAccountIdOneOf = {
  equals?: string | null;
  in?: GetOneAccountWhereQueryParamParameterParentAccountIdOneOfIn;
  notIn?: GetOneAccountWhereQueryParamParameterParentAccountIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneAccountWhereQueryParamParameterParentAccountIdOneOfMode;
  not?: GetOneAccountWhereQueryParamParameterParentAccountIdOneOfNot;
};

export type GetOneAccountWhereQueryParamParameterParentAccountId =
  | string
  | GetOneAccountWhereQueryParamParameterParentAccountIdOneOf;

export type GetOneAccountWhereQueryParamParameterUniversalCustomerId =
  | string
  | GetOneAccountWhereQueryParamParameterUniversalCustomerIdOneOf;

export type GetOneAccountWhereQueryParamParameterUniversalCustomerIdOneOfNot = string | UniversalCustomerId;

export type GetOneAccountWhereQueryParamParameterUniversalCustomerIdOneOfMode =
  typeof GetOneAccountWhereQueryParamParameterUniversalCustomerIdOneOfMode[keyof typeof GetOneAccountWhereQueryParamParameterUniversalCustomerIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAccountWhereQueryParamParameterUniversalCustomerIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneAccountWhereQueryParamParameterUniversalCustomerIdOneOfNotIn = string | string[];

export type GetOneAccountWhereQueryParamParameterUniversalCustomerIdOneOfIn = string | string[];

export type GetOneAccountWhereQueryParamParameterUniversalCustomerIdOneOf = {
  equals?: string | null;
  in?: GetOneAccountWhereQueryParamParameterUniversalCustomerIdOneOfIn;
  notIn?: GetOneAccountWhereQueryParamParameterUniversalCustomerIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneAccountWhereQueryParamParameterUniversalCustomerIdOneOfMode;
  not?: GetOneAccountWhereQueryParamParameterUniversalCustomerIdOneOfNot;
};

export type GetOneAccountWhereQueryParamParameterStripeCustomerRefOneOfNot = string | StripeCustomerRef;

export type GetOneAccountWhereQueryParamParameterStripeCustomerRefOneOfMode =
  typeof GetOneAccountWhereQueryParamParameterStripeCustomerRefOneOfMode[keyof typeof GetOneAccountWhereQueryParamParameterStripeCustomerRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAccountWhereQueryParamParameterStripeCustomerRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneAccountWhereQueryParamParameterStripeCustomerRefOneOfNotIn = string | string[];

export type GetOneAccountWhereQueryParamParameterStripeCustomerRefOneOfIn = string | string[];

export type GetOneAccountWhereQueryParamParameterStripeCustomerRefOneOfEquals = string;

export type GetOneAccountWhereQueryParamParameterStripeCustomerRefOneOf = {
  equals?: GetOneAccountWhereQueryParamParameterStripeCustomerRefOneOfEquals;
  in?: GetOneAccountWhereQueryParamParameterStripeCustomerRefOneOfIn;
  notIn?: GetOneAccountWhereQueryParamParameterStripeCustomerRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneAccountWhereQueryParamParameterStripeCustomerRefOneOfMode;
  not?: GetOneAccountWhereQueryParamParameterStripeCustomerRefOneOfNot;
};

export type GetOneAccountWhereQueryParamParameterStripeCustomerRef =
  | string
  | GetOneAccountWhereQueryParamParameterStripeCustomerRefOneOf;

export type GetOneAccountWhereQueryParamParameterZohoInvContactRef =
  | string
  | GetOneAccountWhereQueryParamParameterZohoInvContactRefOneOf;

export type GetOneAccountWhereQueryParamParameterZohoInvContactRefOneOfNot = string | ZohoInvContactRef;

export type GetOneAccountWhereQueryParamParameterZohoInvContactRefOneOfMode =
  typeof GetOneAccountWhereQueryParamParameterZohoInvContactRefOneOfMode[keyof typeof GetOneAccountWhereQueryParamParameterZohoInvContactRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAccountWhereQueryParamParameterZohoInvContactRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneAccountWhereQueryParamParameterZohoInvContactRefOneOfNotIn = string | string[];

export type GetOneAccountWhereQueryParamParameterZohoInvContactRefOneOfIn = string | string[];

export type GetOneAccountWhereQueryParamParameterZohoInvContactRefOneOfEquals = string;

export type GetOneAccountWhereQueryParamParameterZohoInvContactRefOneOf = {
  equals?: GetOneAccountWhereQueryParamParameterZohoInvContactRefOneOfEquals;
  in?: GetOneAccountWhereQueryParamParameterZohoInvContactRefOneOfIn;
  notIn?: GetOneAccountWhereQueryParamParameterZohoInvContactRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneAccountWhereQueryParamParameterZohoInvContactRefOneOfMode;
  not?: GetOneAccountWhereQueryParamParameterZohoInvContactRefOneOfNot;
};

export type GetOneAccountWhereQueryParamParameterZohoCrmParentAccountRef =
  | string
  | GetOneAccountWhereQueryParamParameterZohoCrmParentAccountRefOneOf;

export type GetOneAccountWhereQueryParamParameterZohoCrmParentAccountRefOneOfNot = string | ZohoCrmParentAccountRef;

export type GetOneAccountWhereQueryParamParameterZohoCrmParentAccountRefOneOfMode =
  typeof GetOneAccountWhereQueryParamParameterZohoCrmParentAccountRefOneOfMode[keyof typeof GetOneAccountWhereQueryParamParameterZohoCrmParentAccountRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAccountWhereQueryParamParameterZohoCrmParentAccountRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneAccountWhereQueryParamParameterZohoCrmParentAccountRefOneOfNotIn = string | string[];

export type GetOneAccountWhereQueryParamParameterZohoCrmParentAccountRefOneOfIn = string | string[];

export type GetOneAccountWhereQueryParamParameterZohoCrmParentAccountRefOneOfEquals = string;

export type GetOneAccountWhereQueryParamParameterZohoCrmParentAccountRefOneOf = {
  equals?: GetOneAccountWhereQueryParamParameterZohoCrmParentAccountRefOneOfEquals;
  in?: GetOneAccountWhereQueryParamParameterZohoCrmParentAccountRefOneOfIn;
  notIn?: GetOneAccountWhereQueryParamParameterZohoCrmParentAccountRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneAccountWhereQueryParamParameterZohoCrmParentAccountRefOneOfMode;
  not?: GetOneAccountWhereQueryParamParameterZohoCrmParentAccountRefOneOfNot;
};

export type GetOneAccountWhereQueryParamParameterZohoCrmAccountRef =
  | string
  | GetOneAccountWhereQueryParamParameterZohoCrmAccountRefOneOf;

export type GetOneAccountWhereQueryParamParameterZohoCrmAccountRefOneOfNot = string | ZohoCrmAccountRef;

export type GetOneAccountWhereQueryParamParameterZohoCrmAccountRefOneOfMode =
  typeof GetOneAccountWhereQueryParamParameterZohoCrmAccountRefOneOfMode[keyof typeof GetOneAccountWhereQueryParamParameterZohoCrmAccountRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAccountWhereQueryParamParameterZohoCrmAccountRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneAccountWhereQueryParamParameterZohoCrmAccountRefOneOfNotIn = string | string[];

export type GetOneAccountWhereQueryParamParameterZohoCrmAccountRefOneOfIn = string | string[];

export type GetOneAccountWhereQueryParamParameterZohoCrmAccountRefOneOfEquals = string;

export type GetOneAccountWhereQueryParamParameterZohoCrmAccountRefOneOf = {
  equals?: GetOneAccountWhereQueryParamParameterZohoCrmAccountRefOneOfEquals;
  in?: GetOneAccountWhereQueryParamParameterZohoCrmAccountRefOneOfIn;
  notIn?: GetOneAccountWhereQueryParamParameterZohoCrmAccountRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneAccountWhereQueryParamParameterZohoCrmAccountRefOneOfMode;
  not?: GetOneAccountWhereQueryParamParameterZohoCrmAccountRefOneOfNot;
};

export type GetOneAccountWhereQueryParamParameterPaymentTermDays =
  | number
  | GetOneAccountWhereQueryParamParameterPaymentTermDaysOneOf;

export type GetOneAccountWhereQueryParamParameterPaymentTermDaysOneOfNot = number | PaymentTermDays;

export type GetOneAccountWhereQueryParamParameterPaymentTermDaysOneOfNotIn = number | number[];

export type GetOneAccountWhereQueryParamParameterPaymentTermDaysOneOfIn = number | number[];

export type GetOneAccountWhereQueryParamParameterPaymentTermDaysOneOfEquals = number;

export type GetOneAccountWhereQueryParamParameterPaymentTermDaysOneOf = {
  equals?: GetOneAccountWhereQueryParamParameterPaymentTermDaysOneOfEquals;
  in?: GetOneAccountWhereQueryParamParameterPaymentTermDaysOneOfIn;
  notIn?: GetOneAccountWhereQueryParamParameterPaymentTermDaysOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: GetOneAccountWhereQueryParamParameterPaymentTermDaysOneOfNot;
};

export type GetOneAccountWhereQueryParamParameterGovBusinessNumberOneOfNot = string | GovBusinessNumber;

export type GetOneAccountWhereQueryParamParameterGovBusinessNumberOneOfMode =
  typeof GetOneAccountWhereQueryParamParameterGovBusinessNumberOneOfMode[keyof typeof GetOneAccountWhereQueryParamParameterGovBusinessNumberOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAccountWhereQueryParamParameterGovBusinessNumberOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneAccountWhereQueryParamParameterGovBusinessNumberOneOfNotIn = string | string[];

export type GetOneAccountWhereQueryParamParameterGovBusinessNumberOneOfIn = string | string[];

export type GetOneAccountWhereQueryParamParameterGovBusinessNumberOneOfEquals = string;

export type GetOneAccountWhereQueryParamParameterGovBusinessNumberOneOf = {
  equals?: GetOneAccountWhereQueryParamParameterGovBusinessNumberOneOfEquals;
  in?: GetOneAccountWhereQueryParamParameterGovBusinessNumberOneOfIn;
  notIn?: GetOneAccountWhereQueryParamParameterGovBusinessNumberOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneAccountWhereQueryParamParameterGovBusinessNumberOneOfMode;
  not?: GetOneAccountWhereQueryParamParameterGovBusinessNumberOneOfNot;
};

export type GetOneAccountWhereQueryParamParameterGovBusinessNumber =
  | string
  | GetOneAccountWhereQueryParamParameterGovBusinessNumberOneOf;

export type GetOneAccountWhereQueryParamParameterCurrency = string | GetOneAccountWhereQueryParamParameterCurrencyOneOf;

export type GetOneAccountWhereQueryParamParameterCurrencyOneOfNot = string | Currency;

export type GetOneAccountWhereQueryParamParameterCurrencyOneOfMode =
  typeof GetOneAccountWhereQueryParamParameterCurrencyOneOfMode[keyof typeof GetOneAccountWhereQueryParamParameterCurrencyOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAccountWhereQueryParamParameterCurrencyOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneAccountWhereQueryParamParameterCurrencyOneOfNotIn = string | string[];

export type GetOneAccountWhereQueryParamParameterCurrencyOneOfIn = string | string[];

export type GetOneAccountWhereQueryParamParameterCurrencyOneOf = {
  equals?: string | null;
  in?: GetOneAccountWhereQueryParamParameterCurrencyOneOfIn;
  notIn?: GetOneAccountWhereQueryParamParameterCurrencyOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneAccountWhereQueryParamParameterCurrencyOneOfMode;
  not?: GetOneAccountWhereQueryParamParameterCurrencyOneOfNot;
};

export type GetOneAccountWhereQueryParamParameterStatusOneOfNot = 'CREATED' | 'ACTIVE' | 'DISABLED' | Status;

export type GetOneAccountWhereQueryParamParameterStatusOneOf = {
  equals?: GetOneAccountWhereQueryParamParameterStatusOneOfEquals;
  in?: GetOneAccountWhereQueryParamParameterStatusOneOfIn;
  notIn?: GetOneAccountWhereQueryParamParameterStatusOneOfNotIn;
  not?: GetOneAccountWhereQueryParamParameterStatusOneOfNot;
} | null;

export type GetOneAccountWhereQueryParamParameterStatus =
  | 'CREATED'
  | 'ACTIVE'
  | 'DISABLED'
  | GetOneAccountWhereQueryParamParameterStatusOneOf;

export type GetOneAccountWhereQueryParamParameterStatusOneOfNotInOneOfItem =
  typeof GetOneAccountWhereQueryParamParameterStatusOneOfNotInOneOfItem[keyof typeof GetOneAccountWhereQueryParamParameterStatusOneOfNotInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAccountWhereQueryParamParameterStatusOneOfNotInOneOfItem = {
  CREATED: 'CREATED',
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type GetOneAccountWhereQueryParamParameterStatusOneOfNotIn =
  | 'CREATED'
  | 'ACTIVE'
  | 'DISABLED'
  | GetOneAccountWhereQueryParamParameterStatusOneOfNotInOneOfItem[];

export type GetOneAccountWhereQueryParamParameterStatusOneOfInOneOfItem =
  typeof GetOneAccountWhereQueryParamParameterStatusOneOfInOneOfItem[keyof typeof GetOneAccountWhereQueryParamParameterStatusOneOfInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAccountWhereQueryParamParameterStatusOneOfInOneOfItem = {
  CREATED: 'CREATED',
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type GetOneAccountWhereQueryParamParameterStatusOneOfIn =
  | 'CREATED'
  | 'ACTIVE'
  | 'DISABLED'
  | GetOneAccountWhereQueryParamParameterStatusOneOfInOneOfItem[];

export type GetOneAccountWhereQueryParamParameterStatusOneOfEquals =
  typeof GetOneAccountWhereQueryParamParameterStatusOneOfEquals[keyof typeof GetOneAccountWhereQueryParamParameterStatusOneOfEquals];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAccountWhereQueryParamParameterStatusOneOfEquals = {
  CREATED: 'CREATED',
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type GetOneAccountWhereQueryParamParameterPhone = string | GetOneAccountWhereQueryParamParameterPhoneOneOf;

export type GetOneAccountWhereQueryParamParameterPhoneOneOfNot = string | Phone;

export type GetOneAccountWhereQueryParamParameterPhoneOneOfMode =
  typeof GetOneAccountWhereQueryParamParameterPhoneOneOfMode[keyof typeof GetOneAccountWhereQueryParamParameterPhoneOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAccountWhereQueryParamParameterPhoneOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneAccountWhereQueryParamParameterPhoneOneOfNotIn = string | string[];

export type GetOneAccountWhereQueryParamParameterPhoneOneOfIn = string | string[];

export type GetOneAccountWhereQueryParamParameterPhoneOneOfEquals = string;

export type GetOneAccountWhereQueryParamParameterPhoneOneOf = {
  equals?: GetOneAccountWhereQueryParamParameterPhoneOneOfEquals;
  in?: GetOneAccountWhereQueryParamParameterPhoneOneOfIn;
  notIn?: GetOneAccountWhereQueryParamParameterPhoneOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneAccountWhereQueryParamParameterPhoneOneOfMode;
  not?: GetOneAccountWhereQueryParamParameterPhoneOneOfNot;
};

export type GetOneAccountWhereQueryParamParameterName = string | GetOneAccountWhereQueryParamParameterNameOneOf;

export type GetOneAccountWhereQueryParamParameterNameOneOfNot = string | Name;

export type GetOneAccountWhereQueryParamParameterNameOneOfMode =
  typeof GetOneAccountWhereQueryParamParameterNameOneOfMode[keyof typeof GetOneAccountWhereQueryParamParameterNameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAccountWhereQueryParamParameterNameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneAccountWhereQueryParamParameterNameOneOfNotIn = string | string[];

export type GetOneAccountWhereQueryParamParameterNameOneOfIn = string | string[];

export type GetOneAccountWhereQueryParamParameterNameOneOf = {
  equals?: string | null;
  in?: GetOneAccountWhereQueryParamParameterNameOneOfIn;
  notIn?: GetOneAccountWhereQueryParamParameterNameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneAccountWhereQueryParamParameterNameOneOfMode;
  not?: GetOneAccountWhereQueryParamParameterNameOneOfNot;
};

export type GetOneAccountWhereQueryParamParameterId = string | GetOneAccountWhereQueryParamParameterIdOneOf;

export type GetOneAccountWhereQueryParamParameterIdOneOfNot = string | Id;

export type GetOneAccountWhereQueryParamParameterIdOneOfMode =
  typeof GetOneAccountWhereQueryParamParameterIdOneOfMode[keyof typeof GetOneAccountWhereQueryParamParameterIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneAccountWhereQueryParamParameterIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneAccountWhereQueryParamParameterIdOneOfNotIn = string | string[];

export type GetOneAccountWhereQueryParamParameterIdOneOfIn = string | string[];

export type GetOneAccountWhereQueryParamParameterIdOneOf = {
  equals?: string | null;
  in?: GetOneAccountWhereQueryParamParameterIdOneOfIn;
  notIn?: GetOneAccountWhereQueryParamParameterIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneAccountWhereQueryParamParameterIdOneOfMode;
  not?: GetOneAccountWhereQueryParamParameterIdOneOfNot;
};

export type GetOneAccountWhereQueryParamParameterNot = AccountWhere | AccountWhere[];

export type GetOneAccountWhereQueryParamParameterOr = AccountWhere | AccountWhere[];

export type GetOneAccountWhereQueryParamParameterAnd = AccountWhere | AccountWhere[];

export type GetOneAccountIncludeQueryParamParameterOneOf = null;

export type GetOneAccountIncludeQueryParamParameter = GetOneAccountIncludeQueryParamParameterOneOf | AccountInclude;

export type GetOneAccountSelectQueryParamParameterOneOf = null;

export type GetOneAccountSelectQueryParamParameter = GetOneAccountSelectQueryParamParameterOneOf | AccountSelect;

/**
 * Query params for get-account-by-id}
 */
export type GetAccountByIdWithTrashedQueryParamParameter = boolean | null;

export type GetAccountByIdIncludeQueryParamParameterOneOf = null;

export type GetAccountByIdIncludeQueryParamParameter = GetAccountByIdIncludeQueryParamParameterOneOf | AccountInclude;

export type GetAccountByIdSelectQueryParamParameterOneOf = null;

export type GetAccountByIdSelectQueryParamParameter = GetAccountByIdSelectQueryParamParameterOneOf | AccountSelect;

/**
 * Query params for get-many-files}
 */
export type GetManyFilesWithTrashedQueryParamParameter = boolean | null;

export type GetManyFilesWhereQueryParamParameterOneOf = null;

export type GetManyFilesWhereQueryParamParameter = GetManyFilesWhereQueryParamParameterOneOf | FileWhere;

export type GetManyFilesIncludeQueryParamParameterOneOf = null;

export type GetManyFilesIncludeQueryParamParameter = GetManyFilesIncludeQueryParamParameterOneOf | FileInclude;

export type GetManyFilesSelectQueryParamParameterOneOf = null;

export type GetManyFilesSelectQueryParamParameter = GetManyFilesSelectQueryParamParameterOneOf | FileSelect;

/**
 * Query params for get-one-file}
 */
export type GetOneFileWithTrashedQueryParamParameter = boolean | null;

export type GetOneFileWhereQueryParamParameterProductId = string | GetOneFileWhereQueryParamParameterProductIdOneOf;

export type GetOneFileWhereQueryParamParameter = {
  AND?: GetOneFileWhereQueryParamParameterAnd;
  OR?: GetOneFileWhereQueryParamParameterOr;
  NOT?: GetOneFileWhereQueryParamParameterNot;
  id?: GetOneFileWhereQueryParamParameterId;
  oringinalFilename?: GetOneFileWhereQueryParamParameterOringinalFilename;
  extension?: GetOneFileWhereQueryParamParameterExtension;
  contentType?: GetOneFileWhereQueryParamParameterContentType;
  height?: GetOneFileWhereQueryParamParameterHeight;
  width?: GetOneFileWhereQueryParamParameterWidth;
  md5?: GetOneFileWhereQueryParamParameterMd5;
  length?: GetOneFileWhereQueryParamParameterLength;
  metadata?: GetOneFileWhereQueryParamParameterMetadata;
  url?: GetOneFileWhereQueryParamParameterUrl;
  createdAt?: GetOneFileWhereQueryParamParameterCreatedAt;
  updatedAt?: GetOneFileWhereQueryParamParameterUpdatedAt;
  deletedAt?: GetOneFileWhereQueryParamParameterDeletedAt;
  product?: GetOneFileWhereQueryParamParameterProduct;
  productId?: GetOneFileWhereQueryParamParameterProductId;
};

export type GetOneFileWhereQueryParamParameterProductIdOneOfNot = string | ProductId;

export type GetOneFileWhereQueryParamParameterProductIdOneOfMode =
  typeof GetOneFileWhereQueryParamParameterProductIdOneOfMode[keyof typeof GetOneFileWhereQueryParamParameterProductIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneFileWhereQueryParamParameterProductIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneFileWhereQueryParamParameterProductIdOneOfNotIn = string | string[];

export type GetOneFileWhereQueryParamParameterProductIdOneOfIn = string | string[];

export type GetOneFileWhereQueryParamParameterProductIdOneOf = {
  equals?: string | null;
  in?: GetOneFileWhereQueryParamParameterProductIdOneOfIn;
  notIn?: GetOneFileWhereQueryParamParameterProductIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneFileWhereQueryParamParameterProductIdOneOfMode;
  not?: GetOneFileWhereQueryParamParameterProductIdOneOfNot;
};

export type GetOneFileWhereQueryParamParameterProductOneOfIsNot = ProductWhere;

export type GetOneFileWhereQueryParamParameterProductOneOfIs = ProductWhere;

export type GetOneFileWhereQueryParamParameterProductOneOf = {
  is?: GetOneFileWhereQueryParamParameterProductOneOfIs;
  isNot?: GetOneFileWhereQueryParamParameterProductOneOfIsNot;
};

export type GetOneFileWhereQueryParamParameterProduct = ProductWhere | GetOneFileWhereQueryParamParameterProductOneOf;

export type GetOneFileWhereQueryParamParameterDeletedAtOneOfNot = string | DeletedAt;

export type GetOneFileWhereQueryParamParameterDeletedAtOneOfNotIn = string | string[];

export type GetOneFileWhereQueryParamParameterDeletedAtOneOfIn = string | string[];

export type GetOneFileWhereQueryParamParameterDeletedAtOneOfEquals = string;

export type GetOneFileWhereQueryParamParameterDeletedAtOneOf = {
  equals?: GetOneFileWhereQueryParamParameterDeletedAtOneOfEquals;
  in?: GetOneFileWhereQueryParamParameterDeletedAtOneOfIn;
  notIn?: GetOneFileWhereQueryParamParameterDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneFileWhereQueryParamParameterDeletedAtOneOfNot;
};

export type GetOneFileWhereQueryParamParameterDeletedAt = string | GetOneFileWhereQueryParamParameterDeletedAtOneOf;

export type GetOneFileWhereQueryParamParameterUpdatedAt = string | GetOneFileWhereQueryParamParameterUpdatedAtOneOf;

export type GetOneFileWhereQueryParamParameterUpdatedAtOneOfNot = string | UpdatedAt;

export type GetOneFileWhereQueryParamParameterUpdatedAtOneOfNotIn = string | string[];

export type GetOneFileWhereQueryParamParameterUpdatedAtOneOfIn = string | string[];

export type GetOneFileWhereQueryParamParameterUpdatedAtOneOf = {
  equals?: string | null;
  in?: GetOneFileWhereQueryParamParameterUpdatedAtOneOfIn;
  notIn?: GetOneFileWhereQueryParamParameterUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneFileWhereQueryParamParameterUpdatedAtOneOfNot;
};

export type GetOneFileWhereQueryParamParameterCreatedAtOneOfNot = string | CreatedAt;

export type GetOneFileWhereQueryParamParameterCreatedAtOneOfNotIn = string | string[];

export type GetOneFileWhereQueryParamParameterCreatedAtOneOfIn = string | string[];

export type GetOneFileWhereQueryParamParameterCreatedAtOneOf = {
  equals?: string | null;
  in?: GetOneFileWhereQueryParamParameterCreatedAtOneOfIn;
  notIn?: GetOneFileWhereQueryParamParameterCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneFileWhereQueryParamParameterCreatedAtOneOfNot;
};

export type GetOneFileWhereQueryParamParameterCreatedAt = string | GetOneFileWhereQueryParamParameterCreatedAtOneOf;

export type GetOneFileWhereQueryParamParameterUrlOneOfNot = string | Url;

export type GetOneFileWhereQueryParamParameterUrlOneOfMode =
  typeof GetOneFileWhereQueryParamParameterUrlOneOfMode[keyof typeof GetOneFileWhereQueryParamParameterUrlOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneFileWhereQueryParamParameterUrlOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneFileWhereQueryParamParameterUrlOneOfNotIn = string | string[];

export type GetOneFileWhereQueryParamParameterUrlOneOfIn = string | string[];

export type GetOneFileWhereQueryParamParameterUrlOneOf = {
  equals?: string | null;
  in?: GetOneFileWhereQueryParamParameterUrlOneOfIn;
  notIn?: GetOneFileWhereQueryParamParameterUrlOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneFileWhereQueryParamParameterUrlOneOfMode;
  not?: GetOneFileWhereQueryParamParameterUrlOneOfNot;
};

export type GetOneFileWhereQueryParamParameterUrl = string | GetOneFileWhereQueryParamParameterUrlOneOf;

export type GetOneFileWhereQueryParamParameterMetadataOneOfPath = string | string[];

export type GetOneFileWhereQueryParamParameterMetadataOneOf = {
  path?: GetOneFileWhereQueryParamParameterMetadataOneOfPath;
  equals?: unknown;
  not?: unknown;
  string_contains?: string | null;
  string_starts_with?: string | null;
  string_ends_with?: string | null;
  array_contains?: unknown;
  array_starts_with?: unknown;
  array_ends_with?: unknown;
};

export type GetOneFileWhereQueryParamParameterMetadata = GetOneFileWhereQueryParamParameterMetadataOneOf;

export type GetOneFileWhereQueryParamParameterLength = number | GetOneFileWhereQueryParamParameterLengthOneOf;

export type GetOneFileWhereQueryParamParameterLengthOneOfNot = number | Length;

export type GetOneFileWhereQueryParamParameterLengthOneOfNotIn = number | number[];

export type GetOneFileWhereQueryParamParameterLengthOneOfIn = number | number[];

export type GetOneFileWhereQueryParamParameterLengthOneOfEquals = number;

export type GetOneFileWhereQueryParamParameterLengthOneOf = {
  equals?: GetOneFileWhereQueryParamParameterLengthOneOfEquals;
  in?: GetOneFileWhereQueryParamParameterLengthOneOfIn;
  notIn?: GetOneFileWhereQueryParamParameterLengthOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: GetOneFileWhereQueryParamParameterLengthOneOfNot;
};

export type GetOneFileWhereQueryParamParameterMd5OneOfNot = string | Md5;

export type GetOneFileWhereQueryParamParameterMd5OneOfMode =
  typeof GetOneFileWhereQueryParamParameterMd5OneOfMode[keyof typeof GetOneFileWhereQueryParamParameterMd5OneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneFileWhereQueryParamParameterMd5OneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneFileWhereQueryParamParameterMd5OneOfNotIn = string | string[];

export type GetOneFileWhereQueryParamParameterMd5OneOfIn = string | string[];

export type GetOneFileWhereQueryParamParameterMd5OneOfEquals = string;

export type GetOneFileWhereQueryParamParameterMd5OneOf = {
  equals?: GetOneFileWhereQueryParamParameterMd5OneOfEquals;
  in?: GetOneFileWhereQueryParamParameterMd5OneOfIn;
  notIn?: GetOneFileWhereQueryParamParameterMd5OneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneFileWhereQueryParamParameterMd5OneOfMode;
  not?: GetOneFileWhereQueryParamParameterMd5OneOfNot;
};

export type GetOneFileWhereQueryParamParameterMd5 = string | GetOneFileWhereQueryParamParameterMd5OneOf;

export type GetOneFileWhereQueryParamParameterWidth = number | GetOneFileWhereQueryParamParameterWidthOneOf;

export type GetOneFileWhereQueryParamParameterWidthOneOfNot = number | Width;

export type GetOneFileWhereQueryParamParameterWidthOneOfNotIn = number | number[];

export type GetOneFileWhereQueryParamParameterWidthOneOfIn = number | number[];

export type GetOneFileWhereQueryParamParameterWidthOneOfEquals = number;

export type GetOneFileWhereQueryParamParameterWidthOneOf = {
  equals?: GetOneFileWhereQueryParamParameterWidthOneOfEquals;
  in?: GetOneFileWhereQueryParamParameterWidthOneOfIn;
  notIn?: GetOneFileWhereQueryParamParameterWidthOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: GetOneFileWhereQueryParamParameterWidthOneOfNot;
};

export type GetOneFileWhereQueryParamParameterHeight = number | GetOneFileWhereQueryParamParameterHeightOneOf;

export type GetOneFileWhereQueryParamParameterHeightOneOfNot = number | Height;

export type GetOneFileWhereQueryParamParameterHeightOneOfNotIn = number | number[];

export type GetOneFileWhereQueryParamParameterHeightOneOfIn = number | number[];

export type GetOneFileWhereQueryParamParameterHeightOneOfEquals = number;

export type GetOneFileWhereQueryParamParameterHeightOneOf = {
  equals?: GetOneFileWhereQueryParamParameterHeightOneOfEquals;
  in?: GetOneFileWhereQueryParamParameterHeightOneOfIn;
  notIn?: GetOneFileWhereQueryParamParameterHeightOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: GetOneFileWhereQueryParamParameterHeightOneOfNot;
};

export type GetOneFileWhereQueryParamParameterContentTypeOneOf = {
  equals?: GetOneFileWhereQueryParamParameterContentTypeOneOfEquals;
  in?: GetOneFileWhereQueryParamParameterContentTypeOneOfIn;
  notIn?: GetOneFileWhereQueryParamParameterContentTypeOneOfNotIn;
  not?: GetOneFileWhereQueryParamParameterContentTypeOneOfNot;
} | null;

export type GetOneFileWhereQueryParamParameterContentType =
  | 'IMAGE'
  | 'VIDEO'
  | GetOneFileWhereQueryParamParameterContentTypeOneOf;

export type GetOneFileWhereQueryParamParameterContentTypeOneOfNot = 'IMAGE' | 'VIDEO' | ContentType;

export type GetOneFileWhereQueryParamParameterContentTypeOneOfNotInOneOfItem =
  typeof GetOneFileWhereQueryParamParameterContentTypeOneOfNotInOneOfItem[keyof typeof GetOneFileWhereQueryParamParameterContentTypeOneOfNotInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneFileWhereQueryParamParameterContentTypeOneOfNotInOneOfItem = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
} as const;

export type GetOneFileWhereQueryParamParameterContentTypeOneOfNotIn =
  | 'IMAGE'
  | 'VIDEO'
  | GetOneFileWhereQueryParamParameterContentTypeOneOfNotInOneOfItem[];

export type GetOneFileWhereQueryParamParameterContentTypeOneOfInOneOfItem =
  typeof GetOneFileWhereQueryParamParameterContentTypeOneOfInOneOfItem[keyof typeof GetOneFileWhereQueryParamParameterContentTypeOneOfInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneFileWhereQueryParamParameterContentTypeOneOfInOneOfItem = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
} as const;

export type GetOneFileWhereQueryParamParameterContentTypeOneOfIn =
  | 'IMAGE'
  | 'VIDEO'
  | GetOneFileWhereQueryParamParameterContentTypeOneOfInOneOfItem[];

export type GetOneFileWhereQueryParamParameterContentTypeOneOfEquals =
  typeof GetOneFileWhereQueryParamParameterContentTypeOneOfEquals[keyof typeof GetOneFileWhereQueryParamParameterContentTypeOneOfEquals];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneFileWhereQueryParamParameterContentTypeOneOfEquals = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
} as const;

export type GetOneFileWhereQueryParamParameterExtension = string | GetOneFileWhereQueryParamParameterExtensionOneOf;

export type GetOneFileWhereQueryParamParameterExtensionOneOfNot = string | Extension;

export type GetOneFileWhereQueryParamParameterExtensionOneOfMode =
  typeof GetOneFileWhereQueryParamParameterExtensionOneOfMode[keyof typeof GetOneFileWhereQueryParamParameterExtensionOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneFileWhereQueryParamParameterExtensionOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneFileWhereQueryParamParameterExtensionOneOfNotIn = string | string[];

export type GetOneFileWhereQueryParamParameterExtensionOneOfIn = string | string[];

export type GetOneFileWhereQueryParamParameterExtensionOneOf = {
  equals?: string | null;
  in?: GetOneFileWhereQueryParamParameterExtensionOneOfIn;
  notIn?: GetOneFileWhereQueryParamParameterExtensionOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneFileWhereQueryParamParameterExtensionOneOfMode;
  not?: GetOneFileWhereQueryParamParameterExtensionOneOfNot;
};

export type GetOneFileWhereQueryParamParameterOringinalFilename =
  | string
  | GetOneFileWhereQueryParamParameterOringinalFilenameOneOf;

export type GetOneFileWhereQueryParamParameterOringinalFilenameOneOfNot = string | OringinalFilename;

export type GetOneFileWhereQueryParamParameterOringinalFilenameOneOfMode =
  typeof GetOneFileWhereQueryParamParameterOringinalFilenameOneOfMode[keyof typeof GetOneFileWhereQueryParamParameterOringinalFilenameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneFileWhereQueryParamParameterOringinalFilenameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneFileWhereQueryParamParameterOringinalFilenameOneOfNotIn = string | string[];

export type GetOneFileWhereQueryParamParameterOringinalFilenameOneOfIn = string | string[];

export type GetOneFileWhereQueryParamParameterOringinalFilenameOneOf = {
  equals?: string | null;
  in?: GetOneFileWhereQueryParamParameterOringinalFilenameOneOfIn;
  notIn?: GetOneFileWhereQueryParamParameterOringinalFilenameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneFileWhereQueryParamParameterOringinalFilenameOneOfMode;
  not?: GetOneFileWhereQueryParamParameterOringinalFilenameOneOfNot;
};

export type GetOneFileWhereQueryParamParameterId = string | GetOneFileWhereQueryParamParameterIdOneOf;

export type GetOneFileWhereQueryParamParameterIdOneOfNot = string | Id;

export type GetOneFileWhereQueryParamParameterIdOneOfMode =
  typeof GetOneFileWhereQueryParamParameterIdOneOfMode[keyof typeof GetOneFileWhereQueryParamParameterIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneFileWhereQueryParamParameterIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneFileWhereQueryParamParameterIdOneOfNotIn = string | string[];

export type GetOneFileWhereQueryParamParameterIdOneOfIn = string | string[];

export type GetOneFileWhereQueryParamParameterIdOneOf = {
  equals?: string | null;
  in?: GetOneFileWhereQueryParamParameterIdOneOfIn;
  notIn?: GetOneFileWhereQueryParamParameterIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneFileWhereQueryParamParameterIdOneOfMode;
  not?: GetOneFileWhereQueryParamParameterIdOneOfNot;
};

export type GetOneFileWhereQueryParamParameterNot = FileWhere | FileWhere[];

export type GetOneFileWhereQueryParamParameterOr = FileWhere | FileWhere[];

export type GetOneFileWhereQueryParamParameterAnd = FileWhere | FileWhere[];

export type GetOneFileIncludeQueryParamParameterOneOf = null;

export type GetOneFileIncludeQueryParamParameter = GetOneFileIncludeQueryParamParameterOneOf | FileInclude;

export type GetOneFileSelectQueryParamParameterOneOf = null;

export type GetOneFileSelectQueryParamParameter = GetOneFileSelectQueryParamParameterOneOf | FileSelect;

/**
 * Query params for get-file-by-id}
 */
export type GetFileByIdWithTrashedQueryParamParameter = boolean | null;

export type GetFileByIdIncludeQueryParamParameterOneOf = null;

export type GetFileByIdIncludeQueryParamParameter = GetFileByIdIncludeQueryParamParameterOneOf | FileInclude;

export type GetFileByIdSelectQueryParamParameterOneOf = null;

export type GetFileByIdSelectQueryParamParameter = GetFileByIdSelectQueryParamParameterOneOf | FileSelect;

/**
 * Query params for get-many-shipment-runs}
 */
export type GetManyShipmentRunsWithTrashedQueryParamParameter = boolean | null;

export type GetManyShipmentRunsWhereQueryParamParameterOneOf = null;

export type GetManyShipmentRunsWhereQueryParamParameter =
  | GetManyShipmentRunsWhereQueryParamParameterOneOf
  | ShipmentRunWhere;

export type GetManyShipmentRunsIncludeQueryParamParameterOneOf = null;

export type GetManyShipmentRunsIncludeQueryParamParameter =
  | GetManyShipmentRunsIncludeQueryParamParameterOneOf
  | ShipmentRunInclude;

export type GetManyShipmentRunsSelectQueryParamParameterOneOf = null;

export type GetManyShipmentRunsSelectQueryParamParameter =
  | GetManyShipmentRunsSelectQueryParamParameterOneOf
  | ShipmentRunSelect;

/**
 * Query params for get-one-shipment-run}
 */
export type GetOneShipmentRunWithTrashedQueryParamParameter = boolean | null;

export type GetOneShipmentRunWhereQueryParamParameter = {
  AND?: GetOneShipmentRunWhereQueryParamParameterAnd;
  OR?: GetOneShipmentRunWhereQueryParamParameterOr;
  NOT?: GetOneShipmentRunWhereQueryParamParameterNot;
  id?: GetOneShipmentRunWhereQueryParamParameterId;
  name?: GetOneShipmentRunWhereQueryParamParameterName;
  initialOrdersSubmitted?: GetOneShipmentRunWhereQueryParamParameterInitialOrdersSubmitted;
  createdBySub?: GetOneShipmentRunWhereQueryParamParameterCreatedBySub;
  stepFunctionExecRef?: GetOneShipmentRunWhereQueryParamParameterStepFunctionExecRef;
  createdAt?: GetOneShipmentRunWhereQueryParamParameterCreatedAt;
  updatedAt?: GetOneShipmentRunWhereQueryParamParameterUpdatedAt;
  deletedAt?: GetOneShipmentRunWhereQueryParamParameterDeletedAt;
  packagesPrintedAt?: GetOneShipmentRunWhereQueryParamParameterPackagesPrintedAt;
  reportsGeneratedAt?: GetOneShipmentRunWhereQueryParamParameterReportsGeneratedAt;
  completedAt?: GetOneShipmentRunWhereQueryParamParameterCompletedAt;
  salesOrders?: GetOneShipmentRunWhereQueryParamParameterSalesOrders;
};

export type GetOneShipmentRunWhereQueryParamParameterSalesOrdersNone = SalesOrderWhere;

export type GetOneShipmentRunWhereQueryParamParameterSalesOrdersEvery = SalesOrderWhere;

export type GetOneShipmentRunWhereQueryParamParameterSalesOrdersSome = SalesOrderWhere;

export type GetOneShipmentRunWhereQueryParamParameterSalesOrders = {
  some?: GetOneShipmentRunWhereQueryParamParameterSalesOrdersSome;
  every?: GetOneShipmentRunWhereQueryParamParameterSalesOrdersEvery;
  none?: GetOneShipmentRunWhereQueryParamParameterSalesOrdersNone;
} | null;

export type GetOneShipmentRunWhereQueryParamParameterCompletedAtOneOfNot = string | CompletedAt;

export type GetOneShipmentRunWhereQueryParamParameterCompletedAtOneOfNotIn = string | string[];

export type GetOneShipmentRunWhereQueryParamParameterCompletedAtOneOfIn = string | string[];

export type GetOneShipmentRunWhereQueryParamParameterCompletedAtOneOfEquals = string;

export type GetOneShipmentRunWhereQueryParamParameterCompletedAtOneOf = {
  equals?: GetOneShipmentRunWhereQueryParamParameterCompletedAtOneOfEquals;
  in?: GetOneShipmentRunWhereQueryParamParameterCompletedAtOneOfIn;
  notIn?: GetOneShipmentRunWhereQueryParamParameterCompletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneShipmentRunWhereQueryParamParameterCompletedAtOneOfNot;
};

export type GetOneShipmentRunWhereQueryParamParameterCompletedAt =
  | string
  | GetOneShipmentRunWhereQueryParamParameterCompletedAtOneOf;

export type GetOneShipmentRunWhereQueryParamParameterReportsGeneratedAt =
  | string
  | GetOneShipmentRunWhereQueryParamParameterReportsGeneratedAtOneOf;

export type GetOneShipmentRunWhereQueryParamParameterReportsGeneratedAtOneOfNot = string | ReportsGeneratedAt;

export type GetOneShipmentRunWhereQueryParamParameterReportsGeneratedAtOneOfNotIn = string | string[];

export type GetOneShipmentRunWhereQueryParamParameterReportsGeneratedAtOneOfIn = string | string[];

export type GetOneShipmentRunWhereQueryParamParameterReportsGeneratedAtOneOfEquals = string;

export type GetOneShipmentRunWhereQueryParamParameterReportsGeneratedAtOneOf = {
  equals?: GetOneShipmentRunWhereQueryParamParameterReportsGeneratedAtOneOfEquals;
  in?: GetOneShipmentRunWhereQueryParamParameterReportsGeneratedAtOneOfIn;
  notIn?: GetOneShipmentRunWhereQueryParamParameterReportsGeneratedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneShipmentRunWhereQueryParamParameterReportsGeneratedAtOneOfNot;
};

export type GetOneShipmentRunWhereQueryParamParameterPackagesPrintedAt =
  | string
  | GetOneShipmentRunWhereQueryParamParameterPackagesPrintedAtOneOf;

export type GetOneShipmentRunWhereQueryParamParameterPackagesPrintedAtOneOfNot = string | PackagesPrintedAt;

export type GetOneShipmentRunWhereQueryParamParameterPackagesPrintedAtOneOfNotIn = string | string[];

export type GetOneShipmentRunWhereQueryParamParameterPackagesPrintedAtOneOfIn = string | string[];

export type GetOneShipmentRunWhereQueryParamParameterPackagesPrintedAtOneOfEquals = string;

export type GetOneShipmentRunWhereQueryParamParameterPackagesPrintedAtOneOf = {
  equals?: GetOneShipmentRunWhereQueryParamParameterPackagesPrintedAtOneOfEquals;
  in?: GetOneShipmentRunWhereQueryParamParameterPackagesPrintedAtOneOfIn;
  notIn?: GetOneShipmentRunWhereQueryParamParameterPackagesPrintedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneShipmentRunWhereQueryParamParameterPackagesPrintedAtOneOfNot;
};

export type GetOneShipmentRunWhereQueryParamParameterDeletedAt =
  | string
  | GetOneShipmentRunWhereQueryParamParameterDeletedAtOneOf;

export type GetOneShipmentRunWhereQueryParamParameterDeletedAtOneOfNot = string | DeletedAt;

export type GetOneShipmentRunWhereQueryParamParameterDeletedAtOneOfNotIn = string | string[];

export type GetOneShipmentRunWhereQueryParamParameterDeletedAtOneOfIn = string | string[];

export type GetOneShipmentRunWhereQueryParamParameterDeletedAtOneOfEquals = string;

export type GetOneShipmentRunWhereQueryParamParameterDeletedAtOneOf = {
  equals?: GetOneShipmentRunWhereQueryParamParameterDeletedAtOneOfEquals;
  in?: GetOneShipmentRunWhereQueryParamParameterDeletedAtOneOfIn;
  notIn?: GetOneShipmentRunWhereQueryParamParameterDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneShipmentRunWhereQueryParamParameterDeletedAtOneOfNot;
};

export type GetOneShipmentRunWhereQueryParamParameterUpdatedAtOneOfNot = string | UpdatedAt;

export type GetOneShipmentRunWhereQueryParamParameterUpdatedAtOneOfNotIn = string | string[];

export type GetOneShipmentRunWhereQueryParamParameterUpdatedAtOneOfIn = string | string[];

export type GetOneShipmentRunWhereQueryParamParameterUpdatedAtOneOf = {
  equals?: string | null;
  in?: GetOneShipmentRunWhereQueryParamParameterUpdatedAtOneOfIn;
  notIn?: GetOneShipmentRunWhereQueryParamParameterUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneShipmentRunWhereQueryParamParameterUpdatedAtOneOfNot;
};

export type GetOneShipmentRunWhereQueryParamParameterUpdatedAt =
  | string
  | GetOneShipmentRunWhereQueryParamParameterUpdatedAtOneOf;

export type GetOneShipmentRunWhereQueryParamParameterCreatedAt =
  | string
  | GetOneShipmentRunWhereQueryParamParameterCreatedAtOneOf;

export type GetOneShipmentRunWhereQueryParamParameterCreatedAtOneOfNot = string | CreatedAt;

export type GetOneShipmentRunWhereQueryParamParameterCreatedAtOneOfNotIn = string | string[];

export type GetOneShipmentRunWhereQueryParamParameterCreatedAtOneOfIn = string | string[];

export type GetOneShipmentRunWhereQueryParamParameterCreatedAtOneOf = {
  equals?: string | null;
  in?: GetOneShipmentRunWhereQueryParamParameterCreatedAtOneOfIn;
  notIn?: GetOneShipmentRunWhereQueryParamParameterCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneShipmentRunWhereQueryParamParameterCreatedAtOneOfNot;
};

export type GetOneShipmentRunWhereQueryParamParameterStepFunctionExecRef =
  | string
  | GetOneShipmentRunWhereQueryParamParameterStepFunctionExecRefOneOf;

export type GetOneShipmentRunWhereQueryParamParameterStepFunctionExecRefOneOfNot = string | StepFunctionExecRef;

export type GetOneShipmentRunWhereQueryParamParameterStepFunctionExecRefOneOfMode =
  typeof GetOneShipmentRunWhereQueryParamParameterStepFunctionExecRefOneOfMode[keyof typeof GetOneShipmentRunWhereQueryParamParameterStepFunctionExecRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneShipmentRunWhereQueryParamParameterStepFunctionExecRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneShipmentRunWhereQueryParamParameterStepFunctionExecRefOneOfNotIn = string | string[];

export type GetOneShipmentRunWhereQueryParamParameterStepFunctionExecRefOneOfIn = string | string[];

export type GetOneShipmentRunWhereQueryParamParameterStepFunctionExecRefOneOfEquals = string;

export type GetOneShipmentRunWhereQueryParamParameterStepFunctionExecRefOneOf = {
  equals?: GetOneShipmentRunWhereQueryParamParameterStepFunctionExecRefOneOfEquals;
  in?: GetOneShipmentRunWhereQueryParamParameterStepFunctionExecRefOneOfIn;
  notIn?: GetOneShipmentRunWhereQueryParamParameterStepFunctionExecRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneShipmentRunWhereQueryParamParameterStepFunctionExecRefOneOfMode;
  not?: GetOneShipmentRunWhereQueryParamParameterStepFunctionExecRefOneOfNot;
};

export type GetOneShipmentRunWhereQueryParamParameterCreatedBySub =
  | string
  | GetOneShipmentRunWhereQueryParamParameterCreatedBySubOneOf;

export type GetOneShipmentRunWhereQueryParamParameterCreatedBySubOneOfNot = string | CreatedBySub;

export type GetOneShipmentRunWhereQueryParamParameterCreatedBySubOneOfMode =
  typeof GetOneShipmentRunWhereQueryParamParameterCreatedBySubOneOfMode[keyof typeof GetOneShipmentRunWhereQueryParamParameterCreatedBySubOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneShipmentRunWhereQueryParamParameterCreatedBySubOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneShipmentRunWhereQueryParamParameterCreatedBySubOneOfNotIn = string | string[];

export type GetOneShipmentRunWhereQueryParamParameterCreatedBySubOneOfIn = string | string[];

export type GetOneShipmentRunWhereQueryParamParameterCreatedBySubOneOf = {
  equals?: string | null;
  in?: GetOneShipmentRunWhereQueryParamParameterCreatedBySubOneOfIn;
  notIn?: GetOneShipmentRunWhereQueryParamParameterCreatedBySubOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneShipmentRunWhereQueryParamParameterCreatedBySubOneOfMode;
  not?: GetOneShipmentRunWhereQueryParamParameterCreatedBySubOneOfNot;
};

export type GetOneShipmentRunWhereQueryParamParameterInitialOrdersSubmittedOneOfPath = string | string[];

export type GetOneShipmentRunWhereQueryParamParameterInitialOrdersSubmittedOneOf = {
  path?: GetOneShipmentRunWhereQueryParamParameterInitialOrdersSubmittedOneOfPath;
  equals?: unknown;
  not?: unknown;
  string_contains?: string | null;
  string_starts_with?: string | null;
  string_ends_with?: string | null;
  array_contains?: unknown;
  array_starts_with?: unknown;
  array_ends_with?: unknown;
};

export type GetOneShipmentRunWhereQueryParamParameterInitialOrdersSubmitted =
  GetOneShipmentRunWhereQueryParamParameterInitialOrdersSubmittedOneOf;

export type GetOneShipmentRunWhereQueryParamParameterNameOneOfNot = string | Name;

export type GetOneShipmentRunWhereQueryParamParameterNameOneOfMode =
  typeof GetOneShipmentRunWhereQueryParamParameterNameOneOfMode[keyof typeof GetOneShipmentRunWhereQueryParamParameterNameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneShipmentRunWhereQueryParamParameterNameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneShipmentRunWhereQueryParamParameterNameOneOfNotIn = string | string[];

export type GetOneShipmentRunWhereQueryParamParameterNameOneOfIn = string | string[];

export type GetOneShipmentRunWhereQueryParamParameterNameOneOf = {
  equals?: string | null;
  in?: GetOneShipmentRunWhereQueryParamParameterNameOneOfIn;
  notIn?: GetOneShipmentRunWhereQueryParamParameterNameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneShipmentRunWhereQueryParamParameterNameOneOfMode;
  not?: GetOneShipmentRunWhereQueryParamParameterNameOneOfNot;
};

export type GetOneShipmentRunWhereQueryParamParameterName = string | GetOneShipmentRunWhereQueryParamParameterNameOneOf;

export type GetOneShipmentRunWhereQueryParamParameterId = string | GetOneShipmentRunWhereQueryParamParameterIdOneOf;

export type GetOneShipmentRunWhereQueryParamParameterIdOneOfNot = string | Id;

export type GetOneShipmentRunWhereQueryParamParameterIdOneOfMode =
  typeof GetOneShipmentRunWhereQueryParamParameterIdOneOfMode[keyof typeof GetOneShipmentRunWhereQueryParamParameterIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneShipmentRunWhereQueryParamParameterIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneShipmentRunWhereQueryParamParameterIdOneOfNotIn = string | string[];

export type GetOneShipmentRunWhereQueryParamParameterIdOneOfIn = string | string[];

export type GetOneShipmentRunWhereQueryParamParameterIdOneOf = {
  equals?: string | null;
  in?: GetOneShipmentRunWhereQueryParamParameterIdOneOfIn;
  notIn?: GetOneShipmentRunWhereQueryParamParameterIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneShipmentRunWhereQueryParamParameterIdOneOfMode;
  not?: GetOneShipmentRunWhereQueryParamParameterIdOneOfNot;
};

export type GetOneShipmentRunWhereQueryParamParameterNot = ShipmentRunWhere | ShipmentRunWhere[];

export type GetOneShipmentRunWhereQueryParamParameterOr = ShipmentRunWhere | ShipmentRunWhere[];

export type GetOneShipmentRunWhereQueryParamParameterAnd = ShipmentRunWhere | ShipmentRunWhere[];

export type GetOneShipmentRunIncludeQueryParamParameterOneOf = null;

export type GetOneShipmentRunIncludeQueryParamParameter =
  | GetOneShipmentRunIncludeQueryParamParameterOneOf
  | ShipmentRunInclude;

export type GetOneShipmentRunSelectQueryParamParameterOneOf = null;

export type GetOneShipmentRunSelectQueryParamParameter =
  | GetOneShipmentRunSelectQueryParamParameterOneOf
  | ShipmentRunSelect;

/**
 * Query params for get-shipment-run-by-id}
 */
export type GetShipmentRunByIdWithTrashedQueryParamParameter = boolean | null;

export type GetShipmentRunByIdIncludeQueryParamParameterOneOf = null;

export type GetShipmentRunByIdIncludeQueryParamParameter =
  | GetShipmentRunByIdIncludeQueryParamParameterOneOf
  | ShipmentRunInclude;

export type GetShipmentRunByIdSelectQueryParamParameterOneOf = null;

export type GetShipmentRunByIdSelectQueryParamParameter =
  | GetShipmentRunByIdSelectQueryParamParameterOneOf
  | ShipmentRunSelect;

/**
 * Query params for get-many-sales-order-line-items}
 */
export type GetManySalesOrderLineItemsWithTrashedQueryParamParameter = boolean | null;

export type GetManySalesOrderLineItemsWhereQueryParamParameterOneOf = null;

export type GetManySalesOrderLineItemsWhereQueryParamParameter =
  | GetManySalesOrderLineItemsWhereQueryParamParameterOneOf
  | SalesOrderLineItemWhere;

export type GetManySalesOrderLineItemsIncludeQueryParamParameterOneOf = null;

export type GetManySalesOrderLineItemsIncludeQueryParamParameter =
  | GetManySalesOrderLineItemsIncludeQueryParamParameterOneOf
  | SalesOrderLineItemInclude;

export type GetManySalesOrderLineItemsSelectQueryParamParameterOneOf = null;

export type GetManySalesOrderLineItemsSelectQueryParamParameter =
  | GetManySalesOrderLineItemsSelectQueryParamParameterOneOf
  | SalesOrderLineItemSelect;

/**
 * Query params for get-one-sales-order-line-item}
 */
export type GetOneSalesOrderLineItemWithTrashedQueryParamParameter = boolean | null;

export type GetOneSalesOrderLineItemWhereQueryParamParameterItem =
  | ItemWhere
  | GetOneSalesOrderLineItemWhereQueryParamParameterItemOneOf;

export type GetOneSalesOrderLineItemWhereQueryParamParameter = {
  AND?: GetOneSalesOrderLineItemWhereQueryParamParameterAnd;
  OR?: GetOneSalesOrderLineItemWhereQueryParamParameterOr;
  NOT?: GetOneSalesOrderLineItemWhereQueryParamParameterNot;
  id?: GetOneSalesOrderLineItemWhereQueryParamParameterId;
  name?: GetOneSalesOrderLineItemWhereQueryParamParameterName;
  type?: GetOneSalesOrderLineItemWhereQueryParamParameterType;
  amountEx?: GetOneSalesOrderLineItemWhereQueryParamParameterAmountEx;
  amountGst?: GetOneSalesOrderLineItemWhereQueryParamParameterAmountGst;
  salesOrderId?: GetOneSalesOrderLineItemWhereQueryParamParameterSalesOrderId;
  itemId?: GetOneSalesOrderLineItemWhereQueryParamParameterItemId;
  quantity?: GetOneSalesOrderLineItemWhereQueryParamParameterQuantity;
  itemData?: GetOneSalesOrderLineItemWhereQueryParamParameterItemData;
  createdAt?: GetOneSalesOrderLineItemWhereQueryParamParameterCreatedAt;
  updatedAt?: GetOneSalesOrderLineItemWhereQueryParamParameterUpdatedAt;
  deletedAt?: GetOneSalesOrderLineItemWhereQueryParamParameterDeletedAt;
  salesOrder?: GetOneSalesOrderLineItemWhereQueryParamParameterSalesOrder;
  item?: GetOneSalesOrderLineItemWhereQueryParamParameterItem;
};

export type GetOneSalesOrderLineItemWhereQueryParamParameterItemOneOfIsNot = ItemWhere;

export type GetOneSalesOrderLineItemWhereQueryParamParameterItemOneOfIs = ItemWhere;

export type GetOneSalesOrderLineItemWhereQueryParamParameterItemOneOf = {
  is?: GetOneSalesOrderLineItemWhereQueryParamParameterItemOneOfIs;
  isNot?: GetOneSalesOrderLineItemWhereQueryParamParameterItemOneOfIsNot;
};

export type GetOneSalesOrderLineItemWhereQueryParamParameterSalesOrderOneOfIsNot = SalesOrderWhere;

export type GetOneSalesOrderLineItemWhereQueryParamParameterSalesOrderOneOfIs = SalesOrderWhere;

export type GetOneSalesOrderLineItemWhereQueryParamParameterSalesOrderOneOf = {
  is?: GetOneSalesOrderLineItemWhereQueryParamParameterSalesOrderOneOfIs;
  isNot?: GetOneSalesOrderLineItemWhereQueryParamParameterSalesOrderOneOfIsNot;
};

export type GetOneSalesOrderLineItemWhereQueryParamParameterSalesOrder =
  | SalesOrderWhere
  | GetOneSalesOrderLineItemWhereQueryParamParameterSalesOrderOneOf;

export type GetOneSalesOrderLineItemWhereQueryParamParameterDeletedAt =
  | string
  | GetOneSalesOrderLineItemWhereQueryParamParameterDeletedAtOneOf;

export type GetOneSalesOrderLineItemWhereQueryParamParameterDeletedAtOneOfNot = string | DeletedAt;

export type GetOneSalesOrderLineItemWhereQueryParamParameterDeletedAtOneOfNotIn = string | string[];

export type GetOneSalesOrderLineItemWhereQueryParamParameterDeletedAtOneOfIn = string | string[];

export type GetOneSalesOrderLineItemWhereQueryParamParameterDeletedAtOneOfEquals = string;

export type GetOneSalesOrderLineItemWhereQueryParamParameterDeletedAtOneOf = {
  equals?: GetOneSalesOrderLineItemWhereQueryParamParameterDeletedAtOneOfEquals;
  in?: GetOneSalesOrderLineItemWhereQueryParamParameterDeletedAtOneOfIn;
  notIn?: GetOneSalesOrderLineItemWhereQueryParamParameterDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneSalesOrderLineItemWhereQueryParamParameterDeletedAtOneOfNot;
};

export type GetOneSalesOrderLineItemWhereQueryParamParameterUpdatedAt =
  | string
  | GetOneSalesOrderLineItemWhereQueryParamParameterUpdatedAtOneOf;

export type GetOneSalesOrderLineItemWhereQueryParamParameterUpdatedAtOneOfNot = string | UpdatedAt;

export type GetOneSalesOrderLineItemWhereQueryParamParameterUpdatedAtOneOfNotIn = string | string[];

export type GetOneSalesOrderLineItemWhereQueryParamParameterUpdatedAtOneOfIn = string | string[];

export type GetOneSalesOrderLineItemWhereQueryParamParameterUpdatedAtOneOf = {
  equals?: string | null;
  in?: GetOneSalesOrderLineItemWhereQueryParamParameterUpdatedAtOneOfIn;
  notIn?: GetOneSalesOrderLineItemWhereQueryParamParameterUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneSalesOrderLineItemWhereQueryParamParameterUpdatedAtOneOfNot;
};

export type GetOneSalesOrderLineItemWhereQueryParamParameterCreatedAt =
  | string
  | GetOneSalesOrderLineItemWhereQueryParamParameterCreatedAtOneOf;

export type GetOneSalesOrderLineItemWhereQueryParamParameterCreatedAtOneOfNot = string | CreatedAt;

export type GetOneSalesOrderLineItemWhereQueryParamParameterCreatedAtOneOfNotIn = string | string[];

export type GetOneSalesOrderLineItemWhereQueryParamParameterCreatedAtOneOfIn = string | string[];

export type GetOneSalesOrderLineItemWhereQueryParamParameterCreatedAtOneOf = {
  equals?: string | null;
  in?: GetOneSalesOrderLineItemWhereQueryParamParameterCreatedAtOneOfIn;
  notIn?: GetOneSalesOrderLineItemWhereQueryParamParameterCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneSalesOrderLineItemWhereQueryParamParameterCreatedAtOneOfNot;
};

export type GetOneSalesOrderLineItemWhereQueryParamParameterItemDataOneOfPath = string | string[];

export type GetOneSalesOrderLineItemWhereQueryParamParameterItemDataOneOf = {
  path?: GetOneSalesOrderLineItemWhereQueryParamParameterItemDataOneOfPath;
  equals?: unknown;
  not?: unknown;
  string_contains?: string | null;
  string_starts_with?: string | null;
  string_ends_with?: string | null;
  array_contains?: unknown;
  array_starts_with?: unknown;
  array_ends_with?: unknown;
};

export type GetOneSalesOrderLineItemWhereQueryParamParameterItemData =
  GetOneSalesOrderLineItemWhereQueryParamParameterItemDataOneOf;

export type GetOneSalesOrderLineItemWhereQueryParamParameterQuantity =
  | number
  | GetOneSalesOrderLineItemWhereQueryParamParameterQuantityOneOf;

export type GetOneSalesOrderLineItemWhereQueryParamParameterQuantityOneOfNotOneOf = null;

export type GetOneSalesOrderLineItemWhereQueryParamParameterQuantityOneOfNot =
  | GetOneSalesOrderLineItemWhereQueryParamParameterQuantityOneOfNotOneOf
  | number
  | Quantity;

export type GetOneSalesOrderLineItemWhereQueryParamParameterQuantityOneOfNotIn = number | number[];

export type GetOneSalesOrderLineItemWhereQueryParamParameterQuantityOneOfIn = number | number[];

export type GetOneSalesOrderLineItemWhereQueryParamParameterQuantityOneOf = {
  equals?: number | null;
  in?: GetOneSalesOrderLineItemWhereQueryParamParameterQuantityOneOfIn;
  notIn?: GetOneSalesOrderLineItemWhereQueryParamParameterQuantityOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: GetOneSalesOrderLineItemWhereQueryParamParameterQuantityOneOfNot;
};

export type GetOneSalesOrderLineItemWhereQueryParamParameterItemId =
  | string
  | GetOneSalesOrderLineItemWhereQueryParamParameterItemIdOneOf;

export type GetOneSalesOrderLineItemWhereQueryParamParameterItemIdOneOfNot = string | ItemId;

export type GetOneSalesOrderLineItemWhereQueryParamParameterItemIdOneOfMode =
  typeof GetOneSalesOrderLineItemWhereQueryParamParameterItemIdOneOfMode[keyof typeof GetOneSalesOrderLineItemWhereQueryParamParameterItemIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneSalesOrderLineItemWhereQueryParamParameterItemIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneSalesOrderLineItemWhereQueryParamParameterItemIdOneOfNotIn = string | string[];

export type GetOneSalesOrderLineItemWhereQueryParamParameterItemIdOneOfIn = string | string[];

export type GetOneSalesOrderLineItemWhereQueryParamParameterItemIdOneOf = {
  equals?: string | null;
  in?: GetOneSalesOrderLineItemWhereQueryParamParameterItemIdOneOfIn;
  notIn?: GetOneSalesOrderLineItemWhereQueryParamParameterItemIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneSalesOrderLineItemWhereQueryParamParameterItemIdOneOfMode;
  not?: GetOneSalesOrderLineItemWhereQueryParamParameterItemIdOneOfNot;
};

export type GetOneSalesOrderLineItemWhereQueryParamParameterSalesOrderIdOneOfNot = string | SalesOrderId;

export type GetOneSalesOrderLineItemWhereQueryParamParameterSalesOrderIdOneOfMode =
  typeof GetOneSalesOrderLineItemWhereQueryParamParameterSalesOrderIdOneOfMode[keyof typeof GetOneSalesOrderLineItemWhereQueryParamParameterSalesOrderIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneSalesOrderLineItemWhereQueryParamParameterSalesOrderIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneSalesOrderLineItemWhereQueryParamParameterSalesOrderIdOneOfNotIn = string | string[];

export type GetOneSalesOrderLineItemWhereQueryParamParameterSalesOrderIdOneOfIn = string | string[];

export type GetOneSalesOrderLineItemWhereQueryParamParameterSalesOrderIdOneOf = {
  equals?: string | null;
  in?: GetOneSalesOrderLineItemWhereQueryParamParameterSalesOrderIdOneOfIn;
  notIn?: GetOneSalesOrderLineItemWhereQueryParamParameterSalesOrderIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneSalesOrderLineItemWhereQueryParamParameterSalesOrderIdOneOfMode;
  not?: GetOneSalesOrderLineItemWhereQueryParamParameterSalesOrderIdOneOfNot;
};

export type GetOneSalesOrderLineItemWhereQueryParamParameterSalesOrderId =
  | string
  | GetOneSalesOrderLineItemWhereQueryParamParameterSalesOrderIdOneOf;

export type GetOneSalesOrderLineItemWhereQueryParamParameterAmountGst =
  | number
  | GetOneSalesOrderLineItemWhereQueryParamParameterAmountGstOneOf;

export type GetOneSalesOrderLineItemWhereQueryParamParameterAmountGstOneOfNotOneOf = null;

export type GetOneSalesOrderLineItemWhereQueryParamParameterAmountGstOneOfNot =
  | GetOneSalesOrderLineItemWhereQueryParamParameterAmountGstOneOfNotOneOf
  | number
  | AmountGst;

export type GetOneSalesOrderLineItemWhereQueryParamParameterAmountGstOneOfNotIn = number | number[];

export type GetOneSalesOrderLineItemWhereQueryParamParameterAmountGstOneOfIn = number | number[];

export type GetOneSalesOrderLineItemWhereQueryParamParameterAmountGstOneOf = {
  equals?: number | null;
  in?: GetOneSalesOrderLineItemWhereQueryParamParameterAmountGstOneOfIn;
  notIn?: GetOneSalesOrderLineItemWhereQueryParamParameterAmountGstOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: GetOneSalesOrderLineItemWhereQueryParamParameterAmountGstOneOfNot;
};

export type GetOneSalesOrderLineItemWhereQueryParamParameterAmountEx =
  | number
  | GetOneSalesOrderLineItemWhereQueryParamParameterAmountExOneOf;

export type GetOneSalesOrderLineItemWhereQueryParamParameterAmountExOneOfNotOneOf = null;

export type GetOneSalesOrderLineItemWhereQueryParamParameterAmountExOneOfNot =
  | GetOneSalesOrderLineItemWhereQueryParamParameterAmountExOneOfNotOneOf
  | number
  | AmountEx;

export type GetOneSalesOrderLineItemWhereQueryParamParameterAmountExOneOfNotIn = number | number[];

export type GetOneSalesOrderLineItemWhereQueryParamParameterAmountExOneOfIn = number | number[];

export type GetOneSalesOrderLineItemWhereQueryParamParameterAmountExOneOf = {
  equals?: number | null;
  in?: GetOneSalesOrderLineItemWhereQueryParamParameterAmountExOneOfIn;
  notIn?: GetOneSalesOrderLineItemWhereQueryParamParameterAmountExOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: GetOneSalesOrderLineItemWhereQueryParamParameterAmountExOneOfNot;
};

export type GetOneSalesOrderLineItemWhereQueryParamParameterTypeOneOfNot =
  | 'PRODUCT'
  | 'VARIANT'
  | 'ITEM'
  | 'SHIPPING'
  | 'TAX'
  | 'DISCOUNT'
  | Type;

export type GetOneSalesOrderLineItemWhereQueryParamParameterTypeOneOf = {
  equals?: GetOneSalesOrderLineItemWhereQueryParamParameterTypeOneOfEquals;
  in?: GetOneSalesOrderLineItemWhereQueryParamParameterTypeOneOfIn;
  notIn?: GetOneSalesOrderLineItemWhereQueryParamParameterTypeOneOfNotIn;
  not?: GetOneSalesOrderLineItemWhereQueryParamParameterTypeOneOfNot;
} | null;

export type GetOneSalesOrderLineItemWhereQueryParamParameterType =
  | 'PRODUCT'
  | 'VARIANT'
  | 'ITEM'
  | 'SHIPPING'
  | 'TAX'
  | 'DISCOUNT'
  | GetOneSalesOrderLineItemWhereQueryParamParameterTypeOneOf;

export type GetOneSalesOrderLineItemWhereQueryParamParameterTypeOneOfNotInOneOfItem =
  typeof GetOneSalesOrderLineItemWhereQueryParamParameterTypeOneOfNotInOneOfItem[keyof typeof GetOneSalesOrderLineItemWhereQueryParamParameterTypeOneOfNotInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneSalesOrderLineItemWhereQueryParamParameterTypeOneOfNotInOneOfItem = {
  PRODUCT: 'PRODUCT',
  VARIANT: 'VARIANT',
  ITEM: 'ITEM',
  SHIPPING: 'SHIPPING',
  TAX: 'TAX',
  DISCOUNT: 'DISCOUNT',
} as const;

export type GetOneSalesOrderLineItemWhereQueryParamParameterTypeOneOfNotIn =
  | 'PRODUCT'
  | 'VARIANT'
  | 'ITEM'
  | 'SHIPPING'
  | 'TAX'
  | 'DISCOUNT'
  | GetOneSalesOrderLineItemWhereQueryParamParameterTypeOneOfNotInOneOfItem[];

export type GetOneSalesOrderLineItemWhereQueryParamParameterTypeOneOfInOneOfItem =
  typeof GetOneSalesOrderLineItemWhereQueryParamParameterTypeOneOfInOneOfItem[keyof typeof GetOneSalesOrderLineItemWhereQueryParamParameterTypeOneOfInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneSalesOrderLineItemWhereQueryParamParameterTypeOneOfInOneOfItem = {
  PRODUCT: 'PRODUCT',
  VARIANT: 'VARIANT',
  ITEM: 'ITEM',
  SHIPPING: 'SHIPPING',
  TAX: 'TAX',
  DISCOUNT: 'DISCOUNT',
} as const;

export type GetOneSalesOrderLineItemWhereQueryParamParameterTypeOneOfIn =
  | 'PRODUCT'
  | 'VARIANT'
  | 'ITEM'
  | 'SHIPPING'
  | 'TAX'
  | 'DISCOUNT'
  | GetOneSalesOrderLineItemWhereQueryParamParameterTypeOneOfInOneOfItem[];

export type GetOneSalesOrderLineItemWhereQueryParamParameterTypeOneOfEquals =
  typeof GetOneSalesOrderLineItemWhereQueryParamParameterTypeOneOfEquals[keyof typeof GetOneSalesOrderLineItemWhereQueryParamParameterTypeOneOfEquals];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneSalesOrderLineItemWhereQueryParamParameterTypeOneOfEquals = {
  PRODUCT: 'PRODUCT',
  VARIANT: 'VARIANT',
  ITEM: 'ITEM',
  SHIPPING: 'SHIPPING',
  TAX: 'TAX',
  DISCOUNT: 'DISCOUNT',
} as const;

export type GetOneSalesOrderLineItemWhereQueryParamParameterName =
  | string
  | GetOneSalesOrderLineItemWhereQueryParamParameterNameOneOf;

export type GetOneSalesOrderLineItemWhereQueryParamParameterNameOneOfNot = string | Name;

export type GetOneSalesOrderLineItemWhereQueryParamParameterNameOneOfMode =
  typeof GetOneSalesOrderLineItemWhereQueryParamParameterNameOneOfMode[keyof typeof GetOneSalesOrderLineItemWhereQueryParamParameterNameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneSalesOrderLineItemWhereQueryParamParameterNameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneSalesOrderLineItemWhereQueryParamParameterNameOneOfNotIn = string | string[];

export type GetOneSalesOrderLineItemWhereQueryParamParameterNameOneOfIn = string | string[];

export type GetOneSalesOrderLineItemWhereQueryParamParameterNameOneOf = {
  equals?: string | null;
  in?: GetOneSalesOrderLineItemWhereQueryParamParameterNameOneOfIn;
  notIn?: GetOneSalesOrderLineItemWhereQueryParamParameterNameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneSalesOrderLineItemWhereQueryParamParameterNameOneOfMode;
  not?: GetOneSalesOrderLineItemWhereQueryParamParameterNameOneOfNot;
};

export type GetOneSalesOrderLineItemWhereQueryParamParameterId =
  | string
  | GetOneSalesOrderLineItemWhereQueryParamParameterIdOneOf;

export type GetOneSalesOrderLineItemWhereQueryParamParameterIdOneOfNot = string | Id;

export type GetOneSalesOrderLineItemWhereQueryParamParameterIdOneOfMode =
  typeof GetOneSalesOrderLineItemWhereQueryParamParameterIdOneOfMode[keyof typeof GetOneSalesOrderLineItemWhereQueryParamParameterIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneSalesOrderLineItemWhereQueryParamParameterIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneSalesOrderLineItemWhereQueryParamParameterIdOneOfNotIn = string | string[];

export type GetOneSalesOrderLineItemWhereQueryParamParameterIdOneOfIn = string | string[];

export type GetOneSalesOrderLineItemWhereQueryParamParameterIdOneOf = {
  equals?: string | null;
  in?: GetOneSalesOrderLineItemWhereQueryParamParameterIdOneOfIn;
  notIn?: GetOneSalesOrderLineItemWhereQueryParamParameterIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneSalesOrderLineItemWhereQueryParamParameterIdOneOfMode;
  not?: GetOneSalesOrderLineItemWhereQueryParamParameterIdOneOfNot;
};

export type GetOneSalesOrderLineItemWhereQueryParamParameterNot = SalesOrderLineItemWhere | SalesOrderLineItemWhere[];

export type GetOneSalesOrderLineItemWhereQueryParamParameterOr = SalesOrderLineItemWhere | SalesOrderLineItemWhere[];

export type GetOneSalesOrderLineItemWhereQueryParamParameterAnd = SalesOrderLineItemWhere | SalesOrderLineItemWhere[];

export type GetOneSalesOrderLineItemIncludeQueryParamParameterOneOf = null;

export type GetOneSalesOrderLineItemIncludeQueryParamParameter =
  | GetOneSalesOrderLineItemIncludeQueryParamParameterOneOf
  | SalesOrderLineItemInclude;

export type GetOneSalesOrderLineItemSelectQueryParamParameterOneOf = null;

export type GetOneSalesOrderLineItemSelectQueryParamParameter =
  | GetOneSalesOrderLineItemSelectQueryParamParameterOneOf
  | SalesOrderLineItemSelect;

/**
 * Query params for get-sales-order-line-item-by-id}
 */
export type GetSalesOrderLineItemByIdWithTrashedQueryParamParameter = boolean | null;

export type GetSalesOrderLineItemByIdIncludeQueryParamParameterOneOf = null;

export type GetSalesOrderLineItemByIdIncludeQueryParamParameter =
  | GetSalesOrderLineItemByIdIncludeQueryParamParameterOneOf
  | SalesOrderLineItemInclude;

export type GetSalesOrderLineItemByIdSelectQueryParamParameterOneOf = null;

export type GetSalesOrderLineItemByIdSelectQueryParamParameter =
  | GetSalesOrderLineItemByIdSelectQueryParamParameterOneOf
  | SalesOrderLineItemSelect;

/**
 * Query params for get-many-sales-orders}
 */
export type GetManySalesOrdersWithTrashedQueryParamParameter = boolean | null;

export type GetManySalesOrdersWhereQueryParamParameterOneOf = null;

export type GetManySalesOrdersWhereQueryParamParameter =
  | GetManySalesOrdersWhereQueryParamParameterOneOf
  | SalesOrderWhere;

export type GetManySalesOrdersIncludeQueryParamParameterOneOf = null;

export type GetManySalesOrdersIncludeQueryParamParameter =
  | GetManySalesOrdersIncludeQueryParamParameterOneOf
  | SalesOrderInclude;

export type GetManySalesOrdersSelectQueryParamParameterOneOf = null;

export type GetManySalesOrdersSelectQueryParamParameter =
  | GetManySalesOrdersSelectQueryParamParameterOneOf
  | SalesOrderSelect;

/**
 * Query params for get-one-sales-order}
 */
export type GetOneSalesOrderWithTrashedQueryParamParameter = boolean | null;

export type GetOneSalesOrderWhereQueryParamParameterMembershipOrder =
  | MembershipOrderWhere
  | GetOneSalesOrderWhereQueryParamParameterMembershipOrderOneOf;

export type GetOneSalesOrderWhereQueryParamParameter = {
  AND?: GetOneSalesOrderWhereQueryParamParameterAnd;
  OR?: GetOneSalesOrderWhereQueryParamParameterOr;
  NOT?: GetOneSalesOrderWhereQueryParamParameterNot;
  id?: GetOneSalesOrderWhereQueryParamParameterId;
  name?: GetOneSalesOrderWhereQueryParamParameterName;
  orderDate?: GetOneSalesOrderWhereQueryParamParameterOrderDate;
  zohoSalesOrderRef?: GetOneSalesOrderWhereQueryParamParameterZohoSalesOrderRef;
  zohoPackageRef?: GetOneSalesOrderWhereQueryParamParameterZohoPackageRef;
  zohoInvoiceRef?: GetOneSalesOrderWhereQueryParamParameterZohoInvoiceRef;
  zohoPaymentRef?: GetOneSalesOrderWhereQueryParamParameterZohoPaymentRef;
  xeroInvoiceRef?: GetOneSalesOrderWhereQueryParamParameterXeroInvoiceRef;
  xeroInvoiceNumber?: GetOneSalesOrderWhereQueryParamParameterXeroInvoiceNumber;
  xeroPaymentRef?: GetOneSalesOrderWhereQueryParamParameterXeroPaymentRef;
  stripePaymentIntentRef?: GetOneSalesOrderWhereQueryParamParameterStripePaymentIntentRef;
  shipmentRunId?: GetOneSalesOrderWhereQueryParamParameterShipmentRunId;
  packagingData?: GetOneSalesOrderWhereQueryParamParameterPackagingData;
  invoiceUrl?: GetOneSalesOrderWhereQueryParamParameterInvoiceUrl;
  accountId?: GetOneSalesOrderWhereQueryParamParameterAccountId;
  createdAt?: GetOneSalesOrderWhereQueryParamParameterCreatedAt;
  updatedAt?: GetOneSalesOrderWhereQueryParamParameterUpdatedAt;
  deletedAt?: GetOneSalesOrderWhereQueryParamParameterDeletedAt;
  account?: GetOneSalesOrderWhereQueryParamParameterAccount;
  shipmentRun?: GetOneSalesOrderWhereQueryParamParameterShipmentRun;
  lineItems?: GetOneSalesOrderWhereQueryParamParameterLineItems;
  membershipOrder?: GetOneSalesOrderWhereQueryParamParameterMembershipOrder;
};

export type GetOneSalesOrderWhereQueryParamParameterMembershipOrderOneOfIsNot = MembershipOrderWhere;

export type GetOneSalesOrderWhereQueryParamParameterMembershipOrderOneOfIs = MembershipOrderWhere;

export type GetOneSalesOrderWhereQueryParamParameterMembershipOrderOneOf = {
  is?: GetOneSalesOrderWhereQueryParamParameterMembershipOrderOneOfIs;
  isNot?: GetOneSalesOrderWhereQueryParamParameterMembershipOrderOneOfIsNot;
};

export type GetOneSalesOrderWhereQueryParamParameterLineItemsNone = SalesOrderLineItemWhere;

export type GetOneSalesOrderWhereQueryParamParameterLineItemsEvery = SalesOrderLineItemWhere;

export type GetOneSalesOrderWhereQueryParamParameterLineItemsSome = SalesOrderLineItemWhere;

export type GetOneSalesOrderWhereQueryParamParameterLineItems = {
  some?: GetOneSalesOrderWhereQueryParamParameterLineItemsSome;
  every?: GetOneSalesOrderWhereQueryParamParameterLineItemsEvery;
  none?: GetOneSalesOrderWhereQueryParamParameterLineItemsNone;
} | null;

export type GetOneSalesOrderWhereQueryParamParameterShipmentRunOneOfIsNot = ShipmentRunWhere;

export type GetOneSalesOrderWhereQueryParamParameterShipmentRunOneOfIs = ShipmentRunWhere;

export type GetOneSalesOrderWhereQueryParamParameterShipmentRunOneOf = {
  is?: GetOneSalesOrderWhereQueryParamParameterShipmentRunOneOfIs;
  isNot?: GetOneSalesOrderWhereQueryParamParameterShipmentRunOneOfIsNot;
};

export type GetOneSalesOrderWhereQueryParamParameterShipmentRun =
  | ShipmentRunWhere
  | GetOneSalesOrderWhereQueryParamParameterShipmentRunOneOf;

export type GetOneSalesOrderWhereQueryParamParameterAccount =
  | AccountWhere
  | GetOneSalesOrderWhereQueryParamParameterAccountOneOf;

export type GetOneSalesOrderWhereQueryParamParameterAccountOneOfIsNot = AccountWhere;

export type GetOneSalesOrderWhereQueryParamParameterAccountOneOfIs = AccountWhere;

export type GetOneSalesOrderWhereQueryParamParameterAccountOneOf = {
  is?: GetOneSalesOrderWhereQueryParamParameterAccountOneOfIs;
  isNot?: GetOneSalesOrderWhereQueryParamParameterAccountOneOfIsNot;
};

export type GetOneSalesOrderWhereQueryParamParameterDeletedAt =
  | string
  | GetOneSalesOrderWhereQueryParamParameterDeletedAtOneOf;

export type GetOneSalesOrderWhereQueryParamParameterDeletedAtOneOfNot = string | DeletedAt;

export type GetOneSalesOrderWhereQueryParamParameterDeletedAtOneOfNotIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterDeletedAtOneOfIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterDeletedAtOneOfEquals = string;

export type GetOneSalesOrderWhereQueryParamParameterDeletedAtOneOf = {
  equals?: GetOneSalesOrderWhereQueryParamParameterDeletedAtOneOfEquals;
  in?: GetOneSalesOrderWhereQueryParamParameterDeletedAtOneOfIn;
  notIn?: GetOneSalesOrderWhereQueryParamParameterDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneSalesOrderWhereQueryParamParameterDeletedAtOneOfNot;
};

export type GetOneSalesOrderWhereQueryParamParameterUpdatedAt =
  | string
  | GetOneSalesOrderWhereQueryParamParameterUpdatedAtOneOf;

export type GetOneSalesOrderWhereQueryParamParameterUpdatedAtOneOfNot = string | UpdatedAt;

export type GetOneSalesOrderWhereQueryParamParameterUpdatedAtOneOfNotIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterUpdatedAtOneOfIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterUpdatedAtOneOf = {
  equals?: string | null;
  in?: GetOneSalesOrderWhereQueryParamParameterUpdatedAtOneOfIn;
  notIn?: GetOneSalesOrderWhereQueryParamParameterUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneSalesOrderWhereQueryParamParameterUpdatedAtOneOfNot;
};

export type GetOneSalesOrderWhereQueryParamParameterCreatedAtOneOfNot = string | CreatedAt;

export type GetOneSalesOrderWhereQueryParamParameterCreatedAtOneOfNotIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterCreatedAtOneOfIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterCreatedAtOneOf = {
  equals?: string | null;
  in?: GetOneSalesOrderWhereQueryParamParameterCreatedAtOneOfIn;
  notIn?: GetOneSalesOrderWhereQueryParamParameterCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneSalesOrderWhereQueryParamParameterCreatedAtOneOfNot;
};

export type GetOneSalesOrderWhereQueryParamParameterCreatedAt =
  | string
  | GetOneSalesOrderWhereQueryParamParameterCreatedAtOneOf;

export type GetOneSalesOrderWhereQueryParamParameterAccountIdOneOfNot = string | AccountId;

export type GetOneSalesOrderWhereQueryParamParameterAccountIdOneOfMode =
  typeof GetOneSalesOrderWhereQueryParamParameterAccountIdOneOfMode[keyof typeof GetOneSalesOrderWhereQueryParamParameterAccountIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneSalesOrderWhereQueryParamParameterAccountIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneSalesOrderWhereQueryParamParameterAccountIdOneOfNotIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterAccountIdOneOfIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterAccountIdOneOf = {
  equals?: string | null;
  in?: GetOneSalesOrderWhereQueryParamParameterAccountIdOneOfIn;
  notIn?: GetOneSalesOrderWhereQueryParamParameterAccountIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneSalesOrderWhereQueryParamParameterAccountIdOneOfMode;
  not?: GetOneSalesOrderWhereQueryParamParameterAccountIdOneOfNot;
};

export type GetOneSalesOrderWhereQueryParamParameterAccountId =
  | string
  | GetOneSalesOrderWhereQueryParamParameterAccountIdOneOf;

export type GetOneSalesOrderWhereQueryParamParameterInvoiceUrl =
  | string
  | GetOneSalesOrderWhereQueryParamParameterInvoiceUrlOneOf;

export type GetOneSalesOrderWhereQueryParamParameterInvoiceUrlOneOfNot = string | InvoiceUrl;

export type GetOneSalesOrderWhereQueryParamParameterInvoiceUrlOneOfMode =
  typeof GetOneSalesOrderWhereQueryParamParameterInvoiceUrlOneOfMode[keyof typeof GetOneSalesOrderWhereQueryParamParameterInvoiceUrlOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneSalesOrderWhereQueryParamParameterInvoiceUrlOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneSalesOrderWhereQueryParamParameterInvoiceUrlOneOfNotIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterInvoiceUrlOneOfIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterInvoiceUrlOneOfEquals = string;

export type GetOneSalesOrderWhereQueryParamParameterInvoiceUrlOneOf = {
  equals?: GetOneSalesOrderWhereQueryParamParameterInvoiceUrlOneOfEquals;
  in?: GetOneSalesOrderWhereQueryParamParameterInvoiceUrlOneOfIn;
  notIn?: GetOneSalesOrderWhereQueryParamParameterInvoiceUrlOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneSalesOrderWhereQueryParamParameterInvoiceUrlOneOfMode;
  not?: GetOneSalesOrderWhereQueryParamParameterInvoiceUrlOneOfNot;
};

export type GetOneSalesOrderWhereQueryParamParameterPackagingDataOneOfPath = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterPackagingDataOneOf = {
  path?: GetOneSalesOrderWhereQueryParamParameterPackagingDataOneOfPath;
  equals?: unknown;
  not?: unknown;
  string_contains?: string | null;
  string_starts_with?: string | null;
  string_ends_with?: string | null;
  array_contains?: unknown;
  array_starts_with?: unknown;
  array_ends_with?: unknown;
};

export type GetOneSalesOrderWhereQueryParamParameterPackagingData =
  GetOneSalesOrderWhereQueryParamParameterPackagingDataOneOf;

export type GetOneSalesOrderWhereQueryParamParameterShipmentRunId =
  | string
  | GetOneSalesOrderWhereQueryParamParameterShipmentRunIdOneOf;

export type GetOneSalesOrderWhereQueryParamParameterShipmentRunIdOneOfNot = string | ShipmentRunId;

export type GetOneSalesOrderWhereQueryParamParameterShipmentRunIdOneOfMode =
  typeof GetOneSalesOrderWhereQueryParamParameterShipmentRunIdOneOfMode[keyof typeof GetOneSalesOrderWhereQueryParamParameterShipmentRunIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneSalesOrderWhereQueryParamParameterShipmentRunIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneSalesOrderWhereQueryParamParameterShipmentRunIdOneOfNotIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterShipmentRunIdOneOfIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterShipmentRunIdOneOf = {
  equals?: string | null;
  in?: GetOneSalesOrderWhereQueryParamParameterShipmentRunIdOneOfIn;
  notIn?: GetOneSalesOrderWhereQueryParamParameterShipmentRunIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneSalesOrderWhereQueryParamParameterShipmentRunIdOneOfMode;
  not?: GetOneSalesOrderWhereQueryParamParameterShipmentRunIdOneOfNot;
};

export type GetOneSalesOrderWhereQueryParamParameterStripePaymentIntentRefOneOfNot = string | StripePaymentIntentRef;

export type GetOneSalesOrderWhereQueryParamParameterStripePaymentIntentRefOneOfMode =
  typeof GetOneSalesOrderWhereQueryParamParameterStripePaymentIntentRefOneOfMode[keyof typeof GetOneSalesOrderWhereQueryParamParameterStripePaymentIntentRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneSalesOrderWhereQueryParamParameterStripePaymentIntentRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneSalesOrderWhereQueryParamParameterStripePaymentIntentRefOneOfNotIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterStripePaymentIntentRefOneOfIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterStripePaymentIntentRefOneOfEquals = string;

export type GetOneSalesOrderWhereQueryParamParameterStripePaymentIntentRefOneOf = {
  equals?: GetOneSalesOrderWhereQueryParamParameterStripePaymentIntentRefOneOfEquals;
  in?: GetOneSalesOrderWhereQueryParamParameterStripePaymentIntentRefOneOfIn;
  notIn?: GetOneSalesOrderWhereQueryParamParameterStripePaymentIntentRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneSalesOrderWhereQueryParamParameterStripePaymentIntentRefOneOfMode;
  not?: GetOneSalesOrderWhereQueryParamParameterStripePaymentIntentRefOneOfNot;
};

export type GetOneSalesOrderWhereQueryParamParameterStripePaymentIntentRef =
  | string
  | GetOneSalesOrderWhereQueryParamParameterStripePaymentIntentRefOneOf;

export type GetOneSalesOrderWhereQueryParamParameterXeroPaymentRef =
  | string
  | GetOneSalesOrderWhereQueryParamParameterXeroPaymentRefOneOf;

export type GetOneSalesOrderWhereQueryParamParameterXeroPaymentRefOneOfNot = string | XeroPaymentRef;

export type GetOneSalesOrderWhereQueryParamParameterXeroPaymentRefOneOfMode =
  typeof GetOneSalesOrderWhereQueryParamParameterXeroPaymentRefOneOfMode[keyof typeof GetOneSalesOrderWhereQueryParamParameterXeroPaymentRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneSalesOrderWhereQueryParamParameterXeroPaymentRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneSalesOrderWhereQueryParamParameterXeroPaymentRefOneOfNotIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterXeroPaymentRefOneOfIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterXeroPaymentRefOneOfEquals = string;

export type GetOneSalesOrderWhereQueryParamParameterXeroPaymentRefOneOf = {
  equals?: GetOneSalesOrderWhereQueryParamParameterXeroPaymentRefOneOfEquals;
  in?: GetOneSalesOrderWhereQueryParamParameterXeroPaymentRefOneOfIn;
  notIn?: GetOneSalesOrderWhereQueryParamParameterXeroPaymentRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneSalesOrderWhereQueryParamParameterXeroPaymentRefOneOfMode;
  not?: GetOneSalesOrderWhereQueryParamParameterXeroPaymentRefOneOfNot;
};

export type GetOneSalesOrderWhereQueryParamParameterXeroInvoiceNumber =
  | string
  | GetOneSalesOrderWhereQueryParamParameterXeroInvoiceNumberOneOf;

export type GetOneSalesOrderWhereQueryParamParameterXeroInvoiceNumberOneOfNot = string | XeroInvoiceNumber;

export type GetOneSalesOrderWhereQueryParamParameterXeroInvoiceNumberOneOfMode =
  typeof GetOneSalesOrderWhereQueryParamParameterXeroInvoiceNumberOneOfMode[keyof typeof GetOneSalesOrderWhereQueryParamParameterXeroInvoiceNumberOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneSalesOrderWhereQueryParamParameterXeroInvoiceNumberOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneSalesOrderWhereQueryParamParameterXeroInvoiceNumberOneOfNotIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterXeroInvoiceNumberOneOfIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterXeroInvoiceNumberOneOfEquals = string;

export type GetOneSalesOrderWhereQueryParamParameterXeroInvoiceNumberOneOf = {
  equals?: GetOneSalesOrderWhereQueryParamParameterXeroInvoiceNumberOneOfEquals;
  in?: GetOneSalesOrderWhereQueryParamParameterXeroInvoiceNumberOneOfIn;
  notIn?: GetOneSalesOrderWhereQueryParamParameterXeroInvoiceNumberOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneSalesOrderWhereQueryParamParameterXeroInvoiceNumberOneOfMode;
  not?: GetOneSalesOrderWhereQueryParamParameterXeroInvoiceNumberOneOfNot;
};

export type GetOneSalesOrderWhereQueryParamParameterXeroInvoiceRef =
  | string
  | GetOneSalesOrderWhereQueryParamParameterXeroInvoiceRefOneOf;

export type GetOneSalesOrderWhereQueryParamParameterXeroInvoiceRefOneOfNot = string | XeroInvoiceRef;

export type GetOneSalesOrderWhereQueryParamParameterXeroInvoiceRefOneOfMode =
  typeof GetOneSalesOrderWhereQueryParamParameterXeroInvoiceRefOneOfMode[keyof typeof GetOneSalesOrderWhereQueryParamParameterXeroInvoiceRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneSalesOrderWhereQueryParamParameterXeroInvoiceRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneSalesOrderWhereQueryParamParameterXeroInvoiceRefOneOfNotIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterXeroInvoiceRefOneOfIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterXeroInvoiceRefOneOfEquals = string;

export type GetOneSalesOrderWhereQueryParamParameterXeroInvoiceRefOneOf = {
  equals?: GetOneSalesOrderWhereQueryParamParameterXeroInvoiceRefOneOfEquals;
  in?: GetOneSalesOrderWhereQueryParamParameterXeroInvoiceRefOneOfIn;
  notIn?: GetOneSalesOrderWhereQueryParamParameterXeroInvoiceRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneSalesOrderWhereQueryParamParameterXeroInvoiceRefOneOfMode;
  not?: GetOneSalesOrderWhereQueryParamParameterXeroInvoiceRefOneOfNot;
};

export type GetOneSalesOrderWhereQueryParamParameterZohoPaymentRef =
  | string
  | GetOneSalesOrderWhereQueryParamParameterZohoPaymentRefOneOf;

export type GetOneSalesOrderWhereQueryParamParameterZohoPaymentRefOneOfNot = string | ZohoPaymentRef;

export type GetOneSalesOrderWhereQueryParamParameterZohoPaymentRefOneOfMode =
  typeof GetOneSalesOrderWhereQueryParamParameterZohoPaymentRefOneOfMode[keyof typeof GetOneSalesOrderWhereQueryParamParameterZohoPaymentRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneSalesOrderWhereQueryParamParameterZohoPaymentRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneSalesOrderWhereQueryParamParameterZohoPaymentRefOneOfNotIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterZohoPaymentRefOneOfIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterZohoPaymentRefOneOfEquals = string;

export type GetOneSalesOrderWhereQueryParamParameterZohoPaymentRefOneOf = {
  equals?: GetOneSalesOrderWhereQueryParamParameterZohoPaymentRefOneOfEquals;
  in?: GetOneSalesOrderWhereQueryParamParameterZohoPaymentRefOneOfIn;
  notIn?: GetOneSalesOrderWhereQueryParamParameterZohoPaymentRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneSalesOrderWhereQueryParamParameterZohoPaymentRefOneOfMode;
  not?: GetOneSalesOrderWhereQueryParamParameterZohoPaymentRefOneOfNot;
};

export type GetOneSalesOrderWhereQueryParamParameterZohoInvoiceRefOneOfNot = string | ZohoInvoiceRef;

export type GetOneSalesOrderWhereQueryParamParameterZohoInvoiceRefOneOfMode =
  typeof GetOneSalesOrderWhereQueryParamParameterZohoInvoiceRefOneOfMode[keyof typeof GetOneSalesOrderWhereQueryParamParameterZohoInvoiceRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneSalesOrderWhereQueryParamParameterZohoInvoiceRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneSalesOrderWhereQueryParamParameterZohoInvoiceRefOneOfNotIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterZohoInvoiceRefOneOfIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterZohoInvoiceRefOneOfEquals = string;

export type GetOneSalesOrderWhereQueryParamParameterZohoInvoiceRefOneOf = {
  equals?: GetOneSalesOrderWhereQueryParamParameterZohoInvoiceRefOneOfEquals;
  in?: GetOneSalesOrderWhereQueryParamParameterZohoInvoiceRefOneOfIn;
  notIn?: GetOneSalesOrderWhereQueryParamParameterZohoInvoiceRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneSalesOrderWhereQueryParamParameterZohoInvoiceRefOneOfMode;
  not?: GetOneSalesOrderWhereQueryParamParameterZohoInvoiceRefOneOfNot;
};

export type GetOneSalesOrderWhereQueryParamParameterZohoInvoiceRef =
  | string
  | GetOneSalesOrderWhereQueryParamParameterZohoInvoiceRefOneOf;

export type GetOneSalesOrderWhereQueryParamParameterZohoPackageRef =
  | string
  | GetOneSalesOrderWhereQueryParamParameterZohoPackageRefOneOf;

export type GetOneSalesOrderWhereQueryParamParameterZohoPackageRefOneOfNot = string | ZohoPackageRef;

export type GetOneSalesOrderWhereQueryParamParameterZohoPackageRefOneOfMode =
  typeof GetOneSalesOrderWhereQueryParamParameterZohoPackageRefOneOfMode[keyof typeof GetOneSalesOrderWhereQueryParamParameterZohoPackageRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneSalesOrderWhereQueryParamParameterZohoPackageRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneSalesOrderWhereQueryParamParameterZohoPackageRefOneOfNotIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterZohoPackageRefOneOfIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterZohoPackageRefOneOfEquals = string;

export type GetOneSalesOrderWhereQueryParamParameterZohoPackageRefOneOf = {
  equals?: GetOneSalesOrderWhereQueryParamParameterZohoPackageRefOneOfEquals;
  in?: GetOneSalesOrderWhereQueryParamParameterZohoPackageRefOneOfIn;
  notIn?: GetOneSalesOrderWhereQueryParamParameterZohoPackageRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneSalesOrderWhereQueryParamParameterZohoPackageRefOneOfMode;
  not?: GetOneSalesOrderWhereQueryParamParameterZohoPackageRefOneOfNot;
};

export type GetOneSalesOrderWhereQueryParamParameterZohoSalesOrderRef =
  | string
  | GetOneSalesOrderWhereQueryParamParameterZohoSalesOrderRefOneOf;

export type GetOneSalesOrderWhereQueryParamParameterZohoSalesOrderRefOneOfNot = string | ZohoSalesOrderRef;

export type GetOneSalesOrderWhereQueryParamParameterZohoSalesOrderRefOneOfMode =
  typeof GetOneSalesOrderWhereQueryParamParameterZohoSalesOrderRefOneOfMode[keyof typeof GetOneSalesOrderWhereQueryParamParameterZohoSalesOrderRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneSalesOrderWhereQueryParamParameterZohoSalesOrderRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneSalesOrderWhereQueryParamParameterZohoSalesOrderRefOneOfNotIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterZohoSalesOrderRefOneOfIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterZohoSalesOrderRefOneOfEquals = string;

export type GetOneSalesOrderWhereQueryParamParameterZohoSalesOrderRefOneOf = {
  equals?: GetOneSalesOrderWhereQueryParamParameterZohoSalesOrderRefOneOfEquals;
  in?: GetOneSalesOrderWhereQueryParamParameterZohoSalesOrderRefOneOfIn;
  notIn?: GetOneSalesOrderWhereQueryParamParameterZohoSalesOrderRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneSalesOrderWhereQueryParamParameterZohoSalesOrderRefOneOfMode;
  not?: GetOneSalesOrderWhereQueryParamParameterZohoSalesOrderRefOneOfNot;
};

export type GetOneSalesOrderWhereQueryParamParameterOrderDate =
  | string
  | GetOneSalesOrderWhereQueryParamParameterOrderDateOneOf;

export type GetOneSalesOrderWhereQueryParamParameterOrderDateOneOfNot = string | OrderDate;

export type GetOneSalesOrderWhereQueryParamParameterOrderDateOneOfNotIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterOrderDateOneOfIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterOrderDateOneOf = {
  equals?: string | null;
  in?: GetOneSalesOrderWhereQueryParamParameterOrderDateOneOfIn;
  notIn?: GetOneSalesOrderWhereQueryParamParameterOrderDateOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneSalesOrderWhereQueryParamParameterOrderDateOneOfNot;
};

export type GetOneSalesOrderWhereQueryParamParameterName = string | GetOneSalesOrderWhereQueryParamParameterNameOneOf;

export type GetOneSalesOrderWhereQueryParamParameterNameOneOfNot = string | Name;

export type GetOneSalesOrderWhereQueryParamParameterNameOneOfMode =
  typeof GetOneSalesOrderWhereQueryParamParameterNameOneOfMode[keyof typeof GetOneSalesOrderWhereQueryParamParameterNameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneSalesOrderWhereQueryParamParameterNameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneSalesOrderWhereQueryParamParameterNameOneOfNotIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterNameOneOfIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterNameOneOf = {
  equals?: string | null;
  in?: GetOneSalesOrderWhereQueryParamParameterNameOneOfIn;
  notIn?: GetOneSalesOrderWhereQueryParamParameterNameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneSalesOrderWhereQueryParamParameterNameOneOfMode;
  not?: GetOneSalesOrderWhereQueryParamParameterNameOneOfNot;
};

export type GetOneSalesOrderWhereQueryParamParameterId = string | GetOneSalesOrderWhereQueryParamParameterIdOneOf;

export type GetOneSalesOrderWhereQueryParamParameterIdOneOfNot = string | Id;

export type GetOneSalesOrderWhereQueryParamParameterIdOneOfMode =
  typeof GetOneSalesOrderWhereQueryParamParameterIdOneOfMode[keyof typeof GetOneSalesOrderWhereQueryParamParameterIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneSalesOrderWhereQueryParamParameterIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneSalesOrderWhereQueryParamParameterIdOneOfNotIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterIdOneOfIn = string | string[];

export type GetOneSalesOrderWhereQueryParamParameterIdOneOf = {
  equals?: string | null;
  in?: GetOneSalesOrderWhereQueryParamParameterIdOneOfIn;
  notIn?: GetOneSalesOrderWhereQueryParamParameterIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneSalesOrderWhereQueryParamParameterIdOneOfMode;
  not?: GetOneSalesOrderWhereQueryParamParameterIdOneOfNot;
};

export type GetOneSalesOrderWhereQueryParamParameterNot = SalesOrderWhere | SalesOrderWhere[];

export type GetOneSalesOrderWhereQueryParamParameterOr = SalesOrderWhere | SalesOrderWhere[];

export type GetOneSalesOrderWhereQueryParamParameterAnd = SalesOrderWhere | SalesOrderWhere[];

export type GetOneSalesOrderIncludeQueryParamParameterOneOf = null;

export type GetOneSalesOrderIncludeQueryParamParameter =
  | GetOneSalesOrderIncludeQueryParamParameterOneOf
  | SalesOrderInclude;

export type GetOneSalesOrderSelectQueryParamParameterOneOf = null;

export type GetOneSalesOrderSelectQueryParamParameter =
  | GetOneSalesOrderSelectQueryParamParameterOneOf
  | SalesOrderSelect;

/**
 * Query params for get-sales-order-by-id}
 */
export type GetSalesOrderByIdWithTrashedQueryParamParameter = boolean | null;

export type GetSalesOrderByIdIncludeQueryParamParameterOneOf = null;

export type GetSalesOrderByIdIncludeQueryParamParameter =
  | GetSalesOrderByIdIncludeQueryParamParameterOneOf
  | SalesOrderInclude;

export type GetSalesOrderByIdSelectQueryParamParameterOneOf = null;

export type GetSalesOrderByIdSelectQueryParamParameter =
  | GetSalesOrderByIdSelectQueryParamParameterOneOf
  | SalesOrderSelect;

/**
 * Query params for get-many-items}
 */
export type GetManyItemsWithTrashedQueryParamParameter = boolean | null;

export type GetManyItemsWhereQueryParamParameterOneOf = null;

export type GetManyItemsWhereQueryParamParameter = GetManyItemsWhereQueryParamParameterOneOf | ItemWhere;

export type GetManyItemsIncludeQueryParamParameterOneOf = null;

export type GetManyItemsIncludeQueryParamParameter = GetManyItemsIncludeQueryParamParameterOneOf | ItemInclude;

export type GetManyItemsSelectQueryParamParameterOneOf = null;

export type GetManyItemsSelectQueryParamParameter = GetManyItemsSelectQueryParamParameterOneOf | ItemSelect;

/**
 * Query params for get-one-item}
 */
export type GetOneItemWithTrashedQueryParamParameter = boolean | null;

export type GetOneItemWhereQueryParamParameterMembershipOfferingItemsNone = MembershipOfferingItemWhere;

export type GetOneItemWhereQueryParamParameterMembershipOfferingItemsEvery = MembershipOfferingItemWhere;

export type GetOneItemWhereQueryParamParameterMembershipOfferingItemsSome = MembershipOfferingItemWhere;

export type GetOneItemWhereQueryParamParameterMembershipOfferingItems = {
  some?: GetOneItemWhereQueryParamParameterMembershipOfferingItemsSome;
  every?: GetOneItemWhereQueryParamParameterMembershipOfferingItemsEvery;
  none?: GetOneItemWhereQueryParamParameterMembershipOfferingItemsNone;
} | null;

export type GetOneItemWhereQueryParamParameterMembershipOrderItemsNone = MembershipOrderItemWhere;

export type GetOneItemWhereQueryParamParameterMembershipOrderItemsEvery = MembershipOrderItemWhere;

export type GetOneItemWhereQueryParamParameterMembershipOrderItemsSome = MembershipOrderItemWhere;

export type GetOneItemWhereQueryParamParameterMembershipOrderItems = {
  some?: GetOneItemWhereQueryParamParameterMembershipOrderItemsSome;
  every?: GetOneItemWhereQueryParamParameterMembershipOrderItemsEvery;
  none?: GetOneItemWhereQueryParamParameterMembershipOrderItemsNone;
} | null;

export type GetOneItemWhereQueryParamParameterSalesOrderLineItemsNone = SalesOrderLineItemWhere;

export type GetOneItemWhereQueryParamParameterSalesOrderLineItemsEvery = SalesOrderLineItemWhere;

export type GetOneItemWhereQueryParamParameterSalesOrderLineItemsSome = SalesOrderLineItemWhere;

export type GetOneItemWhereQueryParamParameterSalesOrderLineItems = {
  some?: GetOneItemWhereQueryParamParameterSalesOrderLineItemsSome;
  every?: GetOneItemWhereQueryParamParameterSalesOrderLineItemsEvery;
  none?: GetOneItemWhereQueryParamParameterSalesOrderLineItemsNone;
} | null;

export type GetOneItemWhereQueryParamParameterProductOneOfIsNot = ProductWhere;

export type GetOneItemWhereQueryParamParameterProductOneOfIs = ProductWhere;

export type GetOneItemWhereQueryParamParameterProductOneOf = {
  is?: GetOneItemWhereQueryParamParameterProductOneOfIs;
  isNot?: GetOneItemWhereQueryParamParameterProductOneOfIsNot;
};

export type GetOneItemWhereQueryParamParameterProduct = ProductWhere | GetOneItemWhereQueryParamParameterProductOneOf;

export type GetOneItemWhereQueryParamParameterVariant =
  | ProductVariantWhere
  | GetOneItemWhereQueryParamParameterVariantOneOf;

export type GetOneItemWhereQueryParamParameter = {
  AND?: GetOneItemWhereQueryParamParameterAnd;
  OR?: GetOneItemWhereQueryParamParameterOr;
  NOT?: GetOneItemWhereQueryParamParameterNot;
  id?: GetOneItemWhereQueryParamParameterId;
  name?: GetOneItemWhereQueryParamParameterName;
  description?: GetOneItemWhereQueryParamParameterDescription;
  sku?: GetOneItemWhereQueryParamParameterSku;
  sellEx?: GetOneItemWhereQueryParamParameterSellEx;
  sellGst?: GetOneItemWhereQueryParamParameterSellGst;
  costEx?: GetOneItemWhereQueryParamParameterCostEx;
  costGst?: GetOneItemWhereQueryParamParameterCostGst;
  stepQty?: GetOneItemWhereQueryParamParameterStepQty;
  minimumQty?: GetOneItemWhereQueryParamParameterMinimumQty;
  zohoInvItemRef?: GetOneItemWhereQueryParamParameterZohoInvItemRef;
  createdAt?: GetOneItemWhereQueryParamParameterCreatedAt;
  updatedAt?: GetOneItemWhereQueryParamParameterUpdatedAt;
  deletedAt?: GetOneItemWhereQueryParamParameterDeletedAt;
  variant?: GetOneItemWhereQueryParamParameterVariant;
  product?: GetOneItemWhereQueryParamParameterProduct;
  salesOrderLineItems?: GetOneItemWhereQueryParamParameterSalesOrderLineItems;
  membershipOrderItems?: GetOneItemWhereQueryParamParameterMembershipOrderItems;
  membershipOfferingItems?: GetOneItemWhereQueryParamParameterMembershipOfferingItems;
};

export type GetOneItemWhereQueryParamParameterVariantOneOfIsNot = ProductVariantWhere;

export type GetOneItemWhereQueryParamParameterVariantOneOfIs = ProductVariantWhere;

export type GetOneItemWhereQueryParamParameterVariantOneOf = {
  is?: GetOneItemWhereQueryParamParameterVariantOneOfIs;
  isNot?: GetOneItemWhereQueryParamParameterVariantOneOfIsNot;
};

export type GetOneItemWhereQueryParamParameterDeletedAtOneOfNot = string | DeletedAt;

export type GetOneItemWhereQueryParamParameterDeletedAtOneOfNotIn = string | string[];

export type GetOneItemWhereQueryParamParameterDeletedAtOneOfIn = string | string[];

export type GetOneItemWhereQueryParamParameterDeletedAtOneOfEquals = string;

export type GetOneItemWhereQueryParamParameterDeletedAtOneOf = {
  equals?: GetOneItemWhereQueryParamParameterDeletedAtOneOfEquals;
  in?: GetOneItemWhereQueryParamParameterDeletedAtOneOfIn;
  notIn?: GetOneItemWhereQueryParamParameterDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneItemWhereQueryParamParameterDeletedAtOneOfNot;
};

export type GetOneItemWhereQueryParamParameterDeletedAt = string | GetOneItemWhereQueryParamParameterDeletedAtOneOf;

export type GetOneItemWhereQueryParamParameterUpdatedAt = string | GetOneItemWhereQueryParamParameterUpdatedAtOneOf;

export type GetOneItemWhereQueryParamParameterUpdatedAtOneOfNot = string | UpdatedAt;

export type GetOneItemWhereQueryParamParameterUpdatedAtOneOfNotIn = string | string[];

export type GetOneItemWhereQueryParamParameterUpdatedAtOneOfIn = string | string[];

export type GetOneItemWhereQueryParamParameterUpdatedAtOneOf = {
  equals?: string | null;
  in?: GetOneItemWhereQueryParamParameterUpdatedAtOneOfIn;
  notIn?: GetOneItemWhereQueryParamParameterUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneItemWhereQueryParamParameterUpdatedAtOneOfNot;
};

export type GetOneItemWhereQueryParamParameterCreatedAt = string | GetOneItemWhereQueryParamParameterCreatedAtOneOf;

export type GetOneItemWhereQueryParamParameterCreatedAtOneOfNot = string | CreatedAt;

export type GetOneItemWhereQueryParamParameterCreatedAtOneOfNotIn = string | string[];

export type GetOneItemWhereQueryParamParameterCreatedAtOneOfIn = string | string[];

export type GetOneItemWhereQueryParamParameterCreatedAtOneOf = {
  equals?: string | null;
  in?: GetOneItemWhereQueryParamParameterCreatedAtOneOfIn;
  notIn?: GetOneItemWhereQueryParamParameterCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneItemWhereQueryParamParameterCreatedAtOneOfNot;
};

export type GetOneItemWhereQueryParamParameterZohoInvItemRef =
  | string
  | GetOneItemWhereQueryParamParameterZohoInvItemRefOneOf;

export type GetOneItemWhereQueryParamParameterZohoInvItemRefOneOfNot = string | ZohoInvItemRef;

export type GetOneItemWhereQueryParamParameterZohoInvItemRefOneOfMode =
  typeof GetOneItemWhereQueryParamParameterZohoInvItemRefOneOfMode[keyof typeof GetOneItemWhereQueryParamParameterZohoInvItemRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneItemWhereQueryParamParameterZohoInvItemRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneItemWhereQueryParamParameterZohoInvItemRefOneOfNotIn = string | string[];

export type GetOneItemWhereQueryParamParameterZohoInvItemRefOneOfIn = string | string[];

export type GetOneItemWhereQueryParamParameterZohoInvItemRefOneOfEquals = string;

export type GetOneItemWhereQueryParamParameterZohoInvItemRefOneOf = {
  equals?: GetOneItemWhereQueryParamParameterZohoInvItemRefOneOfEquals;
  in?: GetOneItemWhereQueryParamParameterZohoInvItemRefOneOfIn;
  notIn?: GetOneItemWhereQueryParamParameterZohoInvItemRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneItemWhereQueryParamParameterZohoInvItemRefOneOfMode;
  not?: GetOneItemWhereQueryParamParameterZohoInvItemRefOneOfNot;
};

export type GetOneItemWhereQueryParamParameterMinimumQty = number | GetOneItemWhereQueryParamParameterMinimumQtyOneOf;

export type GetOneItemWhereQueryParamParameterMinimumQtyOneOfNotOneOf = null;

export type GetOneItemWhereQueryParamParameterMinimumQtyOneOfNot =
  | GetOneItemWhereQueryParamParameterMinimumQtyOneOfNotOneOf
  | number
  | MinimumQty;

export type GetOneItemWhereQueryParamParameterMinimumQtyOneOfNotIn = number | number[];

export type GetOneItemWhereQueryParamParameterMinimumQtyOneOfIn = number | number[];

export type GetOneItemWhereQueryParamParameterMinimumQtyOneOf = {
  equals?: number | null;
  in?: GetOneItemWhereQueryParamParameterMinimumQtyOneOfIn;
  notIn?: GetOneItemWhereQueryParamParameterMinimumQtyOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: GetOneItemWhereQueryParamParameterMinimumQtyOneOfNot;
};

export type GetOneItemWhereQueryParamParameterStepQty = number | GetOneItemWhereQueryParamParameterStepQtyOneOf;

export type GetOneItemWhereQueryParamParameterStepQtyOneOfNotOneOf = null;

export type GetOneItemWhereQueryParamParameterStepQtyOneOfNot =
  | GetOneItemWhereQueryParamParameterStepQtyOneOfNotOneOf
  | number
  | StepQty;

export type GetOneItemWhereQueryParamParameterStepQtyOneOfNotIn = number | number[];

export type GetOneItemWhereQueryParamParameterStepQtyOneOfIn = number | number[];

export type GetOneItemWhereQueryParamParameterStepQtyOneOf = {
  equals?: number | null;
  in?: GetOneItemWhereQueryParamParameterStepQtyOneOfIn;
  notIn?: GetOneItemWhereQueryParamParameterStepQtyOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: GetOneItemWhereQueryParamParameterStepQtyOneOfNot;
};

export type GetOneItemWhereQueryParamParameterCostGstOneOfNotOneOf = null;

export type GetOneItemWhereQueryParamParameterCostGstOneOfNot =
  | GetOneItemWhereQueryParamParameterCostGstOneOfNotOneOf
  | number
  | CostGst;

export type GetOneItemWhereQueryParamParameterCostGstOneOfNotIn = number | number[];

export type GetOneItemWhereQueryParamParameterCostGstOneOfIn = number | number[];

export type GetOneItemWhereQueryParamParameterCostGstOneOf = {
  equals?: number | null;
  in?: GetOneItemWhereQueryParamParameterCostGstOneOfIn;
  notIn?: GetOneItemWhereQueryParamParameterCostGstOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: GetOneItemWhereQueryParamParameterCostGstOneOfNot;
};

export type GetOneItemWhereQueryParamParameterCostGst = number | GetOneItemWhereQueryParamParameterCostGstOneOf;

export type GetOneItemWhereQueryParamParameterCostExOneOfNot =
  | GetOneItemWhereQueryParamParameterCostExOneOfNotOneOf
  | number
  | CostEx;

export type GetOneItemWhereQueryParamParameterCostExOneOf = {
  equals?: number | null;
  in?: GetOneItemWhereQueryParamParameterCostExOneOfIn;
  notIn?: GetOneItemWhereQueryParamParameterCostExOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: GetOneItemWhereQueryParamParameterCostExOneOfNot;
};

export type GetOneItemWhereQueryParamParameterCostEx = number | GetOneItemWhereQueryParamParameterCostExOneOf;

export type GetOneItemWhereQueryParamParameterCostExOneOfNotOneOf = null;

export type GetOneItemWhereQueryParamParameterCostExOneOfNotIn = number | number[];

export type GetOneItemWhereQueryParamParameterCostExOneOfIn = number | number[];

export type GetOneItemWhereQueryParamParameterSellGst = number | GetOneItemWhereQueryParamParameterSellGstOneOf;

export type GetOneItemWhereQueryParamParameterSellGstOneOfNotOneOf = null;

export type GetOneItemWhereQueryParamParameterSellGstOneOfNot =
  | GetOneItemWhereQueryParamParameterSellGstOneOfNotOneOf
  | number
  | SellGst;

export type GetOneItemWhereQueryParamParameterSellGstOneOfNotIn = number | number[];

export type GetOneItemWhereQueryParamParameterSellGstOneOfIn = number | number[];

export type GetOneItemWhereQueryParamParameterSellGstOneOf = {
  equals?: number | null;
  in?: GetOneItemWhereQueryParamParameterSellGstOneOfIn;
  notIn?: GetOneItemWhereQueryParamParameterSellGstOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: GetOneItemWhereQueryParamParameterSellGstOneOfNot;
};

export type GetOneItemWhereQueryParamParameterSellEx = number | GetOneItemWhereQueryParamParameterSellExOneOf;

export type GetOneItemWhereQueryParamParameterSellExOneOfNotOneOf = null;

export type GetOneItemWhereQueryParamParameterSellExOneOfNot =
  | GetOneItemWhereQueryParamParameterSellExOneOfNotOneOf
  | number
  | SellEx;

export type GetOneItemWhereQueryParamParameterSellExOneOfNotIn = number | number[];

export type GetOneItemWhereQueryParamParameterSellExOneOfIn = number | number[];

export type GetOneItemWhereQueryParamParameterSellExOneOf = {
  equals?: number | null;
  in?: GetOneItemWhereQueryParamParameterSellExOneOfIn;
  notIn?: GetOneItemWhereQueryParamParameterSellExOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: GetOneItemWhereQueryParamParameterSellExOneOfNot;
};

export type GetOneItemWhereQueryParamParameterSku = string | GetOneItemWhereQueryParamParameterSkuOneOf;

export type GetOneItemWhereQueryParamParameterSkuOneOfNot = string | Sku;

export type GetOneItemWhereQueryParamParameterSkuOneOfMode =
  typeof GetOneItemWhereQueryParamParameterSkuOneOfMode[keyof typeof GetOneItemWhereQueryParamParameterSkuOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneItemWhereQueryParamParameterSkuOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneItemWhereQueryParamParameterSkuOneOfNotIn = string | string[];

export type GetOneItemWhereQueryParamParameterSkuOneOfIn = string | string[];

export type GetOneItemWhereQueryParamParameterSkuOneOfEquals = string;

export type GetOneItemWhereQueryParamParameterSkuOneOf = {
  equals?: GetOneItemWhereQueryParamParameterSkuOneOfEquals;
  in?: GetOneItemWhereQueryParamParameterSkuOneOfIn;
  notIn?: GetOneItemWhereQueryParamParameterSkuOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneItemWhereQueryParamParameterSkuOneOfMode;
  not?: GetOneItemWhereQueryParamParameterSkuOneOfNot;
};

export type GetOneItemWhereQueryParamParameterDescriptionOneOfNot = string | Description;

export type GetOneItemWhereQueryParamParameterDescriptionOneOfMode =
  typeof GetOneItemWhereQueryParamParameterDescriptionOneOfMode[keyof typeof GetOneItemWhereQueryParamParameterDescriptionOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneItemWhereQueryParamParameterDescriptionOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneItemWhereQueryParamParameterDescriptionOneOfNotIn = string | string[];

export type GetOneItemWhereQueryParamParameterDescriptionOneOfIn = string | string[];

export type GetOneItemWhereQueryParamParameterDescriptionOneOfEquals = string;

export type GetOneItemWhereQueryParamParameterDescriptionOneOf = {
  equals?: GetOneItemWhereQueryParamParameterDescriptionOneOfEquals;
  in?: GetOneItemWhereQueryParamParameterDescriptionOneOfIn;
  notIn?: GetOneItemWhereQueryParamParameterDescriptionOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneItemWhereQueryParamParameterDescriptionOneOfMode;
  not?: GetOneItemWhereQueryParamParameterDescriptionOneOfNot;
};

export type GetOneItemWhereQueryParamParameterDescription = string | GetOneItemWhereQueryParamParameterDescriptionOneOf;

export type GetOneItemWhereQueryParamParameterName = string | GetOneItemWhereQueryParamParameterNameOneOf;

export type GetOneItemWhereQueryParamParameterNameOneOfNot = string | Name;

export type GetOneItemWhereQueryParamParameterNameOneOfMode =
  typeof GetOneItemWhereQueryParamParameterNameOneOfMode[keyof typeof GetOneItemWhereQueryParamParameterNameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneItemWhereQueryParamParameterNameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneItemWhereQueryParamParameterNameOneOfNotIn = string | string[];

export type GetOneItemWhereQueryParamParameterNameOneOfIn = string | string[];

export type GetOneItemWhereQueryParamParameterNameOneOf = {
  equals?: string | null;
  in?: GetOneItemWhereQueryParamParameterNameOneOfIn;
  notIn?: GetOneItemWhereQueryParamParameterNameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneItemWhereQueryParamParameterNameOneOfMode;
  not?: GetOneItemWhereQueryParamParameterNameOneOfNot;
};

export type GetOneItemWhereQueryParamParameterIdOneOfNot = string | Id;

export type GetOneItemWhereQueryParamParameterIdOneOfMode =
  typeof GetOneItemWhereQueryParamParameterIdOneOfMode[keyof typeof GetOneItemWhereQueryParamParameterIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneItemWhereQueryParamParameterIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneItemWhereQueryParamParameterIdOneOfNotIn = string | string[];

export type GetOneItemWhereQueryParamParameterIdOneOfIn = string | string[];

export type GetOneItemWhereQueryParamParameterIdOneOf = {
  equals?: string | null;
  in?: GetOneItemWhereQueryParamParameterIdOneOfIn;
  notIn?: GetOneItemWhereQueryParamParameterIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneItemWhereQueryParamParameterIdOneOfMode;
  not?: GetOneItemWhereQueryParamParameterIdOneOfNot;
};

export type GetOneItemWhereQueryParamParameterId = string | GetOneItemWhereQueryParamParameterIdOneOf;

export type GetOneItemWhereQueryParamParameterNot = ItemWhere | ItemWhere[];

export type GetOneItemWhereQueryParamParameterOr = ItemWhere | ItemWhere[];

export type GetOneItemWhereQueryParamParameterAnd = ItemWhere | ItemWhere[];

export type GetOneItemIncludeQueryParamParameterOneOf = null;

export type GetOneItemIncludeQueryParamParameter = GetOneItemIncludeQueryParamParameterOneOf | ItemInclude;

export type GetOneItemSelectQueryParamParameterOneOf = null;

export type GetOneItemSelectQueryParamParameter = GetOneItemSelectQueryParamParameterOneOf | ItemSelect;

/**
 * Query params for get-item-by-id}
 */
export type GetItemByIdWithTrashedQueryParamParameter = boolean | null;

export type GetItemByIdIncludeQueryParamParameterOneOf = null;

export type GetItemByIdIncludeQueryParamParameter = GetItemByIdIncludeQueryParamParameterOneOf | ItemInclude;

export type GetItemByIdSelectQueryParamParameterOneOf = null;

export type GetItemByIdSelectQueryParamParameter = GetItemByIdSelectQueryParamParameterOneOf | ItemSelect;

/**
 * Query params for get-calculated-shipping}
 */
export type GetCalculatedShippingItemIdsQueryParamParameter = string[];

/**
 * Query params for get-many-categories}
 */
export type GetManyCategoriesWithTrashedQueryParamParameter = boolean | null;

export type GetManyCategoriesWhereQueryParamParameterOneOf = null;

export type GetManyCategoriesWhereQueryParamParameter = GetManyCategoriesWhereQueryParamParameterOneOf | CategoryWhere;

export type GetManyCategoriesIncludeQueryParamParameterOneOf = null;

export type GetManyCategoriesIncludeQueryParamParameter =
  | GetManyCategoriesIncludeQueryParamParameterOneOf
  | CategoryInclude;

export type GetManyCategoriesSelectQueryParamParameterOneOf = null;

export type GetManyCategoriesSelectQueryParamParameter =
  | GetManyCategoriesSelectQueryParamParameterOneOf
  | CategorySelect;

/**
 * Query params for get-one-category}
 */
export type GetOneCategoryWithTrashedQueryParamParameter = boolean | null;

export type GetOneCategoryWhereQueryParamParameter = {
  AND?: GetOneCategoryWhereQueryParamParameterAnd;
  OR?: GetOneCategoryWhereQueryParamParameterOr;
  NOT?: GetOneCategoryWhereQueryParamParameterNot;
  id?: GetOneCategoryWhereQueryParamParameterId;
  name?: GetOneCategoryWhereQueryParamParameterName;
  slug?: GetOneCategoryWhereQueryParamParameterSlug;
  createdAt?: GetOneCategoryWhereQueryParamParameterCreatedAt;
  updatedAt?: GetOneCategoryWhereQueryParamParameterUpdatedAt;
  deletedAt?: GetOneCategoryWhereQueryParamParameterDeletedAt;
  products?: GetOneCategoryWhereQueryParamParameterProducts;
};

export type GetOneCategoryWhereQueryParamParameterProductsNone = ProductWhere;

export type GetOneCategoryWhereQueryParamParameterProductsEvery = ProductWhere;

export type GetOneCategoryWhereQueryParamParameterProductsSome = ProductWhere;

export type GetOneCategoryWhereQueryParamParameterProducts = {
  some?: GetOneCategoryWhereQueryParamParameterProductsSome;
  every?: GetOneCategoryWhereQueryParamParameterProductsEvery;
  none?: GetOneCategoryWhereQueryParamParameterProductsNone;
} | null;

export type GetOneCategoryWhereQueryParamParameterDeletedAt =
  | string
  | GetOneCategoryWhereQueryParamParameterDeletedAtOneOf;

export type GetOneCategoryWhereQueryParamParameterDeletedAtOneOfNot = string | DeletedAt;

export type GetOneCategoryWhereQueryParamParameterDeletedAtOneOfNotIn = string | string[];

export type GetOneCategoryWhereQueryParamParameterDeletedAtOneOfIn = string | string[];

export type GetOneCategoryWhereQueryParamParameterDeletedAtOneOfEquals = string;

export type GetOneCategoryWhereQueryParamParameterDeletedAtOneOf = {
  equals?: GetOneCategoryWhereQueryParamParameterDeletedAtOneOfEquals;
  in?: GetOneCategoryWhereQueryParamParameterDeletedAtOneOfIn;
  notIn?: GetOneCategoryWhereQueryParamParameterDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneCategoryWhereQueryParamParameterDeletedAtOneOfNot;
};

export type GetOneCategoryWhereQueryParamParameterUpdatedAtOneOfNot = string | UpdatedAt;

export type GetOneCategoryWhereQueryParamParameterUpdatedAtOneOfNotIn = string | string[];

export type GetOneCategoryWhereQueryParamParameterUpdatedAtOneOfIn = string | string[];

export type GetOneCategoryWhereQueryParamParameterUpdatedAtOneOf = {
  equals?: string | null;
  in?: GetOneCategoryWhereQueryParamParameterUpdatedAtOneOfIn;
  notIn?: GetOneCategoryWhereQueryParamParameterUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneCategoryWhereQueryParamParameterUpdatedAtOneOfNot;
};

export type GetOneCategoryWhereQueryParamParameterUpdatedAt =
  | string
  | GetOneCategoryWhereQueryParamParameterUpdatedAtOneOf;

export type GetOneCategoryWhereQueryParamParameterCreatedAt =
  | string
  | GetOneCategoryWhereQueryParamParameterCreatedAtOneOf;

export type GetOneCategoryWhereQueryParamParameterCreatedAtOneOfNot = string | CreatedAt;

export type GetOneCategoryWhereQueryParamParameterCreatedAtOneOfNotIn = string | string[];

export type GetOneCategoryWhereQueryParamParameterCreatedAtOneOfIn = string | string[];

export type GetOneCategoryWhereQueryParamParameterCreatedAtOneOf = {
  equals?: string | null;
  in?: GetOneCategoryWhereQueryParamParameterCreatedAtOneOfIn;
  notIn?: GetOneCategoryWhereQueryParamParameterCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneCategoryWhereQueryParamParameterCreatedAtOneOfNot;
};

export type GetOneCategoryWhereQueryParamParameterSlugOneOfNot = string | Slug;

export type GetOneCategoryWhereQueryParamParameterSlugOneOfMode =
  typeof GetOneCategoryWhereQueryParamParameterSlugOneOfMode[keyof typeof GetOneCategoryWhereQueryParamParameterSlugOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneCategoryWhereQueryParamParameterSlugOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneCategoryWhereQueryParamParameterSlugOneOfNotIn = string | string[];

export type GetOneCategoryWhereQueryParamParameterSlugOneOfIn = string | string[];

export type GetOneCategoryWhereQueryParamParameterSlugOneOf = {
  equals?: string | null;
  in?: GetOneCategoryWhereQueryParamParameterSlugOneOfIn;
  notIn?: GetOneCategoryWhereQueryParamParameterSlugOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneCategoryWhereQueryParamParameterSlugOneOfMode;
  not?: GetOneCategoryWhereQueryParamParameterSlugOneOfNot;
};

export type GetOneCategoryWhereQueryParamParameterSlug = string | GetOneCategoryWhereQueryParamParameterSlugOneOf;

export type GetOneCategoryWhereQueryParamParameterName = string | GetOneCategoryWhereQueryParamParameterNameOneOf;

export type GetOneCategoryWhereQueryParamParameterNameOneOfNot = string | Name;

export type GetOneCategoryWhereQueryParamParameterNameOneOfMode =
  typeof GetOneCategoryWhereQueryParamParameterNameOneOfMode[keyof typeof GetOneCategoryWhereQueryParamParameterNameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneCategoryWhereQueryParamParameterNameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneCategoryWhereQueryParamParameterNameOneOfNotIn = string | string[];

export type GetOneCategoryWhereQueryParamParameterNameOneOfIn = string | string[];

export type GetOneCategoryWhereQueryParamParameterNameOneOf = {
  equals?: string | null;
  in?: GetOneCategoryWhereQueryParamParameterNameOneOfIn;
  notIn?: GetOneCategoryWhereQueryParamParameterNameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneCategoryWhereQueryParamParameterNameOneOfMode;
  not?: GetOneCategoryWhereQueryParamParameterNameOneOfNot;
};

export type GetOneCategoryWhereQueryParamParameterId = string | GetOneCategoryWhereQueryParamParameterIdOneOf;

export type GetOneCategoryWhereQueryParamParameterIdOneOfNot = string | Id;

export type GetOneCategoryWhereQueryParamParameterIdOneOfMode =
  typeof GetOneCategoryWhereQueryParamParameterIdOneOfMode[keyof typeof GetOneCategoryWhereQueryParamParameterIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneCategoryWhereQueryParamParameterIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneCategoryWhereQueryParamParameterIdOneOfNotIn = string | string[];

export type GetOneCategoryWhereQueryParamParameterIdOneOfIn = string | string[];

export type GetOneCategoryWhereQueryParamParameterIdOneOf = {
  equals?: string | null;
  in?: GetOneCategoryWhereQueryParamParameterIdOneOfIn;
  notIn?: GetOneCategoryWhereQueryParamParameterIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneCategoryWhereQueryParamParameterIdOneOfMode;
  not?: GetOneCategoryWhereQueryParamParameterIdOneOfNot;
};

export type GetOneCategoryWhereQueryParamParameterNot = CategoryWhere | CategoryWhere[];

export type GetOneCategoryWhereQueryParamParameterOr = CategoryWhere | CategoryWhere[];

export type GetOneCategoryWhereQueryParamParameterAnd = CategoryWhere | CategoryWhere[];

export type GetOneCategoryIncludeQueryParamParameterOneOf = null;

export type GetOneCategoryIncludeQueryParamParameter = GetOneCategoryIncludeQueryParamParameterOneOf | CategoryInclude;

export type GetOneCategorySelectQueryParamParameterOneOf = null;

export type GetOneCategorySelectQueryParamParameter = GetOneCategorySelectQueryParamParameterOneOf | CategorySelect;

/**
 * Query params for get-category-by-id}
 */
export type GetCategoryByIdWithTrashedQueryParamParameter = boolean | null;

export type GetCategoryByIdIncludeQueryParamParameterOneOf = null;

export type GetCategoryByIdIncludeQueryParamParameter =
  | GetCategoryByIdIncludeQueryParamParameterOneOf
  | CategoryInclude;

export type GetCategoryByIdSelectQueryParamParameterOneOf = null;

export type GetCategoryByIdSelectQueryParamParameter = GetCategoryByIdSelectQueryParamParameterOneOf | CategorySelect;

/**
 * Query params for get-many-product-variants}
 */
export type GetManyProductVariantsWithTrashedQueryParamParameter = boolean | null;

export type GetManyProductVariantsWhereQueryParamParameterOneOf = null;

export type GetManyProductVariantsWhereQueryParamParameter =
  | GetManyProductVariantsWhereQueryParamParameterOneOf
  | ProductVariantWhere;

export type GetManyProductVariantsIncludeQueryParamParameterOneOf = null;

export type GetManyProductVariantsIncludeQueryParamParameter =
  | GetManyProductVariantsIncludeQueryParamParameterOneOf
  | ProductVariantInclude;

export type GetManyProductVariantsSelectQueryParamParameterOneOf = null;

export type GetManyProductVariantsSelectQueryParamParameter =
  | GetManyProductVariantsSelectQueryParamParameterOneOf
  | ProductVariantSelect;

/**
 * Query params for get-one-product-variant}
 */
export type GetOneProductVariantWithTrashedQueryParamParameter = boolean | null;

export type GetOneProductVariantWhereQueryParamParameterMembershipFrequency =
  | MembershipFrequencyWhere
  | GetOneProductVariantWhereQueryParamParameterMembershipFrequencyOneOf;

export type GetOneProductVariantWhereQueryParamParameterMembershipFrequencyOneOfIsNot = MembershipFrequencyWhere;

export type GetOneProductVariantWhereQueryParamParameterMembershipFrequencyOneOfIs = MembershipFrequencyWhere;

export type GetOneProductVariantWhereQueryParamParameterMembershipFrequencyOneOf = {
  is?: GetOneProductVariantWhereQueryParamParameterMembershipFrequencyOneOfIs;
  isNot?: GetOneProductVariantWhereQueryParamParameterMembershipFrequencyOneOfIsNot;
};

export type GetOneProductVariantWhereQueryParamParameterProductOneOfIsNot = ProductWhere;

export type GetOneProductVariantWhereQueryParamParameterProductOneOfIs = ProductWhere;

export type GetOneProductVariantWhereQueryParamParameterProductOneOf = {
  is?: GetOneProductVariantWhereQueryParamParameterProductOneOfIs;
  isNot?: GetOneProductVariantWhereQueryParamParameterProductOneOfIsNot;
};

export type GetOneProductVariantWhereQueryParamParameterProduct =
  | ProductWhere
  | GetOneProductVariantWhereQueryParamParameterProductOneOf;

export type GetOneProductVariantWhereQueryParamParameterItemOneOfIsNot = ItemWhere;

export type GetOneProductVariantWhereQueryParamParameterItemOneOfIs = ItemWhere;

export type GetOneProductVariantWhereQueryParamParameterItemOneOf = {
  is?: GetOneProductVariantWhereQueryParamParameterItemOneOfIs;
  isNot?: GetOneProductVariantWhereQueryParamParameterItemOneOfIsNot;
};

export type GetOneProductVariantWhereQueryParamParameterItem =
  | ItemWhere
  | GetOneProductVariantWhereQueryParamParameterItemOneOf;

export type GetOneProductVariantWhereQueryParamParameterDeletedAtOneOf = {
  equals?: GetOneProductVariantWhereQueryParamParameterDeletedAtOneOfEquals;
  in?: GetOneProductVariantWhereQueryParamParameterDeletedAtOneOfIn;
  notIn?: GetOneProductVariantWhereQueryParamParameterDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneProductVariantWhereQueryParamParameterDeletedAtOneOfNot;
};

export type GetOneProductVariantWhereQueryParamParameterDeletedAt =
  | string
  | GetOneProductVariantWhereQueryParamParameterDeletedAtOneOf;

export type GetOneProductVariantWhereQueryParamParameter = {
  AND?: GetOneProductVariantWhereQueryParamParameterAnd;
  OR?: GetOneProductVariantWhereQueryParamParameterOr;
  NOT?: GetOneProductVariantWhereQueryParamParameterNot;
  id?: GetOneProductVariantWhereQueryParamParameterId;
  name?: GetOneProductVariantWhereQueryParamParameterName;
  selectedOptions?: GetOneProductVariantWhereQueryParamParameterSelectedOptions;
  itemId?: GetOneProductVariantWhereQueryParamParameterItemId;
  productId?: GetOneProductVariantWhereQueryParamParameterProductId;
  createdAt?: GetOneProductVariantWhereQueryParamParameterCreatedAt;
  updatedAt?: GetOneProductVariantWhereQueryParamParameterUpdatedAt;
  deletedAt?: GetOneProductVariantWhereQueryParamParameterDeletedAt;
  item?: GetOneProductVariantWhereQueryParamParameterItem;
  product?: GetOneProductVariantWhereQueryParamParameterProduct;
  membershipFrequency?: GetOneProductVariantWhereQueryParamParameterMembershipFrequency;
};

export type GetOneProductVariantWhereQueryParamParameterDeletedAtOneOfNot = string | DeletedAt;

export type GetOneProductVariantWhereQueryParamParameterDeletedAtOneOfNotIn = string | string[];

export type GetOneProductVariantWhereQueryParamParameterDeletedAtOneOfIn = string | string[];

export type GetOneProductVariantWhereQueryParamParameterDeletedAtOneOfEquals = string;

export type GetOneProductVariantWhereQueryParamParameterUpdatedAtOneOfNot = string | UpdatedAt;

export type GetOneProductVariantWhereQueryParamParameterUpdatedAtOneOfNotIn = string | string[];

export type GetOneProductVariantWhereQueryParamParameterUpdatedAtOneOfIn = string | string[];

export type GetOneProductVariantWhereQueryParamParameterUpdatedAtOneOf = {
  equals?: string | null;
  in?: GetOneProductVariantWhereQueryParamParameterUpdatedAtOneOfIn;
  notIn?: GetOneProductVariantWhereQueryParamParameterUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneProductVariantWhereQueryParamParameterUpdatedAtOneOfNot;
};

export type GetOneProductVariantWhereQueryParamParameterUpdatedAt =
  | string
  | GetOneProductVariantWhereQueryParamParameterUpdatedAtOneOf;

export type GetOneProductVariantWhereQueryParamParameterCreatedAtOneOfNot = string | CreatedAt;

export type GetOneProductVariantWhereQueryParamParameterCreatedAtOneOfNotIn = string | string[];

export type GetOneProductVariantWhereQueryParamParameterCreatedAtOneOfIn = string | string[];

export type GetOneProductVariantWhereQueryParamParameterCreatedAtOneOf = {
  equals?: string | null;
  in?: GetOneProductVariantWhereQueryParamParameterCreatedAtOneOfIn;
  notIn?: GetOneProductVariantWhereQueryParamParameterCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneProductVariantWhereQueryParamParameterCreatedAtOneOfNot;
};

export type GetOneProductVariantWhereQueryParamParameterCreatedAt =
  | string
  | GetOneProductVariantWhereQueryParamParameterCreatedAtOneOf;

export type GetOneProductVariantWhereQueryParamParameterProductIdOneOfNot = string | ProductId;

export type GetOneProductVariantWhereQueryParamParameterProductIdOneOfMode =
  typeof GetOneProductVariantWhereQueryParamParameterProductIdOneOfMode[keyof typeof GetOneProductVariantWhereQueryParamParameterProductIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneProductVariantWhereQueryParamParameterProductIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneProductVariantWhereQueryParamParameterProductIdOneOfNotIn = string | string[];

export type GetOneProductVariantWhereQueryParamParameterProductIdOneOfIn = string | string[];

export type GetOneProductVariantWhereQueryParamParameterProductIdOneOf = {
  equals?: string | null;
  in?: GetOneProductVariantWhereQueryParamParameterProductIdOneOfIn;
  notIn?: GetOneProductVariantWhereQueryParamParameterProductIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneProductVariantWhereQueryParamParameterProductIdOneOfMode;
  not?: GetOneProductVariantWhereQueryParamParameterProductIdOneOfNot;
};

export type GetOneProductVariantWhereQueryParamParameterProductId =
  | string
  | GetOneProductVariantWhereQueryParamParameterProductIdOneOf;

export type GetOneProductVariantWhereQueryParamParameterItemId =
  | string
  | GetOneProductVariantWhereQueryParamParameterItemIdOneOf;

export type GetOneProductVariantWhereQueryParamParameterItemIdOneOfNot = string | ItemId;

export type GetOneProductVariantWhereQueryParamParameterItemIdOneOfMode =
  typeof GetOneProductVariantWhereQueryParamParameterItemIdOneOfMode[keyof typeof GetOneProductVariantWhereQueryParamParameterItemIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneProductVariantWhereQueryParamParameterItemIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneProductVariantWhereQueryParamParameterItemIdOneOfNotIn = string | string[];

export type GetOneProductVariantWhereQueryParamParameterItemIdOneOfIn = string | string[];

export type GetOneProductVariantWhereQueryParamParameterItemIdOneOf = {
  equals?: string | null;
  in?: GetOneProductVariantWhereQueryParamParameterItemIdOneOfIn;
  notIn?: GetOneProductVariantWhereQueryParamParameterItemIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneProductVariantWhereQueryParamParameterItemIdOneOfMode;
  not?: GetOneProductVariantWhereQueryParamParameterItemIdOneOfNot;
};

export type GetOneProductVariantWhereQueryParamParameterSelectedOptionsOneOfPath = string | string[];

export type GetOneProductVariantWhereQueryParamParameterSelectedOptionsOneOf = {
  path?: GetOneProductVariantWhereQueryParamParameterSelectedOptionsOneOfPath;
  equals?: unknown;
  not?: unknown;
  string_contains?: string | null;
  string_starts_with?: string | null;
  string_ends_with?: string | null;
  array_contains?: unknown;
  array_starts_with?: unknown;
  array_ends_with?: unknown;
};

export type GetOneProductVariantWhereQueryParamParameterSelectedOptions =
  GetOneProductVariantWhereQueryParamParameterSelectedOptionsOneOf;

export type GetOneProductVariantWhereQueryParamParameterName =
  | string
  | GetOneProductVariantWhereQueryParamParameterNameOneOf;

export type GetOneProductVariantWhereQueryParamParameterNameOneOfNot = string | Name;

export type GetOneProductVariantWhereQueryParamParameterNameOneOfMode =
  typeof GetOneProductVariantWhereQueryParamParameterNameOneOfMode[keyof typeof GetOneProductVariantWhereQueryParamParameterNameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneProductVariantWhereQueryParamParameterNameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneProductVariantWhereQueryParamParameterNameOneOfNotIn = string | string[];

export type GetOneProductVariantWhereQueryParamParameterNameOneOfIn = string | string[];

export type GetOneProductVariantWhereQueryParamParameterNameOneOf = {
  equals?: string | null;
  in?: GetOneProductVariantWhereQueryParamParameterNameOneOfIn;
  notIn?: GetOneProductVariantWhereQueryParamParameterNameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneProductVariantWhereQueryParamParameterNameOneOfMode;
  not?: GetOneProductVariantWhereQueryParamParameterNameOneOfNot;
};

export type GetOneProductVariantWhereQueryParamParameterIdOneOfNot = string | Id;

export type GetOneProductVariantWhereQueryParamParameterIdOneOfMode =
  typeof GetOneProductVariantWhereQueryParamParameterIdOneOfMode[keyof typeof GetOneProductVariantWhereQueryParamParameterIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneProductVariantWhereQueryParamParameterIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneProductVariantWhereQueryParamParameterIdOneOfNotIn = string | string[];

export type GetOneProductVariantWhereQueryParamParameterIdOneOfIn = string | string[];

export type GetOneProductVariantWhereQueryParamParameterIdOneOf = {
  equals?: string | null;
  in?: GetOneProductVariantWhereQueryParamParameterIdOneOfIn;
  notIn?: GetOneProductVariantWhereQueryParamParameterIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneProductVariantWhereQueryParamParameterIdOneOfMode;
  not?: GetOneProductVariantWhereQueryParamParameterIdOneOfNot;
};

export type GetOneProductVariantWhereQueryParamParameterId =
  | string
  | GetOneProductVariantWhereQueryParamParameterIdOneOf;

export type GetOneProductVariantWhereQueryParamParameterNot = ProductVariantWhere | ProductVariantWhere[];

export type GetOneProductVariantWhereQueryParamParameterOr = ProductVariantWhere | ProductVariantWhere[];

export type GetOneProductVariantWhereQueryParamParameterAnd = ProductVariantWhere | ProductVariantWhere[];

export type GetOneProductVariantIncludeQueryParamParameterOneOf = null;

export type GetOneProductVariantIncludeQueryParamParameter =
  | GetOneProductVariantIncludeQueryParamParameterOneOf
  | ProductVariantInclude;

export type GetOneProductVariantSelectQueryParamParameterOneOf = null;

export type GetOneProductVariantSelectQueryParamParameter =
  | GetOneProductVariantSelectQueryParamParameterOneOf
  | ProductVariantSelect;

/**
 * Query params for get-product-variant-by-id}
 */
export type GetProductVariantByIdWithTrashedQueryParamParameter = boolean | null;

export type GetProductVariantByIdIncludeQueryParamParameterOneOf = null;

export type GetProductVariantByIdIncludeQueryParamParameter =
  | GetProductVariantByIdIncludeQueryParamParameterOneOf
  | ProductVariantInclude;

export type GetProductVariantByIdSelectQueryParamParameterOneOf = null;

export type GetProductVariantByIdSelectQueryParamParameter =
  | GetProductVariantByIdSelectQueryParamParameterOneOf
  | ProductVariantSelect;

/**
 * Query params for get-many-products}
 */
export type GetManyProductsWithTrashedQueryParamParameter = boolean | null;

export type GetManyProductsWhereQueryParamParameterOneOf = null;

export type GetManyProductsWhereQueryParamParameter = GetManyProductsWhereQueryParamParameterOneOf | ProductWhere;

export type GetManyProductsIncludeQueryParamParameterOneOf = null;

export type GetManyProductsIncludeQueryParamParameter = GetManyProductsIncludeQueryParamParameterOneOf | ProductInclude;

export type GetManyProductsSelectQueryParamParameterOneOf = null;

export type GetManyProductsSelectQueryParamParameter = GetManyProductsSelectQueryParamParameterOneOf | ProductSelect;

/**
 * Query params for get-one-product}
 */
export type GetOneProductWithTrashedQueryParamParameter = boolean | null;

export type GetOneProductWhereQueryParamParameterFilesNone = FileWhere;

export type GetOneProductWhereQueryParamParameterFilesEvery = FileWhere;

export type GetOneProductWhereQueryParamParameterFilesSome = FileWhere;

export type GetOneProductWhereQueryParamParameterFiles = {
  some?: GetOneProductWhereQueryParamParameterFilesSome;
  every?: GetOneProductWhereQueryParamParameterFilesEvery;
  none?: GetOneProductWhereQueryParamParameterFilesNone;
} | null;

export type GetOneProductWhereQueryParamParameterVariantsNone = ProductVariantWhere;

export type GetOneProductWhereQueryParamParameterVariantsEvery = ProductVariantWhere;

export type GetOneProductWhereQueryParamParameterVariantsSome = ProductVariantWhere;

export type GetOneProductWhereQueryParamParameterVariants = {
  some?: GetOneProductWhereQueryParamParameterVariantsSome;
  every?: GetOneProductWhereQueryParamParameterVariantsEvery;
  none?: GetOneProductWhereQueryParamParameterVariantsNone;
} | null;

export type GetOneProductWhereQueryParamParameter = {
  AND?: GetOneProductWhereQueryParamParameterAnd;
  OR?: GetOneProductWhereQueryParamParameterOr;
  NOT?: GetOneProductWhereQueryParamParameterNot;
  id?: GetOneProductWhereQueryParamParameterId;
  name?: GetOneProductWhereQueryParamParameterName;
  type?: GetOneProductWhereQueryParamParameterType;
  itemId?: GetOneProductWhereQueryParamParameterItemId;
  description?: GetOneProductWhereQueryParamParameterDescription;
  pageData?: GetOneProductWhereQueryParamParameterPageData;
  slug?: GetOneProductWhereQueryParamParameterSlug;
  status?: GetOneProductWhereQueryParamParameterStatus;
  createdAt?: GetOneProductWhereQueryParamParameterCreatedAt;
  updatedAt?: GetOneProductWhereQueryParamParameterUpdatedAt;
  deletedAt?: GetOneProductWhereQueryParamParameterDeletedAt;
  item?: GetOneProductWhereQueryParamParameterItem;
  membershipTier?: GetOneProductWhereQueryParamParameterMembershipTier;
  categories?: GetOneProductWhereQueryParamParameterCategories;
  variants?: GetOneProductWhereQueryParamParameterVariants;
  files?: GetOneProductWhereQueryParamParameterFiles;
};

export type GetOneProductWhereQueryParamParameterCategoriesNone = CategoryWhere;

export type GetOneProductWhereQueryParamParameterCategoriesEvery = CategoryWhere;

export type GetOneProductWhereQueryParamParameterCategoriesSome = CategoryWhere;

export type GetOneProductWhereQueryParamParameterCategories = {
  some?: GetOneProductWhereQueryParamParameterCategoriesSome;
  every?: GetOneProductWhereQueryParamParameterCategoriesEvery;
  none?: GetOneProductWhereQueryParamParameterCategoriesNone;
} | null;

export type GetOneProductWhereQueryParamParameterMembershipTier =
  | MembershipTierWhere
  | GetOneProductWhereQueryParamParameterMembershipTierOneOf;

export type GetOneProductWhereQueryParamParameterMembershipTierOneOfIsNot = MembershipTierWhere;

export type GetOneProductWhereQueryParamParameterMembershipTierOneOfIs = MembershipTierWhere;

export type GetOneProductWhereQueryParamParameterMembershipTierOneOf = {
  is?: GetOneProductWhereQueryParamParameterMembershipTierOneOfIs;
  isNot?: GetOneProductWhereQueryParamParameterMembershipTierOneOfIsNot;
};

export type GetOneProductWhereQueryParamParameterItemOneOfIsNot = ItemWhere;

export type GetOneProductWhereQueryParamParameterItemOneOfIs = ItemWhere;

export type GetOneProductWhereQueryParamParameterItemOneOf = {
  is?: GetOneProductWhereQueryParamParameterItemOneOfIs;
  isNot?: GetOneProductWhereQueryParamParameterItemOneOfIsNot;
};

export type GetOneProductWhereQueryParamParameterItem = ItemWhere | GetOneProductWhereQueryParamParameterItemOneOf;

export type GetOneProductWhereQueryParamParameterDeletedAtOneOfNot = string | DeletedAt;

export type GetOneProductWhereQueryParamParameterDeletedAtOneOfNotIn = string | string[];

export type GetOneProductWhereQueryParamParameterDeletedAtOneOfIn = string | string[];

export type GetOneProductWhereQueryParamParameterDeletedAtOneOfEquals = string;

export type GetOneProductWhereQueryParamParameterDeletedAtOneOf = {
  equals?: GetOneProductWhereQueryParamParameterDeletedAtOneOfEquals;
  in?: GetOneProductWhereQueryParamParameterDeletedAtOneOfIn;
  notIn?: GetOneProductWhereQueryParamParameterDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneProductWhereQueryParamParameterDeletedAtOneOfNot;
};

export type GetOneProductWhereQueryParamParameterDeletedAt =
  | string
  | GetOneProductWhereQueryParamParameterDeletedAtOneOf;

export type GetOneProductWhereQueryParamParameterUpdatedAtOneOfNot = string | UpdatedAt;

export type GetOneProductWhereQueryParamParameterUpdatedAtOneOfNotIn = string | string[];

export type GetOneProductWhereQueryParamParameterUpdatedAtOneOfIn = string | string[];

export type GetOneProductWhereQueryParamParameterUpdatedAtOneOf = {
  equals?: string | null;
  in?: GetOneProductWhereQueryParamParameterUpdatedAtOneOfIn;
  notIn?: GetOneProductWhereQueryParamParameterUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneProductWhereQueryParamParameterUpdatedAtOneOfNot;
};

export type GetOneProductWhereQueryParamParameterUpdatedAt =
  | string
  | GetOneProductWhereQueryParamParameterUpdatedAtOneOf;

export type GetOneProductWhereQueryParamParameterCreatedAt =
  | string
  | GetOneProductWhereQueryParamParameterCreatedAtOneOf;

export type GetOneProductWhereQueryParamParameterCreatedAtOneOfNot = string | CreatedAt;

export type GetOneProductWhereQueryParamParameterCreatedAtOneOfNotIn = string | string[];

export type GetOneProductWhereQueryParamParameterCreatedAtOneOfIn = string | string[];

export type GetOneProductWhereQueryParamParameterCreatedAtOneOf = {
  equals?: string | null;
  in?: GetOneProductWhereQueryParamParameterCreatedAtOneOfIn;
  notIn?: GetOneProductWhereQueryParamParameterCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneProductWhereQueryParamParameterCreatedAtOneOfNot;
};

export type GetOneProductWhereQueryParamParameterStatus =
  | 'ACTIVE'
  | 'DISABLED'
  | GetOneProductWhereQueryParamParameterStatusOneOf;

export type GetOneProductWhereQueryParamParameterStatusOneOfNot = 'ACTIVE' | 'DISABLED' | Status;

export type GetOneProductWhereQueryParamParameterStatusOneOfNotIn =
  | 'ACTIVE'
  | 'DISABLED'
  | GetOneProductWhereQueryParamParameterStatusOneOfNotInOneOfItem[];

export type GetOneProductWhereQueryParamParameterStatusOneOf = {
  equals?: GetOneProductWhereQueryParamParameterStatusOneOfEquals;
  in?: GetOneProductWhereQueryParamParameterStatusOneOfIn;
  notIn?: GetOneProductWhereQueryParamParameterStatusOneOfNotIn;
  not?: GetOneProductWhereQueryParamParameterStatusOneOfNot;
} | null;

export type GetOneProductWhereQueryParamParameterStatusOneOfNotInOneOfItem =
  typeof GetOneProductWhereQueryParamParameterStatusOneOfNotInOneOfItem[keyof typeof GetOneProductWhereQueryParamParameterStatusOneOfNotInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneProductWhereQueryParamParameterStatusOneOfNotInOneOfItem = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type GetOneProductWhereQueryParamParameterStatusOneOfInOneOfItem =
  typeof GetOneProductWhereQueryParamParameterStatusOneOfInOneOfItem[keyof typeof GetOneProductWhereQueryParamParameterStatusOneOfInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneProductWhereQueryParamParameterStatusOneOfInOneOfItem = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type GetOneProductWhereQueryParamParameterStatusOneOfIn =
  | 'ACTIVE'
  | 'DISABLED'
  | GetOneProductWhereQueryParamParameterStatusOneOfInOneOfItem[];

export type GetOneProductWhereQueryParamParameterStatusOneOfEquals =
  typeof GetOneProductWhereQueryParamParameterStatusOneOfEquals[keyof typeof GetOneProductWhereQueryParamParameterStatusOneOfEquals];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneProductWhereQueryParamParameterStatusOneOfEquals = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type GetOneProductWhereQueryParamParameterSlug = string | GetOneProductWhereQueryParamParameterSlugOneOf;

export type GetOneProductWhereQueryParamParameterSlugOneOfNot = string | Slug;

export type GetOneProductWhereQueryParamParameterSlugOneOfMode =
  typeof GetOneProductWhereQueryParamParameterSlugOneOfMode[keyof typeof GetOneProductWhereQueryParamParameterSlugOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneProductWhereQueryParamParameterSlugOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneProductWhereQueryParamParameterSlugOneOfNotIn = string | string[];

export type GetOneProductWhereQueryParamParameterSlugOneOfIn = string | string[];

export type GetOneProductWhereQueryParamParameterSlugOneOf = {
  equals?: string | null;
  in?: GetOneProductWhereQueryParamParameterSlugOneOfIn;
  notIn?: GetOneProductWhereQueryParamParameterSlugOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneProductWhereQueryParamParameterSlugOneOfMode;
  not?: GetOneProductWhereQueryParamParameterSlugOneOfNot;
};

export type GetOneProductWhereQueryParamParameterPageDataOneOfPath = string | string[];

export type GetOneProductWhereQueryParamParameterPageDataOneOf = {
  path?: GetOneProductWhereQueryParamParameterPageDataOneOfPath;
  equals?: unknown;
  not?: unknown;
  string_contains?: string | null;
  string_starts_with?: string | null;
  string_ends_with?: string | null;
  array_contains?: unknown;
  array_starts_with?: unknown;
  array_ends_with?: unknown;
};

export type GetOneProductWhereQueryParamParameterPageData = GetOneProductWhereQueryParamParameterPageDataOneOf;

export type GetOneProductWhereQueryParamParameterDescriptionOneOfNot = string | Description;

export type GetOneProductWhereQueryParamParameterDescriptionOneOfMode =
  typeof GetOneProductWhereQueryParamParameterDescriptionOneOfMode[keyof typeof GetOneProductWhereQueryParamParameterDescriptionOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneProductWhereQueryParamParameterDescriptionOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneProductWhereQueryParamParameterDescriptionOneOfNotIn = string | string[];

export type GetOneProductWhereQueryParamParameterDescriptionOneOfIn = string | string[];

export type GetOneProductWhereQueryParamParameterDescriptionOneOfEquals = string;

export type GetOneProductWhereQueryParamParameterDescriptionOneOf = {
  equals?: GetOneProductWhereQueryParamParameterDescriptionOneOfEquals;
  in?: GetOneProductWhereQueryParamParameterDescriptionOneOfIn;
  notIn?: GetOneProductWhereQueryParamParameterDescriptionOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneProductWhereQueryParamParameterDescriptionOneOfMode;
  not?: GetOneProductWhereQueryParamParameterDescriptionOneOfNot;
};

export type GetOneProductWhereQueryParamParameterDescription =
  | string
  | GetOneProductWhereQueryParamParameterDescriptionOneOf;

export type GetOneProductWhereQueryParamParameterItemId = string | GetOneProductWhereQueryParamParameterItemIdOneOf;

export type GetOneProductWhereQueryParamParameterItemIdOneOfNot = string | ItemId;

export type GetOneProductWhereQueryParamParameterItemIdOneOfMode =
  typeof GetOneProductWhereQueryParamParameterItemIdOneOfMode[keyof typeof GetOneProductWhereQueryParamParameterItemIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneProductWhereQueryParamParameterItemIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneProductWhereQueryParamParameterItemIdOneOfNotIn = string | string[];

export type GetOneProductWhereQueryParamParameterItemIdOneOfIn = string | string[];

export type GetOneProductWhereQueryParamParameterItemIdOneOf = {
  equals?: string | null;
  in?: GetOneProductWhereQueryParamParameterItemIdOneOfIn;
  notIn?: GetOneProductWhereQueryParamParameterItemIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneProductWhereQueryParamParameterItemIdOneOfMode;
  not?: GetOneProductWhereQueryParamParameterItemIdOneOfNot;
};

export type GetOneProductWhereQueryParamParameterTypeOneOfNot = 'PURCHASE' | 'MEMBERSHIP' | 'HIDDEN' | Type;

export type GetOneProductWhereQueryParamParameterTypeOneOf = {
  equals?: GetOneProductWhereQueryParamParameterTypeOneOfEquals;
  in?: GetOneProductWhereQueryParamParameterTypeOneOfIn;
  notIn?: GetOneProductWhereQueryParamParameterTypeOneOfNotIn;
  not?: GetOneProductWhereQueryParamParameterTypeOneOfNot;
} | null;

export type GetOneProductWhereQueryParamParameterType =
  | 'PURCHASE'
  | 'MEMBERSHIP'
  | 'HIDDEN'
  | GetOneProductWhereQueryParamParameterTypeOneOf;

export type GetOneProductWhereQueryParamParameterTypeOneOfNotInOneOfItem =
  typeof GetOneProductWhereQueryParamParameterTypeOneOfNotInOneOfItem[keyof typeof GetOneProductWhereQueryParamParameterTypeOneOfNotInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneProductWhereQueryParamParameterTypeOneOfNotInOneOfItem = {
  PURCHASE: 'PURCHASE',
  MEMBERSHIP: 'MEMBERSHIP',
  HIDDEN: 'HIDDEN',
} as const;

export type GetOneProductWhereQueryParamParameterTypeOneOfNotIn =
  | 'PURCHASE'
  | 'MEMBERSHIP'
  | 'HIDDEN'
  | GetOneProductWhereQueryParamParameterTypeOneOfNotInOneOfItem[];

export type GetOneProductWhereQueryParamParameterTypeOneOfInOneOfItem =
  typeof GetOneProductWhereQueryParamParameterTypeOneOfInOneOfItem[keyof typeof GetOneProductWhereQueryParamParameterTypeOneOfInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneProductWhereQueryParamParameterTypeOneOfInOneOfItem = {
  PURCHASE: 'PURCHASE',
  MEMBERSHIP: 'MEMBERSHIP',
  HIDDEN: 'HIDDEN',
} as const;

export type GetOneProductWhereQueryParamParameterTypeOneOfIn =
  | 'PURCHASE'
  | 'MEMBERSHIP'
  | 'HIDDEN'
  | GetOneProductWhereQueryParamParameterTypeOneOfInOneOfItem[];

export type GetOneProductWhereQueryParamParameterTypeOneOfEquals =
  typeof GetOneProductWhereQueryParamParameterTypeOneOfEquals[keyof typeof GetOneProductWhereQueryParamParameterTypeOneOfEquals];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneProductWhereQueryParamParameterTypeOneOfEquals = {
  PURCHASE: 'PURCHASE',
  MEMBERSHIP: 'MEMBERSHIP',
  HIDDEN: 'HIDDEN',
} as const;

export type GetOneProductWhereQueryParamParameterNameOneOfNot = string | Name;

export type GetOneProductWhereQueryParamParameterNameOneOfMode =
  typeof GetOneProductWhereQueryParamParameterNameOneOfMode[keyof typeof GetOneProductWhereQueryParamParameterNameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneProductWhereQueryParamParameterNameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneProductWhereQueryParamParameterNameOneOfNotIn = string | string[];

export type GetOneProductWhereQueryParamParameterNameOneOfIn = string | string[];

export type GetOneProductWhereQueryParamParameterNameOneOf = {
  equals?: string | null;
  in?: GetOneProductWhereQueryParamParameterNameOneOfIn;
  notIn?: GetOneProductWhereQueryParamParameterNameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneProductWhereQueryParamParameterNameOneOfMode;
  not?: GetOneProductWhereQueryParamParameterNameOneOfNot;
};

export type GetOneProductWhereQueryParamParameterName = string | GetOneProductWhereQueryParamParameterNameOneOf;

export type GetOneProductWhereQueryParamParameterId = string | GetOneProductWhereQueryParamParameterIdOneOf;

export type GetOneProductWhereQueryParamParameterIdOneOfNot = string | Id;

export type GetOneProductWhereQueryParamParameterIdOneOfMode =
  typeof GetOneProductWhereQueryParamParameterIdOneOfMode[keyof typeof GetOneProductWhereQueryParamParameterIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneProductWhereQueryParamParameterIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneProductWhereQueryParamParameterIdOneOfNotIn = string | string[];

export type GetOneProductWhereQueryParamParameterIdOneOfIn = string | string[];

export type GetOneProductWhereQueryParamParameterIdOneOf = {
  equals?: string | null;
  in?: GetOneProductWhereQueryParamParameterIdOneOfIn;
  notIn?: GetOneProductWhereQueryParamParameterIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneProductWhereQueryParamParameterIdOneOfMode;
  not?: GetOneProductWhereQueryParamParameterIdOneOfNot;
};

export type GetOneProductWhereQueryParamParameterNot = ProductWhere | ProductWhere[];

export type GetOneProductWhereQueryParamParameterOr = ProductWhere | ProductWhere[];

export type GetOneProductWhereQueryParamParameterAnd = ProductWhere | ProductWhere[];

export type GetOneProductIncludeQueryParamParameterOneOf = null;

export type GetOneProductIncludeQueryParamParameter = GetOneProductIncludeQueryParamParameterOneOf | ProductInclude;

export type GetOneProductSelectQueryParamParameterOneOf = null;

export type GetOneProductSelectQueryParamParameter = GetOneProductSelectQueryParamParameterOneOf | ProductSelect;

/**
 * Query params for get-product-by-id}
 */
export type GetProductByIdWithTrashedQueryParamParameter = boolean | null;

export type GetProductByIdIncludeQueryParamParameterOneOf = null;

export type GetProductByIdIncludeQueryParamParameter = GetProductByIdIncludeQueryParamParameterOneOf | ProductInclude;

export type GetProductByIdSelectQueryParamParameterOneOf = null;

export type GetProductByIdSelectQueryParamParameter = GetProductByIdSelectQueryParamParameterOneOf | ProductSelect;

/**
 * Query params for get-many-membership-offering-items}
 */
export type GetManyMembershipOfferingItemsWithTrashedQueryParamParameter = boolean | null;

export type GetManyMembershipOfferingItemsWhereQueryParamParameterOneOf = null;

export type GetManyMembershipOfferingItemsWhereQueryParamParameter =
  | GetManyMembershipOfferingItemsWhereQueryParamParameterOneOf
  | MembershipOfferingItemWhere;

export type GetManyMembershipOfferingItemsIncludeQueryParamParameterOneOf = null;

export type GetManyMembershipOfferingItemsIncludeQueryParamParameter =
  | GetManyMembershipOfferingItemsIncludeQueryParamParameterOneOf
  | MembershipOfferingItemInclude;

export type GetManyMembershipOfferingItemsSelectQueryParamParameterOneOf = null;

export type GetManyMembershipOfferingItemsSelectQueryParamParameter =
  | GetManyMembershipOfferingItemsSelectQueryParamParameterOneOf
  | MembershipOfferingItemSelect;

/**
 * Query params for get-one-membership-offering-item}
 */
export type GetOneMembershipOfferingItemWithTrashedQueryParamParameter = boolean | null;

export type GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOffering =
  | MembershipOfferingWhere
  | GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingOneOf;

export type GetOneMembershipOfferingItemWhereQueryParamParameter = {
  AND?: GetOneMembershipOfferingItemWhereQueryParamParameterAnd;
  OR?: GetOneMembershipOfferingItemWhereQueryParamParameterOr;
  NOT?: GetOneMembershipOfferingItemWhereQueryParamParameterNot;
  id?: GetOneMembershipOfferingItemWhereQueryParamParameterId;
  membershipOfferingId?: GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingId;
  membershipOfferingCategoryId?: GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingCategoryId;
  itemId?: GetOneMembershipOfferingItemWhereQueryParamParameterItemId;
  quantity?: GetOneMembershipOfferingItemWhereQueryParamParameterQuantity;
  isSelected?: GetOneMembershipOfferingItemWhereQueryParamParameterIsSelected;
  createdAt?: GetOneMembershipOfferingItemWhereQueryParamParameterCreatedAt;
  updatedAt?: GetOneMembershipOfferingItemWhereQueryParamParameterUpdatedAt;
  deletedAt?: GetOneMembershipOfferingItemWhereQueryParamParameterDeletedAt;
  item?: GetOneMembershipOfferingItemWhereQueryParamParameterItem;
  membershipOfferingCategory?: GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingCategory;
  membershipOffering?: GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOffering;
};

export type GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingOneOfIsNot = MembershipOfferingWhere;

export type GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingOneOfIs = MembershipOfferingWhere;

export type GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingOneOf = {
  is?: GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingOneOfIs;
  isNot?: GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingOneOfIsNot;
};

export type GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingCategory =
  | MembershipOfferingCategoryWhere
  | GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingCategoryOneOf;

export type GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingCategoryOneOfIsNot =
  MembershipOfferingCategoryWhere;

export type GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingCategoryOneOfIs =
  MembershipOfferingCategoryWhere;

export type GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingCategoryOneOf = {
  is?: GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingCategoryOneOfIs;
  isNot?: GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingCategoryOneOfIsNot;
};

export type GetOneMembershipOfferingItemWhereQueryParamParameterItem =
  | ItemWhere
  | GetOneMembershipOfferingItemWhereQueryParamParameterItemOneOf;

export type GetOneMembershipOfferingItemWhereQueryParamParameterItemOneOfIsNot = ItemWhere;

export type GetOneMembershipOfferingItemWhereQueryParamParameterItemOneOfIs = ItemWhere;

export type GetOneMembershipOfferingItemWhereQueryParamParameterItemOneOf = {
  is?: GetOneMembershipOfferingItemWhereQueryParamParameterItemOneOfIs;
  isNot?: GetOneMembershipOfferingItemWhereQueryParamParameterItemOneOfIsNot;
};

export type GetOneMembershipOfferingItemWhereQueryParamParameterDeletedAt =
  | string
  | GetOneMembershipOfferingItemWhereQueryParamParameterDeletedAtOneOf;

export type GetOneMembershipOfferingItemWhereQueryParamParameterDeletedAtOneOfNot = string | DeletedAt;

export type GetOneMembershipOfferingItemWhereQueryParamParameterDeletedAtOneOfNotIn = string | string[];

export type GetOneMembershipOfferingItemWhereQueryParamParameterDeletedAtOneOfIn = string | string[];

export type GetOneMembershipOfferingItemWhereQueryParamParameterDeletedAtOneOfEquals = string;

export type GetOneMembershipOfferingItemWhereQueryParamParameterDeletedAtOneOf = {
  equals?: GetOneMembershipOfferingItemWhereQueryParamParameterDeletedAtOneOfEquals;
  in?: GetOneMembershipOfferingItemWhereQueryParamParameterDeletedAtOneOfIn;
  notIn?: GetOneMembershipOfferingItemWhereQueryParamParameterDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipOfferingItemWhereQueryParamParameterDeletedAtOneOfNot;
};

export type GetOneMembershipOfferingItemWhereQueryParamParameterUpdatedAt =
  | string
  | GetOneMembershipOfferingItemWhereQueryParamParameterUpdatedAtOneOf;

export type GetOneMembershipOfferingItemWhereQueryParamParameterUpdatedAtOneOfNot = string | UpdatedAt;

export type GetOneMembershipOfferingItemWhereQueryParamParameterUpdatedAtOneOfNotIn = string | string[];

export type GetOneMembershipOfferingItemWhereQueryParamParameterUpdatedAtOneOfIn = string | string[];

export type GetOneMembershipOfferingItemWhereQueryParamParameterUpdatedAtOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOfferingItemWhereQueryParamParameterUpdatedAtOneOfIn;
  notIn?: GetOneMembershipOfferingItemWhereQueryParamParameterUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipOfferingItemWhereQueryParamParameterUpdatedAtOneOfNot;
};

export type GetOneMembershipOfferingItemWhereQueryParamParameterCreatedAt =
  | string
  | GetOneMembershipOfferingItemWhereQueryParamParameterCreatedAtOneOf;

export type GetOneMembershipOfferingItemWhereQueryParamParameterCreatedAtOneOfNot = string | CreatedAt;

export type GetOneMembershipOfferingItemWhereQueryParamParameterCreatedAtOneOfNotIn = string | string[];

export type GetOneMembershipOfferingItemWhereQueryParamParameterCreatedAtOneOfIn = string | string[];

export type GetOneMembershipOfferingItemWhereQueryParamParameterCreatedAtOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOfferingItemWhereQueryParamParameterCreatedAtOneOfIn;
  notIn?: GetOneMembershipOfferingItemWhereQueryParamParameterCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipOfferingItemWhereQueryParamParameterCreatedAtOneOfNot;
};

export type GetOneMembershipOfferingItemWhereQueryParamParameterIsSelectedOneOfNot = boolean | IsSelected;

export type GetOneMembershipOfferingItemWhereQueryParamParameterIsSelectedOneOf = {
  equals?: boolean | null;
  not?: GetOneMembershipOfferingItemWhereQueryParamParameterIsSelectedOneOfNot;
};

export type GetOneMembershipOfferingItemWhereQueryParamParameterIsSelected =
  | boolean
  | GetOneMembershipOfferingItemWhereQueryParamParameterIsSelectedOneOf;

export type GetOneMembershipOfferingItemWhereQueryParamParameterQuantityOneOfNotOneOf = null;

export type GetOneMembershipOfferingItemWhereQueryParamParameterQuantityOneOfNot =
  | GetOneMembershipOfferingItemWhereQueryParamParameterQuantityOneOfNotOneOf
  | number
  | Quantity;

export type GetOneMembershipOfferingItemWhereQueryParamParameterQuantityOneOfNotIn = number | number[];

export type GetOneMembershipOfferingItemWhereQueryParamParameterQuantityOneOfIn = number | number[];

export type GetOneMembershipOfferingItemWhereQueryParamParameterQuantityOneOf = {
  equals?: number | null;
  in?: GetOneMembershipOfferingItemWhereQueryParamParameterQuantityOneOfIn;
  notIn?: GetOneMembershipOfferingItemWhereQueryParamParameterQuantityOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: GetOneMembershipOfferingItemWhereQueryParamParameterQuantityOneOfNot;
};

export type GetOneMembershipOfferingItemWhereQueryParamParameterQuantity =
  | number
  | GetOneMembershipOfferingItemWhereQueryParamParameterQuantityOneOf;

export type GetOneMembershipOfferingItemWhereQueryParamParameterItemId =
  | string
  | GetOneMembershipOfferingItemWhereQueryParamParameterItemIdOneOf;

export type GetOneMembershipOfferingItemWhereQueryParamParameterItemIdOneOfNot = string | ItemId;

export type GetOneMembershipOfferingItemWhereQueryParamParameterItemIdOneOfMode =
  typeof GetOneMembershipOfferingItemWhereQueryParamParameterItemIdOneOfMode[keyof typeof GetOneMembershipOfferingItemWhereQueryParamParameterItemIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOfferingItemWhereQueryParamParameterItemIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipOfferingItemWhereQueryParamParameterItemIdOneOfNotIn = string | string[];

export type GetOneMembershipOfferingItemWhereQueryParamParameterItemIdOneOfIn = string | string[];

export type GetOneMembershipOfferingItemWhereQueryParamParameterItemIdOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOfferingItemWhereQueryParamParameterItemIdOneOfIn;
  notIn?: GetOneMembershipOfferingItemWhereQueryParamParameterItemIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipOfferingItemWhereQueryParamParameterItemIdOneOfMode;
  not?: GetOneMembershipOfferingItemWhereQueryParamParameterItemIdOneOfNot;
};

export type GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingCategoryId =
  | string
  | GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingCategoryIdOneOf;

export type GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingCategoryIdOneOfNot =
  | string
  | MembershipOfferingCategoryId;

export type GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingCategoryIdOneOfMode =
  typeof GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingCategoryIdOneOfMode[keyof typeof GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingCategoryIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingCategoryIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingCategoryIdOneOfNotIn =
  | string
  | string[];

export type GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingCategoryIdOneOfIn = string | string[];

export type GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingCategoryIdOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingCategoryIdOneOfIn;
  notIn?: GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingCategoryIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingCategoryIdOneOfMode;
  not?: GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingCategoryIdOneOfNot;
};

export type GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingIdOneOfNot =
  | string
  | MembershipOfferingId;

export type GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingIdOneOfMode =
  typeof GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingIdOneOfMode[keyof typeof GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingIdOneOfNotIn = string | string[];

export type GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingIdOneOfIn = string | string[];

export type GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingIdOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingIdOneOfIn;
  notIn?: GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingIdOneOfMode;
  not?: GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingIdOneOfNot;
};

export type GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingId =
  | string
  | GetOneMembershipOfferingItemWhereQueryParamParameterMembershipOfferingIdOneOf;

export type GetOneMembershipOfferingItemWhereQueryParamParameterId =
  | string
  | GetOneMembershipOfferingItemWhereQueryParamParameterIdOneOf;

export type GetOneMembershipOfferingItemWhereQueryParamParameterIdOneOfNot = string | Id;

export type GetOneMembershipOfferingItemWhereQueryParamParameterIdOneOfMode =
  typeof GetOneMembershipOfferingItemWhereQueryParamParameterIdOneOfMode[keyof typeof GetOneMembershipOfferingItemWhereQueryParamParameterIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOfferingItemWhereQueryParamParameterIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipOfferingItemWhereQueryParamParameterIdOneOfNotIn = string | string[];

export type GetOneMembershipOfferingItemWhereQueryParamParameterIdOneOfIn = string | string[];

export type GetOneMembershipOfferingItemWhereQueryParamParameterIdOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOfferingItemWhereQueryParamParameterIdOneOfIn;
  notIn?: GetOneMembershipOfferingItemWhereQueryParamParameterIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipOfferingItemWhereQueryParamParameterIdOneOfMode;
  not?: GetOneMembershipOfferingItemWhereQueryParamParameterIdOneOfNot;
};

export type GetOneMembershipOfferingItemWhereQueryParamParameterNot =
  | MembershipOfferingItemWhere
  | MembershipOfferingItemWhere[];

export type GetOneMembershipOfferingItemWhereQueryParamParameterOr =
  | MembershipOfferingItemWhere
  | MembershipOfferingItemWhere[];

export type GetOneMembershipOfferingItemWhereQueryParamParameterAnd =
  | MembershipOfferingItemWhere
  | MembershipOfferingItemWhere[];

export type GetOneMembershipOfferingItemIncludeQueryParamParameterOneOf = null;

export type GetOneMembershipOfferingItemIncludeQueryParamParameter =
  | GetOneMembershipOfferingItemIncludeQueryParamParameterOneOf
  | MembershipOfferingItemInclude;

export type GetOneMembershipOfferingItemSelectQueryParamParameterOneOf = null;

export type GetOneMembershipOfferingItemSelectQueryParamParameter =
  | GetOneMembershipOfferingItemSelectQueryParamParameterOneOf
  | MembershipOfferingItemSelect;

/**
 * Query params for get-membership-offering-item-by-id}
 */
export type GetMembershipOfferingItemByIdWithTrashedQueryParamParameter = boolean | null;

export type GetMembershipOfferingItemByIdIncludeQueryParamParameterOneOf = null;

export type GetMembershipOfferingItemByIdIncludeQueryParamParameter =
  | GetMembershipOfferingItemByIdIncludeQueryParamParameterOneOf
  | MembershipOfferingItemInclude;

export type GetMembershipOfferingItemByIdSelectQueryParamParameterOneOf = null;

export type GetMembershipOfferingItemByIdSelectQueryParamParameter =
  | GetMembershipOfferingItemByIdSelectQueryParamParameterOneOf
  | MembershipOfferingItemSelect;

/**
 * Query params for get-many-membership-offering-categories}
 */
export type GetManyMembershipOfferingCategoriesWithTrashedQueryParamParameter = boolean | null;

export type GetManyMembershipOfferingCategoriesWhereQueryParamParameterOneOf = null;

export type GetManyMembershipOfferingCategoriesWhereQueryParamParameter =
  | GetManyMembershipOfferingCategoriesWhereQueryParamParameterOneOf
  | MembershipOfferingCategoryWhere;

export type GetManyMembershipOfferingCategoriesIncludeQueryParamParameterOneOf = null;

export type GetManyMembershipOfferingCategoriesIncludeQueryParamParameter =
  | GetManyMembershipOfferingCategoriesIncludeQueryParamParameterOneOf
  | MembershipOfferingCategoryInclude;

export type GetManyMembershipOfferingCategoriesSelectQueryParamParameterOneOf = null;

export type GetManyMembershipOfferingCategoriesSelectQueryParamParameter =
  | GetManyMembershipOfferingCategoriesSelectQueryParamParameterOneOf
  | MembershipOfferingCategorySelect;

/**
 * Query params for get-one-membership-offering-category}
 */
export type GetOneMembershipOfferingCategoryWithTrashedQueryParamParameter = boolean | null;

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterMembershipOfferingItemsNone =
  MembershipOfferingItemWhere;

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterMembershipOfferingItemsEvery =
  MembershipOfferingItemWhere;

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterMembershipOfferingItemsSome =
  MembershipOfferingItemWhere;

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterMembershipOfferingItems = {
  some?: GetOneMembershipOfferingCategoryWhereQueryParamParameterMembershipOfferingItemsSome;
  every?: GetOneMembershipOfferingCategoryWhereQueryParamParameterMembershipOfferingItemsEvery;
  none?: GetOneMembershipOfferingCategoryWhereQueryParamParameterMembershipOfferingItemsNone;
} | null;

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterDeletedAtOneOfNot = string | DeletedAt;

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterDeletedAtOneOfNotIn = string | string[];

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterDeletedAtOneOfIn = string | string[];

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterDeletedAtOneOfEquals = string;

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterDeletedAtOneOf = {
  equals?: GetOneMembershipOfferingCategoryWhereQueryParamParameterDeletedAtOneOfEquals;
  in?: GetOneMembershipOfferingCategoryWhereQueryParamParameterDeletedAtOneOfIn;
  notIn?: GetOneMembershipOfferingCategoryWhereQueryParamParameterDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipOfferingCategoryWhereQueryParamParameterDeletedAtOneOfNot;
};

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterDeletedAt =
  | string
  | GetOneMembershipOfferingCategoryWhereQueryParamParameterDeletedAtOneOf;

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterUpdatedAt =
  | string
  | GetOneMembershipOfferingCategoryWhereQueryParamParameterUpdatedAtOneOf;

export type GetOneMembershipOfferingCategoryWhereQueryParamParameter = {
  AND?: GetOneMembershipOfferingCategoryWhereQueryParamParameterAnd;
  OR?: GetOneMembershipOfferingCategoryWhereQueryParamParameterOr;
  NOT?: GetOneMembershipOfferingCategoryWhereQueryParamParameterNot;
  id?: GetOneMembershipOfferingCategoryWhereQueryParamParameterId;
  name?: GetOneMembershipOfferingCategoryWhereQueryParamParameterName;
  slug?: GetOneMembershipOfferingCategoryWhereQueryParamParameterSlug;
  createdAt?: GetOneMembershipOfferingCategoryWhereQueryParamParameterCreatedAt;
  updatedAt?: GetOneMembershipOfferingCategoryWhereQueryParamParameterUpdatedAt;
  deletedAt?: GetOneMembershipOfferingCategoryWhereQueryParamParameterDeletedAt;
  membershipOfferingItems?: GetOneMembershipOfferingCategoryWhereQueryParamParameterMembershipOfferingItems;
};

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterUpdatedAtOneOfNot = string | UpdatedAt;

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterUpdatedAtOneOfNotIn = string | string[];

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterUpdatedAtOneOfIn = string | string[];

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterUpdatedAtOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOfferingCategoryWhereQueryParamParameterUpdatedAtOneOfIn;
  notIn?: GetOneMembershipOfferingCategoryWhereQueryParamParameterUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipOfferingCategoryWhereQueryParamParameterUpdatedAtOneOfNot;
};

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterCreatedAt =
  | string
  | GetOneMembershipOfferingCategoryWhereQueryParamParameterCreatedAtOneOf;

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterCreatedAtOneOfNot = string | CreatedAt;

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterCreatedAtOneOfNotIn = string | string[];

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterCreatedAtOneOfIn = string | string[];

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterCreatedAtOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOfferingCategoryWhereQueryParamParameterCreatedAtOneOfIn;
  notIn?: GetOneMembershipOfferingCategoryWhereQueryParamParameterCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipOfferingCategoryWhereQueryParamParameterCreatedAtOneOfNot;
};

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterSlug =
  | string
  | GetOneMembershipOfferingCategoryWhereQueryParamParameterSlugOneOf;

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterSlugOneOfNot = string | Slug;

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterSlugOneOfMode =
  typeof GetOneMembershipOfferingCategoryWhereQueryParamParameterSlugOneOfMode[keyof typeof GetOneMembershipOfferingCategoryWhereQueryParamParameterSlugOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOfferingCategoryWhereQueryParamParameterSlugOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterSlugOneOfNotIn = string | string[];

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterSlugOneOfIn = string | string[];

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterSlugOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOfferingCategoryWhereQueryParamParameterSlugOneOfIn;
  notIn?: GetOneMembershipOfferingCategoryWhereQueryParamParameterSlugOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipOfferingCategoryWhereQueryParamParameterSlugOneOfMode;
  not?: GetOneMembershipOfferingCategoryWhereQueryParamParameterSlugOneOfNot;
};

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterName =
  | string
  | GetOneMembershipOfferingCategoryWhereQueryParamParameterNameOneOf;

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterNameOneOfNot = string | Name;

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterNameOneOfMode =
  typeof GetOneMembershipOfferingCategoryWhereQueryParamParameterNameOneOfMode[keyof typeof GetOneMembershipOfferingCategoryWhereQueryParamParameterNameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOfferingCategoryWhereQueryParamParameterNameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterNameOneOfNotIn = string | string[];

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterNameOneOfIn = string | string[];

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterNameOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOfferingCategoryWhereQueryParamParameterNameOneOfIn;
  notIn?: GetOneMembershipOfferingCategoryWhereQueryParamParameterNameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipOfferingCategoryWhereQueryParamParameterNameOneOfMode;
  not?: GetOneMembershipOfferingCategoryWhereQueryParamParameterNameOneOfNot;
};

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterId =
  | string
  | GetOneMembershipOfferingCategoryWhereQueryParamParameterIdOneOf;

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterIdOneOfNot = string | Id;

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterIdOneOfMode =
  typeof GetOneMembershipOfferingCategoryWhereQueryParamParameterIdOneOfMode[keyof typeof GetOneMembershipOfferingCategoryWhereQueryParamParameterIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOfferingCategoryWhereQueryParamParameterIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterIdOneOfNotIn = string | string[];

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterIdOneOfIn = string | string[];

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterIdOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOfferingCategoryWhereQueryParamParameterIdOneOfIn;
  notIn?: GetOneMembershipOfferingCategoryWhereQueryParamParameterIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipOfferingCategoryWhereQueryParamParameterIdOneOfMode;
  not?: GetOneMembershipOfferingCategoryWhereQueryParamParameterIdOneOfNot;
};

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterNot =
  | MembershipOfferingCategoryWhere
  | MembershipOfferingCategoryWhere[];

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterOr =
  | MembershipOfferingCategoryWhere
  | MembershipOfferingCategoryWhere[];

export type GetOneMembershipOfferingCategoryWhereQueryParamParameterAnd =
  | MembershipOfferingCategoryWhere
  | MembershipOfferingCategoryWhere[];

export type GetOneMembershipOfferingCategoryIncludeQueryParamParameterOneOf = null;

export type GetOneMembershipOfferingCategoryIncludeQueryParamParameter =
  | GetOneMembershipOfferingCategoryIncludeQueryParamParameterOneOf
  | MembershipOfferingCategoryInclude;

export type GetOneMembershipOfferingCategorySelectQueryParamParameterOneOf = null;

export type GetOneMembershipOfferingCategorySelectQueryParamParameter =
  | GetOneMembershipOfferingCategorySelectQueryParamParameterOneOf
  | MembershipOfferingCategorySelect;

/**
 * Query params for get-membership-offering-category-by-id}
 */
export type GetMembershipOfferingCategoryByIdWithTrashedQueryParamParameter = boolean | null;

export type GetMembershipOfferingCategoryByIdIncludeQueryParamParameterOneOf = null;

export type GetMembershipOfferingCategoryByIdIncludeQueryParamParameter =
  | GetMembershipOfferingCategoryByIdIncludeQueryParamParameterOneOf
  | MembershipOfferingCategoryInclude;

export type GetMembershipOfferingCategoryByIdSelectQueryParamParameterOneOf = null;

export type GetMembershipOfferingCategoryByIdSelectQueryParamParameter =
  | GetMembershipOfferingCategoryByIdSelectQueryParamParameterOneOf
  | MembershipOfferingCategorySelect;

/**
 * Query params for get-many-membership-offerings}
 */
export type GetManyMembershipOfferingsWithTrashedQueryParamParameter = boolean | null;

export type GetManyMembershipOfferingsWhereQueryParamParameterOneOf = null;

export type GetManyMembershipOfferingsWhereQueryParamParameter =
  | GetManyMembershipOfferingsWhereQueryParamParameterOneOf
  | MembershipOfferingWhere;

export type GetManyMembershipOfferingsIncludeQueryParamParameterOneOf = null;

export type GetManyMembershipOfferingsIncludeQueryParamParameter =
  | GetManyMembershipOfferingsIncludeQueryParamParameterOneOf
  | MembershipOfferingInclude;

export type GetManyMembershipOfferingsSelectQueryParamParameterOneOf = null;

export type GetManyMembershipOfferingsSelectQueryParamParameter =
  | GetManyMembershipOfferingsSelectQueryParamParameterOneOf
  | MembershipOfferingSelect;

/**
 * Query params for get-one-membership-offering}
 */
export type GetOneMembershipOfferingWithTrashedQueryParamParameter = boolean | null;

export type GetOneMembershipOfferingWhereQueryParamParameterMembershipOfferingItemsNone = MembershipOfferingItemWhere;

export type GetOneMembershipOfferingWhereQueryParamParameterMembershipOfferingItemsEvery = MembershipOfferingItemWhere;

export type GetOneMembershipOfferingWhereQueryParamParameterMembershipOfferingItemsSome = MembershipOfferingItemWhere;

export type GetOneMembershipOfferingWhereQueryParamParameterMembershipOfferingItems = {
  some?: GetOneMembershipOfferingWhereQueryParamParameterMembershipOfferingItemsSome;
  every?: GetOneMembershipOfferingWhereQueryParamParameterMembershipOfferingItemsEvery;
  none?: GetOneMembershipOfferingWhereQueryParamParameterMembershipOfferingItemsNone;
} | null;

export type GetOneMembershipOfferingWhereQueryParamParameterMembershipTier =
  | MembershipTierWhere
  | GetOneMembershipOfferingWhereQueryParamParameterMembershipTierOneOf;

export type GetOneMembershipOfferingWhereQueryParamParameter = {
  AND?: GetOneMembershipOfferingWhereQueryParamParameterAnd;
  OR?: GetOneMembershipOfferingWhereQueryParamParameterOr;
  NOT?: GetOneMembershipOfferingWhereQueryParamParameterNot;
  id?: GetOneMembershipOfferingWhereQueryParamParameterId;
  year?: GetOneMembershipOfferingWhereQueryParamParameterYear;
  month?: GetOneMembershipOfferingWhereQueryParamParameterMonth;
  rules?: GetOneMembershipOfferingWhereQueryParamParameterRules;
  membershipTierId?: GetOneMembershipOfferingWhereQueryParamParameterMembershipTierId;
  createdAt?: GetOneMembershipOfferingWhereQueryParamParameterCreatedAt;
  updatedAt?: GetOneMembershipOfferingWhereQueryParamParameterUpdatedAt;
  deletedAt?: GetOneMembershipOfferingWhereQueryParamParameterDeletedAt;
  membershipTier?: GetOneMembershipOfferingWhereQueryParamParameterMembershipTier;
  membershipOfferingItems?: GetOneMembershipOfferingWhereQueryParamParameterMembershipOfferingItems;
};

export type GetOneMembershipOfferingWhereQueryParamParameterMembershipTierOneOfIsNot = MembershipTierWhere;

export type GetOneMembershipOfferingWhereQueryParamParameterMembershipTierOneOfIs = MembershipTierWhere;

export type GetOneMembershipOfferingWhereQueryParamParameterMembershipTierOneOf = {
  is?: GetOneMembershipOfferingWhereQueryParamParameterMembershipTierOneOfIs;
  isNot?: GetOneMembershipOfferingWhereQueryParamParameterMembershipTierOneOfIsNot;
};

export type GetOneMembershipOfferingWhereQueryParamParameterDeletedAt =
  | string
  | GetOneMembershipOfferingWhereQueryParamParameterDeletedAtOneOf;

export type GetOneMembershipOfferingWhereQueryParamParameterDeletedAtOneOfNot = string | DeletedAt;

export type GetOneMembershipOfferingWhereQueryParamParameterDeletedAtOneOfNotIn = string | string[];

export type GetOneMembershipOfferingWhereQueryParamParameterDeletedAtOneOfIn = string | string[];

export type GetOneMembershipOfferingWhereQueryParamParameterDeletedAtOneOfEquals = string;

export type GetOneMembershipOfferingWhereQueryParamParameterDeletedAtOneOf = {
  equals?: GetOneMembershipOfferingWhereQueryParamParameterDeletedAtOneOfEquals;
  in?: GetOneMembershipOfferingWhereQueryParamParameterDeletedAtOneOfIn;
  notIn?: GetOneMembershipOfferingWhereQueryParamParameterDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipOfferingWhereQueryParamParameterDeletedAtOneOfNot;
};

export type GetOneMembershipOfferingWhereQueryParamParameterUpdatedAtOneOfNot = string | UpdatedAt;

export type GetOneMembershipOfferingWhereQueryParamParameterUpdatedAtOneOfNotIn = string | string[];

export type GetOneMembershipOfferingWhereQueryParamParameterUpdatedAtOneOfIn = string | string[];

export type GetOneMembershipOfferingWhereQueryParamParameterUpdatedAtOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOfferingWhereQueryParamParameterUpdatedAtOneOfIn;
  notIn?: GetOneMembershipOfferingWhereQueryParamParameterUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipOfferingWhereQueryParamParameterUpdatedAtOneOfNot;
};

export type GetOneMembershipOfferingWhereQueryParamParameterUpdatedAt =
  | string
  | GetOneMembershipOfferingWhereQueryParamParameterUpdatedAtOneOf;

export type GetOneMembershipOfferingWhereQueryParamParameterCreatedAt =
  | string
  | GetOneMembershipOfferingWhereQueryParamParameterCreatedAtOneOf;

export type GetOneMembershipOfferingWhereQueryParamParameterCreatedAtOneOfNot = string | CreatedAt;

export type GetOneMembershipOfferingWhereQueryParamParameterCreatedAtOneOfNotIn = string | string[];

export type GetOneMembershipOfferingWhereQueryParamParameterCreatedAtOneOfIn = string | string[];

export type GetOneMembershipOfferingWhereQueryParamParameterCreatedAtOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOfferingWhereQueryParamParameterCreatedAtOneOfIn;
  notIn?: GetOneMembershipOfferingWhereQueryParamParameterCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipOfferingWhereQueryParamParameterCreatedAtOneOfNot;
};

export type GetOneMembershipOfferingWhereQueryParamParameterMembershipTierId =
  | string
  | GetOneMembershipOfferingWhereQueryParamParameterMembershipTierIdOneOf;

export type GetOneMembershipOfferingWhereQueryParamParameterMembershipTierIdOneOfNot = string | MembershipTierId;

export type GetOneMembershipOfferingWhereQueryParamParameterMembershipTierIdOneOfMode =
  typeof GetOneMembershipOfferingWhereQueryParamParameterMembershipTierIdOneOfMode[keyof typeof GetOneMembershipOfferingWhereQueryParamParameterMembershipTierIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOfferingWhereQueryParamParameterMembershipTierIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipOfferingWhereQueryParamParameterMembershipTierIdOneOfNotIn = string | string[];

export type GetOneMembershipOfferingWhereQueryParamParameterMembershipTierIdOneOfIn = string | string[];

export type GetOneMembershipOfferingWhereQueryParamParameterMembershipTierIdOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOfferingWhereQueryParamParameterMembershipTierIdOneOfIn;
  notIn?: GetOneMembershipOfferingWhereQueryParamParameterMembershipTierIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipOfferingWhereQueryParamParameterMembershipTierIdOneOfMode;
  not?: GetOneMembershipOfferingWhereQueryParamParameterMembershipTierIdOneOfNot;
};

export type GetOneMembershipOfferingWhereQueryParamParameterRulesOneOfPath = string | string[];

export type GetOneMembershipOfferingWhereQueryParamParameterRulesOneOf = {
  path?: GetOneMembershipOfferingWhereQueryParamParameterRulesOneOfPath;
  equals?: unknown;
  not?: unknown;
  string_contains?: string | null;
  string_starts_with?: string | null;
  string_ends_with?: string | null;
  array_contains?: unknown;
  array_starts_with?: unknown;
  array_ends_with?: unknown;
};

export type GetOneMembershipOfferingWhereQueryParamParameterRules =
  GetOneMembershipOfferingWhereQueryParamParameterRulesOneOf;

export type GetOneMembershipOfferingWhereQueryParamParameterMonthOneOfNot =
  | 'JANUARY'
  | 'FEBRUARY'
  | 'MARCH'
  | 'APRIL'
  | 'MAY'
  | 'JUNE'
  | 'JULY'
  | 'AUGUST'
  | 'SEPTEMBER'
  | 'OCTOBER'
  | 'NOVEMBER'
  | 'DECEMBER'
  | Month;

export type GetOneMembershipOfferingWhereQueryParamParameterMonthOneOf = {
  equals?: GetOneMembershipOfferingWhereQueryParamParameterMonthOneOfEquals;
  in?: GetOneMembershipOfferingWhereQueryParamParameterMonthOneOfIn;
  notIn?: GetOneMembershipOfferingWhereQueryParamParameterMonthOneOfNotIn;
  not?: GetOneMembershipOfferingWhereQueryParamParameterMonthOneOfNot;
} | null;

export type GetOneMembershipOfferingWhereQueryParamParameterMonth =
  | 'JANUARY'
  | 'FEBRUARY'
  | 'MARCH'
  | 'APRIL'
  | 'MAY'
  | 'JUNE'
  | 'JULY'
  | 'AUGUST'
  | 'SEPTEMBER'
  | 'OCTOBER'
  | 'NOVEMBER'
  | 'DECEMBER'
  | GetOneMembershipOfferingWhereQueryParamParameterMonthOneOf;

export type GetOneMembershipOfferingWhereQueryParamParameterMonthOneOfNotInOneOfItem =
  typeof GetOneMembershipOfferingWhereQueryParamParameterMonthOneOfNotInOneOfItem[keyof typeof GetOneMembershipOfferingWhereQueryParamParameterMonthOneOfNotInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOfferingWhereQueryParamParameterMonthOneOfNotInOneOfItem = {
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
} as const;

export type GetOneMembershipOfferingWhereQueryParamParameterMonthOneOfNotIn =
  | 'JANUARY'
  | 'FEBRUARY'
  | 'MARCH'
  | 'APRIL'
  | 'MAY'
  | 'JUNE'
  | 'JULY'
  | 'AUGUST'
  | 'SEPTEMBER'
  | 'OCTOBER'
  | 'NOVEMBER'
  | 'DECEMBER'
  | GetOneMembershipOfferingWhereQueryParamParameterMonthOneOfNotInOneOfItem[];

export type GetOneMembershipOfferingWhereQueryParamParameterMonthOneOfInOneOfItem =
  typeof GetOneMembershipOfferingWhereQueryParamParameterMonthOneOfInOneOfItem[keyof typeof GetOneMembershipOfferingWhereQueryParamParameterMonthOneOfInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOfferingWhereQueryParamParameterMonthOneOfInOneOfItem = {
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
} as const;

export type GetOneMembershipOfferingWhereQueryParamParameterMonthOneOfIn =
  | 'JANUARY'
  | 'FEBRUARY'
  | 'MARCH'
  | 'APRIL'
  | 'MAY'
  | 'JUNE'
  | 'JULY'
  | 'AUGUST'
  | 'SEPTEMBER'
  | 'OCTOBER'
  | 'NOVEMBER'
  | 'DECEMBER'
  | GetOneMembershipOfferingWhereQueryParamParameterMonthOneOfInOneOfItem[];

export type GetOneMembershipOfferingWhereQueryParamParameterMonthOneOfEquals =
  typeof GetOneMembershipOfferingWhereQueryParamParameterMonthOneOfEquals[keyof typeof GetOneMembershipOfferingWhereQueryParamParameterMonthOneOfEquals];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOfferingWhereQueryParamParameterMonthOneOfEquals = {
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
} as const;

export type GetOneMembershipOfferingWhereQueryParamParameterYear =
  | string
  | GetOneMembershipOfferingWhereQueryParamParameterYearOneOf;

export type GetOneMembershipOfferingWhereQueryParamParameterYearOneOfNot = string | Year;

export type GetOneMembershipOfferingWhereQueryParamParameterYearOneOfMode =
  typeof GetOneMembershipOfferingWhereQueryParamParameterYearOneOfMode[keyof typeof GetOneMembershipOfferingWhereQueryParamParameterYearOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOfferingWhereQueryParamParameterYearOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipOfferingWhereQueryParamParameterYearOneOfNotIn = string | string[];

export type GetOneMembershipOfferingWhereQueryParamParameterYearOneOfIn = string | string[];

export type GetOneMembershipOfferingWhereQueryParamParameterYearOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOfferingWhereQueryParamParameterYearOneOfIn;
  notIn?: GetOneMembershipOfferingWhereQueryParamParameterYearOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipOfferingWhereQueryParamParameterYearOneOfMode;
  not?: GetOneMembershipOfferingWhereQueryParamParameterYearOneOfNot;
};

export type GetOneMembershipOfferingWhereQueryParamParameterIdOneOfNot = string | Id;

export type GetOneMembershipOfferingWhereQueryParamParameterIdOneOfMode =
  typeof GetOneMembershipOfferingWhereQueryParamParameterIdOneOfMode[keyof typeof GetOneMembershipOfferingWhereQueryParamParameterIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOfferingWhereQueryParamParameterIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipOfferingWhereQueryParamParameterIdOneOfNotIn = string | string[];

export type GetOneMembershipOfferingWhereQueryParamParameterIdOneOfIn = string | string[];

export type GetOneMembershipOfferingWhereQueryParamParameterIdOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOfferingWhereQueryParamParameterIdOneOfIn;
  notIn?: GetOneMembershipOfferingWhereQueryParamParameterIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipOfferingWhereQueryParamParameterIdOneOfMode;
  not?: GetOneMembershipOfferingWhereQueryParamParameterIdOneOfNot;
};

export type GetOneMembershipOfferingWhereQueryParamParameterId =
  | string
  | GetOneMembershipOfferingWhereQueryParamParameterIdOneOf;

export type GetOneMembershipOfferingWhereQueryParamParameterNot = MembershipOfferingWhere | MembershipOfferingWhere[];

export type GetOneMembershipOfferingWhereQueryParamParameterOr = MembershipOfferingWhere | MembershipOfferingWhere[];

export type GetOneMembershipOfferingWhereQueryParamParameterAnd = MembershipOfferingWhere | MembershipOfferingWhere[];

export type GetOneMembershipOfferingIncludeQueryParamParameterOneOf = null;

export type GetOneMembershipOfferingIncludeQueryParamParameter =
  | GetOneMembershipOfferingIncludeQueryParamParameterOneOf
  | MembershipOfferingInclude;

export type GetOneMembershipOfferingSelectQueryParamParameterOneOf = null;

export type GetOneMembershipOfferingSelectQueryParamParameter =
  | GetOneMembershipOfferingSelectQueryParamParameterOneOf
  | MembershipOfferingSelect;

/**
 * Query params for get-membership-offering-by-id}
 */
export type GetMembershipOfferingByIdWithTrashedQueryParamParameter = boolean | null;

export type GetMembershipOfferingByIdIncludeQueryParamParameterOneOf = null;

export type GetMembershipOfferingByIdIncludeQueryParamParameter =
  | GetMembershipOfferingByIdIncludeQueryParamParameterOneOf
  | MembershipOfferingInclude;

export type GetMembershipOfferingByIdSelectQueryParamParameterOneOf = null;

export type GetMembershipOfferingByIdSelectQueryParamParameter =
  | GetMembershipOfferingByIdSelectQueryParamParameterOneOf
  | MembershipOfferingSelect;

/**
 * Query params for get-many-membership-order-items}
 */
export type GetManyMembershipOrderItemsWithTrashedQueryParamParameter = boolean | null;

export type GetManyMembershipOrderItemsWhereQueryParamParameterOneOf = null;

export type GetManyMembershipOrderItemsWhereQueryParamParameter =
  | GetManyMembershipOrderItemsWhereQueryParamParameterOneOf
  | MembershipOrderItemWhere;

export type GetManyMembershipOrderItemsIncludeQueryParamParameterOneOf = null;

export type GetManyMembershipOrderItemsIncludeQueryParamParameter =
  | GetManyMembershipOrderItemsIncludeQueryParamParameterOneOf
  | MembershipOrderItemInclude;

export type GetManyMembershipOrderItemsSelectQueryParamParameterOneOf = null;

export type GetManyMembershipOrderItemsSelectQueryParamParameter =
  | GetManyMembershipOrderItemsSelectQueryParamParameterOneOf
  | MembershipOrderItemSelect;

/**
 * Query params for get-one-membership-order-item}
 */
export type GetOneMembershipOrderItemWithTrashedQueryParamParameter = boolean | null;

export type GetOneMembershipOrderItemWhereQueryParamParameterItemOneOfIsNot = ItemWhere;

export type GetOneMembershipOrderItemWhereQueryParamParameterItemOneOfIs = ItemWhere;

export type GetOneMembershipOrderItemWhereQueryParamParameterItemOneOf = {
  is?: GetOneMembershipOrderItemWhereQueryParamParameterItemOneOfIs;
  isNot?: GetOneMembershipOrderItemWhereQueryParamParameterItemOneOfIsNot;
};

export type GetOneMembershipOrderItemWhereQueryParamParameterItem =
  | ItemWhere
  | GetOneMembershipOrderItemWhereQueryParamParameterItemOneOf;

export type GetOneMembershipOrderItemWhereQueryParamParameter = {
  AND?: GetOneMembershipOrderItemWhereQueryParamParameterAnd;
  OR?: GetOneMembershipOrderItemWhereQueryParamParameterOr;
  NOT?: GetOneMembershipOrderItemWhereQueryParamParameterNot;
  id?: GetOneMembershipOrderItemWhereQueryParamParameterId;
  membershipOrderId?: GetOneMembershipOrderItemWhereQueryParamParameterMembershipOrderId;
  itemId?: GetOneMembershipOrderItemWhereQueryParamParameterItemId;
  type?: GetOneMembershipOrderItemWhereQueryParamParameterType;
  quantity?: GetOneMembershipOrderItemWhereQueryParamParameterQuantity;
  createdAt?: GetOneMembershipOrderItemWhereQueryParamParameterCreatedAt;
  updatedAt?: GetOneMembershipOrderItemWhereQueryParamParameterUpdatedAt;
  deletedAt?: GetOneMembershipOrderItemWhereQueryParamParameterDeletedAt;
  membershipOrder?: GetOneMembershipOrderItemWhereQueryParamParameterMembershipOrder;
  item?: GetOneMembershipOrderItemWhereQueryParamParameterItem;
};

export type GetOneMembershipOrderItemWhereQueryParamParameterMembershipOrderOneOfIsNot = MembershipOrderWhere;

export type GetOneMembershipOrderItemWhereQueryParamParameterMembershipOrderOneOfIs = MembershipOrderWhere;

export type GetOneMembershipOrderItemWhereQueryParamParameterMembershipOrderOneOf = {
  is?: GetOneMembershipOrderItemWhereQueryParamParameterMembershipOrderOneOfIs;
  isNot?: GetOneMembershipOrderItemWhereQueryParamParameterMembershipOrderOneOfIsNot;
};

export type GetOneMembershipOrderItemWhereQueryParamParameterMembershipOrder =
  | MembershipOrderWhere
  | GetOneMembershipOrderItemWhereQueryParamParameterMembershipOrderOneOf;

export type GetOneMembershipOrderItemWhereQueryParamParameterDeletedAt =
  | string
  | GetOneMembershipOrderItemWhereQueryParamParameterDeletedAtOneOf;

export type GetOneMembershipOrderItemWhereQueryParamParameterDeletedAtOneOfNot = string | DeletedAt;

export type GetOneMembershipOrderItemWhereQueryParamParameterDeletedAtOneOfNotIn = string | string[];

export type GetOneMembershipOrderItemWhereQueryParamParameterDeletedAtOneOfIn = string | string[];

export type GetOneMembershipOrderItemWhereQueryParamParameterDeletedAtOneOfEquals = string;

export type GetOneMembershipOrderItemWhereQueryParamParameterDeletedAtOneOf = {
  equals?: GetOneMembershipOrderItemWhereQueryParamParameterDeletedAtOneOfEquals;
  in?: GetOneMembershipOrderItemWhereQueryParamParameterDeletedAtOneOfIn;
  notIn?: GetOneMembershipOrderItemWhereQueryParamParameterDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipOrderItemWhereQueryParamParameterDeletedAtOneOfNot;
};

export type GetOneMembershipOrderItemWhereQueryParamParameterUpdatedAt =
  | string
  | GetOneMembershipOrderItemWhereQueryParamParameterUpdatedAtOneOf;

export type GetOneMembershipOrderItemWhereQueryParamParameterUpdatedAtOneOfNot = string | UpdatedAt;

export type GetOneMembershipOrderItemWhereQueryParamParameterUpdatedAtOneOfNotIn = string | string[];

export type GetOneMembershipOrderItemWhereQueryParamParameterUpdatedAtOneOfIn = string | string[];

export type GetOneMembershipOrderItemWhereQueryParamParameterUpdatedAtOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOrderItemWhereQueryParamParameterUpdatedAtOneOfIn;
  notIn?: GetOneMembershipOrderItemWhereQueryParamParameterUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipOrderItemWhereQueryParamParameterUpdatedAtOneOfNot;
};

export type GetOneMembershipOrderItemWhereQueryParamParameterCreatedAt =
  | string
  | GetOneMembershipOrderItemWhereQueryParamParameterCreatedAtOneOf;

export type GetOneMembershipOrderItemWhereQueryParamParameterCreatedAtOneOfNot = string | CreatedAt;

export type GetOneMembershipOrderItemWhereQueryParamParameterCreatedAtOneOfNotIn = string | string[];

export type GetOneMembershipOrderItemWhereQueryParamParameterCreatedAtOneOfIn = string | string[];

export type GetOneMembershipOrderItemWhereQueryParamParameterCreatedAtOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOrderItemWhereQueryParamParameterCreatedAtOneOfIn;
  notIn?: GetOneMembershipOrderItemWhereQueryParamParameterCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipOrderItemWhereQueryParamParameterCreatedAtOneOfNot;
};

export type GetOneMembershipOrderItemWhereQueryParamParameterQuantityOneOfNotOneOf = null;

export type GetOneMembershipOrderItemWhereQueryParamParameterQuantityOneOfNot =
  | GetOneMembershipOrderItemWhereQueryParamParameterQuantityOneOfNotOneOf
  | number
  | Quantity;

export type GetOneMembershipOrderItemWhereQueryParamParameterQuantityOneOfNotIn = number | number[];

export type GetOneMembershipOrderItemWhereQueryParamParameterQuantityOneOfIn = number | number[];

export type GetOneMembershipOrderItemWhereQueryParamParameterQuantityOneOf = {
  equals?: number | null;
  in?: GetOneMembershipOrderItemWhereQueryParamParameterQuantityOneOfIn;
  notIn?: GetOneMembershipOrderItemWhereQueryParamParameterQuantityOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: GetOneMembershipOrderItemWhereQueryParamParameterQuantityOneOfNot;
};

export type GetOneMembershipOrderItemWhereQueryParamParameterQuantity =
  | number
  | GetOneMembershipOrderItemWhereQueryParamParameterQuantityOneOf;

export type GetOneMembershipOrderItemWhereQueryParamParameterTypeOneOfNot = 'INCLUSION' | 'ADDITION' | Type;

export type GetOneMembershipOrderItemWhereQueryParamParameterTypeOneOf = {
  equals?: GetOneMembershipOrderItemWhereQueryParamParameterTypeOneOfEquals;
  in?: GetOneMembershipOrderItemWhereQueryParamParameterTypeOneOfIn;
  notIn?: GetOneMembershipOrderItemWhereQueryParamParameterTypeOneOfNotIn;
  not?: GetOneMembershipOrderItemWhereQueryParamParameterTypeOneOfNot;
} | null;

export type GetOneMembershipOrderItemWhereQueryParamParameterType =
  | 'INCLUSION'
  | 'ADDITION'
  | GetOneMembershipOrderItemWhereQueryParamParameterTypeOneOf;

export type GetOneMembershipOrderItemWhereQueryParamParameterTypeOneOfNotInOneOfItem =
  typeof GetOneMembershipOrderItemWhereQueryParamParameterTypeOneOfNotInOneOfItem[keyof typeof GetOneMembershipOrderItemWhereQueryParamParameterTypeOneOfNotInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOrderItemWhereQueryParamParameterTypeOneOfNotInOneOfItem = {
  INCLUSION: 'INCLUSION',
  ADDITION: 'ADDITION',
} as const;

export type GetOneMembershipOrderItemWhereQueryParamParameterTypeOneOfNotIn =
  | 'INCLUSION'
  | 'ADDITION'
  | GetOneMembershipOrderItemWhereQueryParamParameterTypeOneOfNotInOneOfItem[];

export type GetOneMembershipOrderItemWhereQueryParamParameterTypeOneOfInOneOfItem =
  typeof GetOneMembershipOrderItemWhereQueryParamParameterTypeOneOfInOneOfItem[keyof typeof GetOneMembershipOrderItemWhereQueryParamParameterTypeOneOfInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOrderItemWhereQueryParamParameterTypeOneOfInOneOfItem = {
  INCLUSION: 'INCLUSION',
  ADDITION: 'ADDITION',
} as const;

export type GetOneMembershipOrderItemWhereQueryParamParameterTypeOneOfIn =
  | 'INCLUSION'
  | 'ADDITION'
  | GetOneMembershipOrderItemWhereQueryParamParameterTypeOneOfInOneOfItem[];

export type GetOneMembershipOrderItemWhereQueryParamParameterTypeOneOfEquals =
  typeof GetOneMembershipOrderItemWhereQueryParamParameterTypeOneOfEquals[keyof typeof GetOneMembershipOrderItemWhereQueryParamParameterTypeOneOfEquals];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOrderItemWhereQueryParamParameterTypeOneOfEquals = {
  INCLUSION: 'INCLUSION',
  ADDITION: 'ADDITION',
} as const;

export type GetOneMembershipOrderItemWhereQueryParamParameterItemIdOneOfNot = string | ItemId;

export type GetOneMembershipOrderItemWhereQueryParamParameterItemIdOneOfMode =
  typeof GetOneMembershipOrderItemWhereQueryParamParameterItemIdOneOfMode[keyof typeof GetOneMembershipOrderItemWhereQueryParamParameterItemIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOrderItemWhereQueryParamParameterItemIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipOrderItemWhereQueryParamParameterItemIdOneOfNotIn = string | string[];

export type GetOneMembershipOrderItemWhereQueryParamParameterItemIdOneOfIn = string | string[];

export type GetOneMembershipOrderItemWhereQueryParamParameterItemIdOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOrderItemWhereQueryParamParameterItemIdOneOfIn;
  notIn?: GetOneMembershipOrderItemWhereQueryParamParameterItemIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipOrderItemWhereQueryParamParameterItemIdOneOfMode;
  not?: GetOneMembershipOrderItemWhereQueryParamParameterItemIdOneOfNot;
};

export type GetOneMembershipOrderItemWhereQueryParamParameterItemId =
  | string
  | GetOneMembershipOrderItemWhereQueryParamParameterItemIdOneOf;

export type GetOneMembershipOrderItemWhereQueryParamParameterMembershipOrderId =
  | string
  | GetOneMembershipOrderItemWhereQueryParamParameterMembershipOrderIdOneOf;

export type GetOneMembershipOrderItemWhereQueryParamParameterMembershipOrderIdOneOfNot = string | MembershipOrderId;

export type GetOneMembershipOrderItemWhereQueryParamParameterMembershipOrderIdOneOfMode =
  typeof GetOneMembershipOrderItemWhereQueryParamParameterMembershipOrderIdOneOfMode[keyof typeof GetOneMembershipOrderItemWhereQueryParamParameterMembershipOrderIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOrderItemWhereQueryParamParameterMembershipOrderIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipOrderItemWhereQueryParamParameterMembershipOrderIdOneOfNotIn = string | string[];

export type GetOneMembershipOrderItemWhereQueryParamParameterMembershipOrderIdOneOfIn = string | string[];

export type GetOneMembershipOrderItemWhereQueryParamParameterMembershipOrderIdOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOrderItemWhereQueryParamParameterMembershipOrderIdOneOfIn;
  notIn?: GetOneMembershipOrderItemWhereQueryParamParameterMembershipOrderIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipOrderItemWhereQueryParamParameterMembershipOrderIdOneOfMode;
  not?: GetOneMembershipOrderItemWhereQueryParamParameterMembershipOrderIdOneOfNot;
};

export type GetOneMembershipOrderItemWhereQueryParamParameterId =
  | string
  | GetOneMembershipOrderItemWhereQueryParamParameterIdOneOf;

export type GetOneMembershipOrderItemWhereQueryParamParameterIdOneOfNot = string | Id;

export type GetOneMembershipOrderItemWhereQueryParamParameterIdOneOfMode =
  typeof GetOneMembershipOrderItemWhereQueryParamParameterIdOneOfMode[keyof typeof GetOneMembershipOrderItemWhereQueryParamParameterIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOrderItemWhereQueryParamParameterIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipOrderItemWhereQueryParamParameterIdOneOfNotIn = string | string[];

export type GetOneMembershipOrderItemWhereQueryParamParameterIdOneOfIn = string | string[];

export type GetOneMembershipOrderItemWhereQueryParamParameterIdOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOrderItemWhereQueryParamParameterIdOneOfIn;
  notIn?: GetOneMembershipOrderItemWhereQueryParamParameterIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipOrderItemWhereQueryParamParameterIdOneOfMode;
  not?: GetOneMembershipOrderItemWhereQueryParamParameterIdOneOfNot;
};

export type GetOneMembershipOrderItemWhereQueryParamParameterNot =
  | MembershipOrderItemWhere
  | MembershipOrderItemWhere[];

export type GetOneMembershipOrderItemWhereQueryParamParameterOr = MembershipOrderItemWhere | MembershipOrderItemWhere[];

export type GetOneMembershipOrderItemWhereQueryParamParameterAnd =
  | MembershipOrderItemWhere
  | MembershipOrderItemWhere[];

export type GetOneMembershipOrderItemIncludeQueryParamParameterOneOf = null;

export type GetOneMembershipOrderItemIncludeQueryParamParameter =
  | GetOneMembershipOrderItemIncludeQueryParamParameterOneOf
  | MembershipOrderItemInclude;

export type GetOneMembershipOrderItemSelectQueryParamParameterOneOf = null;

export type GetOneMembershipOrderItemSelectQueryParamParameter =
  | GetOneMembershipOrderItemSelectQueryParamParameterOneOf
  | MembershipOrderItemSelect;

/**
 * Query params for get-membership-order-item-by-id}
 */
export type GetMembershipOrderItemByIdWithTrashedQueryParamParameter = boolean | null;

export type GetMembershipOrderItemByIdIncludeQueryParamParameterOneOf = null;

export type GetMembershipOrderItemByIdIncludeQueryParamParameter =
  | GetMembershipOrderItemByIdIncludeQueryParamParameterOneOf
  | MembershipOrderItemInclude;

export type GetMembershipOrderItemByIdSelectQueryParamParameterOneOf = null;

export type GetMembershipOrderItemByIdSelectQueryParamParameter =
  | GetMembershipOrderItemByIdSelectQueryParamParameterOneOf
  | MembershipOrderItemSelect;

/**
 * Query params for get-many-membership-orders}
 */
export type GetManyMembershipOrdersWithTrashedQueryParamParameter = boolean | null;

export type GetManyMembershipOrdersWhereQueryParamParameterOneOf = null;

export type GetManyMembershipOrdersWhereQueryParamParameter =
  | GetManyMembershipOrdersWhereQueryParamParameterOneOf
  | MembershipOrderWhere;

export type GetManyMembershipOrdersIncludeQueryParamParameterOneOf = null;

export type GetManyMembershipOrdersIncludeQueryParamParameter =
  | GetManyMembershipOrdersIncludeQueryParamParameterOneOf
  | MembershipOrderInclude;

export type GetManyMembershipOrdersSelectQueryParamParameterOneOf = null;

export type GetManyMembershipOrdersSelectQueryParamParameter =
  | GetManyMembershipOrdersSelectQueryParamParameterOneOf
  | MembershipOrderSelect;

/**
 * Query params for get-one-membership-order}
 */
export type GetOneMembershipOrderWithTrashedQueryParamParameter = boolean | null;

export type GetOneMembershipOrderWhereQueryParamParameterMembershipOrderItemsNone = MembershipOrderItemWhere;

export type GetOneMembershipOrderWhereQueryParamParameterMembershipOrderItemsEvery = MembershipOrderItemWhere;

export type GetOneMembershipOrderWhereQueryParamParameterMembershipOrderItemsSome = MembershipOrderItemWhere;

export type GetOneMembershipOrderWhereQueryParamParameterMembershipOrderItems = {
  some?: GetOneMembershipOrderWhereQueryParamParameterMembershipOrderItemsSome;
  every?: GetOneMembershipOrderWhereQueryParamParameterMembershipOrderItemsEvery;
  none?: GetOneMembershipOrderWhereQueryParamParameterMembershipOrderItemsNone;
} | null;

export type GetOneMembershipOrderWhereQueryParamParameterSalesOrderOneOfIsNot = SalesOrderWhere;

export type GetOneMembershipOrderWhereQueryParamParameterSalesOrderOneOfIs = SalesOrderWhere;

export type GetOneMembershipOrderWhereQueryParamParameterSalesOrderOneOf = {
  is?: GetOneMembershipOrderWhereQueryParamParameterSalesOrderOneOfIs;
  isNot?: GetOneMembershipOrderWhereQueryParamParameterSalesOrderOneOfIsNot;
};

export type GetOneMembershipOrderWhereQueryParamParameterSalesOrder =
  | SalesOrderWhere
  | GetOneMembershipOrderWhereQueryParamParameterSalesOrderOneOf;

export type GetOneMembershipOrderWhereQueryParamParameterMembership =
  | MembershipWhere
  | GetOneMembershipOrderWhereQueryParamParameterMembershipOneOf;

export type GetOneMembershipOrderWhereQueryParamParameter = {
  AND?: GetOneMembershipOrderWhereQueryParamParameterAnd;
  OR?: GetOneMembershipOrderWhereQueryParamParameterOr;
  NOT?: GetOneMembershipOrderWhereQueryParamParameterNot;
  id?: GetOneMembershipOrderWhereQueryParamParameterId;
  year?: GetOneMembershipOrderWhereQueryParamParameterYear;
  month?: GetOneMembershipOrderWhereQueryParamParameterMonth;
  membershipId?: GetOneMembershipOrderWhereQueryParamParameterMembershipId;
  salesOrderId?: GetOneMembershipOrderWhereQueryParamParameterSalesOrderId;
  orderData?: GetOneMembershipOrderWhereQueryParamParameterOrderData;
  processedAt?: GetOneMembershipOrderWhereQueryParamParameterProcessedAt;
  createdAt?: GetOneMembershipOrderWhereQueryParamParameterCreatedAt;
  updatedAt?: GetOneMembershipOrderWhereQueryParamParameterUpdatedAt;
  deletedAt?: GetOneMembershipOrderWhereQueryParamParameterDeletedAt;
  membership?: GetOneMembershipOrderWhereQueryParamParameterMembership;
  salesOrder?: GetOneMembershipOrderWhereQueryParamParameterSalesOrder;
  membershipOrderItems?: GetOneMembershipOrderWhereQueryParamParameterMembershipOrderItems;
};

export type GetOneMembershipOrderWhereQueryParamParameterMembershipOneOfIsNot = MembershipWhere;

export type GetOneMembershipOrderWhereQueryParamParameterMembershipOneOfIs = MembershipWhere;

export type GetOneMembershipOrderWhereQueryParamParameterMembershipOneOf = {
  is?: GetOneMembershipOrderWhereQueryParamParameterMembershipOneOfIs;
  isNot?: GetOneMembershipOrderWhereQueryParamParameterMembershipOneOfIsNot;
};

export type GetOneMembershipOrderWhereQueryParamParameterDeletedAtOneOfNot = string | DeletedAt;

export type GetOneMembershipOrderWhereQueryParamParameterDeletedAtOneOf = {
  equals?: GetOneMembershipOrderWhereQueryParamParameterDeletedAtOneOfEquals;
  in?: GetOneMembershipOrderWhereQueryParamParameterDeletedAtOneOfIn;
  notIn?: GetOneMembershipOrderWhereQueryParamParameterDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipOrderWhereQueryParamParameterDeletedAtOneOfNot;
};

export type GetOneMembershipOrderWhereQueryParamParameterDeletedAt =
  | string
  | GetOneMembershipOrderWhereQueryParamParameterDeletedAtOneOf;

export type GetOneMembershipOrderWhereQueryParamParameterDeletedAtOneOfNotIn = string | string[];

export type GetOneMembershipOrderWhereQueryParamParameterDeletedAtOneOfIn = string | string[];

export type GetOneMembershipOrderWhereQueryParamParameterDeletedAtOneOfEquals = string;

export type GetOneMembershipOrderWhereQueryParamParameterUpdatedAtOneOfNot = string | UpdatedAt;

export type GetOneMembershipOrderWhereQueryParamParameterUpdatedAtOneOfNotIn = string | string[];

export type GetOneMembershipOrderWhereQueryParamParameterUpdatedAtOneOfIn = string | string[];

export type GetOneMembershipOrderWhereQueryParamParameterUpdatedAtOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOrderWhereQueryParamParameterUpdatedAtOneOfIn;
  notIn?: GetOneMembershipOrderWhereQueryParamParameterUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipOrderWhereQueryParamParameterUpdatedAtOneOfNot;
};

export type GetOneMembershipOrderWhereQueryParamParameterUpdatedAt =
  | string
  | GetOneMembershipOrderWhereQueryParamParameterUpdatedAtOneOf;

export type GetOneMembershipOrderWhereQueryParamParameterCreatedAtOneOfNot = string | CreatedAt;

export type GetOneMembershipOrderWhereQueryParamParameterCreatedAtOneOfNotIn = string | string[];

export type GetOneMembershipOrderWhereQueryParamParameterCreatedAtOneOfIn = string | string[];

export type GetOneMembershipOrderWhereQueryParamParameterCreatedAtOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOrderWhereQueryParamParameterCreatedAtOneOfIn;
  notIn?: GetOneMembershipOrderWhereQueryParamParameterCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipOrderWhereQueryParamParameterCreatedAtOneOfNot;
};

export type GetOneMembershipOrderWhereQueryParamParameterCreatedAt =
  | string
  | GetOneMembershipOrderWhereQueryParamParameterCreatedAtOneOf;

export type GetOneMembershipOrderWhereQueryParamParameterProcessedAt =
  | string
  | GetOneMembershipOrderWhereQueryParamParameterProcessedAtOneOf;

export type GetOneMembershipOrderWhereQueryParamParameterProcessedAtOneOfNot = string | ProcessedAt;

export type GetOneMembershipOrderWhereQueryParamParameterProcessedAtOneOfNotIn = string | string[];

export type GetOneMembershipOrderWhereQueryParamParameterProcessedAtOneOfIn = string | string[];

export type GetOneMembershipOrderWhereQueryParamParameterProcessedAtOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOrderWhereQueryParamParameterProcessedAtOneOfIn;
  notIn?: GetOneMembershipOrderWhereQueryParamParameterProcessedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipOrderWhereQueryParamParameterProcessedAtOneOfNot;
};

export type GetOneMembershipOrderWhereQueryParamParameterOrderDataOneOfPath = string | string[];

export type GetOneMembershipOrderWhereQueryParamParameterOrderDataOneOf = {
  path?: GetOneMembershipOrderWhereQueryParamParameterOrderDataOneOfPath;
  equals?: unknown;
  not?: unknown;
  string_contains?: string | null;
  string_starts_with?: string | null;
  string_ends_with?: string | null;
  array_contains?: unknown;
  array_starts_with?: unknown;
  array_ends_with?: unknown;
};

export type GetOneMembershipOrderWhereQueryParamParameterOrderData =
  GetOneMembershipOrderWhereQueryParamParameterOrderDataOneOf;

export type GetOneMembershipOrderWhereQueryParamParameterSalesOrderId =
  | string
  | GetOneMembershipOrderWhereQueryParamParameterSalesOrderIdOneOf;

export type GetOneMembershipOrderWhereQueryParamParameterSalesOrderIdOneOfNot = string | SalesOrderId;

export type GetOneMembershipOrderWhereQueryParamParameterSalesOrderIdOneOfMode =
  typeof GetOneMembershipOrderWhereQueryParamParameterSalesOrderIdOneOfMode[keyof typeof GetOneMembershipOrderWhereQueryParamParameterSalesOrderIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOrderWhereQueryParamParameterSalesOrderIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipOrderWhereQueryParamParameterSalesOrderIdOneOfNotIn = string | string[];

export type GetOneMembershipOrderWhereQueryParamParameterSalesOrderIdOneOfIn = string | string[];

export type GetOneMembershipOrderWhereQueryParamParameterSalesOrderIdOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOrderWhereQueryParamParameterSalesOrderIdOneOfIn;
  notIn?: GetOneMembershipOrderWhereQueryParamParameterSalesOrderIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipOrderWhereQueryParamParameterSalesOrderIdOneOfMode;
  not?: GetOneMembershipOrderWhereQueryParamParameterSalesOrderIdOneOfNot;
};

export type GetOneMembershipOrderWhereQueryParamParameterMembershipId =
  | string
  | GetOneMembershipOrderWhereQueryParamParameterMembershipIdOneOf;

export type GetOneMembershipOrderWhereQueryParamParameterMembershipIdOneOfNot = string | MembershipId;

export type GetOneMembershipOrderWhereQueryParamParameterMembershipIdOneOfMode =
  typeof GetOneMembershipOrderWhereQueryParamParameterMembershipIdOneOfMode[keyof typeof GetOneMembershipOrderWhereQueryParamParameterMembershipIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOrderWhereQueryParamParameterMembershipIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipOrderWhereQueryParamParameterMembershipIdOneOfNotIn = string | string[];

export type GetOneMembershipOrderWhereQueryParamParameterMembershipIdOneOfIn = string | string[];

export type GetOneMembershipOrderWhereQueryParamParameterMembershipIdOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOrderWhereQueryParamParameterMembershipIdOneOfIn;
  notIn?: GetOneMembershipOrderWhereQueryParamParameterMembershipIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipOrderWhereQueryParamParameterMembershipIdOneOfMode;
  not?: GetOneMembershipOrderWhereQueryParamParameterMembershipIdOneOfNot;
};

export type GetOneMembershipOrderWhereQueryParamParameterMonth =
  | 'JANUARY'
  | 'FEBRUARY'
  | 'MARCH'
  | 'APRIL'
  | 'MAY'
  | 'JUNE'
  | 'JULY'
  | 'AUGUST'
  | 'SEPTEMBER'
  | 'OCTOBER'
  | 'NOVEMBER'
  | 'DECEMBER'
  | GetOneMembershipOrderWhereQueryParamParameterMonthOneOf;

export type GetOneMembershipOrderWhereQueryParamParameterMonthOneOfNot =
  | 'JANUARY'
  | 'FEBRUARY'
  | 'MARCH'
  | 'APRIL'
  | 'MAY'
  | 'JUNE'
  | 'JULY'
  | 'AUGUST'
  | 'SEPTEMBER'
  | 'OCTOBER'
  | 'NOVEMBER'
  | 'DECEMBER'
  | Month;

export type GetOneMembershipOrderWhereQueryParamParameterMonthOneOfNotInOneOfItem =
  typeof GetOneMembershipOrderWhereQueryParamParameterMonthOneOfNotInOneOfItem[keyof typeof GetOneMembershipOrderWhereQueryParamParameterMonthOneOfNotInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOrderWhereQueryParamParameterMonthOneOfNotInOneOfItem = {
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
} as const;

export type GetOneMembershipOrderWhereQueryParamParameterMonthOneOfNotIn =
  | 'JANUARY'
  | 'FEBRUARY'
  | 'MARCH'
  | 'APRIL'
  | 'MAY'
  | 'JUNE'
  | 'JULY'
  | 'AUGUST'
  | 'SEPTEMBER'
  | 'OCTOBER'
  | 'NOVEMBER'
  | 'DECEMBER'
  | GetOneMembershipOrderWhereQueryParamParameterMonthOneOfNotInOneOfItem[];

export type GetOneMembershipOrderWhereQueryParamParameterMonthOneOfInOneOfItem =
  typeof GetOneMembershipOrderWhereQueryParamParameterMonthOneOfInOneOfItem[keyof typeof GetOneMembershipOrderWhereQueryParamParameterMonthOneOfInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOrderWhereQueryParamParameterMonthOneOfInOneOfItem = {
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
} as const;

export type GetOneMembershipOrderWhereQueryParamParameterMonthOneOfIn =
  | 'JANUARY'
  | 'FEBRUARY'
  | 'MARCH'
  | 'APRIL'
  | 'MAY'
  | 'JUNE'
  | 'JULY'
  | 'AUGUST'
  | 'SEPTEMBER'
  | 'OCTOBER'
  | 'NOVEMBER'
  | 'DECEMBER'
  | GetOneMembershipOrderWhereQueryParamParameterMonthOneOfInOneOfItem[];

export type GetOneMembershipOrderWhereQueryParamParameterMonthOneOfEquals =
  typeof GetOneMembershipOrderWhereQueryParamParameterMonthOneOfEquals[keyof typeof GetOneMembershipOrderWhereQueryParamParameterMonthOneOfEquals];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOrderWhereQueryParamParameterMonthOneOfEquals = {
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
} as const;

export type GetOneMembershipOrderWhereQueryParamParameterMonthOneOf = {
  equals?: GetOneMembershipOrderWhereQueryParamParameterMonthOneOfEquals;
  in?: GetOneMembershipOrderWhereQueryParamParameterMonthOneOfIn;
  notIn?: GetOneMembershipOrderWhereQueryParamParameterMonthOneOfNotIn;
  not?: GetOneMembershipOrderWhereQueryParamParameterMonthOneOfNot;
} | null;

export type GetOneMembershipOrderWhereQueryParamParameterYear =
  | string
  | GetOneMembershipOrderWhereQueryParamParameterYearOneOf;

export type GetOneMembershipOrderWhereQueryParamParameterYearOneOfNot = string | Year;

export type GetOneMembershipOrderWhereQueryParamParameterYearOneOfMode =
  typeof GetOneMembershipOrderWhereQueryParamParameterYearOneOfMode[keyof typeof GetOneMembershipOrderWhereQueryParamParameterYearOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOrderWhereQueryParamParameterYearOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipOrderWhereQueryParamParameterYearOneOfNotIn = string | string[];

export type GetOneMembershipOrderWhereQueryParamParameterYearOneOfIn = string | string[];

export type GetOneMembershipOrderWhereQueryParamParameterYearOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOrderWhereQueryParamParameterYearOneOfIn;
  notIn?: GetOneMembershipOrderWhereQueryParamParameterYearOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipOrderWhereQueryParamParameterYearOneOfMode;
  not?: GetOneMembershipOrderWhereQueryParamParameterYearOneOfNot;
};

export type GetOneMembershipOrderWhereQueryParamParameterId =
  | string
  | GetOneMembershipOrderWhereQueryParamParameterIdOneOf;

export type GetOneMembershipOrderWhereQueryParamParameterIdOneOfNot = string | Id;

export type GetOneMembershipOrderWhereQueryParamParameterIdOneOfMode =
  typeof GetOneMembershipOrderWhereQueryParamParameterIdOneOfMode[keyof typeof GetOneMembershipOrderWhereQueryParamParameterIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipOrderWhereQueryParamParameterIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipOrderWhereQueryParamParameterIdOneOfNotIn = string | string[];

export type GetOneMembershipOrderWhereQueryParamParameterIdOneOfIn = string | string[];

export type GetOneMembershipOrderWhereQueryParamParameterIdOneOf = {
  equals?: string | null;
  in?: GetOneMembershipOrderWhereQueryParamParameterIdOneOfIn;
  notIn?: GetOneMembershipOrderWhereQueryParamParameterIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipOrderWhereQueryParamParameterIdOneOfMode;
  not?: GetOneMembershipOrderWhereQueryParamParameterIdOneOfNot;
};

export type GetOneMembershipOrderWhereQueryParamParameterNot = MembershipOrderWhere | MembershipOrderWhere[];

export type GetOneMembershipOrderWhereQueryParamParameterOr = MembershipOrderWhere | MembershipOrderWhere[];

export type GetOneMembershipOrderWhereQueryParamParameterAnd = MembershipOrderWhere | MembershipOrderWhere[];

export type GetOneMembershipOrderIncludeQueryParamParameterOneOf = null;

export type GetOneMembershipOrderIncludeQueryParamParameter =
  | GetOneMembershipOrderIncludeQueryParamParameterOneOf
  | MembershipOrderInclude;

export type GetOneMembershipOrderSelectQueryParamParameterOneOf = null;

export type GetOneMembershipOrderSelectQueryParamParameter =
  | GetOneMembershipOrderSelectQueryParamParameterOneOf
  | MembershipOrderSelect;

export type GetCalculateMembershipOrderPricingByIdItemsQueryParamParameterItemType =
  typeof GetCalculateMembershipOrderPricingByIdItemsQueryParamParameterItemType[keyof typeof GetCalculateMembershipOrderPricingByIdItemsQueryParamParameterItemType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCalculateMembershipOrderPricingByIdItemsQueryParamParameterItemType = {
  INCLUSION: 'INCLUSION',
  ADDITION: 'ADDITION',
} as const;

export type GetCalculateMembershipOrderPricingByIdItemsQueryParamParameterItem = {
  id: string;
  quantity: number;
  type: GetCalculateMembershipOrderPricingByIdItemsQueryParamParameterItemType;
};

/**
 * Query params for get-calculate-membership-order-pricing-by-id}
 */
export type GetCalculateMembershipOrderPricingByIdItemsQueryParamParameter =
  GetCalculateMembershipOrderPricingByIdItemsQueryParamParameterItem[];

/**
 * Query params for get-membership-order-by-id}
 */
export type GetMembershipOrderByIdWithTrashedQueryParamParameter = boolean | null;

export type GetMembershipOrderByIdIncludeQueryParamParameterOneOf = null;

export type GetMembershipOrderByIdIncludeQueryParamParameter =
  | GetMembershipOrderByIdIncludeQueryParamParameterOneOf
  | MembershipOrderInclude;

export type GetMembershipOrderByIdSelectQueryParamParameterOneOf = null;

export type GetMembershipOrderByIdSelectQueryParamParameter =
  | GetMembershipOrderByIdSelectQueryParamParameterOneOf
  | MembershipOrderSelect;

/**
 * Query params for get-many-membership-frequencies}
 */
export type GetManyMembershipFrequenciesWithTrashedQueryParamParameter = boolean | null;

export type GetManyMembershipFrequenciesWhereQueryParamParameterOneOf = null;

export type GetManyMembershipFrequenciesWhereQueryParamParameter =
  | GetManyMembershipFrequenciesWhereQueryParamParameterOneOf
  | MembershipFrequencyWhere;

export type GetManyMembershipFrequenciesIncludeQueryParamParameterOneOf = null;

export type GetManyMembershipFrequenciesIncludeQueryParamParameter =
  | GetManyMembershipFrequenciesIncludeQueryParamParameterOneOf
  | MembershipFrequencyInclude;

export type GetManyMembershipFrequenciesSelectQueryParamParameterOneOf = null;

export type GetManyMembershipFrequenciesSelectQueryParamParameter =
  | GetManyMembershipFrequenciesSelectQueryParamParameterOneOf
  | MembershipFrequencySelect;

/**
 * Query params for get-one-membership-frequency}
 */
export type GetOneMembershipFrequencyWithTrashedQueryParamParameter = boolean | null;

export type GetOneMembershipFrequencyWhereQueryParamParameterMembershipsNone = MembershipWhere;

export type GetOneMembershipFrequencyWhereQueryParamParameterMembershipsEvery = MembershipWhere;

export type GetOneMembershipFrequencyWhereQueryParamParameterMembershipsSome = MembershipWhere;

export type GetOneMembershipFrequencyWhereQueryParamParameterMemberships = {
  some?: GetOneMembershipFrequencyWhereQueryParamParameterMembershipsSome;
  every?: GetOneMembershipFrequencyWhereQueryParamParameterMembershipsEvery;
  none?: GetOneMembershipFrequencyWhereQueryParamParameterMembershipsNone;
} | null;

export type GetOneMembershipFrequencyWhereQueryParamParameterProductVariantOneOfIsNot = ProductVariantWhere;

export type GetOneMembershipFrequencyWhereQueryParamParameterProductVariantOneOfIs = ProductVariantWhere;

export type GetOneMembershipFrequencyWhereQueryParamParameterProductVariantOneOf = {
  is?: GetOneMembershipFrequencyWhereQueryParamParameterProductVariantOneOfIs;
  isNot?: GetOneMembershipFrequencyWhereQueryParamParameterProductVariantOneOfIsNot;
};

export type GetOneMembershipFrequencyWhereQueryParamParameterProductVariant =
  | ProductVariantWhere
  | GetOneMembershipFrequencyWhereQueryParamParameterProductVariantOneOf;

export type GetOneMembershipFrequencyWhereQueryParamParameterMembershipTierOneOfIsNot = MembershipTierWhere;

export type GetOneMembershipFrequencyWhereQueryParamParameterMembershipTierOneOfIs = MembershipTierWhere;

export type GetOneMembershipFrequencyWhereQueryParamParameterMembershipTierOneOf = {
  is?: GetOneMembershipFrequencyWhereQueryParamParameterMembershipTierOneOfIs;
  isNot?: GetOneMembershipFrequencyWhereQueryParamParameterMembershipTierOneOfIsNot;
};

export type GetOneMembershipFrequencyWhereQueryParamParameterMembershipTier =
  | MembershipTierWhere
  | GetOneMembershipFrequencyWhereQueryParamParameterMembershipTierOneOf;

export type GetOneMembershipFrequencyWhereQueryParamParameterDeletedAt =
  | string
  | GetOneMembershipFrequencyWhereQueryParamParameterDeletedAtOneOf;

export type GetOneMembershipFrequencyWhereQueryParamParameter = {
  AND?: GetOneMembershipFrequencyWhereQueryParamParameterAnd;
  OR?: GetOneMembershipFrequencyWhereQueryParamParameterOr;
  NOT?: GetOneMembershipFrequencyWhereQueryParamParameterNot;
  id?: GetOneMembershipFrequencyWhereQueryParamParameterId;
  duration?: GetOneMembershipFrequencyWhereQueryParamParameterDuration;
  units?: GetOneMembershipFrequencyWhereQueryParamParameterUnits;
  membershipTierId?: GetOneMembershipFrequencyWhereQueryParamParameterMembershipTierId;
  productVariantId?: GetOneMembershipFrequencyWhereQueryParamParameterProductVariantId;
  createdAt?: GetOneMembershipFrequencyWhereQueryParamParameterCreatedAt;
  updatedAt?: GetOneMembershipFrequencyWhereQueryParamParameterUpdatedAt;
  deletedAt?: GetOneMembershipFrequencyWhereQueryParamParameterDeletedAt;
  membershipTier?: GetOneMembershipFrequencyWhereQueryParamParameterMembershipTier;
  productVariant?: GetOneMembershipFrequencyWhereQueryParamParameterProductVariant;
  memberships?: GetOneMembershipFrequencyWhereQueryParamParameterMemberships;
};

export type GetOneMembershipFrequencyWhereQueryParamParameterDeletedAtOneOfNot = string | DeletedAt;

export type GetOneMembershipFrequencyWhereQueryParamParameterDeletedAtOneOfNotIn = string | string[];

export type GetOneMembershipFrequencyWhereQueryParamParameterDeletedAtOneOfIn = string | string[];

export type GetOneMembershipFrequencyWhereQueryParamParameterDeletedAtOneOfEquals = string;

export type GetOneMembershipFrequencyWhereQueryParamParameterDeletedAtOneOf = {
  equals?: GetOneMembershipFrequencyWhereQueryParamParameterDeletedAtOneOfEquals;
  in?: GetOneMembershipFrequencyWhereQueryParamParameterDeletedAtOneOfIn;
  notIn?: GetOneMembershipFrequencyWhereQueryParamParameterDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipFrequencyWhereQueryParamParameterDeletedAtOneOfNot;
};

export type GetOneMembershipFrequencyWhereQueryParamParameterUpdatedAtOneOfNot = string | UpdatedAt;

export type GetOneMembershipFrequencyWhereQueryParamParameterUpdatedAtOneOfNotIn = string | string[];

export type GetOneMembershipFrequencyWhereQueryParamParameterUpdatedAtOneOfIn = string | string[];

export type GetOneMembershipFrequencyWhereQueryParamParameterUpdatedAtOneOf = {
  equals?: string | null;
  in?: GetOneMembershipFrequencyWhereQueryParamParameterUpdatedAtOneOfIn;
  notIn?: GetOneMembershipFrequencyWhereQueryParamParameterUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipFrequencyWhereQueryParamParameterUpdatedAtOneOfNot;
};

export type GetOneMembershipFrequencyWhereQueryParamParameterUpdatedAt =
  | string
  | GetOneMembershipFrequencyWhereQueryParamParameterUpdatedAtOneOf;

export type GetOneMembershipFrequencyWhereQueryParamParameterCreatedAtOneOfNot = string | CreatedAt;

export type GetOneMembershipFrequencyWhereQueryParamParameterCreatedAtOneOfNotIn = string | string[];

export type GetOneMembershipFrequencyWhereQueryParamParameterCreatedAtOneOfIn = string | string[];

export type GetOneMembershipFrequencyWhereQueryParamParameterCreatedAtOneOf = {
  equals?: string | null;
  in?: GetOneMembershipFrequencyWhereQueryParamParameterCreatedAtOneOfIn;
  notIn?: GetOneMembershipFrequencyWhereQueryParamParameterCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipFrequencyWhereQueryParamParameterCreatedAtOneOfNot;
};

export type GetOneMembershipFrequencyWhereQueryParamParameterCreatedAt =
  | string
  | GetOneMembershipFrequencyWhereQueryParamParameterCreatedAtOneOf;

export type GetOneMembershipFrequencyWhereQueryParamParameterProductVariantId =
  | string
  | GetOneMembershipFrequencyWhereQueryParamParameterProductVariantIdOneOf;

export type GetOneMembershipFrequencyWhereQueryParamParameterProductVariantIdOneOfNot = string | ProductVariantId;

export type GetOneMembershipFrequencyWhereQueryParamParameterProductVariantIdOneOfMode =
  typeof GetOneMembershipFrequencyWhereQueryParamParameterProductVariantIdOneOfMode[keyof typeof GetOneMembershipFrequencyWhereQueryParamParameterProductVariantIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipFrequencyWhereQueryParamParameterProductVariantIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipFrequencyWhereQueryParamParameterProductVariantIdOneOfNotIn = string | string[];

export type GetOneMembershipFrequencyWhereQueryParamParameterProductVariantIdOneOfIn = string | string[];

export type GetOneMembershipFrequencyWhereQueryParamParameterProductVariantIdOneOf = {
  equals?: string | null;
  in?: GetOneMembershipFrequencyWhereQueryParamParameterProductVariantIdOneOfIn;
  notIn?: GetOneMembershipFrequencyWhereQueryParamParameterProductVariantIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipFrequencyWhereQueryParamParameterProductVariantIdOneOfMode;
  not?: GetOneMembershipFrequencyWhereQueryParamParameterProductVariantIdOneOfNot;
};

export type GetOneMembershipFrequencyWhereQueryParamParameterMembershipTierId =
  | string
  | GetOneMembershipFrequencyWhereQueryParamParameterMembershipTierIdOneOf;

export type GetOneMembershipFrequencyWhereQueryParamParameterMembershipTierIdOneOfNot = string | MembershipTierId;

export type GetOneMembershipFrequencyWhereQueryParamParameterMembershipTierIdOneOfMode =
  typeof GetOneMembershipFrequencyWhereQueryParamParameterMembershipTierIdOneOfMode[keyof typeof GetOneMembershipFrequencyWhereQueryParamParameterMembershipTierIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipFrequencyWhereQueryParamParameterMembershipTierIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipFrequencyWhereQueryParamParameterMembershipTierIdOneOfNotIn = string | string[];

export type GetOneMembershipFrequencyWhereQueryParamParameterMembershipTierIdOneOfIn = string | string[];

export type GetOneMembershipFrequencyWhereQueryParamParameterMembershipTierIdOneOf = {
  equals?: string | null;
  in?: GetOneMembershipFrequencyWhereQueryParamParameterMembershipTierIdOneOfIn;
  notIn?: GetOneMembershipFrequencyWhereQueryParamParameterMembershipTierIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipFrequencyWhereQueryParamParameterMembershipTierIdOneOfMode;
  not?: GetOneMembershipFrequencyWhereQueryParamParameterMembershipTierIdOneOfNot;
};

export type GetOneMembershipFrequencyWhereQueryParamParameterUnits =
  | 'MONTHS'
  | 'YEARS'
  | GetOneMembershipFrequencyWhereQueryParamParameterUnitsOneOf;

export type GetOneMembershipFrequencyWhereQueryParamParameterUnitsOneOfNot = 'MONTHS' | 'YEARS' | Units;

export type GetOneMembershipFrequencyWhereQueryParamParameterUnitsOneOfNotInOneOfItem =
  typeof GetOneMembershipFrequencyWhereQueryParamParameterUnitsOneOfNotInOneOfItem[keyof typeof GetOneMembershipFrequencyWhereQueryParamParameterUnitsOneOfNotInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipFrequencyWhereQueryParamParameterUnitsOneOfNotInOneOfItem = {
  MONTHS: 'MONTHS',
  YEARS: 'YEARS',
} as const;

export type GetOneMembershipFrequencyWhereQueryParamParameterUnitsOneOfNotIn =
  | 'MONTHS'
  | 'YEARS'
  | GetOneMembershipFrequencyWhereQueryParamParameterUnitsOneOfNotInOneOfItem[];

export type GetOneMembershipFrequencyWhereQueryParamParameterUnitsOneOfInOneOfItem =
  typeof GetOneMembershipFrequencyWhereQueryParamParameterUnitsOneOfInOneOfItem[keyof typeof GetOneMembershipFrequencyWhereQueryParamParameterUnitsOneOfInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipFrequencyWhereQueryParamParameterUnitsOneOfInOneOfItem = {
  MONTHS: 'MONTHS',
  YEARS: 'YEARS',
} as const;

export type GetOneMembershipFrequencyWhereQueryParamParameterUnitsOneOfIn =
  | 'MONTHS'
  | 'YEARS'
  | GetOneMembershipFrequencyWhereQueryParamParameterUnitsOneOfInOneOfItem[];

export type GetOneMembershipFrequencyWhereQueryParamParameterUnitsOneOfEquals =
  typeof GetOneMembershipFrequencyWhereQueryParamParameterUnitsOneOfEquals[keyof typeof GetOneMembershipFrequencyWhereQueryParamParameterUnitsOneOfEquals];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipFrequencyWhereQueryParamParameterUnitsOneOfEquals = {
  MONTHS: 'MONTHS',
  YEARS: 'YEARS',
} as const;

export type GetOneMembershipFrequencyWhereQueryParamParameterUnitsOneOf = {
  equals?: GetOneMembershipFrequencyWhereQueryParamParameterUnitsOneOfEquals;
  in?: GetOneMembershipFrequencyWhereQueryParamParameterUnitsOneOfIn;
  notIn?: GetOneMembershipFrequencyWhereQueryParamParameterUnitsOneOfNotIn;
  not?: GetOneMembershipFrequencyWhereQueryParamParameterUnitsOneOfNot;
} | null;

export type GetOneMembershipFrequencyWhereQueryParamParameterDuration =
  | number
  | GetOneMembershipFrequencyWhereQueryParamParameterDurationOneOf;

export type GetOneMembershipFrequencyWhereQueryParamParameterDurationOneOfNot = number | Duration;

export type GetOneMembershipFrequencyWhereQueryParamParameterDurationOneOfNotIn = number | number[];

export type GetOneMembershipFrequencyWhereQueryParamParameterDurationOneOfIn = number | number[];

export type GetOneMembershipFrequencyWhereQueryParamParameterDurationOneOf = {
  equals?: number | null;
  in?: GetOneMembershipFrequencyWhereQueryParamParameterDurationOneOfIn;
  notIn?: GetOneMembershipFrequencyWhereQueryParamParameterDurationOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: GetOneMembershipFrequencyWhereQueryParamParameterDurationOneOfNot;
};

export type GetOneMembershipFrequencyWhereQueryParamParameterId =
  | string
  | GetOneMembershipFrequencyWhereQueryParamParameterIdOneOf;

export type GetOneMembershipFrequencyWhereQueryParamParameterIdOneOfNot = string | Id;

export type GetOneMembershipFrequencyWhereQueryParamParameterIdOneOfMode =
  typeof GetOneMembershipFrequencyWhereQueryParamParameterIdOneOfMode[keyof typeof GetOneMembershipFrequencyWhereQueryParamParameterIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipFrequencyWhereQueryParamParameterIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipFrequencyWhereQueryParamParameterIdOneOfNotIn = string | string[];

export type GetOneMembershipFrequencyWhereQueryParamParameterIdOneOfIn = string | string[];

export type GetOneMembershipFrequencyWhereQueryParamParameterIdOneOf = {
  equals?: string | null;
  in?: GetOneMembershipFrequencyWhereQueryParamParameterIdOneOfIn;
  notIn?: GetOneMembershipFrequencyWhereQueryParamParameterIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipFrequencyWhereQueryParamParameterIdOneOfMode;
  not?: GetOneMembershipFrequencyWhereQueryParamParameterIdOneOfNot;
};

export type GetOneMembershipFrequencyWhereQueryParamParameterNot =
  | MembershipFrequencyWhere
  | MembershipFrequencyWhere[];

export type GetOneMembershipFrequencyWhereQueryParamParameterOr = MembershipFrequencyWhere | MembershipFrequencyWhere[];

export type GetOneMembershipFrequencyWhereQueryParamParameterAnd =
  | MembershipFrequencyWhere
  | MembershipFrequencyWhere[];

export type GetOneMembershipFrequencyIncludeQueryParamParameterOneOf = null;

export type GetOneMembershipFrequencyIncludeQueryParamParameter =
  | GetOneMembershipFrequencyIncludeQueryParamParameterOneOf
  | MembershipFrequencyInclude;

export type GetOneMembershipFrequencySelectQueryParamParameterOneOf = null;

export type GetOneMembershipFrequencySelectQueryParamParameter =
  | GetOneMembershipFrequencySelectQueryParamParameterOneOf
  | MembershipFrequencySelect;

/**
 * Query params for get-membership-frequency-by-id}
 */
export type GetMembershipFrequencyByIdWithTrashedQueryParamParameter = boolean | null;

export type GetMembershipFrequencyByIdIncludeQueryParamParameterOneOf = null;

export type GetMembershipFrequencyByIdIncludeQueryParamParameter =
  | GetMembershipFrequencyByIdIncludeQueryParamParameterOneOf
  | MembershipFrequencyInclude;

export type GetMembershipFrequencyByIdSelectQueryParamParameterOneOf = null;

export type GetMembershipFrequencyByIdSelectQueryParamParameter =
  | GetMembershipFrequencyByIdSelectQueryParamParameterOneOf
  | MembershipFrequencySelect;

/**
 * Query params for get-many-membership-tiers}
 */
export type GetManyMembershipTiersWithTrashedQueryParamParameter = boolean | null;

export type GetManyMembershipTiersWhereQueryParamParameterOneOf = null;

export type GetManyMembershipTiersWhereQueryParamParameter =
  | GetManyMembershipTiersWhereQueryParamParameterOneOf
  | MembershipTierWhere;

export type GetManyMembershipTiersIncludeQueryParamParameterOneOf = null;

export type GetManyMembershipTiersIncludeQueryParamParameter =
  | GetManyMembershipTiersIncludeQueryParamParameterOneOf
  | MembershipTierInclude;

export type GetManyMembershipTiersSelectQueryParamParameterOneOf = null;

export type GetManyMembershipTiersSelectQueryParamParameter =
  | GetManyMembershipTiersSelectQueryParamParameterOneOf
  | MembershipTierSelect;

/**
 * Query params for get-one-membership-tier}
 */
export type GetOneMembershipTierWithTrashedQueryParamParameter = boolean | null;

export type GetOneMembershipTierWhereQueryParamParameterMembershipOfferingsNone = MembershipOfferingWhere;

export type GetOneMembershipTierWhereQueryParamParameterMembershipOfferingsEvery = MembershipOfferingWhere;

export type GetOneMembershipTierWhereQueryParamParameterMembershipOfferingsSome = MembershipOfferingWhere;

export type GetOneMembershipTierWhereQueryParamParameterMembershipOfferings = {
  some?: GetOneMembershipTierWhereQueryParamParameterMembershipOfferingsSome;
  every?: GetOneMembershipTierWhereQueryParamParameterMembershipOfferingsEvery;
  none?: GetOneMembershipTierWhereQueryParamParameterMembershipOfferingsNone;
} | null;

export type GetOneMembershipTierWhereQueryParamParameter = {
  AND?: GetOneMembershipTierWhereQueryParamParameterAnd;
  OR?: GetOneMembershipTierWhereQueryParamParameterOr;
  NOT?: GetOneMembershipTierWhereQueryParamParameterNot;
  id?: GetOneMembershipTierWhereQueryParamParameterId;
  name?: GetOneMembershipTierWhereQueryParamParameterName;
  description?: GetOneMembershipTierWhereQueryParamParameterDescription;
  isPublic?: GetOneMembershipTierWhereQueryParamParameterIsPublic;
  discountRules?: GetOneMembershipTierWhereQueryParamParameterDiscountRules;
  productId?: GetOneMembershipTierWhereQueryParamParameterProductId;
  createdAt?: GetOneMembershipTierWhereQueryParamParameterCreatedAt;
  updatedAt?: GetOneMembershipTierWhereQueryParamParameterUpdatedAt;
  deletedAt?: GetOneMembershipTierWhereQueryParamParameterDeletedAt;
  product?: GetOneMembershipTierWhereQueryParamParameterProduct;
  membershipFrequencies?: GetOneMembershipTierWhereQueryParamParameterMembershipFrequencies;
  membershipOfferings?: GetOneMembershipTierWhereQueryParamParameterMembershipOfferings;
};

export type GetOneMembershipTierWhereQueryParamParameterMembershipFrequenciesNone = MembershipFrequencyWhere;

export type GetOneMembershipTierWhereQueryParamParameterMembershipFrequenciesEvery = MembershipFrequencyWhere;

export type GetOneMembershipTierWhereQueryParamParameterMembershipFrequenciesSome = MembershipFrequencyWhere;

export type GetOneMembershipTierWhereQueryParamParameterMembershipFrequencies = {
  some?: GetOneMembershipTierWhereQueryParamParameterMembershipFrequenciesSome;
  every?: GetOneMembershipTierWhereQueryParamParameterMembershipFrequenciesEvery;
  none?: GetOneMembershipTierWhereQueryParamParameterMembershipFrequenciesNone;
} | null;

export type GetOneMembershipTierWhereQueryParamParameterProductOneOfIsNot = ProductWhere;

export type GetOneMembershipTierWhereQueryParamParameterProductOneOfIs = ProductWhere;

export type GetOneMembershipTierWhereQueryParamParameterProductOneOf = {
  is?: GetOneMembershipTierWhereQueryParamParameterProductOneOfIs;
  isNot?: GetOneMembershipTierWhereQueryParamParameterProductOneOfIsNot;
};

export type GetOneMembershipTierWhereQueryParamParameterProduct =
  | ProductWhere
  | GetOneMembershipTierWhereQueryParamParameterProductOneOf;

export type GetOneMembershipTierWhereQueryParamParameterDeletedAt =
  | string
  | GetOneMembershipTierWhereQueryParamParameterDeletedAtOneOf;

export type GetOneMembershipTierWhereQueryParamParameterDeletedAtOneOfNot = string | DeletedAt;

export type GetOneMembershipTierWhereQueryParamParameterDeletedAtOneOfNotIn = string | string[];

export type GetOneMembershipTierWhereQueryParamParameterDeletedAtOneOfIn = string | string[];

export type GetOneMembershipTierWhereQueryParamParameterDeletedAtOneOfEquals = string;

export type GetOneMembershipTierWhereQueryParamParameterDeletedAtOneOf = {
  equals?: GetOneMembershipTierWhereQueryParamParameterDeletedAtOneOfEquals;
  in?: GetOneMembershipTierWhereQueryParamParameterDeletedAtOneOfIn;
  notIn?: GetOneMembershipTierWhereQueryParamParameterDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipTierWhereQueryParamParameterDeletedAtOneOfNot;
};

export type GetOneMembershipTierWhereQueryParamParameterUpdatedAtOneOfNot = string | UpdatedAt;

export type GetOneMembershipTierWhereQueryParamParameterUpdatedAtOneOfNotIn = string | string[];

export type GetOneMembershipTierWhereQueryParamParameterUpdatedAtOneOfIn = string | string[];

export type GetOneMembershipTierWhereQueryParamParameterUpdatedAtOneOf = {
  equals?: string | null;
  in?: GetOneMembershipTierWhereQueryParamParameterUpdatedAtOneOfIn;
  notIn?: GetOneMembershipTierWhereQueryParamParameterUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipTierWhereQueryParamParameterUpdatedAtOneOfNot;
};

export type GetOneMembershipTierWhereQueryParamParameterUpdatedAt =
  | string
  | GetOneMembershipTierWhereQueryParamParameterUpdatedAtOneOf;

export type GetOneMembershipTierWhereQueryParamParameterCreatedAt =
  | string
  | GetOneMembershipTierWhereQueryParamParameterCreatedAtOneOf;

export type GetOneMembershipTierWhereQueryParamParameterCreatedAtOneOfNot = string | CreatedAt;

export type GetOneMembershipTierWhereQueryParamParameterCreatedAtOneOfNotIn = string | string[];

export type GetOneMembershipTierWhereQueryParamParameterCreatedAtOneOfIn = string | string[];

export type GetOneMembershipTierWhereQueryParamParameterCreatedAtOneOf = {
  equals?: string | null;
  in?: GetOneMembershipTierWhereQueryParamParameterCreatedAtOneOfIn;
  notIn?: GetOneMembershipTierWhereQueryParamParameterCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipTierWhereQueryParamParameterCreatedAtOneOfNot;
};

export type GetOneMembershipTierWhereQueryParamParameterProductIdOneOfNot = string | ProductId;

export type GetOneMembershipTierWhereQueryParamParameterProductIdOneOfMode =
  typeof GetOneMembershipTierWhereQueryParamParameterProductIdOneOfMode[keyof typeof GetOneMembershipTierWhereQueryParamParameterProductIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipTierWhereQueryParamParameterProductIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipTierWhereQueryParamParameterProductIdOneOfNotIn = string | string[];

export type GetOneMembershipTierWhereQueryParamParameterProductIdOneOfIn = string | string[];

export type GetOneMembershipTierWhereQueryParamParameterProductIdOneOf = {
  equals?: string | null;
  in?: GetOneMembershipTierWhereQueryParamParameterProductIdOneOfIn;
  notIn?: GetOneMembershipTierWhereQueryParamParameterProductIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipTierWhereQueryParamParameterProductIdOneOfMode;
  not?: GetOneMembershipTierWhereQueryParamParameterProductIdOneOfNot;
};

export type GetOneMembershipTierWhereQueryParamParameterProductId =
  | string
  | GetOneMembershipTierWhereQueryParamParameterProductIdOneOf;

export type GetOneMembershipTierWhereQueryParamParameterDiscountRulesOneOfPath = string | string[];

export type GetOneMembershipTierWhereQueryParamParameterDiscountRulesOneOf = {
  path?: GetOneMembershipTierWhereQueryParamParameterDiscountRulesOneOfPath;
  equals?: unknown;
  not?: unknown;
  string_contains?: string | null;
  string_starts_with?: string | null;
  string_ends_with?: string | null;
  array_contains?: unknown;
  array_starts_with?: unknown;
  array_ends_with?: unknown;
};

export type GetOneMembershipTierWhereQueryParamParameterDiscountRules =
  GetOneMembershipTierWhereQueryParamParameterDiscountRulesOneOf;

export type GetOneMembershipTierWhereQueryParamParameterIsPublicOneOfNot = boolean | IsPublic;

export type GetOneMembershipTierWhereQueryParamParameterIsPublicOneOf = {
  equals?: boolean | null;
  not?: GetOneMembershipTierWhereQueryParamParameterIsPublicOneOfNot;
};

export type GetOneMembershipTierWhereQueryParamParameterIsPublic =
  | boolean
  | GetOneMembershipTierWhereQueryParamParameterIsPublicOneOf;

export type GetOneMembershipTierWhereQueryParamParameterDescription =
  | string
  | GetOneMembershipTierWhereQueryParamParameterDescriptionOneOf;

export type GetOneMembershipTierWhereQueryParamParameterDescriptionOneOfNot = string | Description;

export type GetOneMembershipTierWhereQueryParamParameterDescriptionOneOfMode =
  typeof GetOneMembershipTierWhereQueryParamParameterDescriptionOneOfMode[keyof typeof GetOneMembershipTierWhereQueryParamParameterDescriptionOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipTierWhereQueryParamParameterDescriptionOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipTierWhereQueryParamParameterDescriptionOneOfNotIn = string | string[];

export type GetOneMembershipTierWhereQueryParamParameterDescriptionOneOfIn = string | string[];

export type GetOneMembershipTierWhereQueryParamParameterDescriptionOneOfEquals = string;

export type GetOneMembershipTierWhereQueryParamParameterDescriptionOneOf = {
  equals?: GetOneMembershipTierWhereQueryParamParameterDescriptionOneOfEquals;
  in?: GetOneMembershipTierWhereQueryParamParameterDescriptionOneOfIn;
  notIn?: GetOneMembershipTierWhereQueryParamParameterDescriptionOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipTierWhereQueryParamParameterDescriptionOneOfMode;
  not?: GetOneMembershipTierWhereQueryParamParameterDescriptionOneOfNot;
};

export type GetOneMembershipTierWhereQueryParamParameterNameOneOfNot = string | Name;

export type GetOneMembershipTierWhereQueryParamParameterNameOneOfMode =
  typeof GetOneMembershipTierWhereQueryParamParameterNameOneOfMode[keyof typeof GetOneMembershipTierWhereQueryParamParameterNameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipTierWhereQueryParamParameterNameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipTierWhereQueryParamParameterNameOneOfNotIn = string | string[];

export type GetOneMembershipTierWhereQueryParamParameterNameOneOfIn = string | string[];

export type GetOneMembershipTierWhereQueryParamParameterNameOneOf = {
  equals?: string | null;
  in?: GetOneMembershipTierWhereQueryParamParameterNameOneOfIn;
  notIn?: GetOneMembershipTierWhereQueryParamParameterNameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipTierWhereQueryParamParameterNameOneOfMode;
  not?: GetOneMembershipTierWhereQueryParamParameterNameOneOfNot;
};

export type GetOneMembershipTierWhereQueryParamParameterName =
  | string
  | GetOneMembershipTierWhereQueryParamParameterNameOneOf;

export type GetOneMembershipTierWhereQueryParamParameterId =
  | string
  | GetOneMembershipTierWhereQueryParamParameterIdOneOf;

export type GetOneMembershipTierWhereQueryParamParameterIdOneOfNot = string | Id;

export type GetOneMembershipTierWhereQueryParamParameterIdOneOfMode =
  typeof GetOneMembershipTierWhereQueryParamParameterIdOneOfMode[keyof typeof GetOneMembershipTierWhereQueryParamParameterIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipTierWhereQueryParamParameterIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipTierWhereQueryParamParameterIdOneOfNotIn = string | string[];

export type GetOneMembershipTierWhereQueryParamParameterIdOneOfIn = string | string[];

export type GetOneMembershipTierWhereQueryParamParameterIdOneOf = {
  equals?: string | null;
  in?: GetOneMembershipTierWhereQueryParamParameterIdOneOfIn;
  notIn?: GetOneMembershipTierWhereQueryParamParameterIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipTierWhereQueryParamParameterIdOneOfMode;
  not?: GetOneMembershipTierWhereQueryParamParameterIdOneOfNot;
};

export type GetOneMembershipTierWhereQueryParamParameterNot = MembershipTierWhere | MembershipTierWhere[];

export type GetOneMembershipTierWhereQueryParamParameterOr = MembershipTierWhere | MembershipTierWhere[];

export type GetOneMembershipTierWhereQueryParamParameterAnd = MembershipTierWhere | MembershipTierWhere[];

export type GetOneMembershipTierIncludeQueryParamParameterOneOf = null;

export type GetOneMembershipTierIncludeQueryParamParameter =
  | GetOneMembershipTierIncludeQueryParamParameterOneOf
  | MembershipTierInclude;

export type GetOneMembershipTierSelectQueryParamParameterOneOf = null;

export type GetOneMembershipTierSelectQueryParamParameter =
  | GetOneMembershipTierSelectQueryParamParameterOneOf
  | MembershipTierSelect;

/**
 * Query params for get-membership-tier-by-id}
 */
export type GetMembershipTierByIdWithTrashedQueryParamParameter = boolean | null;

export type GetMembershipTierByIdIncludeQueryParamParameterOneOf = null;

export type GetMembershipTierByIdIncludeQueryParamParameter =
  | GetMembershipTierByIdIncludeQueryParamParameterOneOf
  | MembershipTierInclude;

export type GetMembershipTierByIdSelectQueryParamParameterOneOf = null;

export type GetMembershipTierByIdSelectQueryParamParameter =
  | GetMembershipTierByIdSelectQueryParamParameterOneOf
  | MembershipTierSelect;

/**
 * Query params for get-many-memberships}
 */
export type GetManyMembershipsWithTrashedQueryParamParameter = boolean | null;

export type GetManyMembershipsWhereQueryParamParameterOneOf = null;

export type GetManyMembershipsWhereQueryParamParameter =
  | GetManyMembershipsWhereQueryParamParameterOneOf
  | MembershipWhere;

export type GetManyMembershipsIncludeQueryParamParameterOneOf = null;

export type GetManyMembershipsIncludeQueryParamParameter =
  | GetManyMembershipsIncludeQueryParamParameterOneOf
  | MembershipInclude;

export type GetManyMembershipsSelectQueryParamParameterOneOf = null;

export type GetManyMembershipsSelectQueryParamParameter =
  | GetManyMembershipsSelectQueryParamParameterOneOf
  | MembershipSelect;

/**
 * Query params for get-one-membership}
 */
export type GetOneMembershipWithTrashedQueryParamParameter = boolean | null;

export type GetOneMembershipWhereQueryParamParameterMembershipOrdersNone = MembershipOrderWhere;

export type GetOneMembershipWhereQueryParamParameterMembershipOrdersEvery = MembershipOrderWhere;

export type GetOneMembershipWhereQueryParamParameterMembershipOrdersSome = MembershipOrderWhere;

export type GetOneMembershipWhereQueryParamParameterMembershipOrders = {
  some?: GetOneMembershipWhereQueryParamParameterMembershipOrdersSome;
  every?: GetOneMembershipWhereQueryParamParameterMembershipOrdersEvery;
  none?: GetOneMembershipWhereQueryParamParameterMembershipOrdersNone;
} | null;

export type GetOneMembershipWhereQueryParamParameterAccount =
  | AccountWhere
  | GetOneMembershipWhereQueryParamParameterAccountOneOf;

export type GetOneMembershipWhereQueryParamParameterAccountOneOfIsNot = AccountWhere;

export type GetOneMembershipWhereQueryParamParameterAccountOneOfIs = AccountWhere;

export type GetOneMembershipWhereQueryParamParameterAccountOneOf = {
  is?: GetOneMembershipWhereQueryParamParameterAccountOneOfIs;
  isNot?: GetOneMembershipWhereQueryParamParameterAccountOneOfIsNot;
};

export type GetOneMembershipWhereQueryParamParameterMembershipFrequency =
  | MembershipFrequencyWhere
  | GetOneMembershipWhereQueryParamParameterMembershipFrequencyOneOf;

export type GetOneMembershipWhereQueryParamParameterMembershipFrequencyOneOfIsNot = MembershipFrequencyWhere;

export type GetOneMembershipWhereQueryParamParameterMembershipFrequencyOneOfIs = MembershipFrequencyWhere;

export type GetOneMembershipWhereQueryParamParameterMembershipFrequencyOneOf = {
  is?: GetOneMembershipWhereQueryParamParameterMembershipFrequencyOneOfIs;
  isNot?: GetOneMembershipWhereQueryParamParameterMembershipFrequencyOneOfIsNot;
};

export type GetOneMembershipWhereQueryParamParameterDeletedAt =
  | string
  | GetOneMembershipWhereQueryParamParameterDeletedAtOneOf;

export type GetOneMembershipWhereQueryParamParameterDeletedAtOneOfNot = string | DeletedAt;

export type GetOneMembershipWhereQueryParamParameterDeletedAtOneOfNotIn = string | string[];

export type GetOneMembershipWhereQueryParamParameterDeletedAtOneOfIn = string | string[];

export type GetOneMembershipWhereQueryParamParameterDeletedAtOneOfEquals = string;

export type GetOneMembershipWhereQueryParamParameterDeletedAtOneOf = {
  equals?: GetOneMembershipWhereQueryParamParameterDeletedAtOneOfEquals;
  in?: GetOneMembershipWhereQueryParamParameterDeletedAtOneOfIn;
  notIn?: GetOneMembershipWhereQueryParamParameterDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipWhereQueryParamParameterDeletedAtOneOfNot;
};

export type GetOneMembershipWhereQueryParamParameterUpdatedAt =
  | string
  | GetOneMembershipWhereQueryParamParameterUpdatedAtOneOf;

export type GetOneMembershipWhereQueryParamParameterUpdatedAtOneOfNot = string | UpdatedAt;

export type GetOneMembershipWhereQueryParamParameterUpdatedAtOneOfNotIn = string | string[];

export type GetOneMembershipWhereQueryParamParameterUpdatedAtOneOfIn = string | string[];

export type GetOneMembershipWhereQueryParamParameterUpdatedAtOneOf = {
  equals?: string | null;
  in?: GetOneMembershipWhereQueryParamParameterUpdatedAtOneOfIn;
  notIn?: GetOneMembershipWhereQueryParamParameterUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipWhereQueryParamParameterUpdatedAtOneOfNot;
};

export type GetOneMembershipWhereQueryParamParameterCreatedAt =
  | string
  | GetOneMembershipWhereQueryParamParameterCreatedAtOneOf;

export type GetOneMembershipWhereQueryParamParameterCreatedAtOneOfNot = string | CreatedAt;

export type GetOneMembershipWhereQueryParamParameterCreatedAtOneOfNotIn = string | string[];

export type GetOneMembershipWhereQueryParamParameterCreatedAtOneOfIn = string | string[];

export type GetOneMembershipWhereQueryParamParameterCreatedAtOneOf = {
  equals?: string | null;
  in?: GetOneMembershipWhereQueryParamParameterCreatedAtOneOfIn;
  notIn?: GetOneMembershipWhereQueryParamParameterCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipWhereQueryParamParameterCreatedAtOneOfNot;
};

export type GetOneMembershipWhereQueryParamParameterCurrentPeriodEndAt =
  | string
  | GetOneMembershipWhereQueryParamParameterCurrentPeriodEndAtOneOf;

export type GetOneMembershipWhereQueryParamParameterCurrentPeriodEndAtOneOfNot = string | CurrentPeriodEndAt;

export type GetOneMembershipWhereQueryParamParameterCurrentPeriodEndAtOneOfNotIn = string | string[];

export type GetOneMembershipWhereQueryParamParameterCurrentPeriodEndAtOneOfIn = string | string[];

export type GetOneMembershipWhereQueryParamParameterCurrentPeriodEndAtOneOfEquals = string;

export type GetOneMembershipWhereQueryParamParameterCurrentPeriodEndAtOneOf = {
  equals?: GetOneMembershipWhereQueryParamParameterCurrentPeriodEndAtOneOfEquals;
  in?: GetOneMembershipWhereQueryParamParameterCurrentPeriodEndAtOneOfIn;
  notIn?: GetOneMembershipWhereQueryParamParameterCurrentPeriodEndAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipWhereQueryParamParameterCurrentPeriodEndAtOneOfNot;
};

export type GetOneMembershipWhereQueryParamParameterCurrentPeriodStartAt =
  | string
  | GetOneMembershipWhereQueryParamParameterCurrentPeriodStartAtOneOf;

export type GetOneMembershipWhereQueryParamParameterCurrentPeriodStartAtOneOfNot = string | CurrentPeriodStartAt;

export type GetOneMembershipWhereQueryParamParameterCurrentPeriodStartAtOneOfNotIn = string | string[];

export type GetOneMembershipWhereQueryParamParameterCurrentPeriodStartAtOneOfIn = string | string[];

export type GetOneMembershipWhereQueryParamParameterCurrentPeriodStartAtOneOfEquals = string;

export type GetOneMembershipWhereQueryParamParameterCurrentPeriodStartAtOneOf = {
  equals?: GetOneMembershipWhereQueryParamParameterCurrentPeriodStartAtOneOfEquals;
  in?: GetOneMembershipWhereQueryParamParameterCurrentPeriodStartAtOneOfIn;
  notIn?: GetOneMembershipWhereQueryParamParameterCurrentPeriodStartAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipWhereQueryParamParameterCurrentPeriodStartAtOneOfNot;
};

export type GetOneMembershipWhereQueryParamParameterEndAt = string | GetOneMembershipWhereQueryParamParameterEndAtOneOf;

export type GetOneMembershipWhereQueryParamParameterEndAtOneOfNot = string | EndAt;

export type GetOneMembershipWhereQueryParamParameterEndAtOneOfNotIn = string | string[];

export type GetOneMembershipWhereQueryParamParameterEndAtOneOfIn = string | string[];

export type GetOneMembershipWhereQueryParamParameterEndAtOneOfEquals = string;

export type GetOneMembershipWhereQueryParamParameterEndAtOneOf = {
  equals?: GetOneMembershipWhereQueryParamParameterEndAtOneOfEquals;
  in?: GetOneMembershipWhereQueryParamParameterEndAtOneOfIn;
  notIn?: GetOneMembershipWhereQueryParamParameterEndAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipWhereQueryParamParameterEndAtOneOfNot;
};

export type GetOneMembershipWhereQueryParamParameterChargeFrom =
  | string
  | GetOneMembershipWhereQueryParamParameterChargeFromOneOf;

export type GetOneMembershipWhereQueryParamParameterChargeFromOneOfNot = string | ChargeFrom;

export type GetOneMembershipWhereQueryParamParameterChargeFromOneOfNotIn = string | string[];

export type GetOneMembershipWhereQueryParamParameterChargeFromOneOfIn = string | string[];

export type GetOneMembershipWhereQueryParamParameterChargeFromOneOfEquals = string;

export type GetOneMembershipWhereQueryParamParameterChargeFromOneOf = {
  equals?: GetOneMembershipWhereQueryParamParameterChargeFromOneOfEquals;
  in?: GetOneMembershipWhereQueryParamParameterChargeFromOneOfIn;
  notIn?: GetOneMembershipWhereQueryParamParameterChargeFromOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: GetOneMembershipWhereQueryParamParameterChargeFromOneOfNot;
};

export type GetOneMembershipWhereQueryParamParameterPreferencesOneOfPath = string | string[];

export type GetOneMembershipWhereQueryParamParameterPreferencesOneOf = {
  path?: GetOneMembershipWhereQueryParamParameterPreferencesOneOfPath;
  equals?: unknown;
  not?: unknown;
  string_contains?: string | null;
  string_starts_with?: string | null;
  string_ends_with?: string | null;
  array_contains?: unknown;
  array_starts_with?: unknown;
  array_ends_with?: unknown;
};

export type GetOneMembershipWhereQueryParamParameterPreferences =
  GetOneMembershipWhereQueryParamParameterPreferencesOneOf;

export type GetOneMembershipWhereQueryParamParameterAccountId =
  | string
  | GetOneMembershipWhereQueryParamParameterAccountIdOneOf;

export type GetOneMembershipWhereQueryParamParameterAccountIdOneOfNot = string | AccountId;

export type GetOneMembershipWhereQueryParamParameterAccountIdOneOfMode =
  typeof GetOneMembershipWhereQueryParamParameterAccountIdOneOfMode[keyof typeof GetOneMembershipWhereQueryParamParameterAccountIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipWhereQueryParamParameterAccountIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipWhereQueryParamParameterAccountIdOneOfNotIn = string | string[];

export type GetOneMembershipWhereQueryParamParameterAccountIdOneOfIn = string | string[];

export type GetOneMembershipWhereQueryParamParameterAccountIdOneOf = {
  equals?: string | null;
  in?: GetOneMembershipWhereQueryParamParameterAccountIdOneOfIn;
  notIn?: GetOneMembershipWhereQueryParamParameterAccountIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipWhereQueryParamParameterAccountIdOneOfMode;
  not?: GetOneMembershipWhereQueryParamParameterAccountIdOneOfNot;
};

export type GetOneMembershipWhereQueryParamParameterMembershipFrequencyId =
  | string
  | GetOneMembershipWhereQueryParamParameterMembershipFrequencyIdOneOf;

export type GetOneMembershipWhereQueryParamParameterMembershipFrequencyIdOneOfNot = string | MembershipFrequencyId;

export type GetOneMembershipWhereQueryParamParameterMembershipFrequencyIdOneOfMode =
  typeof GetOneMembershipWhereQueryParamParameterMembershipFrequencyIdOneOfMode[keyof typeof GetOneMembershipWhereQueryParamParameterMembershipFrequencyIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipWhereQueryParamParameterMembershipFrequencyIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipWhereQueryParamParameterMembershipFrequencyIdOneOfNotIn = string | string[];

export type GetOneMembershipWhereQueryParamParameterMembershipFrequencyIdOneOfIn = string | string[];

export type GetOneMembershipWhereQueryParamParameterMembershipFrequencyIdOneOf = {
  equals?: string | null;
  in?: GetOneMembershipWhereQueryParamParameterMembershipFrequencyIdOneOfIn;
  notIn?: GetOneMembershipWhereQueryParamParameterMembershipFrequencyIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipWhereQueryParamParameterMembershipFrequencyIdOneOfMode;
  not?: GetOneMembershipWhereQueryParamParameterMembershipFrequencyIdOneOfNot;
};

export type GetOneMembershipWhereQueryParamParameterNotesOneOfNot = string | Notes;

export type GetOneMembershipWhereQueryParamParameterNotesOneOfMode =
  typeof GetOneMembershipWhereQueryParamParameterNotesOneOfMode[keyof typeof GetOneMembershipWhereQueryParamParameterNotesOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipWhereQueryParamParameterNotesOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipWhereQueryParamParameterNotesOneOfNotIn = string | string[];

export type GetOneMembershipWhereQueryParamParameterNotesOneOfIn = string | string[];

export type GetOneMembershipWhereQueryParamParameterNotesOneOfEquals = string;

export type GetOneMembershipWhereQueryParamParameterNotesOneOf = {
  equals?: GetOneMembershipWhereQueryParamParameterNotesOneOfEquals;
  in?: GetOneMembershipWhereQueryParamParameterNotesOneOfIn;
  notIn?: GetOneMembershipWhereQueryParamParameterNotesOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipWhereQueryParamParameterNotesOneOfMode;
  not?: GetOneMembershipWhereQueryParamParameterNotesOneOfNot;
};

export type GetOneMembershipWhereQueryParamParameterNotes = string | GetOneMembershipWhereQueryParamParameterNotesOneOf;

export type GetOneMembershipWhereQueryParamParameterId = string | GetOneMembershipWhereQueryParamParameterIdOneOf;

export type GetOneMembershipWhereQueryParamParameter = {
  AND?: GetOneMembershipWhereQueryParamParameterAnd;
  OR?: GetOneMembershipWhereQueryParamParameterOr;
  NOT?: GetOneMembershipWhereQueryParamParameterNot;
  id?: GetOneMembershipWhereQueryParamParameterId;
  notes?: GetOneMembershipWhereQueryParamParameterNotes;
  membershipFrequencyId?: GetOneMembershipWhereQueryParamParameterMembershipFrequencyId;
  accountId?: GetOneMembershipWhereQueryParamParameterAccountId;
  preferences?: GetOneMembershipWhereQueryParamParameterPreferences;
  chargeFrom?: GetOneMembershipWhereQueryParamParameterChargeFrom;
  endAt?: GetOneMembershipWhereQueryParamParameterEndAt;
  currentPeriodStartAt?: GetOneMembershipWhereQueryParamParameterCurrentPeriodStartAt;
  currentPeriodEndAt?: GetOneMembershipWhereQueryParamParameterCurrentPeriodEndAt;
  createdAt?: GetOneMembershipWhereQueryParamParameterCreatedAt;
  updatedAt?: GetOneMembershipWhereQueryParamParameterUpdatedAt;
  deletedAt?: GetOneMembershipWhereQueryParamParameterDeletedAt;
  membershipFrequency?: GetOneMembershipWhereQueryParamParameterMembershipFrequency;
  account?: GetOneMembershipWhereQueryParamParameterAccount;
  membershipOrders?: GetOneMembershipWhereQueryParamParameterMembershipOrders;
};

export type GetOneMembershipWhereQueryParamParameterIdOneOfNot = string | Id;

export type GetOneMembershipWhereQueryParamParameterIdOneOfMode =
  typeof GetOneMembershipWhereQueryParamParameterIdOneOfMode[keyof typeof GetOneMembershipWhereQueryParamParameterIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOneMembershipWhereQueryParamParameterIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type GetOneMembershipWhereQueryParamParameterIdOneOfNotIn = string | string[];

export type GetOneMembershipWhereQueryParamParameterIdOneOfIn = string | string[];

export type GetOneMembershipWhereQueryParamParameterIdOneOf = {
  equals?: string | null;
  in?: GetOneMembershipWhereQueryParamParameterIdOneOfIn;
  notIn?: GetOneMembershipWhereQueryParamParameterIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: GetOneMembershipWhereQueryParamParameterIdOneOfMode;
  not?: GetOneMembershipWhereQueryParamParameterIdOneOfNot;
};

export type GetOneMembershipWhereQueryParamParameterNot = MembershipWhere | MembershipWhere[];

export type GetOneMembershipWhereQueryParamParameterOr = MembershipWhere | MembershipWhere[];

export type GetOneMembershipWhereQueryParamParameterAnd = MembershipWhere | MembershipWhere[];

export type GetOneMembershipIncludeQueryParamParameterOneOf = null;

export type GetOneMembershipIncludeQueryParamParameter =
  | GetOneMembershipIncludeQueryParamParameterOneOf
  | MembershipInclude;

export type GetOneMembershipSelectQueryParamParameterOneOf = null;

export type GetOneMembershipSelectQueryParamParameter =
  | GetOneMembershipSelectQueryParamParameterOneOf
  | MembershipSelect;

/**
 * Query params for get-membership-by-id}
 */
export type GetMembershipByIdWithTrashedQueryParamParameter = boolean | null;

export type GetMembershipByIdIncludeQueryParamParameterOneOf = null;

export type GetMembershipByIdIncludeQueryParamParameter =
  | GetMembershipByIdIncludeQueryParamParameterOneOf
  | MembershipInclude;

export type GetMembershipByIdSelectQueryParamParameterOneOf = null;

export type GetMembershipByIdSelectQueryParamParameter =
  | GetMembershipByIdSelectQueryParamParameterOneOf
  | MembershipSelect;

export type PostContactBodyIncludeOneOf = null;

export type PostContactBodyInclude = PostContactBodyIncludeOneOf | ContactInclude;

export type PostContactBody = {
  select?: PostContactBodySelect;
  include?: PostContactBodyInclude;
  data: ContactCreate;
};

export type PostContactBodySelectOneOf = null;

export type PostContactBodySelect = PostContactBodySelectOneOf | ContactSelect;

export type DeleteDestroyContactByIdBodyIncludeOneOf = null;

export type DeleteDestroyContactByIdBodyInclude = DeleteDestroyContactByIdBodyIncludeOneOf | ContactInclude;

export type DeleteDestroyContactByIdBody = {
  select?: DeleteDestroyContactByIdBodySelect;
  include?: DeleteDestroyContactByIdBodyInclude;
};

export type DeleteDestroyContactByIdBodySelectOneOf = null;

export type DeleteDestroyContactByIdBodySelect = DeleteDestroyContactByIdBodySelectOneOf | ContactSelect;

export type PatchRestoreContactByIdBody = {
  select?: PatchRestoreContactByIdBodySelect;
  include?: PatchRestoreContactByIdBodyInclude;
};

export type PatchRestoreContactByIdBodyIncludeOneOf = null;

export type PatchRestoreContactByIdBodyInclude = PatchRestoreContactByIdBodyIncludeOneOf | ContactInclude;

export type PatchRestoreContactByIdBodySelectOneOf = null;

export type PatchRestoreContactByIdBodySelect = PatchRestoreContactByIdBodySelectOneOf | ContactSelect;

export type DeleteContactByIdBodyIncludeOneOf = null;

export type DeleteContactByIdBodyInclude = DeleteContactByIdBodyIncludeOneOf | ContactInclude;

export type DeleteContactByIdBodySelectOneOf = null;

export type DeleteContactByIdBodySelect = DeleteContactByIdBodySelectOneOf | ContactSelect;

export type DeleteContactByIdBody = {
  select?: DeleteContactByIdBodySelect;
  include?: DeleteContactByIdBodyInclude;
};

export type PatchContactByIdBodyIncludeOneOf = null;

export type PatchContactByIdBodyInclude = PatchContactByIdBodyIncludeOneOf | ContactInclude;

export type PatchContactByIdBodySelectOneOf = null;

export type PatchContactByIdBodySelect = PatchContactByIdBodySelectOneOf | ContactSelect;

export type PatchContactByIdBody = {
  select?: PatchContactByIdBodySelect;
  include?: PatchContactByIdBodyInclude;
  data: ContactUpdate;
};

export type PostAddressBodyIncludeOneOf = null;

export type PostAddressBodyInclude = PostAddressBodyIncludeOneOf | AddressInclude;

export type PostAddressBody = {
  select?: PostAddressBodySelect;
  include?: PostAddressBodyInclude;
  data: AddressCreate;
};

export type PostAddressBodySelectOneOf = null;

export type PostAddressBodySelect = PostAddressBodySelectOneOf | AddressSelect;

export type DeleteDestroyAddressByIdBodyIncludeOneOf = null;

export type DeleteDestroyAddressByIdBodyInclude = DeleteDestroyAddressByIdBodyIncludeOneOf | AddressInclude;

export type DeleteDestroyAddressByIdBodySelectOneOf = null;

export type DeleteDestroyAddressByIdBodySelect = DeleteDestroyAddressByIdBodySelectOneOf | AddressSelect;

export type DeleteDestroyAddressByIdBody = {
  select?: DeleteDestroyAddressByIdBodySelect;
  include?: DeleteDestroyAddressByIdBodyInclude;
};

export type PatchRestoreAddressByIdBodyIncludeOneOf = null;

export type PatchRestoreAddressByIdBodyInclude = PatchRestoreAddressByIdBodyIncludeOneOf | AddressInclude;

export type PatchRestoreAddressByIdBody = {
  select?: PatchRestoreAddressByIdBodySelect;
  include?: PatchRestoreAddressByIdBodyInclude;
};

export type PatchRestoreAddressByIdBodySelectOneOf = null;

export type PatchRestoreAddressByIdBodySelect = PatchRestoreAddressByIdBodySelectOneOf | AddressSelect;

export type DeleteAddressByIdBodyIncludeOneOf = null;

export type DeleteAddressByIdBodyInclude = DeleteAddressByIdBodyIncludeOneOf | AddressInclude;

export type DeleteAddressByIdBody = {
  select?: DeleteAddressByIdBodySelect;
  include?: DeleteAddressByIdBodyInclude;
};

export type DeleteAddressByIdBodySelectOneOf = null;

export type DeleteAddressByIdBodySelect = DeleteAddressByIdBodySelectOneOf | AddressSelect;

export type PatchAddressByIdBodyIncludeOneOf = null;

export type PatchAddressByIdBodyInclude = PatchAddressByIdBodyIncludeOneOf | AddressInclude;

export type PatchAddressByIdBody = {
  select?: PatchAddressByIdBodySelect;
  include?: PatchAddressByIdBodyInclude;
  data: AddressUpdate;
};

export type PatchAddressByIdBodySelectOneOf = null;

export type PatchAddressByIdBodySelect = PatchAddressByIdBodySelectOneOf | AddressSelect;

export type PostImportAccountsFromZohoCrmBody = {
  ids: string[];
};

export type PostAccountBodyIncludeOneOf = null;

export type PostAccountBodyInclude = PostAccountBodyIncludeOneOf | AccountInclude;

export type PostAccountBodySelectOneOf = null;

export type PostAccountBodySelect = PostAccountBodySelectOneOf | AccountSelect;

export type PostAccountBody = {
  select?: PostAccountBodySelect;
  include?: PostAccountBodyInclude;
  data: AccountCreate;
};

export type DeleteDestroyAccountByIdBodyIncludeOneOf = null;

export type DeleteDestroyAccountByIdBodyInclude = DeleteDestroyAccountByIdBodyIncludeOneOf | AccountInclude;

export type DeleteDestroyAccountByIdBody = {
  select?: DeleteDestroyAccountByIdBodySelect;
  include?: DeleteDestroyAccountByIdBodyInclude;
};

export type DeleteDestroyAccountByIdBodySelectOneOf = null;

export type DeleteDestroyAccountByIdBodySelect = DeleteDestroyAccountByIdBodySelectOneOf | AccountSelect;

export type PatchRestoreAccountByIdBodyIncludeOneOf = null;

export type PatchRestoreAccountByIdBodyInclude = PatchRestoreAccountByIdBodyIncludeOneOf | AccountInclude;

export type PatchRestoreAccountByIdBody = {
  select?: PatchRestoreAccountByIdBodySelect;
  include?: PatchRestoreAccountByIdBodyInclude;
};

export type PatchRestoreAccountByIdBodySelectOneOf = null;

export type PatchRestoreAccountByIdBodySelect = PatchRestoreAccountByIdBodySelectOneOf | AccountSelect;

export type DeleteAccountByIdBodyIncludeOneOf = null;

export type DeleteAccountByIdBodyInclude = DeleteAccountByIdBodyIncludeOneOf | AccountInclude;

export type DeleteAccountByIdBodySelectOneOf = null;

export type DeleteAccountByIdBodySelect = DeleteAccountByIdBodySelectOneOf | AccountSelect;

export type DeleteAccountByIdBody = {
  select?: DeleteAccountByIdBodySelect;
  include?: DeleteAccountByIdBodyInclude;
};

export type PatchAccountByIdBodyIncludeOneOf = null;

export type PatchAccountByIdBodyInclude = PatchAccountByIdBodyIncludeOneOf | AccountInclude;

export type PatchAccountByIdBodySelectOneOf = null;

export type PatchAccountByIdBodySelect = PatchAccountByIdBodySelectOneOf | AccountSelect;

export type PatchAccountByIdBody = {
  select?: PatchAccountByIdBodySelect;
  include?: PatchAccountByIdBodyInclude;
  data: AccountUpdate;
};

export type PostFileBodyIncludeOneOf = null;

export type PostFileBodyInclude = PostFileBodyIncludeOneOf | FileInclude;

export type PostFileBody = {
  select?: PostFileBodySelect;
  include?: PostFileBodyInclude;
  data: FileCreate;
};

export type PostFileBodySelectOneOf = null;

export type PostFileBodySelect = PostFileBodySelectOneOf | FileSelect;

export type DeleteDestroyFileByIdBodyIncludeOneOf = null;

export type DeleteDestroyFileByIdBodyInclude = DeleteDestroyFileByIdBodyIncludeOneOf | FileInclude;

export type DeleteDestroyFileByIdBodySelectOneOf = null;

export type DeleteDestroyFileByIdBodySelect = DeleteDestroyFileByIdBodySelectOneOf | FileSelect;

export type DeleteDestroyFileByIdBody = {
  select?: DeleteDestroyFileByIdBodySelect;
  include?: DeleteDestroyFileByIdBodyInclude;
};

export type PatchRestoreFileByIdBodyIncludeOneOf = null;

export type PatchRestoreFileByIdBodyInclude = PatchRestoreFileByIdBodyIncludeOneOf | FileInclude;

export type PatchRestoreFileByIdBodySelectOneOf = null;

export type PatchRestoreFileByIdBodySelect = PatchRestoreFileByIdBodySelectOneOf | FileSelect;

export type PatchRestoreFileByIdBody = {
  select?: PatchRestoreFileByIdBodySelect;
  include?: PatchRestoreFileByIdBodyInclude;
};

export type DeleteFileByIdBodyIncludeOneOf = null;

export type DeleteFileByIdBodyInclude = DeleteFileByIdBodyIncludeOneOf | FileInclude;

export type DeleteFileByIdBody = {
  select?: DeleteFileByIdBodySelect;
  include?: DeleteFileByIdBodyInclude;
};

export type DeleteFileByIdBodySelectOneOf = null;

export type DeleteFileByIdBodySelect = DeleteFileByIdBodySelectOneOf | FileSelect;

export type PatchFileByIdBodyIncludeOneOf = null;

export type PatchFileByIdBodyInclude = PatchFileByIdBodyIncludeOneOf | FileInclude;

export type PatchFileByIdBody = {
  select?: PatchFileByIdBodySelect;
  include?: PatchFileByIdBodyInclude;
  data: FileUpdate;
};

export type PatchFileByIdBodySelectOneOf = null;

export type PatchFileByIdBodySelect = PatchFileByIdBodySelectOneOf | FileSelect;

export type PostShipmentRunBodyIncludeOneOf = null;

export type PostShipmentRunBodyInclude = PostShipmentRunBodyIncludeOneOf | ShipmentRunInclude;

export type PostShipmentRunBody = {
  select?: PostShipmentRunBodySelect;
  include?: PostShipmentRunBodyInclude;
  data: ShipmentRunCreate;
  ignoreWarnings?: boolean | null;
};

export type PostShipmentRunBodySelectOneOf = null;

export type PostShipmentRunBodySelect = PostShipmentRunBodySelectOneOf | ShipmentRunSelect;

export type DeleteDestroyShipmentRunByIdBodyIncludeOneOf = null;

export type DeleteDestroyShipmentRunByIdBodyInclude = DeleteDestroyShipmentRunByIdBodyIncludeOneOf | ShipmentRunInclude;

export type DeleteDestroyShipmentRunByIdBodySelectOneOf = null;

export type DeleteDestroyShipmentRunByIdBodySelect = DeleteDestroyShipmentRunByIdBodySelectOneOf | ShipmentRunSelect;

export type DeleteDestroyShipmentRunByIdBody = {
  select?: DeleteDestroyShipmentRunByIdBodySelect;
  include?: DeleteDestroyShipmentRunByIdBodyInclude;
};

export type PatchRestoreShipmentRunByIdBodyInclude = PatchRestoreShipmentRunByIdBodyIncludeOneOf | ShipmentRunInclude;

export type PatchRestoreShipmentRunByIdBody = {
  select?: PatchRestoreShipmentRunByIdBodySelect;
  include?: PatchRestoreShipmentRunByIdBodyInclude;
};

export type PatchRestoreShipmentRunByIdBodyIncludeOneOf = null;

export type PatchRestoreShipmentRunByIdBodySelectOneOf = null;

export type PatchRestoreShipmentRunByIdBodySelect = PatchRestoreShipmentRunByIdBodySelectOneOf | ShipmentRunSelect;

export type DeleteShipmentRunByIdBodyIncludeOneOf = null;

export type DeleteShipmentRunByIdBodyInclude = DeleteShipmentRunByIdBodyIncludeOneOf | ShipmentRunInclude;

export type DeleteShipmentRunByIdBodySelectOneOf = null;

export type DeleteShipmentRunByIdBodySelect = DeleteShipmentRunByIdBodySelectOneOf | ShipmentRunSelect;

export type DeleteShipmentRunByIdBody = {
  select?: DeleteShipmentRunByIdBodySelect;
  include?: DeleteShipmentRunByIdBodyInclude;
};

export type PatchShipmentRunByIdBodyIncludeOneOf = null;

export type PatchShipmentRunByIdBodyInclude = PatchShipmentRunByIdBodyIncludeOneOf | ShipmentRunInclude;

export type PatchShipmentRunByIdBodySelectOneOf = null;

export type PatchShipmentRunByIdBodySelect = PatchShipmentRunByIdBodySelectOneOf | ShipmentRunSelect;

export type PatchShipmentRunByIdBody = {
  select?: PatchShipmentRunByIdBodySelect;
  include?: PatchShipmentRunByIdBodyInclude;
  data: ShipmentRunUpdate;
};

export type PostSalesOrderLineItemBodyIncludeOneOf = null;

export type PostSalesOrderLineItemBodyInclude = PostSalesOrderLineItemBodyIncludeOneOf | SalesOrderLineItemInclude;

export type PostSalesOrderLineItemBody = {
  select?: PostSalesOrderLineItemBodySelect;
  include?: PostSalesOrderLineItemBodyInclude;
  data: SalesOrderLineItemCreate;
};

export type PostSalesOrderLineItemBodySelectOneOf = null;

export type PostSalesOrderLineItemBodySelect = PostSalesOrderLineItemBodySelectOneOf | SalesOrderLineItemSelect;

export type DeleteDestroySalesOrderLineItemByIdBodyIncludeOneOf = null;

export type DeleteDestroySalesOrderLineItemByIdBodyInclude =
  | DeleteDestroySalesOrderLineItemByIdBodyIncludeOneOf
  | SalesOrderLineItemInclude;

export type DeleteDestroySalesOrderLineItemByIdBodySelectOneOf = null;

export type DeleteDestroySalesOrderLineItemByIdBodySelect =
  | DeleteDestroySalesOrderLineItemByIdBodySelectOneOf
  | SalesOrderLineItemSelect;

export type DeleteDestroySalesOrderLineItemByIdBody = {
  select?: DeleteDestroySalesOrderLineItemByIdBodySelect;
  include?: DeleteDestroySalesOrderLineItemByIdBodyInclude;
};

export type PatchRestoreSalesOrderLineItemByIdBodyIncludeOneOf = null;

export type PatchRestoreSalesOrderLineItemByIdBodyInclude =
  | PatchRestoreSalesOrderLineItemByIdBodyIncludeOneOf
  | SalesOrderLineItemInclude;

export type PatchRestoreSalesOrderLineItemByIdBody = {
  select?: PatchRestoreSalesOrderLineItemByIdBodySelect;
  include?: PatchRestoreSalesOrderLineItemByIdBodyInclude;
};

export type PatchRestoreSalesOrderLineItemByIdBodySelectOneOf = null;

export type PatchRestoreSalesOrderLineItemByIdBodySelect =
  | PatchRestoreSalesOrderLineItemByIdBodySelectOneOf
  | SalesOrderLineItemSelect;

export type DeleteSalesOrderLineItemByIdBodyIncludeOneOf = null;

export type DeleteSalesOrderLineItemByIdBodyInclude =
  | DeleteSalesOrderLineItemByIdBodyIncludeOneOf
  | SalesOrderLineItemInclude;

export type DeleteSalesOrderLineItemByIdBody = {
  select?: DeleteSalesOrderLineItemByIdBodySelect;
  include?: DeleteSalesOrderLineItemByIdBodyInclude;
};

export type DeleteSalesOrderLineItemByIdBodySelectOneOf = null;

export type DeleteSalesOrderLineItemByIdBodySelect =
  | DeleteSalesOrderLineItemByIdBodySelectOneOf
  | SalesOrderLineItemSelect;

export type PatchSalesOrderLineItemByIdBody = {
  select?: PatchSalesOrderLineItemByIdBodySelect;
  include?: PatchSalesOrderLineItemByIdBodyInclude;
  data: SalesOrderLineItemUpdate;
};

export type PatchSalesOrderLineItemByIdBodyIncludeOneOf = null;

export type PatchSalesOrderLineItemByIdBodyInclude =
  | PatchSalesOrderLineItemByIdBodyIncludeOneOf
  | SalesOrderLineItemInclude;

export type PatchSalesOrderLineItemByIdBodySelectOneOf = null;

export type PatchSalesOrderLineItemByIdBodySelect =
  | PatchSalesOrderLineItemByIdBodySelectOneOf
  | SalesOrderLineItemSelect;

export type PostSalesOrderBodyIncludeOneOf = null;

export type PostSalesOrderBodyInclude = PostSalesOrderBodyIncludeOneOf | SalesOrderInclude;

export type PostSalesOrderBodySelectOneOf = null;

export type PostSalesOrderBodySelect = PostSalesOrderBodySelectOneOf | SalesOrderSelect;

export type PostSalesOrderBody = {
  select?: PostSalesOrderBodySelect;
  include?: PostSalesOrderBodyInclude;
  data: SalesOrderCreate;
};

export type DeleteDestroySalesOrderByIdBodyIncludeOneOf = null;

export type DeleteDestroySalesOrderByIdBodyInclude = DeleteDestroySalesOrderByIdBodyIncludeOneOf | SalesOrderInclude;

export type DeleteDestroySalesOrderByIdBodySelectOneOf = null;

export type DeleteDestroySalesOrderByIdBodySelect = DeleteDestroySalesOrderByIdBodySelectOneOf | SalesOrderSelect;

export type DeleteDestroySalesOrderByIdBody = {
  select?: DeleteDestroySalesOrderByIdBodySelect;
  include?: DeleteDestroySalesOrderByIdBodyInclude;
};

export type PatchRestoreSalesOrderByIdBodyIncludeOneOf = null;

export type PatchRestoreSalesOrderByIdBodyInclude = PatchRestoreSalesOrderByIdBodyIncludeOneOf | SalesOrderInclude;

export type PatchRestoreSalesOrderByIdBody = {
  select?: PatchRestoreSalesOrderByIdBodySelect;
  include?: PatchRestoreSalesOrderByIdBodyInclude;
};

export type PatchRestoreSalesOrderByIdBodySelectOneOf = null;

export type PatchRestoreSalesOrderByIdBodySelect = PatchRestoreSalesOrderByIdBodySelectOneOf | SalesOrderSelect;

export type DeleteSalesOrderByIdBodyIncludeOneOf = null;

export type DeleteSalesOrderByIdBodyInclude = DeleteSalesOrderByIdBodyIncludeOneOf | SalesOrderInclude;

export type DeleteSalesOrderByIdBodySelectOneOf = null;

export type DeleteSalesOrderByIdBodySelect = DeleteSalesOrderByIdBodySelectOneOf | SalesOrderSelect;

export type DeleteSalesOrderByIdBody = {
  select?: DeleteSalesOrderByIdBodySelect;
  include?: DeleteSalesOrderByIdBodyInclude;
};

export type PatchSalesOrderByIdBodyIncludeOneOf = null;

export type PatchSalesOrderByIdBodyInclude = PatchSalesOrderByIdBodyIncludeOneOf | SalesOrderInclude;

export type PatchSalesOrderByIdBody = {
  select?: PatchSalesOrderByIdBodySelect;
  include?: PatchSalesOrderByIdBodyInclude;
  data: SalesOrderUpdate;
};

export type PatchSalesOrderByIdBodySelectOneOf = null;

export type PatchSalesOrderByIdBodySelect = PatchSalesOrderByIdBodySelectOneOf | SalesOrderSelect;

export type PostItemBodyIncludeOneOf = null;

export type PostItemBodyInclude = PostItemBodyIncludeOneOf | ItemInclude;

export type PostItemBody = {
  select?: PostItemBodySelect;
  include?: PostItemBodyInclude;
  data: ItemCreate;
};

export type PostItemBodySelectOneOf = null;

export type PostItemBodySelect = PostItemBodySelectOneOf | ItemSelect;

export type DeleteDestroyItemByIdBodyIncludeOneOf = null;

export type DeleteDestroyItemByIdBodyInclude = DeleteDestroyItemByIdBodyIncludeOneOf | ItemInclude;

export type DeleteDestroyItemByIdBody = {
  select?: DeleteDestroyItemByIdBodySelect;
  include?: DeleteDestroyItemByIdBodyInclude;
};

export type DeleteDestroyItemByIdBodySelectOneOf = null;

export type DeleteDestroyItemByIdBodySelect = DeleteDestroyItemByIdBodySelectOneOf | ItemSelect;

export type PatchRestoreItemByIdBodyIncludeOneOf = null;

export type PatchRestoreItemByIdBodyInclude = PatchRestoreItemByIdBodyIncludeOneOf | ItemInclude;

export type PatchRestoreItemByIdBodySelectOneOf = null;

export type PatchRestoreItemByIdBodySelect = PatchRestoreItemByIdBodySelectOneOf | ItemSelect;

export type PatchRestoreItemByIdBody = {
  select?: PatchRestoreItemByIdBodySelect;
  include?: PatchRestoreItemByIdBodyInclude;
};

export type DeleteItemByIdBodyIncludeOneOf = null;

export type DeleteItemByIdBodyInclude = DeleteItemByIdBodyIncludeOneOf | ItemInclude;

export type DeleteItemByIdBody = {
  select?: DeleteItemByIdBodySelect;
  include?: DeleteItemByIdBodyInclude;
};

export type DeleteItemByIdBodySelectOneOf = null;

export type DeleteItemByIdBodySelect = DeleteItemByIdBodySelectOneOf | ItemSelect;

export type PatchItemByIdBodyIncludeOneOf = null;

export type PatchItemByIdBodyInclude = PatchItemByIdBodyIncludeOneOf | ItemInclude;

export type PatchItemByIdBody = {
  select?: PatchItemByIdBodySelect;
  include?: PatchItemByIdBodyInclude;
  data: ItemUpdate;
};

export type PatchItemByIdBodySelectOneOf = null;

export type PatchItemByIdBodySelect = PatchItemByIdBodySelectOneOf | ItemSelect;

export type PostCategoryBodyIncludeOneOf = null;

export type PostCategoryBodyInclude = PostCategoryBodyIncludeOneOf | CategoryInclude;

export type PostCategoryBodySelectOneOf = null;

export type PostCategoryBodySelect = PostCategoryBodySelectOneOf | CategorySelect;

export type PostCategoryBody = {
  select?: PostCategoryBodySelect;
  include?: PostCategoryBodyInclude;
  data: CategoryCreate;
};

export type DeleteDestroyCategoryByIdBodyIncludeOneOf = null;

export type DeleteDestroyCategoryByIdBodyInclude = DeleteDestroyCategoryByIdBodyIncludeOneOf | CategoryInclude;

export type DeleteDestroyCategoryByIdBody = {
  select?: DeleteDestroyCategoryByIdBodySelect;
  include?: DeleteDestroyCategoryByIdBodyInclude;
};

export type DeleteDestroyCategoryByIdBodySelectOneOf = null;

export type DeleteDestroyCategoryByIdBodySelect = DeleteDestroyCategoryByIdBodySelectOneOf | CategorySelect;

export type PatchRestoreCategoryByIdBodyIncludeOneOf = null;

export type PatchRestoreCategoryByIdBodyInclude = PatchRestoreCategoryByIdBodyIncludeOneOf | CategoryInclude;

export type PatchRestoreCategoryByIdBodySelectOneOf = null;

export type PatchRestoreCategoryByIdBodySelect = PatchRestoreCategoryByIdBodySelectOneOf | CategorySelect;

export type PatchRestoreCategoryByIdBody = {
  select?: PatchRestoreCategoryByIdBodySelect;
  include?: PatchRestoreCategoryByIdBodyInclude;
};

export type DeleteCategoryByIdBodyIncludeOneOf = null;

export type DeleteCategoryByIdBodyInclude = DeleteCategoryByIdBodyIncludeOneOf | CategoryInclude;

export type DeleteCategoryByIdBody = {
  select?: DeleteCategoryByIdBodySelect;
  include?: DeleteCategoryByIdBodyInclude;
};

export type DeleteCategoryByIdBodySelectOneOf = null;

export type DeleteCategoryByIdBodySelect = DeleteCategoryByIdBodySelectOneOf | CategorySelect;

export type PatchCategoryByIdBodyIncludeOneOf = null;

export type PatchCategoryByIdBodyInclude = PatchCategoryByIdBodyIncludeOneOf | CategoryInclude;

export type PatchCategoryByIdBodySelectOneOf = null;

export type PatchCategoryByIdBodySelect = PatchCategoryByIdBodySelectOneOf | CategorySelect;

export type PatchCategoryByIdBody = {
  select?: PatchCategoryByIdBodySelect;
  include?: PatchCategoryByIdBodyInclude;
  data: CategoryUpdate;
};

export type PostProductVariantBodyIncludeOneOf = null;

export type PostProductVariantBodyInclude = PostProductVariantBodyIncludeOneOf | ProductVariantInclude;

export type PostProductVariantBody = {
  select?: PostProductVariantBodySelect;
  include?: PostProductVariantBodyInclude;
  data: ProductVariantCreate;
};

export type PostProductVariantBodySelectOneOf = null;

export type PostProductVariantBodySelect = PostProductVariantBodySelectOneOf | ProductVariantSelect;

export type DeleteDestroyProductVariantByIdBodyIncludeOneOf = null;

export type DeleteDestroyProductVariantByIdBodyInclude =
  | DeleteDestroyProductVariantByIdBodyIncludeOneOf
  | ProductVariantInclude;

export type DeleteDestroyProductVariantByIdBody = {
  select?: DeleteDestroyProductVariantByIdBodySelect;
  include?: DeleteDestroyProductVariantByIdBodyInclude;
};

export type DeleteDestroyProductVariantByIdBodySelectOneOf = null;

export type DeleteDestroyProductVariantByIdBodySelect =
  | DeleteDestroyProductVariantByIdBodySelectOneOf
  | ProductVariantSelect;

export type PatchRestoreProductVariantByIdBodyIncludeOneOf = null;

export type PatchRestoreProductVariantByIdBodyInclude =
  | PatchRestoreProductVariantByIdBodyIncludeOneOf
  | ProductVariantInclude;

export type PatchRestoreProductVariantByIdBodySelectOneOf = null;

export type PatchRestoreProductVariantByIdBodySelect =
  | PatchRestoreProductVariantByIdBodySelectOneOf
  | ProductVariantSelect;

export type PatchRestoreProductVariantByIdBody = {
  select?: PatchRestoreProductVariantByIdBodySelect;
  include?: PatchRestoreProductVariantByIdBodyInclude;
};

export type DeleteProductVariantByIdBodyIncludeOneOf = null;

export type DeleteProductVariantByIdBodyInclude = DeleteProductVariantByIdBodyIncludeOneOf | ProductVariantInclude;

export type DeleteProductVariantByIdBodySelectOneOf = null;

export type DeleteProductVariantByIdBodySelect = DeleteProductVariantByIdBodySelectOneOf | ProductVariantSelect;

export type DeleteProductVariantByIdBody = {
  select?: DeleteProductVariantByIdBodySelect;
  include?: DeleteProductVariantByIdBodyInclude;
};

export type PatchProductVariantByIdBodyIncludeOneOf = null;

export type PatchProductVariantByIdBodyInclude = PatchProductVariantByIdBodyIncludeOneOf | ProductVariantInclude;

export type PatchProductVariantByIdBodySelectOneOf = null;

export type PatchProductVariantByIdBodySelect = PatchProductVariantByIdBodySelectOneOf | ProductVariantSelect;

export type PatchProductVariantByIdBody = {
  select?: PatchProductVariantByIdBodySelect;
  include?: PatchProductVariantByIdBodyInclude;
  data: ProductVariantUpdate;
};

export type PostProductBodyIncludeOneOf = null;

export type PostProductBodyInclude = PostProductBodyIncludeOneOf | ProductInclude;

export type PostProductBodySelectOneOf = null;

export type PostProductBodySelect = PostProductBodySelectOneOf | ProductSelect;

export type PostProductBody = {
  select?: PostProductBodySelect;
  include?: PostProductBodyInclude;
  data: ProductCreate;
};

export type DeleteDestroyProductByIdBodyIncludeOneOf = null;

export type DeleteDestroyProductByIdBodyInclude = DeleteDestroyProductByIdBodyIncludeOneOf | ProductInclude;

export type DeleteDestroyProductByIdBodySelectOneOf = null;

export type DeleteDestroyProductByIdBodySelect = DeleteDestroyProductByIdBodySelectOneOf | ProductSelect;

export type DeleteDestroyProductByIdBody = {
  select?: DeleteDestroyProductByIdBodySelect;
  include?: DeleteDestroyProductByIdBodyInclude;
};

export type PatchRestoreProductByIdBodyIncludeOneOf = null;

export type PatchRestoreProductByIdBodyInclude = PatchRestoreProductByIdBodyIncludeOneOf | ProductInclude;

export type PatchRestoreProductByIdBody = {
  select?: PatchRestoreProductByIdBodySelect;
  include?: PatchRestoreProductByIdBodyInclude;
};

export type PatchRestoreProductByIdBodySelectOneOf = null;

export type PatchRestoreProductByIdBodySelect = PatchRestoreProductByIdBodySelectOneOf | ProductSelect;

export type DeleteProductByIdBodyIncludeOneOf = null;

export type DeleteProductByIdBodyInclude = DeleteProductByIdBodyIncludeOneOf | ProductInclude;

export type DeleteProductByIdBody = {
  select?: DeleteProductByIdBodySelect;
  include?: DeleteProductByIdBodyInclude;
};

export type DeleteProductByIdBodySelectOneOf = null;

export type DeleteProductByIdBodySelect = DeleteProductByIdBodySelectOneOf | ProductSelect;

export type PatchProductByIdBodyIncludeOneOf = null;

export type PatchProductByIdBodyInclude = PatchProductByIdBodyIncludeOneOf | ProductInclude;

export type PatchProductByIdBody = {
  select?: PatchProductByIdBodySelect;
  include?: PatchProductByIdBodyInclude;
  data: ProductUpdate;
};

export type PatchProductByIdBodySelectOneOf = null;

export type PatchProductByIdBodySelect = PatchProductByIdBodySelectOneOf | ProductSelect;

export type PostMembershipOfferingItemBodyIncludeOneOf = null;

export type PostMembershipOfferingItemBodyInclude =
  | PostMembershipOfferingItemBodyIncludeOneOf
  | MembershipOfferingItemInclude;

export type PostMembershipOfferingItemBodySelectOneOf = null;

export type PostMembershipOfferingItemBodySelect =
  | PostMembershipOfferingItemBodySelectOneOf
  | MembershipOfferingItemSelect;

export type PostMembershipOfferingItemBody = {
  select?: PostMembershipOfferingItemBodySelect;
  include?: PostMembershipOfferingItemBodyInclude;
  data: MembershipOfferingItemCreate;
};

export type DeleteDestroyMembershipOfferingItemByIdBodyIncludeOneOf = null;

export type DeleteDestroyMembershipOfferingItemByIdBodyInclude =
  | DeleteDestroyMembershipOfferingItemByIdBodyIncludeOneOf
  | MembershipOfferingItemInclude;

export type DeleteDestroyMembershipOfferingItemByIdBody = {
  select?: DeleteDestroyMembershipOfferingItemByIdBodySelect;
  include?: DeleteDestroyMembershipOfferingItemByIdBodyInclude;
};

export type DeleteDestroyMembershipOfferingItemByIdBodySelectOneOf = null;

export type DeleteDestroyMembershipOfferingItemByIdBodySelect =
  | DeleteDestroyMembershipOfferingItemByIdBodySelectOneOf
  | MembershipOfferingItemSelect;

export type PatchRestoreMembershipOfferingItemByIdBodyIncludeOneOf = null;

export type PatchRestoreMembershipOfferingItemByIdBodyInclude =
  | PatchRestoreMembershipOfferingItemByIdBodyIncludeOneOf
  | MembershipOfferingItemInclude;

export type PatchRestoreMembershipOfferingItemByIdBodySelectOneOf = null;

export type PatchRestoreMembershipOfferingItemByIdBodySelect =
  | PatchRestoreMembershipOfferingItemByIdBodySelectOneOf
  | MembershipOfferingItemSelect;

export type PatchRestoreMembershipOfferingItemByIdBody = {
  select?: PatchRestoreMembershipOfferingItemByIdBodySelect;
  include?: PatchRestoreMembershipOfferingItemByIdBodyInclude;
};

export type DeleteMembershipOfferingItemByIdBodyIncludeOneOf = null;

export type DeleteMembershipOfferingItemByIdBodyInclude =
  | DeleteMembershipOfferingItemByIdBodyIncludeOneOf
  | MembershipOfferingItemInclude;

export type DeleteMembershipOfferingItemByIdBodySelectOneOf = null;

export type DeleteMembershipOfferingItemByIdBodySelect =
  | DeleteMembershipOfferingItemByIdBodySelectOneOf
  | MembershipOfferingItemSelect;

export type DeleteMembershipOfferingItemByIdBody = {
  select?: DeleteMembershipOfferingItemByIdBodySelect;
  include?: DeleteMembershipOfferingItemByIdBodyInclude;
};

export type PatchMembershipOfferingItemByIdBodyIncludeOneOf = null;

export type PatchMembershipOfferingItemByIdBodyInclude =
  | PatchMembershipOfferingItemByIdBodyIncludeOneOf
  | MembershipOfferingItemInclude;

export type PatchMembershipOfferingItemByIdBody = {
  select?: PatchMembershipOfferingItemByIdBodySelect;
  include?: PatchMembershipOfferingItemByIdBodyInclude;
  data: MembershipOfferingItemUpdate;
};

export type PatchMembershipOfferingItemByIdBodySelectOneOf = null;

export type PatchMembershipOfferingItemByIdBodySelect =
  | PatchMembershipOfferingItemByIdBodySelectOneOf
  | MembershipOfferingItemSelect;

export type PostMembershipOfferingCategoryBodyIncludeOneOf = null;

export type PostMembershipOfferingCategoryBodyInclude =
  | PostMembershipOfferingCategoryBodyIncludeOneOf
  | MembershipOfferingCategoryInclude;

export type PostMembershipOfferingCategoryBodySelectOneOf = null;

export type PostMembershipOfferingCategoryBodySelect =
  | PostMembershipOfferingCategoryBodySelectOneOf
  | MembershipOfferingCategorySelect;

export type PostMembershipOfferingCategoryBody = {
  select?: PostMembershipOfferingCategoryBodySelect;
  include?: PostMembershipOfferingCategoryBodyInclude;
  data: MembershipOfferingCategoryCreate;
};

export type DeleteDestroyMembershipOfferingCategoryByIdBodyIncludeOneOf = null;

export type DeleteDestroyMembershipOfferingCategoryByIdBodyInclude =
  | DeleteDestroyMembershipOfferingCategoryByIdBodyIncludeOneOf
  | MembershipOfferingCategoryInclude;

export type DeleteDestroyMembershipOfferingCategoryByIdBody = {
  select?: DeleteDestroyMembershipOfferingCategoryByIdBodySelect;
  include?: DeleteDestroyMembershipOfferingCategoryByIdBodyInclude;
};

export type DeleteDestroyMembershipOfferingCategoryByIdBodySelectOneOf = null;

export type DeleteDestroyMembershipOfferingCategoryByIdBodySelect =
  | DeleteDestroyMembershipOfferingCategoryByIdBodySelectOneOf
  | MembershipOfferingCategorySelect;

export type PatchRestoreMembershipOfferingCategoryByIdBodyIncludeOneOf = null;

export type PatchRestoreMembershipOfferingCategoryByIdBodyInclude =
  | PatchRestoreMembershipOfferingCategoryByIdBodyIncludeOneOf
  | MembershipOfferingCategoryInclude;

export type PatchRestoreMembershipOfferingCategoryByIdBody = {
  select?: PatchRestoreMembershipOfferingCategoryByIdBodySelect;
  include?: PatchRestoreMembershipOfferingCategoryByIdBodyInclude;
};

export type PatchRestoreMembershipOfferingCategoryByIdBodySelectOneOf = null;

export type PatchRestoreMembershipOfferingCategoryByIdBodySelect =
  | PatchRestoreMembershipOfferingCategoryByIdBodySelectOneOf
  | MembershipOfferingCategorySelect;

export type DeleteMembershipOfferingCategoryByIdBody = {
  select?: DeleteMembershipOfferingCategoryByIdBodySelect;
  include?: DeleteMembershipOfferingCategoryByIdBodyInclude;
};

export type DeleteMembershipOfferingCategoryByIdBodyIncludeOneOf = null;

export type DeleteMembershipOfferingCategoryByIdBodyInclude =
  | DeleteMembershipOfferingCategoryByIdBodyIncludeOneOf
  | MembershipOfferingCategoryInclude;

export type DeleteMembershipOfferingCategoryByIdBodySelectOneOf = null;

export type DeleteMembershipOfferingCategoryByIdBodySelect =
  | DeleteMembershipOfferingCategoryByIdBodySelectOneOf
  | MembershipOfferingCategorySelect;

export type PatchMembershipOfferingCategoryByIdBodyIncludeOneOf = null;

export type PatchMembershipOfferingCategoryByIdBodyInclude =
  | PatchMembershipOfferingCategoryByIdBodyIncludeOneOf
  | MembershipOfferingCategoryInclude;

export type PatchMembershipOfferingCategoryByIdBodySelectOneOf = null;

export type PatchMembershipOfferingCategoryByIdBodySelect =
  | PatchMembershipOfferingCategoryByIdBodySelectOneOf
  | MembershipOfferingCategorySelect;

export type PatchMembershipOfferingCategoryByIdBody = {
  select?: PatchMembershipOfferingCategoryByIdBodySelect;
  include?: PatchMembershipOfferingCategoryByIdBodyInclude;
  data: MembershipOfferingCategoryUpdate;
};

export type PostMembershipOfferingBodyIncludeOneOf = null;

export type PostMembershipOfferingBodyInclude = PostMembershipOfferingBodyIncludeOneOf | MembershipOfferingInclude;

export type PostMembershipOfferingBodySelectOneOf = null;

export type PostMembershipOfferingBodySelect = PostMembershipOfferingBodySelectOneOf | MembershipOfferingSelect;

export type PostMembershipOfferingBody = {
  select?: PostMembershipOfferingBodySelect;
  include?: PostMembershipOfferingBodyInclude;
  data: MembershipOfferingCreate;
};

export type DeleteDestroyMembershipOfferingByIdBodyIncludeOneOf = null;

export type DeleteDestroyMembershipOfferingByIdBodyInclude =
  | DeleteDestroyMembershipOfferingByIdBodyIncludeOneOf
  | MembershipOfferingInclude;

export type DeleteDestroyMembershipOfferingByIdBody = {
  select?: DeleteDestroyMembershipOfferingByIdBodySelect;
  include?: DeleteDestroyMembershipOfferingByIdBodyInclude;
};

export type DeleteDestroyMembershipOfferingByIdBodySelectOneOf = null;

export type DeleteDestroyMembershipOfferingByIdBodySelect =
  | DeleteDestroyMembershipOfferingByIdBodySelectOneOf
  | MembershipOfferingSelect;

export type PatchRestoreMembershipOfferingByIdBodyIncludeOneOf = null;

export type PatchRestoreMembershipOfferingByIdBodyInclude =
  | PatchRestoreMembershipOfferingByIdBodyIncludeOneOf
  | MembershipOfferingInclude;

export type PatchRestoreMembershipOfferingByIdBodySelectOneOf = null;

export type PatchRestoreMembershipOfferingByIdBodySelect =
  | PatchRestoreMembershipOfferingByIdBodySelectOneOf
  | MembershipOfferingSelect;

export type PatchRestoreMembershipOfferingByIdBody = {
  select?: PatchRestoreMembershipOfferingByIdBodySelect;
  include?: PatchRestoreMembershipOfferingByIdBodyInclude;
};

export type DeleteMembershipOfferingByIdBodyIncludeOneOf = null;

export type DeleteMembershipOfferingByIdBodyInclude =
  | DeleteMembershipOfferingByIdBodyIncludeOneOf
  | MembershipOfferingInclude;

export type DeleteMembershipOfferingByIdBody = {
  select?: DeleteMembershipOfferingByIdBodySelect;
  include?: DeleteMembershipOfferingByIdBodyInclude;
};

export type DeleteMembershipOfferingByIdBodySelectOneOf = null;

export type DeleteMembershipOfferingByIdBodySelect =
  | DeleteMembershipOfferingByIdBodySelectOneOf
  | MembershipOfferingSelect;

export type PatchMembershipOfferingByIdBodyIncludeOneOf = null;

export type PatchMembershipOfferingByIdBodyInclude =
  | PatchMembershipOfferingByIdBodyIncludeOneOf
  | MembershipOfferingInclude;

export type PatchMembershipOfferingByIdBody = {
  select?: PatchMembershipOfferingByIdBodySelect;
  include?: PatchMembershipOfferingByIdBodyInclude;
  data: MembershipOfferingUpdate;
};

export type PatchMembershipOfferingByIdBodySelectOneOf = null;

export type PatchMembershipOfferingByIdBodySelect =
  | PatchMembershipOfferingByIdBodySelectOneOf
  | MembershipOfferingSelect;

export type PostMembershipOrderItemBodyIncludeOneOf = null;

export type PostMembershipOrderItemBodyInclude = PostMembershipOrderItemBodyIncludeOneOf | MembershipOrderItemInclude;

export type PostMembershipOrderItemBody = {
  select?: PostMembershipOrderItemBodySelect;
  include?: PostMembershipOrderItemBodyInclude;
  data: MembershipOrderItemCreate;
};

export type PostMembershipOrderItemBodySelectOneOf = null;

export type PostMembershipOrderItemBodySelect = PostMembershipOrderItemBodySelectOneOf | MembershipOrderItemSelect;

export type DeleteDestroyMembershipOrderItemByIdBodyIncludeOneOf = null;

export type DeleteDestroyMembershipOrderItemByIdBodyInclude =
  | DeleteDestroyMembershipOrderItemByIdBodyIncludeOneOf
  | MembershipOrderItemInclude;

export type DeleteDestroyMembershipOrderItemByIdBodySelectOneOf = null;

export type DeleteDestroyMembershipOrderItemByIdBodySelect =
  | DeleteDestroyMembershipOrderItemByIdBodySelectOneOf
  | MembershipOrderItemSelect;

export type DeleteDestroyMembershipOrderItemByIdBody = {
  select?: DeleteDestroyMembershipOrderItemByIdBodySelect;
  include?: DeleteDestroyMembershipOrderItemByIdBodyInclude;
};

export type PatchRestoreMembershipOrderItemByIdBodyIncludeOneOf = null;

export type PatchRestoreMembershipOrderItemByIdBodyInclude =
  | PatchRestoreMembershipOrderItemByIdBodyIncludeOneOf
  | MembershipOrderItemInclude;

export type PatchRestoreMembershipOrderItemByIdBody = {
  select?: PatchRestoreMembershipOrderItemByIdBodySelect;
  include?: PatchRestoreMembershipOrderItemByIdBodyInclude;
};

export type PatchRestoreMembershipOrderItemByIdBodySelectOneOf = null;

export type PatchRestoreMembershipOrderItemByIdBodySelect =
  | PatchRestoreMembershipOrderItemByIdBodySelectOneOf
  | MembershipOrderItemSelect;

export type DeleteMembershipOrderItemByIdBodyIncludeOneOf = null;

export type DeleteMembershipOrderItemByIdBodyInclude =
  | DeleteMembershipOrderItemByIdBodyIncludeOneOf
  | MembershipOrderItemInclude;

export type DeleteMembershipOrderItemByIdBody = {
  select?: DeleteMembershipOrderItemByIdBodySelect;
  include?: DeleteMembershipOrderItemByIdBodyInclude;
};

export type DeleteMembershipOrderItemByIdBodySelectOneOf = null;

export type DeleteMembershipOrderItemByIdBodySelect =
  | DeleteMembershipOrderItemByIdBodySelectOneOf
  | MembershipOrderItemSelect;

export type PatchMembershipOrderItemByIdBodyIncludeOneOf = null;

export type PatchMembershipOrderItemByIdBodyInclude =
  | PatchMembershipOrderItemByIdBodyIncludeOneOf
  | MembershipOrderItemInclude;

export type PatchMembershipOrderItemByIdBodySelectOneOf = null;

export type PatchMembershipOrderItemByIdBodySelect =
  | PatchMembershipOrderItemByIdBodySelectOneOf
  | MembershipOrderItemSelect;

export type PatchMembershipOrderItemByIdBody = {
  select?: PatchMembershipOrderItemByIdBodySelect;
  include?: PatchMembershipOrderItemByIdBodyInclude;
  data: MembershipOrderItemUpdate;
};

export type PostBulkGenerateMembershipOrderBodyMonth =
  typeof PostBulkGenerateMembershipOrderBodyMonth[keyof typeof PostBulkGenerateMembershipOrderBodyMonth];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostBulkGenerateMembershipOrderBodyMonth = {
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
} as const;

export type PostBulkGenerateMembershipOrderBody = {
  membershipIds: string[];
  month: PostBulkGenerateMembershipOrderBodyMonth;
  year: string;
};

export type PostMembershipOrderBodyIncludeOneOf = null;

export type PostMembershipOrderBodyInclude = PostMembershipOrderBodyIncludeOneOf | MembershipOrderInclude;

export type PostMembershipOrderBodySelectOneOf = null;

export type PostMembershipOrderBodySelect = PostMembershipOrderBodySelectOneOf | MembershipOrderSelect;

export type PostMembershipOrderBody = {
  select?: PostMembershipOrderBodySelect;
  include?: PostMembershipOrderBodyInclude;
  data: MembershipOrderCreate;
};

export type DeleteDestroyMembershipOrderByIdBodyInclude =
  | DeleteDestroyMembershipOrderByIdBodyIncludeOneOf
  | MembershipOrderInclude;

export type DeleteDestroyMembershipOrderByIdBody = {
  select?: DeleteDestroyMembershipOrderByIdBodySelect;
  include?: DeleteDestroyMembershipOrderByIdBodyInclude;
};

export type DeleteDestroyMembershipOrderByIdBodyIncludeOneOf = null;

export type DeleteDestroyMembershipOrderByIdBodySelectOneOf = null;

export type DeleteDestroyMembershipOrderByIdBodySelect =
  | DeleteDestroyMembershipOrderByIdBodySelectOneOf
  | MembershipOrderSelect;

export type PatchRestoreMembershipOrderByIdBodyIncludeOneOf = null;

export type PatchRestoreMembershipOrderByIdBodyInclude =
  | PatchRestoreMembershipOrderByIdBodyIncludeOneOf
  | MembershipOrderInclude;

export type PatchRestoreMembershipOrderByIdBodySelectOneOf = null;

export type PatchRestoreMembershipOrderByIdBodySelect =
  | PatchRestoreMembershipOrderByIdBodySelectOneOf
  | MembershipOrderSelect;

export type PatchRestoreMembershipOrderByIdBody = {
  select?: PatchRestoreMembershipOrderByIdBodySelect;
  include?: PatchRestoreMembershipOrderByIdBodyInclude;
};

export type DeleteMembershipOrderByIdBodyIncludeOneOf = null;

export type DeleteMembershipOrderByIdBodyInclude = DeleteMembershipOrderByIdBodyIncludeOneOf | MembershipOrderInclude;

export type DeleteMembershipOrderByIdBodySelectOneOf = null;

export type DeleteMembershipOrderByIdBodySelect = DeleteMembershipOrderByIdBodySelectOneOf | MembershipOrderSelect;

export type DeleteMembershipOrderByIdBody = {
  select?: DeleteMembershipOrderByIdBodySelect;
  include?: DeleteMembershipOrderByIdBodyInclude;
};

export type PatchMembershipOrderByIdBodyIncludeOneOf = null;

export type PatchMembershipOrderByIdBodyInclude = PatchMembershipOrderByIdBodyIncludeOneOf | MembershipOrderInclude;

export type PatchMembershipOrderByIdBody = {
  select?: PatchMembershipOrderByIdBodySelect;
  include?: PatchMembershipOrderByIdBodyInclude;
  data: MembershipOrderUpdate;
};

export type PatchMembershipOrderByIdBodySelectOneOf = null;

export type PatchMembershipOrderByIdBodySelect = PatchMembershipOrderByIdBodySelectOneOf | MembershipOrderSelect;

export type PostMembershipFrequencyBodyIncludeOneOf = null;

export type PostMembershipFrequencyBodyInclude = PostMembershipFrequencyBodyIncludeOneOf | MembershipFrequencyInclude;

export type PostMembershipFrequencyBodySelectOneOf = null;

export type PostMembershipFrequencyBodySelect = PostMembershipFrequencyBodySelectOneOf | MembershipFrequencySelect;

export type PostMembershipFrequencyBody = {
  select?: PostMembershipFrequencyBodySelect;
  include?: PostMembershipFrequencyBodyInclude;
  data: MembershipFrequencyCreate;
};

export type DeleteDestroyMembershipFrequencyByIdBodyIncludeOneOf = null;

export type DeleteDestroyMembershipFrequencyByIdBodyInclude =
  | DeleteDestroyMembershipFrequencyByIdBodyIncludeOneOf
  | MembershipFrequencyInclude;

export type DeleteDestroyMembershipFrequencyByIdBody = {
  select?: DeleteDestroyMembershipFrequencyByIdBodySelect;
  include?: DeleteDestroyMembershipFrequencyByIdBodyInclude;
};

export type DeleteDestroyMembershipFrequencyByIdBodySelectOneOf = null;

export type DeleteDestroyMembershipFrequencyByIdBodySelect =
  | DeleteDestroyMembershipFrequencyByIdBodySelectOneOf
  | MembershipFrequencySelect;

export type PatchRestoreMembershipFrequencyByIdBodyIncludeOneOf = null;

export type PatchRestoreMembershipFrequencyByIdBodyInclude =
  | PatchRestoreMembershipFrequencyByIdBodyIncludeOneOf
  | MembershipFrequencyInclude;

export type PatchRestoreMembershipFrequencyByIdBody = {
  select?: PatchRestoreMembershipFrequencyByIdBodySelect;
  include?: PatchRestoreMembershipFrequencyByIdBodyInclude;
};

export type PatchRestoreMembershipFrequencyByIdBodySelectOneOf = null;

export type PatchRestoreMembershipFrequencyByIdBodySelect =
  | PatchRestoreMembershipFrequencyByIdBodySelectOneOf
  | MembershipFrequencySelect;

export type DeleteMembershipFrequencyByIdBody = {
  select?: DeleteMembershipFrequencyByIdBodySelect;
  include?: DeleteMembershipFrequencyByIdBodyInclude;
};

export type DeleteMembershipFrequencyByIdBodyIncludeOneOf = null;

export type DeleteMembershipFrequencyByIdBodyInclude =
  | DeleteMembershipFrequencyByIdBodyIncludeOneOf
  | MembershipFrequencyInclude;

export type DeleteMembershipFrequencyByIdBodySelectOneOf = null;

export type DeleteMembershipFrequencyByIdBodySelect =
  | DeleteMembershipFrequencyByIdBodySelectOneOf
  | MembershipFrequencySelect;

export type PatchMembershipFrequencyByIdBodyIncludeOneOf = null;

export type PatchMembershipFrequencyByIdBodyInclude =
  | PatchMembershipFrequencyByIdBodyIncludeOneOf
  | MembershipFrequencyInclude;

export type PatchMembershipFrequencyByIdBodySelectOneOf = null;

export type PatchMembershipFrequencyByIdBodySelect =
  | PatchMembershipFrequencyByIdBodySelectOneOf
  | MembershipFrequencySelect;

export type PatchMembershipFrequencyByIdBody = {
  select?: PatchMembershipFrequencyByIdBodySelect;
  include?: PatchMembershipFrequencyByIdBodyInclude;
  data: MembershipFrequencyUpdate;
};

export type PostMembershipTierBodyIncludeOneOf = null;

export type PostMembershipTierBodyInclude = PostMembershipTierBodyIncludeOneOf | MembershipTierInclude;

export type PostMembershipTierBodySelectOneOf = null;

export type PostMembershipTierBodySelect = PostMembershipTierBodySelectOneOf | MembershipTierSelect;

export type PostMembershipTierBody = {
  select?: PostMembershipTierBodySelect;
  include?: PostMembershipTierBodyInclude;
  data: MembershipTierCreate;
};

export type DeleteDestroyMembershipTierByIdBodyIncludeOneOf = null;

export type DeleteDestroyMembershipTierByIdBodyInclude =
  | DeleteDestroyMembershipTierByIdBodyIncludeOneOf
  | MembershipTierInclude;

export type DeleteDestroyMembershipTierByIdBody = {
  select?: DeleteDestroyMembershipTierByIdBodySelect;
  include?: DeleteDestroyMembershipTierByIdBodyInclude;
};

export type DeleteDestroyMembershipTierByIdBodySelectOneOf = null;

export type DeleteDestroyMembershipTierByIdBodySelect =
  | DeleteDestroyMembershipTierByIdBodySelectOneOf
  | MembershipTierSelect;

export type PatchRestoreMembershipTierByIdBodyIncludeOneOf = null;

export type PatchRestoreMembershipTierByIdBodyInclude =
  | PatchRestoreMembershipTierByIdBodyIncludeOneOf
  | MembershipTierInclude;

export type PatchRestoreMembershipTierByIdBodySelectOneOf = null;

export type PatchRestoreMembershipTierByIdBodySelect =
  | PatchRestoreMembershipTierByIdBodySelectOneOf
  | MembershipTierSelect;

export type PatchRestoreMembershipTierByIdBody = {
  select?: PatchRestoreMembershipTierByIdBodySelect;
  include?: PatchRestoreMembershipTierByIdBodyInclude;
};

export type DeleteMembershipTierByIdBodyIncludeOneOf = null;

export type DeleteMembershipTierByIdBodyInclude = DeleteMembershipTierByIdBodyIncludeOneOf | MembershipTierInclude;

export type DeleteMembershipTierByIdBody = {
  select?: DeleteMembershipTierByIdBodySelect;
  include?: DeleteMembershipTierByIdBodyInclude;
};

export type DeleteMembershipTierByIdBodySelectOneOf = null;

export type DeleteMembershipTierByIdBodySelect = DeleteMembershipTierByIdBodySelectOneOf | MembershipTierSelect;

export type PatchMembershipTierByIdBodyIncludeOneOf = null;

export type PatchMembershipTierByIdBodyInclude = PatchMembershipTierByIdBodyIncludeOneOf | MembershipTierInclude;

export type PatchMembershipTierByIdBody = {
  select?: PatchMembershipTierByIdBodySelect;
  include?: PatchMembershipTierByIdBodyInclude;
  data: MembershipTierUpdate;
};

export type PatchMembershipTierByIdBodySelectOneOf = null;

export type PatchMembershipTierByIdBodySelect = PatchMembershipTierByIdBodySelectOneOf | MembershipTierSelect;

export type PostMembershipBodyIncludeOneOf = null;

export type PostMembershipBodyInclude = PostMembershipBodyIncludeOneOf | MembershipInclude;

export type PostMembershipBody = {
  select?: PostMembershipBodySelect;
  include?: PostMembershipBodyInclude;
  data: MembershipCreate;
};

export type PostMembershipBodySelectOneOf = null;

export type PostMembershipBodySelect = PostMembershipBodySelectOneOf | MembershipSelect;

export type DeleteDestroyMembershipByIdBodyIncludeOneOf = null;

export type DeleteDestroyMembershipByIdBodyInclude = DeleteDestroyMembershipByIdBodyIncludeOneOf | MembershipInclude;

export type DeleteDestroyMembershipByIdBodySelectOneOf = null;

export type DeleteDestroyMembershipByIdBodySelect = DeleteDestroyMembershipByIdBodySelectOneOf | MembershipSelect;

export type DeleteDestroyMembershipByIdBody = {
  select?: DeleteDestroyMembershipByIdBodySelect;
  include?: DeleteDestroyMembershipByIdBodyInclude;
};

export type PatchRestoreMembershipByIdBodyIncludeOneOf = null;

export type PatchRestoreMembershipByIdBodyInclude = PatchRestoreMembershipByIdBodyIncludeOneOf | MembershipInclude;

export type PatchRestoreMembershipByIdBody = {
  select?: PatchRestoreMembershipByIdBodySelect;
  include?: PatchRestoreMembershipByIdBodyInclude;
};

export type PatchRestoreMembershipByIdBodySelectOneOf = null;

export type PatchRestoreMembershipByIdBodySelect = PatchRestoreMembershipByIdBodySelectOneOf | MembershipSelect;

export type DeleteMembershipByIdBodyIncludeOneOf = null;

export type DeleteMembershipByIdBodyInclude = DeleteMembershipByIdBodyIncludeOneOf | MembershipInclude;

export type DeleteMembershipByIdBody = {
  select?: DeleteMembershipByIdBodySelect;
  include?: DeleteMembershipByIdBodyInclude;
};

export type DeleteMembershipByIdBodySelectOneOf = null;

export type DeleteMembershipByIdBodySelect = DeleteMembershipByIdBodySelectOneOf | MembershipSelect;

export type PatchMembershipByIdBodyIncludeOneOf = null;

export type PatchMembershipByIdBodyInclude = PatchMembershipByIdBodyIncludeOneOf | MembershipInclude;

export type PatchMembershipByIdBodySelectOneOf = null;

export type PatchMembershipByIdBodySelect = PatchMembershipByIdBodySelectOneOf | MembershipSelect;

export type PatchMembershipByIdBody = {
  select?: PatchMembershipByIdBodySelect;
  include?: PatchMembershipByIdBodyInclude;
  data: MembershipUpdate;
};

export type PostImportAccountsFromZohoCRMResponseBodyErrorsItem = {
  id: string;
  error: string;
};

export type PostImportAccountsFromZohoCRMResponseBody = {
  imported: string[];
  errors: PostImportAccountsFromZohoCRMResponseBodyErrorsItem[];
};

export type ContactUpdateBillingForAccount = {
  connect: AccountUniqueWhere;
} | null;

export type ContactUpdatePrimaryForAccount = {
  connect: AccountUniqueWhere;
} | null;

export type ContactUpdateAccount = {
  connect: AccountUniqueWhere;
} | null;

export type ContactUpdateStatus = typeof ContactUpdateStatus[keyof typeof ContactUpdateStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactUpdateStatus = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type ContactUpdate = {
  id?: string | null;
  name?: string | null;
  email?: string | null;
  phone?: string | null;
  status?: ContactUpdateStatus;
  userSub?: string | null;
  zohoCrmContactRef?: string | null;
  zohoInvContactPersonRef?: string | null;
  jobTitle?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  account?: ContactUpdateAccount;
  primaryForAccount?: ContactUpdatePrimaryForAccount;
  billingForAccount?: ContactUpdateBillingForAccount;
};

export type ContactCreateBillingForAccount = {
  connect: AccountUniqueWhere;
} | null;

export type ContactCreatePrimaryForAccount = {
  connect: AccountUniqueWhere;
} | null;

export type ContactCreateAccount = {
  connect: AccountUniqueWhere;
};

export type ContactCreateStatus = typeof ContactCreateStatus[keyof typeof ContactCreateStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactCreateStatus = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type ContactCreate = {
  id?: string | null;
  name: string;
  email: string;
  phone?: string | null;
  status?: ContactCreateStatus;
  userSub?: string | null;
  zohoCrmContactRef?: string | null;
  zohoInvContactPersonRef?: string | null;
  jobTitle?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  account: ContactCreateAccount;
  primaryForAccount?: ContactCreatePrimaryForAccount;
  billingForAccount?: ContactCreateBillingForAccount;
};

export type ContactUniqueWhere = {
  id?: string | null;
};

export type ContactWhereBillingForAccount = AccountWhere | ContactWhereBillingForAccountOneOf;

export type ContactWhere = {
  AND?: ContactWhereAnd;
  OR?: ContactWhereOr;
  NOT?: ContactWhereNot;
  id?: ContactWhereId;
  name?: ContactWhereName;
  email?: ContactWhereEmail;
  phone?: ContactWherePhone;
  status?: ContactWhereStatus;
  userSub?: ContactWhereUserSub;
  zohoCrmContactRef?: ContactWhereZohoCrmContactRef;
  zohoInvContactPersonRef?: ContactWhereZohoInvContactPersonRef;
  accountId?: ContactWhereAccountId;
  jobTitle?: ContactWhereJobTitle;
  createdAt?: ContactWhereCreatedAt;
  updatedAt?: ContactWhereUpdatedAt;
  deletedAt?: ContactWhereDeletedAt;
  account?: ContactWhereAccount;
  primaryForAccount?: ContactWherePrimaryForAccount;
  billingForAccount?: ContactWhereBillingForAccount;
};

export type ContactWhereBillingForAccountOneOfIsNot = AccountWhere;

export type ContactWhereBillingForAccountOneOfIs = AccountWhere;

export type ContactWhereBillingForAccountOneOf = {
  is?: ContactWhereBillingForAccountOneOfIs;
  isNot?: ContactWhereBillingForAccountOneOfIsNot;
};

export type ContactWherePrimaryForAccount = AccountWhere | ContactWherePrimaryForAccountOneOf;

export type ContactWherePrimaryForAccountOneOfIsNot = AccountWhere;

export type ContactWherePrimaryForAccountOneOfIs = AccountWhere;

export type ContactWherePrimaryForAccountOneOf = {
  is?: ContactWherePrimaryForAccountOneOfIs;
  isNot?: ContactWherePrimaryForAccountOneOfIsNot;
};

export type ContactWhereAccountOneOfIsNot = AccountWhere;

export type ContactWhereAccountOneOfIs = AccountWhere;

export type ContactWhereAccountOneOf = {
  is?: ContactWhereAccountOneOfIs;
  isNot?: ContactWhereAccountOneOfIsNot;
};

export type ContactWhereAccount = AccountWhere | ContactWhereAccountOneOf;

export type ContactWhereDeletedAtOneOfNot = string | DeletedAt;

export type ContactWhereDeletedAtOneOfNotIn = string | string[];

export type ContactWhereDeletedAtOneOfIn = string | string[];

export type ContactWhereDeletedAtOneOfEquals = string;

export type ContactWhereDeletedAtOneOf = {
  equals?: ContactWhereDeletedAtOneOfEquals;
  in?: ContactWhereDeletedAtOneOfIn;
  notIn?: ContactWhereDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: ContactWhereDeletedAtOneOfNot;
};

export type ContactWhereDeletedAt = string | ContactWhereDeletedAtOneOf;

export type ContactWhereUpdatedAt = string | ContactWhereUpdatedAtOneOf;

export type ContactWhereUpdatedAtOneOfNot = string | UpdatedAt;

export type ContactWhereUpdatedAtOneOfNotIn = string | string[];

export type ContactWhereUpdatedAtOneOfIn = string | string[];

export type ContactWhereUpdatedAtOneOf = {
  equals?: string | null;
  in?: ContactWhereUpdatedAtOneOfIn;
  notIn?: ContactWhereUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: ContactWhereUpdatedAtOneOfNot;
};

export type ContactWhereCreatedAt = string | ContactWhereCreatedAtOneOf;

export type ContactWhereCreatedAtOneOfNot = string | CreatedAt;

export type ContactWhereCreatedAtOneOfNotIn = string | string[];

export type ContactWhereCreatedAtOneOfIn = string | string[];

export type ContactWhereCreatedAtOneOf = {
  equals?: string | null;
  in?: ContactWhereCreatedAtOneOfIn;
  notIn?: ContactWhereCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: ContactWhereCreatedAtOneOfNot;
};

export type ContactWhereJobTitle = string | ContactWhereJobTitleOneOf;

export type ContactWhereJobTitleOneOfNot = string | JobTitle;

export type ContactWhereJobTitleOneOfMode =
  typeof ContactWhereJobTitleOneOfMode[keyof typeof ContactWhereJobTitleOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactWhereJobTitleOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ContactWhereJobTitleOneOfNotIn = string | string[];

export type ContactWhereJobTitleOneOfIn = string | string[];

export type ContactWhereJobTitleOneOfEquals = string;

export type ContactWhereJobTitleOneOf = {
  equals?: ContactWhereJobTitleOneOfEquals;
  in?: ContactWhereJobTitleOneOfIn;
  notIn?: ContactWhereJobTitleOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ContactWhereJobTitleOneOfMode;
  not?: ContactWhereJobTitleOneOfNot;
};

export type ContactWhereAccountId = string | ContactWhereAccountIdOneOf;

export type ContactWhereAccountIdOneOfNot = string | AccountId;

export type ContactWhereAccountIdOneOfMode =
  typeof ContactWhereAccountIdOneOfMode[keyof typeof ContactWhereAccountIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactWhereAccountIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ContactWhereAccountIdOneOfNotIn = string | string[];

export type ContactWhereAccountIdOneOfIn = string | string[];

export type ContactWhereAccountIdOneOf = {
  equals?: string | null;
  in?: ContactWhereAccountIdOneOfIn;
  notIn?: ContactWhereAccountIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ContactWhereAccountIdOneOfMode;
  not?: ContactWhereAccountIdOneOfNot;
};

export type ContactWhereZohoInvContactPersonRefOneOfNot = string | ZohoInvContactPersonRef;

export type ContactWhereZohoInvContactPersonRefOneOfMode =
  typeof ContactWhereZohoInvContactPersonRefOneOfMode[keyof typeof ContactWhereZohoInvContactPersonRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactWhereZohoInvContactPersonRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ContactWhereZohoInvContactPersonRefOneOfNotIn = string | string[];

export type ContactWhereZohoInvContactPersonRefOneOfIn = string | string[];

export type ContactWhereZohoInvContactPersonRefOneOfEquals = string;

export type ContactWhereZohoInvContactPersonRefOneOf = {
  equals?: ContactWhereZohoInvContactPersonRefOneOfEquals;
  in?: ContactWhereZohoInvContactPersonRefOneOfIn;
  notIn?: ContactWhereZohoInvContactPersonRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ContactWhereZohoInvContactPersonRefOneOfMode;
  not?: ContactWhereZohoInvContactPersonRefOneOfNot;
};

export type ContactWhereZohoInvContactPersonRef = string | ContactWhereZohoInvContactPersonRefOneOf;

export type ContactWhereZohoCrmContactRef = string | ContactWhereZohoCrmContactRefOneOf;

export type ContactWhereZohoCrmContactRefOneOfNot = string | ZohoCrmContactRef;

export type ContactWhereZohoCrmContactRefOneOfMode =
  typeof ContactWhereZohoCrmContactRefOneOfMode[keyof typeof ContactWhereZohoCrmContactRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactWhereZohoCrmContactRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ContactWhereZohoCrmContactRefOneOfNotIn = string | string[];

export type ContactWhereZohoCrmContactRefOneOfIn = string | string[];

export type ContactWhereZohoCrmContactRefOneOfEquals = string;

export type ContactWhereZohoCrmContactRefOneOf = {
  equals?: ContactWhereZohoCrmContactRefOneOfEquals;
  in?: ContactWhereZohoCrmContactRefOneOfIn;
  notIn?: ContactWhereZohoCrmContactRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ContactWhereZohoCrmContactRefOneOfMode;
  not?: ContactWhereZohoCrmContactRefOneOfNot;
};

export type ContactWhereUserSub = string | ContactWhereUserSubOneOf;

export type ContactWhereUserSubOneOfNot = string | UserSub;

export type ContactWhereUserSubOneOfMode =
  typeof ContactWhereUserSubOneOfMode[keyof typeof ContactWhereUserSubOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactWhereUserSubOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ContactWhereUserSubOneOfNotIn = string | string[];

export type ContactWhereUserSubOneOfIn = string | string[];

export type ContactWhereUserSubOneOfEquals = string;

export type ContactWhereUserSubOneOf = {
  equals?: ContactWhereUserSubOneOfEquals;
  in?: ContactWhereUserSubOneOfIn;
  notIn?: ContactWhereUserSubOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ContactWhereUserSubOneOfMode;
  not?: ContactWhereUserSubOneOfNot;
};

export type ContactWhereStatus = 'ACTIVE' | 'DISABLED' | ContactWhereStatusOneOf;

export type ContactWhereStatusOneOfNot = 'ACTIVE' | 'DISABLED' | Status;

export type ContactWhereStatusOneOfNotInOneOfItem =
  typeof ContactWhereStatusOneOfNotInOneOfItem[keyof typeof ContactWhereStatusOneOfNotInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactWhereStatusOneOfNotInOneOfItem = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type ContactWhereStatusOneOfNotIn = 'ACTIVE' | 'DISABLED' | ContactWhereStatusOneOfNotInOneOfItem[];

export type ContactWhereStatusOneOfInOneOfItem =
  typeof ContactWhereStatusOneOfInOneOfItem[keyof typeof ContactWhereStatusOneOfInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactWhereStatusOneOfInOneOfItem = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type ContactWhereStatusOneOfIn = 'ACTIVE' | 'DISABLED' | ContactWhereStatusOneOfInOneOfItem[];

export type ContactWhereStatusOneOfEquals =
  typeof ContactWhereStatusOneOfEquals[keyof typeof ContactWhereStatusOneOfEquals];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactWhereStatusOneOfEquals = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type ContactWhereStatusOneOf = {
  equals?: ContactWhereStatusOneOfEquals;
  in?: ContactWhereStatusOneOfIn;
  notIn?: ContactWhereStatusOneOfNotIn;
  not?: ContactWhereStatusOneOfNot;
} | null;

export type ContactWherePhone = string | ContactWherePhoneOneOf;

export type ContactWherePhoneOneOfNot = string | Phone;

export type ContactWherePhoneOneOfMode = typeof ContactWherePhoneOneOfMode[keyof typeof ContactWherePhoneOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactWherePhoneOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ContactWherePhoneOneOfNotIn = string | string[];

export type ContactWherePhoneOneOfIn = string | string[];

export type ContactWherePhoneOneOfEquals = string;

export type ContactWherePhoneOneOf = {
  equals?: ContactWherePhoneOneOfEquals;
  in?: ContactWherePhoneOneOfIn;
  notIn?: ContactWherePhoneOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ContactWherePhoneOneOfMode;
  not?: ContactWherePhoneOneOfNot;
};

export type ContactWhereEmailOneOfNot = string | Email;

export type ContactWhereEmailOneOfMode = typeof ContactWhereEmailOneOfMode[keyof typeof ContactWhereEmailOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactWhereEmailOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ContactWhereEmailOneOfNotIn = string | string[];

export type ContactWhereEmailOneOfIn = string | string[];

export type ContactWhereEmailOneOf = {
  equals?: string | null;
  in?: ContactWhereEmailOneOfIn;
  notIn?: ContactWhereEmailOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ContactWhereEmailOneOfMode;
  not?: ContactWhereEmailOneOfNot;
};

export type ContactWhereEmail = string | ContactWhereEmailOneOf;

export type ContactWhereName = string | ContactWhereNameOneOf;

export type ContactWhereNameOneOfNot = string | Name;

export type ContactWhereNameOneOfMode = typeof ContactWhereNameOneOfMode[keyof typeof ContactWhereNameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactWhereNameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ContactWhereNameOneOfNotIn = string | string[];

export type ContactWhereNameOneOfIn = string | string[];

export type ContactWhereNameOneOf = {
  equals?: string | null;
  in?: ContactWhereNameOneOfIn;
  notIn?: ContactWhereNameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ContactWhereNameOneOfMode;
  not?: ContactWhereNameOneOfNot;
};

export type ContactWhereId = string | ContactWhereIdOneOf;

export type ContactWhereIdOneOfNot = string | Id;

export type ContactWhereIdOneOfMode = typeof ContactWhereIdOneOfMode[keyof typeof ContactWhereIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactWhereIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ContactWhereIdOneOfNotIn = string | string[];

export type ContactWhereIdOneOfIn = string | string[];

export type ContactWhereIdOneOf = {
  equals?: string | null;
  in?: ContactWhereIdOneOfIn;
  notIn?: ContactWhereIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ContactWhereIdOneOfMode;
  not?: ContactWhereIdOneOfNot;
};

export type ContactWhereNot = ContactWhere | ContactWhere[];

export type ContactWhereOr = ContactWhere | ContactWhere[];

export type ContactWhereAnd = ContactWhere | ContactWhere[];

export type ContactBillingForAccount = Account;

export type ContactPrimaryForAccount = Account;

export type ContactAccount = Account;

export type ContactStatus = typeof ContactStatus[keyof typeof ContactStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContactStatus = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type Contact = {
  id?: string | null;
  name?: string | null;
  email?: string | null;
  phone?: string | null;
  status?: ContactStatus;
  userSub?: string | null;
  zohoCrmContactRef?: string | null;
  zohoInvContactPersonRef?: string | null;
  accountId?: string | null;
  jobTitle?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  account?: ContactAccount;
  primaryForAccount?: ContactPrimaryForAccount;
  billingForAccount?: ContactBillingForAccount;
};

export type Contacts = {
  items?: Contact[];
};

export type ContactInclude = {
  account?: boolean | null;
  primaryForAccount?: boolean | null;
  billingForAccount?: boolean | null;
};

export type ContactSelect = {
  id?: boolean | null;
  name?: boolean | null;
  email?: boolean | null;
  phone?: boolean | null;
  status?: boolean | null;
  userSub?: boolean | null;
  zohoCrmContactRef?: boolean | null;
  zohoInvContactPersonRef?: boolean | null;
  accountId?: boolean | null;
  jobTitle?: boolean | null;
  createdAt?: boolean | null;
  updatedAt?: boolean | null;
  deletedAt?: boolean | null;
  account?: boolean | null;
  primaryForAccount?: boolean | null;
  billingForAccount?: boolean | null;
};

export type AddressUpdateAccount = {
  connect: AccountUniqueWhere;
} | null;

export type AddressUpdateType = typeof AddressUpdateType[keyof typeof AddressUpdateType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressUpdateType = {
  BILLING: 'BILLING',
  SHIPPING: 'SHIPPING',
} as const;

export type AddressUpdate = {
  id?: string | null;
  name?: string | null;
  line1?: string | null;
  line2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
  type?: AddressUpdateType;
  createdAt?: string | null;
  updatedAt?: string | null;
  account?: AddressUpdateAccount;
};

export type AddressCreateAccount = {
  connect: AccountUniqueWhere;
} | null;

export type AddressCreateType = typeof AddressCreateType[keyof typeof AddressCreateType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressCreateType = {
  BILLING: 'BILLING',
  SHIPPING: 'SHIPPING',
} as const;

export type AddressCreate = {
  id?: string | null;
  name?: string | null;
  line1: string;
  line2?: string | null;
  city: string;
  state: string;
  country?: string | null;
  postalCode: string;
  type: AddressCreateType;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  account?: AddressCreateAccount;
};

export type AddressUniqueWhere = {
  id?: string | null;
};

export type AddressWhereAccountOneOfIsNot = AccountWhere;

export type AddressWhereAccountOneOfIs = AccountWhere;

export type AddressWhereAccountOneOf = {
  is?: AddressWhereAccountOneOfIs;
  isNot?: AddressWhereAccountOneOfIsNot;
};

export type AddressWhereAccount = AccountWhere | AddressWhereAccountOneOf;

export type AddressWhereDeletedAt = string | AddressWhereDeletedAtOneOf;

export type AddressWhere = {
  AND?: AddressWhereAnd;
  OR?: AddressWhereOr;
  NOT?: AddressWhereNot;
  id?: AddressWhereId;
  name?: AddressWhereName;
  line1?: AddressWhereLine1;
  line2?: AddressWhereLine2;
  city?: AddressWhereCity;
  state?: AddressWhereState;
  country?: AddressWhereCountry;
  postalCode?: AddressWherePostalCode;
  type?: AddressWhereType;
  accountId?: AddressWhereAccountId;
  createdAt?: AddressWhereCreatedAt;
  updatedAt?: AddressWhereUpdatedAt;
  deletedAt?: AddressWhereDeletedAt;
  account?: AddressWhereAccount;
};

export type AddressWhereDeletedAtOneOfNot = string | DeletedAt;

export type AddressWhereDeletedAtOneOfNotIn = string | string[];

export type AddressWhereDeletedAtOneOfIn = string | string[];

export type AddressWhereDeletedAtOneOfEquals = string;

export type AddressWhereDeletedAtOneOf = {
  equals?: AddressWhereDeletedAtOneOfEquals;
  in?: AddressWhereDeletedAtOneOfIn;
  notIn?: AddressWhereDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: AddressWhereDeletedAtOneOfNot;
};

export type AddressWhereUpdatedAtOneOfNot = string | UpdatedAt;

export type AddressWhereUpdatedAtOneOfNotIn = string | string[];

export type AddressWhereUpdatedAtOneOfIn = string | string[];

export type AddressWhereUpdatedAtOneOf = {
  equals?: string | null;
  in?: AddressWhereUpdatedAtOneOfIn;
  notIn?: AddressWhereUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: AddressWhereUpdatedAtOneOfNot;
};

export type AddressWhereUpdatedAt = string | AddressWhereUpdatedAtOneOf;

export type AddressWhereCreatedAtOneOfNot = string | CreatedAt;

export type AddressWhereCreatedAtOneOfNotIn = string | string[];

export type AddressWhereCreatedAtOneOfIn = string | string[];

export type AddressWhereCreatedAtOneOf = {
  equals?: string | null;
  in?: AddressWhereCreatedAtOneOfIn;
  notIn?: AddressWhereCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: AddressWhereCreatedAtOneOfNot;
};

export type AddressWhereCreatedAt = string | AddressWhereCreatedAtOneOf;

export type AddressWhereAccountId = string | AddressWhereAccountIdOneOf;

export type AddressWhereAccountIdOneOfNot = string | AccountId;

export type AddressWhereAccountIdOneOfMode =
  typeof AddressWhereAccountIdOneOfMode[keyof typeof AddressWhereAccountIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressWhereAccountIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type AddressWhereAccountIdOneOfNotIn = string | string[];

export type AddressWhereAccountIdOneOfIn = string | string[];

export type AddressWhereAccountIdOneOf = {
  equals?: string | null;
  in?: AddressWhereAccountIdOneOfIn;
  notIn?: AddressWhereAccountIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: AddressWhereAccountIdOneOfMode;
  not?: AddressWhereAccountIdOneOfNot;
};

export type AddressWhereTypeOneOfNot = 'BILLING' | 'SHIPPING' | Type;

export type AddressWhereTypeOneOfNotInOneOfItem =
  typeof AddressWhereTypeOneOfNotInOneOfItem[keyof typeof AddressWhereTypeOneOfNotInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressWhereTypeOneOfNotInOneOfItem = {
  BILLING: 'BILLING',
  SHIPPING: 'SHIPPING',
} as const;

export type AddressWhereTypeOneOfNotIn = 'BILLING' | 'SHIPPING' | AddressWhereTypeOneOfNotInOneOfItem[];

export type AddressWhereTypeOneOfInOneOfItem =
  typeof AddressWhereTypeOneOfInOneOfItem[keyof typeof AddressWhereTypeOneOfInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressWhereTypeOneOfInOneOfItem = {
  BILLING: 'BILLING',
  SHIPPING: 'SHIPPING',
} as const;

export type AddressWhereTypeOneOfIn = 'BILLING' | 'SHIPPING' | AddressWhereTypeOneOfInOneOfItem[];

export type AddressWhereTypeOneOfEquals = typeof AddressWhereTypeOneOfEquals[keyof typeof AddressWhereTypeOneOfEquals];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressWhereTypeOneOfEquals = {
  BILLING: 'BILLING',
  SHIPPING: 'SHIPPING',
} as const;

export type AddressWhereTypeOneOf = {
  equals?: AddressWhereTypeOneOfEquals;
  in?: AddressWhereTypeOneOfIn;
  notIn?: AddressWhereTypeOneOfNotIn;
  not?: AddressWhereTypeOneOfNot;
} | null;

export type AddressWhereType = 'BILLING' | 'SHIPPING' | AddressWhereTypeOneOf;

export type AddressWherePostalCodeOneOfNot = string | PostalCode;

export type AddressWherePostalCodeOneOfMode =
  typeof AddressWherePostalCodeOneOfMode[keyof typeof AddressWherePostalCodeOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressWherePostalCodeOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type AddressWherePostalCodeOneOfNotIn = string | string[];

export type AddressWherePostalCodeOneOfIn = string | string[];

export type AddressWherePostalCodeOneOf = {
  equals?: string | null;
  in?: AddressWherePostalCodeOneOfIn;
  notIn?: AddressWherePostalCodeOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: AddressWherePostalCodeOneOfMode;
  not?: AddressWherePostalCodeOneOfNot;
};

export type AddressWherePostalCode = string | AddressWherePostalCodeOneOf;

export type AddressWhereCountryOneOfNot = string | Country;

export type AddressWhereCountryOneOfMode =
  typeof AddressWhereCountryOneOfMode[keyof typeof AddressWhereCountryOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressWhereCountryOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type AddressWhereCountryOneOfNotIn = string | string[];

export type AddressWhereCountryOneOfIn = string | string[];

export type AddressWhereCountryOneOfEquals = string;

export type AddressWhereCountryOneOf = {
  equals?: AddressWhereCountryOneOfEquals;
  in?: AddressWhereCountryOneOfIn;
  notIn?: AddressWhereCountryOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: AddressWhereCountryOneOfMode;
  not?: AddressWhereCountryOneOfNot;
};

export type AddressWhereCountry = string | AddressWhereCountryOneOf;

export type AddressWhereState = string | AddressWhereStateOneOf;

export type AddressWhereStateOneOfNot = string | State;

export type AddressWhereStateOneOfMode = typeof AddressWhereStateOneOfMode[keyof typeof AddressWhereStateOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressWhereStateOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type AddressWhereStateOneOfNotIn = string | string[];

export type AddressWhereStateOneOfIn = string | string[];

export type AddressWhereStateOneOf = {
  equals?: string | null;
  in?: AddressWhereStateOneOfIn;
  notIn?: AddressWhereStateOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: AddressWhereStateOneOfMode;
  not?: AddressWhereStateOneOfNot;
};

export type AddressWhereCity = string | AddressWhereCityOneOf;

export type AddressWhereCityOneOfNot = string | City;

export type AddressWhereCityOneOfMode = typeof AddressWhereCityOneOfMode[keyof typeof AddressWhereCityOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressWhereCityOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type AddressWhereCityOneOfNotIn = string | string[];

export type AddressWhereCityOneOfIn = string | string[];

export type AddressWhereCityOneOf = {
  equals?: string | null;
  in?: AddressWhereCityOneOfIn;
  notIn?: AddressWhereCityOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: AddressWhereCityOneOfMode;
  not?: AddressWhereCityOneOfNot;
};

export type AddressWhereLine2 = string | AddressWhereLine2OneOf;

export type AddressWhereLine2OneOfNot = string | Line2;

export type AddressWhereLine2OneOfMode = typeof AddressWhereLine2OneOfMode[keyof typeof AddressWhereLine2OneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressWhereLine2OneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type AddressWhereLine2OneOfNotIn = string | string[];

export type AddressWhereLine2OneOfIn = string | string[];

export type AddressWhereLine2OneOfEquals = string;

export type AddressWhereLine2OneOf = {
  equals?: AddressWhereLine2OneOfEquals;
  in?: AddressWhereLine2OneOfIn;
  notIn?: AddressWhereLine2OneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: AddressWhereLine2OneOfMode;
  not?: AddressWhereLine2OneOfNot;
};

export type AddressWhereLine1OneOfNot = string | Line1;

export type AddressWhereLine1OneOfMode = typeof AddressWhereLine1OneOfMode[keyof typeof AddressWhereLine1OneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressWhereLine1OneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type AddressWhereLine1OneOfNotIn = string | string[];

export type AddressWhereLine1OneOfIn = string | string[];

export type AddressWhereLine1OneOf = {
  equals?: string | null;
  in?: AddressWhereLine1OneOfIn;
  notIn?: AddressWhereLine1OneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: AddressWhereLine1OneOfMode;
  not?: AddressWhereLine1OneOfNot;
};

export type AddressWhereLine1 = string | AddressWhereLine1OneOf;

export type AddressWhereName = string | AddressWhereNameOneOf;

export type AddressWhereNameOneOfNot = string | Name;

export type AddressWhereNameOneOfMode = typeof AddressWhereNameOneOfMode[keyof typeof AddressWhereNameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressWhereNameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type AddressWhereNameOneOfNotIn = string | string[];

export type AddressWhereNameOneOfIn = string | string[];

export type AddressWhereNameOneOfEquals = string;

export type AddressWhereNameOneOf = {
  equals?: AddressWhereNameOneOfEquals;
  in?: AddressWhereNameOneOfIn;
  notIn?: AddressWhereNameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: AddressWhereNameOneOfMode;
  not?: AddressWhereNameOneOfNot;
};

export type AddressWhereId = string | AddressWhereIdOneOf;

export type AddressWhereIdOneOfNot = string | Id;

export type AddressWhereIdOneOfMode = typeof AddressWhereIdOneOfMode[keyof typeof AddressWhereIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressWhereIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type AddressWhereIdOneOfNotIn = string | string[];

export type AddressWhereIdOneOfIn = string | string[];

export type AddressWhereIdOneOf = {
  equals?: string | null;
  in?: AddressWhereIdOneOfIn;
  notIn?: AddressWhereIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: AddressWhereIdOneOfMode;
  not?: AddressWhereIdOneOfNot;
};

export type AddressWhereNot = AddressWhere | AddressWhere[];

export type AddressWhereOr = AddressWhere | AddressWhere[];

export type AddressWhereAnd = AddressWhere | AddressWhere[];

export type Addresses = {
  items?: Address[];
};

export type AddressAccount = Account;

export type AddressType = typeof AddressType[keyof typeof AddressType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressType = {
  BILLING: 'BILLING',
  SHIPPING: 'SHIPPING',
} as const;

export type Address = {
  id?: string | null;
  name?: string | null;
  line1?: string | null;
  line2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  postalCode?: string | null;
  type?: AddressType;
  accountId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  account?: AddressAccount;
};

export type AddressInclude = {
  account?: boolean | null;
};

export type AddressSelect = {
  id?: boolean | null;
  name?: boolean | null;
  line1?: boolean | null;
  line2?: boolean | null;
  city?: boolean | null;
  state?: boolean | null;
  country?: boolean | null;
  postalCode?: boolean | null;
  type?: boolean | null;
  accountId?: boolean | null;
  createdAt?: boolean | null;
  updatedAt?: boolean | null;
  deletedAt?: boolean | null;
  account?: boolean | null;
};

export type AccountUpdateMembershipsConnect = MembershipUniqueWhere | MembershipUniqueWhere[];

export type AccountUpdateMemberships = {
  connect: AccountUpdateMembershipsConnect;
} | null;

export type AccountUpdateAddressesConnect = AddressUniqueWhere | AddressUniqueWhere[];

export type AccountUpdateAddresses = {
  connect: AccountUpdateAddressesConnect;
} | null;

export type AccountUpdateSalesOrdersConnect = SalesOrderUniqueWhere | SalesOrderUniqueWhere[];

export type AccountUpdateSalesOrders = {
  connect: AccountUpdateSalesOrdersConnect;
} | null;

export type AccountUpdateContactsConnect = ContactUniqueWhere | ContactUniqueWhere[];

export type AccountUpdateContacts = {
  connect: AccountUpdateContactsConnect;
} | null;

export type AccountUpdateChildAccountsConnect = AccountUniqueWhere | AccountUniqueWhere[];

export type AccountUpdateChildAccounts = {
  connect: AccountUpdateChildAccountsConnect;
} | null;

export type AccountUpdateBillingContact = {
  connect: ContactUniqueWhere;
} | null;

export type AccountUpdatePrimaryContact = {
  connect: ContactUniqueWhere;
} | null;

export type AccountUpdateParentAccount = {
  connect: AccountUniqueWhere;
} | null;

export type AccountUpdateStatus = typeof AccountUpdateStatus[keyof typeof AccountUpdateStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountUpdateStatus = {
  CREATED: 'CREATED',
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type AccountUpdate = {
  id?: string | null;
  name?: string | null;
  phone?: string | null;
  status?: AccountUpdateStatus;
  currency?: string | null;
  govBusinessNumber?: string | null;
  paymentTermDays?: number | null;
  zohoCrmAccountRef?: string | null;
  zohoCrmParentAccountRef?: string | null;
  zohoInvContactRef?: string | null;
  stripeCustomerRef?: string | null;
  universalCustomerId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  parentAccount?: AccountUpdateParentAccount;
  primaryContact?: AccountUpdatePrimaryContact;
  billingContact?: AccountUpdateBillingContact;
  childAccounts?: AccountUpdateChildAccounts;
  contacts?: AccountUpdateContacts;
  salesOrders?: AccountUpdateSalesOrders;
  addresses?: AccountUpdateAddresses;
  memberships?: AccountUpdateMemberships;
};

export type AccountCreateMembershipsConnect = MembershipUniqueWhere | MembershipUniqueWhere[];

export type AccountCreateMemberships = {
  connect: AccountCreateMembershipsConnect;
} | null;

export type AccountCreate = {
  id?: string | null;
  name: string;
  phone?: string | null;
  status?: AccountCreateStatus;
  currency?: string | null;
  govBusinessNumber?: string | null;
  paymentTermDays?: number | null;
  zohoCrmAccountRef?: string | null;
  zohoCrmParentAccountRef?: string | null;
  zohoInvContactRef?: string | null;
  stripeCustomerRef?: string | null;
  universalCustomerId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  parentAccount?: AccountCreateParentAccount;
  primaryContact?: AccountCreatePrimaryContact;
  billingContact?: AccountCreateBillingContact;
  childAccounts?: AccountCreateChildAccounts;
  contacts?: AccountCreateContacts;
  salesOrders?: AccountCreateSalesOrders;
  addresses?: AccountCreateAddresses;
  memberships?: AccountCreateMemberships;
};

export type AccountCreateAddressesConnect = AddressUniqueWhere | AddressUniqueWhere[];

export type AccountCreateAddresses = {
  connect: AccountCreateAddressesConnect;
} | null;

export type AccountCreateSalesOrdersConnect = SalesOrderUniqueWhere | SalesOrderUniqueWhere[];

export type AccountCreateSalesOrders = {
  connect: AccountCreateSalesOrdersConnect;
} | null;

export type AccountCreateContactsConnect = ContactUniqueWhere | ContactUniqueWhere[];

export type AccountCreateContacts = {
  connect: AccountCreateContactsConnect;
} | null;

export type AccountCreateChildAccountsConnect = AccountUniqueWhere | AccountUniqueWhere[];

export type AccountCreateChildAccounts = {
  connect: AccountCreateChildAccountsConnect;
} | null;

export type AccountCreateBillingContact = {
  connect: ContactUniqueWhere;
} | null;

export type AccountCreatePrimaryContact = {
  connect: ContactUniqueWhere;
} | null;

export type AccountCreateStatus = typeof AccountCreateStatus[keyof typeof AccountCreateStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountCreateStatus = {
  CREATED: 'CREATED',
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type AccountUniqueWhere = {
  id?: string | null;
  govBusinessNumber?: string | null;
  primaryContactId?: string | null;
  billingContactId?: string | null;
};

export type AccountCreateParentAccount = {
  connect: AccountUniqueWhere;
} | null;

export type AccountWhereMembershipsNone = MembershipWhere;

export type AccountWhereMembershipsEvery = MembershipWhere;

export type AccountWhereMembershipsSome = MembershipWhere;

export type AccountWhereMemberships = {
  some?: AccountWhereMembershipsSome;
  every?: AccountWhereMembershipsEvery;
  none?: AccountWhereMembershipsNone;
} | null;

export type AccountWhere = {
  AND?: AccountWhereAnd;
  OR?: AccountWhereOr;
  NOT?: AccountWhereNot;
  id?: AccountWhereId;
  name?: AccountWhereName;
  phone?: AccountWherePhone;
  status?: AccountWhereStatus;
  currency?: AccountWhereCurrency;
  govBusinessNumber?: AccountWhereGovBusinessNumber;
  paymentTermDays?: AccountWherePaymentTermDays;
  zohoCrmAccountRef?: AccountWhereZohoCrmAccountRef;
  zohoCrmParentAccountRef?: AccountWhereZohoCrmParentAccountRef;
  zohoInvContactRef?: AccountWhereZohoInvContactRef;
  stripeCustomerRef?: AccountWhereStripeCustomerRef;
  universalCustomerId?: AccountWhereUniversalCustomerId;
  parentAccountId?: AccountWhereParentAccountId;
  primaryContactId?: AccountWherePrimaryContactId;
  billingContactId?: AccountWhereBillingContactId;
  createdAt?: AccountWhereCreatedAt;
  updatedAt?: AccountWhereUpdatedAt;
  deletedAt?: AccountWhereDeletedAt;
  parentAccount?: AccountWhereParentAccount;
  primaryContact?: AccountWherePrimaryContact;
  billingContact?: AccountWhereBillingContact;
  childAccounts?: AccountWhereChildAccounts;
  contacts?: AccountWhereContacts;
  salesOrders?: AccountWhereSalesOrders;
  addresses?: AccountWhereAddresses;
  memberships?: AccountWhereMemberships;
};

export type AccountWhereAddressesNone = AddressWhere;

export type AccountWhereAddressesEvery = AddressWhere;

export type AccountWhereAddressesSome = AddressWhere;

export type AccountWhereAddresses = {
  some?: AccountWhereAddressesSome;
  every?: AccountWhereAddressesEvery;
  none?: AccountWhereAddressesNone;
} | null;

export type AccountWhereSalesOrdersNone = SalesOrderWhere;

export type AccountWhereSalesOrdersEvery = SalesOrderWhere;

export type AccountWhereSalesOrdersSome = SalesOrderWhere;

export type AccountWhereSalesOrders = {
  some?: AccountWhereSalesOrdersSome;
  every?: AccountWhereSalesOrdersEvery;
  none?: AccountWhereSalesOrdersNone;
} | null;

export type AccountWhereContactsNone = ContactWhere;

export type AccountWhereContactsEvery = ContactWhere;

export type AccountWhereContactsSome = ContactWhere;

export type AccountWhereContacts = {
  some?: AccountWhereContactsSome;
  every?: AccountWhereContactsEvery;
  none?: AccountWhereContactsNone;
} | null;

export type AccountWhereChildAccountsNone = AccountWhere;

export type AccountWhereChildAccountsEvery = AccountWhere;

export type AccountWhereChildAccountsSome = AccountWhere;

export type AccountWhereChildAccounts = {
  some?: AccountWhereChildAccountsSome;
  every?: AccountWhereChildAccountsEvery;
  none?: AccountWhereChildAccountsNone;
} | null;

export type AccountWhereBillingContact = ContactWhere | AccountWhereBillingContactOneOf;

export type AccountWhereBillingContactOneOfIsNot = ContactWhere;

export type AccountWhereBillingContactOneOfIs = ContactWhere;

export type AccountWhereBillingContactOneOf = {
  is?: AccountWhereBillingContactOneOfIs;
  isNot?: AccountWhereBillingContactOneOfIsNot;
};

export type AccountWherePrimaryContactOneOfIsNot = ContactWhere;

export type AccountWherePrimaryContactOneOfIs = ContactWhere;

export type AccountWherePrimaryContactOneOf = {
  is?: AccountWherePrimaryContactOneOfIs;
  isNot?: AccountWherePrimaryContactOneOfIsNot;
};

export type AccountWherePrimaryContact = ContactWhere | AccountWherePrimaryContactOneOf;

export type AccountWhereParentAccountOneOfIsNot = AccountWhere;

export type AccountWhereParentAccountOneOfIs = AccountWhere;

export type AccountWhereParentAccountOneOf = {
  is?: AccountWhereParentAccountOneOfIs;
  isNot?: AccountWhereParentAccountOneOfIsNot;
};

export type AccountWhereParentAccount = AccountWhere | AccountWhereParentAccountOneOf;

export type AccountWhereDeletedAt = string | AccountWhereDeletedAtOneOf;

export type AccountWhereDeletedAtOneOfNot = string | DeletedAt;

export type AccountWhereDeletedAtOneOfNotIn = string | string[];

export type AccountWhereDeletedAtOneOfIn = string | string[];

export type AccountWhereDeletedAtOneOfEquals = string;

export type AccountWhereDeletedAtOneOf = {
  equals?: AccountWhereDeletedAtOneOfEquals;
  in?: AccountWhereDeletedAtOneOfIn;
  notIn?: AccountWhereDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: AccountWhereDeletedAtOneOfNot;
};

export type AccountWhereUpdatedAt = string | AccountWhereUpdatedAtOneOf;

export type AccountWhereUpdatedAtOneOfNot = string | UpdatedAt;

export type AccountWhereUpdatedAtOneOfNotIn = string | string[];

export type AccountWhereUpdatedAtOneOfIn = string | string[];

export type AccountWhereUpdatedAtOneOf = {
  equals?: string | null;
  in?: AccountWhereUpdatedAtOneOfIn;
  notIn?: AccountWhereUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: AccountWhereUpdatedAtOneOfNot;
};

export type AccountWhereCreatedAt = string | AccountWhereCreatedAtOneOf;

export type AccountWhereCreatedAtOneOfNot = string | CreatedAt;

export type AccountWhereCreatedAtOneOfNotIn = string | string[];

export type AccountWhereCreatedAtOneOfIn = string | string[];

export type AccountWhereCreatedAtOneOf = {
  equals?: string | null;
  in?: AccountWhereCreatedAtOneOfIn;
  notIn?: AccountWhereCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: AccountWhereCreatedAtOneOfNot;
};

export type AccountWhereBillingContactId = string | AccountWhereBillingContactIdOneOf;

export type AccountWhereBillingContactIdOneOfNot = string | BillingContactId;

export type AccountWhereBillingContactIdOneOfMode =
  typeof AccountWhereBillingContactIdOneOfMode[keyof typeof AccountWhereBillingContactIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountWhereBillingContactIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type AccountWhereBillingContactIdOneOfNotIn = string | string[];

export type AccountWhereBillingContactIdOneOfIn = string | string[];

export type AccountWhereBillingContactIdOneOf = {
  equals?: string | null;
  in?: AccountWhereBillingContactIdOneOfIn;
  notIn?: AccountWhereBillingContactIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: AccountWhereBillingContactIdOneOfMode;
  not?: AccountWhereBillingContactIdOneOfNot;
};

export type AccountWherePrimaryContactId = string | AccountWherePrimaryContactIdOneOf;

export type AccountWherePrimaryContactIdOneOfNot = string | PrimaryContactId;

export type AccountWherePrimaryContactIdOneOfMode =
  typeof AccountWherePrimaryContactIdOneOfMode[keyof typeof AccountWherePrimaryContactIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountWherePrimaryContactIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type AccountWherePrimaryContactIdOneOfNotIn = string | string[];

export type AccountWherePrimaryContactIdOneOfIn = string | string[];

export type AccountWherePrimaryContactIdOneOf = {
  equals?: string | null;
  in?: AccountWherePrimaryContactIdOneOfIn;
  notIn?: AccountWherePrimaryContactIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: AccountWherePrimaryContactIdOneOfMode;
  not?: AccountWherePrimaryContactIdOneOfNot;
};

export type AccountWhereParentAccountId = string | AccountWhereParentAccountIdOneOf;

export type AccountWhereParentAccountIdOneOfNot = string | ParentAccountId;

export type AccountWhereParentAccountIdOneOfMode =
  typeof AccountWhereParentAccountIdOneOfMode[keyof typeof AccountWhereParentAccountIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountWhereParentAccountIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type AccountWhereParentAccountIdOneOfNotIn = string | string[];

export type AccountWhereParentAccountIdOneOfIn = string | string[];

export type AccountWhereParentAccountIdOneOf = {
  equals?: string | null;
  in?: AccountWhereParentAccountIdOneOfIn;
  notIn?: AccountWhereParentAccountIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: AccountWhereParentAccountIdOneOfMode;
  not?: AccountWhereParentAccountIdOneOfNot;
};

export type AccountWhereUniversalCustomerId = string | AccountWhereUniversalCustomerIdOneOf;

export type AccountWhereUniversalCustomerIdOneOfNot = string | UniversalCustomerId;

export type AccountWhereUniversalCustomerIdOneOfMode =
  typeof AccountWhereUniversalCustomerIdOneOfMode[keyof typeof AccountWhereUniversalCustomerIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountWhereUniversalCustomerIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type AccountWhereUniversalCustomerIdOneOfNotIn = string | string[];

export type AccountWhereUniversalCustomerIdOneOfIn = string | string[];

export type AccountWhereUniversalCustomerIdOneOf = {
  equals?: string | null;
  in?: AccountWhereUniversalCustomerIdOneOfIn;
  notIn?: AccountWhereUniversalCustomerIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: AccountWhereUniversalCustomerIdOneOfMode;
  not?: AccountWhereUniversalCustomerIdOneOfNot;
};

export type AccountWhereStripeCustomerRefOneOfNot = string | StripeCustomerRef;

export type AccountWhereStripeCustomerRefOneOfMode =
  typeof AccountWhereStripeCustomerRefOneOfMode[keyof typeof AccountWhereStripeCustomerRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountWhereStripeCustomerRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type AccountWhereStripeCustomerRefOneOfNotIn = string | string[];

export type AccountWhereStripeCustomerRefOneOfIn = string | string[];

export type AccountWhereStripeCustomerRefOneOfEquals = string;

export type AccountWhereStripeCustomerRefOneOf = {
  equals?: AccountWhereStripeCustomerRefOneOfEquals;
  in?: AccountWhereStripeCustomerRefOneOfIn;
  notIn?: AccountWhereStripeCustomerRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: AccountWhereStripeCustomerRefOneOfMode;
  not?: AccountWhereStripeCustomerRefOneOfNot;
};

export type AccountWhereStripeCustomerRef = string | AccountWhereStripeCustomerRefOneOf;

export type AccountWhereZohoInvContactRef = string | AccountWhereZohoInvContactRefOneOf;

export type AccountWhereZohoInvContactRefOneOfNot = string | ZohoInvContactRef;

export type AccountWhereZohoInvContactRefOneOfMode =
  typeof AccountWhereZohoInvContactRefOneOfMode[keyof typeof AccountWhereZohoInvContactRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountWhereZohoInvContactRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type AccountWhereZohoInvContactRefOneOfNotIn = string | string[];

export type AccountWhereZohoInvContactRefOneOfIn = string | string[];

export type AccountWhereZohoInvContactRefOneOfEquals = string;

export type AccountWhereZohoInvContactRefOneOf = {
  equals?: AccountWhereZohoInvContactRefOneOfEquals;
  in?: AccountWhereZohoInvContactRefOneOfIn;
  notIn?: AccountWhereZohoInvContactRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: AccountWhereZohoInvContactRefOneOfMode;
  not?: AccountWhereZohoInvContactRefOneOfNot;
};

export type AccountWhereZohoCrmParentAccountRef = string | AccountWhereZohoCrmParentAccountRefOneOf;

export type AccountWhereZohoCrmParentAccountRefOneOfNot = string | ZohoCrmParentAccountRef;

export type AccountWhereZohoCrmParentAccountRefOneOfMode =
  typeof AccountWhereZohoCrmParentAccountRefOneOfMode[keyof typeof AccountWhereZohoCrmParentAccountRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountWhereZohoCrmParentAccountRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type AccountWhereZohoCrmParentAccountRefOneOfNotIn = string | string[];

export type AccountWhereZohoCrmParentAccountRefOneOfIn = string | string[];

export type AccountWhereZohoCrmParentAccountRefOneOfEquals = string;

export type AccountWhereZohoCrmParentAccountRefOneOf = {
  equals?: AccountWhereZohoCrmParentAccountRefOneOfEquals;
  in?: AccountWhereZohoCrmParentAccountRefOneOfIn;
  notIn?: AccountWhereZohoCrmParentAccountRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: AccountWhereZohoCrmParentAccountRefOneOfMode;
  not?: AccountWhereZohoCrmParentAccountRefOneOfNot;
};

export type AccountWhereZohoCrmAccountRefOneOfNot = string | ZohoCrmAccountRef;

export type AccountWhereZohoCrmAccountRefOneOfMode =
  typeof AccountWhereZohoCrmAccountRefOneOfMode[keyof typeof AccountWhereZohoCrmAccountRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountWhereZohoCrmAccountRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type AccountWhereZohoCrmAccountRefOneOfNotIn = string | string[];

export type AccountWhereZohoCrmAccountRefOneOfIn = string | string[];

export type AccountWhereZohoCrmAccountRefOneOfEquals = string;

export type AccountWhereZohoCrmAccountRefOneOf = {
  equals?: AccountWhereZohoCrmAccountRefOneOfEquals;
  in?: AccountWhereZohoCrmAccountRefOneOfIn;
  notIn?: AccountWhereZohoCrmAccountRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: AccountWhereZohoCrmAccountRefOneOfMode;
  not?: AccountWhereZohoCrmAccountRefOneOfNot;
};

export type AccountWhereZohoCrmAccountRef = string | AccountWhereZohoCrmAccountRefOneOf;

export type AccountWherePaymentTermDays = number | AccountWherePaymentTermDaysOneOf;

export type AccountWherePaymentTermDaysOneOfNot = number | PaymentTermDays;

export type AccountWherePaymentTermDaysOneOfNotIn = number | number[];

export type AccountWherePaymentTermDaysOneOfIn = number | number[];

export type AccountWherePaymentTermDaysOneOfEquals = number;

export type AccountWherePaymentTermDaysOneOf = {
  equals?: AccountWherePaymentTermDaysOneOfEquals;
  in?: AccountWherePaymentTermDaysOneOfIn;
  notIn?: AccountWherePaymentTermDaysOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: AccountWherePaymentTermDaysOneOfNot;
};

export type AccountWhereGovBusinessNumberOneOfNot = string | GovBusinessNumber;

export type AccountWhereGovBusinessNumberOneOfMode =
  typeof AccountWhereGovBusinessNumberOneOfMode[keyof typeof AccountWhereGovBusinessNumberOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountWhereGovBusinessNumberOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type AccountWhereGovBusinessNumberOneOfNotIn = string | string[];

export type AccountWhereGovBusinessNumberOneOfIn = string | string[];

export type AccountWhereGovBusinessNumberOneOfEquals = string;

export type AccountWhereGovBusinessNumberOneOf = {
  equals?: AccountWhereGovBusinessNumberOneOfEquals;
  in?: AccountWhereGovBusinessNumberOneOfIn;
  notIn?: AccountWhereGovBusinessNumberOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: AccountWhereGovBusinessNumberOneOfMode;
  not?: AccountWhereGovBusinessNumberOneOfNot;
};

export type AccountWhereGovBusinessNumber = string | AccountWhereGovBusinessNumberOneOf;

export type AccountWhereCurrency = string | AccountWhereCurrencyOneOf;

export type AccountWhereCurrencyOneOfNot = string | Currency;

export type AccountWhereCurrencyOneOfMode =
  typeof AccountWhereCurrencyOneOfMode[keyof typeof AccountWhereCurrencyOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountWhereCurrencyOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type AccountWhereCurrencyOneOfNotIn = string | string[];

export type AccountWhereCurrencyOneOfIn = string | string[];

export type AccountWhereCurrencyOneOf = {
  equals?: string | null;
  in?: AccountWhereCurrencyOneOfIn;
  notIn?: AccountWhereCurrencyOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: AccountWhereCurrencyOneOfMode;
  not?: AccountWhereCurrencyOneOfNot;
};

export type AccountWhereStatus = 'CREATED' | 'ACTIVE' | 'DISABLED' | AccountWhereStatusOneOf;

export type AccountWhereStatusOneOfNot = 'CREATED' | 'ACTIVE' | 'DISABLED' | Status;

export type AccountWhereStatusOneOfNotInOneOfItem =
  typeof AccountWhereStatusOneOfNotInOneOfItem[keyof typeof AccountWhereStatusOneOfNotInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountWhereStatusOneOfNotInOneOfItem = {
  CREATED: 'CREATED',
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type AccountWhereStatusOneOfNotIn = 'CREATED' | 'ACTIVE' | 'DISABLED' | AccountWhereStatusOneOfNotInOneOfItem[];

export type AccountWhereStatusOneOfInOneOfItem =
  typeof AccountWhereStatusOneOfInOneOfItem[keyof typeof AccountWhereStatusOneOfInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountWhereStatusOneOfInOneOfItem = {
  CREATED: 'CREATED',
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type AccountWhereStatusOneOfIn = 'CREATED' | 'ACTIVE' | 'DISABLED' | AccountWhereStatusOneOfInOneOfItem[];

export type AccountWhereStatusOneOfEquals =
  typeof AccountWhereStatusOneOfEquals[keyof typeof AccountWhereStatusOneOfEquals];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountWhereStatusOneOfEquals = {
  CREATED: 'CREATED',
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type AccountWhereStatusOneOf = {
  equals?: AccountWhereStatusOneOfEquals;
  in?: AccountWhereStatusOneOfIn;
  notIn?: AccountWhereStatusOneOfNotIn;
  not?: AccountWhereStatusOneOfNot;
} | null;

export type AccountWherePhoneOneOfNot = string | Phone;

export type AccountWherePhoneOneOfMode = typeof AccountWherePhoneOneOfMode[keyof typeof AccountWherePhoneOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountWherePhoneOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type AccountWherePhoneOneOfNotIn = string | string[];

export type AccountWherePhoneOneOfIn = string | string[];

export type AccountWherePhoneOneOfEquals = string;

export type AccountWherePhoneOneOf = {
  equals?: AccountWherePhoneOneOfEquals;
  in?: AccountWherePhoneOneOfIn;
  notIn?: AccountWherePhoneOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: AccountWherePhoneOneOfMode;
  not?: AccountWherePhoneOneOfNot;
};

export type AccountWherePhone = string | AccountWherePhoneOneOf;

export type AccountWhereName = string | AccountWhereNameOneOf;

export type AccountWhereNameOneOfNot = string | Name;

export type AccountWhereNameOneOfMode = typeof AccountWhereNameOneOfMode[keyof typeof AccountWhereNameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountWhereNameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type AccountWhereNameOneOfNotIn = string | string[];

export type AccountWhereNameOneOfIn = string | string[];

export type AccountWhereNameOneOf = {
  equals?: string | null;
  in?: AccountWhereNameOneOfIn;
  notIn?: AccountWhereNameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: AccountWhereNameOneOfMode;
  not?: AccountWhereNameOneOfNot;
};

export type AccountWhereId = string | AccountWhereIdOneOf;

export type AccountWhereIdOneOfNot = string | Id;

export type AccountWhereIdOneOfMode = typeof AccountWhereIdOneOfMode[keyof typeof AccountWhereIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountWhereIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type AccountWhereIdOneOfNotIn = string | string[];

export type AccountWhereIdOneOfIn = string | string[];

export type AccountWhereIdOneOf = {
  equals?: string | null;
  in?: AccountWhereIdOneOfIn;
  notIn?: AccountWhereIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: AccountWhereIdOneOfMode;
  not?: AccountWhereIdOneOfNot;
};

export type AccountWhereNot = AccountWhere | AccountWhere[];

export type AccountWhereOr = AccountWhere | AccountWhere[];

export type AccountWhereAnd = AccountWhere | AccountWhere[];

export type Accounts = {
  items?: Account[];
};

export type AccountMemberships = Memberships;

export type AccountAddresses = Addresses;

export type AccountSalesOrders = SalesOrders;

export type AccountContacts = Contacts;

export type AccountChildAccounts = Accounts;

export type AccountBillingContact = Contact;

export type AccountPrimaryContact = Contact;

export type AccountParentAccount = Account;

export type AccountStatus = typeof AccountStatus[keyof typeof AccountStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountStatus = {
  CREATED: 'CREATED',
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type Account = {
  id?: string | null;
  name?: string | null;
  phone?: string | null;
  status?: AccountStatus;
  currency?: string | null;
  govBusinessNumber?: string | null;
  paymentTermDays?: number | null;
  zohoCrmAccountRef?: string | null;
  zohoCrmParentAccountRef?: string | null;
  zohoInvContactRef?: string | null;
  stripeCustomerRef?: string | null;
  universalCustomerId?: string | null;
  parentAccountId?: string | null;
  primaryContactId?: string | null;
  billingContactId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  parentAccount?: AccountParentAccount;
  primaryContact?: AccountPrimaryContact;
  billingContact?: AccountBillingContact;
  childAccounts?: AccountChildAccounts;
  contacts?: AccountContacts;
  salesOrders?: AccountSalesOrders;
  addresses?: AccountAddresses;
  memberships?: AccountMemberships;
  _count?: unknown;
};

export type AccountInclude = {
  parentAccount?: boolean | null;
  primaryContact?: boolean | null;
  billingContact?: boolean | null;
  childAccounts?: boolean | null;
  contacts?: boolean | null;
  salesOrders?: boolean | null;
  addresses?: boolean | null;
  memberships?: boolean | null;
};

export type AccountSelect = {
  id?: boolean | null;
  name?: boolean | null;
  phone?: boolean | null;
  status?: boolean | null;
  currency?: boolean | null;
  govBusinessNumber?: boolean | null;
  paymentTermDays?: boolean | null;
  zohoCrmAccountRef?: boolean | null;
  zohoCrmParentAccountRef?: boolean | null;
  zohoInvContactRef?: boolean | null;
  stripeCustomerRef?: boolean | null;
  universalCustomerId?: boolean | null;
  parentAccountId?: boolean | null;
  primaryContactId?: boolean | null;
  billingContactId?: boolean | null;
  createdAt?: boolean | null;
  updatedAt?: boolean | null;
  deletedAt?: boolean | null;
  parentAccount?: boolean | null;
  primaryContact?: boolean | null;
  billingContact?: boolean | null;
  childAccounts?: boolean | null;
  contacts?: boolean | null;
  salesOrders?: boolean | null;
  addresses?: boolean | null;
  memberships?: boolean | null;
  _count?: boolean | null;
};

export type FileUpdateProduct = {
  connect: ProductUniqueWhere;
} | null;

export type FileUpdateContentType = typeof FileUpdateContentType[keyof typeof FileUpdateContentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileUpdateContentType = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
} as const;

export type FileUpdate = {
  id?: string | null;
  oringinalFilename?: string | null;
  extension?: string | null;
  contentType?: FileUpdateContentType;
  height?: number | null;
  width?: number | null;
  md5?: string | null;
  length?: number | null;
  metadata?: unknown;
  url?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  product?: FileUpdateProduct;
};

export type FileCreateProduct = {
  connect: ProductUniqueWhere;
} | null;

export type FileCreateContentType = typeof FileCreateContentType[keyof typeof FileCreateContentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileCreateContentType = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
} as const;

export type FileCreate = {
  id?: string | null;
  oringinalFilename: string;
  extension: string;
  contentType: FileCreateContentType;
  height?: number | null;
  width?: number | null;
  md5?: string | null;
  length?: number | null;
  metadata?: unknown;
  url: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  product?: FileCreateProduct;
};

export type FileUniqueWhere = {
  id?: string | null;
};

export type FileWhereProductId = string | FileWhereProductIdOneOf;

export type FileWhere = {
  AND?: FileWhereAnd;
  OR?: FileWhereOr;
  NOT?: FileWhereNot;
  id?: FileWhereId;
  oringinalFilename?: FileWhereOringinalFilename;
  extension?: FileWhereExtension;
  contentType?: FileWhereContentType;
  height?: FileWhereHeight;
  width?: FileWhereWidth;
  md5?: FileWhereMd5;
  length?: FileWhereLength;
  metadata?: FileWhereMetadata;
  url?: FileWhereUrl;
  createdAt?: FileWhereCreatedAt;
  updatedAt?: FileWhereUpdatedAt;
  deletedAt?: FileWhereDeletedAt;
  product?: FileWhereProduct;
  productId?: FileWhereProductId;
};

export type FileWhereProductIdOneOfNot = string | ProductId;

export type FileWhereProductIdOneOfMode = typeof FileWhereProductIdOneOfMode[keyof typeof FileWhereProductIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileWhereProductIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type FileWhereProductIdOneOfNotIn = string | string[];

export type FileWhereProductIdOneOfIn = string | string[];

export type FileWhereProductIdOneOf = {
  equals?: string | null;
  in?: FileWhereProductIdOneOfIn;
  notIn?: FileWhereProductIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: FileWhereProductIdOneOfMode;
  not?: FileWhereProductIdOneOfNot;
};

export type FileWhereProductOneOfIsNot = ProductWhere;

export type FileWhereProductOneOfIs = ProductWhere;

export type FileWhereProductOneOf = {
  is?: FileWhereProductOneOfIs;
  isNot?: FileWhereProductOneOfIsNot;
};

export type FileWhereProduct = ProductWhere | FileWhereProductOneOf;

export type FileWhereDeletedAtOneOfNot = string | DeletedAt;

export type FileWhereDeletedAtOneOfNotIn = string | string[];

export type FileWhereDeletedAtOneOfIn = string | string[];

export type FileWhereDeletedAtOneOfEquals = string;

export type FileWhereDeletedAtOneOf = {
  equals?: FileWhereDeletedAtOneOfEquals;
  in?: FileWhereDeletedAtOneOfIn;
  notIn?: FileWhereDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: FileWhereDeletedAtOneOfNot;
};

export type FileWhereDeletedAt = string | FileWhereDeletedAtOneOf;

export type FileWhereUpdatedAt = string | FileWhereUpdatedAtOneOf;

export type FileWhereUpdatedAtOneOfNot = string | UpdatedAt;

export type FileWhereUpdatedAtOneOfNotIn = string | string[];

export type FileWhereUpdatedAtOneOfIn = string | string[];

export type FileWhereUpdatedAtOneOf = {
  equals?: string | null;
  in?: FileWhereUpdatedAtOneOfIn;
  notIn?: FileWhereUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: FileWhereUpdatedAtOneOfNot;
};

export type FileWhereCreatedAt = string | FileWhereCreatedAtOneOf;

export type FileWhereCreatedAtOneOfNot = string | CreatedAt;

export type FileWhereCreatedAtOneOfNotIn = string | string[];

export type FileWhereCreatedAtOneOfIn = string | string[];

export type FileWhereCreatedAtOneOf = {
  equals?: string | null;
  in?: FileWhereCreatedAtOneOfIn;
  notIn?: FileWhereCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: FileWhereCreatedAtOneOfNot;
};

export type FileWhereUrl = string | FileWhereUrlOneOf;

export type FileWhereUrlOneOfNot = string | Url;

export type FileWhereUrlOneOfMode = typeof FileWhereUrlOneOfMode[keyof typeof FileWhereUrlOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileWhereUrlOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type FileWhereUrlOneOfNotIn = string | string[];

export type FileWhereUrlOneOfIn = string | string[];

export type FileWhereUrlOneOf = {
  equals?: string | null;
  in?: FileWhereUrlOneOfIn;
  notIn?: FileWhereUrlOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: FileWhereUrlOneOfMode;
  not?: FileWhereUrlOneOfNot;
};

export type FileWhereMetadataOneOfPath = string | string[];

export type FileWhereMetadataOneOf = {
  path?: FileWhereMetadataOneOfPath;
  equals?: unknown;
  not?: unknown;
  string_contains?: string | null;
  string_starts_with?: string | null;
  string_ends_with?: string | null;
  array_contains?: unknown;
  array_starts_with?: unknown;
  array_ends_with?: unknown;
};

export type FileWhereMetadata = FileWhereMetadataOneOf;

export type FileWhereLength = number | FileWhereLengthOneOf;

export type FileWhereLengthOneOfNot = number | Length;

export type FileWhereLengthOneOfNotIn = number | number[];

export type FileWhereLengthOneOfIn = number | number[];

export type FileWhereLengthOneOfEquals = number;

export type FileWhereLengthOneOf = {
  equals?: FileWhereLengthOneOfEquals;
  in?: FileWhereLengthOneOfIn;
  notIn?: FileWhereLengthOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: FileWhereLengthOneOfNot;
};

export type FileWhereMd5 = string | FileWhereMd5OneOf;

export type FileWhereMd5OneOfNot = string | Md5;

export type FileWhereMd5OneOfMode = typeof FileWhereMd5OneOfMode[keyof typeof FileWhereMd5OneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileWhereMd5OneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type FileWhereMd5OneOfNotIn = string | string[];

export type FileWhereMd5OneOfIn = string | string[];

export type FileWhereMd5OneOfEquals = string;

export type FileWhereMd5OneOf = {
  equals?: FileWhereMd5OneOfEquals;
  in?: FileWhereMd5OneOfIn;
  notIn?: FileWhereMd5OneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: FileWhereMd5OneOfMode;
  not?: FileWhereMd5OneOfNot;
};

export type FileWhereWidth = number | FileWhereWidthOneOf;

export type FileWhereWidthOneOfNot = number | Width;

export type FileWhereWidthOneOfNotIn = number | number[];

export type FileWhereWidthOneOfIn = number | number[];

export type FileWhereWidthOneOfEquals = number;

export type FileWhereWidthOneOf = {
  equals?: FileWhereWidthOneOfEquals;
  in?: FileWhereWidthOneOfIn;
  notIn?: FileWhereWidthOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: FileWhereWidthOneOfNot;
};

export type FileWhereHeight = number | FileWhereHeightOneOf;

export type FileWhereHeightOneOfNot = number | Height;

export type FileWhereHeightOneOfNotIn = number | number[];

export type FileWhereHeightOneOfIn = number | number[];

export type FileWhereHeightOneOfEquals = number;

export type FileWhereHeightOneOf = {
  equals?: FileWhereHeightOneOfEquals;
  in?: FileWhereHeightOneOfIn;
  notIn?: FileWhereHeightOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: FileWhereHeightOneOfNot;
};

export type FileWhereContentType = 'IMAGE' | 'VIDEO' | FileWhereContentTypeOneOf;

export type FileWhereContentTypeOneOfNot = 'IMAGE' | 'VIDEO' | ContentType;

export type FileWhereContentTypeOneOfNotInOneOfItem =
  typeof FileWhereContentTypeOneOfNotInOneOfItem[keyof typeof FileWhereContentTypeOneOfNotInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileWhereContentTypeOneOfNotInOneOfItem = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
} as const;

export type FileWhereContentTypeOneOfNotIn = 'IMAGE' | 'VIDEO' | FileWhereContentTypeOneOfNotInOneOfItem[];

export type FileWhereContentTypeOneOfInOneOfItem =
  typeof FileWhereContentTypeOneOfInOneOfItem[keyof typeof FileWhereContentTypeOneOfInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileWhereContentTypeOneOfInOneOfItem = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
} as const;

export type FileWhereContentTypeOneOfIn = 'IMAGE' | 'VIDEO' | FileWhereContentTypeOneOfInOneOfItem[];

export type FileWhereContentTypeOneOfEquals =
  typeof FileWhereContentTypeOneOfEquals[keyof typeof FileWhereContentTypeOneOfEquals];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileWhereContentTypeOneOfEquals = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
} as const;

export type FileWhereContentTypeOneOf = {
  equals?: FileWhereContentTypeOneOfEquals;
  in?: FileWhereContentTypeOneOfIn;
  notIn?: FileWhereContentTypeOneOfNotIn;
  not?: FileWhereContentTypeOneOfNot;
} | null;

export type FileWhereExtension = string | FileWhereExtensionOneOf;

export type FileWhereExtensionOneOfNot = string | Extension;

export type FileWhereExtensionOneOfMode = typeof FileWhereExtensionOneOfMode[keyof typeof FileWhereExtensionOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileWhereExtensionOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type FileWhereExtensionOneOfNotIn = string | string[];

export type FileWhereExtensionOneOfIn = string | string[];

export type FileWhereExtensionOneOf = {
  equals?: string | null;
  in?: FileWhereExtensionOneOfIn;
  notIn?: FileWhereExtensionOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: FileWhereExtensionOneOfMode;
  not?: FileWhereExtensionOneOfNot;
};

export type FileWhereOringinalFilename = string | FileWhereOringinalFilenameOneOf;

export type FileWhereOringinalFilenameOneOfNot = string | OringinalFilename;

export type FileWhereOringinalFilenameOneOfMode =
  typeof FileWhereOringinalFilenameOneOfMode[keyof typeof FileWhereOringinalFilenameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileWhereOringinalFilenameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type FileWhereOringinalFilenameOneOfNotIn = string | string[];

export type FileWhereOringinalFilenameOneOfIn = string | string[];

export type FileWhereOringinalFilenameOneOf = {
  equals?: string | null;
  in?: FileWhereOringinalFilenameOneOfIn;
  notIn?: FileWhereOringinalFilenameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: FileWhereOringinalFilenameOneOfMode;
  not?: FileWhereOringinalFilenameOneOfNot;
};

export type FileWhereId = string | FileWhereIdOneOf;

export type FileWhereIdOneOfNot = string | Id;

export type FileWhereIdOneOfMode = typeof FileWhereIdOneOfMode[keyof typeof FileWhereIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileWhereIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type FileWhereIdOneOfNotIn = string | string[];

export type FileWhereIdOneOfIn = string | string[];

export type FileWhereIdOneOf = {
  equals?: string | null;
  in?: FileWhereIdOneOfIn;
  notIn?: FileWhereIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: FileWhereIdOneOfMode;
  not?: FileWhereIdOneOfNot;
};

export type FileWhereNot = FileWhere | FileWhere[];

export type FileWhereOr = FileWhere | FileWhere[];

export type FileWhereAnd = FileWhere | FileWhere[];

export type FileProduct = Product;

export type FileContentType = typeof FileContentType[keyof typeof FileContentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileContentType = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
} as const;

export type File = {
  id?: string | null;
  oringinalFilename?: string | null;
  extension?: string | null;
  contentType?: FileContentType;
  height?: number | null;
  width?: number | null;
  md5?: string | null;
  length?: number | null;
  metadata?: unknown;
  url?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  productId?: string | null;
  product?: FileProduct;
};

export type Files = {
  items?: File[];
};

export type FileInclude = {
  product?: boolean | null;
};

export type FileSelect = {
  id?: boolean | null;
  oringinalFilename?: boolean | null;
  extension?: boolean | null;
  contentType?: boolean | null;
  height?: boolean | null;
  width?: boolean | null;
  md5?: boolean | null;
  length?: boolean | null;
  metadata?: boolean | null;
  url?: boolean | null;
  createdAt?: boolean | null;
  updatedAt?: boolean | null;
  deletedAt?: boolean | null;
  product?: boolean | null;
  productId?: boolean | null;
};

export type PostShipmentRunRequestBodySchemaIncludeOneOf = null;

export type PostShipmentRunRequestBodySchemaInclude = PostShipmentRunRequestBodySchemaIncludeOneOf | ShipmentRunInclude;

export type PostShipmentRunRequestBodySchema = {
  select?: PostShipmentRunRequestBodySchemaSelect;
  include?: PostShipmentRunRequestBodySchemaInclude;
  data: ShipmentRunCreate;
  ignoreWarnings?: boolean | null;
};

export type PostShipmentRunRequestBodySchemaSelectOneOf = null;

export type PostShipmentRunRequestBodySchemaSelect = PostShipmentRunRequestBodySchemaSelectOneOf | ShipmentRunSelect;

export type ShipmentRunUpdateSalesOrdersConnect = SalesOrderUniqueWhere | SalesOrderUniqueWhere[];

export type ShipmentRunUpdateSalesOrders = {
  connect: ShipmentRunUpdateSalesOrdersConnect;
} | null;

export type ShipmentRunUpdate = {
  id?: string | null;
  name?: string | null;
  initialOrdersSubmitted?: unknown;
  createdBySub?: string | null;
  stepFunctionExecRef?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  packagesPrintedAt?: string | null;
  reportsGeneratedAt?: string | null;
  completedAt?: string | null;
  salesOrders?: ShipmentRunUpdateSalesOrders;
};

export type ShipmentRunCreateSalesOrdersConnect = SalesOrderUniqueWhere | SalesOrderUniqueWhere[];

export type ShipmentRunCreateSalesOrders = {
  connect: ShipmentRunCreateSalesOrdersConnect;
} | null;

export type ShipmentRunCreate = {
  id?: string | null;
  name: string;
  initialOrdersSubmitted: unknown;
  createdBySub: string;
  stepFunctionExecRef?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  packagesPrintedAt?: string | null;
  reportsGeneratedAt?: string | null;
  completedAt?: string | null;
  salesOrders?: ShipmentRunCreateSalesOrders;
};

export type ShipmentRunUniqueWhere = {
  id?: string | null;
};

export type ShipmentRunWhereSalesOrdersNone = SalesOrderWhere;

export type ShipmentRunWhereSalesOrdersEvery = SalesOrderWhere;

export type ShipmentRunWhereSalesOrdersSome = SalesOrderWhere;

export type ShipmentRunWhereSalesOrders = {
  some?: ShipmentRunWhereSalesOrdersSome;
  every?: ShipmentRunWhereSalesOrdersEvery;
  none?: ShipmentRunWhereSalesOrdersNone;
} | null;

export type ShipmentRunWhereCompletedAtOneOfNot = string | CompletedAt;

export type ShipmentRunWhereCompletedAtOneOfNotIn = string | string[];

export type ShipmentRunWhereCompletedAtOneOfIn = string | string[];

export type ShipmentRunWhereCompletedAtOneOfEquals = string;

export type ShipmentRunWhereCompletedAtOneOf = {
  equals?: ShipmentRunWhereCompletedAtOneOfEquals;
  in?: ShipmentRunWhereCompletedAtOneOfIn;
  notIn?: ShipmentRunWhereCompletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: ShipmentRunWhereCompletedAtOneOfNot;
};

export type ShipmentRunWhereCompletedAt = string | ShipmentRunWhereCompletedAtOneOf;

export type ShipmentRunWhereReportsGeneratedAt = string | ShipmentRunWhereReportsGeneratedAtOneOf;

export type ShipmentRunWhereReportsGeneratedAtOneOfNot = string | ReportsGeneratedAt;

export type ShipmentRunWhereReportsGeneratedAtOneOfNotIn = string | string[];

export type ShipmentRunWhereReportsGeneratedAtOneOfIn = string | string[];

export type ShipmentRunWhereReportsGeneratedAtOneOfEquals = string;

export type ShipmentRunWhereReportsGeneratedAtOneOf = {
  equals?: ShipmentRunWhereReportsGeneratedAtOneOfEquals;
  in?: ShipmentRunWhereReportsGeneratedAtOneOfIn;
  notIn?: ShipmentRunWhereReportsGeneratedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: ShipmentRunWhereReportsGeneratedAtOneOfNot;
};

export type ShipmentRunWherePackagesPrintedAt = string | ShipmentRunWherePackagesPrintedAtOneOf;

export type ShipmentRunWhere = {
  AND?: ShipmentRunWhereAnd;
  OR?: ShipmentRunWhereOr;
  NOT?: ShipmentRunWhereNot;
  id?: ShipmentRunWhereId;
  name?: ShipmentRunWhereName;
  initialOrdersSubmitted?: ShipmentRunWhereInitialOrdersSubmitted;
  createdBySub?: ShipmentRunWhereCreatedBySub;
  stepFunctionExecRef?: ShipmentRunWhereStepFunctionExecRef;
  createdAt?: ShipmentRunWhereCreatedAt;
  updatedAt?: ShipmentRunWhereUpdatedAt;
  deletedAt?: ShipmentRunWhereDeletedAt;
  packagesPrintedAt?: ShipmentRunWherePackagesPrintedAt;
  reportsGeneratedAt?: ShipmentRunWhereReportsGeneratedAt;
  completedAt?: ShipmentRunWhereCompletedAt;
  salesOrders?: ShipmentRunWhereSalesOrders;
};

export type ShipmentRunWherePackagesPrintedAtOneOfNot = string | PackagesPrintedAt;

export type ShipmentRunWherePackagesPrintedAtOneOfNotIn = string | string[];

export type ShipmentRunWherePackagesPrintedAtOneOfIn = string | string[];

export type ShipmentRunWherePackagesPrintedAtOneOfEquals = string;

export type ShipmentRunWherePackagesPrintedAtOneOf = {
  equals?: ShipmentRunWherePackagesPrintedAtOneOfEquals;
  in?: ShipmentRunWherePackagesPrintedAtOneOfIn;
  notIn?: ShipmentRunWherePackagesPrintedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: ShipmentRunWherePackagesPrintedAtOneOfNot;
};

export type ShipmentRunWhereDeletedAtOneOfNot = string | DeletedAt;

export type ShipmentRunWhereDeletedAtOneOfNotIn = string | string[];

export type ShipmentRunWhereDeletedAtOneOfIn = string | string[];

export type ShipmentRunWhereDeletedAtOneOfEquals = string;

export type ShipmentRunWhereDeletedAtOneOf = {
  equals?: ShipmentRunWhereDeletedAtOneOfEquals;
  in?: ShipmentRunWhereDeletedAtOneOfIn;
  notIn?: ShipmentRunWhereDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: ShipmentRunWhereDeletedAtOneOfNot;
};

export type ShipmentRunWhereDeletedAt = string | ShipmentRunWhereDeletedAtOneOf;

export type ShipmentRunWhereUpdatedAtOneOfNot = string | UpdatedAt;

export type ShipmentRunWhereUpdatedAtOneOfNotIn = string | string[];

export type ShipmentRunWhereUpdatedAtOneOfIn = string | string[];

export type ShipmentRunWhereUpdatedAtOneOf = {
  equals?: string | null;
  in?: ShipmentRunWhereUpdatedAtOneOfIn;
  notIn?: ShipmentRunWhereUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: ShipmentRunWhereUpdatedAtOneOfNot;
};

export type ShipmentRunWhereUpdatedAt = string | ShipmentRunWhereUpdatedAtOneOf;

export type ShipmentRunWhereCreatedAt = string | ShipmentRunWhereCreatedAtOneOf;

export type ShipmentRunWhereCreatedAtOneOfNot = string | CreatedAt;

export type ShipmentRunWhereCreatedAtOneOfNotIn = string | string[];

export type ShipmentRunWhereCreatedAtOneOfIn = string | string[];

export type ShipmentRunWhereCreatedAtOneOf = {
  equals?: string | null;
  in?: ShipmentRunWhereCreatedAtOneOfIn;
  notIn?: ShipmentRunWhereCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: ShipmentRunWhereCreatedAtOneOfNot;
};

export type ShipmentRunWhereStepFunctionExecRef = string | ShipmentRunWhereStepFunctionExecRefOneOf;

export type ShipmentRunWhereStepFunctionExecRefOneOfNot = string | StepFunctionExecRef;

export type ShipmentRunWhereStepFunctionExecRefOneOfMode =
  typeof ShipmentRunWhereStepFunctionExecRefOneOfMode[keyof typeof ShipmentRunWhereStepFunctionExecRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShipmentRunWhereStepFunctionExecRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ShipmentRunWhereStepFunctionExecRefOneOfNotIn = string | string[];

export type ShipmentRunWhereStepFunctionExecRefOneOfIn = string | string[];

export type ShipmentRunWhereStepFunctionExecRefOneOfEquals = string;

export type ShipmentRunWhereStepFunctionExecRefOneOf = {
  equals?: ShipmentRunWhereStepFunctionExecRefOneOfEquals;
  in?: ShipmentRunWhereStepFunctionExecRefOneOfIn;
  notIn?: ShipmentRunWhereStepFunctionExecRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ShipmentRunWhereStepFunctionExecRefOneOfMode;
  not?: ShipmentRunWhereStepFunctionExecRefOneOfNot;
};

export type ShipmentRunWhereCreatedBySubOneOfNot = string | CreatedBySub;

export type ShipmentRunWhereCreatedBySubOneOfMode =
  typeof ShipmentRunWhereCreatedBySubOneOfMode[keyof typeof ShipmentRunWhereCreatedBySubOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShipmentRunWhereCreatedBySubOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ShipmentRunWhereCreatedBySubOneOfNotIn = string | string[];

export type ShipmentRunWhereCreatedBySubOneOfIn = string | string[];

export type ShipmentRunWhereCreatedBySubOneOf = {
  equals?: string | null;
  in?: ShipmentRunWhereCreatedBySubOneOfIn;
  notIn?: ShipmentRunWhereCreatedBySubOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ShipmentRunWhereCreatedBySubOneOfMode;
  not?: ShipmentRunWhereCreatedBySubOneOfNot;
};

export type ShipmentRunWhereCreatedBySub = string | ShipmentRunWhereCreatedBySubOneOf;

export type ShipmentRunWhereInitialOrdersSubmittedOneOfPath = string | string[];

export type ShipmentRunWhereInitialOrdersSubmittedOneOf = {
  path?: ShipmentRunWhereInitialOrdersSubmittedOneOfPath;
  equals?: unknown;
  not?: unknown;
  string_contains?: string | null;
  string_starts_with?: string | null;
  string_ends_with?: string | null;
  array_contains?: unknown;
  array_starts_with?: unknown;
  array_ends_with?: unknown;
};

export type ShipmentRunWhereInitialOrdersSubmitted = ShipmentRunWhereInitialOrdersSubmittedOneOf;

export type ShipmentRunWhereName = string | ShipmentRunWhereNameOneOf;

export type ShipmentRunWhereNameOneOfNot = string | Name;

export type ShipmentRunWhereNameOneOfMode =
  typeof ShipmentRunWhereNameOneOfMode[keyof typeof ShipmentRunWhereNameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShipmentRunWhereNameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ShipmentRunWhereNameOneOfNotIn = string | string[];

export type ShipmentRunWhereNameOneOfIn = string | string[];

export type ShipmentRunWhereNameOneOf = {
  equals?: string | null;
  in?: ShipmentRunWhereNameOneOfIn;
  notIn?: ShipmentRunWhereNameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ShipmentRunWhereNameOneOfMode;
  not?: ShipmentRunWhereNameOneOfNot;
};

export type ShipmentRunWhereId = string | ShipmentRunWhereIdOneOf;

export type ShipmentRunWhereIdOneOfNot = string | Id;

export type ShipmentRunWhereIdOneOfMode = typeof ShipmentRunWhereIdOneOfMode[keyof typeof ShipmentRunWhereIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShipmentRunWhereIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ShipmentRunWhereIdOneOfNotIn = string | string[];

export type ShipmentRunWhereIdOneOfIn = string | string[];

export type ShipmentRunWhereIdOneOf = {
  equals?: string | null;
  in?: ShipmentRunWhereIdOneOfIn;
  notIn?: ShipmentRunWhereIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ShipmentRunWhereIdOneOfMode;
  not?: ShipmentRunWhereIdOneOfNot;
};

export type ShipmentRunWhereNot = ShipmentRunWhere | ShipmentRunWhere[];

export type ShipmentRunWhereOr = ShipmentRunWhere | ShipmentRunWhere[];

export type ShipmentRunWhereAnd = ShipmentRunWhere | ShipmentRunWhere[];

export type ShipmentRunSalesOrders = SalesOrders;

export type ShipmentRun = {
  id?: string | null;
  name?: string | null;
  initialOrdersSubmitted?: unknown;
  createdBySub?: string | null;
  stepFunctionExecRef?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  packagesPrintedAt?: string | null;
  reportsGeneratedAt?: string | null;
  completedAt?: string | null;
  salesOrders?: ShipmentRunSalesOrders;
  _count?: unknown;
};

export type ShipmentRuns = {
  items?: ShipmentRun[];
};

export type ShipmentRunInclude = {
  salesOrders?: boolean | null;
};

export type ShipmentRunSelect = {
  id?: boolean | null;
  name?: boolean | null;
  initialOrdersSubmitted?: boolean | null;
  createdBySub?: boolean | null;
  stepFunctionExecRef?: boolean | null;
  createdAt?: boolean | null;
  updatedAt?: boolean | null;
  deletedAt?: boolean | null;
  packagesPrintedAt?: boolean | null;
  reportsGeneratedAt?: boolean | null;
  completedAt?: boolean | null;
  salesOrders?: boolean | null;
  _count?: boolean | null;
};

export type SalesOrderLineItemUpdateItem = {
  connect: ItemUniqueWhere;
} | null;

export type SalesOrderLineItemUpdateSalesOrder = {
  connect: SalesOrderUniqueWhere;
} | null;

export type SalesOrderLineItemUpdateType =
  typeof SalesOrderLineItemUpdateType[keyof typeof SalesOrderLineItemUpdateType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesOrderLineItemUpdateType = {
  PRODUCT: 'PRODUCT',
  VARIANT: 'VARIANT',
  ITEM: 'ITEM',
  SHIPPING: 'SHIPPING',
  TAX: 'TAX',
  DISCOUNT: 'DISCOUNT',
} as const;

export type SalesOrderLineItemUpdate = {
  id?: string | null;
  name?: string | null;
  type?: SalesOrderLineItemUpdateType;
  amountEx?: number | null;
  amountGst?: number | null;
  quantity?: number | null;
  itemData?: unknown;
  createdAt?: string | null;
  updatedAt?: string | null;
  salesOrder?: SalesOrderLineItemUpdateSalesOrder;
  item?: SalesOrderLineItemUpdateItem;
};

export type SalesOrderLineItemCreateItem = {
  connect: ItemUniqueWhere;
};

export type SalesOrderLineItemCreateSalesOrder = {
  connect: SalesOrderUniqueWhere;
};

export type SalesOrderLineItemCreateType =
  typeof SalesOrderLineItemCreateType[keyof typeof SalesOrderLineItemCreateType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesOrderLineItemCreateType = {
  PRODUCT: 'PRODUCT',
  VARIANT: 'VARIANT',
  ITEM: 'ITEM',
  SHIPPING: 'SHIPPING',
  TAX: 'TAX',
  DISCOUNT: 'DISCOUNT',
} as const;

export type SalesOrderLineItemCreate = {
  id?: string | null;
  name: string;
  type: SalesOrderLineItemCreateType;
  amountEx: number;
  amountGst: number;
  quantity: number;
  itemData: unknown;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  salesOrder: SalesOrderLineItemCreateSalesOrder;
  item?: SalesOrderLineItemCreateItem;
};

export type SalesOrderLineItemUniqueWhere = {
  id?: string | null;
};

export type SalesOrderLineItemWhere = {
  AND?: SalesOrderLineItemWhereAnd;
  OR?: SalesOrderLineItemWhereOr;
  NOT?: SalesOrderLineItemWhereNot;
  id?: SalesOrderLineItemWhereId;
  name?: SalesOrderLineItemWhereName;
  type?: SalesOrderLineItemWhereType;
  amountEx?: SalesOrderLineItemWhereAmountEx;
  amountGst?: SalesOrderLineItemWhereAmountGst;
  salesOrderId?: SalesOrderLineItemWhereSalesOrderId;
  itemId?: SalesOrderLineItemWhereItemId;
  quantity?: SalesOrderLineItemWhereQuantity;
  itemData?: SalesOrderLineItemWhereItemData;
  createdAt?: SalesOrderLineItemWhereCreatedAt;
  updatedAt?: SalesOrderLineItemWhereUpdatedAt;
  deletedAt?: SalesOrderLineItemWhereDeletedAt;
  salesOrder?: SalesOrderLineItemWhereSalesOrder;
  item?: SalesOrderLineItemWhereItem;
};

export type SalesOrderLineItemWhereItemOneOfIsNot = ItemWhere;

export type SalesOrderLineItemWhereItemOneOfIs = ItemWhere;

export type SalesOrderLineItemWhereItemOneOf = {
  is?: SalesOrderLineItemWhereItemOneOfIs;
  isNot?: SalesOrderLineItemWhereItemOneOfIsNot;
};

export type SalesOrderLineItemWhereItem = ItemWhere | SalesOrderLineItemWhereItemOneOf;

export type SalesOrderLineItemWhereSalesOrder = SalesOrderWhere | SalesOrderLineItemWhereSalesOrderOneOf;

export type SalesOrderLineItemWhereSalesOrderOneOfIsNot = SalesOrderWhere;

export type SalesOrderLineItemWhereSalesOrderOneOfIs = SalesOrderWhere;

export type SalesOrderLineItemWhereSalesOrderOneOf = {
  is?: SalesOrderLineItemWhereSalesOrderOneOfIs;
  isNot?: SalesOrderLineItemWhereSalesOrderOneOfIsNot;
};

export type SalesOrderLineItemWhereDeletedAt = string | SalesOrderLineItemWhereDeletedAtOneOf;

export type SalesOrderLineItemWhereDeletedAtOneOfNot = string | DeletedAt;

export type SalesOrderLineItemWhereDeletedAtOneOfNotIn = string | string[];

export type SalesOrderLineItemWhereDeletedAtOneOfIn = string | string[];

export type SalesOrderLineItemWhereDeletedAtOneOfEquals = string;

export type SalesOrderLineItemWhereDeletedAtOneOf = {
  equals?: SalesOrderLineItemWhereDeletedAtOneOfEquals;
  in?: SalesOrderLineItemWhereDeletedAtOneOfIn;
  notIn?: SalesOrderLineItemWhereDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: SalesOrderLineItemWhereDeletedAtOneOfNot;
};

export type SalesOrderLineItemWhereUpdatedAtOneOfNot = string | UpdatedAt;

export type SalesOrderLineItemWhereUpdatedAtOneOfNotIn = string | string[];

export type SalesOrderLineItemWhereUpdatedAtOneOfIn = string | string[];

export type SalesOrderLineItemWhereUpdatedAtOneOf = {
  equals?: string | null;
  in?: SalesOrderLineItemWhereUpdatedAtOneOfIn;
  notIn?: SalesOrderLineItemWhereUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: SalesOrderLineItemWhereUpdatedAtOneOfNot;
};

export type SalesOrderLineItemWhereUpdatedAt = string | SalesOrderLineItemWhereUpdatedAtOneOf;

export type SalesOrderLineItemWhereCreatedAt = string | SalesOrderLineItemWhereCreatedAtOneOf;

export type SalesOrderLineItemWhereCreatedAtOneOfNot = string | CreatedAt;

export type SalesOrderLineItemWhereCreatedAtOneOfNotIn = string | string[];

export type SalesOrderLineItemWhereCreatedAtOneOfIn = string | string[];

export type SalesOrderLineItemWhereCreatedAtOneOf = {
  equals?: string | null;
  in?: SalesOrderLineItemWhereCreatedAtOneOfIn;
  notIn?: SalesOrderLineItemWhereCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: SalesOrderLineItemWhereCreatedAtOneOfNot;
};

export type SalesOrderLineItemWhereItemDataOneOfPath = string | string[];

export type SalesOrderLineItemWhereItemDataOneOf = {
  path?: SalesOrderLineItemWhereItemDataOneOfPath;
  equals?: unknown;
  not?: unknown;
  string_contains?: string | null;
  string_starts_with?: string | null;
  string_ends_with?: string | null;
  array_contains?: unknown;
  array_starts_with?: unknown;
  array_ends_with?: unknown;
};

export type SalesOrderLineItemWhereItemData = SalesOrderLineItemWhereItemDataOneOf;

export type SalesOrderLineItemWhereQuantity = number | SalesOrderLineItemWhereQuantityOneOf;

export type SalesOrderLineItemWhereQuantityOneOfNotOneOf = null;

export type SalesOrderLineItemWhereQuantityOneOfNot = SalesOrderLineItemWhereQuantityOneOfNotOneOf | number | Quantity;

export type SalesOrderLineItemWhereQuantityOneOfNotIn = number | number[];

export type SalesOrderLineItemWhereQuantityOneOfIn = number | number[];

export type SalesOrderLineItemWhereQuantityOneOf = {
  equals?: number | null;
  in?: SalesOrderLineItemWhereQuantityOneOfIn;
  notIn?: SalesOrderLineItemWhereQuantityOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: SalesOrderLineItemWhereQuantityOneOfNot;
};

export type SalesOrderLineItemWhereItemId = string | SalesOrderLineItemWhereItemIdOneOf;

export type SalesOrderLineItemWhereItemIdOneOfNot = string | ItemId;

export type SalesOrderLineItemWhereItemIdOneOfMode =
  typeof SalesOrderLineItemWhereItemIdOneOfMode[keyof typeof SalesOrderLineItemWhereItemIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesOrderLineItemWhereItemIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type SalesOrderLineItemWhereItemIdOneOfNotIn = string | string[];

export type SalesOrderLineItemWhereItemIdOneOfIn = string | string[];

export type SalesOrderLineItemWhereItemIdOneOf = {
  equals?: string | null;
  in?: SalesOrderLineItemWhereItemIdOneOfIn;
  notIn?: SalesOrderLineItemWhereItemIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: SalesOrderLineItemWhereItemIdOneOfMode;
  not?: SalesOrderLineItemWhereItemIdOneOfNot;
};

export type SalesOrderLineItemWhereSalesOrderIdOneOfNot = string | SalesOrderId;

export type SalesOrderLineItemWhereSalesOrderIdOneOfMode =
  typeof SalesOrderLineItemWhereSalesOrderIdOneOfMode[keyof typeof SalesOrderLineItemWhereSalesOrderIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesOrderLineItemWhereSalesOrderIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type SalesOrderLineItemWhereSalesOrderIdOneOfNotIn = string | string[];

export type SalesOrderLineItemWhereSalesOrderIdOneOfIn = string | string[];

export type SalesOrderLineItemWhereSalesOrderIdOneOf = {
  equals?: string | null;
  in?: SalesOrderLineItemWhereSalesOrderIdOneOfIn;
  notIn?: SalesOrderLineItemWhereSalesOrderIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: SalesOrderLineItemWhereSalesOrderIdOneOfMode;
  not?: SalesOrderLineItemWhereSalesOrderIdOneOfNot;
};

export type SalesOrderLineItemWhereSalesOrderId = string | SalesOrderLineItemWhereSalesOrderIdOneOf;

export type SalesOrderLineItemWhereAmountGst = number | SalesOrderLineItemWhereAmountGstOneOf;

export type SalesOrderLineItemWhereAmountGstOneOfNotOneOf = null;

export type SalesOrderLineItemWhereAmountGstOneOfNot =
  | SalesOrderLineItemWhereAmountGstOneOfNotOneOf
  | number
  | AmountGst;

export type SalesOrderLineItemWhereAmountGstOneOfNotIn = number | number[];

export type SalesOrderLineItemWhereAmountGstOneOfIn = number | number[];

export type SalesOrderLineItemWhereAmountGstOneOf = {
  equals?: number | null;
  in?: SalesOrderLineItemWhereAmountGstOneOfIn;
  notIn?: SalesOrderLineItemWhereAmountGstOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: SalesOrderLineItemWhereAmountGstOneOfNot;
};

export type SalesOrderLineItemWhereAmountEx = number | SalesOrderLineItemWhereAmountExOneOf;

export type SalesOrderLineItemWhereAmountExOneOfNotOneOf = null;

export type SalesOrderLineItemWhereAmountExOneOfNot = SalesOrderLineItemWhereAmountExOneOfNotOneOf | number | AmountEx;

export type SalesOrderLineItemWhereAmountExOneOfNotIn = number | number[];

export type SalesOrderLineItemWhereAmountExOneOfIn = number | number[];

export type SalesOrderLineItemWhereAmountExOneOf = {
  equals?: number | null;
  in?: SalesOrderLineItemWhereAmountExOneOfIn;
  notIn?: SalesOrderLineItemWhereAmountExOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: SalesOrderLineItemWhereAmountExOneOfNot;
};

export type SalesOrderLineItemWhereTypeOneOfNot =
  | 'PRODUCT'
  | 'VARIANT'
  | 'ITEM'
  | 'SHIPPING'
  | 'TAX'
  | 'DISCOUNT'
  | Type;

export type SalesOrderLineItemWhereTypeOneOf = {
  equals?: SalesOrderLineItemWhereTypeOneOfEquals;
  in?: SalesOrderLineItemWhereTypeOneOfIn;
  notIn?: SalesOrderLineItemWhereTypeOneOfNotIn;
  not?: SalesOrderLineItemWhereTypeOneOfNot;
} | null;

export type SalesOrderLineItemWhereType =
  | 'PRODUCT'
  | 'VARIANT'
  | 'ITEM'
  | 'SHIPPING'
  | 'TAX'
  | 'DISCOUNT'
  | SalesOrderLineItemWhereTypeOneOf;

export type SalesOrderLineItemWhereTypeOneOfNotInOneOfItem =
  typeof SalesOrderLineItemWhereTypeOneOfNotInOneOfItem[keyof typeof SalesOrderLineItemWhereTypeOneOfNotInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesOrderLineItemWhereTypeOneOfNotInOneOfItem = {
  PRODUCT: 'PRODUCT',
  VARIANT: 'VARIANT',
  ITEM: 'ITEM',
  SHIPPING: 'SHIPPING',
  TAX: 'TAX',
  DISCOUNT: 'DISCOUNT',
} as const;

export type SalesOrderLineItemWhereTypeOneOfNotIn =
  | 'PRODUCT'
  | 'VARIANT'
  | 'ITEM'
  | 'SHIPPING'
  | 'TAX'
  | 'DISCOUNT'
  | SalesOrderLineItemWhereTypeOneOfNotInOneOfItem[];

export type SalesOrderLineItemWhereTypeOneOfInOneOfItem =
  typeof SalesOrderLineItemWhereTypeOneOfInOneOfItem[keyof typeof SalesOrderLineItemWhereTypeOneOfInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesOrderLineItemWhereTypeOneOfInOneOfItem = {
  PRODUCT: 'PRODUCT',
  VARIANT: 'VARIANT',
  ITEM: 'ITEM',
  SHIPPING: 'SHIPPING',
  TAX: 'TAX',
  DISCOUNT: 'DISCOUNT',
} as const;

export type SalesOrderLineItemWhereTypeOneOfIn =
  | 'PRODUCT'
  | 'VARIANT'
  | 'ITEM'
  | 'SHIPPING'
  | 'TAX'
  | 'DISCOUNT'
  | SalesOrderLineItemWhereTypeOneOfInOneOfItem[];

export type SalesOrderLineItemWhereTypeOneOfEquals =
  typeof SalesOrderLineItemWhereTypeOneOfEquals[keyof typeof SalesOrderLineItemWhereTypeOneOfEquals];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesOrderLineItemWhereTypeOneOfEquals = {
  PRODUCT: 'PRODUCT',
  VARIANT: 'VARIANT',
  ITEM: 'ITEM',
  SHIPPING: 'SHIPPING',
  TAX: 'TAX',
  DISCOUNT: 'DISCOUNT',
} as const;

export type SalesOrderLineItemWhereName = string | SalesOrderLineItemWhereNameOneOf;

export type SalesOrderLineItemWhereNameOneOfNot = string | Name;

export type SalesOrderLineItemWhereNameOneOfMode =
  typeof SalesOrderLineItemWhereNameOneOfMode[keyof typeof SalesOrderLineItemWhereNameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesOrderLineItemWhereNameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type SalesOrderLineItemWhereNameOneOfNotIn = string | string[];

export type SalesOrderLineItemWhereNameOneOfIn = string | string[];

export type SalesOrderLineItemWhereNameOneOf = {
  equals?: string | null;
  in?: SalesOrderLineItemWhereNameOneOfIn;
  notIn?: SalesOrderLineItemWhereNameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: SalesOrderLineItemWhereNameOneOfMode;
  not?: SalesOrderLineItemWhereNameOneOfNot;
};

export type SalesOrderLineItemWhereIdOneOfNot = string | Id;

export type SalesOrderLineItemWhereIdOneOfMode =
  typeof SalesOrderLineItemWhereIdOneOfMode[keyof typeof SalesOrderLineItemWhereIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesOrderLineItemWhereIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type SalesOrderLineItemWhereIdOneOfNotIn = string | string[];

export type SalesOrderLineItemWhereIdOneOfIn = string | string[];

export type SalesOrderLineItemWhereIdOneOf = {
  equals?: string | null;
  in?: SalesOrderLineItemWhereIdOneOfIn;
  notIn?: SalesOrderLineItemWhereIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: SalesOrderLineItemWhereIdOneOfMode;
  not?: SalesOrderLineItemWhereIdOneOfNot;
};

export type SalesOrderLineItemWhereId = string | SalesOrderLineItemWhereIdOneOf;

export type SalesOrderLineItemWhereNot = SalesOrderLineItemWhere | SalesOrderLineItemWhere[];

export type SalesOrderLineItemWhereOr = SalesOrderLineItemWhere | SalesOrderLineItemWhere[];

export type SalesOrderLineItemWhereAnd = SalesOrderLineItemWhere | SalesOrderLineItemWhere[];

export type SalesOrderLineItemItem = Item;

export type SalesOrderLineItemSalesOrder = SalesOrder;

export type SalesOrderLineItemType = typeof SalesOrderLineItemType[keyof typeof SalesOrderLineItemType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesOrderLineItemType = {
  PRODUCT: 'PRODUCT',
  VARIANT: 'VARIANT',
  ITEM: 'ITEM',
  SHIPPING: 'SHIPPING',
  TAX: 'TAX',
  DISCOUNT: 'DISCOUNT',
} as const;

export type SalesOrderLineItem = {
  id?: string | null;
  name?: string | null;
  type?: SalesOrderLineItemType;
  amountEx?: number | null;
  amountGst?: number | null;
  salesOrderId?: string | null;
  itemId?: string | null;
  quantity?: number | null;
  itemData?: unknown;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  salesOrder?: SalesOrderLineItemSalesOrder;
  item?: SalesOrderLineItemItem;
};

export type SalesOrderLineItems = {
  items?: SalesOrderLineItem[];
};

export type SalesOrderLineItemInclude = {
  salesOrder?: boolean | null;
  item?: boolean | null;
};

export type SalesOrderLineItemSelect = {
  id?: boolean | null;
  name?: boolean | null;
  type?: boolean | null;
  amountEx?: boolean | null;
  amountGst?: boolean | null;
  salesOrderId?: boolean | null;
  itemId?: boolean | null;
  quantity?: boolean | null;
  itemData?: boolean | null;
  createdAt?: boolean | null;
  updatedAt?: boolean | null;
  deletedAt?: boolean | null;
  salesOrder?: boolean | null;
  item?: boolean | null;
};

export type SalesOrderUpdateMembershipOrder = {
  connect: MembershipOrderUniqueWhere;
} | null;

export type SalesOrderUpdateLineItemsConnect = SalesOrderLineItemUniqueWhere | SalesOrderLineItemUniqueWhere[];

export type SalesOrderUpdateLineItems = {
  connect: SalesOrderUpdateLineItemsConnect;
} | null;

export type SalesOrderUpdateShipmentRun = {
  connect: ShipmentRunUniqueWhere;
} | null;

export type SalesOrderUpdateAccount = {
  connect: AccountUniqueWhere;
} | null;

export type SalesOrderUpdate = {
  id?: string | null;
  name?: string | null;
  orderDate?: string | null;
  zohoSalesOrderRef?: string | null;
  zohoPackageRef?: string | null;
  zohoInvoiceRef?: string | null;
  zohoPaymentRef?: string | null;
  xeroInvoiceRef?: string | null;
  xeroInvoiceNumber?: string | null;
  xeroPaymentRef?: string | null;
  stripePaymentIntentRef?: string | null;
  packagingData?: unknown;
  invoiceUrl?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  account?: SalesOrderUpdateAccount;
  shipmentRun?: SalesOrderUpdateShipmentRun;
  lineItems?: SalesOrderUpdateLineItems;
  membershipOrder?: SalesOrderUpdateMembershipOrder;
};

export type SalesOrderCreateMembershipOrder = {
  connect: MembershipOrderUniqueWhere;
} | null;

export type SalesOrderCreateLineItemsConnect = SalesOrderLineItemUniqueWhere | SalesOrderLineItemUniqueWhere[];

export type SalesOrderCreateLineItems = {
  connect: SalesOrderCreateLineItemsConnect;
} | null;

export type SalesOrderCreateShipmentRun = {
  connect: ShipmentRunUniqueWhere;
} | null;

export type SalesOrderCreateAccount = {
  connect: AccountUniqueWhere;
};

export type SalesOrderCreate = {
  id?: string | null;
  name: string;
  orderDate: string;
  zohoSalesOrderRef?: string | null;
  zohoPackageRef?: string | null;
  zohoInvoiceRef?: string | null;
  zohoPaymentRef?: string | null;
  xeroInvoiceRef?: string | null;
  xeroInvoiceNumber?: string | null;
  xeroPaymentRef?: string | null;
  stripePaymentIntentRef?: string | null;
  packagingData?: unknown;
  invoiceUrl?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  account: SalesOrderCreateAccount;
  shipmentRun?: SalesOrderCreateShipmentRun;
  lineItems?: SalesOrderCreateLineItems;
  membershipOrder?: SalesOrderCreateMembershipOrder;
};

export type SalesOrderUniqueWhere = {
  id?: string | null;
};

export type SalesOrderWhereMembershipOrderOneOfIsNot = MembershipOrderWhere;

export type SalesOrderWhereMembershipOrderOneOfIs = MembershipOrderWhere;

export type SalesOrderWhereMembershipOrderOneOf = {
  is?: SalesOrderWhereMembershipOrderOneOfIs;
  isNot?: SalesOrderWhereMembershipOrderOneOfIsNot;
};

export type SalesOrderWhereMembershipOrder = MembershipOrderWhere | SalesOrderWhereMembershipOrderOneOf;

export type SalesOrderWhereLineItemsNone = SalesOrderLineItemWhere;

export type SalesOrderWhereLineItems = {
  some?: SalesOrderWhereLineItemsSome;
  every?: SalesOrderWhereLineItemsEvery;
  none?: SalesOrderWhereLineItemsNone;
} | null;

export type SalesOrderWhere = {
  AND?: SalesOrderWhereAnd;
  OR?: SalesOrderWhereOr;
  NOT?: SalesOrderWhereNot;
  id?: SalesOrderWhereId;
  name?: SalesOrderWhereName;
  orderDate?: SalesOrderWhereOrderDate;
  zohoSalesOrderRef?: SalesOrderWhereZohoSalesOrderRef;
  zohoPackageRef?: SalesOrderWhereZohoPackageRef;
  zohoInvoiceRef?: SalesOrderWhereZohoInvoiceRef;
  zohoPaymentRef?: SalesOrderWhereZohoPaymentRef;
  xeroInvoiceRef?: SalesOrderWhereXeroInvoiceRef;
  xeroInvoiceNumber?: SalesOrderWhereXeroInvoiceNumber;
  xeroPaymentRef?: SalesOrderWhereXeroPaymentRef;
  stripePaymentIntentRef?: SalesOrderWhereStripePaymentIntentRef;
  shipmentRunId?: SalesOrderWhereShipmentRunId;
  packagingData?: SalesOrderWherePackagingData;
  invoiceUrl?: SalesOrderWhereInvoiceUrl;
  accountId?: SalesOrderWhereAccountId;
  createdAt?: SalesOrderWhereCreatedAt;
  updatedAt?: SalesOrderWhereUpdatedAt;
  deletedAt?: SalesOrderWhereDeletedAt;
  account?: SalesOrderWhereAccount;
  shipmentRun?: SalesOrderWhereShipmentRun;
  lineItems?: SalesOrderWhereLineItems;
  membershipOrder?: SalesOrderWhereMembershipOrder;
};

export type SalesOrderWhereLineItemsEvery = SalesOrderLineItemWhere;

export type SalesOrderWhereLineItemsSome = SalesOrderLineItemWhere;

export type SalesOrderWhereShipmentRun = ShipmentRunWhere | SalesOrderWhereShipmentRunOneOf;

export type SalesOrderWhereShipmentRunOneOfIsNot = ShipmentRunWhere;

export type SalesOrderWhereShipmentRunOneOfIs = ShipmentRunWhere;

export type SalesOrderWhereShipmentRunOneOf = {
  is?: SalesOrderWhereShipmentRunOneOfIs;
  isNot?: SalesOrderWhereShipmentRunOneOfIsNot;
};

export type SalesOrderWhereAccount = AccountWhere | SalesOrderWhereAccountOneOf;

export type SalesOrderWhereAccountOneOfIsNot = AccountWhere;

export type SalesOrderWhereAccountOneOfIs = AccountWhere;

export type SalesOrderWhereAccountOneOf = {
  is?: SalesOrderWhereAccountOneOfIs;
  isNot?: SalesOrderWhereAccountOneOfIsNot;
};

export type SalesOrderWhereDeletedAt = string | SalesOrderWhereDeletedAtOneOf;

export type SalesOrderWhereDeletedAtOneOfNot = string | DeletedAt;

export type SalesOrderWhereDeletedAtOneOfNotIn = string | string[];

export type SalesOrderWhereDeletedAtOneOfIn = string | string[];

export type SalesOrderWhereDeletedAtOneOfEquals = string;

export type SalesOrderWhereDeletedAtOneOf = {
  equals?: SalesOrderWhereDeletedAtOneOfEquals;
  in?: SalesOrderWhereDeletedAtOneOfIn;
  notIn?: SalesOrderWhereDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: SalesOrderWhereDeletedAtOneOfNot;
};

export type SalesOrderWhereUpdatedAt = string | SalesOrderWhereUpdatedAtOneOf;

export type SalesOrderWhereUpdatedAtOneOfNot = string | UpdatedAt;

export type SalesOrderWhereUpdatedAtOneOfNotIn = string | string[];

export type SalesOrderWhereUpdatedAtOneOfIn = string | string[];

export type SalesOrderWhereUpdatedAtOneOf = {
  equals?: string | null;
  in?: SalesOrderWhereUpdatedAtOneOfIn;
  notIn?: SalesOrderWhereUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: SalesOrderWhereUpdatedAtOneOfNot;
};

export type SalesOrderWhereCreatedAtOneOfNot = string | CreatedAt;

export type SalesOrderWhereCreatedAtOneOfNotIn = string | string[];

export type SalesOrderWhereCreatedAtOneOfIn = string | string[];

export type SalesOrderWhereCreatedAtOneOf = {
  equals?: string | null;
  in?: SalesOrderWhereCreatedAtOneOfIn;
  notIn?: SalesOrderWhereCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: SalesOrderWhereCreatedAtOneOfNot;
};

export type SalesOrderWhereCreatedAt = string | SalesOrderWhereCreatedAtOneOf;

export type SalesOrderWhereAccountId = string | SalesOrderWhereAccountIdOneOf;

export type SalesOrderWhereAccountIdOneOfNot = string | AccountId;

export type SalesOrderWhereAccountIdOneOfMode =
  typeof SalesOrderWhereAccountIdOneOfMode[keyof typeof SalesOrderWhereAccountIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesOrderWhereAccountIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type SalesOrderWhereAccountIdOneOfNotIn = string | string[];

export type SalesOrderWhereAccountIdOneOfIn = string | string[];

export type SalesOrderWhereAccountIdOneOf = {
  equals?: string | null;
  in?: SalesOrderWhereAccountIdOneOfIn;
  notIn?: SalesOrderWhereAccountIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: SalesOrderWhereAccountIdOneOfMode;
  not?: SalesOrderWhereAccountIdOneOfNot;
};

export type SalesOrderWhereInvoiceUrlOneOfNot = string | InvoiceUrl;

export type SalesOrderWhereInvoiceUrlOneOfMode =
  typeof SalesOrderWhereInvoiceUrlOneOfMode[keyof typeof SalesOrderWhereInvoiceUrlOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesOrderWhereInvoiceUrlOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type SalesOrderWhereInvoiceUrlOneOfNotIn = string | string[];

export type SalesOrderWhereInvoiceUrlOneOfIn = string | string[];

export type SalesOrderWhereInvoiceUrlOneOfEquals = string;

export type SalesOrderWhereInvoiceUrlOneOf = {
  equals?: SalesOrderWhereInvoiceUrlOneOfEquals;
  in?: SalesOrderWhereInvoiceUrlOneOfIn;
  notIn?: SalesOrderWhereInvoiceUrlOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: SalesOrderWhereInvoiceUrlOneOfMode;
  not?: SalesOrderWhereInvoiceUrlOneOfNot;
};

export type SalesOrderWhereInvoiceUrl = string | SalesOrderWhereInvoiceUrlOneOf;

export type SalesOrderWherePackagingDataOneOfPath = string | string[];

export type SalesOrderWherePackagingDataOneOf = {
  path?: SalesOrderWherePackagingDataOneOfPath;
  equals?: unknown;
  not?: unknown;
  string_contains?: string | null;
  string_starts_with?: string | null;
  string_ends_with?: string | null;
  array_contains?: unknown;
  array_starts_with?: unknown;
  array_ends_with?: unknown;
};

export type SalesOrderWherePackagingData = SalesOrderWherePackagingDataOneOf;

export type SalesOrderWhereShipmentRunId = string | SalesOrderWhereShipmentRunIdOneOf;

export type SalesOrderWhereShipmentRunIdOneOfNot = string | ShipmentRunId;

export type SalesOrderWhereShipmentRunIdOneOfMode =
  typeof SalesOrderWhereShipmentRunIdOneOfMode[keyof typeof SalesOrderWhereShipmentRunIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesOrderWhereShipmentRunIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type SalesOrderWhereShipmentRunIdOneOfNotIn = string | string[];

export type SalesOrderWhereShipmentRunIdOneOfIn = string | string[];

export type SalesOrderWhereShipmentRunIdOneOf = {
  equals?: string | null;
  in?: SalesOrderWhereShipmentRunIdOneOfIn;
  notIn?: SalesOrderWhereShipmentRunIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: SalesOrderWhereShipmentRunIdOneOfMode;
  not?: SalesOrderWhereShipmentRunIdOneOfNot;
};

export type SalesOrderWhereStripePaymentIntentRef = string | SalesOrderWhereStripePaymentIntentRefOneOf;

export type SalesOrderWhereStripePaymentIntentRefOneOfNot = string | StripePaymentIntentRef;

export type SalesOrderWhereStripePaymentIntentRefOneOfMode =
  typeof SalesOrderWhereStripePaymentIntentRefOneOfMode[keyof typeof SalesOrderWhereStripePaymentIntentRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesOrderWhereStripePaymentIntentRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type SalesOrderWhereStripePaymentIntentRefOneOfNotIn = string | string[];

export type SalesOrderWhereStripePaymentIntentRefOneOfIn = string | string[];

export type SalesOrderWhereStripePaymentIntentRefOneOfEquals = string;

export type SalesOrderWhereStripePaymentIntentRefOneOf = {
  equals?: SalesOrderWhereStripePaymentIntentRefOneOfEquals;
  in?: SalesOrderWhereStripePaymentIntentRefOneOfIn;
  notIn?: SalesOrderWhereStripePaymentIntentRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: SalesOrderWhereStripePaymentIntentRefOneOfMode;
  not?: SalesOrderWhereStripePaymentIntentRefOneOfNot;
};

export type SalesOrderWhereXeroPaymentRef = string | SalesOrderWhereXeroPaymentRefOneOf;

export type SalesOrderWhereXeroPaymentRefOneOfNot = string | XeroPaymentRef;

export type SalesOrderWhereXeroPaymentRefOneOfMode =
  typeof SalesOrderWhereXeroPaymentRefOneOfMode[keyof typeof SalesOrderWhereXeroPaymentRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesOrderWhereXeroPaymentRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type SalesOrderWhereXeroPaymentRefOneOfNotIn = string | string[];

export type SalesOrderWhereXeroPaymentRefOneOfIn = string | string[];

export type SalesOrderWhereXeroPaymentRefOneOfEquals = string;

export type SalesOrderWhereXeroPaymentRefOneOf = {
  equals?: SalesOrderWhereXeroPaymentRefOneOfEquals;
  in?: SalesOrderWhereXeroPaymentRefOneOfIn;
  notIn?: SalesOrderWhereXeroPaymentRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: SalesOrderWhereXeroPaymentRefOneOfMode;
  not?: SalesOrderWhereXeroPaymentRefOneOfNot;
};

export type SalesOrderWhereXeroInvoiceNumber = string | SalesOrderWhereXeroInvoiceNumberOneOf;

export type SalesOrderWhereXeroInvoiceNumberOneOfNot = string | XeroInvoiceNumber;

export type SalesOrderWhereXeroInvoiceNumberOneOfMode =
  typeof SalesOrderWhereXeroInvoiceNumberOneOfMode[keyof typeof SalesOrderWhereXeroInvoiceNumberOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesOrderWhereXeroInvoiceNumberOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type SalesOrderWhereXeroInvoiceNumberOneOfNotIn = string | string[];

export type SalesOrderWhereXeroInvoiceNumberOneOfIn = string | string[];

export type SalesOrderWhereXeroInvoiceNumberOneOfEquals = string;

export type SalesOrderWhereXeroInvoiceNumberOneOf = {
  equals?: SalesOrderWhereXeroInvoiceNumberOneOfEquals;
  in?: SalesOrderWhereXeroInvoiceNumberOneOfIn;
  notIn?: SalesOrderWhereXeroInvoiceNumberOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: SalesOrderWhereXeroInvoiceNumberOneOfMode;
  not?: SalesOrderWhereXeroInvoiceNumberOneOfNot;
};

export type SalesOrderWhereXeroInvoiceRef = string | SalesOrderWhereXeroInvoiceRefOneOf;

export type SalesOrderWhereXeroInvoiceRefOneOfNot = string | XeroInvoiceRef;

export type SalesOrderWhereXeroInvoiceRefOneOfMode =
  typeof SalesOrderWhereXeroInvoiceRefOneOfMode[keyof typeof SalesOrderWhereXeroInvoiceRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesOrderWhereXeroInvoiceRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type SalesOrderWhereXeroInvoiceRefOneOfNotIn = string | string[];

export type SalesOrderWhereXeroInvoiceRefOneOfIn = string | string[];

export type SalesOrderWhereXeroInvoiceRefOneOfEquals = string;

export type SalesOrderWhereXeroInvoiceRefOneOf = {
  equals?: SalesOrderWhereXeroInvoiceRefOneOfEquals;
  in?: SalesOrderWhereXeroInvoiceRefOneOfIn;
  notIn?: SalesOrderWhereXeroInvoiceRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: SalesOrderWhereXeroInvoiceRefOneOfMode;
  not?: SalesOrderWhereXeroInvoiceRefOneOfNot;
};

export type SalesOrderWhereZohoPaymentRef = string | SalesOrderWhereZohoPaymentRefOneOf;

export type SalesOrderWhereZohoPaymentRefOneOfNot = string | ZohoPaymentRef;

export type SalesOrderWhereZohoPaymentRefOneOfMode =
  typeof SalesOrderWhereZohoPaymentRefOneOfMode[keyof typeof SalesOrderWhereZohoPaymentRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesOrderWhereZohoPaymentRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type SalesOrderWhereZohoPaymentRefOneOfNotIn = string | string[];

export type SalesOrderWhereZohoPaymentRefOneOfIn = string | string[];

export type SalesOrderWhereZohoPaymentRefOneOfEquals = string;

export type SalesOrderWhereZohoPaymentRefOneOf = {
  equals?: SalesOrderWhereZohoPaymentRefOneOfEquals;
  in?: SalesOrderWhereZohoPaymentRefOneOfIn;
  notIn?: SalesOrderWhereZohoPaymentRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: SalesOrderWhereZohoPaymentRefOneOfMode;
  not?: SalesOrderWhereZohoPaymentRefOneOfNot;
};

export type SalesOrderWhereZohoInvoiceRef = string | SalesOrderWhereZohoInvoiceRefOneOf;

export type SalesOrderWhereZohoInvoiceRefOneOfNot = string | ZohoInvoiceRef;

export type SalesOrderWhereZohoInvoiceRefOneOfMode =
  typeof SalesOrderWhereZohoInvoiceRefOneOfMode[keyof typeof SalesOrderWhereZohoInvoiceRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesOrderWhereZohoInvoiceRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type SalesOrderWhereZohoInvoiceRefOneOfNotIn = string | string[];

export type SalesOrderWhereZohoInvoiceRefOneOfIn = string | string[];

export type SalesOrderWhereZohoInvoiceRefOneOfEquals = string;

export type SalesOrderWhereZohoInvoiceRefOneOf = {
  equals?: SalesOrderWhereZohoInvoiceRefOneOfEquals;
  in?: SalesOrderWhereZohoInvoiceRefOneOfIn;
  notIn?: SalesOrderWhereZohoInvoiceRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: SalesOrderWhereZohoInvoiceRefOneOfMode;
  not?: SalesOrderWhereZohoInvoiceRefOneOfNot;
};

export type SalesOrderWhereZohoPackageRefOneOfNot = string | ZohoPackageRef;

export type SalesOrderWhereZohoPackageRefOneOfMode =
  typeof SalesOrderWhereZohoPackageRefOneOfMode[keyof typeof SalesOrderWhereZohoPackageRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesOrderWhereZohoPackageRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type SalesOrderWhereZohoPackageRefOneOfNotIn = string | string[];

export type SalesOrderWhereZohoPackageRefOneOfIn = string | string[];

export type SalesOrderWhereZohoPackageRefOneOfEquals = string;

export type SalesOrderWhereZohoPackageRefOneOf = {
  equals?: SalesOrderWhereZohoPackageRefOneOfEquals;
  in?: SalesOrderWhereZohoPackageRefOneOfIn;
  notIn?: SalesOrderWhereZohoPackageRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: SalesOrderWhereZohoPackageRefOneOfMode;
  not?: SalesOrderWhereZohoPackageRefOneOfNot;
};

export type SalesOrderWhereZohoPackageRef = string | SalesOrderWhereZohoPackageRefOneOf;

export type SalesOrderWhereZohoSalesOrderRef = string | SalesOrderWhereZohoSalesOrderRefOneOf;

export type SalesOrderWhereZohoSalesOrderRefOneOfNot = string | ZohoSalesOrderRef;

export type SalesOrderWhereZohoSalesOrderRefOneOfMode =
  typeof SalesOrderWhereZohoSalesOrderRefOneOfMode[keyof typeof SalesOrderWhereZohoSalesOrderRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesOrderWhereZohoSalesOrderRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type SalesOrderWhereZohoSalesOrderRefOneOfNotIn = string | string[];

export type SalesOrderWhereZohoSalesOrderRefOneOfIn = string | string[];

export type SalesOrderWhereZohoSalesOrderRefOneOfEquals = string;

export type SalesOrderWhereZohoSalesOrderRefOneOf = {
  equals?: SalesOrderWhereZohoSalesOrderRefOneOfEquals;
  in?: SalesOrderWhereZohoSalesOrderRefOneOfIn;
  notIn?: SalesOrderWhereZohoSalesOrderRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: SalesOrderWhereZohoSalesOrderRefOneOfMode;
  not?: SalesOrderWhereZohoSalesOrderRefOneOfNot;
};

export type SalesOrderWhereOrderDateOneOfNot = string | OrderDate;

export type SalesOrderWhereOrderDateOneOfNotIn = string | string[];

export type SalesOrderWhereOrderDateOneOfIn = string | string[];

export type SalesOrderWhereOrderDateOneOf = {
  equals?: string | null;
  in?: SalesOrderWhereOrderDateOneOfIn;
  notIn?: SalesOrderWhereOrderDateOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: SalesOrderWhereOrderDateOneOfNot;
};

export type SalesOrderWhereOrderDate = string | SalesOrderWhereOrderDateOneOf;

export type SalesOrderWhereName = string | SalesOrderWhereNameOneOf;

export type SalesOrderWhereNameOneOfNot = string | Name;

export type SalesOrderWhereNameOneOfMode =
  typeof SalesOrderWhereNameOneOfMode[keyof typeof SalesOrderWhereNameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesOrderWhereNameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type SalesOrderWhereNameOneOfNotIn = string | string[];

export type SalesOrderWhereNameOneOfIn = string | string[];

export type SalesOrderWhereNameOneOf = {
  equals?: string | null;
  in?: SalesOrderWhereNameOneOfIn;
  notIn?: SalesOrderWhereNameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: SalesOrderWhereNameOneOfMode;
  not?: SalesOrderWhereNameOneOfNot;
};

export type SalesOrderWhereId = string | SalesOrderWhereIdOneOf;

export type SalesOrderWhereIdOneOfNot = string | Id;

export type SalesOrderWhereIdOneOfMode = typeof SalesOrderWhereIdOneOfMode[keyof typeof SalesOrderWhereIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalesOrderWhereIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type SalesOrderWhereIdOneOfNotIn = string | string[];

export type SalesOrderWhereIdOneOfIn = string | string[];

export type SalesOrderWhereIdOneOf = {
  equals?: string | null;
  in?: SalesOrderWhereIdOneOfIn;
  notIn?: SalesOrderWhereIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: SalesOrderWhereIdOneOfMode;
  not?: SalesOrderWhereIdOneOfNot;
};

export type SalesOrderWhereNot = SalesOrderWhere | SalesOrderWhere[];

export type SalesOrderWhereOr = SalesOrderWhere | SalesOrderWhere[];

export type SalesOrderWhereAnd = SalesOrderWhere | SalesOrderWhere[];

export type SalesOrders = {
  items?: SalesOrder[];
};

export type SalesOrderMembershipOrder = MembershipOrder;

export type SalesOrderLineItemsProperty = SalesOrderLineItems;

export type SalesOrderShipmentRun = ShipmentRun;

export type SalesOrderAccount = Account;

export type SalesOrder = {
  id?: string | null;
  name?: string | null;
  orderDate?: string | null;
  zohoSalesOrderRef?: string | null;
  zohoPackageRef?: string | null;
  zohoInvoiceRef?: string | null;
  zohoPaymentRef?: string | null;
  xeroInvoiceRef?: string | null;
  xeroInvoiceNumber?: string | null;
  xeroPaymentRef?: string | null;
  stripePaymentIntentRef?: string | null;
  shipmentRunId?: string | null;
  packagingData?: unknown;
  invoiceUrl?: string | null;
  accountId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  account?: SalesOrderAccount;
  shipmentRun?: SalesOrderShipmentRun;
  lineItems?: SalesOrderLineItemsProperty;
  membershipOrder?: SalesOrderMembershipOrder;
  _count?: unknown;
};

export type SalesOrderInclude = {
  account?: boolean | null;
  shipmentRun?: boolean | null;
  lineItems?: boolean | null;
  membershipOrder?: boolean | null;
};

export type SalesOrderSelect = {
  id?: boolean | null;
  name?: boolean | null;
  orderDate?: boolean | null;
  zohoSalesOrderRef?: boolean | null;
  zohoPackageRef?: boolean | null;
  zohoInvoiceRef?: boolean | null;
  zohoPaymentRef?: boolean | null;
  xeroInvoiceRef?: boolean | null;
  xeroInvoiceNumber?: boolean | null;
  xeroPaymentRef?: boolean | null;
  stripePaymentIntentRef?: boolean | null;
  shipmentRunId?: boolean | null;
  packagingData?: boolean | null;
  invoiceUrl?: boolean | null;
  accountId?: boolean | null;
  createdAt?: boolean | null;
  updatedAt?: boolean | null;
  deletedAt?: boolean | null;
  account?: boolean | null;
  shipmentRun?: boolean | null;
  lineItems?: boolean | null;
  membershipOrder?: boolean | null;
  _count?: boolean | null;
};

export type GetCalculateShippingResponseBodySchema = {
  amountEx: number;
  amountGst: number;
};

export type ItemUpdateMembershipOfferingItemsConnect =
  | MembershipOfferingItemUniqueWhere
  | MembershipOfferingItemUniqueWhere[];

export type ItemUpdateMembershipOfferingItems = {
  connect: ItemUpdateMembershipOfferingItemsConnect;
} | null;

export type ItemUpdate = {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  sku?: string | null;
  sellEx?: number | null;
  sellGst?: number | null;
  costEx?: number | null;
  costGst?: number | null;
  stepQty?: number | null;
  minimumQty?: number | null;
  zohoInvItemRef?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  variant?: ItemUpdateVariant;
  product?: ItemUpdateProduct;
  salesOrderLineItems?: ItemUpdateSalesOrderLineItems;
  membershipOrderItems?: ItemUpdateMembershipOrderItems;
  membershipOfferingItems?: ItemUpdateMembershipOfferingItems;
};

export type ItemUpdateMembershipOrderItemsConnect = MembershipOrderItemUniqueWhere | MembershipOrderItemUniqueWhere[];

export type ItemUpdateMembershipOrderItems = {
  connect: ItemUpdateMembershipOrderItemsConnect;
} | null;

export type ItemUpdateSalesOrderLineItemsConnect = SalesOrderLineItemUniqueWhere | SalesOrderLineItemUniqueWhere[];

export type ItemUpdateSalesOrderLineItems = {
  connect: ItemUpdateSalesOrderLineItemsConnect;
} | null;

export type ItemUpdateProduct = {
  connect: ProductUniqueWhere;
} | null;

export type ItemUpdateVariant = {
  connect: ProductVariantUniqueWhere;
} | null;

export type ItemCreateMembershipOfferingItemsConnect =
  | MembershipOfferingItemUniqueWhere
  | MembershipOfferingItemUniqueWhere[];

export type ItemCreateMembershipOfferingItems = {
  connect: ItemCreateMembershipOfferingItemsConnect;
} | null;

export type ItemCreateMembershipOrderItemsConnect = MembershipOrderItemUniqueWhere | MembershipOrderItemUniqueWhere[];

export type ItemCreateMembershipOrderItems = {
  connect: ItemCreateMembershipOrderItemsConnect;
} | null;

export type ItemCreateSalesOrderLineItemsConnect = SalesOrderLineItemUniqueWhere | SalesOrderLineItemUniqueWhere[];

export type ItemCreateSalesOrderLineItems = {
  connect: ItemCreateSalesOrderLineItemsConnect;
} | null;

export type ItemCreateProduct = {
  connect: ProductUniqueWhere;
} | null;

export type ItemCreateVariant = {
  connect: ProductVariantUniqueWhere;
} | null;

export type ItemCreate = {
  id?: string | null;
  name: string;
  description?: string | null;
  sku?: string | null;
  sellEx: number;
  sellGst: number;
  costEx: number;
  costGst: number;
  stepQty?: number | null;
  minimumQty: number;
  zohoInvItemRef?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  variant?: ItemCreateVariant;
  product?: ItemCreateProduct;
  salesOrderLineItems?: ItemCreateSalesOrderLineItems;
  membershipOrderItems?: ItemCreateMembershipOrderItems;
  membershipOfferingItems?: ItemCreateMembershipOfferingItems;
};

export type ItemUniqueWhere = {
  id?: string | null;
};

export type ItemWhere = {
  AND?: ItemWhereAnd;
  OR?: ItemWhereOr;
  NOT?: ItemWhereNot;
  id?: ItemWhereId;
  name?: ItemWhereName;
  description?: ItemWhereDescription;
  sku?: ItemWhereSku;
  sellEx?: ItemWhereSellEx;
  sellGst?: ItemWhereSellGst;
  costEx?: ItemWhereCostEx;
  costGst?: ItemWhereCostGst;
  stepQty?: ItemWhereStepQty;
  minimumQty?: ItemWhereMinimumQty;
  zohoInvItemRef?: ItemWhereZohoInvItemRef;
  createdAt?: ItemWhereCreatedAt;
  updatedAt?: ItemWhereUpdatedAt;
  deletedAt?: ItemWhereDeletedAt;
  variant?: ItemWhereVariant;
  product?: ItemWhereProduct;
  salesOrderLineItems?: ItemWhereSalesOrderLineItems;
  membershipOrderItems?: ItemWhereMembershipOrderItems;
  membershipOfferingItems?: ItemWhereMembershipOfferingItems;
};

export type ItemWhereMembershipOfferingItemsNone = MembershipOfferingItemWhere;

export type ItemWhereMembershipOfferingItemsEvery = MembershipOfferingItemWhere;

export type ItemWhereMembershipOfferingItemsSome = MembershipOfferingItemWhere;

export type ItemWhereMembershipOfferingItems = {
  some?: ItemWhereMembershipOfferingItemsSome;
  every?: ItemWhereMembershipOfferingItemsEvery;
  none?: ItemWhereMembershipOfferingItemsNone;
} | null;

export type ItemWhereMembershipOrderItemsNone = MembershipOrderItemWhere;

export type ItemWhereMembershipOrderItemsEvery = MembershipOrderItemWhere;

export type ItemWhereMembershipOrderItemsSome = MembershipOrderItemWhere;

export type ItemWhereMembershipOrderItems = {
  some?: ItemWhereMembershipOrderItemsSome;
  every?: ItemWhereMembershipOrderItemsEvery;
  none?: ItemWhereMembershipOrderItemsNone;
} | null;

export type ItemWhereSalesOrderLineItemsNone = SalesOrderLineItemWhere;

export type ItemWhereSalesOrderLineItemsEvery = SalesOrderLineItemWhere;

export type ItemWhereSalesOrderLineItemsSome = SalesOrderLineItemWhere;

export type ItemWhereSalesOrderLineItems = {
  some?: ItemWhereSalesOrderLineItemsSome;
  every?: ItemWhereSalesOrderLineItemsEvery;
  none?: ItemWhereSalesOrderLineItemsNone;
} | null;

export type ItemWhereProductOneOfIsNot = ProductWhere;

export type ItemWhereProductOneOfIs = ProductWhere;

export type ItemWhereProductOneOf = {
  is?: ItemWhereProductOneOfIs;
  isNot?: ItemWhereProductOneOfIsNot;
};

export type ItemWhereProduct = ProductWhere | ItemWhereProductOneOf;

export type ItemWhereVariant = ProductVariantWhere | ItemWhereVariantOneOf;

export type ItemWhereVariantOneOfIsNot = ProductVariantWhere;

export type ItemWhereVariantOneOfIs = ProductVariantWhere;

export type ItemWhereVariantOneOf = {
  is?: ItemWhereVariantOneOfIs;
  isNot?: ItemWhereVariantOneOfIsNot;
};

export type ItemWhereDeletedAt = string | ItemWhereDeletedAtOneOf;

export type ItemWhereDeletedAtOneOfNot = string | DeletedAt;

export type ItemWhereDeletedAtOneOfNotIn = string | string[];

export type ItemWhereDeletedAtOneOfIn = string | string[];

export type ItemWhereDeletedAtOneOfEquals = string;

export type ItemWhereDeletedAtOneOf = {
  equals?: ItemWhereDeletedAtOneOfEquals;
  in?: ItemWhereDeletedAtOneOfIn;
  notIn?: ItemWhereDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: ItemWhereDeletedAtOneOfNot;
};

export type ItemWhereUpdatedAtOneOfNot = string | UpdatedAt;

export type ItemWhereUpdatedAtOneOfNotIn = string | string[];

export type ItemWhereUpdatedAtOneOfIn = string | string[];

export type ItemWhereUpdatedAtOneOf = {
  equals?: string | null;
  in?: ItemWhereUpdatedAtOneOfIn;
  notIn?: ItemWhereUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: ItemWhereUpdatedAtOneOfNot;
};

export type ItemWhereUpdatedAt = string | ItemWhereUpdatedAtOneOf;

export type ItemWhereCreatedAt = string | ItemWhereCreatedAtOneOf;

export type ItemWhereCreatedAtOneOfNot = string | CreatedAt;

export type ItemWhereCreatedAtOneOfNotIn = string | string[];

export type ItemWhereCreatedAtOneOfIn = string | string[];

export type ItemWhereCreatedAtOneOf = {
  equals?: string | null;
  in?: ItemWhereCreatedAtOneOfIn;
  notIn?: ItemWhereCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: ItemWhereCreatedAtOneOfNot;
};

export type ItemWhereZohoInvItemRef = string | ItemWhereZohoInvItemRefOneOf;

export type ItemWhereZohoInvItemRefOneOfNot = string | ZohoInvItemRef;

export type ItemWhereZohoInvItemRefOneOfMode =
  typeof ItemWhereZohoInvItemRefOneOfMode[keyof typeof ItemWhereZohoInvItemRefOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ItemWhereZohoInvItemRefOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ItemWhereZohoInvItemRefOneOfNotIn = string | string[];

export type ItemWhereZohoInvItemRefOneOfIn = string | string[];

export type ItemWhereZohoInvItemRefOneOfEquals = string;

export type ItemWhereZohoInvItemRefOneOf = {
  equals?: ItemWhereZohoInvItemRefOneOfEquals;
  in?: ItemWhereZohoInvItemRefOneOfIn;
  notIn?: ItemWhereZohoInvItemRefOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ItemWhereZohoInvItemRefOneOfMode;
  not?: ItemWhereZohoInvItemRefOneOfNot;
};

export type ItemWhereMinimumQty = number | ItemWhereMinimumQtyOneOf;

export type ItemWhereMinimumQtyOneOfNotOneOf = null;

export type ItemWhereMinimumQtyOneOfNot = ItemWhereMinimumQtyOneOfNotOneOf | number | MinimumQty;

export type ItemWhereMinimumQtyOneOfNotIn = number | number[];

export type ItemWhereMinimumQtyOneOfIn = number | number[];

export type ItemWhereMinimumQtyOneOf = {
  equals?: number | null;
  in?: ItemWhereMinimumQtyOneOfIn;
  notIn?: ItemWhereMinimumQtyOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: ItemWhereMinimumQtyOneOfNot;
};

export type ItemWhereStepQtyOneOf = {
  equals?: number | null;
  in?: ItemWhereStepQtyOneOfIn;
  notIn?: ItemWhereStepQtyOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: ItemWhereStepQtyOneOfNot;
};

export type ItemWhereStepQty = number | ItemWhereStepQtyOneOf;

export type ItemWhereStepQtyOneOfNotOneOf = null;

export type ItemWhereStepQtyOneOfNot = ItemWhereStepQtyOneOfNotOneOf | number | StepQty;

export type ItemWhereStepQtyOneOfNotIn = number | number[];

export type ItemWhereStepQtyOneOfIn = number | number[];

export type ItemWhereCostGst = number | ItemWhereCostGstOneOf;

export type ItemWhereCostGstOneOfNotOneOf = null;

export type ItemWhereCostGstOneOfNot = ItemWhereCostGstOneOfNotOneOf | number | CostGst;

export type ItemWhereCostGstOneOfNotIn = number | number[];

export type ItemWhereCostGstOneOfIn = number | number[];

export type ItemWhereCostGstOneOf = {
  equals?: number | null;
  in?: ItemWhereCostGstOneOfIn;
  notIn?: ItemWhereCostGstOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: ItemWhereCostGstOneOfNot;
};

export type ItemWhereCostEx = number | ItemWhereCostExOneOf;

export type ItemWhereCostExOneOfNotOneOf = null;

export type ItemWhereCostExOneOfNot = ItemWhereCostExOneOfNotOneOf | number | CostEx;

export type ItemWhereCostExOneOfNotIn = number | number[];

export type ItemWhereCostExOneOfIn = number | number[];

export type ItemWhereCostExOneOf = {
  equals?: number | null;
  in?: ItemWhereCostExOneOfIn;
  notIn?: ItemWhereCostExOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: ItemWhereCostExOneOfNot;
};

export type ItemWhereSellGstOneOfNotOneOf = null;

export type ItemWhereSellGstOneOfNot = ItemWhereSellGstOneOfNotOneOf | number | SellGst;

export type ItemWhereSellGstOneOfNotIn = number | number[];

export type ItemWhereSellGstOneOfIn = number | number[];

export type ItemWhereSellGstOneOf = {
  equals?: number | null;
  in?: ItemWhereSellGstOneOfIn;
  notIn?: ItemWhereSellGstOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: ItemWhereSellGstOneOfNot;
};

export type ItemWhereSellGst = number | ItemWhereSellGstOneOf;

export type ItemWhereSellEx = number | ItemWhereSellExOneOf;

export type ItemWhereSellExOneOfNotOneOf = null;

export type ItemWhereSellExOneOfNot = ItemWhereSellExOneOfNotOneOf | number | SellEx;

export type ItemWhereSellExOneOfNotIn = number | number[];

export type ItemWhereSellExOneOfIn = number | number[];

export type ItemWhereSellExOneOf = {
  equals?: number | null;
  in?: ItemWhereSellExOneOfIn;
  notIn?: ItemWhereSellExOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: ItemWhereSellExOneOfNot;
};

export type ItemWhereSku = string | ItemWhereSkuOneOf;

export type ItemWhereSkuOneOfNot = string | Sku;

export type ItemWhereSkuOneOfMode = typeof ItemWhereSkuOneOfMode[keyof typeof ItemWhereSkuOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ItemWhereSkuOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ItemWhereSkuOneOfNotIn = string | string[];

export type ItemWhereSkuOneOfIn = string | string[];

export type ItemWhereSkuOneOfEquals = string;

export type ItemWhereSkuOneOf = {
  equals?: ItemWhereSkuOneOfEquals;
  in?: ItemWhereSkuOneOfIn;
  notIn?: ItemWhereSkuOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ItemWhereSkuOneOfMode;
  not?: ItemWhereSkuOneOfNot;
};

export type ItemWhereDescription = string | ItemWhereDescriptionOneOf;

export type ItemWhereDescriptionOneOfNot = string | Description;

export type ItemWhereDescriptionOneOfMode =
  typeof ItemWhereDescriptionOneOfMode[keyof typeof ItemWhereDescriptionOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ItemWhereDescriptionOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ItemWhereDescriptionOneOfNotIn = string | string[];

export type ItemWhereDescriptionOneOfIn = string | string[];

export type ItemWhereDescriptionOneOfEquals = string;

export type ItemWhereDescriptionOneOf = {
  equals?: ItemWhereDescriptionOneOfEquals;
  in?: ItemWhereDescriptionOneOfIn;
  notIn?: ItemWhereDescriptionOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ItemWhereDescriptionOneOfMode;
  not?: ItemWhereDescriptionOneOfNot;
};

export type ItemWhereName = string | ItemWhereNameOneOf;

export type ItemWhereNameOneOfNot = string | Name;

export type ItemWhereNameOneOfMode = typeof ItemWhereNameOneOfMode[keyof typeof ItemWhereNameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ItemWhereNameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ItemWhereNameOneOfNotIn = string | string[];

export type ItemWhereNameOneOfIn = string | string[];

export type ItemWhereNameOneOf = {
  equals?: string | null;
  in?: ItemWhereNameOneOfIn;
  notIn?: ItemWhereNameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ItemWhereNameOneOfMode;
  not?: ItemWhereNameOneOfNot;
};

export type ItemWhereIdOneOfNot = string | Id;

export type ItemWhereIdOneOfMode = typeof ItemWhereIdOneOfMode[keyof typeof ItemWhereIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ItemWhereIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ItemWhereIdOneOfNotIn = string | string[];

export type ItemWhereIdOneOfIn = string | string[];

export type ItemWhereIdOneOf = {
  equals?: string | null;
  in?: ItemWhereIdOneOfIn;
  notIn?: ItemWhereIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ItemWhereIdOneOfMode;
  not?: ItemWhereIdOneOfNot;
};

export type ItemWhereId = string | ItemWhereIdOneOf;

export type ItemWhereNot = ItemWhere | ItemWhere[];

export type ItemWhereOr = ItemWhere | ItemWhere[];

export type ItemWhereAnd = ItemWhere | ItemWhere[];

export type ItemMembershipOfferingItems = MembershipOfferingItems;

export type ItemMembershipOrderItems = MembershipOrderItems;

export type ItemSalesOrderLineItems = SalesOrderLineItems;

export type ItemProduct = Product;

export type ItemVariant = ProductVariant;

export type Item = {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  sku?: string | null;
  sellEx?: number | null;
  sellGst?: number | null;
  costEx?: number | null;
  costGst?: number | null;
  stepQty?: number | null;
  minimumQty?: number | null;
  zohoInvItemRef?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  variant?: ItemVariant;
  product?: ItemProduct;
  salesOrderLineItems?: ItemSalesOrderLineItems;
  membershipOrderItems?: ItemMembershipOrderItems;
  membershipOfferingItems?: ItemMembershipOfferingItems;
  _count?: unknown;
};

export type Items = {
  items?: Item[];
};

export type ItemIncludeVariantOneOf = {
  select?: ProductVariantSelect;
  include?: ProductVariantInclude;
};

export type ItemIncludeVariant = boolean | ItemIncludeVariantOneOf;

export type ItemIncludeSalesOrderLineItems = boolean | ItemIncludeSalesOrderLineItemsOneOf;

export type ItemInclude = {
  product?: ItemIncludeProduct;
  membershipOrderItems?: ItemIncludeMembershipOrderItems;
  membershipOfferingItems?: ItemIncludeMembershipOfferingItems;
  salesOrderLineItems?: ItemIncludeSalesOrderLineItems;
  variant?: ItemIncludeVariant;
};

export type ItemIncludeSalesOrderLineItemsOneOf = {
  select?: SalesOrderLineItemSelect;
  include?: SalesOrderLineItemInclude;
};

export type ItemIncludeMembershipOfferingItemsOneOf = {
  select?: MembershipOfferingItemSelect;
  include?: MembershipOfferingItemInclude;
};

export type ItemIncludeMembershipOfferingItems = boolean | ItemIncludeMembershipOfferingItemsOneOf;

export type ItemIncludeMembershipOrderItemsOneOf = {
  select?: MembershipOrderItemSelect;
  include?: MembershipOrderItemInclude;
};

export type ItemIncludeMembershipOrderItems = boolean | ItemIncludeMembershipOrderItemsOneOf;

export type ItemIncludeProductOneOf = {
  select?: ProductSelect;
  include?: ProductInclude;
};

export type ItemIncludeProduct = boolean | ItemIncludeProductOneOf;

export type ItemSelect = {
  id?: boolean | null;
  name?: boolean | null;
  description?: boolean | null;
  sku?: boolean | null;
  sellEx?: boolean | null;
  sellGst?: boolean | null;
  costEx?: boolean | null;
  costGst?: boolean | null;
  stepQty?: boolean | null;
  minimumQty?: boolean | null;
  zohoInvItemRef?: boolean | null;
  createdAt?: boolean | null;
  updatedAt?: boolean | null;
  deletedAt?: boolean | null;
  variant?: boolean | null;
  product?: boolean | null;
  salesOrderLineItems?: boolean | null;
  membershipOrderItems?: boolean | null;
  membershipOfferingItems?: boolean | null;
  _count?: boolean | null;
};

export type CategoryUpdateProductsConnect = ProductUniqueWhere | ProductUniqueWhere[];

export type CategoryUpdateProducts = {
  connect: CategoryUpdateProductsConnect;
} | null;

export type CategoryUpdate = {
  id?: string | null;
  name?: string | null;
  slug?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  products?: CategoryUpdateProducts;
};

export type CategoryCreateProductsConnect = ProductUniqueWhere | ProductUniqueWhere[];

export type CategoryCreateProducts = {
  connect: CategoryCreateProductsConnect;
} | null;

export type CategoryCreate = {
  id?: string | null;
  name: string;
  slug: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  products?: CategoryCreateProducts;
};

export type CategoryUniqueWhere = {
  id?: string | null;
};

export type CategoryWhereProductsNone = ProductWhere;

export type CategoryWhereProductsEvery = ProductWhere;

export type CategoryWhereProductsSome = ProductWhere;

export type CategoryWhereProducts = {
  some?: CategoryWhereProductsSome;
  every?: CategoryWhereProductsEvery;
  none?: CategoryWhereProductsNone;
} | null;

export type CategoryWhereDeletedAt = string | CategoryWhereDeletedAtOneOf;

export type CategoryWhere = {
  AND?: CategoryWhereAnd;
  OR?: CategoryWhereOr;
  NOT?: CategoryWhereNot;
  id?: CategoryWhereId;
  name?: CategoryWhereName;
  slug?: CategoryWhereSlug;
  createdAt?: CategoryWhereCreatedAt;
  updatedAt?: CategoryWhereUpdatedAt;
  deletedAt?: CategoryWhereDeletedAt;
  products?: CategoryWhereProducts;
};

export type CategoryWhereDeletedAtOneOfNot = string | DeletedAt;

export type CategoryWhereDeletedAtOneOfNotIn = string | string[];

export type CategoryWhereDeletedAtOneOfIn = string | string[];

export type CategoryWhereDeletedAtOneOfEquals = string;

export type CategoryWhereDeletedAtOneOf = {
  equals?: CategoryWhereDeletedAtOneOfEquals;
  in?: CategoryWhereDeletedAtOneOfIn;
  notIn?: CategoryWhereDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: CategoryWhereDeletedAtOneOfNot;
};

export type CategoryWhereUpdatedAtOneOfNot = string | UpdatedAt;

export type CategoryWhereUpdatedAtOneOfNotIn = string | string[];

export type CategoryWhereUpdatedAtOneOfIn = string | string[];

export type CategoryWhereUpdatedAtOneOf = {
  equals?: string | null;
  in?: CategoryWhereUpdatedAtOneOfIn;
  notIn?: CategoryWhereUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: CategoryWhereUpdatedAtOneOfNot;
};

export type CategoryWhereUpdatedAt = string | CategoryWhereUpdatedAtOneOf;

export type CategoryWhereCreatedAtOneOfNot = string | CreatedAt;

export type CategoryWhereCreatedAtOneOfNotIn = string | string[];

export type CategoryWhereCreatedAtOneOfIn = string | string[];

export type CategoryWhereCreatedAtOneOf = {
  equals?: string | null;
  in?: CategoryWhereCreatedAtOneOfIn;
  notIn?: CategoryWhereCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: CategoryWhereCreatedAtOneOfNot;
};

export type CategoryWhereCreatedAt = string | CategoryWhereCreatedAtOneOf;

export type CategoryWhereSlug = string | CategoryWhereSlugOneOf;

export type CategoryWhereSlugOneOfNot = string | Slug;

export type CategoryWhereSlugOneOfMode = typeof CategoryWhereSlugOneOfMode[keyof typeof CategoryWhereSlugOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CategoryWhereSlugOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type CategoryWhereSlugOneOfNotIn = string | string[];

export type CategoryWhereSlugOneOfIn = string | string[];

export type CategoryWhereSlugOneOf = {
  equals?: string | null;
  in?: CategoryWhereSlugOneOfIn;
  notIn?: CategoryWhereSlugOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: CategoryWhereSlugOneOfMode;
  not?: CategoryWhereSlugOneOfNot;
};

export type CategoryWhereName = string | CategoryWhereNameOneOf;

export type CategoryWhereNameOneOfNot = string | Name;

export type CategoryWhereNameOneOfMode = typeof CategoryWhereNameOneOfMode[keyof typeof CategoryWhereNameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CategoryWhereNameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type CategoryWhereNameOneOfNotIn = string | string[];

export type CategoryWhereNameOneOfIn = string | string[];

export type CategoryWhereNameOneOf = {
  equals?: string | null;
  in?: CategoryWhereNameOneOfIn;
  notIn?: CategoryWhereNameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: CategoryWhereNameOneOfMode;
  not?: CategoryWhereNameOneOfNot;
};

export type CategoryWhereIdOneOfNot = string | Id;

export type CategoryWhereIdOneOfMode = typeof CategoryWhereIdOneOfMode[keyof typeof CategoryWhereIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CategoryWhereIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type CategoryWhereIdOneOfNotIn = string | string[];

export type CategoryWhereIdOneOfIn = string | string[];

export type CategoryWhereIdOneOf = {
  equals?: string | null;
  in?: CategoryWhereIdOneOfIn;
  notIn?: CategoryWhereIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: CategoryWhereIdOneOfMode;
  not?: CategoryWhereIdOneOfNot;
};

export type CategoryWhereId = string | CategoryWhereIdOneOf;

export type CategoryWhereNot = CategoryWhere | CategoryWhere[];

export type CategoryWhereOr = CategoryWhere | CategoryWhere[];

export type CategoryWhereAnd = CategoryWhere | CategoryWhere[];

export type CategoryProducts = Products;

export type Category = {
  id?: string | null;
  name?: string | null;
  slug?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  products?: CategoryProducts;
  _count?: unknown;
};

export type Categories = {
  items?: Category[];
};

export type CategoryInclude = {
  products?: boolean | null;
};

export type CategorySelect = {
  id?: boolean | null;
  name?: boolean | null;
  slug?: boolean | null;
  createdAt?: boolean | null;
  updatedAt?: boolean | null;
  deletedAt?: boolean | null;
  products?: boolean | null;
  _count?: boolean | null;
};

export type ProductVariantUpdateItem = {
  update: ItemUpdate;
} | null;

export type ProductVariantUpdate = {
  id?: string | null;
  name?: string | null;
  selectedOptions?: unknown;
  createdAt?: string | null;
  updatedAt?: string | null;
  item?: ProductVariantUpdateItem;
};

export type ProductVariantCreateMembershipFrequency = {
  connect: MembershipFrequencyUniqueWhere;
} | null;

export type ProductVariantCreateProduct = {
  connect: ProductUniqueWhere;
};

export type ProductVariantCreateItem = {
  create: ItemCreate;
};

export type ProductVariantCreate = {
  id?: string | null;
  name: string;
  selectedOptions: unknown;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  item: ProductVariantCreateItem;
  product: ProductVariantCreateProduct;
  membershipFrequency?: ProductVariantCreateMembershipFrequency;
};

export type ProductVariantUniqueWhere = {
  id?: string | null;
  itemId?: string | null;
};

export type ProductVariantWhereMembershipFrequency =
  | MembershipFrequencyWhere
  | ProductVariantWhereMembershipFrequencyOneOf;

export type ProductVariantWhereMembershipFrequencyOneOfIsNot = MembershipFrequencyWhere;

export type ProductVariantWhereMembershipFrequencyOneOfIs = MembershipFrequencyWhere;

export type ProductVariantWhereMembershipFrequencyOneOf = {
  is?: ProductVariantWhereMembershipFrequencyOneOfIs;
  isNot?: ProductVariantWhereMembershipFrequencyOneOfIsNot;
};

export type ProductVariantWhereProduct = ProductWhere | ProductVariantWhereProductOneOf;

export type ProductVariantWhere = {
  AND?: ProductVariantWhereAnd;
  OR?: ProductVariantWhereOr;
  NOT?: ProductVariantWhereNot;
  id?: ProductVariantWhereId;
  name?: ProductVariantWhereName;
  selectedOptions?: ProductVariantWhereSelectedOptions;
  itemId?: ProductVariantWhereItemId;
  productId?: ProductVariantWhereProductId;
  createdAt?: ProductVariantWhereCreatedAt;
  updatedAt?: ProductVariantWhereUpdatedAt;
  deletedAt?: ProductVariantWhereDeletedAt;
  item?: ProductVariantWhereItem;
  product?: ProductVariantWhereProduct;
  membershipFrequency?: ProductVariantWhereMembershipFrequency;
};

export type ProductVariantWhereProductOneOfIsNot = ProductWhere;

export type ProductVariantWhereProductOneOfIs = ProductWhere;

export type ProductVariantWhereProductOneOf = {
  is?: ProductVariantWhereProductOneOfIs;
  isNot?: ProductVariantWhereProductOneOfIsNot;
};

export type ProductVariantWhereItemOneOfIsNot = ItemWhere;

export type ProductVariantWhereItemOneOfIs = ItemWhere;

export type ProductVariantWhereItemOneOf = {
  is?: ProductVariantWhereItemOneOfIs;
  isNot?: ProductVariantWhereItemOneOfIsNot;
};

export type ProductVariantWhereItem = ItemWhere | ProductVariantWhereItemOneOf;

export type ProductVariantWhereDeletedAtOneOfNot = string | DeletedAt;

export type ProductVariantWhereDeletedAtOneOfNotIn = string | string[];

export type ProductVariantWhereDeletedAtOneOfIn = string | string[];

export type ProductVariantWhereDeletedAtOneOfEquals = string;

export type ProductVariantWhereDeletedAtOneOf = {
  equals?: ProductVariantWhereDeletedAtOneOfEquals;
  in?: ProductVariantWhereDeletedAtOneOfIn;
  notIn?: ProductVariantWhereDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: ProductVariantWhereDeletedAtOneOfNot;
};

export type ProductVariantWhereDeletedAt = string | ProductVariantWhereDeletedAtOneOf;

export type ProductVariantWhereUpdatedAtOneOfNot = string | UpdatedAt;

export type ProductVariantWhereUpdatedAtOneOfNotIn = string | string[];

export type ProductVariantWhereUpdatedAtOneOfIn = string | string[];

export type ProductVariantWhereUpdatedAtOneOf = {
  equals?: string | null;
  in?: ProductVariantWhereUpdatedAtOneOfIn;
  notIn?: ProductVariantWhereUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: ProductVariantWhereUpdatedAtOneOfNot;
};

export type ProductVariantWhereUpdatedAt = string | ProductVariantWhereUpdatedAtOneOf;

export type ProductVariantWhereCreatedAt = string | ProductVariantWhereCreatedAtOneOf;

export type ProductVariantWhereCreatedAtOneOfNot = string | CreatedAt;

export type ProductVariantWhereCreatedAtOneOfNotIn = string | string[];

export type ProductVariantWhereCreatedAtOneOfIn = string | string[];

export type ProductVariantWhereCreatedAtOneOf = {
  equals?: string | null;
  in?: ProductVariantWhereCreatedAtOneOfIn;
  notIn?: ProductVariantWhereCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: ProductVariantWhereCreatedAtOneOfNot;
};

export type ProductVariantWhereProductId = string | ProductVariantWhereProductIdOneOf;

export type ProductVariantWhereProductIdOneOfNot = string | ProductId;

export type ProductVariantWhereProductIdOneOfMode =
  typeof ProductVariantWhereProductIdOneOfMode[keyof typeof ProductVariantWhereProductIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductVariantWhereProductIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ProductVariantWhereProductIdOneOfNotIn = string | string[];

export type ProductVariantWhereProductIdOneOfIn = string | string[];

export type ProductVariantWhereProductIdOneOf = {
  equals?: string | null;
  in?: ProductVariantWhereProductIdOneOfIn;
  notIn?: ProductVariantWhereProductIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ProductVariantWhereProductIdOneOfMode;
  not?: ProductVariantWhereProductIdOneOfNot;
};

export type ProductVariantWhereItemId = string | ProductVariantWhereItemIdOneOf;

export type ProductVariantWhereItemIdOneOfNot = string | ItemId;

export type ProductVariantWhereItemIdOneOfMode =
  typeof ProductVariantWhereItemIdOneOfMode[keyof typeof ProductVariantWhereItemIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductVariantWhereItemIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ProductVariantWhereItemIdOneOfNotIn = string | string[];

export type ProductVariantWhereItemIdOneOfIn = string | string[];

export type ProductVariantWhereItemIdOneOf = {
  equals?: string | null;
  in?: ProductVariantWhereItemIdOneOfIn;
  notIn?: ProductVariantWhereItemIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ProductVariantWhereItemIdOneOfMode;
  not?: ProductVariantWhereItemIdOneOfNot;
};

export type ProductVariantWhereSelectedOptionsOneOfPath = string | string[];

export type ProductVariantWhereSelectedOptionsOneOf = {
  path?: ProductVariantWhereSelectedOptionsOneOfPath;
  equals?: unknown;
  not?: unknown;
  string_contains?: string | null;
  string_starts_with?: string | null;
  string_ends_with?: string | null;
  array_contains?: unknown;
  array_starts_with?: unknown;
  array_ends_with?: unknown;
};

export type ProductVariantWhereSelectedOptions = ProductVariantWhereSelectedOptionsOneOf;

export type ProductVariantWhereName = string | ProductVariantWhereNameOneOf;

export type ProductVariantWhereNameOneOfNot = string | Name;

export type ProductVariantWhereNameOneOfMode =
  typeof ProductVariantWhereNameOneOfMode[keyof typeof ProductVariantWhereNameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductVariantWhereNameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ProductVariantWhereNameOneOfNotIn = string | string[];

export type ProductVariantWhereNameOneOfIn = string | string[];

export type ProductVariantWhereNameOneOf = {
  equals?: string | null;
  in?: ProductVariantWhereNameOneOfIn;
  notIn?: ProductVariantWhereNameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ProductVariantWhereNameOneOfMode;
  not?: ProductVariantWhereNameOneOfNot;
};

export type ProductVariantWhereIdOneOfNot = string | Id;

export type ProductVariantWhereIdOneOfMode =
  typeof ProductVariantWhereIdOneOfMode[keyof typeof ProductVariantWhereIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductVariantWhereIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ProductVariantWhereIdOneOfNotIn = string | string[];

export type ProductVariantWhereIdOneOfIn = string | string[];

export type ProductVariantWhereIdOneOf = {
  equals?: string | null;
  in?: ProductVariantWhereIdOneOfIn;
  notIn?: ProductVariantWhereIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ProductVariantWhereIdOneOfMode;
  not?: ProductVariantWhereIdOneOfNot;
};

export type ProductVariantWhereId = string | ProductVariantWhereIdOneOf;

export type ProductVariantWhereNot = ProductVariantWhere | ProductVariantWhere[];

export type ProductVariantWhereOr = ProductVariantWhere | ProductVariantWhere[];

export type ProductVariantWhereAnd = ProductVariantWhere | ProductVariantWhere[];

export type ProductVariantMembershipFrequency = MembershipFrequency;

export type ProductVariantProduct = Product;

export type ProductVariantItem = Item;

export type ProductVariant = {
  id?: string | null;
  name?: string | null;
  selectedOptions?: unknown;
  itemId?: string | null;
  productId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  item?: ProductVariantItem;
  product?: ProductVariantProduct;
  membershipFrequency?: ProductVariantMembershipFrequency;
};

export type ProductVariants = {
  items?: ProductVariant[];
};

export type ProductVariantIncludeProductOneOf = {
  select?: ProductSelect;
  include?: ProductInclude;
};

export type ProductVariantIncludeProduct = boolean | ProductVariantIncludeProductOneOf;

export type ProductVariantInclude = {
  item?: ProductVariantIncludeItem;
  membershipFrequency?: ProductVariantIncludeMembershipFrequency;
  product?: ProductVariantIncludeProduct;
};

export type ProductVariantIncludeMembershipFrequencyOneOf = {
  select?: MembershipFrequencySelect;
  include?: MembershipFrequencyInclude;
};

export type ProductVariantIncludeMembershipFrequency = boolean | ProductVariantIncludeMembershipFrequencyOneOf;

export type ProductVariantIncludeItemOneOf = {
  select?: ItemSelect;
  include?: ItemInclude;
};

export type ProductVariantIncludeItem = boolean | ProductVariantIncludeItemOneOf;

export type ProductVariantSelect = {
  id?: boolean | null;
  name?: boolean | null;
  selectedOptions?: boolean | null;
  itemId?: boolean | null;
  productId?: boolean | null;
  createdAt?: boolean | null;
  updatedAt?: boolean | null;
  deletedAt?: boolean | null;
  item?: boolean | null;
  product?: boolean | null;
  membershipFrequency?: boolean | null;
};

export type ProductUpdateFilesConnect = FileUniqueWhere | FileUniqueWhere[];

export type ProductUpdateFiles = {
  connect: ProductUpdateFilesConnect;
} | null;

export type ProductUpdateVariantsConnect = ProductVariantUniqueWhere | ProductVariantUniqueWhere[];

export type ProductUpdateVariants = {
  connect: ProductUpdateVariantsConnect;
} | null;

export type ProductUpdateCategoriesConnect = CategoryUniqueWhere | CategoryUniqueWhere[];

export type ProductUpdateCategories = {
  connect: ProductUpdateCategoriesConnect;
} | null;

export type ProductUpdateMembershipTier = {
  connect: MembershipTierUniqueWhere;
} | null;

export type ProductUpdateItem = {
  update: ItemUpdate;
} | null;

export type ProductUpdateStatus = typeof ProductUpdateStatus[keyof typeof ProductUpdateStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductUpdateStatus = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type ProductUpdateType = typeof ProductUpdateType[keyof typeof ProductUpdateType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductUpdateType = {
  PURCHASE: 'PURCHASE',
  MEMBERSHIP: 'MEMBERSHIP',
  HIDDEN: 'HIDDEN',
} as const;

export type ProductUpdate = {
  id?: string | null;
  name?: string | null;
  type?: ProductUpdateType;
  description?: string | null;
  pageData?: unknown;
  slug?: string | null;
  status?: ProductUpdateStatus;
  createdAt?: string | null;
  updatedAt?: string | null;
  item?: ProductUpdateItem;
  membershipTier?: ProductUpdateMembershipTier;
  categories?: ProductUpdateCategories;
  variants?: ProductUpdateVariants;
  files?: ProductUpdateFiles;
};

export type ProductCreateFilesConnect = FileUniqueWhere | FileUniqueWhere[];

export type ProductCreateFiles = {
  connect: ProductCreateFilesConnect;
} | null;

export type ProductCreateVariantsConnect = ProductVariantUniqueWhere | ProductVariantUniqueWhere[];

export type ProductCreateVariants = {
  connect: ProductCreateVariantsConnect;
} | null;

export type ProductCreateCategoriesConnect = CategoryUniqueWhere | CategoryUniqueWhere[];

export type ProductCreateCategories = {
  connect: ProductCreateCategoriesConnect;
} | null;

export type ProductCreateMembershipTier = {
  connect: MembershipTierUniqueWhere;
} | null;

export type ProductCreateItem = {
  connect: ItemUniqueWhere;
} | null;

export type ProductCreateStatus = typeof ProductCreateStatus[keyof typeof ProductCreateStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductCreateStatus = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type ProductCreateType = typeof ProductCreateType[keyof typeof ProductCreateType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductCreateType = {
  PURCHASE: 'PURCHASE',
  MEMBERSHIP: 'MEMBERSHIP',
  HIDDEN: 'HIDDEN',
} as const;

export type ProductCreate = {
  id?: string | null;
  name: string;
  type: ProductCreateType;
  description?: string | null;
  pageData?: unknown;
  slug: string;
  status?: ProductCreateStatus;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  item?: ProductCreateItem;
  membershipTier?: ProductCreateMembershipTier;
  categories?: ProductCreateCategories;
  variants?: ProductCreateVariants;
  files?: ProductCreateFiles;
};

export type ProductUniqueWhere = {
  id?: string | null;
  itemId?: string | null;
};

export type ProductWhere = {
  AND?: ProductWhereAnd;
  OR?: ProductWhereOr;
  NOT?: ProductWhereNot;
  id?: ProductWhereId;
  name?: ProductWhereName;
  type?: ProductWhereType;
  itemId?: ProductWhereItemId;
  description?: ProductWhereDescription;
  pageData?: ProductWherePageData;
  slug?: ProductWhereSlug;
  status?: ProductWhereStatus;
  createdAt?: ProductWhereCreatedAt;
  updatedAt?: ProductWhereUpdatedAt;
  deletedAt?: ProductWhereDeletedAt;
  item?: ProductWhereItem;
  membershipTier?: ProductWhereMembershipTier;
  categories?: ProductWhereCategories;
  variants?: ProductWhereVariants;
  files?: ProductWhereFiles;
};

export type ProductWhereFilesNone = FileWhere;

export type ProductWhereFilesEvery = FileWhere;

export type ProductWhereFilesSome = FileWhere;

export type ProductWhereFiles = {
  some?: ProductWhereFilesSome;
  every?: ProductWhereFilesEvery;
  none?: ProductWhereFilesNone;
} | null;

export type ProductWhereVariantsNone = ProductVariantWhere;

export type ProductWhereVariantsEvery = ProductVariantWhere;

export type ProductWhereVariantsSome = ProductVariantWhere;

export type ProductWhereVariants = {
  some?: ProductWhereVariantsSome;
  every?: ProductWhereVariantsEvery;
  none?: ProductWhereVariantsNone;
} | null;

export type ProductWhereCategoriesNone = CategoryWhere;

export type ProductWhereCategoriesEvery = CategoryWhere;

export type ProductWhereCategoriesSome = CategoryWhere;

export type ProductWhereCategories = {
  some?: ProductWhereCategoriesSome;
  every?: ProductWhereCategoriesEvery;
  none?: ProductWhereCategoriesNone;
} | null;

export type ProductWhereMembershipTier = MembershipTierWhere | ProductWhereMembershipTierOneOf;

export type ProductWhereMembershipTierOneOfIsNot = MembershipTierWhere;

export type ProductWhereMembershipTierOneOfIs = MembershipTierWhere;

export type ProductWhereMembershipTierOneOf = {
  is?: ProductWhereMembershipTierOneOfIs;
  isNot?: ProductWhereMembershipTierOneOfIsNot;
};

export type ProductWhereItemOneOfIsNot = ItemWhere;

export type ProductWhereItemOneOfIs = ItemWhere;

export type ProductWhereItemOneOf = {
  is?: ProductWhereItemOneOfIs;
  isNot?: ProductWhereItemOneOfIsNot;
};

export type ProductWhereItem = ItemWhere | ProductWhereItemOneOf;

export type ProductWhereDeletedAtOneOfNot = string | DeletedAt;

export type ProductWhereDeletedAtOneOfNotIn = string | string[];

export type ProductWhereDeletedAtOneOfIn = string | string[];

export type ProductWhereDeletedAtOneOfEquals = string;

export type ProductWhereDeletedAtOneOf = {
  equals?: ProductWhereDeletedAtOneOfEquals;
  in?: ProductWhereDeletedAtOneOfIn;
  notIn?: ProductWhereDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: ProductWhereDeletedAtOneOfNot;
};

export type ProductWhereDeletedAt = string | ProductWhereDeletedAtOneOf;

export type ProductWhereUpdatedAtOneOfNot = string | UpdatedAt;

export type ProductWhereUpdatedAtOneOfNotIn = string | string[];

export type ProductWhereUpdatedAtOneOfIn = string | string[];

export type ProductWhereUpdatedAtOneOf = {
  equals?: string | null;
  in?: ProductWhereUpdatedAtOneOfIn;
  notIn?: ProductWhereUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: ProductWhereUpdatedAtOneOfNot;
};

export type ProductWhereUpdatedAt = string | ProductWhereUpdatedAtOneOf;

export type ProductWhereCreatedAtOneOfNot = string | CreatedAt;

export type ProductWhereCreatedAtOneOfNotIn = string | string[];

export type ProductWhereCreatedAtOneOfIn = string | string[];

export type ProductWhereCreatedAtOneOf = {
  equals?: string | null;
  in?: ProductWhereCreatedAtOneOfIn;
  notIn?: ProductWhereCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: ProductWhereCreatedAtOneOfNot;
};

export type ProductWhereCreatedAt = string | ProductWhereCreatedAtOneOf;

export type ProductWhereStatusOneOfNot = 'ACTIVE' | 'DISABLED' | Status;

export type ProductWhereStatusOneOf = {
  equals?: ProductWhereStatusOneOfEquals;
  in?: ProductWhereStatusOneOfIn;
  notIn?: ProductWhereStatusOneOfNotIn;
  not?: ProductWhereStatusOneOfNot;
} | null;

export type ProductWhereStatus = 'ACTIVE' | 'DISABLED' | ProductWhereStatusOneOf;

export type ProductWhereStatusOneOfNotInOneOfItem =
  typeof ProductWhereStatusOneOfNotInOneOfItem[keyof typeof ProductWhereStatusOneOfNotInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductWhereStatusOneOfNotInOneOfItem = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type ProductWhereStatusOneOfNotIn = 'ACTIVE' | 'DISABLED' | ProductWhereStatusOneOfNotInOneOfItem[];

export type ProductWhereStatusOneOfInOneOfItem =
  typeof ProductWhereStatusOneOfInOneOfItem[keyof typeof ProductWhereStatusOneOfInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductWhereStatusOneOfInOneOfItem = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type ProductWhereStatusOneOfIn = 'ACTIVE' | 'DISABLED' | ProductWhereStatusOneOfInOneOfItem[];

export type ProductWhereStatusOneOfEquals =
  typeof ProductWhereStatusOneOfEquals[keyof typeof ProductWhereStatusOneOfEquals];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductWhereStatusOneOfEquals = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type ProductWhereSlug = string | ProductWhereSlugOneOf;

export type ProductWhereSlugOneOfNot = string | Slug;

export type ProductWhereSlugOneOfMode = typeof ProductWhereSlugOneOfMode[keyof typeof ProductWhereSlugOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductWhereSlugOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ProductWhereSlugOneOfNotIn = string | string[];

export type ProductWhereSlugOneOfIn = string | string[];

export type ProductWhereSlugOneOf = {
  equals?: string | null;
  in?: ProductWhereSlugOneOfIn;
  notIn?: ProductWhereSlugOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ProductWhereSlugOneOfMode;
  not?: ProductWhereSlugOneOfNot;
};

export type ProductWherePageDataOneOfPath = string | string[];

export type ProductWherePageDataOneOf = {
  path?: ProductWherePageDataOneOfPath;
  equals?: unknown;
  not?: unknown;
  string_contains?: string | null;
  string_starts_with?: string | null;
  string_ends_with?: string | null;
  array_contains?: unknown;
  array_starts_with?: unknown;
  array_ends_with?: unknown;
};

export type ProductWherePageData = ProductWherePageDataOneOf;

export type ProductWhereDescription = string | ProductWhereDescriptionOneOf;

export type ProductWhereDescriptionOneOfNot = string | Description;

export type ProductWhereDescriptionOneOfMode =
  typeof ProductWhereDescriptionOneOfMode[keyof typeof ProductWhereDescriptionOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductWhereDescriptionOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ProductWhereDescriptionOneOfNotIn = string | string[];

export type ProductWhereDescriptionOneOfIn = string | string[];

export type ProductWhereDescriptionOneOfEquals = string;

export type ProductWhereDescriptionOneOf = {
  equals?: ProductWhereDescriptionOneOfEquals;
  in?: ProductWhereDescriptionOneOfIn;
  notIn?: ProductWhereDescriptionOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ProductWhereDescriptionOneOfMode;
  not?: ProductWhereDescriptionOneOfNot;
};

export type ProductWhereItemId = string | ProductWhereItemIdOneOf;

export type ProductWhereItemIdOneOfNot = string | ItemId;

export type ProductWhereItemIdOneOfMode = typeof ProductWhereItemIdOneOfMode[keyof typeof ProductWhereItemIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductWhereItemIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ProductWhereItemIdOneOfNotIn = string | string[];

export type ProductWhereItemIdOneOfIn = string | string[];

export type ProductWhereItemIdOneOf = {
  equals?: string | null;
  in?: ProductWhereItemIdOneOfIn;
  notIn?: ProductWhereItemIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ProductWhereItemIdOneOfMode;
  not?: ProductWhereItemIdOneOfNot;
};

export type ProductWhereType = 'PURCHASE' | 'MEMBERSHIP' | 'HIDDEN' | ProductWhereTypeOneOf;

export type ProductWhereTypeOneOfNot = 'PURCHASE' | 'MEMBERSHIP' | 'HIDDEN' | Type;

export type ProductWhereTypeOneOfNotInOneOfItem =
  typeof ProductWhereTypeOneOfNotInOneOfItem[keyof typeof ProductWhereTypeOneOfNotInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductWhereTypeOneOfNotInOneOfItem = {
  PURCHASE: 'PURCHASE',
  MEMBERSHIP: 'MEMBERSHIP',
  HIDDEN: 'HIDDEN',
} as const;

export type ProductWhereTypeOneOfNotIn = 'PURCHASE' | 'MEMBERSHIP' | 'HIDDEN' | ProductWhereTypeOneOfNotInOneOfItem[];

export type ProductWhereTypeOneOfInOneOfItem =
  typeof ProductWhereTypeOneOfInOneOfItem[keyof typeof ProductWhereTypeOneOfInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductWhereTypeOneOfInOneOfItem = {
  PURCHASE: 'PURCHASE',
  MEMBERSHIP: 'MEMBERSHIP',
  HIDDEN: 'HIDDEN',
} as const;

export type ProductWhereTypeOneOfIn = 'PURCHASE' | 'MEMBERSHIP' | 'HIDDEN' | ProductWhereTypeOneOfInOneOfItem[];

export type ProductWhereTypeOneOfEquals = typeof ProductWhereTypeOneOfEquals[keyof typeof ProductWhereTypeOneOfEquals];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductWhereTypeOneOfEquals = {
  PURCHASE: 'PURCHASE',
  MEMBERSHIP: 'MEMBERSHIP',
  HIDDEN: 'HIDDEN',
} as const;

export type ProductWhereTypeOneOf = {
  equals?: ProductWhereTypeOneOfEquals;
  in?: ProductWhereTypeOneOfIn;
  notIn?: ProductWhereTypeOneOfNotIn;
  not?: ProductWhereTypeOneOfNot;
} | null;

export type ProductWhereNameOneOfNot = string | Name;

export type ProductWhereNameOneOfMode = typeof ProductWhereNameOneOfMode[keyof typeof ProductWhereNameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductWhereNameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ProductWhereNameOneOfNotIn = string | string[];

export type ProductWhereNameOneOfIn = string | string[];

export type ProductWhereNameOneOf = {
  equals?: string | null;
  in?: ProductWhereNameOneOfIn;
  notIn?: ProductWhereNameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ProductWhereNameOneOfMode;
  not?: ProductWhereNameOneOfNot;
};

export type ProductWhereName = string | ProductWhereNameOneOf;

export type ProductWhereId = string | ProductWhereIdOneOf;

export type ProductWhereIdOneOfNot = string | Id;

export type ProductWhereIdOneOfMode = typeof ProductWhereIdOneOfMode[keyof typeof ProductWhereIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductWhereIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type ProductWhereIdOneOfNotIn = string | string[];

export type ProductWhereIdOneOfIn = string | string[];

export type ProductWhereIdOneOf = {
  equals?: string | null;
  in?: ProductWhereIdOneOfIn;
  notIn?: ProductWhereIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: ProductWhereIdOneOfMode;
  not?: ProductWhereIdOneOfNot;
};

export type ProductWhereNot = ProductWhere | ProductWhere[];

export type ProductWhereOr = ProductWhere | ProductWhere[];

export type ProductWhereAnd = ProductWhere | ProductWhere[];

export type Products = {
  items?: Product[];
};

export type ProductFiles = Files;

export type ProductVariantsProperty = ProductVariants;

export type ProductCategories = Categories;

export type ProductMembershipTier = MembershipTier;

export type ProductItem = Item;

export type ProductStatus = typeof ProductStatus[keyof typeof ProductStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductStatus = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
} as const;

export type ProductType = typeof ProductType[keyof typeof ProductType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductType = {
  PURCHASE: 'PURCHASE',
  MEMBERSHIP: 'MEMBERSHIP',
  HIDDEN: 'HIDDEN',
} as const;

export type Product = {
  id?: string | null;
  name?: string | null;
  type?: ProductType;
  itemId?: string | null;
  description?: string | null;
  pageData?: unknown;
  slug?: string | null;
  status?: ProductStatus;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  item?: ProductItem;
  membershipTier?: ProductMembershipTier;
  categories?: ProductCategories;
  variants?: ProductVariantsProperty;
  files?: ProductFiles;
  _count?: unknown;
};

export type ProductIncludeVariants = boolean | ProductIncludeVariantsOneOf;

export type ProductIncludeVariantsOneOfInclude = ProductVariantInclude;

export type ProductIncludeVariantsOneOfSelect = ProductVariantSelect;

export type ProductIncludeVariantsOneOf = {
  select?: ProductIncludeVariantsOneOfSelect;
  include?: ProductIncludeVariantsOneOfInclude;
};

export type ProductIncludeMembershipTier = boolean | ProductIncludeMembershipTierOneOf;

export type ProductInclude = {
  item?: boolean | null;
  membershipTier?: ProductIncludeMembershipTier;
  categories?: boolean | null;
  variants?: ProductIncludeVariants;
  files?: boolean | null;
};

export type ProductIncludeMembershipTierOneOfInclude = MembershipTierInclude;

export type ProductIncludeMembershipTierOneOfSelect = MembershipTierSelect;

export type ProductIncludeMembershipTierOneOf = {
  select?: ProductIncludeMembershipTierOneOfSelect;
  include?: ProductIncludeMembershipTierOneOfInclude;
};

export type ProductSelect = {
  id?: boolean | null;
  name?: boolean | null;
  type?: boolean | null;
  itemId?: boolean | null;
  description?: boolean | null;
  pageData?: boolean | null;
  slug?: boolean | null;
  status?: boolean | null;
  createdAt?: boolean | null;
  updatedAt?: boolean | null;
  deletedAt?: boolean | null;
  item?: boolean | null;
  membershipTier?: boolean | null;
  categories?: boolean | null;
  variants?: boolean | null;
  files?: boolean | null;
  _count?: boolean | null;
};

export type GetCalculateMembershipOrderPricingByIdResponseBodySchemaDiscount = {
  amountEx: number;
  amountGst: number;
  labels: string[];
};

export type GetCalculateMembershipOrderPricingByIdResponseBodySchemaTotal = {
  amountEx: number;
  amountGst: number;
};

export type GetCalculateMembershipOrderPricingByIdResponseBodySchemaShipping = {
  amountEx: number;
  amountGst: number;
};

export type GetCalculateMembershipOrderPricingByIdResponseBodySchemaSubscription = {
  amountEx: number;
  amountGst: number;
};

export type GetCalculateMembershipOrderPricingByIdResponseBodySchemaAdditionsTotal = {
  amountEx: number;
  amountGst: number;
};

export type GetCalculateMembershipOrderPricingByIdResponseBodySchema = {
  additionsTotal: GetCalculateMembershipOrderPricingByIdResponseBodySchemaAdditionsTotal;
  subscription: GetCalculateMembershipOrderPricingByIdResponseBodySchemaSubscription;
  shipping: GetCalculateMembershipOrderPricingByIdResponseBodySchemaShipping;
  total: GetCalculateMembershipOrderPricingByIdResponseBodySchemaTotal;
  discount: GetCalculateMembershipOrderPricingByIdResponseBodySchemaDiscount;
};

export type PostBulkConvertMembershipOrdersResponseBodySchemaErrorsItem = {
  id: string;
  error: string;
};

export type PostBulkConvertMembershipOrdersResponseBodySchema = {
  convertedIds: string[];
  errors: PostBulkConvertMembershipOrdersResponseBodySchemaErrorsItem[];
};

export type PostBulkGenerateMembershipOrdersResponseBodySchemaErrorsItem = {
  id: string;
  error: string;
};

export type PostBulkGenerateMembershipOrdersResponseBodySchema = {
  generatedIds: string[];
  errors: PostBulkGenerateMembershipOrdersResponseBodySchemaErrorsItem[];
};

export type MembershipOfferingItemUpdateMembershipOffering = {
  connect: MembershipOfferingUniqueWhere;
} | null;

export type MembershipOfferingItemUpdateMembershipOfferingCategory = {
  connect: MembershipOfferingCategoryUniqueWhere;
} | null;

export type MembershipOfferingItemUpdateItem = {
  connect: ItemUniqueWhere;
} | null;

export type MembershipOfferingItemUpdate = {
  id?: string | null;
  quantity?: number | null;
  isSelected?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  item?: MembershipOfferingItemUpdateItem;
  membershipOfferingCategory?: MembershipOfferingItemUpdateMembershipOfferingCategory;
  membershipOffering?: MembershipOfferingItemUpdateMembershipOffering;
};

export type MembershipOfferingItemCreateMembershipOffering = {
  connect: MembershipOfferingUniqueWhere;
};

export type MembershipOfferingItemCreateMembershipOfferingCategory = {
  connect: MembershipOfferingCategoryUniqueWhere;
};

export type MembershipOfferingItemCreateItem = {
  connect: ItemUniqueWhere;
};

export type MembershipOfferingItemCreate = {
  id?: string | null;
  quantity: number;
  isSelected?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  item: MembershipOfferingItemCreateItem;
  membershipOfferingCategory: MembershipOfferingItemCreateMembershipOfferingCategory;
  membershipOffering: MembershipOfferingItemCreateMembershipOffering;
};

export type MembershipOfferingItemUniqueWhere = {
  id?: string | null;
};

export type MembershipOfferingItemWhereMembershipOffering =
  | MembershipOfferingWhere
  | MembershipOfferingItemWhereMembershipOfferingOneOf;

export type MembershipOfferingItemWhere = {
  AND?: MembershipOfferingItemWhereAnd;
  OR?: MembershipOfferingItemWhereOr;
  NOT?: MembershipOfferingItemWhereNot;
  id?: MembershipOfferingItemWhereId;
  membershipOfferingId?: MembershipOfferingItemWhereMembershipOfferingId;
  membershipOfferingCategoryId?: MembershipOfferingItemWhereMembershipOfferingCategoryId;
  itemId?: MembershipOfferingItemWhereItemId;
  quantity?: MembershipOfferingItemWhereQuantity;
  isSelected?: MembershipOfferingItemWhereIsSelected;
  createdAt?: MembershipOfferingItemWhereCreatedAt;
  updatedAt?: MembershipOfferingItemWhereUpdatedAt;
  deletedAt?: MembershipOfferingItemWhereDeletedAt;
  item?: MembershipOfferingItemWhereItem;
  membershipOfferingCategory?: MembershipOfferingItemWhereMembershipOfferingCategory;
  membershipOffering?: MembershipOfferingItemWhereMembershipOffering;
};

export type MembershipOfferingItemWhereMembershipOfferingOneOfIsNot = MembershipOfferingWhere;

export type MembershipOfferingItemWhereMembershipOfferingOneOfIs = MembershipOfferingWhere;

export type MembershipOfferingItemWhereMembershipOfferingOneOf = {
  is?: MembershipOfferingItemWhereMembershipOfferingOneOfIs;
  isNot?: MembershipOfferingItemWhereMembershipOfferingOneOfIsNot;
};

export type MembershipOfferingItemWhereMembershipOfferingCategoryOneOfIsNot = MembershipOfferingCategoryWhere;

export type MembershipOfferingItemWhereMembershipOfferingCategoryOneOfIs = MembershipOfferingCategoryWhere;

export type MembershipOfferingItemWhereMembershipOfferingCategoryOneOf = {
  is?: MembershipOfferingItemWhereMembershipOfferingCategoryOneOfIs;
  isNot?: MembershipOfferingItemWhereMembershipOfferingCategoryOneOfIsNot;
};

export type MembershipOfferingItemWhereMembershipOfferingCategory =
  | MembershipOfferingCategoryWhere
  | MembershipOfferingItemWhereMembershipOfferingCategoryOneOf;

export type MembershipOfferingItemWhereItemOneOfIsNot = ItemWhere;

export type MembershipOfferingItemWhereItemOneOfIs = ItemWhere;

export type MembershipOfferingItemWhereItemOneOf = {
  is?: MembershipOfferingItemWhereItemOneOfIs;
  isNot?: MembershipOfferingItemWhereItemOneOfIsNot;
};

export type MembershipOfferingItemWhereItem = ItemWhere | MembershipOfferingItemWhereItemOneOf;

export type MembershipOfferingItemWhereDeletedAt = string | MembershipOfferingItemWhereDeletedAtOneOf;

export type MembershipOfferingItemWhereDeletedAtOneOfNot = string | DeletedAt;

export type MembershipOfferingItemWhereDeletedAtOneOfNotIn = string | string[];

export type MembershipOfferingItemWhereDeletedAtOneOfIn = string | string[];

export type MembershipOfferingItemWhereDeletedAtOneOfEquals = string;

export type MembershipOfferingItemWhereDeletedAtOneOf = {
  equals?: MembershipOfferingItemWhereDeletedAtOneOfEquals;
  in?: MembershipOfferingItemWhereDeletedAtOneOfIn;
  notIn?: MembershipOfferingItemWhereDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipOfferingItemWhereDeletedAtOneOfNot;
};

export type MembershipOfferingItemWhereUpdatedAtOneOfNot = string | UpdatedAt;

export type MembershipOfferingItemWhereUpdatedAtOneOfNotIn = string | string[];

export type MembershipOfferingItemWhereUpdatedAtOneOfIn = string | string[];

export type MembershipOfferingItemWhereUpdatedAtOneOf = {
  equals?: string | null;
  in?: MembershipOfferingItemWhereUpdatedAtOneOfIn;
  notIn?: MembershipOfferingItemWhereUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipOfferingItemWhereUpdatedAtOneOfNot;
};

export type MembershipOfferingItemWhereUpdatedAt = string | MembershipOfferingItemWhereUpdatedAtOneOf;

export type MembershipOfferingItemWhereCreatedAt = string | MembershipOfferingItemWhereCreatedAtOneOf;

export type MembershipOfferingItemWhereCreatedAtOneOfNot = string | CreatedAt;

export type MembershipOfferingItemWhereCreatedAtOneOfNotIn = string | string[];

export type MembershipOfferingItemWhereCreatedAtOneOfIn = string | string[];

export type MembershipOfferingItemWhereCreatedAtOneOf = {
  equals?: string | null;
  in?: MembershipOfferingItemWhereCreatedAtOneOfIn;
  notIn?: MembershipOfferingItemWhereCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipOfferingItemWhereCreatedAtOneOfNot;
};

export type MembershipOfferingItemWhereIsSelectedOneOfNot = boolean | IsSelected;

export type MembershipOfferingItemWhereIsSelectedOneOf = {
  equals?: boolean | null;
  not?: MembershipOfferingItemWhereIsSelectedOneOfNot;
};

export type MembershipOfferingItemWhereIsSelected = boolean | MembershipOfferingItemWhereIsSelectedOneOf;

export type MembershipOfferingItemWhereQuantity = number | MembershipOfferingItemWhereQuantityOneOf;

export type MembershipOfferingItemWhereQuantityOneOfNotOneOf = null;

export type MembershipOfferingItemWhereQuantityOneOfNot =
  | MembershipOfferingItemWhereQuantityOneOfNotOneOf
  | number
  | Quantity;

export type MembershipOfferingItemWhereQuantityOneOfNotIn = number | number[];

export type MembershipOfferingItemWhereQuantityOneOfIn = number | number[];

export type MembershipOfferingItemWhereQuantityOneOf = {
  equals?: number | null;
  in?: MembershipOfferingItemWhereQuantityOneOfIn;
  notIn?: MembershipOfferingItemWhereQuantityOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: MembershipOfferingItemWhereQuantityOneOfNot;
};

export type MembershipOfferingItemWhereItemIdOneOfNot = string | ItemId;

export type MembershipOfferingItemWhereItemIdOneOfMode =
  typeof MembershipOfferingItemWhereItemIdOneOfMode[keyof typeof MembershipOfferingItemWhereItemIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOfferingItemWhereItemIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipOfferingItemWhereItemIdOneOfNotIn = string | string[];

export type MembershipOfferingItemWhereItemIdOneOfIn = string | string[];

export type MembershipOfferingItemWhereItemIdOneOf = {
  equals?: string | null;
  in?: MembershipOfferingItemWhereItemIdOneOfIn;
  notIn?: MembershipOfferingItemWhereItemIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipOfferingItemWhereItemIdOneOfMode;
  not?: MembershipOfferingItemWhereItemIdOneOfNot;
};

export type MembershipOfferingItemWhereItemId = string | MembershipOfferingItemWhereItemIdOneOf;

export type MembershipOfferingItemWhereMembershipOfferingCategoryId =
  | string
  | MembershipOfferingItemWhereMembershipOfferingCategoryIdOneOf;

export type MembershipOfferingItemWhereMembershipOfferingCategoryIdOneOfNot = string | MembershipOfferingCategoryId;

export type MembershipOfferingItemWhereMembershipOfferingCategoryIdOneOfMode =
  typeof MembershipOfferingItemWhereMembershipOfferingCategoryIdOneOfMode[keyof typeof MembershipOfferingItemWhereMembershipOfferingCategoryIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOfferingItemWhereMembershipOfferingCategoryIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipOfferingItemWhereMembershipOfferingCategoryIdOneOfNotIn = string | string[];

export type MembershipOfferingItemWhereMembershipOfferingCategoryIdOneOfIn = string | string[];

export type MembershipOfferingItemWhereMembershipOfferingCategoryIdOneOf = {
  equals?: string | null;
  in?: MembershipOfferingItemWhereMembershipOfferingCategoryIdOneOfIn;
  notIn?: MembershipOfferingItemWhereMembershipOfferingCategoryIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipOfferingItemWhereMembershipOfferingCategoryIdOneOfMode;
  not?: MembershipOfferingItemWhereMembershipOfferingCategoryIdOneOfNot;
};

export type MembershipOfferingItemWhereMembershipOfferingId =
  | string
  | MembershipOfferingItemWhereMembershipOfferingIdOneOf;

export type MembershipOfferingItemWhereMembershipOfferingIdOneOfNot = string | MembershipOfferingId;

export type MembershipOfferingItemWhereMembershipOfferingIdOneOfMode =
  typeof MembershipOfferingItemWhereMembershipOfferingIdOneOfMode[keyof typeof MembershipOfferingItemWhereMembershipOfferingIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOfferingItemWhereMembershipOfferingIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipOfferingItemWhereMembershipOfferingIdOneOfNotIn = string | string[];

export type MembershipOfferingItemWhereMembershipOfferingIdOneOfIn = string | string[];

export type MembershipOfferingItemWhereMembershipOfferingIdOneOf = {
  equals?: string | null;
  in?: MembershipOfferingItemWhereMembershipOfferingIdOneOfIn;
  notIn?: MembershipOfferingItemWhereMembershipOfferingIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipOfferingItemWhereMembershipOfferingIdOneOfMode;
  not?: MembershipOfferingItemWhereMembershipOfferingIdOneOfNot;
};

export type MembershipOfferingItemWhereId = string | MembershipOfferingItemWhereIdOneOf;

export type MembershipOfferingItemWhereIdOneOfNot = string | Id;

export type MembershipOfferingItemWhereIdOneOfMode =
  typeof MembershipOfferingItemWhereIdOneOfMode[keyof typeof MembershipOfferingItemWhereIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOfferingItemWhereIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipOfferingItemWhereIdOneOfNotIn = string | string[];

export type MembershipOfferingItemWhereIdOneOfIn = string | string[];

export type MembershipOfferingItemWhereIdOneOf = {
  equals?: string | null;
  in?: MembershipOfferingItemWhereIdOneOfIn;
  notIn?: MembershipOfferingItemWhereIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipOfferingItemWhereIdOneOfMode;
  not?: MembershipOfferingItemWhereIdOneOfNot;
};

export type MembershipOfferingItemWhereNot = MembershipOfferingItemWhere | MembershipOfferingItemWhere[];

export type MembershipOfferingItemWhereOr = MembershipOfferingItemWhere | MembershipOfferingItemWhere[];

export type MembershipOfferingItemWhereAnd = MembershipOfferingItemWhere | MembershipOfferingItemWhere[];

export type MembershipOfferingItems = {
  items?: MembershipOfferingItem[];
};

export type MembershipOfferingItemMembershipOffering = MembershipOffering;

export type MembershipOfferingItemMembershipOfferingCategory = MembershipOfferingCategory;

export type MembershipOfferingItemItem = Item;

export type MembershipOfferingItem = {
  id?: string | null;
  membershipOfferingId?: string | null;
  membershipOfferingCategoryId?: string | null;
  itemId?: string | null;
  quantity?: number | null;
  isSelected?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  item?: MembershipOfferingItemItem;
  membershipOfferingCategory?: MembershipOfferingItemMembershipOfferingCategory;
  membershipOffering?: MembershipOfferingItemMembershipOffering;
};

export type MembershipOfferingItemIncludeItemOneOf = {
  select?: ItemSelect;
  include?: ItemInclude;
};

export type MembershipOfferingItemIncludeItem = boolean | MembershipOfferingItemIncludeItemOneOf;

export type MembershipOfferingItemInclude = {
  membershipOfferingCategory?: boolean | null;
  membershipOffering?: boolean | null;
  item?: MembershipOfferingItemIncludeItem;
};

export type MembershipOfferingItemSelect = {
  id?: boolean | null;
  membershipOfferingId?: boolean | null;
  membershipOfferingCategoryId?: boolean | null;
  itemId?: boolean | null;
  quantity?: boolean | null;
  isSelected?: boolean | null;
  createdAt?: boolean | null;
  updatedAt?: boolean | null;
  deletedAt?: boolean | null;
  item?: boolean | null;
  membershipOfferingCategory?: boolean | null;
  membershipOffering?: boolean | null;
};

export type MembershipOfferingCategoryUpdateMembershipOfferingItemsConnect =
  | MembershipOfferingItemUniqueWhere
  | MembershipOfferingItemUniqueWhere[];

export type MembershipOfferingCategoryUpdateMembershipOfferingItems = {
  connect: MembershipOfferingCategoryUpdateMembershipOfferingItemsConnect;
} | null;

export type MembershipOfferingCategoryUpdate = {
  id?: string | null;
  name?: string | null;
  slug?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  membershipOfferingItems?: MembershipOfferingCategoryUpdateMembershipOfferingItems;
};

export type MembershipOfferingCategoryCreateMembershipOfferingItemsConnect =
  | MembershipOfferingItemUniqueWhere
  | MembershipOfferingItemUniqueWhere[];

export type MembershipOfferingCategoryCreateMembershipOfferingItems = {
  connect: MembershipOfferingCategoryCreateMembershipOfferingItemsConnect;
} | null;

export type MembershipOfferingCategoryCreate = {
  id?: string | null;
  name: string;
  slug?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  membershipOfferingItems?: MembershipOfferingCategoryCreateMembershipOfferingItems;
};

export type MembershipOfferingCategoryUniqueWhere = {
  id?: string | null;
};

export type MembershipOfferingCategoryWhereMembershipOfferingItemsNone = MembershipOfferingItemWhere;

export type MembershipOfferingCategoryWhereMembershipOfferingItemsEvery = MembershipOfferingItemWhere;

export type MembershipOfferingCategoryWhereMembershipOfferingItemsSome = MembershipOfferingItemWhere;

export type MembershipOfferingCategoryWhereMembershipOfferingItems = {
  some?: MembershipOfferingCategoryWhereMembershipOfferingItemsSome;
  every?: MembershipOfferingCategoryWhereMembershipOfferingItemsEvery;
  none?: MembershipOfferingCategoryWhereMembershipOfferingItemsNone;
} | null;

export type MembershipOfferingCategoryWhereDeletedAt = string | MembershipOfferingCategoryWhereDeletedAtOneOf;

export type MembershipOfferingCategoryWhereDeletedAtOneOfNot = string | DeletedAt;

export type MembershipOfferingCategoryWhereDeletedAtOneOfNotIn = string | string[];

export type MembershipOfferingCategoryWhereDeletedAtOneOfIn = string | string[];

export type MembershipOfferingCategoryWhereDeletedAtOneOfEquals = string;

export type MembershipOfferingCategoryWhereDeletedAtOneOf = {
  equals?: MembershipOfferingCategoryWhereDeletedAtOneOfEquals;
  in?: MembershipOfferingCategoryWhereDeletedAtOneOfIn;
  notIn?: MembershipOfferingCategoryWhereDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipOfferingCategoryWhereDeletedAtOneOfNot;
};

export type MembershipOfferingCategoryWhereUpdatedAt = string | MembershipOfferingCategoryWhereUpdatedAtOneOf;

export type MembershipOfferingCategoryWhereUpdatedAtOneOfNot = string | UpdatedAt;

export type MembershipOfferingCategoryWhereUpdatedAtOneOfNotIn = string | string[];

export type MembershipOfferingCategoryWhereUpdatedAtOneOfIn = string | string[];

export type MembershipOfferingCategoryWhereUpdatedAtOneOf = {
  equals?: string | null;
  in?: MembershipOfferingCategoryWhereUpdatedAtOneOfIn;
  notIn?: MembershipOfferingCategoryWhereUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipOfferingCategoryWhereUpdatedAtOneOfNot;
};

export type MembershipOfferingCategoryWhereCreatedAt = string | MembershipOfferingCategoryWhereCreatedAtOneOf;

export type MembershipOfferingCategoryWhereCreatedAtOneOfNot = string | CreatedAt;

export type MembershipOfferingCategoryWhereCreatedAtOneOfNotIn = string | string[];

export type MembershipOfferingCategoryWhereCreatedAtOneOfIn = string | string[];

export type MembershipOfferingCategoryWhereCreatedAtOneOf = {
  equals?: string | null;
  in?: MembershipOfferingCategoryWhereCreatedAtOneOfIn;
  notIn?: MembershipOfferingCategoryWhereCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipOfferingCategoryWhereCreatedAtOneOfNot;
};

export type MembershipOfferingCategoryWhereSlug = string | MembershipOfferingCategoryWhereSlugOneOf;

export type MembershipOfferingCategoryWhereSlugOneOfNot = string | Slug;

export type MembershipOfferingCategoryWhereSlugOneOfMode =
  typeof MembershipOfferingCategoryWhereSlugOneOfMode[keyof typeof MembershipOfferingCategoryWhereSlugOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOfferingCategoryWhereSlugOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipOfferingCategoryWhereSlugOneOfNotIn = string | string[];

export type MembershipOfferingCategoryWhereSlugOneOfIn = string | string[];

export type MembershipOfferingCategoryWhereSlugOneOf = {
  equals?: string | null;
  in?: MembershipOfferingCategoryWhereSlugOneOfIn;
  notIn?: MembershipOfferingCategoryWhereSlugOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipOfferingCategoryWhereSlugOneOfMode;
  not?: MembershipOfferingCategoryWhereSlugOneOfNot;
};

export type MembershipOfferingCategoryWhereName = string | MembershipOfferingCategoryWhereNameOneOf;

export type MembershipOfferingCategoryWhereNameOneOfNot = string | Name;

export type MembershipOfferingCategoryWhereNameOneOfMode =
  typeof MembershipOfferingCategoryWhereNameOneOfMode[keyof typeof MembershipOfferingCategoryWhereNameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOfferingCategoryWhereNameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipOfferingCategoryWhereNameOneOfNotIn = string | string[];

export type MembershipOfferingCategoryWhereNameOneOfIn = string | string[];

export type MembershipOfferingCategoryWhereNameOneOf = {
  equals?: string | null;
  in?: MembershipOfferingCategoryWhereNameOneOfIn;
  notIn?: MembershipOfferingCategoryWhereNameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipOfferingCategoryWhereNameOneOfMode;
  not?: MembershipOfferingCategoryWhereNameOneOfNot;
};

export type MembershipOfferingCategoryWhereIdOneOfNot = string | Id;

export type MembershipOfferingCategoryWhereIdOneOfMode =
  typeof MembershipOfferingCategoryWhereIdOneOfMode[keyof typeof MembershipOfferingCategoryWhereIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOfferingCategoryWhereIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipOfferingCategoryWhereIdOneOfNotIn = string | string[];

export type MembershipOfferingCategoryWhereIdOneOfIn = string | string[];

export type MembershipOfferingCategoryWhereIdOneOf = {
  equals?: string | null;
  in?: MembershipOfferingCategoryWhereIdOneOfIn;
  notIn?: MembershipOfferingCategoryWhereIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipOfferingCategoryWhereIdOneOfMode;
  not?: MembershipOfferingCategoryWhereIdOneOfNot;
};

export type MembershipOfferingCategoryWhereId = string | MembershipOfferingCategoryWhereIdOneOf;

export type MembershipOfferingCategoryWhereNot = MembershipOfferingCategoryWhere | MembershipOfferingCategoryWhere[];

export type MembershipOfferingCategoryWhereOr = MembershipOfferingCategoryWhere | MembershipOfferingCategoryWhere[];

export type MembershipOfferingCategoryWhereAnd = MembershipOfferingCategoryWhere | MembershipOfferingCategoryWhere[];

export type MembershipOfferingCategoryWhere = {
  AND?: MembershipOfferingCategoryWhereAnd;
  OR?: MembershipOfferingCategoryWhereOr;
  NOT?: MembershipOfferingCategoryWhereNot;
  id?: MembershipOfferingCategoryWhereId;
  name?: MembershipOfferingCategoryWhereName;
  slug?: MembershipOfferingCategoryWhereSlug;
  createdAt?: MembershipOfferingCategoryWhereCreatedAt;
  updatedAt?: MembershipOfferingCategoryWhereUpdatedAt;
  deletedAt?: MembershipOfferingCategoryWhereDeletedAt;
  membershipOfferingItems?: MembershipOfferingCategoryWhereMembershipOfferingItems;
};

export type MembershipOfferingCategoryMembershipOfferingItems = MembershipOfferingItems;

export type MembershipOfferingCategory = {
  id?: string | null;
  name?: string | null;
  slug?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  membershipOfferingItems?: MembershipOfferingCategoryMembershipOfferingItems;
  _count?: unknown;
};

export type MembershipOfferingCategories = {
  items?: MembershipOfferingCategory[];
};

export type MembershipOfferingCategoryInclude = {
  membershipOfferingItems?: boolean | null;
};

export type MembershipOfferingCategorySelect = {
  id?: boolean | null;
  name?: boolean | null;
  slug?: boolean | null;
  createdAt?: boolean | null;
  updatedAt?: boolean | null;
  deletedAt?: boolean | null;
  membershipOfferingItems?: boolean | null;
  _count?: boolean | null;
};

export type MembershipOfferingUpdateMembershipOfferingItemsConnect =
  | MembershipOfferingItemUniqueWhere
  | MembershipOfferingItemUniqueWhere[];

export type MembershipOfferingUpdateMembershipOfferingItems = {
  connect: MembershipOfferingUpdateMembershipOfferingItemsConnect;
} | null;

export type MembershipOfferingUpdateMembershipTier = {
  connect: MembershipTierUniqueWhere;
} | null;

export type MembershipOfferingUpdateRules = { [key: string]: any };

export type MembershipOfferingUpdateMonth =
  typeof MembershipOfferingUpdateMonth[keyof typeof MembershipOfferingUpdateMonth];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOfferingUpdateMonth = {
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
} as const;

export type MembershipOfferingUpdate = {
  id?: string | null;
  year?: string | null;
  month?: MembershipOfferingUpdateMonth;
  rules?: MembershipOfferingUpdateRules;
  createdAt?: string | null;
  updatedAt?: string | null;
  membershipTier?: MembershipOfferingUpdateMembershipTier;
  membershipOfferingItems?: MembershipOfferingUpdateMembershipOfferingItems;
};

export type MembershipOfferingCreateMembershipOfferingItemsConnect =
  | MembershipOfferingItemUniqueWhere
  | MembershipOfferingItemUniqueWhere[];

export type MembershipOfferingCreateMembershipOfferingItems = {
  connect: MembershipOfferingCreateMembershipOfferingItemsConnect;
} | null;

export type MembershipOfferingCreateMembershipTier = {
  connect: MembershipTierUniqueWhere;
};

export type MembershipOfferingCreateRules = { [key: string]: any };

export type MembershipOfferingCreateMonth =
  typeof MembershipOfferingCreateMonth[keyof typeof MembershipOfferingCreateMonth];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOfferingCreateMonth = {
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
} as const;

export type MembershipOfferingCreate = {
  id?: string | null;
  year: string;
  month: MembershipOfferingCreateMonth;
  rules: MembershipOfferingCreateRules;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  membershipTier: MembershipOfferingCreateMembershipTier;
  membershipOfferingItems?: MembershipOfferingCreateMembershipOfferingItems;
};

export type MembershipOfferingUniqueWhere = {
  id?: string | null;
};

export type MembershipOfferingWhereMembershipOfferingItemsNone = MembershipOfferingItemWhere;

export type MembershipOfferingWhereMembershipOfferingItemsEvery = MembershipOfferingItemWhere;

export type MembershipOfferingWhereMembershipOfferingItemsSome = MembershipOfferingItemWhere;

export type MembershipOfferingWhereMembershipOfferingItems = {
  some?: MembershipOfferingWhereMembershipOfferingItemsSome;
  every?: MembershipOfferingWhereMembershipOfferingItemsEvery;
  none?: MembershipOfferingWhereMembershipOfferingItemsNone;
} | null;

export type MembershipOfferingWhereMembershipTier = MembershipTierWhere | MembershipOfferingWhereMembershipTierOneOf;

export type MembershipOfferingWhere = {
  AND?: MembershipOfferingWhereAnd;
  OR?: MembershipOfferingWhereOr;
  NOT?: MembershipOfferingWhereNot;
  id?: MembershipOfferingWhereId;
  year?: MembershipOfferingWhereYear;
  month?: MembershipOfferingWhereMonth;
  rules?: MembershipOfferingWhereRules;
  membershipTierId?: MembershipOfferingWhereMembershipTierId;
  createdAt?: MembershipOfferingWhereCreatedAt;
  updatedAt?: MembershipOfferingWhereUpdatedAt;
  deletedAt?: MembershipOfferingWhereDeletedAt;
  membershipTier?: MembershipOfferingWhereMembershipTier;
  membershipOfferingItems?: MembershipOfferingWhereMembershipOfferingItems;
};

export type MembershipOfferingWhereMembershipTierOneOfIsNot = MembershipTierWhere;

export type MembershipOfferingWhereMembershipTierOneOfIs = MembershipTierWhere;

export type MembershipOfferingWhereMembershipTierOneOf = {
  is?: MembershipOfferingWhereMembershipTierOneOfIs;
  isNot?: MembershipOfferingWhereMembershipTierOneOfIsNot;
};

export type MembershipOfferingWhereDeletedAtOneOfNot = string | DeletedAt;

export type MembershipOfferingWhereDeletedAtOneOfNotIn = string | string[];

export type MembershipOfferingWhereDeletedAtOneOfIn = string | string[];

export type MembershipOfferingWhereDeletedAtOneOfEquals = string;

export type MembershipOfferingWhereDeletedAtOneOf = {
  equals?: MembershipOfferingWhereDeletedAtOneOfEquals;
  in?: MembershipOfferingWhereDeletedAtOneOfIn;
  notIn?: MembershipOfferingWhereDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipOfferingWhereDeletedAtOneOfNot;
};

export type MembershipOfferingWhereDeletedAt = string | MembershipOfferingWhereDeletedAtOneOf;

export type MembershipOfferingWhereUpdatedAtOneOfNot = string | UpdatedAt;

export type MembershipOfferingWhereUpdatedAtOneOfNotIn = string | string[];

export type MembershipOfferingWhereUpdatedAtOneOfIn = string | string[];

export type MembershipOfferingWhereUpdatedAtOneOf = {
  equals?: string | null;
  in?: MembershipOfferingWhereUpdatedAtOneOfIn;
  notIn?: MembershipOfferingWhereUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipOfferingWhereUpdatedAtOneOfNot;
};

export type MembershipOfferingWhereUpdatedAt = string | MembershipOfferingWhereUpdatedAtOneOf;

export type MembershipOfferingWhereCreatedAt = string | MembershipOfferingWhereCreatedAtOneOf;

export type MembershipOfferingWhereCreatedAtOneOfNot = string | CreatedAt;

export type MembershipOfferingWhereCreatedAtOneOfNotIn = string | string[];

export type MembershipOfferingWhereCreatedAtOneOfIn = string | string[];

export type MembershipOfferingWhereCreatedAtOneOf = {
  equals?: string | null;
  in?: MembershipOfferingWhereCreatedAtOneOfIn;
  notIn?: MembershipOfferingWhereCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipOfferingWhereCreatedAtOneOfNot;
};

export type MembershipOfferingWhereMembershipTierIdOneOfNot = string | MembershipTierId;

export type MembershipOfferingWhereMembershipTierIdOneOfMode =
  typeof MembershipOfferingWhereMembershipTierIdOneOfMode[keyof typeof MembershipOfferingWhereMembershipTierIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOfferingWhereMembershipTierIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipOfferingWhereMembershipTierIdOneOfNotIn = string | string[];

export type MembershipOfferingWhereMembershipTierIdOneOfIn = string | string[];

export type MembershipOfferingWhereMembershipTierIdOneOf = {
  equals?: string | null;
  in?: MembershipOfferingWhereMembershipTierIdOneOfIn;
  notIn?: MembershipOfferingWhereMembershipTierIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipOfferingWhereMembershipTierIdOneOfMode;
  not?: MembershipOfferingWhereMembershipTierIdOneOfNot;
};

export type MembershipOfferingWhereMembershipTierId = string | MembershipOfferingWhereMembershipTierIdOneOf;

export type MembershipOfferingWhereRulesOneOfPath = string | string[];

export type MembershipOfferingWhereRulesOneOf = {
  path?: MembershipOfferingWhereRulesOneOfPath;
  equals?: unknown;
  not?: unknown;
  string_contains?: string | null;
  string_starts_with?: string | null;
  string_ends_with?: string | null;
  array_contains?: unknown;
  array_starts_with?: unknown;
  array_ends_with?: unknown;
};

export type MembershipOfferingWhereRules = MembershipOfferingWhereRulesOneOf;

export type MembershipOfferingWhereMonthOneOfNot =
  | 'JANUARY'
  | 'FEBRUARY'
  | 'MARCH'
  | 'APRIL'
  | 'MAY'
  | 'JUNE'
  | 'JULY'
  | 'AUGUST'
  | 'SEPTEMBER'
  | 'OCTOBER'
  | 'NOVEMBER'
  | 'DECEMBER'
  | Month;

export type MembershipOfferingWhereMonthOneOf = {
  equals?: MembershipOfferingWhereMonthOneOfEquals;
  in?: MembershipOfferingWhereMonthOneOfIn;
  notIn?: MembershipOfferingWhereMonthOneOfNotIn;
  not?: MembershipOfferingWhereMonthOneOfNot;
} | null;

export type MembershipOfferingWhereMonth =
  | 'JANUARY'
  | 'FEBRUARY'
  | 'MARCH'
  | 'APRIL'
  | 'MAY'
  | 'JUNE'
  | 'JULY'
  | 'AUGUST'
  | 'SEPTEMBER'
  | 'OCTOBER'
  | 'NOVEMBER'
  | 'DECEMBER'
  | MembershipOfferingWhereMonthOneOf;

export type MembershipOfferingWhereMonthOneOfNotInOneOfItem =
  typeof MembershipOfferingWhereMonthOneOfNotInOneOfItem[keyof typeof MembershipOfferingWhereMonthOneOfNotInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOfferingWhereMonthOneOfNotInOneOfItem = {
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
} as const;

export type MembershipOfferingWhereMonthOneOfNotIn =
  | 'JANUARY'
  | 'FEBRUARY'
  | 'MARCH'
  | 'APRIL'
  | 'MAY'
  | 'JUNE'
  | 'JULY'
  | 'AUGUST'
  | 'SEPTEMBER'
  | 'OCTOBER'
  | 'NOVEMBER'
  | 'DECEMBER'
  | MembershipOfferingWhereMonthOneOfNotInOneOfItem[];

export type MembershipOfferingWhereMonthOneOfInOneOfItem =
  typeof MembershipOfferingWhereMonthOneOfInOneOfItem[keyof typeof MembershipOfferingWhereMonthOneOfInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOfferingWhereMonthOneOfInOneOfItem = {
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
} as const;

export type MembershipOfferingWhereMonthOneOfIn =
  | 'JANUARY'
  | 'FEBRUARY'
  | 'MARCH'
  | 'APRIL'
  | 'MAY'
  | 'JUNE'
  | 'JULY'
  | 'AUGUST'
  | 'SEPTEMBER'
  | 'OCTOBER'
  | 'NOVEMBER'
  | 'DECEMBER'
  | MembershipOfferingWhereMonthOneOfInOneOfItem[];

export type MembershipOfferingWhereMonthOneOfEquals =
  typeof MembershipOfferingWhereMonthOneOfEquals[keyof typeof MembershipOfferingWhereMonthOneOfEquals];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOfferingWhereMonthOneOfEquals = {
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
} as const;

export type MembershipOfferingWhereYear = string | MembershipOfferingWhereYearOneOf;

export type MembershipOfferingWhereYearOneOfNot = string | Year;

export type MembershipOfferingWhereYearOneOfMode =
  typeof MembershipOfferingWhereYearOneOfMode[keyof typeof MembershipOfferingWhereYearOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOfferingWhereYearOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipOfferingWhereYearOneOfNotIn = string | string[];

export type MembershipOfferingWhereYearOneOfIn = string | string[];

export type MembershipOfferingWhereYearOneOf = {
  equals?: string | null;
  in?: MembershipOfferingWhereYearOneOfIn;
  notIn?: MembershipOfferingWhereYearOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipOfferingWhereYearOneOfMode;
  not?: MembershipOfferingWhereYearOneOfNot;
};

export type MembershipOfferingWhereId = string | MembershipOfferingWhereIdOneOf;

export type MembershipOfferingWhereIdOneOfNot = string | Id;

export type MembershipOfferingWhereIdOneOfMode =
  typeof MembershipOfferingWhereIdOneOfMode[keyof typeof MembershipOfferingWhereIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOfferingWhereIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipOfferingWhereIdOneOfNotIn = string | string[];

export type MembershipOfferingWhereIdOneOfIn = string | string[];

export type MembershipOfferingWhereIdOneOf = {
  equals?: string | null;
  in?: MembershipOfferingWhereIdOneOfIn;
  notIn?: MembershipOfferingWhereIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipOfferingWhereIdOneOfMode;
  not?: MembershipOfferingWhereIdOneOfNot;
};

export type MembershipOfferingWhereNot = MembershipOfferingWhere | MembershipOfferingWhere[];

export type MembershipOfferingWhereOr = MembershipOfferingWhere | MembershipOfferingWhere[];

export type MembershipOfferingWhereAnd = MembershipOfferingWhere | MembershipOfferingWhere[];

export type MembershipOfferingMembershipOfferingItems = MembershipOfferingItems;

export type MembershipOfferingMembershipTier = MembershipTier;

export type MembershipOfferingRules = { [key: string]: any };

export type MembershipOfferingMonth = typeof MembershipOfferingMonth[keyof typeof MembershipOfferingMonth];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOfferingMonth = {
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
} as const;

export type MembershipOffering = {
  id?: string | null;
  year?: string | null;
  month?: MembershipOfferingMonth;
  rules?: MembershipOfferingRules;
  membershipTierId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  membershipTier?: MembershipOfferingMembershipTier;
  membershipOfferingItems?: MembershipOfferingMembershipOfferingItems;
  _count?: unknown;
};

export type MembershipOfferings = {
  items?: MembershipOffering[];
};

export type MembershipOfferingIncludeMembershipOfferingItemsOneOf = {
  select?: MembershipOfferingItemSelect;
  include?: MembershipOfferingItemInclude;
};

export type MembershipOfferingIncludeMembershipOfferingItems =
  | boolean
  | MembershipOfferingIncludeMembershipOfferingItemsOneOf;

export type MembershipOfferingInclude = {
  membershipTier?: boolean | null;
  membershipOfferingItems?: MembershipOfferingIncludeMembershipOfferingItems;
};

export type MembershipOfferingSelect = {
  id?: boolean | null;
  year?: boolean | null;
  month?: boolean | null;
  rules?: boolean | null;
  membershipTierId?: boolean | null;
  createdAt?: boolean | null;
  updatedAt?: boolean | null;
  deletedAt?: boolean | null;
  membershipTier?: boolean | null;
  membershipOfferingItems?: boolean | null;
  _count?: boolean | null;
};

export type MembershipOrderItemUpdateItem = {
  connect: ItemUniqueWhere;
} | null;

export type MembershipOrderItemUpdateMembershipOrder = {
  connect: MembershipOrderUniqueWhere;
} | null;

export type MembershipOrderItemUpdateType =
  typeof MembershipOrderItemUpdateType[keyof typeof MembershipOrderItemUpdateType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOrderItemUpdateType = {
  INCLUSION: 'INCLUSION',
  ADDITION: 'ADDITION',
} as const;

export type MembershipOrderItemUpdate = {
  id?: string | null;
  type?: MembershipOrderItemUpdateType;
  quantity?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  membershipOrder?: MembershipOrderItemUpdateMembershipOrder;
  item?: MembershipOrderItemUpdateItem;
};

export type MembershipOrderItemCreateItem = {
  connect: ItemUniqueWhere;
};

export type MembershipOrderItemCreateMembershipOrder = {
  connect: MembershipOrderUniqueWhere;
};

export type MembershipOrderItemCreateType =
  typeof MembershipOrderItemCreateType[keyof typeof MembershipOrderItemCreateType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOrderItemCreateType = {
  INCLUSION: 'INCLUSION',
  ADDITION: 'ADDITION',
} as const;

export type MembershipOrderItemCreate = {
  id?: string | null;
  type: MembershipOrderItemCreateType;
  quantity: number;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  membershipOrder: MembershipOrderItemCreateMembershipOrder;
  item: MembershipOrderItemCreateItem;
};

export type MembershipOrderItemUniqueWhere = {
  id?: string | null;
};

export type MembershipOrderItemWhere = {
  AND?: MembershipOrderItemWhereAnd;
  OR?: MembershipOrderItemWhereOr;
  NOT?: MembershipOrderItemWhereNot;
  id?: MembershipOrderItemWhereId;
  membershipOrderId?: MembershipOrderItemWhereMembershipOrderId;
  itemId?: MembershipOrderItemWhereItemId;
  type?: MembershipOrderItemWhereType;
  quantity?: MembershipOrderItemWhereQuantity;
  createdAt?: MembershipOrderItemWhereCreatedAt;
  updatedAt?: MembershipOrderItemWhereUpdatedAt;
  deletedAt?: MembershipOrderItemWhereDeletedAt;
  membershipOrder?: MembershipOrderItemWhereMembershipOrder;
  item?: MembershipOrderItemWhereItem;
};

export type MembershipOrderItemWhereItemOneOfIsNot = ItemWhere;

export type MembershipOrderItemWhereItemOneOfIs = ItemWhere;

export type MembershipOrderItemWhereItemOneOf = {
  is?: MembershipOrderItemWhereItemOneOfIs;
  isNot?: MembershipOrderItemWhereItemOneOfIsNot;
};

export type MembershipOrderItemWhereItem = ItemWhere | MembershipOrderItemWhereItemOneOf;

export type MembershipOrderItemWhereMembershipOrder =
  | MembershipOrderWhere
  | MembershipOrderItemWhereMembershipOrderOneOf;

export type MembershipOrderItemWhereMembershipOrderOneOfIsNot = MembershipOrderWhere;

export type MembershipOrderItemWhereMembershipOrderOneOfIs = MembershipOrderWhere;

export type MembershipOrderItemWhereMembershipOrderOneOf = {
  is?: MembershipOrderItemWhereMembershipOrderOneOfIs;
  isNot?: MembershipOrderItemWhereMembershipOrderOneOfIsNot;
};

export type MembershipOrderItemWhereDeletedAt = string | MembershipOrderItemWhereDeletedAtOneOf;

export type MembershipOrderItemWhereDeletedAtOneOfNot = string | DeletedAt;

export type MembershipOrderItemWhereDeletedAtOneOfNotIn = string | string[];

export type MembershipOrderItemWhereDeletedAtOneOfIn = string | string[];

export type MembershipOrderItemWhereDeletedAtOneOfEquals = string;

export type MembershipOrderItemWhereDeletedAtOneOf = {
  equals?: MembershipOrderItemWhereDeletedAtOneOfEquals;
  in?: MembershipOrderItemWhereDeletedAtOneOfIn;
  notIn?: MembershipOrderItemWhereDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipOrderItemWhereDeletedAtOneOfNot;
};

export type MembershipOrderItemWhereUpdatedAt = string | MembershipOrderItemWhereUpdatedAtOneOf;

export type MembershipOrderItemWhereUpdatedAtOneOfNot = string | UpdatedAt;

export type MembershipOrderItemWhereUpdatedAtOneOfNotIn = string | string[];

export type MembershipOrderItemWhereUpdatedAtOneOfIn = string | string[];

export type MembershipOrderItemWhereUpdatedAtOneOf = {
  equals?: string | null;
  in?: MembershipOrderItemWhereUpdatedAtOneOfIn;
  notIn?: MembershipOrderItemWhereUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipOrderItemWhereUpdatedAtOneOfNot;
};

export type MembershipOrderItemWhereCreatedAtOneOfNot = string | CreatedAt;

export type MembershipOrderItemWhereCreatedAtOneOfNotIn = string | string[];

export type MembershipOrderItemWhereCreatedAtOneOfIn = string | string[];

export type MembershipOrderItemWhereCreatedAtOneOf = {
  equals?: string | null;
  in?: MembershipOrderItemWhereCreatedAtOneOfIn;
  notIn?: MembershipOrderItemWhereCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipOrderItemWhereCreatedAtOneOfNot;
};

export type MembershipOrderItemWhereCreatedAt = string | MembershipOrderItemWhereCreatedAtOneOf;

export type MembershipOrderItemWhereQuantityOneOfNotOneOf = null;

export type MembershipOrderItemWhereQuantityOneOfNot =
  | MembershipOrderItemWhereQuantityOneOfNotOneOf
  | number
  | Quantity;

export type MembershipOrderItemWhereQuantityOneOfNotIn = number | number[];

export type MembershipOrderItemWhereQuantityOneOfIn = number | number[];

export type MembershipOrderItemWhereQuantityOneOf = {
  equals?: number | null;
  in?: MembershipOrderItemWhereQuantityOneOfIn;
  notIn?: MembershipOrderItemWhereQuantityOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: MembershipOrderItemWhereQuantityOneOfNot;
};

export type MembershipOrderItemWhereQuantity = number | MembershipOrderItemWhereQuantityOneOf;

export type MembershipOrderItemWhereType = 'INCLUSION' | 'ADDITION' | MembershipOrderItemWhereTypeOneOf;

export type MembershipOrderItemWhereTypeOneOfNot = 'INCLUSION' | 'ADDITION' | Type;

export type MembershipOrderItemWhereTypeOneOfNotInOneOfItem =
  typeof MembershipOrderItemWhereTypeOneOfNotInOneOfItem[keyof typeof MembershipOrderItemWhereTypeOneOfNotInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOrderItemWhereTypeOneOfNotInOneOfItem = {
  INCLUSION: 'INCLUSION',
  ADDITION: 'ADDITION',
} as const;

export type MembershipOrderItemWhereTypeOneOfNotIn =
  | 'INCLUSION'
  | 'ADDITION'
  | MembershipOrderItemWhereTypeOneOfNotInOneOfItem[];

export type MembershipOrderItemWhereTypeOneOf = {
  equals?: MembershipOrderItemWhereTypeOneOfEquals;
  in?: MembershipOrderItemWhereTypeOneOfIn;
  notIn?: MembershipOrderItemWhereTypeOneOfNotIn;
  not?: MembershipOrderItemWhereTypeOneOfNot;
} | null;

export type MembershipOrderItemWhereTypeOneOfInOneOfItem =
  typeof MembershipOrderItemWhereTypeOneOfInOneOfItem[keyof typeof MembershipOrderItemWhereTypeOneOfInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOrderItemWhereTypeOneOfInOneOfItem = {
  INCLUSION: 'INCLUSION',
  ADDITION: 'ADDITION',
} as const;

export type MembershipOrderItemWhereTypeOneOfIn =
  | 'INCLUSION'
  | 'ADDITION'
  | MembershipOrderItemWhereTypeOneOfInOneOfItem[];

export type MembershipOrderItemWhereTypeOneOfEquals =
  typeof MembershipOrderItemWhereTypeOneOfEquals[keyof typeof MembershipOrderItemWhereTypeOneOfEquals];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOrderItemWhereTypeOneOfEquals = {
  INCLUSION: 'INCLUSION',
  ADDITION: 'ADDITION',
} as const;

export type MembershipOrderItemWhereItemId = string | MembershipOrderItemWhereItemIdOneOf;

export type MembershipOrderItemWhereItemIdOneOfNot = string | ItemId;

export type MembershipOrderItemWhereItemIdOneOfMode =
  typeof MembershipOrderItemWhereItemIdOneOfMode[keyof typeof MembershipOrderItemWhereItemIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOrderItemWhereItemIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipOrderItemWhereItemIdOneOfNotIn = string | string[];

export type MembershipOrderItemWhereItemIdOneOfIn = string | string[];

export type MembershipOrderItemWhereItemIdOneOf = {
  equals?: string | null;
  in?: MembershipOrderItemWhereItemIdOneOfIn;
  notIn?: MembershipOrderItemWhereItemIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipOrderItemWhereItemIdOneOfMode;
  not?: MembershipOrderItemWhereItemIdOneOfNot;
};

export type MembershipOrderItemWhereMembershipOrderId = string | MembershipOrderItemWhereMembershipOrderIdOneOf;

export type MembershipOrderItemWhereMembershipOrderIdOneOfNot = string | MembershipOrderId;

export type MembershipOrderItemWhereMembershipOrderIdOneOfMode =
  typeof MembershipOrderItemWhereMembershipOrderIdOneOfMode[keyof typeof MembershipOrderItemWhereMembershipOrderIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOrderItemWhereMembershipOrderIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipOrderItemWhereMembershipOrderIdOneOfNotIn = string | string[];

export type MembershipOrderItemWhereMembershipOrderIdOneOfIn = string | string[];

export type MembershipOrderItemWhereMembershipOrderIdOneOf = {
  equals?: string | null;
  in?: MembershipOrderItemWhereMembershipOrderIdOneOfIn;
  notIn?: MembershipOrderItemWhereMembershipOrderIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipOrderItemWhereMembershipOrderIdOneOfMode;
  not?: MembershipOrderItemWhereMembershipOrderIdOneOfNot;
};

export type MembershipOrderItemWhereIdOneOfNot = string | Id;

export type MembershipOrderItemWhereIdOneOfMode =
  typeof MembershipOrderItemWhereIdOneOfMode[keyof typeof MembershipOrderItemWhereIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOrderItemWhereIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipOrderItemWhereIdOneOfNotIn = string | string[];

export type MembershipOrderItemWhereIdOneOfIn = string | string[];

export type MembershipOrderItemWhereIdOneOf = {
  equals?: string | null;
  in?: MembershipOrderItemWhereIdOneOfIn;
  notIn?: MembershipOrderItemWhereIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipOrderItemWhereIdOneOfMode;
  not?: MembershipOrderItemWhereIdOneOfNot;
};

export type MembershipOrderItemWhereId = string | MembershipOrderItemWhereIdOneOf;

export type MembershipOrderItemWhereNot = MembershipOrderItemWhere | MembershipOrderItemWhere[];

export type MembershipOrderItemWhereOr = MembershipOrderItemWhere | MembershipOrderItemWhere[];

export type MembershipOrderItemWhereAnd = MembershipOrderItemWhere | MembershipOrderItemWhere[];

export type MembershipOrderItems = {
  items?: MembershipOrderItem[];
};

export type MembershipOrderItemItem = Item;

export type MembershipOrderItemMembershipOrder = MembershipOrder;

export type MembershipOrderItemType = typeof MembershipOrderItemType[keyof typeof MembershipOrderItemType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOrderItemType = {
  INCLUSION: 'INCLUSION',
  ADDITION: 'ADDITION',
} as const;

export type MembershipOrderItem = {
  id?: string | null;
  type?: MembershipOrderItemType;
  membershipOrderId?: string | null;
  itemId?: string | null;
  quantity?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  membershipOrder?: MembershipOrderItemMembershipOrder;
  item?: MembershipOrderItemItem;
};

export type MembershipOrderItemIncludeItemOneOf = {
  select?: ItemSelect;
  include?: ItemInclude;
};

export type MembershipOrderItemIncludeItem = boolean | MembershipOrderItemIncludeItemOneOf;

export type MembershipOrderItemInclude = {
  membershipOrder?: boolean | null;
  item?: MembershipOrderItemIncludeItem;
};

export type MembershipOrderItemSelect = {
  id?: boolean | null;
  membershipOrderId?: boolean | null;
  itemId?: boolean | null;
  type?: boolean | null;
  quantity?: boolean | null;
  createdAt?: boolean | null;
  updatedAt?: boolean | null;
  deletedAt?: boolean | null;
  membershipOrder?: boolean | null;
  item?: boolean | null;
};

export type MembershipOrderUpdateMembershipOrderItemsConnect =
  | MembershipOrderItemUniqueWhere
  | MembershipOrderItemUniqueWhere[];

export type MembershipOrderUpdateMembershipOrderItems = {
  connect: MembershipOrderUpdateMembershipOrderItemsConnect;
} | null;

export type MembershipOrderUpdateSalesOrder = {
  connect: SalesOrderUniqueWhere;
} | null;

export type MembershipOrderUpdateMembership = {
  connect: MembershipUniqueWhere;
} | null;

export type MembershipOrderUpdateMonth = typeof MembershipOrderUpdateMonth[keyof typeof MembershipOrderUpdateMonth];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOrderUpdateMonth = {
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
} as const;

export type MembershipOrderUpdate = {
  id?: string | null;
  year?: string | null;
  month?: MembershipOrderUpdateMonth;
  orderData?: unknown;
  processedAt?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  membership?: MembershipOrderUpdateMembership;
  salesOrder?: MembershipOrderUpdateSalesOrder;
  membershipOrderItems?: MembershipOrderUpdateMembershipOrderItems;
};

export type MembershipOrderCreateMembershipOrderItemsConnect =
  | MembershipOrderItemUniqueWhere
  | MembershipOrderItemUniqueWhere[];

export type MembershipOrderCreateMembershipOrderItems = {
  connect: MembershipOrderCreateMembershipOrderItemsConnect;
} | null;

export type MembershipOrderCreateSalesOrder = {
  connect: SalesOrderUniqueWhere;
} | null;

export type MembershipOrderCreateMembership = {
  connect: MembershipUniqueWhere;
};

export type MembershipOrderCreateMonth = typeof MembershipOrderCreateMonth[keyof typeof MembershipOrderCreateMonth];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOrderCreateMonth = {
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
} as const;

export type MembershipOrderCreate = {
  id?: string | null;
  year: string;
  month: MembershipOrderCreateMonth;
  orderData?: unknown;
  processedAt?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  membership: MembershipOrderCreateMembership;
  salesOrder?: MembershipOrderCreateSalesOrder;
  membershipOrderItems?: MembershipOrderCreateMembershipOrderItems;
};

export type MembershipOrderUniqueWhere = {
  id?: string | null;
  salesOrderId?: string | null;
};

export type MembershipOrderWhere = {
  AND?: MembershipOrderWhereAnd;
  OR?: MembershipOrderWhereOr;
  NOT?: MembershipOrderWhereNot;
  id?: MembershipOrderWhereId;
  year?: MembershipOrderWhereYear;
  month?: MembershipOrderWhereMonth;
  membershipId?: MembershipOrderWhereMembershipId;
  salesOrderId?: MembershipOrderWhereSalesOrderId;
  orderData?: MembershipOrderWhereOrderData;
  processedAt?: MembershipOrderWhereProcessedAt;
  createdAt?: MembershipOrderWhereCreatedAt;
  updatedAt?: MembershipOrderWhereUpdatedAt;
  deletedAt?: MembershipOrderWhereDeletedAt;
  membership?: MembershipOrderWhereMembership;
  salesOrder?: MembershipOrderWhereSalesOrder;
  membershipOrderItems?: MembershipOrderWhereMembershipOrderItems;
};

export type MembershipOrderWhereMembershipOrderItemsNone = MembershipOrderItemWhere;

export type MembershipOrderWhereMembershipOrderItemsEvery = MembershipOrderItemWhere;

export type MembershipOrderWhereMembershipOrderItemsSome = MembershipOrderItemWhere;

export type MembershipOrderWhereMembershipOrderItems = {
  some?: MembershipOrderWhereMembershipOrderItemsSome;
  every?: MembershipOrderWhereMembershipOrderItemsEvery;
  none?: MembershipOrderWhereMembershipOrderItemsNone;
} | null;

export type MembershipOrderWhereSalesOrder = SalesOrderWhere | MembershipOrderWhereSalesOrderOneOf;

export type MembershipOrderWhereSalesOrderOneOfIsNot = SalesOrderWhere;

export type MembershipOrderWhereSalesOrderOneOfIs = SalesOrderWhere;

export type MembershipOrderWhereSalesOrderOneOf = {
  is?: MembershipOrderWhereSalesOrderOneOfIs;
  isNot?: MembershipOrderWhereSalesOrderOneOfIsNot;
};

export type MembershipOrderWhereMembershipOneOfIsNot = MembershipWhere;

export type MembershipOrderWhereMembershipOneOfIs = MembershipWhere;

export type MembershipOrderWhereMembershipOneOf = {
  is?: MembershipOrderWhereMembershipOneOfIs;
  isNot?: MembershipOrderWhereMembershipOneOfIsNot;
};

export type MembershipOrderWhereMembership = MembershipWhere | MembershipOrderWhereMembershipOneOf;

export type MembershipOrderWhereDeletedAt = string | MembershipOrderWhereDeletedAtOneOf;

export type MembershipOrderWhereDeletedAtOneOfNot = string | DeletedAt;

export type MembershipOrderWhereDeletedAtOneOfNotIn = string | string[];

export type MembershipOrderWhereDeletedAtOneOfIn = string | string[];

export type MembershipOrderWhereDeletedAtOneOfEquals = string;

export type MembershipOrderWhereDeletedAtOneOf = {
  equals?: MembershipOrderWhereDeletedAtOneOfEquals;
  in?: MembershipOrderWhereDeletedAtOneOfIn;
  notIn?: MembershipOrderWhereDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipOrderWhereDeletedAtOneOfNot;
};

export type MembershipOrderWhereUpdatedAtOneOf = {
  equals?: string | null;
  in?: MembershipOrderWhereUpdatedAtOneOfIn;
  notIn?: MembershipOrderWhereUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipOrderWhereUpdatedAtOneOfNot;
};

export type MembershipOrderWhereUpdatedAt = string | MembershipOrderWhereUpdatedAtOneOf;

export type MembershipOrderWhereUpdatedAtOneOfNot = string | UpdatedAt;

export type MembershipOrderWhereUpdatedAtOneOfNotIn = string | string[];

export type MembershipOrderWhereUpdatedAtOneOfIn = string | string[];

export type MembershipOrderWhereCreatedAtOneOfNot = string | CreatedAt;

export type MembershipOrderWhereCreatedAtOneOfNotIn = string | string[];

export type MembershipOrderWhereCreatedAtOneOfIn = string | string[];

export type MembershipOrderWhereCreatedAtOneOf = {
  equals?: string | null;
  in?: MembershipOrderWhereCreatedAtOneOfIn;
  notIn?: MembershipOrderWhereCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipOrderWhereCreatedAtOneOfNot;
};

export type MembershipOrderWhereCreatedAt = string | MembershipOrderWhereCreatedAtOneOf;

export type MembershipOrderWhereProcessedAtOneOfNot = string | ProcessedAt;

export type MembershipOrderWhereProcessedAtOneOfNotIn = string | string[];

export type MembershipOrderWhereProcessedAtOneOfIn = string | string[];

export type MembershipOrderWhereProcessedAtOneOf = {
  equals?: string | null;
  in?: MembershipOrderWhereProcessedAtOneOfIn;
  notIn?: MembershipOrderWhereProcessedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipOrderWhereProcessedAtOneOfNot;
};

export type MembershipOrderWhereProcessedAt = string | MembershipOrderWhereProcessedAtOneOf;

export type MembershipOrderWhereOrderDataOneOfPath = string | string[];

export type MembershipOrderWhereOrderDataOneOf = {
  path?: MembershipOrderWhereOrderDataOneOfPath;
  equals?: unknown;
  not?: unknown;
  string_contains?: string | null;
  string_starts_with?: string | null;
  string_ends_with?: string | null;
  array_contains?: unknown;
  array_starts_with?: unknown;
  array_ends_with?: unknown;
};

export type MembershipOrderWhereOrderData = MembershipOrderWhereOrderDataOneOf;

export type MembershipOrderWhereSalesOrderIdOneOfNot = string | SalesOrderId;

export type MembershipOrderWhereSalesOrderIdOneOfMode =
  typeof MembershipOrderWhereSalesOrderIdOneOfMode[keyof typeof MembershipOrderWhereSalesOrderIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOrderWhereSalesOrderIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipOrderWhereSalesOrderIdOneOfNotIn = string | string[];

export type MembershipOrderWhereSalesOrderIdOneOfIn = string | string[];

export type MembershipOrderWhereSalesOrderIdOneOf = {
  equals?: string | null;
  in?: MembershipOrderWhereSalesOrderIdOneOfIn;
  notIn?: MembershipOrderWhereSalesOrderIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipOrderWhereSalesOrderIdOneOfMode;
  not?: MembershipOrderWhereSalesOrderIdOneOfNot;
};

export type MembershipOrderWhereSalesOrderId = string | MembershipOrderWhereSalesOrderIdOneOf;

export type MembershipOrderWhereMembershipId = string | MembershipOrderWhereMembershipIdOneOf;

export type MembershipOrderWhereMembershipIdOneOfNot = string | MembershipId;

export type MembershipOrderWhereMembershipIdOneOfMode =
  typeof MembershipOrderWhereMembershipIdOneOfMode[keyof typeof MembershipOrderWhereMembershipIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOrderWhereMembershipIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipOrderWhereMembershipIdOneOfNotIn = string | string[];

export type MembershipOrderWhereMembershipIdOneOfIn = string | string[];

export type MembershipOrderWhereMembershipIdOneOf = {
  equals?: string | null;
  in?: MembershipOrderWhereMembershipIdOneOfIn;
  notIn?: MembershipOrderWhereMembershipIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipOrderWhereMembershipIdOneOfMode;
  not?: MembershipOrderWhereMembershipIdOneOfNot;
};

export type MembershipOrderWhereMonth =
  | 'JANUARY'
  | 'FEBRUARY'
  | 'MARCH'
  | 'APRIL'
  | 'MAY'
  | 'JUNE'
  | 'JULY'
  | 'AUGUST'
  | 'SEPTEMBER'
  | 'OCTOBER'
  | 'NOVEMBER'
  | 'DECEMBER'
  | MembershipOrderWhereMonthOneOf;

export type MembershipOrderWhereMonthOneOfNot =
  | 'JANUARY'
  | 'FEBRUARY'
  | 'MARCH'
  | 'APRIL'
  | 'MAY'
  | 'JUNE'
  | 'JULY'
  | 'AUGUST'
  | 'SEPTEMBER'
  | 'OCTOBER'
  | 'NOVEMBER'
  | 'DECEMBER'
  | Month;

export type MembershipOrderWhereMonthOneOfNotInOneOfItem =
  typeof MembershipOrderWhereMonthOneOfNotInOneOfItem[keyof typeof MembershipOrderWhereMonthOneOfNotInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOrderWhereMonthOneOfNotInOneOfItem = {
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
} as const;

export type MembershipOrderWhereMonthOneOfNotIn =
  | 'JANUARY'
  | 'FEBRUARY'
  | 'MARCH'
  | 'APRIL'
  | 'MAY'
  | 'JUNE'
  | 'JULY'
  | 'AUGUST'
  | 'SEPTEMBER'
  | 'OCTOBER'
  | 'NOVEMBER'
  | 'DECEMBER'
  | MembershipOrderWhereMonthOneOfNotInOneOfItem[];

export type MembershipOrderWhereMonthOneOfIn =
  | 'JANUARY'
  | 'FEBRUARY'
  | 'MARCH'
  | 'APRIL'
  | 'MAY'
  | 'JUNE'
  | 'JULY'
  | 'AUGUST'
  | 'SEPTEMBER'
  | 'OCTOBER'
  | 'NOVEMBER'
  | 'DECEMBER'
  | MembershipOrderWhereMonthOneOfInOneOfItem[];

export type MembershipOrderWhereMonthOneOf = {
  equals?: MembershipOrderWhereMonthOneOfEquals;
  in?: MembershipOrderWhereMonthOneOfIn;
  notIn?: MembershipOrderWhereMonthOneOfNotIn;
  not?: MembershipOrderWhereMonthOneOfNot;
} | null;

export type MembershipOrderWhereMonthOneOfInOneOfItem =
  typeof MembershipOrderWhereMonthOneOfInOneOfItem[keyof typeof MembershipOrderWhereMonthOneOfInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOrderWhereMonthOneOfInOneOfItem = {
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
} as const;

export type MembershipOrderWhereMonthOneOfEquals =
  typeof MembershipOrderWhereMonthOneOfEquals[keyof typeof MembershipOrderWhereMonthOneOfEquals];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOrderWhereMonthOneOfEquals = {
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
} as const;

export type MembershipOrderWhereYear = string | MembershipOrderWhereYearOneOf;

export type MembershipOrderWhereYearOneOfNot = string | Year;

export type MembershipOrderWhereYearOneOfMode =
  typeof MembershipOrderWhereYearOneOfMode[keyof typeof MembershipOrderWhereYearOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOrderWhereYearOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipOrderWhereYearOneOfNotIn = string | string[];

export type MembershipOrderWhereYearOneOfIn = string | string[];

export type MembershipOrderWhereYearOneOf = {
  equals?: string | null;
  in?: MembershipOrderWhereYearOneOfIn;
  notIn?: MembershipOrderWhereYearOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipOrderWhereYearOneOfMode;
  not?: MembershipOrderWhereYearOneOfNot;
};

export type MembershipOrderWhereId = string | MembershipOrderWhereIdOneOf;

export type MembershipOrderWhereIdOneOfNot = string | Id;

export type MembershipOrderWhereIdOneOfMode =
  typeof MembershipOrderWhereIdOneOfMode[keyof typeof MembershipOrderWhereIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOrderWhereIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipOrderWhereIdOneOfNotIn = string | string[];

export type MembershipOrderWhereIdOneOfIn = string | string[];

export type MembershipOrderWhereIdOneOf = {
  equals?: string | null;
  in?: MembershipOrderWhereIdOneOfIn;
  notIn?: MembershipOrderWhereIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipOrderWhereIdOneOfMode;
  not?: MembershipOrderWhereIdOneOfNot;
};

export type MembershipOrderWhereNot = MembershipOrderWhere | MembershipOrderWhere[];

export type MembershipOrderWhereOr = MembershipOrderWhere | MembershipOrderWhere[];

export type MembershipOrderWhereAnd = MembershipOrderWhere | MembershipOrderWhere[];

export type MembershipOrderMembershipOrderItems = MembershipOrderItems;

export type MembershipOrderSalesOrder = SalesOrder;

export type MembershipOrderMembership = Membership;

export type MembershipOrderMonth = typeof MembershipOrderMonth[keyof typeof MembershipOrderMonth];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipOrderMonth = {
  JANUARY: 'JANUARY',
  FEBRUARY: 'FEBRUARY',
  MARCH: 'MARCH',
  APRIL: 'APRIL',
  MAY: 'MAY',
  JUNE: 'JUNE',
  JULY: 'JULY',
  AUGUST: 'AUGUST',
  SEPTEMBER: 'SEPTEMBER',
  OCTOBER: 'OCTOBER',
  NOVEMBER: 'NOVEMBER',
  DECEMBER: 'DECEMBER',
} as const;

export type MembershipOrder = {
  id?: string | null;
  year?: string | null;
  month?: MembershipOrderMonth;
  membershipId?: string | null;
  salesOrderId?: string | null;
  orderData?: unknown;
  processedAt?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  membership?: MembershipOrderMembership;
  salesOrder?: MembershipOrderSalesOrder;
  membershipOrderItems?: MembershipOrderMembershipOrderItems;
  _count?: unknown;
};

export type MembershipOrders = {
  items?: MembershipOrder[];
};

export type MembershipOrderIncludeMembershipOrderItemsOneOf = {
  select?: MembershipOrderItemSelect;
  include?: MembershipOrderItemInclude;
};

export type MembershipOrderIncludeMembershipOrderItems = boolean | MembershipOrderIncludeMembershipOrderItemsOneOf;

export type MembershipOrderIncludeSalesOrderOneOf = {
  select?: SalesOrderSelect;
  include?: SalesOrderInclude;
};

export type MembershipOrderIncludeSalesOrder = boolean | MembershipOrderIncludeSalesOrderOneOf;

export type MembershipOrderIncludeMembershipOneOf = {
  select?: MembershipSelect;
  include?: MembershipInclude;
};

export type MembershipOrderIncludeMembership = boolean | MembershipOrderIncludeMembershipOneOf;

export type MembershipOrderInclude = {
  membership?: MembershipOrderIncludeMembership;
  salesOrder?: MembershipOrderIncludeSalesOrder;
  membershipOrderItems?: MembershipOrderIncludeMembershipOrderItems;
};

export type MembershipOrderSelect = {
  id?: boolean | null;
  year?: boolean | null;
  month?: boolean | null;
  membershipId?: boolean | null;
  salesOrderId?: boolean | null;
  orderData?: boolean | null;
  processedAt?: boolean | null;
  createdAt?: boolean | null;
  updatedAt?: boolean | null;
  deletedAt?: boolean | null;
  membership?: boolean | null;
  salesOrder?: boolean | null;
  membershipOrderItems?: boolean | null;
  _count?: boolean | null;
};

export type MembershipFrequencyUpdateMembershipsConnect = MembershipUniqueWhere | MembershipUniqueWhere[];

export type MembershipFrequencyUpdateMemberships = {
  connect: MembershipFrequencyUpdateMembershipsConnect;
} | null;

export type MembershipFrequencyUpdateProductVariant = {
  connect: ProductVariantUniqueWhere;
} | null;

export type MembershipFrequencyUpdateMembershipTier = {
  connect: MembershipTierUniqueWhere;
} | null;

export type MembershipFrequencyUpdateUnits =
  typeof MembershipFrequencyUpdateUnits[keyof typeof MembershipFrequencyUpdateUnits];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipFrequencyUpdateUnits = {
  MONTHS: 'MONTHS',
  YEARS: 'YEARS',
} as const;

export type MembershipFrequencyUpdate = {
  id?: string | null;
  duration?: number | null;
  units?: MembershipFrequencyUpdateUnits;
  createdAt?: string | null;
  updatedAt?: string | null;
  membershipTier?: MembershipFrequencyUpdateMembershipTier;
  productVariant?: MembershipFrequencyUpdateProductVariant;
  memberships?: MembershipFrequencyUpdateMemberships;
};

export type MembershipFrequencyCreateMembershipsConnect = MembershipUniqueWhere | MembershipUniqueWhere[];

export type MembershipFrequencyCreateMemberships = {
  connect: MembershipFrequencyCreateMembershipsConnect;
} | null;

export type MembershipFrequencyCreateProductVariant = {
  connect: ProductVariantUniqueWhere;
};

export type MembershipFrequencyCreateMembershipTier = {
  connect: MembershipTierUniqueWhere;
};

export type MembershipFrequencyCreateUnits =
  typeof MembershipFrequencyCreateUnits[keyof typeof MembershipFrequencyCreateUnits];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipFrequencyCreateUnits = {
  MONTHS: 'MONTHS',
  YEARS: 'YEARS',
} as const;

export type MembershipFrequencyCreate = {
  id?: string | null;
  duration: number;
  units: MembershipFrequencyCreateUnits;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  membershipTier: MembershipFrequencyCreateMembershipTier;
  productVariant: MembershipFrequencyCreateProductVariant;
  memberships?: MembershipFrequencyCreateMemberships;
};

export type MembershipFrequencyUniqueWhere = {
  id?: string | null;
  productVariantId?: string | null;
};

export type MembershipFrequencyWhere = {
  AND?: MembershipFrequencyWhereAnd;
  OR?: MembershipFrequencyWhereOr;
  NOT?: MembershipFrequencyWhereNot;
  id?: MembershipFrequencyWhereId;
  duration?: MembershipFrequencyWhereDuration;
  units?: MembershipFrequencyWhereUnits;
  membershipTierId?: MembershipFrequencyWhereMembershipTierId;
  productVariantId?: MembershipFrequencyWhereProductVariantId;
  createdAt?: MembershipFrequencyWhereCreatedAt;
  updatedAt?: MembershipFrequencyWhereUpdatedAt;
  deletedAt?: MembershipFrequencyWhereDeletedAt;
  membershipTier?: MembershipFrequencyWhereMembershipTier;
  productVariant?: MembershipFrequencyWhereProductVariant;
  memberships?: MembershipFrequencyWhereMemberships;
};

export type MembershipFrequencyWhereMembershipsNone = MembershipWhere;

export type MembershipFrequencyWhereMembershipsEvery = MembershipWhere;

export type MembershipFrequencyWhereMembershipsSome = MembershipWhere;

export type MembershipFrequencyWhereMemberships = {
  some?: MembershipFrequencyWhereMembershipsSome;
  every?: MembershipFrequencyWhereMembershipsEvery;
  none?: MembershipFrequencyWhereMembershipsNone;
} | null;

export type MembershipFrequencyWhereProductVariantOneOfIsNot = ProductVariantWhere;

export type MembershipFrequencyWhereProductVariantOneOfIs = ProductVariantWhere;

export type MembershipFrequencyWhereProductVariantOneOf = {
  is?: MembershipFrequencyWhereProductVariantOneOfIs;
  isNot?: MembershipFrequencyWhereProductVariantOneOfIsNot;
};

export type MembershipFrequencyWhereProductVariant = ProductVariantWhere | MembershipFrequencyWhereProductVariantOneOf;

export type MembershipFrequencyWhereMembershipTier = MembershipTierWhere | MembershipFrequencyWhereMembershipTierOneOf;

export type MembershipFrequencyWhereMembershipTierOneOfIsNot = MembershipTierWhere;

export type MembershipFrequencyWhereMembershipTierOneOfIs = MembershipTierWhere;

export type MembershipFrequencyWhereMembershipTierOneOf = {
  is?: MembershipFrequencyWhereMembershipTierOneOfIs;
  isNot?: MembershipFrequencyWhereMembershipTierOneOfIsNot;
};

export type MembershipFrequencyWhereDeletedAt = string | MembershipFrequencyWhereDeletedAtOneOf;

export type MembershipFrequencyWhereDeletedAtOneOfNot = string | DeletedAt;

export type MembershipFrequencyWhereDeletedAtOneOfNotIn = string | string[];

export type MembershipFrequencyWhereDeletedAtOneOfIn = string | string[];

export type MembershipFrequencyWhereDeletedAtOneOfEquals = string;

export type MembershipFrequencyWhereDeletedAtOneOf = {
  equals?: MembershipFrequencyWhereDeletedAtOneOfEquals;
  in?: MembershipFrequencyWhereDeletedAtOneOfIn;
  notIn?: MembershipFrequencyWhereDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipFrequencyWhereDeletedAtOneOfNot;
};

export type MembershipFrequencyWhereUpdatedAt = string | MembershipFrequencyWhereUpdatedAtOneOf;

export type MembershipFrequencyWhereUpdatedAtOneOfNot = string | UpdatedAt;

export type MembershipFrequencyWhereUpdatedAtOneOfNotIn = string | string[];

export type MembershipFrequencyWhereUpdatedAtOneOfIn = string | string[];

export type MembershipFrequencyWhereUpdatedAtOneOf = {
  equals?: string | null;
  in?: MembershipFrequencyWhereUpdatedAtOneOfIn;
  notIn?: MembershipFrequencyWhereUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipFrequencyWhereUpdatedAtOneOfNot;
};

export type MembershipFrequencyWhereCreatedAt = string | MembershipFrequencyWhereCreatedAtOneOf;

export type MembershipFrequencyWhereCreatedAtOneOfNot = string | CreatedAt;

export type MembershipFrequencyWhereCreatedAtOneOfNotIn = string | string[];

export type MembershipFrequencyWhereCreatedAtOneOfIn = string | string[];

export type MembershipFrequencyWhereCreatedAtOneOf = {
  equals?: string | null;
  in?: MembershipFrequencyWhereCreatedAtOneOfIn;
  notIn?: MembershipFrequencyWhereCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipFrequencyWhereCreatedAtOneOfNot;
};

export type MembershipFrequencyWhereProductVariantId = string | MembershipFrequencyWhereProductVariantIdOneOf;

export type MembershipFrequencyWhereProductVariantIdOneOfNot = string | ProductVariantId;

export type MembershipFrequencyWhereProductVariantIdOneOfMode =
  typeof MembershipFrequencyWhereProductVariantIdOneOfMode[keyof typeof MembershipFrequencyWhereProductVariantIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipFrequencyWhereProductVariantIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipFrequencyWhereProductVariantIdOneOfNotIn = string | string[];

export type MembershipFrequencyWhereProductVariantIdOneOfIn = string | string[];

export type MembershipFrequencyWhereProductVariantIdOneOf = {
  equals?: string | null;
  in?: MembershipFrequencyWhereProductVariantIdOneOfIn;
  notIn?: MembershipFrequencyWhereProductVariantIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipFrequencyWhereProductVariantIdOneOfMode;
  not?: MembershipFrequencyWhereProductVariantIdOneOfNot;
};

export type MembershipFrequencyWhereMembershipTierId = string | MembershipFrequencyWhereMembershipTierIdOneOf;

export type MembershipFrequencyWhereMembershipTierIdOneOfNot = string | MembershipTierId;

export type MembershipFrequencyWhereMembershipTierIdOneOfMode =
  typeof MembershipFrequencyWhereMembershipTierIdOneOfMode[keyof typeof MembershipFrequencyWhereMembershipTierIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipFrequencyWhereMembershipTierIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipFrequencyWhereMembershipTierIdOneOfNotIn = string | string[];

export type MembershipFrequencyWhereMembershipTierIdOneOfIn = string | string[];

export type MembershipFrequencyWhereMembershipTierIdOneOf = {
  equals?: string | null;
  in?: MembershipFrequencyWhereMembershipTierIdOneOfIn;
  notIn?: MembershipFrequencyWhereMembershipTierIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipFrequencyWhereMembershipTierIdOneOfMode;
  not?: MembershipFrequencyWhereMembershipTierIdOneOfNot;
};

export type MembershipFrequencyWhereUnitsOneOfNot = 'MONTHS' | 'YEARS' | Units;

export type MembershipFrequencyWhereUnitsOneOf = {
  equals?: MembershipFrequencyWhereUnitsOneOfEquals;
  in?: MembershipFrequencyWhereUnitsOneOfIn;
  notIn?: MembershipFrequencyWhereUnitsOneOfNotIn;
  not?: MembershipFrequencyWhereUnitsOneOfNot;
} | null;

export type MembershipFrequencyWhereUnits = 'MONTHS' | 'YEARS' | MembershipFrequencyWhereUnitsOneOf;

export type MembershipFrequencyWhereUnitsOneOfNotInOneOfItem =
  typeof MembershipFrequencyWhereUnitsOneOfNotInOneOfItem[keyof typeof MembershipFrequencyWhereUnitsOneOfNotInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipFrequencyWhereUnitsOneOfNotInOneOfItem = {
  MONTHS: 'MONTHS',
  YEARS: 'YEARS',
} as const;

export type MembershipFrequencyWhereUnitsOneOfNotIn =
  | 'MONTHS'
  | 'YEARS'
  | MembershipFrequencyWhereUnitsOneOfNotInOneOfItem[];

export type MembershipFrequencyWhereUnitsOneOfInOneOfItem =
  typeof MembershipFrequencyWhereUnitsOneOfInOneOfItem[keyof typeof MembershipFrequencyWhereUnitsOneOfInOneOfItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipFrequencyWhereUnitsOneOfInOneOfItem = {
  MONTHS: 'MONTHS',
  YEARS: 'YEARS',
} as const;

export type MembershipFrequencyWhereUnitsOneOfIn = 'MONTHS' | 'YEARS' | MembershipFrequencyWhereUnitsOneOfInOneOfItem[];

export type MembershipFrequencyWhereUnitsOneOfEquals =
  typeof MembershipFrequencyWhereUnitsOneOfEquals[keyof typeof MembershipFrequencyWhereUnitsOneOfEquals];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipFrequencyWhereUnitsOneOfEquals = {
  MONTHS: 'MONTHS',
  YEARS: 'YEARS',
} as const;

export type MembershipFrequencyWhereDuration = number | MembershipFrequencyWhereDurationOneOf;

export type MembershipFrequencyWhereDurationOneOfNot = number | Duration;

export type MembershipFrequencyWhereDurationOneOfNotIn = number | number[];

export type MembershipFrequencyWhereDurationOneOfIn = number | number[];

export type MembershipFrequencyWhereDurationOneOf = {
  equals?: number | null;
  in?: MembershipFrequencyWhereDurationOneOfIn;
  notIn?: MembershipFrequencyWhereDurationOneOfNotIn;
  lt?: number | null;
  lte?: number | null;
  gt?: number | null;
  gte?: number | null;
  not?: MembershipFrequencyWhereDurationOneOfNot;
};

export type MembershipFrequencyWhereId = string | MembershipFrequencyWhereIdOneOf;

export type MembershipFrequencyWhereIdOneOfNot = string | Id;

export type MembershipFrequencyWhereIdOneOfMode =
  typeof MembershipFrequencyWhereIdOneOfMode[keyof typeof MembershipFrequencyWhereIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipFrequencyWhereIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipFrequencyWhereIdOneOfNotIn = string | string[];

export type MembershipFrequencyWhereIdOneOfIn = string | string[];

export type MembershipFrequencyWhereIdOneOf = {
  equals?: string | null;
  in?: MembershipFrequencyWhereIdOneOfIn;
  notIn?: MembershipFrequencyWhereIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipFrequencyWhereIdOneOfMode;
  not?: MembershipFrequencyWhereIdOneOfNot;
};

export type MembershipFrequencyWhereNot = MembershipFrequencyWhere | MembershipFrequencyWhere[];

export type MembershipFrequencyWhereOr = MembershipFrequencyWhere | MembershipFrequencyWhere[];

export type MembershipFrequencyWhereAnd = MembershipFrequencyWhere | MembershipFrequencyWhere[];

export type MembershipFrequencies = {
  items?: MembershipFrequency[];
};

export type MembershipFrequencyMemberships = Memberships;

export type MembershipFrequencyProductVariant = ProductVariant;

export type MembershipFrequencyMembershipTier = MembershipTier;

export type MembershipFrequencyUnits = typeof MembershipFrequencyUnits[keyof typeof MembershipFrequencyUnits];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipFrequencyUnits = {
  MONTHS: 'MONTHS',
  YEARS: 'YEARS',
} as const;

export type MembershipFrequency = {
  id?: string | null;
  duration?: number | null;
  units?: MembershipFrequencyUnits;
  membershipTierId?: string | null;
  productVariantId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  membershipTier?: MembershipFrequencyMembershipTier;
  productVariant?: MembershipFrequencyProductVariant;
  memberships?: MembershipFrequencyMemberships;
  _count?: unknown;
};

export type MembershipFrequencyInclude = {
  membershipTier?: boolean | null;
  productVariant?: boolean | null;
  memberships?: boolean | null;
};

export type MembershipFrequencySelect = {
  id?: boolean | null;
  duration?: boolean | null;
  units?: boolean | null;
  membershipTierId?: boolean | null;
  productVariantId?: boolean | null;
  createdAt?: boolean | null;
  updatedAt?: boolean | null;
  deletedAt?: boolean | null;
  membershipTier?: boolean | null;
  productVariant?: boolean | null;
  memberships?: boolean | null;
  _count?: boolean | null;
};

export type MembershipTierUpdateMembershipOfferingsConnect =
  | MembershipOfferingUniqueWhere
  | MembershipOfferingUniqueWhere[];

export type MembershipTierUpdateMembershipOfferings = {
  connect: MembershipTierUpdateMembershipOfferingsConnect;
} | null;

export type MembershipTierUpdate = {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  isPublic?: boolean | null;
  discountRules?: unknown;
  createdAt?: string | null;
  updatedAt?: string | null;
  product?: MembershipTierUpdateProduct;
  membershipFrequencies?: MembershipTierUpdateMembershipFrequencies;
  membershipOfferings?: MembershipTierUpdateMembershipOfferings;
};

export type MembershipTierUpdateMembershipFrequenciesConnect =
  | MembershipFrequencyUniqueWhere
  | MembershipFrequencyUniqueWhere[];

export type MembershipTierUpdateMembershipFrequencies = {
  connect: MembershipTierUpdateMembershipFrequenciesConnect;
} | null;

export type MembershipTierUpdateProduct = {
  connect: ProductUniqueWhere;
} | null;

export type MembershipTierCreateMembershipOfferingsConnect =
  | MembershipOfferingUniqueWhere
  | MembershipOfferingUniqueWhere[];

export type MembershipTierCreateMembershipOfferings = {
  connect: MembershipTierCreateMembershipOfferingsConnect;
} | null;

export type MembershipTierCreateMembershipFrequenciesConnect =
  | MembershipFrequencyUniqueWhere
  | MembershipFrequencyUniqueWhere[];

export type MembershipTierCreateMembershipFrequencies = {
  connect: MembershipTierCreateMembershipFrequenciesConnect;
} | null;

export type MembershipTierCreateProduct = {
  connect: ProductUniqueWhere;
} | null;

export type MembershipTierCreate = {
  id?: string | null;
  name: string;
  description?: string | null;
  isPublic?: boolean | null;
  discountRules?: unknown;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  product?: MembershipTierCreateProduct;
  membershipFrequencies?: MembershipTierCreateMembershipFrequencies;
  membershipOfferings?: MembershipTierCreateMembershipOfferings;
};

export type MembershipTierUniqueWhere =
  | (unknown & {
      id?: string | null;
      productId?: string | null;
    })
  | (unknown & {
      id?: string | null;
      productId?: string | null;
    });

export type MembershipTierWhereMembershipOfferingsNone = MembershipOfferingWhere;

export type MembershipTierWhereMembershipOfferingsEvery = MembershipOfferingWhere;

export type MembershipTierWhereMembershipOfferingsSome = MembershipOfferingWhere;

export type MembershipTierWhereMembershipOfferings = {
  some?: MembershipTierWhereMembershipOfferingsSome;
  every?: MembershipTierWhereMembershipOfferingsEvery;
  none?: MembershipTierWhereMembershipOfferingsNone;
} | null;

export type MembershipTierWhere = {
  AND?: MembershipTierWhereAnd;
  OR?: MembershipTierWhereOr;
  NOT?: MembershipTierWhereNot;
  id?: MembershipTierWhereId;
  name?: MembershipTierWhereName;
  description?: MembershipTierWhereDescription;
  isPublic?: MembershipTierWhereIsPublic;
  discountRules?: MembershipTierWhereDiscountRules;
  productId?: MembershipTierWhereProductId;
  createdAt?: MembershipTierWhereCreatedAt;
  updatedAt?: MembershipTierWhereUpdatedAt;
  deletedAt?: MembershipTierWhereDeletedAt;
  product?: MembershipTierWhereProduct;
  membershipFrequencies?: MembershipTierWhereMembershipFrequencies;
  membershipOfferings?: MembershipTierWhereMembershipOfferings;
};

export type MembershipTierWhereMembershipFrequenciesNone = MembershipFrequencyWhere;

export type MembershipTierWhereMembershipFrequenciesEvery = MembershipFrequencyWhere;

export type MembershipTierWhereMembershipFrequenciesSome = MembershipFrequencyWhere;

export type MembershipTierWhereMembershipFrequencies = {
  some?: MembershipTierWhereMembershipFrequenciesSome;
  every?: MembershipTierWhereMembershipFrequenciesEvery;
  none?: MembershipTierWhereMembershipFrequenciesNone;
} | null;

export type MembershipTierWhereProductOneOfIsNot = ProductWhere;

export type MembershipTierWhereProductOneOfIs = ProductWhere;

export type MembershipTierWhereProductOneOf = {
  is?: MembershipTierWhereProductOneOfIs;
  isNot?: MembershipTierWhereProductOneOfIsNot;
};

export type MembershipTierWhereProduct = ProductWhere | MembershipTierWhereProductOneOf;

export type MembershipTierWhereDeletedAt = string | MembershipTierWhereDeletedAtOneOf;

export type MembershipTierWhereDeletedAtOneOfNot = string | DeletedAt;

export type MembershipTierWhereDeletedAtOneOfNotIn = string | string[];

export type MembershipTierWhereDeletedAtOneOfIn = string | string[];

export type MembershipTierWhereDeletedAtOneOfEquals = string;

export type MembershipTierWhereDeletedAtOneOf = {
  equals?: MembershipTierWhereDeletedAtOneOfEquals;
  in?: MembershipTierWhereDeletedAtOneOfIn;
  notIn?: MembershipTierWhereDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipTierWhereDeletedAtOneOfNot;
};

export type MembershipTierWhereUpdatedAt = string | MembershipTierWhereUpdatedAtOneOf;

export type MembershipTierWhereUpdatedAtOneOfNot = string | UpdatedAt;

export type MembershipTierWhereUpdatedAtOneOfNotIn = string | string[];

export type MembershipTierWhereUpdatedAtOneOfIn = string | string[];

export type MembershipTierWhereUpdatedAtOneOf = {
  equals?: string | null;
  in?: MembershipTierWhereUpdatedAtOneOfIn;
  notIn?: MembershipTierWhereUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipTierWhereUpdatedAtOneOfNot;
};

export type MembershipTierWhereCreatedAt = string | MembershipTierWhereCreatedAtOneOf;

export type MembershipTierWhereCreatedAtOneOfNot = string | CreatedAt;

export type MembershipTierWhereCreatedAtOneOfNotIn = string | string[];

export type MembershipTierWhereCreatedAtOneOfIn = string | string[];

export type MembershipTierWhereCreatedAtOneOf = {
  equals?: string | null;
  in?: MembershipTierWhereCreatedAtOneOfIn;
  notIn?: MembershipTierWhereCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipTierWhereCreatedAtOneOfNot;
};

export type MembershipTierWhereProductId = string | MembershipTierWhereProductIdOneOf;

export type MembershipTierWhereProductIdOneOfNot = string | ProductId;

export type MembershipTierWhereProductIdOneOfMode =
  typeof MembershipTierWhereProductIdOneOfMode[keyof typeof MembershipTierWhereProductIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipTierWhereProductIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipTierWhereProductIdOneOfNotIn = string | string[];

export type MembershipTierWhereProductIdOneOfIn = string | string[];

export type MembershipTierWhereProductIdOneOf = {
  equals?: string | null;
  in?: MembershipTierWhereProductIdOneOfIn;
  notIn?: MembershipTierWhereProductIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipTierWhereProductIdOneOfMode;
  not?: MembershipTierWhereProductIdOneOfNot;
};

export type MembershipTierWhereDiscountRulesOneOfPath = string | string[];

export type MembershipTierWhereDiscountRulesOneOf = {
  path?: MembershipTierWhereDiscountRulesOneOfPath;
  equals?: unknown;
  not?: unknown;
  string_contains?: string | null;
  string_starts_with?: string | null;
  string_ends_with?: string | null;
  array_contains?: unknown;
  array_starts_with?: unknown;
  array_ends_with?: unknown;
};

export type MembershipTierWhereDiscountRules = MembershipTierWhereDiscountRulesOneOf;

export type MembershipTierWhereIsPublicOneOfNot = boolean | IsPublic;

export type MembershipTierWhereIsPublicOneOf = {
  equals?: boolean | null;
  not?: MembershipTierWhereIsPublicOneOfNot;
};

export type MembershipTierWhereIsPublic = boolean | MembershipTierWhereIsPublicOneOf;

export type MembershipTierWhereDescription = string | MembershipTierWhereDescriptionOneOf;

export type MembershipTierWhereDescriptionOneOfNot = string | Description;

export type MembershipTierWhereDescriptionOneOfMode =
  typeof MembershipTierWhereDescriptionOneOfMode[keyof typeof MembershipTierWhereDescriptionOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipTierWhereDescriptionOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipTierWhereDescriptionOneOfNotIn = string | string[];

export type MembershipTierWhereDescriptionOneOfIn = string | string[];

export type MembershipTierWhereDescriptionOneOfEquals = string;

export type MembershipTierWhereDescriptionOneOf = {
  equals?: MembershipTierWhereDescriptionOneOfEquals;
  in?: MembershipTierWhereDescriptionOneOfIn;
  notIn?: MembershipTierWhereDescriptionOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipTierWhereDescriptionOneOfMode;
  not?: MembershipTierWhereDescriptionOneOfNot;
};

export type MembershipTierWhereName = string | MembershipTierWhereNameOneOf;

export type MembershipTierWhereNameOneOfNot = string | Name;

export type MembershipTierWhereNameOneOfMode =
  typeof MembershipTierWhereNameOneOfMode[keyof typeof MembershipTierWhereNameOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipTierWhereNameOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipTierWhereNameOneOfNotIn = string | string[];

export type MembershipTierWhereNameOneOfIn = string | string[];

export type MembershipTierWhereNameOneOf = {
  equals?: string | null;
  in?: MembershipTierWhereNameOneOfIn;
  notIn?: MembershipTierWhereNameOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipTierWhereNameOneOfMode;
  not?: MembershipTierWhereNameOneOfNot;
};

export type MembershipTierWhereId = string | MembershipTierWhereIdOneOf;

export type MembershipTierWhereIdOneOfNot = string | Id;

export type MembershipTierWhereIdOneOfMode =
  typeof MembershipTierWhereIdOneOfMode[keyof typeof MembershipTierWhereIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipTierWhereIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipTierWhereIdOneOfNotIn = string | string[];

export type MembershipTierWhereIdOneOfIn = string | string[];

export type MembershipTierWhereIdOneOf = {
  equals?: string | null;
  in?: MembershipTierWhereIdOneOfIn;
  notIn?: MembershipTierWhereIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipTierWhereIdOneOfMode;
  not?: MembershipTierWhereIdOneOfNot;
};

export type MembershipTierWhereNot = MembershipTierWhere | MembershipTierWhere[];

export type MembershipTierWhereOr = MembershipTierWhere | MembershipTierWhere[];

export type MembershipTierWhereAnd = MembershipTierWhere | MembershipTierWhere[];

export type MembershipTierMembershipOfferings = MembershipOfferings;

export type MembershipTierMembershipFrequencies = MembershipFrequencies;

export type MembershipTierProduct = Product;

export type MembershipTier = {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  isPublic?: boolean | null;
  discountRules?: unknown;
  productId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  product?: MembershipTierProduct;
  membershipFrequencies?: MembershipTierMembershipFrequencies;
  membershipOfferings?: MembershipTierMembershipOfferings;
  _count?: unknown;
};

export type MembershipTiers = {
  items?: MembershipTier[];
};

export type MembershipTierInclude = {
  product?: boolean | null;
  membershipFrequencies?: boolean | null;
  membershipOfferings?: boolean | null;
};

export type MembershipTierSelect = {
  id?: boolean | null;
  name?: boolean | null;
  description?: boolean | null;
  isPublic?: boolean | null;
  discountRules?: boolean | null;
  productId?: boolean | null;
  createdAt?: boolean | null;
  updatedAt?: boolean | null;
  deletedAt?: boolean | null;
  product?: boolean | null;
  membershipFrequencies?: boolean | null;
  membershipOfferings?: boolean | null;
  _count?: boolean | null;
};

export type MembershipUpdateMembershipOrdersConnect = MembershipOrderUniqueWhere | MembershipOrderUniqueWhere[];

export type MembershipUpdateMembershipOrders = {
  connect: MembershipUpdateMembershipOrdersConnect;
} | null;

export type MembershipUpdateAccount = {
  connect: AccountUniqueWhere;
} | null;

export type MembershipUpdateMembershipFrequency = {
  connect: MembershipFrequencyUniqueWhere;
} | null;

export type MembershipUpdate = {
  id?: string | null;
  notes?: string | null;
  preferences?: unknown;
  chargeFrom?: string | null;
  endAt?: string | null;
  currentPeriodStartAt?: string | null;
  currentPeriodEndAt?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  membershipFrequency?: MembershipUpdateMembershipFrequency;
  account?: MembershipUpdateAccount;
  membershipOrders?: MembershipUpdateMembershipOrders;
};

export type MembershipCreate = {
  id?: string | null;
  notes?: string | null;
  preferences?: unknown;
  chargeFrom?: string | null;
  endAt?: string | null;
  currentPeriodStartAt?: string | null;
  currentPeriodEndAt?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  membershipFrequency: MembershipCreateMembershipFrequency;
  account: MembershipCreateAccount;
  membershipOrders?: MembershipCreateMembershipOrders;
};

export type MembershipCreateMembershipOrdersConnect = MembershipOrderUniqueWhere | MembershipOrderUniqueWhere[];

export type MembershipCreateMembershipOrders = {
  connect: MembershipCreateMembershipOrdersConnect;
} | null;

export type MembershipCreateAccount = {
  connect: AccountUniqueWhere;
};

export type MembershipCreateMembershipFrequency = {
  connect: MembershipFrequencyUniqueWhere;
};

export type MembershipUniqueWhere = {
  id?: string | null;
  accountId?: string | null;
};

export type MembershipWhereMembershipOrdersNone = MembershipOrderWhere;

export type MembershipWhereMembershipOrdersEvery = MembershipOrderWhere;

export type MembershipWhereMembershipOrdersSome = MembershipOrderWhere;

export type MembershipWhereMembershipOrders = {
  some?: MembershipWhereMembershipOrdersSome;
  every?: MembershipWhereMembershipOrdersEvery;
  none?: MembershipWhereMembershipOrdersNone;
} | null;

export type MembershipWhereAccountOneOfIsNot = AccountWhere;

export type MembershipWhereAccountOneOfIs = AccountWhere;

export type MembershipWhereAccountOneOf = {
  is?: MembershipWhereAccountOneOfIs;
  isNot?: MembershipWhereAccountOneOfIsNot;
};

export type MembershipWhereAccount = AccountWhere | MembershipWhereAccountOneOf;

export type MembershipWhereMembershipFrequencyOneOfIsNot = MembershipFrequencyWhere;

export type MembershipWhereMembershipFrequencyOneOfIs = MembershipFrequencyWhere;

export type MembershipWhereMembershipFrequencyOneOf = {
  is?: MembershipWhereMembershipFrequencyOneOfIs;
  isNot?: MembershipWhereMembershipFrequencyOneOfIsNot;
};

export type MembershipWhereMembershipFrequency = MembershipFrequencyWhere | MembershipWhereMembershipFrequencyOneOf;

export type MembershipWhereDeletedAt = string | MembershipWhereDeletedAtOneOf;

export type MembershipWhere = {
  AND?: MembershipWhereAnd;
  OR?: MembershipWhereOr;
  NOT?: MembershipWhereNot;
  id?: MembershipWhereId;
  notes?: MembershipWhereNotes;
  membershipFrequencyId?: MembershipWhereMembershipFrequencyId;
  accountId?: MembershipWhereAccountId;
  preferences?: MembershipWherePreferences;
  chargeFrom?: MembershipWhereChargeFrom;
  endAt?: MembershipWhereEndAt;
  currentPeriodStartAt?: MembershipWhereCurrentPeriodStartAt;
  currentPeriodEndAt?: MembershipWhereCurrentPeriodEndAt;
  createdAt?: MembershipWhereCreatedAt;
  updatedAt?: MembershipWhereUpdatedAt;
  deletedAt?: MembershipWhereDeletedAt;
  membershipFrequency?: MembershipWhereMembershipFrequency;
  account?: MembershipWhereAccount;
  membershipOrders?: MembershipWhereMembershipOrders;
};

export type MembershipWhereDeletedAtOneOfNot = string | DeletedAt;

export type MembershipWhereDeletedAtOneOfNotIn = string | string[];

export type MembershipWhereDeletedAtOneOfIn = string | string[];

export type MembershipWhereDeletedAtOneOfEquals = string;

export type MembershipWhereDeletedAtOneOf = {
  equals?: MembershipWhereDeletedAtOneOfEquals;
  in?: MembershipWhereDeletedAtOneOfIn;
  notIn?: MembershipWhereDeletedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipWhereDeletedAtOneOfNot;
};

export type MembershipWhereUpdatedAt = string | MembershipWhereUpdatedAtOneOf;

export type MembershipWhereUpdatedAtOneOfNot = string | UpdatedAt;

export type MembershipWhereUpdatedAtOneOfNotIn = string | string[];

export type MembershipWhereUpdatedAtOneOfIn = string | string[];

export type MembershipWhereUpdatedAtOneOf = {
  equals?: string | null;
  in?: MembershipWhereUpdatedAtOneOfIn;
  notIn?: MembershipWhereUpdatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipWhereUpdatedAtOneOfNot;
};

export type MembershipWhereCreatedAt = string | MembershipWhereCreatedAtOneOf;

export type MembershipWhereCreatedAtOneOfNot = string | CreatedAt;

export type MembershipWhereCreatedAtOneOfNotIn = string | string[];

export type MembershipWhereCreatedAtOneOfIn = string | string[];

export type MembershipWhereCreatedAtOneOf = {
  equals?: string | null;
  in?: MembershipWhereCreatedAtOneOfIn;
  notIn?: MembershipWhereCreatedAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipWhereCreatedAtOneOfNot;
};

export type MembershipWhereCurrentPeriodEndAtOneOfNot = string | CurrentPeriodEndAt;

export type MembershipWhereCurrentPeriodEndAtOneOfNotIn = string | string[];

export type MembershipWhereCurrentPeriodEndAtOneOfIn = string | string[];

export type MembershipWhereCurrentPeriodEndAtOneOfEquals = string;

export type MembershipWhereCurrentPeriodEndAtOneOf = {
  equals?: MembershipWhereCurrentPeriodEndAtOneOfEquals;
  in?: MembershipWhereCurrentPeriodEndAtOneOfIn;
  notIn?: MembershipWhereCurrentPeriodEndAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipWhereCurrentPeriodEndAtOneOfNot;
};

export type MembershipWhereCurrentPeriodEndAt = string | MembershipWhereCurrentPeriodEndAtOneOf;

export type MembershipWhereCurrentPeriodStartAt = string | MembershipWhereCurrentPeriodStartAtOneOf;

export type MembershipWhereCurrentPeriodStartAtOneOfNot = string | CurrentPeriodStartAt;

export type MembershipWhereCurrentPeriodStartAtOneOfNotIn = string | string[];

export type MembershipWhereCurrentPeriodStartAtOneOfIn = string | string[];

export type MembershipWhereCurrentPeriodStartAtOneOfEquals = string;

export type MembershipWhereCurrentPeriodStartAtOneOf = {
  equals?: MembershipWhereCurrentPeriodStartAtOneOfEquals;
  in?: MembershipWhereCurrentPeriodStartAtOneOfIn;
  notIn?: MembershipWhereCurrentPeriodStartAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipWhereCurrentPeriodStartAtOneOfNot;
};

export type MembershipWhereEndAt = string | MembershipWhereEndAtOneOf;

export type MembershipWhereEndAtOneOfNot = string | EndAt;

export type MembershipWhereEndAtOneOfNotIn = string | string[];

export type MembershipWhereEndAtOneOfIn = string | string[];

export type MembershipWhereEndAtOneOfEquals = string;

export type MembershipWhereEndAtOneOf = {
  equals?: MembershipWhereEndAtOneOfEquals;
  in?: MembershipWhereEndAtOneOfIn;
  notIn?: MembershipWhereEndAtOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipWhereEndAtOneOfNot;
};

export type MembershipWhereChargeFrom = string | MembershipWhereChargeFromOneOf;

export type MembershipWhereChargeFromOneOfNot = string | ChargeFrom;

export type MembershipWhereChargeFromOneOfNotIn = string | string[];

export type MembershipWhereChargeFromOneOfIn = string | string[];

export type MembershipWhereChargeFromOneOfEquals = string;

export type MembershipWhereChargeFromOneOf = {
  equals?: MembershipWhereChargeFromOneOfEquals;
  in?: MembershipWhereChargeFromOneOfIn;
  notIn?: MembershipWhereChargeFromOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  not?: MembershipWhereChargeFromOneOfNot;
};

export type MembershipWherePreferencesOneOfPath = string | string[];

export type MembershipWherePreferencesOneOf = {
  path?: MembershipWherePreferencesOneOfPath;
  equals?: unknown;
  not?: unknown;
  string_contains?: string | null;
  string_starts_with?: string | null;
  string_ends_with?: string | null;
  array_contains?: unknown;
  array_starts_with?: unknown;
  array_ends_with?: unknown;
};

export type MembershipWherePreferences = MembershipWherePreferencesOneOf;

export type MembershipWhereAccountId = string | MembershipWhereAccountIdOneOf;

export type MembershipWhereAccountIdOneOfNot = string | AccountId;

export type MembershipWhereAccountIdOneOfMode =
  typeof MembershipWhereAccountIdOneOfMode[keyof typeof MembershipWhereAccountIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipWhereAccountIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipWhereAccountIdOneOfNotIn = string | string[];

export type MembershipWhereAccountIdOneOfIn = string | string[];

export type MembershipWhereAccountIdOneOf = {
  equals?: string | null;
  in?: MembershipWhereAccountIdOneOfIn;
  notIn?: MembershipWhereAccountIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipWhereAccountIdOneOfMode;
  not?: MembershipWhereAccountIdOneOfNot;
};

export type MembershipWhereMembershipFrequencyId = string | MembershipWhereMembershipFrequencyIdOneOf;

export type MembershipWhereMembershipFrequencyIdOneOfNot = string | MembershipFrequencyId;

export type MembershipWhereMembershipFrequencyIdOneOfMode =
  typeof MembershipWhereMembershipFrequencyIdOneOfMode[keyof typeof MembershipWhereMembershipFrequencyIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipWhereMembershipFrequencyIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipWhereMembershipFrequencyIdOneOfNotIn = string | string[];

export type MembershipWhereMembershipFrequencyIdOneOfIn = string | string[];

export type MembershipWhereMembershipFrequencyIdOneOf = {
  equals?: string | null;
  in?: MembershipWhereMembershipFrequencyIdOneOfIn;
  notIn?: MembershipWhereMembershipFrequencyIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipWhereMembershipFrequencyIdOneOfMode;
  not?: MembershipWhereMembershipFrequencyIdOneOfNot;
};

export type MembershipWhereNotes = string | MembershipWhereNotesOneOf;

export type MembershipWhereNotesOneOfNot = string | Notes;

export type MembershipWhereNotesOneOfMode =
  typeof MembershipWhereNotesOneOfMode[keyof typeof MembershipWhereNotesOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipWhereNotesOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipWhereNotesOneOfNotIn = string | string[];

export type MembershipWhereNotesOneOfIn = string | string[];

export type MembershipWhereNotesOneOfEquals = string;

export type MembershipWhereNotesOneOf = {
  equals?: MembershipWhereNotesOneOfEquals;
  in?: MembershipWhereNotesOneOfIn;
  notIn?: MembershipWhereNotesOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipWhereNotesOneOfMode;
  not?: MembershipWhereNotesOneOfNot;
};

export type MembershipWhereId = string | MembershipWhereIdOneOf;

export type MembershipWhereIdOneOfNot = string | Id;

export type MembershipWhereIdOneOfMode = typeof MembershipWhereIdOneOfMode[keyof typeof MembershipWhereIdOneOfMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MembershipWhereIdOneOfMode = {
  default: 'default',
  insensitive: 'insensitive',
} as const;

export type MembershipWhereIdOneOfNotIn = string | string[];

export type MembershipWhereIdOneOfIn = string | string[];

export type MembershipWhereIdOneOf = {
  equals?: string | null;
  in?: MembershipWhereIdOneOfIn;
  notIn?: MembershipWhereIdOneOfNotIn;
  lt?: string | null;
  lte?: string | null;
  gt?: string | null;
  gte?: string | null;
  contains?: string | null;
  startsWith?: string | null;
  endsWith?: string | null;
  mode?: MembershipWhereIdOneOfMode;
  not?: MembershipWhereIdOneOfNot;
};

export type MembershipWhereNot = MembershipWhere | MembershipWhere[];

export type MembershipWhereOr = MembershipWhere | MembershipWhere[];

export type MembershipWhereAnd = MembershipWhere | MembershipWhere[];

export type MembershipMembershipOrders = MembershipOrders;

export type MembershipAccount = Account;

export type MembershipMembershipFrequency = MembershipFrequency;

export type Membership = {
  id?: string | null;
  notes?: string | null;
  membershipFrequencyId?: string | null;
  accountId?: string | null;
  preferences?: unknown;
  chargeFrom?: string | null;
  endAt?: string | null;
  currentPeriodStartAt?: string | null;
  currentPeriodEndAt?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  membershipFrequency?: MembershipMembershipFrequency;
  account?: MembershipAccount;
  membershipOrders?: MembershipMembershipOrders;
  _count?: unknown;
};

export type Memberships = {
  items?: Membership[];
};

export type MembershipIncludeMembershipOrdersOneOf = {
  select?: MembershipOrderSelect;
  include?: MembershipOrderInclude;
};

export type MembershipIncludeMembershipOrders = boolean | MembershipIncludeMembershipOrdersOneOf;

export type MembershipIncludeMembershipFrequencyOneOf = {
  select?: MembershipFrequencySelect;
  include?: MembershipFrequencyInclude;
};

export type MembershipIncludeMembershipFrequency = boolean | MembershipIncludeMembershipFrequencyOneOf;

export type MembershipInclude = {
  membershipFrequency?: MembershipIncludeMembershipFrequency;
  account?: boolean | null;
  membershipOrders?: MembershipIncludeMembershipOrders;
};

export type MembershipSelect = {
  id?: boolean | null;
  notes?: boolean | null;
  membershipFrequencyId?: boolean | null;
  accountId?: boolean | null;
  preferences?: boolean | null;
  chargeFrom?: boolean | null;
  endAt?: boolean | null;
  currentPeriodStartAt?: boolean | null;
  currentPeriodEndAt?: boolean | null;
  createdAt?: boolean | null;
  updatedAt?: boolean | null;
  deletedAt?: boolean | null;
  membershipFrequency?: boolean | null;
  account?: boolean | null;
  membershipOrders?: boolean | null;
  _count?: boolean | null;
};

export type ValidationErrorsErrorsItem = { [key: string]: any };

export type ValidationErrors = {
  message?: string;
  errors?: ValidationErrorsErrorsItem[];
  count?: string;
};
