import { FunctionComponent, MouseEvent, useState } from 'react';
import classNames, { Argument } from 'classnames';
import { FaTimes, FaCheck, FaTrashAlt } from 'react-icons/fa';
import { IconType } from 'react-icons';

export type ActionButtonsProps = {
  className?: Argument;
  action?: () => any;
  StandardIcon?: IconType;
  ConfirmIcon?: IconType;
  CancelIcon?: IconType;
  confirmationMessage?: string;
  standardIconClassName?: Argument;
  confirmIconClassName?: Argument;
  cancelIconClassName?: Argument;
  confirmationMessageClassName?: Argument;
};

export const ActionButtons: FunctionComponent<ActionButtonsProps> = ({
  className,
  action,
  StandardIcon = FaTrashAlt,
  ConfirmIcon = FaCheck,
  CancelIcon = FaTimes,
  confirmationMessage,
  standardIconClassName,
  confirmIconClassName,
  cancelIconClassName,
  confirmationMessageClassName,
}) => {
  const [actioning, setActioning] = useState(false);

  const handleSetDelete = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setActioning(true);
  };

  const handleSetDeleteFalse = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setActioning(false);
  };

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (action) {
      action();
    }
    setActioning(false);
  };

  return (
    <div className="flex flex-col">
      <div className={classNames('action-buttons w-min mt-1 flex relative z-10', className)}>
        {actioning ? (
          <>
            <div
              className={classNames(
                'text-green-500 hover:text-blue-400 hover:active:text-blue-300 border-green-500 hover:border-blue-400 hover:active:border-blue-300 w-7 h-7 ml-2 flex justify-center items-center hover:cursor-pointer border-2 rounded-md mb-1',
                confirmIconClassName,
              )}
              onClick={handleClick}
            >
              <ConfirmIcon />
            </div>
            <div
              className={classNames(
                'text-red-500 hover:text-blue-400 hover:active:text-blue-300 border-red-500 hover:border-blue-400 hover:active:border-blue-300 w-7 h-7 ml-2 flex justify-center items-center hover:cursor-pointer border-2 rounded-md',
                cancelIconClassName,
              )}
              onClick={handleSetDeleteFalse}
            >
              <CancelIcon />
            </div>
          </>
        ) : (
          <>
            <div
              className={classNames(
                'text-red-500 hover:text-blue-400 hover:active:text-blue-300 border-red-500 hover:border-blue-400 hover:active:border-blue-300 w-7 h-7 ml-2 flex justify-center items-center hover:cursor-pointer border-2 rounded-md',
                standardIconClassName,
              )}
              onClick={handleSetDelete}
            >
              <StandardIcon />
            </div>
          </>
        )}
        {actioning && confirmationMessage && (
          <div className={classNames('flex justify-center absolute top-12', confirmationMessageClassName)}>
            <div className="text-gray-500 bg-white shadow-lg rounded-md font-normal p-4 whitespace-nowrap ">
              {confirmationMessage}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
