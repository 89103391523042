import { ThScrollPage } from '@components/ui/th-scroll-page';
import { useGetManyMembershipOfferings } from '@core/clients/ecommerce-services-admin/membership-offerings/membership-offerings';
import { useSelectedSet } from '@core/hooks/useSelectedSet';
import { setPageMeta } from '@core/stores/core-store';
import { FunctionComponent, MouseEvent } from 'react';
import { BsPlus } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { OfferingCard } from '../../components/offerings/offering-card';
import { OfferingCardSkeleton } from '../../components/offerings/offering-card-skeleton';
import { MembershipRoutePaths } from '../../router/membership-routes';

export type OfferingsIndexPageProps = {};

export const OfferingsIndexPage: FunctionComponent<OfferingsIndexPageProps> = () => {
  setPageMeta('Offerings', false);

  const { selectedSet, selectClickHandler } = useSelectedSet();

  const { data, isLoading } = useGetManyMembershipOfferings({
    include: { membershipTier: true, membershipOfferingItems: { select: { id: true } } },
  });

  return (
    <ThScrollPage id="offerings-index-page">
      <div className="rounded h-16 bg-th-membership-200 p-2 flex items-center">
        <Link
          to={`${MembershipRoutePaths.OFFERING_DETAILS}new`}
          className="rounded flex bg-th-membership-500 text-white font-semibold ml-auto p-2 items-center"
        >
          <BsPlus className="text-xl font-bold"></BsPlus> Add offering
        </Link>
      </div>
      <div className="h-full w-full rounded bg-th-membership-100 p-2 mt-4">
        {isLoading && <OfferingCardSkeleton />}
        {data?.items?.length === 0 && (
          <div className="flex w-full justify-center mt-4">
            <p className=" font-semibold text-th-membership-800">No offerings found.</p>
          </div>
        )}
        {data?.items?.map((o) => (
          <Link to={`${MembershipRoutePaths.OFFERING_DETAILS}${o.id}`} key={o.id}>
            <OfferingCard
              offering={o as any}
              selected={selectedSet.has(o.id!)}
              onSelectClicked={selectClickHandler(o.id!)}
              className="bg-white rounded mb-2 p-2"
            ></OfferingCard>
          </Link>
        ))}
      </div>
    </ThScrollPage>
  );
};
