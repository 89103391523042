/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * admin-rest-api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Product,
  ValidationErrors,
  GetProductByIdParams,
  PatchProductByIdBody,
  DeleteProductByIdBody,
  PatchRestoreProductByIdBody,
  DeleteDestroyProductByIdBody,
  GetOneProductParams,
  Products,
  GetManyProductsParams,
  PostProductBody
} from '../ecommerce-services.schemas'
import { ecommerceAdminApiInstance } from '../../../apis/ecommerce-admin-api-instance'
import type { ErrorType } from '../../../apis/ecommerce-admin-api-instance'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Gets one Product by Id in path
 */
export const getProductById = (
    id: string,
    params?: GetProductByIdParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<Product>(
      {url: `/product/${id}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetProductByIdQueryKey = (id: string,
    params?: GetProductByIdParams,) => [`/product/${id}`, ...(params ? [params]: [])];

    
export type GetProductByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getProductById>>>
export type GetProductByIdQueryError = ErrorType<void | ValidationErrors>

export const useGetProductById = <TData = Awaited<ReturnType<typeof getProductById>>, TError = ErrorType<void | ValidationErrors>>(
 id: string,
    params?: GetProductByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductById>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProductByIdQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductById>>> = ({ signal }) => getProductById(id,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getProductById>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Patches one Product by Id in path
 */
export const patchProductById = (
    id: string,
    patchProductByIdBody: PatchProductByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Product>(
      {url: `/product/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchProductByIdBody
    },
      options);
    }
  


    export type PatchProductByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchProductById>>>
    export type PatchProductByIdMutationBody = PatchProductByIdBody
    export type PatchProductByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchProductById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchProductById>>, TError,{id: string;data: PatchProductByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchProductById>>, {id: string;data: PatchProductByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchProductById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchProductById>>, TError, {id: string;data: PatchProductByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Deletes one Product by Id in path
 */
export const deleteProductById = (
    id: string,
    deleteProductByIdBody: DeleteProductByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Product>(
      {url: `/product/${id}`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteProductByIdBody
    },
      options);
    }
  


    export type DeleteProductByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProductById>>>
    export type DeleteProductByIdMutationBody = DeleteProductByIdBody
    export type DeleteProductByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteProductById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProductById>>, TError,{id: string;data: DeleteProductByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProductById>>, {id: string;data: DeleteProductByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteProductById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteProductById>>, TError, {id: string;data: DeleteProductByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Restores one Product by Id in path
 */
export const patchRestoreProductById = (
    id: string,
    patchRestoreProductByIdBody: PatchRestoreProductByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Product>(
      {url: `/product/${id}/restore`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchRestoreProductByIdBody
    },
      options);
    }
  


    export type PatchRestoreProductByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchRestoreProductById>>>
    export type PatchRestoreProductByIdMutationBody = PatchRestoreProductByIdBody
    export type PatchRestoreProductByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchRestoreProductById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchRestoreProductById>>, TError,{id: string;data: PatchRestoreProductByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchRestoreProductById>>, {id: string;data: PatchRestoreProductByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchRestoreProductById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchRestoreProductById>>, TError, {id: string;data: PatchRestoreProductByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Destroys one Product by Id in path
 */
export const deleteDestroyProductById = (
    id: string,
    deleteDestroyProductByIdBody: DeleteDestroyProductByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Product>(
      {url: `/product/${id}/destroy`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteDestroyProductByIdBody
    },
      options);
    }
  


    export type DeleteDestroyProductByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDestroyProductById>>>
    export type DeleteDestroyProductByIdMutationBody = DeleteDestroyProductByIdBody
    export type DeleteDestroyProductByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteDestroyProductById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDestroyProductById>>, TError,{id: string;data: DeleteDestroyProductByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDestroyProductById>>, {id: string;data: DeleteDestroyProductByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteDestroyProductById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteDestroyProductById>>, TError, {id: string;data: DeleteDestroyProductByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Get one Products
 */
export const getOneProduct = (
    params?: GetOneProductParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<Product>(
      {url: `/product`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetOneProductQueryKey = (params?: GetOneProductParams,) => [`/product`, ...(params ? [params]: [])];

    
export type GetOneProductQueryResult = NonNullable<Awaited<ReturnType<typeof getOneProduct>>>
export type GetOneProductQueryError = ErrorType<void | ValidationErrors>

export const useGetOneProduct = <TData = Awaited<ReturnType<typeof getOneProduct>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetOneProductParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOneProduct>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOneProductQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneProduct>>> = ({ signal }) => getOneProduct(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOneProduct>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Get many Products
 */
export const getManyProducts = (
    params?: GetManyProductsParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<Products>(
      {url: `/products`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetManyProductsQueryKey = (params?: GetManyProductsParams,) => [`/products`, ...(params ? [params]: [])];

    
export type GetManyProductsQueryResult = NonNullable<Awaited<ReturnType<typeof getManyProducts>>>
export type GetManyProductsQueryError = ErrorType<void | ValidationErrors>

export const useGetManyProducts = <TData = Awaited<ReturnType<typeof getManyProducts>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetManyProductsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getManyProducts>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetManyProductsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getManyProducts>>> = ({ signal }) => getManyProducts(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getManyProducts>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Posts a new Product
 */
export const postProduct = (
    postProductBody: PostProductBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Product>(
      {url: `/products`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postProductBody
    },
      options);
    }
  


    export type PostProductMutationResult = NonNullable<Awaited<ReturnType<typeof postProduct>>>
    export type PostProductMutationBody = PostProductBody
    export type PostProductMutationError = ErrorType<void | ValidationErrors>

    export const usePostProduct = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postProduct>>, TError,{data: PostProductBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProduct>>, {data: PostProductBody}> = (props) => {
          const {data} = props ?? {};

          return  postProduct(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postProduct>>, TError, {data: PostProductBody}, TContext>(mutationFn, mutationOptions)
    }
    