import { FunctionComponent, MouseEvent } from 'react';
import classNames, { Argument } from 'classnames';

export type ButtonProps = {
  className?: Argument;
  varient?: 'solid' | 'outlined';
  type?: 'button' | 'submit';
  onClick?: (event: MouseEvent) => void;
};

export const Button: FunctionComponent<ButtonProps> = ({ className, children, varient, type = 'button', onClick }) => {
  return (
    <button
      onClick={onClick && onClick}
      type={type}
      className={classNames(
        'py-2 px-4 w-full rounded-md button border-2 border-th-membership-500 hover:border-th-membership-300',
        className,
        {
          'bg-th-membership-500 hover:bg-th-membership-300 text-white': varient === 'solid',
          'bg-white hover:bg-gray-100  text-th-membership-500 hover:text-th-membership-300': varient === 'outlined',
        },
      )}
    >
      {children}
    </button>
  );
};
