/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * admin-rest-api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  MembershipFrequency,
  ValidationErrors,
  GetMembershipFrequencyByIdParams,
  PatchMembershipFrequencyByIdBody,
  DeleteMembershipFrequencyByIdBody,
  PatchRestoreMembershipFrequencyByIdBody,
  DeleteDestroyMembershipFrequencyByIdBody,
  GetOneMembershipFrequencyParams,
  MembershipFrequencies,
  GetManyMembershipFrequenciesParams,
  PostMembershipFrequencyBody
} from '../ecommerce-services.schemas'
import { ecommerceAdminApiInstance } from '../../../apis/ecommerce-admin-api-instance'
import type { ErrorType } from '../../../apis/ecommerce-admin-api-instance'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Gets one Membership Frequency by Id in path
 */
export const getMembershipFrequencyById = (
    id: string,
    params?: GetMembershipFrequencyByIdParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<MembershipFrequency>(
      {url: `/membership-frequency/${id}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetMembershipFrequencyByIdQueryKey = (id: string,
    params?: GetMembershipFrequencyByIdParams,) => [`/membership-frequency/${id}`, ...(params ? [params]: [])];

    
export type GetMembershipFrequencyByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getMembershipFrequencyById>>>
export type GetMembershipFrequencyByIdQueryError = ErrorType<void | ValidationErrors>

export const useGetMembershipFrequencyById = <TData = Awaited<ReturnType<typeof getMembershipFrequencyById>>, TError = ErrorType<void | ValidationErrors>>(
 id: string,
    params?: GetMembershipFrequencyByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMembershipFrequencyById>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMembershipFrequencyByIdQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMembershipFrequencyById>>> = ({ signal }) => getMembershipFrequencyById(id,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMembershipFrequencyById>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Patches one Membership Frequency by Id in path
 */
export const patchMembershipFrequencyById = (
    id: string,
    patchMembershipFrequencyByIdBody: PatchMembershipFrequencyByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipFrequency>(
      {url: `/membership-frequency/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchMembershipFrequencyByIdBody
    },
      options);
    }
  


    export type PatchMembershipFrequencyByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchMembershipFrequencyById>>>
    export type PatchMembershipFrequencyByIdMutationBody = PatchMembershipFrequencyByIdBody
    export type PatchMembershipFrequencyByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchMembershipFrequencyById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchMembershipFrequencyById>>, TError,{id: string;data: PatchMembershipFrequencyByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchMembershipFrequencyById>>, {id: string;data: PatchMembershipFrequencyByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchMembershipFrequencyById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchMembershipFrequencyById>>, TError, {id: string;data: PatchMembershipFrequencyByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Deletes one Membership Frequency by Id in path
 */
export const deleteMembershipFrequencyById = (
    id: string,
    deleteMembershipFrequencyByIdBody: DeleteMembershipFrequencyByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipFrequency>(
      {url: `/membership-frequency/${id}`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteMembershipFrequencyByIdBody
    },
      options);
    }
  


    export type DeleteMembershipFrequencyByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMembershipFrequencyById>>>
    export type DeleteMembershipFrequencyByIdMutationBody = DeleteMembershipFrequencyByIdBody
    export type DeleteMembershipFrequencyByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteMembershipFrequencyById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMembershipFrequencyById>>, TError,{id: string;data: DeleteMembershipFrequencyByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMembershipFrequencyById>>, {id: string;data: DeleteMembershipFrequencyByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteMembershipFrequencyById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteMembershipFrequencyById>>, TError, {id: string;data: DeleteMembershipFrequencyByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Restores one Membership Frequency by Id in path
 */
export const patchRestoreMembershipFrequencyById = (
    id: string,
    patchRestoreMembershipFrequencyByIdBody: PatchRestoreMembershipFrequencyByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipFrequency>(
      {url: `/membership-frequency/${id}/restore`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchRestoreMembershipFrequencyByIdBody
    },
      options);
    }
  


    export type PatchRestoreMembershipFrequencyByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchRestoreMembershipFrequencyById>>>
    export type PatchRestoreMembershipFrequencyByIdMutationBody = PatchRestoreMembershipFrequencyByIdBody
    export type PatchRestoreMembershipFrequencyByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchRestoreMembershipFrequencyById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchRestoreMembershipFrequencyById>>, TError,{id: string;data: PatchRestoreMembershipFrequencyByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchRestoreMembershipFrequencyById>>, {id: string;data: PatchRestoreMembershipFrequencyByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchRestoreMembershipFrequencyById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchRestoreMembershipFrequencyById>>, TError, {id: string;data: PatchRestoreMembershipFrequencyByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Destroys one Membership Frequency by Id in path
 */
export const deleteDestroyMembershipFrequencyById = (
    id: string,
    deleteDestroyMembershipFrequencyByIdBody: DeleteDestroyMembershipFrequencyByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipFrequency>(
      {url: `/membership-frequency/${id}/destroy`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteDestroyMembershipFrequencyByIdBody
    },
      options);
    }
  


    export type DeleteDestroyMembershipFrequencyByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDestroyMembershipFrequencyById>>>
    export type DeleteDestroyMembershipFrequencyByIdMutationBody = DeleteDestroyMembershipFrequencyByIdBody
    export type DeleteDestroyMembershipFrequencyByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteDestroyMembershipFrequencyById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDestroyMembershipFrequencyById>>, TError,{id: string;data: DeleteDestroyMembershipFrequencyByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDestroyMembershipFrequencyById>>, {id: string;data: DeleteDestroyMembershipFrequencyByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteDestroyMembershipFrequencyById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteDestroyMembershipFrequencyById>>, TError, {id: string;data: DeleteDestroyMembershipFrequencyByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Get one Membership Frequencies
 */
export const getOneMembershipFrequency = (
    params?: GetOneMembershipFrequencyParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<MembershipFrequency>(
      {url: `/membership-frequency`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetOneMembershipFrequencyQueryKey = (params?: GetOneMembershipFrequencyParams,) => [`/membership-frequency`, ...(params ? [params]: [])];

    
export type GetOneMembershipFrequencyQueryResult = NonNullable<Awaited<ReturnType<typeof getOneMembershipFrequency>>>
export type GetOneMembershipFrequencyQueryError = ErrorType<void | ValidationErrors>

export const useGetOneMembershipFrequency = <TData = Awaited<ReturnType<typeof getOneMembershipFrequency>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetOneMembershipFrequencyParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOneMembershipFrequency>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOneMembershipFrequencyQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneMembershipFrequency>>> = ({ signal }) => getOneMembershipFrequency(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOneMembershipFrequency>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Get many Membership Frequencies
 */
export const getManyMembershipFrequencies = (
    params?: GetManyMembershipFrequenciesParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<MembershipFrequencies>(
      {url: `/membership-frequencies`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetManyMembershipFrequenciesQueryKey = (params?: GetManyMembershipFrequenciesParams,) => [`/membership-frequencies`, ...(params ? [params]: [])];

    
export type GetManyMembershipFrequenciesQueryResult = NonNullable<Awaited<ReturnType<typeof getManyMembershipFrequencies>>>
export type GetManyMembershipFrequenciesQueryError = ErrorType<void | ValidationErrors>

export const useGetManyMembershipFrequencies = <TData = Awaited<ReturnType<typeof getManyMembershipFrequencies>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetManyMembershipFrequenciesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getManyMembershipFrequencies>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetManyMembershipFrequenciesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getManyMembershipFrequencies>>> = ({ signal }) => getManyMembershipFrequencies(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getManyMembershipFrequencies>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Posts a new Membership Frequency
 */
export const postMembershipFrequency = (
    postMembershipFrequencyBody: PostMembershipFrequencyBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipFrequency>(
      {url: `/membership-frequencies`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postMembershipFrequencyBody
    },
      options);
    }
  


    export type PostMembershipFrequencyMutationResult = NonNullable<Awaited<ReturnType<typeof postMembershipFrequency>>>
    export type PostMembershipFrequencyMutationBody = PostMembershipFrequencyBody
    export type PostMembershipFrequencyMutationError = ErrorType<void | ValidationErrors>

    export const usePostMembershipFrequency = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMembershipFrequency>>, TError,{data: PostMembershipFrequencyBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMembershipFrequency>>, {data: PostMembershipFrequencyBody}> = (props) => {
          const {data} = props ?? {};

          return  postMembershipFrequency(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMembershipFrequency>>, TError, {data: PostMembershipFrequencyBody}, TContext>(mutationFn, mutationOptions)
    }
    