import { FC } from 'react';
import classNames, { Argument } from 'classnames';
import { NebPropsWithStd } from '@villageco/nebula/core';

export type THImageProps = {
  className?: Argument;
  src?: string;
  alt?: string | null;
};

export const THImage: FC<NebPropsWithStd<THImageProps>> = ({ className, src, alt }) => {
  return <img className={classNames('th-image', className)} src={src} alt={alt ?? undefined} />;
};
