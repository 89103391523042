import { SlimButton } from '@components/ui/slim-button';
import { THH3 } from '@components/ui/th-h3';
import { THImage } from '@components/ui/th-image';
import THModal from '@components/ui/th-modal';
import { THP } from '@components/ui/th-p';
import { THVideo } from '@components/ui/th-video';
import {
  File,
  FileContentType,
  FileCreate,
  Product,
} from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import { useDeleteFileById, usePostFile } from '@core/clients/ecommerce-services-admin/files/files';
import { useGetManyProducts, usePatchProductById } from '@core/clients/ecommerce-services-admin/products/products';
import { useQueryClient } from '@tanstack/react-query';
import { EmbeddedVideo, NebPropsWithStd } from '@villageco/nebula/core';
import { RichEditor } from '@villageco/nebula/forms';
import classNames from 'classnames';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { BiErrorAlt } from 'react-icons/bi';
import { FaImage, FaRegCheckCircle, FaTimes } from 'react-icons/fa';
import { FiAlertTriangle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { useGetPageDataFiles } from '../../hooks/use-get-page-data-files';
import { usePrintMoney } from '../../hooks/use-print-money';
import { EcommerceRoutePaths } from '../../router/ecommerce-routes';
import { ecommcerceState } from '../../stores';
import { PageContent, PageData } from '../../types';
import { ActionButtons } from '../core/action-buttons';
import { AdvancedSelectOptions } from '../core/advanced-select';
import { Button } from '../core/button';
import { CollapseSection } from '../core/collapse-section';
import { EditableField } from '../core/editable-field';
import { FileUpload } from '../files/file-upload';
import { ProductBlock } from './product-block';
import { ProductInfoBlock } from './product-info-block';
import { ProductSearch } from './product-search';

export type ProductPageEditFormProps = {
  product: Product;
};

const DEFUALT_CURRENCY = 'AUD';
const FEATURES_KEY = 'features';
const CONTENT_BLOCKS_KEY = 'contentBlocks';
const EXPANDABLE_INFOS_KEY = 'expandableInfo';

const compareByDate = (a: File, b: File) => {
  if (!a.createdAt || !b.createdAt) {
    return 0;
  }

  return new Date(a.createdAt)?.getTime() - new Date(b.createdAt)?.getTime();
};

export const ProductPageEditForm: FC<NebPropsWithStd<ProductPageEditFormProps>> = ({ className, product }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const storeSnap = useSnapshot(ecommcerceState);

  const {
    data: productsData,
    isLoading: productIsLoading,
    refetch,
  } = useGetManyProducts({
    include: { item: true, files: true },
    where: { status: 'ACTIVE', type: 'PURCHASE' },
  });

  const { mutateAsync: uploadFile } = usePostFile();
  const { mutateAsync: deleteFile } = useDeleteFileById();

  const { mutateAsync: patchProduct, isLoading: productIsUpdating } = usePatchProductById();

  const products = productsData?.items ?? [];

  const item =
    product?.variants?.items && product?.variants?.items?.length > 0
      ? product?.variants?.items[0]?.item
      : product?.item;

  const printer = usePrintMoney({ currency: DEFUALT_CURRENCY });

  const imageContainer = useRef<HTMLDivElement>(null);

  const [publishModalOpen, setPublishModalOpen] = useState<boolean>(false);
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const [publishSuccess, setPublishSuccess] = useState<boolean>(false);
  const [showEditRelatedProducts, setEditRelatedProducts] = useState<boolean>(false);

  const [saving, setSaving] = useState<boolean>(false);

  const [error, setError] = useState<string>('');

  const [editingPageData, setEditingPageData] = useState<PageData>();
  const [images, setImages] = useState<File[]>();
  const [draftLongDescription, setDraftLongDescription] = useState<string>();

  const {
    data: pageDataFileData,
    isLoading: pageDataFilesLoading,
    isFetching: pageDataFilesFetching,
    refetch: refetchPageDataFiles,
  } = useGetPageDataFiles(product);

  const getPageDataWithFiles = (pageData: PageData) => {
    const settingPageData = { ...pageData };
    for (let content of settingPageData?.contentBlocks ?? []) {
      content.file = pageDataFileData?.items?.find((f) => f.id === content.file?.id);
    }
    // for (let content of settingPageData.features) {
    //   content.file = pageDataFileData?.items?.find((f) => f.id === content.file?.id);
    // }
    // for (let content of settingPageData.expandableInfo) {
    //   content.file = pageDataFileData?.items?.find((f) => f.id === content.file?.id);
    // }

    setEditingPageData(settingPageData);
    return settingPageData;
  };

  useEffect(() => {
    if (!editingPageData && !pageDataFilesLoading) {
      getPageDataWithFiles((product.pageData ?? {}) as any);
    }

    if (!draftLongDescription) {
      setDraftLongDescription(editingPageData?.longDescription);
    }

    if (!images || images?.length === 0) {
      const pageData: PageData = editingPageData ?? (product.pageData as PageData);
      const files = product?.files?.items?.filter((f) => f.deletedAt === null);
      if (files?.length) {
        const primaryFile = files?.find((i) => i.id === pageData.primaryImageFileId) ?? files[0];

        setImages([primaryFile, ...files.filter((f) => f.id !== primaryFile.id)]);
      }
    }
  }, [product, pageDataFilesLoading, pageDataFilesFetching, editingPageData]);

  useEffect(() => {
    if (imageContainer?.current) {
      imageContainer.current.scrollLeft = 5000;
    }
  }, [product?.files?.items?.length, pageDataFileData, pageDataFilesLoading]);

  const relatedProducts = products?.filter((prod) => editingPageData?.relatedProductIds?.includes(prod.id!));

  const randRelatedProducts = useMemo(() => {
    return [...relatedProducts].sort(() => 0.5 - Math.random())?.slice(0, 3);
  }, [JSON.stringify(relatedProducts)]);

  const relatedProductSelectOptions: AdvancedSelectOptions[] = useMemo(() => {
    return (
      products
        ?.filter(
          (p) =>
            p.id &&
            p.id !== product.id &&
            (!editingPageData?.relatedProductIds?.length || !editingPageData?.relatedProductIds.includes(p.id)),
        )
        ?.map((p) => ({
          value: p.id!,
          searchableText: p?.item?.name ?? '',
          node: (
            <div className="flex">
              <div>
                {(p?.pageData as any)?.images && (p.pageData as any).images[0].url ? (
                  <THImage className="w-12 h-12" src={(p?.pageData as any)?.images[0]?.url} />
                ) : (
                  <div className="w-12 h-12"></div>
                )}
              </div>
              <div className="flex items-center">
                <THP className="pl-4">{p.item?.name}</THP>
              </div>
            </div>
          ),
        })) ?? []
    );
  }, [products, relatedProducts]);

  const renderRelatedProducts = (product: Product) => {
    return (
      <div className="flex py-2" key={product.id}>
        <div className="mr-2">
          {product.files?.items && product.files?.items?.length > 0 ? (
            <img className="w-12 h-12" src={product.files?.items[0].url as string} alt="" />
          ) : (
            <div className="w-12 h-12"></div>
          )}
        </div>
        <div className="flex-row flex justify-between w-full">
          <div className="flex flex-col items-start">
            <THP className="text-center text-xl font-bold">{product.name}</THP>

            <THP className="text-center">{product.description}</THP>
          </div>
          <div
            className="w-12 h-12  flex items-center justify-center text-gray-400 hover:text-red-500 hover:cursor-pointer"
            onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
              handleRelatedProductsDelete(product.id ?? '', event)
            }
          >
            <FaTimes size={24} className="" />
          </div>
        </div>
      </div>
    );
  };

  const handleRelatedProductsSelect = (value: string) => {
    if (!editingPageData) {
      return;
    }

    const updatedData = { ...editingPageData };

    updatedData.relatedProductIds.push(value);
    setEditingPageData(updatedData);
  };

  const handleRelatedProductsDelete = (value: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    if (!editingPageData) {
      return;
    }

    const updatedData = { ...editingPageData };

    updatedData.relatedProductIds = [...updatedData.relatedProductIds.filter((r) => r !== value)];
    setEditingPageData(updatedData);
  };

  const updatePageContent = (
    contentType: keyof Pick<PageData, 'features' | 'contentBlocks' | 'expandableInfo'>,
    index?: number,
    title?: string,
    body?: string,
    file?: File,
    updateFile?: boolean,
    video?: EmbeddedVideo,
    updateVideo?: boolean,
  ) => {
    if (index === undefined) {
      return;
    }

    if (!editingPageData) {
      return;
    }

    let updatedPageContent = [...editingPageData[contentType]];

    updatedPageContent[index].title = title ?? updatedPageContent[index].title;
    updatedPageContent[index].body = body ?? updatedPageContent[index].body;

    if (updateFile) {
      updatedPageContent[index].file = file;
    }

    if (updateVideo) {
      updatedPageContent[index].embeddedVideo = video;
    }

    let updatedPageData = { ...editingPageData };

    if (!updatedPageData) {
      return;
    }

    updatedPageData[contentType] = updatedPageContent;

    setEditingPageData(updatedPageData);
  };

  const handleAddPageContent = (contentType: keyof Pick<PageData, 'features' | 'contentBlocks' | 'expandableInfo'>) => {
    if (!editingPageData) {
      return;
    }

    const data = editingPageData[contentType];

    let updatedPageContent = data && Array.isArray(data) ? [...editingPageData[contentType]] : [];

    updatedPageContent.push({});

    let updatedPageData = { ...editingPageData };

    if (!updatedPageData) {
      return;
    }

    updatedPageData[contentType] = updatedPageContent;

    setEditingPageData(updatedPageData);
  };

  const handleDeletePageContent = (
    contentType: keyof Pick<PageData, 'features' | 'contentBlocks' | 'expandableInfo'>,
    index: number,
  ) => {
    if (index === undefined) {
      return;
    }

    if (!editingPageData) {
      return;
    }

    let updatedPageContent = [...editingPageData[contentType]];

    let updatedPageData = { ...editingPageData };

    if (!updatedPageData) {
      return;
    }

    updatedPageData[contentType] = updatedPageContent.filter((upc, i) => i !== index);

    setEditingPageData(updatedPageData);
  };

  const handleImageChange = (file: File, index?: number) => {
    let updatingImages = images ?? [];
    if (index !== undefined && index >= 0) {
      updatingImages[index] = file;
      setImages([...updatingImages.sort(compareByDate)]);
    } else {
      updatingImages.push(file);
      setImages([...updatingImages.sort(compareByDate)]);
    }
  };

  const handleImageDelete = (imageIndex: number) => {
    let updatingImages = images ?? [];

    if (imageIndex >= 0) {
      updatingImages.splice(imageIndex, 1);
      setImages([...updatingImages.sort(compareByDate)]);
    }
  };

  const firstImage = images && images?.length > 0 ? images[0] : null;

  const handleLongDescriptionUpdate = () => {
    if (!editingPageData) {
      setError('Failed to edit page data');
      return;
    }

    setEditingPageData({ ...editingPageData, longDescription: draftLongDescription });
  };

  const handleLongDescriptionCancel = () => {
    setDraftLongDescription(editingPageData?.longDescription);
  };

  const handleCancel = () => {
    // CHECK FOR DIF
    const diff = true;
    if (diff) {
      setCancelModalOpen(true);
    } else {
      handleCancelConfirm();
    }
  };

  const handleCancelClose = () => {
    setCancelModalOpen(false);
  };

  const handleCancelConfirm = () => {
    navigate(EcommerceRoutePaths.PRODUCTS_INDEX);
  };

  const handlePublish = () => {
    setPublishModalOpen(true);
  };

  const handlePublishCancel = () => {
    setPublishModalOpen(false);
  };

  const uploadPageDataFiles = async (pageContents: PageContent[]) => {
    const returnContents: PageContent[] = [];
    for (var updatedContent of pageContents) {
      if (
        updatedContent?.file?.extension &&
        updatedContent?.file?.oringinalFilename &&
        updatedContent?.file?.contentType &&
        updatedContent?.file?.url
      ) {
        if (!updatedContent.file?.id) {
          const newFile = updatedContent.file;

          const file = await uploadFile({
            data: { data: { ...(newFile as FileCreate) } },
          });

          //updatedContent.file = { id: file.id as string };
          returnContents.push({ ...updatedContent, file: { id: file.id as string } });
        } else {
          returnContents.push({ ...updatedContent, file: { id: updatedContent.file.id as string } });
          //updatedContent.file = { id: updatedContent.file.id as string };
        }
      } else {
        returnContents.push(updatedContent);
      }
    }
    return returnContents;
  };

  const handlePublishConfirm = async () => {
    setSaving(true);
    if (!product.id) {
      setError("Can't save product as no id is present");
      return;
    }

    const prodData = { ...product };

    const updatedImages = images ?? [];

    for (let updatedImage of updatedImages) {
      if (!updatedImage?.id) {
        if (
          updatedImage?.extension &&
          updatedImage?.oringinalFilename &&
          updatedImage?.contentType &&
          updatedImage?.url
        ) {
          const file = await uploadFile({
            data: { data: { ...(updatedImage as FileCreate), product: { connect: { id: product.id } } } },
          });

          updatedImage = file;
        }
      }
    }

    const updatedImageIds = updatedImages?.map((i) => i.id);

    const deletedIds = (prodData.files?.items
      ?.filter((i) => i.deletedAt === null && !updatedImageIds.includes(i.id))
      ?.map((i) => i.id) ?? []) as string[];

    for (let deletedId of deletedIds) {
      await deleteFile({ id: deletedId, data: {} });
    }

    const updatedData = editingPageData ?? {
      contentBlocks: [],
      expandableInfo: [],
      features: [],
      relatedProductIds: [],
      longDescription: '',
      primaryImageFileId: '',
    };

    let updatedPageData: PageData = {
      ...updatedData,
      primaryImageFileId: updatedImages?.length ? updatedImages[0].id ?? '' : '',
    };

    updatedPageData.contentBlocks = await uploadPageDataFiles(updatedPageData.contentBlocks ?? []);
    // updatedPageData.expandableInfo = await uploadPageDataFiles(updatedPageData.expandableInfo ?? []);
    // updatedPageData.features = await uploadPageDataFiles(updatedPageData.features ?? []);

    const result = await patchProduct({
      id: product.id,
      data: {
        data: { pageData: { ...updatedPageData } },
        include: { files: true, item: true },
      },
    });

    if (result) {
      setPublishSuccess(true);
      await refetch();
      await refetchPageDataFiles();
      setSaving(false);
      // if (updatedPageData) {
      //   setEditingPageData(getPageDataWithFiles(updatedPageData as PageData));
      // }
    }

    // setTimeout(() => {
    //   setPublishModalOpen(false);
    //   setPublishSuccess(false);
    // }, 3000);

    //navigate(EcommerceRoutePaths.PRODUCTS_INDEX);
  };

  return (
    <div className={classNames('product-page-edit-form container mx-auto', className)}>
      <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start">
        <div className="flex flex-col w-full md:w-[31.25rem] mb-4">
          <div
            className={classNames('w-full', {
              'aspect-square': firstImage?.contentType === 'IMAGE',
              'aspect-video': firstImage?.contentType === 'VIDEO',
            })}
          >
            {firstImage?.url ? (
              <>
                {firstImage.contentType === FileContentType.VIDEO ? (
                  <THVideo src={firstImage?.url} extension={firstImage.extension ?? ''} className="w-full" />
                ) : (
                  <THImage src={firstImage?.url} alt="" className="w-full" />
                )}
              </>
            ) : (
              <div className="bg-gray-100 w-full h-[500px] flex justify-center items-center">
                <FaImage size={72} className="text-gray-500" />
              </div>
            )}
          </div>
          <div className="flex mt-4 overflow-x-visible h-auto">
            {/*ref={imageContainer}*/}
            {images?.map((image, index) => {
              return (
                <div key={`preview-image-${index}-${image?.url ?? image?.id}`} className="flex flex-col">
                  <FileUpload
                    value={image}
                    id={`preview-image-${index}`}
                    className="mr-2 w-12 h-12"
                    onChange={(file: File) => {
                      handleImageChange(file, index);
                    }}
                  />
                  <div>
                    <ActionButtons
                      action={() => handleImageDelete(index)}
                      className="flex-col-reverse items-center"
                      standardIconClassName="!ml-0 mr-2"
                      confirmIconClassName="!ml-0 mr-2"
                      cancelIconClassName="!ml-0 mr-2 mb-1"
                      confirmationMessage="Remove image?"
                      confirmationMessageClassName="top-20"
                    />
                  </div>
                </div>
              );
            })}
            <FileUpload
              className="h-12"
              onChange={(file: File) => handleImageChange(file, undefined)}
              id="new-upload"
            />
          </div>
        </div>
        <div className="flex flex-col w-full md:w-[29.5rem]">
          <div className="flex justify-between">
            <div className="flex flex-col w-full">
              <THH3>{product?.name}</THH3>
              <THH3 light={true}>{product?.description ? product?.description : '(No Description)'}</THH3>

              <div className="flex mt-1">
                <THP>{printer.format(item?.sellEx ?? 0)}</THP>
                <THP className="font-bold ml-2">{printer.format(item?.sellGst ?? 0)}</THP>
              </div>
            </div>
          </div>
          <div className="flex mt-6 mb-8 justify-between"></div>
          <div className="w-full pb-8"></div>
          <CollapseSection title="What is it?">
            <EditableField
              fieldName="draftLongDescription"
              inputNode={
                <RichEditor
                  name="draftProduct.description"
                  id="draftProduct.description"
                  value={draftLongDescription ?? ''}
                  onChange={setDraftLongDescription}
                  className="pl-0 w-full max-w-[26rem] h-fit"
                />
              }
              displayNode={
                <THP className="">
                  {editingPageData?.longDescription ? (
                    <div
                      className="[&_a]:text-gl-pink-primary"
                      dangerouslySetInnerHTML={{ __html: editingPageData?.longDescription }}
                    ></div>
                  ) : (
                    '(No Description added - This section will be hidden)'
                  )}
                </THP>
              }
              onChangeConfirmed={handleLongDescriptionUpdate}
              onCancel={handleLongDescriptionCancel}
            />
          </CollapseSection>
          <CollapseSection title="Features">
            {editingPageData?.features?.map((feature, index) => (
              <ProductInfoBlock
                key={`feature-${index}`}
                body={feature?.body}
                name={`feature-${index}`}
                title={feature.title}
                mediaFile={feature.file}
                className={classNames({ 'mt-8': index > 0 })}
                index={index}
                mediaPosition="bottom"
                onUpdate={(index, title, body, file) => updatePageContent(FEATURES_KEY, index, title, body, file)}
                hideImage
                editable
                deleteMessage="Remove feature?"
                onDelete={() => handleDeletePageContent(FEATURES_KEY, index)}
              />
            ))}
            <div className="flex justify-end mt-8">
              <SlimButton
                className="flex items-center text-gray-500 hover:text-gray-400"
                onClick={() => handleAddPageContent(FEATURES_KEY)}
              >
                {editingPageData?.features?.length === 0 ? 'Add feature' : 'Add more features'}
              </SlimButton>
            </div>
          </CollapseSection>

          {editingPageData?.expandableInfo?.map((info, index) => {
            return (
              <CollapseSection
                key={`info-snippet-${index}`}
                title={info.title ?? ''}
                hideTitleWhenOpen
                editable
                onDelete={() => handleDeletePageContent(EXPANDABLE_INFOS_KEY, index)}
              >
                <ProductInfoBlock
                  key={`info-snippet-${index}`}
                  name={`info-snippet-${index}`}
                  hideImage
                  body={info?.body}
                  title={info.title}
                  mediaFile={info.file}
                  index={index}
                  mediaPosition="bottom"
                  onUpdate={(index, title, body, file) =>
                    updatePageContent(EXPANDABLE_INFOS_KEY, index, title, body, file)
                  }
                />
              </CollapseSection>
            );
          })}
          <div className="flex justify-end pt-8 mb-6 border-t-2 border-gray-400">
            <SlimButton
              className="flex items-center text-gray-500 hover:text-gray-400"
              onClick={() => handleAddPageContent(EXPANDABLE_INFOS_KEY)}
            >
              {editingPageData?.expandableInfo?.length === 0 ? 'Add expanding section' : 'Add more expanding sections'}
            </SlimButton>
          </div>

          <CollapseSection title="Similar items" shouldOpen={true} locked={true}>
            {randRelatedProducts?.length > 0 ? (
              <div className="grid md: grid-cols-3 gap-6">
                {randRelatedProducts?.map((rp) => {
                  return <ProductBlock product={rp} showDescription={false} key={rp.name} />;
                })}
              </div>
            ) : (
              <div className="">Up to 3 items will be shown here from the selected related products</div>
            )}
            <div className="flex justify-end mt-8">
              <SlimButton
                className="flex items-center text-gray-500 hover:text-gray-400"
                onClick={() => setEditRelatedProducts(true)}
              >
                Edit related products
              </SlimButton>
            </div>
          </CollapseSection>
        </div>
      </div>
      <div className="flex flex-col mt-8">
        {editingPageData?.contentBlocks?.map((mb: any, index: any) => {
          return (
            <ProductInfoBlock
              key={`media-block-${index}`}
              name={`media-block-${index}`}
              title={mb.title}
              body={mb.body}
              mediaFile={mb.file}
              embeddedVideo={mb.embeddedVideo}
              mediaPosition={index % 2 === 1 ? 'left' : 'right'}
              index={index}
              className="py-10"
              bodyClassName={classNames('mt-8', {
                'px-4 lg:pl-0 lg:pr-12': mb.file && index % 2 === 0,
                'px-4 lg:pr-0 lg:pl-12': mb.file && index % 2 === 1,
              })}
              mediaClassName="px-24 md:px-0"
              onUpdate={(index, title, body, file, updateFile, video, updateVideo) =>
                updatePageContent(CONTENT_BLOCKS_KEY, index, title, body, file, updateFile, video, updateVideo)
              }
              editable
              deleteMessage="Remove content block?"
              onDelete={() => handleDeletePageContent(CONTENT_BLOCKS_KEY, index)}
            />
          );
        })}

        <div className="flex justify-end mt-8">
          <SlimButton
            className="flex items-center text-gray-500 hover:text-gray-400"
            onClick={() => handleAddPageContent(CONTENT_BLOCKS_KEY)}
          >
            {editingPageData?.contentBlocks?.length === 0 ? 'Add content block' : 'Add more content blocks'}
          </SlimButton>
        </div>
      </div>
      <div className="grid grid-cols-2 my-8">
        <div></div>
        <div className="grid grid-cols-2 gap-8">
          <div>
            <Button onClick={handlePublish} variant="solid">
              Publish
            </Button>
          </div>
          <div>
            <Button onClick={handleCancel} variant="outlined">
              Close
            </Button>
          </div>
        </div>
      </div>
      <THModal open={publishModalOpen} onClose={() => setPublishModalOpen(false)}>
        <div className="flex items-center flex-col p-4">
          <div className="w-full text-left border-b-2 pb-2 flex items-center justify-between">
            <THH3>Confirm Page Publish</THH3>
            {publishSuccess ? (
              <div className="text-green-500 text-3xl">
                <FaRegCheckCircle />
              </div>
            ) : (
              <div className="text-orange-500 text-3xl">
                <FiAlertTriangle />
              </div>
            )}
          </div>
          {publishSuccess ? (
            <div className="min-w-[400px]">
              <div className="py-4 border-b-2 flex items-center justify-between">
                <THP className="text-lg">Product successfully published</THP>
              </div>
              <div className="flex space-x-4 pt-4 w-full justify-end">
                <Button
                  variant="outlined"
                  disabled={productIsUpdating}
                  onClick={() => {
                    setPublishModalOpen(false);
                    setPublishSuccess(false);
                  }}
                  className="max-w-[120px]"
                >
                  Back
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div className="py-4 border-b-2">
                <THP>
                  Clicking <span className="font-bold">Confirm</span> will publish your changes to the Ecommerce Site
                </THP>
                <THP>Make sure your changes are correct!</THP>
              </div>
              <div className="flex space-x-4 pt-4 w-full justify-end">
                <Button loading={saving} onClick={handlePublishConfirm} className="max-w-[120px]">
                  Confirm
                </Button>
                <Button
                  variant="outlined"
                  disabled={productIsUpdating}
                  onClick={handlePublishCancel}
                  className="max-w-[120px]"
                >
                  Cancel
                </Button>
              </div>
            </>
          )}
        </div>
      </THModal>
      <THModal open={cancelModalOpen} onClose={() => setCancelModalOpen(false)}>
        <div className="flex items-center flex-col p-4">
          <div className="w-full text-left border-b-2 pb-2 flex items-center justify-between">
            <THH3>Leaving?</THH3>
            <div className="text-red-500 text-3xl">
              <BiErrorAlt />
            </div>
          </div>
          <div className="py-4 border-b-2">
            <THP>
              Are you sure you want to leave? Any <span className="font-bold">unsaved changes</span> will be lost
            </THP>
          </div>
          <div className="flex space-x-4 pt-4 w-full justify-end">
            <Button onClick={handleCancelConfirm} className="max-w-[100px]">
              Yes
            </Button>
            <Button variant="outlined" onClick={handleCancelClose} className="max-w-[100px]">
              No
            </Button>
          </div>
        </div>
      </THModal>
      <THModal open={showEditRelatedProducts} onClose={() => setEditRelatedProducts(false)} size="lg">
        <div className="flex items-center flex-col p-4">
          <div className="w-full text-left border-b-2 pb-2 flex items-center justify-between">
            <THH3>Edit Related Products</THH3>
          </div>
          <div className="py-4 border-b-2 w-full">
            <ProductSearch
              onChange={handleRelatedProductsSelect}
              options={relatedProductSelectOptions}
              optionsClassName="max-h-72"
            ></ProductSearch>
          </div>
          <div className="py-4 border-b-2 w-full max-h-80">
            {relatedProducts?.length > 0 ? (
              <div className="">
                {relatedProducts?.map((rp, index) => {
                  return renderRelatedProducts(rp);
                })}
              </div>
            ) : (
              <div className="">No products selected</div>
            )}
          </div>
          <div className="flex space-x-4 pt-4 w-full justify-end">
            <Button variant="outlined" onClick={() => setEditRelatedProducts(false)} className="max-w-[100px]">
              Back
            </Button>
          </div>
        </div>
      </THModal>
    </div>
  );
};
