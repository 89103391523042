import { Membership } from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';

const currentDateString = new Date()?.toISOString();

export const sortMembershipsByUpdatedAtDesc = (membershipA: Membership, membershipB: Membership) => {
  return (
    new Date(membershipB?.updatedAt ?? currentDateString)?.getTime() -
    new Date(membershipA?.updatedAt ?? currentDateString)?.getTime()
  );
};
