import React from 'react';

export const useClickOutside = (ref?: HTMLDivElement | null, callback?: () => void, disabled?: boolean) => {
  const handleClick = (e: any) => {
    if (disabled === false) {
      if (ref && ref !== e.target && !ref.contains(e?.target) && callback) {
        callback();
      }
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });
};
