import { QueryKey } from '@tanstack/react-query';
import { proxy } from 'valtio';

export type EcommcerceStoreState = {
  productsIndexQueryKey?: QueryKey;
  ordersIndexQueryKey?: QueryKey;
  shipmentRunIndexQueryKey?: QueryKey;
  itemsIndexQueryKey?: QueryKey;
};

export const ecommcerceState = proxy<EcommcerceStoreState>({
  productsIndexQueryKey: undefined,
  ordersIndexQueryKey: undefined,
  shipmentRunIndexQueryKey: undefined,
  itemsIndexQueryKey: undefined,
});
