import { NebPropsWithStd } from '@villageco/nebula/core';
import classNames from 'classnames';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';

export type TooltipProps = {
  content: ReactNode;
  contentClassName?: never;
  position?: 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right';
} & {
  contentClassName?: string;
  position?: never;
};

export const Tooltip: FC<NebPropsWithStd<TooltipProps>> = ({
  className,
  contentClassName,
  content,
  position = 'bottom-left',
  children,
}) => {
  const [show, setShow] = useState(false);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

  const onMouseEnter = () => {
    setShow(true);
  };

  const onMouseLeave = () => {
    setShow(false);
  };

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      setContainerSize({ width, height });
    }
  }, [children]);

  const positionStyle = {
    top: position.includes('top') ? -containerSize.height : undefined,
    bottom: position.includes('bottom') ? -containerSize.height : undefined,
    left: position.includes('left') ? 0 : undefined,
    right: position.includes('right') ? 0 : undefined,
  };

  const positionClass = {
    '-mt-1': !contentClassName && position.includes('top'),
    '-mb-1': !contentClassName && position.includes('bottom'),
    'mr-1': !contentClassName && position.includes('left'),
    'ml-1': !contentClassName && position.includes('right'),
  };

  return (
    <div
      ref={containerRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={classNames('tooltip relative', className)}
    >
      {children}
      <div
        style={positionStyle}
        className={classNames(contentClassName, positionClass, 'absolute', {
          'opacity-0': !show,
          'opacity-100': show,
          'bg-gray-100 text-slate-600 py-1 px-2 rounded shadow-xl transition-opacity duration-200': !contentClassName,
        })}
      >
        {content}
      </div>
    </div>
  );
};
