import { FC, PropsWithChildren } from 'react';
import { NebPropsWithStd } from '@villageco/nebula/core';

export type OfferingDetailsSkeletonProps = {};

export const OfferingDetailsSkeleton: FC<PropsWithChildren<OfferingDetailsSkeletonProps>> = () => {
  return (
    <div className="offering-details-skeleton w-full h-48 bg-slate-100 p-2 rounded">
      <div className="animate-pulse">
        <div className="w-full h-8 bg-slate-200 rounded"></div>

        <div className="flex mt-4 space-x-4">
          <div className="w-full h-8 bg-slate-200 rounded"></div>
          <div className="w-full h-8 bg-slate-200 rounded"></div>
        </div>

        <div className="flex mt-2 space-x-4">
          <div className="w-full h-8 bg-slate-200 rounded"></div>
          <div className="w-full h-8 bg-slate-200 rounded"></div>
        </div>

        <div className="flex mt-4 w-full space-x-4">
          <div className="ml-auto w-32 h-8 bg-slate-200 rounded"></div>
          <div className="w-32 h-8 bg-slate-200 rounded"></div>
        </div>
      </div>
    </div>
  );
};
