import { PropsWithChildren, ReactElement, useState } from 'react';
import { FC } from 'react';
import { useCurrentAuthenticatedUser } from '../hooks/use-current-authenticated-user';

export type CreateRequireGuestGuardProps = {
  authenticatedElement: FC;
};

export type RequireGuestGuardProps = {
  authenticatedElement?: FC;
};

export const createRequireGuestGuard = ({ authenticatedElement: defaultUnauth }: CreateRequireGuestGuardProps) => {
  const RequireGuestGuard: FC<PropsWithChildren<RequireGuestGuardProps>> = ({ children, authenticatedElement }) => {
    const UnauthEl = authenticatedElement ?? defaultUnauth;

    const { loading, user } = useCurrentAuthenticatedUser();

    return loading ? <></> : user ? <UnauthEl></UnauthEl> : (children as ReactElement);
  };

  return {
    RequireGuestGuard,
  };
};
