import classnames from 'classnames';
import { FC } from 'react';
import { CognitoUserAttributes } from '../../auth/amplify/og/types';
import { getInitials } from '../../core';

export type UserProfileImageProps = {
  userAttributes: (CognitoUserAttributes & { picture: string }) | null;
  className?: string;
  styles?: {
    fallbackClassName?: string;
  };
};

export const UserProfileImage: FC<UserProfileImageProps> = ({ className, styles, userAttributes }) => {
  return (
    <div className={classnames('user-profile-image', className)}>
      <div className="h-12 w-12 rounded-full flex justify-center items-center overflow-hidden">
        {userAttributes?.picture ? (
          <img src={userAttributes?.picture} referrerPolicy="no-referrer"></img>
        ) : (
          <div
            className={classnames(
              styles?.fallbackClassName,
              'h-full w-full flex items-center justify-center tracking-tighter',
            )}
          >
            {getInitials(userAttributes?.name ?? '')}
          </div>
        )}
      </div>
    </div>
  );
};
