import { SelectBox } from '@components/ui/select-box';
import { File, Item, Product } from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import classNames from 'classnames';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { FC, MouseEvent } from 'react';

export type ProductCardProps = {
  product: Product & { files: File[]; item: Item };
  selected?: boolean;
  onSelectClicked: (evt: MouseEvent) => void;
};

export const ProductCard: FC<NebPropsWithStd<ProductCardProps>> = ({
  className,
  selected,
  product,
  onSelectClicked,
}) => {
  return (
    <div className={classNames('product-card flex', className)}>
      <div className="w-16 h-16 flex items-center" onClick={onSelectClicked}>
        <SelectBox selected={selected} deselectedContainerClass="border border-gray-200">
          {' '}
        </SelectBox>
      </div>
      <div>
        <div className="flex">
          <p>{product.name}</p>
          <p
            className={classNames(
              { 'bg-green-300 text-green-700': product.status === 'ACTIVE' },
              { 'bg-gray-300 text-gray-700': product.status === 'DISABLED' },
              'rounded p-1 lowercase font-medium ml-4',
            )}
          >
            {product.status}
          </p>
          <p className="rounded bg-gray-500 text-white p-1 ml-4">{product.slug}</p>
        </div>
        {/* <div className="flex">
          <p>{product.item.sellEx}</p>
          <p>{product.item.costEx}</p>
        </div> */}
      </div>
    </div>
  );
};
