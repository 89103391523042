import { CSSProperties, FunctionComponent, useRef, useState, useMemo, useEffect } from 'react';
import classNames, { Argument } from 'classnames';
import { BsChevronUp } from 'react-icons/bs';
import { THP } from '@components/ui/th-p';
import { useClickOutside } from '@villageco/nebula/core';

export type AdvancedSelectOptions = {
  value: string;
  searchableText?: string;
  node: React.ReactNode;
};

export type AdvancedSelectProps = {
  className?: Argument;
  options?: AdvancedSelectOptions[];
  optionsClassName?: Argument;
  disabled?: boolean;
  onChange?: (value: string) => void;
  title?: string;
};

const defaultModalStyle: CSSProperties = {
  top: '0px',
  left: '0px',
  minWidth: '0px',
};

export const ProductSearch: FunctionComponent<AdvancedSelectProps> = ({
  className,
  options = [],
  optionsClassName,
  onChange,
  disabled,
  title,
}) => {
  const [filter, setFilter] = useState<string>('');

  const filteredOptions = useMemo(() => {
    return (
      options?.filter(
        (opt) =>
          opt.searchableText &&
          (!filter ||
            opt.searchableText?.toLowerCase()?.includes(filter?.toLowerCase()) ||
            filter?.toLowerCase().includes(opt.searchableText?.toLowerCase())),
      ) ?? []
    );
  }, [options, filter]);

  const handleChange = (val: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    if (onChange) {
      onChange(val);
    }
  };

  const selectField = useRef<HTMLDivElement>(null);

  const handleClickAway = () => {
    setFilter('');
  };

  useClickOutside(selectField.current, handleClickAway);

  const handleSelectClick = () => {
    setFilter('');
  };

  return (
    <div className={classNames('bs-select w-full relative ', className)} ref={selectField}>
      {!disabled && (
        <div className=" z-40 rounded-b shadow-lg flex flex-col">
          <div className="px-4 py-1 w-full bg-white text-left shadow-md z-50">
            <input
              placeholder="search"
              className="h-10 outline-none w-full"
              value={filter}
              onChange={(event) => setFilter(event.currentTarget.value)}
            />
          </div>
          <div className={classNames('max-h-72 overflow-y-scroll', optionsClassName)}>
            {filteredOptions?.length > 0 ? (
              <>
                {filteredOptions?.map((opt, index) => {
                  return (
                    <button
                      key={`options-${opt.value}`}
                      type="button"
                      className={classNames('p-2 px-4 w-full bg-white hover:bg-gray-100 text-left', {
                        'rounded-b-md': index === options?.length - 1,
                      })}
                      onClick={(event) => handleChange(opt.value, event)}
                    >
                      {opt.node}
                    </button>
                  );
                })}
              </>
            ) : (
              <div className="flex items-center p-3 px-4 h-16 w-full bg-white hover:bg-gray-100 text-left rounded-b-md">
                No products found
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
