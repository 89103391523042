import classnames, { Argument } from 'classnames';
import { omit } from 'lodash-es';
import { ElementType, PropsWithChildren, ReactElement } from 'react';
import { FallbackIcon } from '../../core';
import { BaseLinkProps, GenericLink } from '../../core/types';

export type SidebarLinkProps<L extends GenericLink> = BaseLinkProps<L> & {
  icon?: ElementType;
  className?: Argument;
  inGroup?: boolean;
  styles?: {
    bgColor?: Argument;
    textColor?: Argument;
  };
};

export const SidebarLink = <L extends GenericLink>(props: PropsWithChildren<SidebarLinkProps<L>>): ReactElement => {
  const { icon: Icon, children, className, inGroup, styles, linkComponent: Link } = props;

  const linkProps = omit(props, ['className', 'inGroup', 'icon', 'styles', 'linkComponent']);

  return (
    <Link {...linkProps} className={classnames('relative flex h-14 w-full mt-2', props.className)}>
      {/* <a className="relative flex h-14 w-full mt-2"> */}
      <div className={classnames(className, 'sidebar-link w-full hover:bg-gray-200 rounded')}>
        <div className="flex items-center w-full h-full">
          {inGroup ? (
            Icon ? (
              <div
                className={classnames(
                  styles?.bgColor,
                  'left-0 top-0 rounded w-12 h-full flex justify-center items-center',
                )}
              >
                <Icon className={styles?.textColor}></Icon>
              </div>
            ) : (
              <></>
            )
          ) : (
            <div
              className={classnames(
                styles?.bgColor,
                'left-0 top-0 rounded w-12 mr-2 shrink-0 h-full flex justify-center items-center',
              )}
            >
              <FallbackIcon
                icon={Icon}
                text={children}
                textClassName={styles?.textColor}
                iconClassName={styles?.textColor}
              ></FallbackIcon>
            </div>
          )}
          {/* {Icon ? (
              
                <Icon className={styles?.textColor}></Icon>
              </div>
            ) : null} */}
          <span
            className={classnames(
              {
                'text-sm': inGroup,
              },
              'text-gray-700 font-medium whitespace-nowrap',
            )}
          >
            {children}
          </span>
        </div>
      </div>
      {/* </a> */}
    </Link>
  );
};
