import { Link } from '@core/clients/bitey-services-admin/bitey-services.schemas';
import { Popover } from '@headlessui/react';
import { NebPropsWithStd } from '@villageco/nebula/core';
import classNames from 'classnames';
import { FC, MouseEvent, useState } from 'react';
import { AiFillAndroid } from 'react-icons/ai';
import { BiCopy } from 'react-icons/bi';
import { BsApple } from 'react-icons/bs';
import { FiExternalLink } from 'react-icons/fi';
import { GoBrowser } from 'react-icons/go';

export type LinkCardProps = {
  link: Link;
};

export const LinkCard: FC<NebPropsWithStd<LinkCardProps>> = ({ link, className }) => {
  const [showCopied, setShowCopied] = useState(false);

  const linkCopyClicked = (link: Link) => (evt: MouseEvent) => {
    evt.preventDefault();
    navigator.clipboard.writeText(`https://grubl.ink/${link.path}`);

    setShowCopied(true);

    setTimeout(() => {
      setShowCopied(false);
    }, 1500);
  };

  return (
    <div className={classNames('link-card', className)}>
      <div className="flex text-slate-800 items-stretch">
        <div className="truncate w-[25rem] flex flex-col items-start space-y-2">
          <div className="flex items-center">
            <p className="text-lg">{link.name}</p>
            {link.deletedAt && <div className="rounded px-1 bg-red-600 text-white ml-4 font-semibold">Trashed</div>}
          </div>
          <div className="flex">
            <div
              className={classNames(
                'rounded bg-slate-300 flex items-center justify-start px-2 text-slate-600 py-1 text-sm truncate hover:bg-slate-200 active:bg-slate-400',
                {
                  'pointer-events-none': link.deletedAt,
                },
              )}
              onClick={linkCopyClicked(link)}
            >
              https://grubl.ink/{link.path}
              {!link.deletedAt && <BiCopy className="ml-2"></BiCopy>}
            </div>

            <div
              className={classNames(
                'ml-2 font-semibold transition-opacity duration-200 text-sm rounded bg-slate-100 p-1',
                {
                  'opacity-100': showCopied,
                  'opacity-0': !showCopied,
                },
              )}
            >
              Link copied
            </div>
          </div>
        </div>

        <Popover className="relative ml-auto">
          <Popover.Button
            className="flex focus:outline-none hover:bg-slate-50 space-x-4 border border-slate-200 p-2 h-full items-center rounded"
            disabled={!!link.deletedAt}
          >
            <div
              className={classNames('rounded  text-white h-8 w-8 flex justify-center items-center', {
                'bg-green-300': link.iosUrl,
                'bg-slate-400': !link.iosUrl,
              })}
            >
              <BsApple className="w-5 h-5"></BsApple>
            </div>
            <div
              className={classNames('rounded  text-white h-8 w-8 flex justify-center items-center', {
                'bg-green-300': link.androidUrl,
                'bg-slate-400': !link.androidUrl,
              })}
            >
              <AiFillAndroid className="w-6 h-6"> </AiFillAndroid>
            </div>
            <div
              className={classNames('rounded  text-white h-8 w-8 flex justify-center items-center', {
                'bg-green-300': link.defaultUrl,
                'bg-slate-400': !link.defaultUrl,
              })}
            >
              <GoBrowser className="w-6 h-6"> </GoBrowser>
            </div>
          </Popover.Button>
          <Popover.Panel className="absolute z-10 mt-2 w-[35rem] right-0 shadow-xl border border-slate-200 rounded">
            <div
              className="bg-white rounded p-2 cursor-default"
              onClick={(evt) => {
                evt.preventDefault();
                evt.stopPropagation();
              }}
            >
              <p className="font-semibold text-slate-500">Destinations</p>
              <div className="flex flex-col mt-4 space-y-2">
                <a
                  href={link.iosUrl ?? ''}
                  target="_blank"
                  onClick={(evt) => {
                    !link.iosUrl && evt.preventDefault();
                    evt.stopPropagation();
                  }}
                  className={classNames('flex w-full  rounded p-2 text-slate-600 items-center', {
                    'bg-green-100 cursor-pointer ': link.iosUrl,
                    'cursor-default': !link.iosUrl,
                  })}
                >
                  <div className="flex w-28 items-center">
                    <BsApple className="w-5 h-5"></BsApple>
                    <span className="text-medium ml-2 text-sm mr-6">iOS/Mac</span>
                  </div>

                  <span className="text-sm text-slate-700 truncate">{link.iosUrl}</span>
                  {link.iosUrl && <FiExternalLink className="ml-auto" />}
                </a>
                <a
                  href={link.androidUrl ?? ''}
                  target="_blank"
                  onClick={(evt) => {
                    !link.androidUrl && evt.preventDefault();
                    evt.stopPropagation();
                  }}
                  className={classNames('flex w-full  rounded p-2 text-slate-600 items-center', {
                    'bg-green-100 cursor-pointer': link.androidUrl,
                    'cursor-default': !link.androidUrl,
                  })}
                >
                  <div className="flex w-28 items-center">
                    <AiFillAndroid className="w-6 h-6"> </AiFillAndroid>
                    <span className="text-medium ml-2 text-sm mr-6">Android</span>
                  </div>

                  <span className="text-sm text-slate-700 truncate">{link.androidUrl}</span>
                  {link.androidUrl && <FiExternalLink className="ml-auto" />}
                </a>
                <a
                  href={link.defaultUrl ?? ''}
                  target="_blank"
                  onClick={(evt) => {
                    !link.defaultUrl && evt.preventDefault();
                    evt.stopPropagation();
                  }}
                  className={classNames('flex w-full rounded p-2 text-slate-600 items-center', {
                    'bg-green-100 cursor-pointer': link.defaultUrl,
                    'cursor-default': !link.defaultUrl,
                  })}
                >
                  <div className="flex w-28 items-center">
                    <GoBrowser className="w-5 h-5"> </GoBrowser>
                    <span className="text-medium ml-2 text-sm mr-6">Default</span>
                  </div>

                  <span className="text-sm text-slate-700 truncate">{link.defaultUrl}</span>
                  {link.defaultUrl && <FiExternalLink className="ml-auto" />}
                </a>
              </div>
            </div>
          </Popover.Panel>
        </Popover>

        {/* <div className="flex flex-col rounded bg-blue-300 text-white p-1 w-32 ml-4 items-end">
          <span className="text-3xl font-semibold">?</span>
          <span className="text-xs font-semibold mt-auto uppercase">Opens last 7 days</span>
        </div>

        <div className="flex flex-col rounded bg-blue-400 text-white p-1 w-32 ml-2 items-end">
          <span className="text-3xl font-semibold">?</span>
          <span className="text-xs font-semibold mt-auto uppercase">Total Opens</span>
        </div> */}
      </div>
    </div>
  );
};
