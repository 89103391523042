import { Product } from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import { PageData } from '../types';

export const getPageDataFileIdsFromProduct = (product: Product) => {
  const ids: string[] = [];
  const pageData = product.pageData as PageData;

  ids.push(...(pageData?.contentBlocks ?? []).map((cb) => cb.file?.id as string)?.filter((cb) => cb));
  // ids.push(...pageData?.expandableInfo.map((cb) => cb.file?.id as string)?.filter((cb) => cb));
  // ids.push(...pageData?.features.map((cb) => cb.file?.id as string)?.filter((cb) => cb));

  return ids;
};
