import classNames from 'classnames';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { FC } from 'react';

export type ThScrollPageProps = {
  id: string;
};

export const ThScrollPage: FC<NebPropsWithStd<ThScrollPageProps>> = ({ id, className, children }) => {
  return (
    <div id={id} className={classNames(className, 'th-scroll-page h-full w-full p-4 pr-0 flex flex-col')}>
      <div className="h-full overflow-auto pr-4">{children}</div>
    </div>
  );
};
