import { ThScrollPage } from '@components/ui/th-scroll-page';
import { useGetManyLinks } from '@core/clients/bitey-services-admin/links/links';
import { setPageMeta } from '@core/stores/core-store';
import classNames from 'classnames';
import { FC } from 'react';
import { BsPlus } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { LinkCard } from '../../components/links/link-card';
import { LinkCardSkeleton } from '../../components/links/link-card-skeleton';
import { BiteyRoutePaths } from '../../router/bitey-routes';

export type LinksIndexPageProps = {};

const sortByDates = (a: any, b: any) => {
  if (a.deletedAt && !b.deletedAt) {
    return 1;
  }

  if (!a.deletedAt && b.deletedAt) {
    return -1;
  }

  if (a.updatedAt < b.updatedAt) {
    return 1;
  }
  if (a.updatedAt > b.updatedAt) {
    return -1;
  }
  return 0;
};

export const LinksIndexPage: FC<LinksIndexPageProps> = () => {
  setPageMeta('Links', false);

  const { data, isLoading } = useGetManyLinks({
    withTrashed: true,
  });

  return (
    <ThScrollPage id="links-index-page">
      <div className="rounded h-16 bg-th-bitey-200 p-2 flex items-center">
        <Link
          to={`${BiteyRoutePaths.LINK_DETAILS}new`}
          className="rounded flex bg-th-bitey-500 text-white font-semibold ml-auto p-2 items-center"
        >
          <BsPlus className="text-xl font-bold"></BsPlus> Add link
        </Link>
      </div>
      <div className="min-h-full w-full rounded bg-th-bitey-100 p-2 mt-4 space-y-2 flex flex-col">
        {isLoading && <LinkCardSkeleton />}
        {data?.items?.length === 0 && (
          <div className="flex w-full justify-center mt-4">
            <p className="text-th-bitey-600 font-semibold">No links found.</p>
          </div>
        )}
        {data?.items?.sort(sortByDates)?.map((l) => (
          <Link to={`${BiteyRoutePaths.LINK_DETAILS}${l.id}`} key={l.id}>
            <LinkCard
              link={l as any}
              className={classNames('rounded p-2', {
                'bg-slate-100': l.deletedAt,
                'bg-white': !l.deletedAt,
              })}
            ></LinkCard>
          </Link>
        ))}
      </div>
    </ThScrollPage>
  );
};
