import { setPageMeta } from '@core/stores/core-store';
import { FC } from 'react';

export type EcommerceHomePageProps = {};

export const EcommerceHomePage: FC<EcommerceHomePageProps> = () => {
  setPageMeta('Ecommerce Summary', true);

  return (
    <div className="ecommerce-home-page w-full h-full p-4">
      <div className="bg-th-ecommerce-100 rounded w-full h-full">Ecommerce Home</div>
    </div>
  );
};
