import { MembershipOffering } from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import {
  usePatchMembershipOfferingById,
  usePostMembershipOffering,
} from '@core/clients/ecommerce-services-admin/membership-offerings/membership-offerings';
import { useGetManyMembershipTiers } from '@core/clients/ecommerce-services-admin/membership-tiers/membership-tiers';
import { MONTH_SELECT_OPTIONS, YEAR_SELECT_OPTIONS } from '@core/helpers/select-constants';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { Promiseable } from '@villageco/helpers';
import { pick } from 'lodash';
import { NebPropsWithStd } from '@villageco/nebula/core';
import {
  Form,
  FormOutput,
  Json,
  List,
  ListOptionConfig,
  notFoundAuthHandler,
  ResetButton,
  SubmitButton,
  TextArea,
} from '@villageco/nebula/forms';
import { FC } from 'react';
import { membershipFormConfig } from '../../libs/form-config';

export type OfferingEditFormProps = {
  offering?: MembershipOffering;
  invalidateQueries: QueryKey[];
  onCreate?: (offering: MembershipOffering) => Promiseable<void>;
  onUpdate?: (offering: MembershipOffering) => Promiseable<void>;
};

export const OfferingEditForm: FC<NebPropsWithStd<OfferingEditFormProps>> = ({
  offering,
  invalidateQueries,
  onCreate,
  onUpdate,
}) => {
  const queryClient = useQueryClient();

  const { data: tiers, isLoading: tierLoading } = useGetManyMembershipTiers({
    select: {
      id: true,
      name: true,
    },
  });

  const mutation = {
    onSuccess: () => {
      invalidateQueries.forEach((q) => queryClient.invalidateQueries(q));
    },
  };

  const { mutateAsync: createOfferingMut } = usePostMembershipOffering({
    mutation,
  });
  const { mutateAsync: updateOfferingMut } = usePatchMembershipOfferingById({
    mutation,
  });

  const formSubmitted = async (rawData: MembershipOffering) => {
    const data = {
      ...rawData,
      rules: JSON.parse(rawData.rules as any),
      membershipTier: rawData.membershipTierId
        ? {
            connect: {
              id: rawData.membershipTierId,
            },
          }
        : undefined,
    } as any;

    delete data.membershipTierId;

    if (offering?.id) {
      const res = await updateOfferingMut({ id: offering.id, data: { data } });
      onUpdate && (await onUpdate(res));
    } else {
      const res = await createOfferingMut({ data: { data } });
      onCreate && (await onCreate(res));
    }
  };

  const defaultData = pick(offering ?? {}, ['rules', 'membershipTierId', 'month', 'year']);

  if (!defaultData.month) {
    defaultData.month = MONTH_SELECT_OPTIONS[(new Date().getMonth() + 1) % 12].value as any;
  }

  if (!defaultData.year) {
    defaultData.year = new Date().getFullYear().toString();
  }

  if (!defaultData.rules) {
    defaultData.rules = '{}' as any;
  } else {
    defaultData.rules = JSON.stringify(defaultData.rules, null, 2) as any;
  }

  const tierOptions =
    tiers?.items?.map<ListOptionConfig>((a) => ({
      label: a.name!,
      value: a.id!,
    })) ?? [];

  return (
    <Form
      defaultData={defaultData}
      config={{ ...membershipFormConfig, formErrorHandler: notFoundAuthHandler }}
      onSubmit={formSubmitted}
    >
      <div className="flex w-full mt-4">
        <div className="w-1/3 mr-2">
          <List<typeof defaultData>
            options={tierOptions}
            className="w-full"
            name="membershipTierId"
            label="Membership Tier"
            disabled={tierLoading}
            placeholder={tierLoading ? `Loading` : undefined}
            validationErrorKey="membershipTier"
          ></List>
          <List<typeof defaultData> name="month" className="w-full" label="Month" options={MONTH_SELECT_OPTIONS}></List>
          <List<typeof defaultData> name="year" className="w-full" label="Year" options={YEAR_SELECT_OPTIONS}></List>
        </div>
        <div className="w-2/3 ml-2">
          <TextArea<typeof defaultData> className="h-full" name="rules" label="Offering Rules"></TextArea>
        </div>
      </div>

      <div className="flex justify-end mt-4 space-x-4">
        <FormOutput className="mr-auto"></FormOutput>
        <SubmitButton></SubmitButton>
        <ResetButton></ResetButton>
      </div>
    </Form>
  );
};
