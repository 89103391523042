import { SelectBox } from '@components/ui/select-box';
import { Account, MembershipOrder } from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import classNames from 'classnames';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { FC, MouseEvent } from 'react';

export type SelectOrderCardProps = {
  order: MembershipOrder & { account: Account };
  selected?: boolean;
  onSelectClicked: (evt: MouseEvent) => void;
};

export const SelectOrderCard: FC<NebPropsWithStd<SelectOrderCardProps>> = ({
  order,
  className,
  selected,
  onSelectClicked,
}) => {
  return (
    <div className={classNames('convert-membership-order-cart flex', className)}>
      <div className="w-16 h-16 flex items-center" onClick={onSelectClicked}>
        <SelectBox selected={selected} deselectedContainerClass="border border-gray-200"></SelectBox>
      </div>
      <div>
        <div className="flex">
          <p>{order.account.name}</p>
        </div>
      </div>
    </div>
  );
};
