import { FC } from 'react';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { Link, useMatch } from 'react-router-dom';
import { NavbarLink } from '@villageco/nebula/internal';
import { BsShop } from 'react-icons/bs';

export type EcommerceNavLinkProps = {};

export const EcommerceNavLink: FC<NebPropsWithStd<EcommerceNavLinkProps>> = ({ className }) => {
  const isActive = useMatch('/ecommerce/*');

  return (
    <NavbarLink
      isActive={!!isActive}
      linkComponent={Link}
      className={className}
      icon={BsShop}
      to="/ecommerce"
      styles={{
        bgColor: isActive ? 'bg-th-ecommerce-500' : 'bg-th-ecommerce-200',
        textColor: isActive ? 'text-th-ecommerce-200' : 'text-th-ecommerce-700',
      }}
    >
      Ecommerce
    </NavbarLink>
  );
};
