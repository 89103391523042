import { FC, PropsWithChildren } from 'react';
import { NebPropsWithStd } from '@villageco/nebula/core';
import classNames, { Argument } from 'classnames';
import { FieldClassConfig } from '../../contexts';

export type LabelProps = {
  label?: string;
  optional?: boolean;
  className?: Argument;
  labelClassConfig?: FieldClassConfig['label'];
};

export const Label: FC<LabelProps> = ({ label, optional, className, labelClassConfig }) => {
  return label || optional ? (
    <div
      className={classNames(
        'mb-2 flex items-center justify-between flex-row',
        className ?? labelClassConfig?.container,
      )}
    >
      <label className={classNames('text-gray-600 mb-1', labelClassConfig?.element)}>{label}</label>
      {optional && <p className={classNames('mr-12 italic text-sm font-thin', labelClassConfig?.optional)}>optional</p>}
    </div>
  ) : null;
};
