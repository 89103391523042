import classNames from 'classnames';
import { merge } from 'lodash-es';
import { ChangeEvent, InputHTMLAttributes, useMemo } from 'react';
import { NebPropsWithStd } from '../../../core';
import { FormConfig } from '../../contexts';
import { useFormState } from '../../hooks';
import { FormErrorMessage } from '../common/form-error-message';
import { Label } from '../common/label';
import { BaseFieldProps } from './types';

export type NumericProps<T> = Omit<InputHTMLAttributes<HTMLInputElement>, 'name' | 'onChange'> &
  BaseFieldProps<T> & {
    hideInput?: boolean;
    min?: number;
    max?: number;
    step?: number;
    onChange?: (values: { parsedValue: number; event: ChangeEvent<HTMLInputElement> }) => void;
  };

export const Numeric = <T,>({
  name,
  className,
  classes,
  label,
  optional,
  hideInput,
  children,
  disabled,
  prefix,
  onChange,
  value,
  min,
  max,
  step,
  validationErrorKey,
  ...rest
}: NebPropsWithStd<NumericProps<T>>) => {
  const { getFieldErrors, getFieldValue, setFieldValue, validationErrors, config, hasFieldChanged } =
    useFormState() ?? {};

  const { defaultClassConfig } = config ? config : ({} as FormConfig<any>);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let val = parseFloat(event.target.value);

    if (step) {
      const quot = Math.round(val / step);
      val = step * quot;
    }

    if (min && val < min) {
      val = min;
    }

    if (max && val > max) {
      val = max;
    }

    onChange && onChange({ event, parsedValue: val });
    setFieldValue && setFieldValue(name, val);
  };

  const cc = merge({}, defaultClassConfig, classes);

  const fieldErrors = getFieldErrors && useMemo(() => getFieldErrors(validationErrorKey ?? name), [validationErrors]);
  const hasErrors = useMemo(() => fieldErrors && !!fieldErrors.length, [fieldErrors]);

  const hasChanged = hasFieldChanged ? hasFieldChanged(name) : false;

  const fieldValue = (getFieldValue && getFieldValue(name)) ?? '';

  return (
    <div className={classNames(className, cc?.container, 'text-field')}>
      <div aria-live="polite">
        <Label label={label} optional={optional} labelClassConfig={cc?.label} />
        <div className={cc?.field?.container}>
          {prefix && <>{prefix}</>}

          <input
            type="number"
            aria-label={label}
            disabled={disabled}
            aria-invalid={hasErrors}
            className={classNames(cc?.field?.element, {
              [cc?.field?.ready ?? '']: !hasErrors && !disabled && !hasChanged,
              [cc?.field?.changed ?? '']: hasChanged && !disabled && !hasErrors,
              [cc?.field?.error ?? '']: hasErrors,
              'invisible absolute': hideInput,
              [cc?.field?.disabled ?? '']: disabled,
            })}
            value={value ?? fieldValue}
            name={name as string}
            onChange={handleChange}
            min={min}
            max={max}
            step={step}
            {...rest}
          />
        </div>
      </div>
      {fieldErrors &&
        Array.isArray(fieldErrors) &&
        fieldErrors.map((e) => <FormErrorMessage className={cc?.error}>{e}</FormErrorMessage>)}
    </div>
  );
};
