import logoUrl from '@assets/TownHall-03.png';
import { AuthRoutePaths } from '@auth';
import { PageShield } from '@components/shields/page-shield';
import { Foyer } from '@foyer';
import { useState } from 'react';
import { useMatch } from 'react-router-dom';
import { AppRouter } from './router/app-router';
import './styles/fonts.css';
import './styles/globals.css';

function App() {
  const authMatch = useMatch(`${AuthRoutePaths.ROOT}/*`);

  const [showShield, setShowShield] = useState(!!authMatch);

  const showTimeout = setTimeout(() => {
    setShowShield(false);
  }, 1500);

  return (
    <div className="town-hall-app relative">
      <PageShield show={showShield} className="bg-vc-light-brown px-72">
        <img src={logoUrl} />
      </PageShield>
      <Foyer></Foyer>
      <AppRouter></AppRouter>
    </div>
  );
}

export default App;
