import { Link, LinkCreate, LinkUpdate } from '@core/clients/bitey-services-admin/bitey-services.schemas';
import { usePatchLinkById, usePostLink } from '@core/clients/bitey-services-admin/links/links';
// import { BiteyRoutePaths } from '../../router/bitey-routes';
import { useGetManyPages } from '@core/clients/bitey-services-admin/pages/pages';
import { Switch } from '@headlessui/react';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { NebPropsWithStd } from '@villageco/nebula/core';
import {
  Form,
  FormOutput,
  List,
  notFoundAuthHandler,
  ResetButton,
  SubmitButton,
  Tags,
  Text,
  TextArea,
} from '@villageco/nebula/forms';
import classNames from 'classnames';
import { pick } from 'lodash';
import { FC, useState } from 'react';
import { AiFillAndroid } from 'react-icons/ai';
import { BsApple } from 'react-icons/bs';
import { GoBrowser } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';
import { biteyFormConfig } from '../../libs/form-config';
import { BiteyRoutePaths } from '../../router/bitey-routes';

export type LinkEditFormProps = {
  link?: Link;
  queryKey: QueryKey;
};

export const LinkEditForm: FC<NebPropsWithStd<LinkEditFormProps>> = ({ link, queryKey, className }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [autoGeneratePath, setAutoGeneratePath] = useState(true);
  const [routeToPage, setRouteToPage] = useState(false);

  const { data: pages, isLoading: pagesLoading } = useGetManyPages();

  const mutation = {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
  };

  const { mutateAsync: createLinkMut } = usePostLink({
    mutation,
  });
  const { mutateAsync: updateLinkMut } = usePatchLinkById({
    mutation,
  });

  const defaultData = pick(
    {
      ...link,
      routeToPage,
    } ?? {},
    [
      'name',
      'description',
      'path',
      'tags',
      'iosUrl',
      'androidUrl',
      'defaultUrl',
      'pageId',
      'autoGenerate',
      'routeToPage',
    ],
  );

  const formSubmitted = async (rawData: typeof defaultData) => {    
    if (!link && autoGeneratePath) {
      delete rawData.path;
    } else if(rawData.path) {
      rawData.path = rawData.path?.replaceAll(' ', '-');
      rawData.path = rawData.path?.replaceAll(/[^a-zA-Z0-9-_]/g, '');
    }

    if (rawData.routeToPage) {
      delete rawData.defaultUrl;
      delete rawData.androidUrl;
      delete rawData.iosUrl;
    } else {
      delete rawData.pageId;
    }

    delete (rawData as any).autoGenerate;
    delete (rawData as any).routeToPage;

    if (rawData.defaultUrl && !rawData.defaultUrl?.includes('://')) {
      rawData.defaultUrl = `https://${rawData.defaultUrl}`;
    }

    if (link?.id) {
      const data = {
        ...rawData,
      } as LinkUpdate;

      if (rawData.pageId) {
        data.page = {
          connect: {
            id: rawData.pageId,
          },
        };
      } else if (link.pageId) {
        data.page = {
          disconnect: true,
        };
      }

      delete (data as any).pageId;

      const res = await updateLinkMut({ id: link.id, data: { data } });
    } else {
      const data = {
        ...rawData,
      } as LinkCreate;

      if (rawData.pageId) {
        data.page = {
          connect: {
            id: rawData.pageId,
          },
        };
      }

      delete (data as any).pageId;

      const res = await createLinkMut({ data: { data } });
      navigate(`${BiteyRoutePaths.LINK_DETAILS}${res.id}`);
    }
  };

  const pageOptions = (pages?.items ?? ([] as any[])).map((page) => ({
    label: page.name,
    value: page.id,
  }));

  return (
    <Form
      className="w-full"
      defaultData={defaultData}
      config={{ ...biteyFormConfig, formErrorHandler: notFoundAuthHandler }}
      onSubmit={formSubmitted}
    >
      <div className="w-full">
        <div className="w-full">
          <div className="flex w-full mt-2 space-x-4">
            <Text<typeof defaultData> className="w-1/2" name="name" label="Name"></Text>
            <Tags<typeof defaultData> className="w-1/2 overflow-hidden" name="tags" label="Tags" optional></Tags>
          </div>
          <div className="flex w-full mt-2 space-x-4">
            {!link && (
              <div className="self-stretch flex flex-col">
                <p className="text-th-bitey-700 font-semibold text-sm uppercase tracking-tighter">Auto-generate path</p>

                <Switch
                  checked={autoGeneratePath}
                  onChange={setAutoGeneratePath}
                  className={classNames('my-auto rounded-full flex w-12 h-8 bg-white border border-th-bitey-500', {
                    'bg-th-bitey-700': autoGeneratePath,
                    'bg-th-bitey-100': !autoGeneratePath,
                  })}
                >
                  <span
                    className={`${
                      autoGeneratePath ? 'translate-x-4' : 'translate-x-0'
                    } inline-block h-7 w-7 transform rounded-full bg-th-bitey-500 mt-[1px] ml-[1px] transition`}
                  />
                </Switch>
              </div>
            )}

                    <Text<typeof defaultData>
              disabled={autoGeneratePath}
              className="w-96"
              name="path"
              label="Path"
              placeholder="Path"
            ></Text>
            
            {!link && (

<span className='italic text-[0.8rem] text-th-bitey-800'>Paths must be valid URL path, as such:<br/>* Spaces are not allowed and will be replaced with dashes<br/>* Special characters other than dashes and underscores will be removed</span>
)}
          </div>
          
          <div className="flex w-full mt-2 space-x-4">
            <TextArea<typeof defaultData>
              classes={{
                field: {
                  element: `${biteyFormConfig.defaultClassConfig?.field?.element} h-52`,
                },
              }}
              className="w-1/2"
              name="description"
              optional={true}
              label="Description"
            ></TextArea>
            {/* <List<typeof defaultData>
              options={linkTypeOptions}
              className="w-full"
              name="type"
              label="Link type"
              placeholder="Select link type"
            ></List> */}
            <div className="w-1/2">
              <div className="flex">
                <p className="text-th-bitey-700 font-semibold text-sm uppercase tracking-tighter">Destinations</p>

                {/* <div className="self-stretch flex flex-col ml-auto">
                  <p className="text-th-bitey-700 font-semibold text-sm uppercase tracking-tighter">
                    Route to GL Launch Page
                  </p>

                  <Switch
                    disabled={true}
                    checked={routeToPage}
                    onChange={setRouteToPage}
                    className={classNames(
                      'my-auto rounded-full flex w-12 h-8 border border-th-bitey-500 transition-colors duration-150',
                      {
                        'bg-th-bitey-700': routeToPage,
                        'bg-th-bitey-100': !routeToPage,
                      },
                    )}
                  >
                    <span
                      className={`${
                        routeToPage ? 'translate-x-4' : 'translate-x-0'
                      } inline-block h-7 w-7 transform rounded-full bg-th-bitey-500 mt-[1px] ml-[1px] transition`}
                    />
                  </Switch>
                </div> */}
              </div>
              <div className="flex flex-col w-full mt-4 space-y-2">
                {routeToPage ? (
                  <List<typeof defaultData>
                    options={pageOptions}
                    disabled={pagesLoading}
                    className="w-full"
                    name="pageId"
                    label="Linked Page"
                    placeholder={pagesLoading ? 'Loading...' : 'Select GL Launch page to link to'}
                  ></List>
                ) : (
                  <>
                    <div className="w-full flex">
                      <div className="relative flex w-32 items-center -mt-6 text-th-bitey-800">
                        <BsApple className="w-5 h-5"></BsApple>
                        <span className="text-medium ml-2 text-sm mr-6">iOS/Mac</span>
                        <span className="absolute top-12 left-7 text-xs tracking-tighter">Optional</span>
                      </div>
                      <Text<typeof defaultData> className="w-full" name="iosUrl"></Text>
                    </div>

                    <div className="w-full flex">
                      <div className="relative flex w-32 items-center -mt-6 text-th-bitey-800">
                        <AiFillAndroid className="w-6 h-6"> </AiFillAndroid>
                        <span className="text-medium ml-2 text-sm mr-6">Android</span>
                        <span className="absolute top-12 left-8 text-xs tracking-tighter">Optional</span>
                      </div>
                      <Text<typeof defaultData> className="w-full" name="androidUrl"></Text>
                    </div>

                    <div className="w-full flex">
                      <div className="flex w-32 items-center text-th-bitey-800">
                        <GoBrowser className="w-5 h-5"> </GoBrowser>
                        <span className="text-medium ml-2 text-sm mr-6">Default</span>
                      </div>
                      <Text<typeof defaultData> className="w-full" name="defaultUrl"></Text>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end mt-4 space-x-4">
        <FormOutput className="mr-auto"></FormOutput>
        <SubmitButton></SubmitButton>
        <ResetButton></ResetButton>
      </div>
    </Form>
  );
};
