import { FunctionComponent, MouseEvent, MouseEventHandler, useEffect, useState } from 'react';
import { usePrevious } from '@villageco/nebula';
import classNames, { Argument } from 'classnames';
import { FaChevronUp, FaTimes, FaCheck, FaTrashAlt } from 'react-icons/fa';
import { ActionButtons } from './action-buttons';

export type CollapseSectionProps = {
  title: string;
  shouldOpen?: boolean;
  locked?: boolean;
  titleClassName?: Argument;
  contentClassName?: Argument;
  hideTitleWhenOpen?: boolean;
  editable?: boolean;
  onDelete?: () => void;
};

export const CollapseSection: FunctionComponent<CollapseSectionProps> = ({
  title,
  shouldOpen = false,
  locked = false,
  children,
  titleClassName,
  contentClassName,
  hideTitleWhenOpen,
  editable,
  onDelete,
}) => {
  const [open, setOpen] = useState(true);
  const [deleting, setDeleting] = useState(false);

  const previousShouldOpen = usePrevious(shouldOpen);

  useEffect(() => {
    if (shouldOpen !== previousShouldOpen) {
      setOpen(true);
    }
  }, [shouldOpen]);

  const titleClicked = (event: MouseEvent) => {
    event.preventDefault();

    if (locked) {
      return;
    }

    setOpen(!open);
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete();
    }
  };

  return (
    <div className="collapse-section w-full">
      <button
        onClick={titleClicked}
        className={classNames(
          titleClassName,
          'text-left w-full border-t-2 border-gray-400 text-gray-500 py-6 font-bold uppercase',
          {
            'cursor-default': locked,
          },
        )}
      >
        <div className="flex justify-between w-full">
          {((hideTitleWhenOpen && !open) || !hideTitleWhenOpen) && (title ?? 'No title added')}
          {hideTitleWhenOpen && open && (
            <div className="w-full flex justify-center">
              <FaChevronUp size={24} />
            </div>
          )}
          {editable && (
            <ActionButtons
              action={handleDelete}
              confirmationMessage="Remove expanding section?"
              confirmationMessageClassName="normal-case right-0"
            />
          )}
        </div>
      </button>
      <div className={classNames({ 'h-auto': open, 'h-0': !open }, contentClassName, 'overflow-hidden')}>
        <div className="pb-6 w-full text-gray-500 whitespace-pre-line">{children}</div>
      </div>
    </div>
  );
};
