import { useGetManyMembershipOrders } from '@core/clients/ecommerce-services-admin/membership-orders/membership-orders';
import { usePostSalesOrder } from '@core/clients/ecommerce-services-admin/sales-orders/sales-orders';
import { MONTH_SELECT_OPTIONS, YEAR_SELECT_OPTIONS } from '@core/helpers/select-constants';
import { useSelectedSet } from '@core/hooks/useSelectedSet';
import { capitalCase } from 'change-case';
import classNames from 'classnames';
import { NebPropsWithStd } from '@villageco/nebula/core';
// import { Select } from '@villageco/nebula/forms';
import { FC, MouseEvent, useState } from 'react';
import { SelectOrderCard } from './select-order-card';

export type ConvertMembershipOrdersPanelProps = {};

export const ConvertMembershipOrdersPanel: FC<NebPropsWithStd<ConvertMembershipOrdersPanelProps>> = ({ className }) => {
  const [enableSearch, setEnableSearch] = useState(false);
  const [month, setMonth] = useState<string | undefined>();
  const [year, setYear] = useState<string | undefined>();

  const { selectedSet, selectClickHandler } = useSelectedSet();

  const { mutateAsync } = usePostSalesOrder();

  const { data, status } = useGetManyMembershipOrders(
    { where: {} },
    {
      query: {
        enabled: enableSearch,
      },
    },
  );

  const count = data?.items?.length ?? 0 + 1;

  const convertClicked = (evt: MouseEvent) => {};

  return (
    <div className={classNames('convert-membership-orders-panel flex', className)}>
      <p className="text-gray-500 font-semibold">Select a membership month to begin</p>

      <div className="flex">
        {/* <Select
          name="month"
          className="w-56"
          onChange={(val: string | undefined) => {
            setMonth(val);
            year && setEnableSearch(true);
          }}
          options={MONTH_SELECT_OPTIONS}
          label="Month"
        ></Select>
        <Select
          name="year"
          className="w-56 ml-4"
          onChange={(val: string | undefined) => {
            setYear(val);
            month && setEnableSearch(true);
          }}
          options={YEAR_SELECT_OPTIONS}
          label="Year"
        ></Select> */}
      </div>
      {status === 'loading' && enableSearch && (
        <div>
          <div className="bg-gray-200 animate-pulse rounded w-full h-10"></div>
          <div className="bg-gray-200 animate-pulse rounded w-full h-48 mt-4"></div>
        </div>
      )}
      {status === 'success' && month && year && (
        <>
          <div className="flex">
            <p>
              Unconverted Membership Orders for {capitalCase(month)} {year}
            </p>
            <p>{count} orders</p>
          </div>
          <div className="bg-th-ecommerce-50 rounded">
            {data.items?.map((o) => (
              <SelectOrderCard
                order={o as any}
                selected={selectedSet.has(o.id!)}
                onSelectClicked={selectClickHandler(o.id!)}
              ></SelectOrderCard>
            ))}
          </div>
          <div>
            <p className="">This will lock all membership orders for this month</p>
            <button className="" onClick={convertClicked}>
              Convert {count} membership orders
            </button>
          </div>
        </>
      )}
    </div>
  );
};
