import axios from 'axios';
import { ajvAxiosErrorParser, FormConfig } from '@villageco/nebula/forms';

export const LIST_OPTION_CONFIG = {
  container: 'bg-white w-full border rounded mt-1 p-1',
  element: 'py-1 px-2 hover:bg-gray-100 hover:rounded',
  disabled: 'text-gray-300',
  selected: 'rounded bg-blue-500 text-white',
};

export const membershipFormConfig: FormConfig<any> = {
  defaultClassConfig: {
    container: 'mb-2',
    listOptions: LIST_OPTION_CONFIG,
    field: {
      element:
        'h-10 rounded border border-gray-300 border-b-2  px-2 w-full outline-none focus:border-b-th-membership-500',
      ready: 'border-b-th-membership-300',
      changed: 'border-b-purple-400',
      error: 'border-b-red-400',
    },
    submitButton: {
      element: 'rounded w-24 h-12 font-medium',
      unchanged: 'bg-th-membership-200 text-white',
      ready: 'bg-th-membership-500 text-white',
      loading: 'bg-th-membership-300 text-white animate-pulse',
      disabled: '!bg-th-membership-200 !text-white',
    },
    resetButton: {
      element: 'rounded w-24 h-12 border font-medium',
      unchanged: 'border text-gray-300 border-gray-300',
      ready: 'border text-gray-700 border-gray-600 border-2',
      loading: 'border-gray-300 text-gray-300 animate-pulse',
      disabled: '!border !text-gray-300 !border-gray-300',
    },
  },
  submitErrorFieldParser: ajvAxiosErrorParser(),
};
