import { FunctionComponent, ReactNode } from 'react';
import { UserPin } from './user-pin';

export type NavbarProps = {
  className?: string;
  logo?: ReactNode;
};

export const Navbar: FunctionComponent<NavbarProps> = ({ children, logo, className }) => {
  return (
    <div className={`nav-bar flex ${className}`}>
      <div className="h-full flex p-4">
        <div className="w-48">{logo}</div>
        <div className="h-full flex items-center">{children}</div>
      </div>
      <div className="ml-auto h-full p-4">
        <UserPin />
      </div>
    </div>
  );
};
