/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * admin-rest-api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  MembershipTier,
  ValidationErrors,
  GetMembershipTierByIdParams,
  PatchMembershipTierByIdBody,
  DeleteMembershipTierByIdBody,
  PatchRestoreMembershipTierByIdBody,
  DeleteDestroyMembershipTierByIdBody,
  GetOneMembershipTierParams,
  MembershipTiers,
  GetManyMembershipTiersParams,
  PostMembershipTierBody
} from '../ecommerce-services.schemas'
import { ecommerceAdminApiInstance } from '../../../apis/ecommerce-admin-api-instance'
import type { ErrorType } from '../../../apis/ecommerce-admin-api-instance'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Gets one Membership Tier by Id in path
 */
export const getMembershipTierById = (
    id: string,
    params?: GetMembershipTierByIdParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<MembershipTier>(
      {url: `/membership-tier/${id}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetMembershipTierByIdQueryKey = (id: string,
    params?: GetMembershipTierByIdParams,) => [`/membership-tier/${id}`, ...(params ? [params]: [])];

    
export type GetMembershipTierByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getMembershipTierById>>>
export type GetMembershipTierByIdQueryError = ErrorType<void | ValidationErrors>

export const useGetMembershipTierById = <TData = Awaited<ReturnType<typeof getMembershipTierById>>, TError = ErrorType<void | ValidationErrors>>(
 id: string,
    params?: GetMembershipTierByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMembershipTierById>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMembershipTierByIdQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMembershipTierById>>> = ({ signal }) => getMembershipTierById(id,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMembershipTierById>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Patches one Membership Tier by Id in path
 */
export const patchMembershipTierById = (
    id: string,
    patchMembershipTierByIdBody: PatchMembershipTierByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipTier>(
      {url: `/membership-tier/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchMembershipTierByIdBody
    },
      options);
    }
  


    export type PatchMembershipTierByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchMembershipTierById>>>
    export type PatchMembershipTierByIdMutationBody = PatchMembershipTierByIdBody
    export type PatchMembershipTierByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchMembershipTierById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchMembershipTierById>>, TError,{id: string;data: PatchMembershipTierByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchMembershipTierById>>, {id: string;data: PatchMembershipTierByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchMembershipTierById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchMembershipTierById>>, TError, {id: string;data: PatchMembershipTierByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Deletes one Membership Tier by Id in path
 */
export const deleteMembershipTierById = (
    id: string,
    deleteMembershipTierByIdBody: DeleteMembershipTierByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipTier>(
      {url: `/membership-tier/${id}`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteMembershipTierByIdBody
    },
      options);
    }
  


    export type DeleteMembershipTierByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMembershipTierById>>>
    export type DeleteMembershipTierByIdMutationBody = DeleteMembershipTierByIdBody
    export type DeleteMembershipTierByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteMembershipTierById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMembershipTierById>>, TError,{id: string;data: DeleteMembershipTierByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMembershipTierById>>, {id: string;data: DeleteMembershipTierByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteMembershipTierById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteMembershipTierById>>, TError, {id: string;data: DeleteMembershipTierByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Restores one Membership Tier by Id in path
 */
export const patchRestoreMembershipTierById = (
    id: string,
    patchRestoreMembershipTierByIdBody: PatchRestoreMembershipTierByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipTier>(
      {url: `/membership-tier/${id}/restore`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchRestoreMembershipTierByIdBody
    },
      options);
    }
  


    export type PatchRestoreMembershipTierByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchRestoreMembershipTierById>>>
    export type PatchRestoreMembershipTierByIdMutationBody = PatchRestoreMembershipTierByIdBody
    export type PatchRestoreMembershipTierByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchRestoreMembershipTierById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchRestoreMembershipTierById>>, TError,{id: string;data: PatchRestoreMembershipTierByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchRestoreMembershipTierById>>, {id: string;data: PatchRestoreMembershipTierByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchRestoreMembershipTierById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchRestoreMembershipTierById>>, TError, {id: string;data: PatchRestoreMembershipTierByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Destroys one Membership Tier by Id in path
 */
export const deleteDestroyMembershipTierById = (
    id: string,
    deleteDestroyMembershipTierByIdBody: DeleteDestroyMembershipTierByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipTier>(
      {url: `/membership-tier/${id}/destroy`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteDestroyMembershipTierByIdBody
    },
      options);
    }
  


    export type DeleteDestroyMembershipTierByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDestroyMembershipTierById>>>
    export type DeleteDestroyMembershipTierByIdMutationBody = DeleteDestroyMembershipTierByIdBody
    export type DeleteDestroyMembershipTierByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteDestroyMembershipTierById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDestroyMembershipTierById>>, TError,{id: string;data: DeleteDestroyMembershipTierByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDestroyMembershipTierById>>, {id: string;data: DeleteDestroyMembershipTierByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteDestroyMembershipTierById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteDestroyMembershipTierById>>, TError, {id: string;data: DeleteDestroyMembershipTierByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Get one Membership Tiers
 */
export const getOneMembershipTier = (
    params?: GetOneMembershipTierParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<MembershipTier>(
      {url: `/membership-tier`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetOneMembershipTierQueryKey = (params?: GetOneMembershipTierParams,) => [`/membership-tier`, ...(params ? [params]: [])];

    
export type GetOneMembershipTierQueryResult = NonNullable<Awaited<ReturnType<typeof getOneMembershipTier>>>
export type GetOneMembershipTierQueryError = ErrorType<void | ValidationErrors>

export const useGetOneMembershipTier = <TData = Awaited<ReturnType<typeof getOneMembershipTier>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetOneMembershipTierParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOneMembershipTier>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOneMembershipTierQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneMembershipTier>>> = ({ signal }) => getOneMembershipTier(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOneMembershipTier>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Get many Membership Tiers
 */
export const getManyMembershipTiers = (
    params?: GetManyMembershipTiersParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<MembershipTiers>(
      {url: `/membership-tiers`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetManyMembershipTiersQueryKey = (params?: GetManyMembershipTiersParams,) => [`/membership-tiers`, ...(params ? [params]: [])];

    
export type GetManyMembershipTiersQueryResult = NonNullable<Awaited<ReturnType<typeof getManyMembershipTiers>>>
export type GetManyMembershipTiersQueryError = ErrorType<void | ValidationErrors>

export const useGetManyMembershipTiers = <TData = Awaited<ReturnType<typeof getManyMembershipTiers>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetManyMembershipTiersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getManyMembershipTiers>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetManyMembershipTiersQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getManyMembershipTiers>>> = ({ signal }) => getManyMembershipTiers(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getManyMembershipTiers>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Posts a new Membership Tier
 */
export const postMembershipTier = (
    postMembershipTierBody: PostMembershipTierBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipTier>(
      {url: `/membership-tiers`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postMembershipTierBody
    },
      options);
    }
  


    export type PostMembershipTierMutationResult = NonNullable<Awaited<ReturnType<typeof postMembershipTier>>>
    export type PostMembershipTierMutationBody = PostMembershipTierBody
    export type PostMembershipTierMutationError = ErrorType<void | ValidationErrors>

    export const usePostMembershipTier = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMembershipTier>>, TError,{data: PostMembershipTierBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMembershipTier>>, {data: PostMembershipTierBody}> = (props) => {
          const {data} = props ?? {};

          return  postMembershipTier(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMembershipTier>>, TError, {data: PostMembershipTierBody}, TContext>(mutationFn, mutationOptions)
    }
    