/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * admin-rest-api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Membership,
  ValidationErrors,
  GetMembershipByIdParams,
  PatchMembershipByIdBody,
  DeleteMembershipByIdBody,
  PatchRestoreMembershipByIdBody,
  DeleteDestroyMembershipByIdBody,
  GetOneMembershipParams,
  Memberships,
  GetManyMembershipsParams,
  PostMembershipBody
} from '../ecommerce-services.schemas'
import { ecommerceAdminApiInstance } from '../../../apis/ecommerce-admin-api-instance'
import type { ErrorType } from '../../../apis/ecommerce-admin-api-instance'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Gets one Membership by Id in path
 */
export const getMembershipById = (
    id: string,
    params?: GetMembershipByIdParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<Membership>(
      {url: `/membership/${id}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetMembershipByIdQueryKey = (id: string,
    params?: GetMembershipByIdParams,) => [`/membership/${id}`, ...(params ? [params]: [])];

    
export type GetMembershipByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getMembershipById>>>
export type GetMembershipByIdQueryError = ErrorType<void | ValidationErrors>

export const useGetMembershipById = <TData = Awaited<ReturnType<typeof getMembershipById>>, TError = ErrorType<void | ValidationErrors>>(
 id: string,
    params?: GetMembershipByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMembershipById>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMembershipByIdQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMembershipById>>> = ({ signal }) => getMembershipById(id,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMembershipById>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Patches one Membership by Id in path
 */
export const patchMembershipById = (
    id: string,
    patchMembershipByIdBody: PatchMembershipByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Membership>(
      {url: `/membership/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchMembershipByIdBody
    },
      options);
    }
  


    export type PatchMembershipByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchMembershipById>>>
    export type PatchMembershipByIdMutationBody = PatchMembershipByIdBody
    export type PatchMembershipByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchMembershipById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchMembershipById>>, TError,{id: string;data: PatchMembershipByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchMembershipById>>, {id: string;data: PatchMembershipByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchMembershipById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchMembershipById>>, TError, {id: string;data: PatchMembershipByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Deletes one Membership by Id in path
 */
export const deleteMembershipById = (
    id: string,
    deleteMembershipByIdBody: DeleteMembershipByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Membership>(
      {url: `/membership/${id}`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteMembershipByIdBody
    },
      options);
    }
  


    export type DeleteMembershipByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMembershipById>>>
    export type DeleteMembershipByIdMutationBody = DeleteMembershipByIdBody
    export type DeleteMembershipByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteMembershipById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMembershipById>>, TError,{id: string;data: DeleteMembershipByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMembershipById>>, {id: string;data: DeleteMembershipByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteMembershipById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteMembershipById>>, TError, {id: string;data: DeleteMembershipByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Restores one Membership by Id in path
 */
export const patchRestoreMembershipById = (
    id: string,
    patchRestoreMembershipByIdBody: PatchRestoreMembershipByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Membership>(
      {url: `/membership/${id}/restore`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchRestoreMembershipByIdBody
    },
      options);
    }
  


    export type PatchRestoreMembershipByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchRestoreMembershipById>>>
    export type PatchRestoreMembershipByIdMutationBody = PatchRestoreMembershipByIdBody
    export type PatchRestoreMembershipByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchRestoreMembershipById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchRestoreMembershipById>>, TError,{id: string;data: PatchRestoreMembershipByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchRestoreMembershipById>>, {id: string;data: PatchRestoreMembershipByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchRestoreMembershipById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchRestoreMembershipById>>, TError, {id: string;data: PatchRestoreMembershipByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Destroys one Membership by Id in path
 */
export const deleteDestroyMembershipById = (
    id: string,
    deleteDestroyMembershipByIdBody: DeleteDestroyMembershipByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Membership>(
      {url: `/membership/${id}/destroy`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteDestroyMembershipByIdBody
    },
      options);
    }
  


    export type DeleteDestroyMembershipByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDestroyMembershipById>>>
    export type DeleteDestroyMembershipByIdMutationBody = DeleteDestroyMembershipByIdBody
    export type DeleteDestroyMembershipByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteDestroyMembershipById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDestroyMembershipById>>, TError,{id: string;data: DeleteDestroyMembershipByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDestroyMembershipById>>, {id: string;data: DeleteDestroyMembershipByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteDestroyMembershipById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteDestroyMembershipById>>, TError, {id: string;data: DeleteDestroyMembershipByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Get one Memberships
 */
export const getOneMembership = (
    params?: GetOneMembershipParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<Membership>(
      {url: `/membership`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetOneMembershipQueryKey = (params?: GetOneMembershipParams,) => [`/membership`, ...(params ? [params]: [])];

    
export type GetOneMembershipQueryResult = NonNullable<Awaited<ReturnType<typeof getOneMembership>>>
export type GetOneMembershipQueryError = ErrorType<void | ValidationErrors>

export const useGetOneMembership = <TData = Awaited<ReturnType<typeof getOneMembership>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetOneMembershipParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOneMembership>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOneMembershipQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneMembership>>> = ({ signal }) => getOneMembership(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOneMembership>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Get many Memberships
 */
export const getManyMemberships = (
    params?: GetManyMembershipsParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<Memberships>(
      {url: `/memberships`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetManyMembershipsQueryKey = (params?: GetManyMembershipsParams,) => [`/memberships`, ...(params ? [params]: [])];

    
export type GetManyMembershipsQueryResult = NonNullable<Awaited<ReturnType<typeof getManyMemberships>>>
export type GetManyMembershipsQueryError = ErrorType<void | ValidationErrors>

export const useGetManyMemberships = <TData = Awaited<ReturnType<typeof getManyMemberships>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetManyMembershipsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getManyMemberships>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetManyMembershipsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getManyMemberships>>> = ({ signal }) => getManyMemberships(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getManyMemberships>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Posts a new Membership
 */
export const postMembership = (
    postMembershipBody: PostMembershipBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Membership>(
      {url: `/memberships`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postMembershipBody
    },
      options);
    }
  


    export type PostMembershipMutationResult = NonNullable<Awaited<ReturnType<typeof postMembership>>>
    export type PostMembershipMutationBody = PostMembershipBody
    export type PostMembershipMutationError = ErrorType<void | ValidationErrors>

    export const usePostMembership = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMembership>>, TError,{data: PostMembershipBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMembership>>, {data: PostMembershipBody}> = (props) => {
          const {data} = props ?? {};

          return  postMembership(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMembership>>, TError, {data: PostMembershipBody}, TContext>(mutationFn, mutationOptions)
    }
    