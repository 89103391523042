/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * admin-rest-api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Category,
  ValidationErrors,
  GetCategoryByIdParams,
  PatchCategoryByIdBody,
  DeleteCategoryByIdBody,
  PatchRestoreCategoryByIdBody,
  DeleteDestroyCategoryByIdBody,
  GetOneCategoryParams,
  Categories,
  GetManyCategoriesParams,
  PostCategoryBody
} from '../ecommerce-services.schemas'
import { ecommerceAdminApiInstance } from '../../../apis/ecommerce-admin-api-instance'
import type { ErrorType } from '../../../apis/ecommerce-admin-api-instance'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Gets one Category by Id in path
 */
export const getCategoryById = (
    id: string,
    params?: GetCategoryByIdParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<Category>(
      {url: `/category/${id}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetCategoryByIdQueryKey = (id: string,
    params?: GetCategoryByIdParams,) => [`/category/${id}`, ...(params ? [params]: [])];

    
export type GetCategoryByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getCategoryById>>>
export type GetCategoryByIdQueryError = ErrorType<void | ValidationErrors>

export const useGetCategoryById = <TData = Awaited<ReturnType<typeof getCategoryById>>, TError = ErrorType<void | ValidationErrors>>(
 id: string,
    params?: GetCategoryByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCategoryById>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCategoryByIdQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCategoryById>>> = ({ signal }) => getCategoryById(id,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCategoryById>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Patches one Category by Id in path
 */
export const patchCategoryById = (
    id: string,
    patchCategoryByIdBody: PatchCategoryByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Category>(
      {url: `/category/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchCategoryByIdBody
    },
      options);
    }
  


    export type PatchCategoryByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchCategoryById>>>
    export type PatchCategoryByIdMutationBody = PatchCategoryByIdBody
    export type PatchCategoryByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchCategoryById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchCategoryById>>, TError,{id: string;data: PatchCategoryByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchCategoryById>>, {id: string;data: PatchCategoryByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchCategoryById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchCategoryById>>, TError, {id: string;data: PatchCategoryByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Deletes one Category by Id in path
 */
export const deleteCategoryById = (
    id: string,
    deleteCategoryByIdBody: DeleteCategoryByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Category>(
      {url: `/category/${id}`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteCategoryByIdBody
    },
      options);
    }
  


    export type DeleteCategoryByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCategoryById>>>
    export type DeleteCategoryByIdMutationBody = DeleteCategoryByIdBody
    export type DeleteCategoryByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteCategoryById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCategoryById>>, TError,{id: string;data: DeleteCategoryByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCategoryById>>, {id: string;data: DeleteCategoryByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteCategoryById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteCategoryById>>, TError, {id: string;data: DeleteCategoryByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Restores one Category by Id in path
 */
export const patchRestoreCategoryById = (
    id: string,
    patchRestoreCategoryByIdBody: PatchRestoreCategoryByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Category>(
      {url: `/category/${id}/restore`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchRestoreCategoryByIdBody
    },
      options);
    }
  


    export type PatchRestoreCategoryByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchRestoreCategoryById>>>
    export type PatchRestoreCategoryByIdMutationBody = PatchRestoreCategoryByIdBody
    export type PatchRestoreCategoryByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchRestoreCategoryById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchRestoreCategoryById>>, TError,{id: string;data: PatchRestoreCategoryByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchRestoreCategoryById>>, {id: string;data: PatchRestoreCategoryByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchRestoreCategoryById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchRestoreCategoryById>>, TError, {id: string;data: PatchRestoreCategoryByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Destroys one Category by Id in path
 */
export const deleteDestroyCategoryById = (
    id: string,
    deleteDestroyCategoryByIdBody: DeleteDestroyCategoryByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Category>(
      {url: `/category/${id}/destroy`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteDestroyCategoryByIdBody
    },
      options);
    }
  


    export type DeleteDestroyCategoryByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDestroyCategoryById>>>
    export type DeleteDestroyCategoryByIdMutationBody = DeleteDestroyCategoryByIdBody
    export type DeleteDestroyCategoryByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteDestroyCategoryById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDestroyCategoryById>>, TError,{id: string;data: DeleteDestroyCategoryByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDestroyCategoryById>>, {id: string;data: DeleteDestroyCategoryByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteDestroyCategoryById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteDestroyCategoryById>>, TError, {id: string;data: DeleteDestroyCategoryByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Get one Categories
 */
export const getOneCategory = (
    params?: GetOneCategoryParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<Category>(
      {url: `/category`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetOneCategoryQueryKey = (params?: GetOneCategoryParams,) => [`/category`, ...(params ? [params]: [])];

    
export type GetOneCategoryQueryResult = NonNullable<Awaited<ReturnType<typeof getOneCategory>>>
export type GetOneCategoryQueryError = ErrorType<void | ValidationErrors>

export const useGetOneCategory = <TData = Awaited<ReturnType<typeof getOneCategory>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetOneCategoryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOneCategory>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOneCategoryQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneCategory>>> = ({ signal }) => getOneCategory(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOneCategory>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Get many Categories
 */
export const getManyCategories = (
    params?: GetManyCategoriesParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<Categories>(
      {url: `/categories`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetManyCategoriesQueryKey = (params?: GetManyCategoriesParams,) => [`/categories`, ...(params ? [params]: [])];

    
export type GetManyCategoriesQueryResult = NonNullable<Awaited<ReturnType<typeof getManyCategories>>>
export type GetManyCategoriesQueryError = ErrorType<void | ValidationErrors>

export const useGetManyCategories = <TData = Awaited<ReturnType<typeof getManyCategories>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetManyCategoriesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getManyCategories>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetManyCategoriesQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getManyCategories>>> = ({ signal }) => getManyCategories(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getManyCategories>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Posts a new Category
 */
export const postCategory = (
    postCategoryBody: PostCategoryBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<Category>(
      {url: `/categories`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postCategoryBody
    },
      options);
    }
  


    export type PostCategoryMutationResult = NonNullable<Awaited<ReturnType<typeof postCategory>>>
    export type PostCategoryMutationBody = PostCategoryBody
    export type PostCategoryMutationError = ErrorType<void | ValidationErrors>

    export const usePostCategory = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCategory>>, TError,{data: PostCategoryBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCategory>>, {data: PostCategoryBody}> = (props) => {
          const {data} = props ?? {};

          return  postCategory(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postCategory>>, TError, {data: PostCategoryBody}, TContext>(mutationFn, mutationOptions)
    }
    