import { useEffect, useRef, useState } from 'react';
import type { SetInterval } from '@villageco/helpers';

export const MINUTE_MILIS = 60 * 1000;

export const useTimeGreeting = () => {
  const [timeGreeting, setTimeGreeting] = useState('good morning');
  const timeInterval = useRef<null | SetInterval>(null);

  useEffect(() => {
    const setGreeting = () => {
      const now = new Date();
      const hr = now.getHours();

      setTimeGreeting(hr < 12 ? 'Good morning' : hr < 18 ? 'Good afternoon' : 'Good evening');
    };

    setGreeting();

    if (!timeInterval.current) {
      timeInterval.current = setInterval(setGreeting, MINUTE_MILIS);
    }

    return () => {
      if (timeInterval.current) {
        clearInterval(timeInterval.current);
      }
    };
  }, []);

  return timeGreeting;
};
