import {
  Account,
  Membership,
  MembershipFrequency,
  ProductVariant,
} from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import classNames from 'classnames';
import { RequiredProperty } from '@villageco/helpers';
import { useStringDateTimeFormatter } from '@villageco/nebula';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { MembershipRoutePaths } from '../../router/membership-routes';

export type MembershipCardProps = {
  membership: Membership & {
    account: Account;
    membershipFrequency: MembershipFrequency & { productVariant: ProductVariant };
  };
};

export const MembershipCard: FC<NebPropsWithStd<MembershipCardProps>> = ({ membership, className }) => {
  const { account, membershipFrequency: frequency } = membership;

  const { formattedDate: memberSince } = useStringDateTimeFormatter(membership.createdAt!, 'en-AU', {
    month: 'long',
    year: 'numeric',
  });

  return (
    <Link to={{ pathname: `${MembershipRoutePaths.MEMBERSHIP_DETAILS}${membership.id}` }}>
      <div className={classNames(className, 'membership-card bg-th-membership-200 rounded p-4 w-full')}>
        <div className="flex">
          <p className="text-xl font-semibold text-th-membership-700">{account.name}</p>
          <p
            className={classNames(
              {
                'bg-green-300 text-green-700': !membership.endAt,
                'bg-orange-400 text-orange-700': membership.endAt,
              },
              'p-1 rounded font-medium ml-4',
            )}
          >
            {membership.endAt ? 'cancelled' : 'active'}
          </p>
          <p className="p-1 rounded font-medium ml-4 bg-blue-500 text-white">{frequency.productVariant?.name}</p>
        </div>
        <div className="flex">
          <p className="italic font-light text-th-membership-900 mt-1">
            Member since <span className="font-medium">{memberSince}</span>
          </p>
        </div>
      </div>
    </Link>
  );
};
