import classNames from 'classnames';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { FC } from 'react';
import { BsPlus } from 'react-icons/bs';
import { Link, To } from 'react-router-dom';

export type ThAddLinkProps = {
  to: To;
};

export const ThAddLink: FC<NebPropsWithStd<ThAddLinkProps>> = ({ className, to, children }) => {
  return (
    <Link
      to={to}
      className={classNames(
        className,
        'w-32 h-10 th-add-link flex items-center justify-center rounded ml-auto font-semibold',
      )}
    >
      {children ?? (
        <>
          <BsPlus className="font-bold text-xl mr-2"></BsPlus>
          Add
        </>
      )}
    </Link>
  );
};
