import { ThScrollPage } from '@components/ui/th-scroll-page';
import { Product } from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import { useGetManyProducts } from '@core/clients/ecommerce-services-admin/products/products';
import { useSelectedSet } from '@core/hooks/useSelectedSet';
import { setPageMeta } from '@core/stores/core-store';
import { MembershipRoutePaths } from '@membership';
import { FunctionComponent } from 'react';
import { BsPlus } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { ProductCard } from '../../components/products/product-card';
import { ProductCardSkeleton } from '../../components/products/product-card-skeleton';
import { EcommerceRoutePaths } from '../../router/ecommerce-routes';
// import { useProducts } from '../../hooks/useProducts';

export type ProductsIndexPageProps = {};

const productSortByName = (productA: Product, productB: Product) => {
  return !productA?.name && !productB?.name
    ? 0
    : !productA?.name
    ? 1
    : !productB?.name
    ? -1
    : productA?.name < productB?.name
    ? -1
    : productA?.name > productB?.name
    ? 1
    : 0;
};

export const ProductsIndexPage: FunctionComponent<ProductsIndexPageProps> = () => {
  setPageMeta('Products', false);

  const { selectedSet, selectClickHandler } = useSelectedSet();

  const { data, isLoading } = useGetManyProducts();

  return (
    <ThScrollPage id="products-index-page">
      <div className="rounded h-16 bg-th-ecommerce-200 p-2 flex items-center">
        <Link
          to={`${EcommerceRoutePaths.PRODUCT_DETAILS}new`}
          className="rounded flex bg-th-ecommerce-500 text-white font-semibold ml-auto p-2 items-center"
        >
          <BsPlus className="text-xl font-bold"></BsPlus> Add product
        </Link>
      </div>
      <div className="min-h-full w-full rounded bg-th-ecommerce-100 p-2 mt-4 space-y-2 flex flex-col">
        {isLoading && <ProductCardSkeleton />}
        {data?.items?.length === 0 && (
          <div className="flex w-full justify-center mt-4">
            <p className="text-th-ecommerce-600 font-semibold">No products found.</p>
          </div>
        )}
        {data?.items?.sort(productSortByName)?.map((p) => (
          <Link to={`${EcommerceRoutePaths.PRODUCT_DETAILS}${p.id}`} key={p.id}>
            <ProductCard
              product={p as any}
              selected={selectedSet.has(p.id!)}
              onSelectClicked={selectClickHandler(p.id!)}
              className="bg-white rounded p-2"
            ></ProductCard>
          </Link>
        ))}
      </div>
    </ThScrollPage>
  );
};
