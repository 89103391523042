import { FC } from 'react';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { Link, useMatch } from 'react-router-dom';
import { NavbarLink } from '@villageco/nebula/internal';
import { BsStar } from 'react-icons/bs';

export type MembershipNavLinkProps = {};

export const MembershipNavLink: FC<NebPropsWithStd<MembershipNavLinkProps>> = ({ className }) => {
  const isActive = useMatch('/membership/*');

  return (
    <NavbarLink
      isActive={!!isActive}
      linkComponent={Link}
      className={className}
      icon={BsStar}
      to="/membership"
      styles={{
        bgColor: isActive ? 'bg-th-membership-500' : 'bg-th-membership-200',
        textColor: isActive ? 'text-th-membership-200' : 'text-th-membership-700',
      }}
    >
      Membership
    </NavbarLink>
  );
};
