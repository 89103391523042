import { AjvAxiosErrorParserOptions, AjvErrorResponse } from './types';
import Axios, { AxiosError } from 'axios';
import { ValidationErrors } from '../contexts';
import { camelCase } from 'lodash-es';

export const parseInstancePath = (path: string, purge: RegExp | string) =>
  path.replace(purge, '/').replace('/', '.').replace(/^./, '');

export const ajvAxiosErrorParser = (opts?: AjvAxiosErrorParserOptions) => (error: AxiosError<AjvErrorResponse>) => {
  if (!(error instanceof Error)) return error;
  if (!(Axios.isAxiosError(error) && error.response?.status === 422 && error.response.data.errors)) throw error;

  return error.response.data.errors.reduce<ValidationErrors<any>>((ers, e) => {
    let instancePath = e.instancePath;
    let message = e.message ?? JSON.stringify(e);

    if (e.keyword === 'required') {
      const prop = e.params.missingProperty;
      instancePath = `${instancePath}/${prop}`;
      message = `'${prop}' is required`;
    }

    const field = opts?.instancePathMap
      ? opts?.instancePathMap(instancePath)
      : parseInstancePath(instancePath, /\/connectOrCreate\/|\/data\/|\/connect\/|\/create\/|\/update\//g);

    console.log(field);

    if (field) {
      if (Object.prototype.hasOwnProperty.call(ers, field)) {
        ers[field]?.push(message);
      } else {
        ers[field] = [message];
      }
    }

    return ers;
  }, {});
};
