import { FC } from 'react';
import classNames, { Argument } from 'classnames';
import { NebPropsWithStd } from '@villageco/nebula/core';
import { MembershipOfferingItem } from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';

export type IncludedProductCardProps = {
  className?: Argument;
  offeringItem?: MembershipOfferingItem;
};

export const IncludedProductCard: FC<NebPropsWithStd<IncludedProductCardProps>> = ({ className, offeringItem }) => {
  const files = offeringItem?.item?.product?.files ?? offeringItem?.item?.variant?.product?.files;
  const file = files?.items?.length ? files?.items[0] : null;
  return (
    <div className="flex justify-start">
      <div
        className={classNames(
          'w-16 h-16 mr-4 flex items-center justify-center',
          {
            border: !file?.url,
          },
          className,
        )}
      >
        {file?.url && <img className="object-contain" src={file.url} />}
      </div>
      <div className="flex items-center">{offeringItem?.item?.product?.name ?? offeringItem?.item?.variant?.name}</div>
    </div>
  );
};
