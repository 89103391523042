/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * admin-rest-api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  ShipmentRun,
  ValidationErrors,
  GetShipmentRunByIdParams,
  PatchShipmentRunByIdBody,
  DeleteShipmentRunByIdBody,
  PatchRestoreShipmentRunByIdBody,
  DeleteDestroyShipmentRunByIdBody,
  GetOneShipmentRunParams,
  ShipmentRuns,
  GetManyShipmentRunsParams,
  PostShipmentRunBody
} from '../ecommerce-services.schemas'
import { ecommerceAdminApiInstance } from '../../../apis/ecommerce-admin-api-instance'
import type { ErrorType } from '../../../apis/ecommerce-admin-api-instance'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Gets one Shipment Run by Id in path
 */
export const getShipmentRunById = (
    id: string,
    params?: GetShipmentRunByIdParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<ShipmentRun>(
      {url: `/shipment-run/${id}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetShipmentRunByIdQueryKey = (id: string,
    params?: GetShipmentRunByIdParams,) => [`/shipment-run/${id}`, ...(params ? [params]: [])];

    
export type GetShipmentRunByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getShipmentRunById>>>
export type GetShipmentRunByIdQueryError = ErrorType<void | ValidationErrors>

export const useGetShipmentRunById = <TData = Awaited<ReturnType<typeof getShipmentRunById>>, TError = ErrorType<void | ValidationErrors>>(
 id: string,
    params?: GetShipmentRunByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getShipmentRunById>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetShipmentRunByIdQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getShipmentRunById>>> = ({ signal }) => getShipmentRunById(id,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getShipmentRunById>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Patches one Shipment Run by Id in path
 */
export const patchShipmentRunById = (
    id: string,
    patchShipmentRunByIdBody: PatchShipmentRunByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<ShipmentRun>(
      {url: `/shipment-run/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchShipmentRunByIdBody
    },
      options);
    }
  


    export type PatchShipmentRunByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchShipmentRunById>>>
    export type PatchShipmentRunByIdMutationBody = PatchShipmentRunByIdBody
    export type PatchShipmentRunByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchShipmentRunById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchShipmentRunById>>, TError,{id: string;data: PatchShipmentRunByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchShipmentRunById>>, {id: string;data: PatchShipmentRunByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchShipmentRunById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchShipmentRunById>>, TError, {id: string;data: PatchShipmentRunByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Deletes one Shipment Run by Id in path
 */
export const deleteShipmentRunById = (
    id: string,
    deleteShipmentRunByIdBody: DeleteShipmentRunByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<ShipmentRun>(
      {url: `/shipment-run/${id}`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteShipmentRunByIdBody
    },
      options);
    }
  


    export type DeleteShipmentRunByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteShipmentRunById>>>
    export type DeleteShipmentRunByIdMutationBody = DeleteShipmentRunByIdBody
    export type DeleteShipmentRunByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteShipmentRunById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteShipmentRunById>>, TError,{id: string;data: DeleteShipmentRunByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteShipmentRunById>>, {id: string;data: DeleteShipmentRunByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteShipmentRunById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteShipmentRunById>>, TError, {id: string;data: DeleteShipmentRunByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Restores one Shipment Run by Id in path
 */
export const patchRestoreShipmentRunById = (
    id: string,
    patchRestoreShipmentRunByIdBody: PatchRestoreShipmentRunByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<ShipmentRun>(
      {url: `/shipment-run/${id}/restore`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchRestoreShipmentRunByIdBody
    },
      options);
    }
  


    export type PatchRestoreShipmentRunByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchRestoreShipmentRunById>>>
    export type PatchRestoreShipmentRunByIdMutationBody = PatchRestoreShipmentRunByIdBody
    export type PatchRestoreShipmentRunByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchRestoreShipmentRunById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchRestoreShipmentRunById>>, TError,{id: string;data: PatchRestoreShipmentRunByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchRestoreShipmentRunById>>, {id: string;data: PatchRestoreShipmentRunByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchRestoreShipmentRunById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchRestoreShipmentRunById>>, TError, {id: string;data: PatchRestoreShipmentRunByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Destroys one Shipment Run by Id in path
 */
export const deleteDestroyShipmentRunById = (
    id: string,
    deleteDestroyShipmentRunByIdBody: DeleteDestroyShipmentRunByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<ShipmentRun>(
      {url: `/shipment-run/${id}/destroy`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteDestroyShipmentRunByIdBody
    },
      options);
    }
  


    export type DeleteDestroyShipmentRunByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDestroyShipmentRunById>>>
    export type DeleteDestroyShipmentRunByIdMutationBody = DeleteDestroyShipmentRunByIdBody
    export type DeleteDestroyShipmentRunByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteDestroyShipmentRunById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDestroyShipmentRunById>>, TError,{id: string;data: DeleteDestroyShipmentRunByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDestroyShipmentRunById>>, {id: string;data: DeleteDestroyShipmentRunByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteDestroyShipmentRunById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteDestroyShipmentRunById>>, TError, {id: string;data: DeleteDestroyShipmentRunByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Get one Shipment Runs
 */
export const getOneShipmentRun = (
    params?: GetOneShipmentRunParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<ShipmentRun>(
      {url: `/shipment-run`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetOneShipmentRunQueryKey = (params?: GetOneShipmentRunParams,) => [`/shipment-run`, ...(params ? [params]: [])];

    
export type GetOneShipmentRunQueryResult = NonNullable<Awaited<ReturnType<typeof getOneShipmentRun>>>
export type GetOneShipmentRunQueryError = ErrorType<void | ValidationErrors>

export const useGetOneShipmentRun = <TData = Awaited<ReturnType<typeof getOneShipmentRun>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetOneShipmentRunParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOneShipmentRun>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOneShipmentRunQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneShipmentRun>>> = ({ signal }) => getOneShipmentRun(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOneShipmentRun>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Get many Shipment Runs
 */
export const getManyShipmentRuns = (
    params?: GetManyShipmentRunsParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<ShipmentRuns>(
      {url: `/shipment-runs`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetManyShipmentRunsQueryKey = (params?: GetManyShipmentRunsParams,) => [`/shipment-runs`, ...(params ? [params]: [])];

    
export type GetManyShipmentRunsQueryResult = NonNullable<Awaited<ReturnType<typeof getManyShipmentRuns>>>
export type GetManyShipmentRunsQueryError = ErrorType<void | ValidationErrors>

export const useGetManyShipmentRuns = <TData = Awaited<ReturnType<typeof getManyShipmentRuns>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetManyShipmentRunsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getManyShipmentRuns>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetManyShipmentRunsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getManyShipmentRuns>>> = ({ signal }) => getManyShipmentRuns(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getManyShipmentRuns>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Posts a new Shipment Run
 */
export const postShipmentRun = (
    postShipmentRunBody: PostShipmentRunBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<ShipmentRun>(
      {url: `/shipment-runs`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postShipmentRunBody
    },
      options);
    }
  


    export type PostShipmentRunMutationResult = NonNullable<Awaited<ReturnType<typeof postShipmentRun>>>
    export type PostShipmentRunMutationBody = PostShipmentRunBody
    export type PostShipmentRunMutationError = ErrorType<void | ValidationErrors>

    export const usePostShipmentRun = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postShipmentRun>>, TError,{data: PostShipmentRunBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postShipmentRun>>, {data: PostShipmentRunBody}> = (props) => {
          const {data} = props ?? {};

          return  postShipmentRun(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postShipmentRun>>, TError, {data: PostShipmentRunBody}, TContext>(mutationFn, mutationOptions)
    }
    