import classnames, { Argument } from 'classnames';
import { FunctionComponent, ReactElement, ReactNode, useState } from 'react';
import { BiLock, BiLockOpen } from 'react-icons/bi';
import { Navbar } from '../../components/navbar-temp';

export type DashLayoutProps = {
  className?: Argument;
  navbarLogo?: ReactNode;
  navbarChildren?: ReactElement;
  sidebarChildren?: ReactElement;
  sidebarStyles?: {
    bgColor?: Argument;
  };
  pageClassName?: Argument;
};

export const DashLayout: FunctionComponent<DashLayoutProps> = ({
  className,
  navbarLogo,
  navbarChildren,
  sidebarChildren,
  sidebarStyles,
  pageClassName,
  children,
}) => {
  const [isLocked, setIsLocked] = useState(false);

  const sidebarClasses = classnames(
    {
      'bg-gray-300': !sidebarStyles?.bgColor,
    },
    sidebarStyles?.bgColor,
  );

  const lockClicked = () => {
    setIsLocked(!isLocked);
  };

  return (
    <div className={classnames('dash-layout w-screen h-screen flex flex-col', className)}>
      <Navbar className="h-20" logo={navbarLogo}>
        {navbarChildren}
      </Navbar>
      <div className="flex h-[calc(100vh-5rem)]">
        <div className="pl-4 pb-4">
          <div
            className={classnames(
              className,
              {
                'w-16': !isLocked,
                'w-64': isLocked,
              },
              'sidebar h-full relative',
            )}
          >
            <div className="absolute h-full z-20">
              <div
                className={classnames(
                  sidebarClasses,
                  {
                    'w-16': !isLocked,
                    'w-64': isLocked,
                  },
                  'group rounded h-full hover:w-64 transition-width duration-500 overflow-hidden flex',
                )}
              >
                {sidebarChildren}
                <button
                  onClick={lockClicked}
                  className={classnames(
                    {
                      'opacity-0': !isLocked,
                    },
                    'absolute -right-3 top-3 text-lg rounded bg-gray-200 h-6 w-6 z-30 group-hover:opacity-100 group-hover:w-8 group-hover:h-8 group-hover:text-xl transition-all duration-500 flex items-center justify-center',
                  )}
                >
                  {isLocked ? <BiLock className=""></BiLock> : <BiLockOpen className=""></BiLockOpen>}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={classnames(
            {
              'w-[calc(100vw-5rem)]': !isLocked,
              'w-[calc(100vw-17rem)]': isLocked,
            },
            pageClassName,
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
