/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * admin-rest-api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  MembershipOrderItem,
  ValidationErrors,
  GetMembershipOrderItemByIdParams,
  PatchMembershipOrderItemByIdBody,
  DeleteMembershipOrderItemByIdBody,
  PatchRestoreMembershipOrderItemByIdBody,
  DeleteDestroyMembershipOrderItemByIdBody,
  GetOneMembershipOrderItemParams,
  MembershipOrderItems,
  GetManyMembershipOrderItemsParams,
  PostMembershipOrderItemBody
} from '../ecommerce-services.schemas'
import { ecommerceAdminApiInstance } from '../../../apis/ecommerce-admin-api-instance'
import type { ErrorType } from '../../../apis/ecommerce-admin-api-instance'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Gets one Membership Order Item by Id in path
 */
export const getMembershipOrderItemById = (
    id: string,
    params?: GetMembershipOrderItemByIdParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<MembershipOrderItem>(
      {url: `/membership-order-item/${id}`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetMembershipOrderItemByIdQueryKey = (id: string,
    params?: GetMembershipOrderItemByIdParams,) => [`/membership-order-item/${id}`, ...(params ? [params]: [])];

    
export type GetMembershipOrderItemByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getMembershipOrderItemById>>>
export type GetMembershipOrderItemByIdQueryError = ErrorType<void | ValidationErrors>

export const useGetMembershipOrderItemById = <TData = Awaited<ReturnType<typeof getMembershipOrderItemById>>, TError = ErrorType<void | ValidationErrors>>(
 id: string,
    params?: GetMembershipOrderItemByIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMembershipOrderItemById>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMembershipOrderItemByIdQueryKey(id,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMembershipOrderItemById>>> = ({ signal }) => getMembershipOrderItemById(id,params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMembershipOrderItemById>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Patches one Membership Order Item by Id in path
 */
export const patchMembershipOrderItemById = (
    id: string,
    patchMembershipOrderItemByIdBody: PatchMembershipOrderItemByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipOrderItem>(
      {url: `/membership-order-item/${id}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchMembershipOrderItemByIdBody
    },
      options);
    }
  


    export type PatchMembershipOrderItemByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchMembershipOrderItemById>>>
    export type PatchMembershipOrderItemByIdMutationBody = PatchMembershipOrderItemByIdBody
    export type PatchMembershipOrderItemByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchMembershipOrderItemById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchMembershipOrderItemById>>, TError,{id: string;data: PatchMembershipOrderItemByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchMembershipOrderItemById>>, {id: string;data: PatchMembershipOrderItemByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchMembershipOrderItemById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchMembershipOrderItemById>>, TError, {id: string;data: PatchMembershipOrderItemByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Deletes one Membership Order Item by Id in path
 */
export const deleteMembershipOrderItemById = (
    id: string,
    deleteMembershipOrderItemByIdBody: DeleteMembershipOrderItemByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipOrderItem>(
      {url: `/membership-order-item/${id}`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteMembershipOrderItemByIdBody
    },
      options);
    }
  


    export type DeleteMembershipOrderItemByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMembershipOrderItemById>>>
    export type DeleteMembershipOrderItemByIdMutationBody = DeleteMembershipOrderItemByIdBody
    export type DeleteMembershipOrderItemByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteMembershipOrderItemById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMembershipOrderItemById>>, TError,{id: string;data: DeleteMembershipOrderItemByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMembershipOrderItemById>>, {id: string;data: DeleteMembershipOrderItemByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteMembershipOrderItemById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteMembershipOrderItemById>>, TError, {id: string;data: DeleteMembershipOrderItemByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Restores one Membership Order Item by Id in path
 */
export const patchRestoreMembershipOrderItemById = (
    id: string,
    patchRestoreMembershipOrderItemByIdBody: PatchRestoreMembershipOrderItemByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipOrderItem>(
      {url: `/membership-order-item/${id}/restore`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchRestoreMembershipOrderItemByIdBody
    },
      options);
    }
  


    export type PatchRestoreMembershipOrderItemByIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchRestoreMembershipOrderItemById>>>
    export type PatchRestoreMembershipOrderItemByIdMutationBody = PatchRestoreMembershipOrderItemByIdBody
    export type PatchRestoreMembershipOrderItemByIdMutationError = ErrorType<void | ValidationErrors>

    export const usePatchRestoreMembershipOrderItemById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchRestoreMembershipOrderItemById>>, TError,{id: string;data: PatchRestoreMembershipOrderItemByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchRestoreMembershipOrderItemById>>, {id: string;data: PatchRestoreMembershipOrderItemByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  patchRestoreMembershipOrderItemById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof patchRestoreMembershipOrderItemById>>, TError, {id: string;data: PatchRestoreMembershipOrderItemByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Destroys one Membership Order Item by Id in path
 */
export const deleteDestroyMembershipOrderItemById = (
    id: string,
    deleteDestroyMembershipOrderItemByIdBody: DeleteDestroyMembershipOrderItemByIdBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipOrderItem>(
      {url: `/membership-order-item/${id}/destroy`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteDestroyMembershipOrderItemByIdBody
    },
      options);
    }
  


    export type DeleteDestroyMembershipOrderItemByIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDestroyMembershipOrderItemById>>>
    export type DeleteDestroyMembershipOrderItemByIdMutationBody = DeleteDestroyMembershipOrderItemByIdBody
    export type DeleteDestroyMembershipOrderItemByIdMutationError = ErrorType<void | ValidationErrors>

    export const useDeleteDestroyMembershipOrderItemById = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDestroyMembershipOrderItemById>>, TError,{id: string;data: DeleteDestroyMembershipOrderItemByIdBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDestroyMembershipOrderItemById>>, {id: string;data: DeleteDestroyMembershipOrderItemByIdBody}> = (props) => {
          const {id,data} = props ?? {};

          return  deleteDestroyMembershipOrderItemById(id,data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof deleteDestroyMembershipOrderItemById>>, TError, {id: string;data: DeleteDestroyMembershipOrderItemByIdBody}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * @summary Get one Membership Order Items
 */
export const getOneMembershipOrderItem = (
    params?: GetOneMembershipOrderItemParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<MembershipOrderItem>(
      {url: `/membership-order-item`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetOneMembershipOrderItemQueryKey = (params?: GetOneMembershipOrderItemParams,) => [`/membership-order-item`, ...(params ? [params]: [])];

    
export type GetOneMembershipOrderItemQueryResult = NonNullable<Awaited<ReturnType<typeof getOneMembershipOrderItem>>>
export type GetOneMembershipOrderItemQueryError = ErrorType<void | ValidationErrors>

export const useGetOneMembershipOrderItem = <TData = Awaited<ReturnType<typeof getOneMembershipOrderItem>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetOneMembershipOrderItemParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOneMembershipOrderItem>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOneMembershipOrderItemQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOneMembershipOrderItem>>> = ({ signal }) => getOneMembershipOrderItem(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOneMembershipOrderItem>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Get many Membership Order Items
 */
export const getManyMembershipOrderItems = (
    params?: GetManyMembershipOrderItemsParams,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,signal?: AbortSignal
) => {
      return ecommerceAdminApiInstance<MembershipOrderItems>(
      {url: `/membership-order-items`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetManyMembershipOrderItemsQueryKey = (params?: GetManyMembershipOrderItemsParams,) => [`/membership-order-items`, ...(params ? [params]: [])];

    
export type GetManyMembershipOrderItemsQueryResult = NonNullable<Awaited<ReturnType<typeof getManyMembershipOrderItems>>>
export type GetManyMembershipOrderItemsQueryError = ErrorType<void | ValidationErrors>

export const useGetManyMembershipOrderItems = <TData = Awaited<ReturnType<typeof getManyMembershipOrderItems>>, TError = ErrorType<void | ValidationErrors>>(
 params?: GetManyMembershipOrderItemsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getManyMembershipOrderItems>>, TError, TData>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetManyMembershipOrderItemsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getManyMembershipOrderItems>>> = ({ signal }) => getManyMembershipOrderItems(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getManyMembershipOrderItems>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * @summary Posts a new Membership Order Item
 */
export const postMembershipOrderItem = (
    postMembershipOrderItemBody: PostMembershipOrderItemBody,
 options?: SecondParameter<typeof ecommerceAdminApiInstance>,) => {
      return ecommerceAdminApiInstance<MembershipOrderItem>(
      {url: `/membership-order-items`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: postMembershipOrderItemBody
    },
      options);
    }
  


    export type PostMembershipOrderItemMutationResult = NonNullable<Awaited<ReturnType<typeof postMembershipOrderItem>>>
    export type PostMembershipOrderItemMutationBody = PostMembershipOrderItemBody
    export type PostMembershipOrderItemMutationError = ErrorType<void | ValidationErrors>

    export const usePostMembershipOrderItem = <TError = ErrorType<void | ValidationErrors>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postMembershipOrderItem>>, TError,{data: PostMembershipOrderItemBody}, TContext>, request?: SecondParameter<typeof ecommerceAdminApiInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postMembershipOrderItem>>, {data: PostMembershipOrderItemBody}> = (props) => {
          const {data} = props ?? {};

          return  postMembershipOrderItem(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postMembershipOrderItem>>, TError, {data: PostMembershipOrderItemBody}, TContext>(mutationFn, mutationOptions)
    }
    