import { THH2 } from '@components/ui/th-h2';
import { useGetManyAccounts } from '@core/clients/ecommerce-services-admin/accounts/accounts';
import { useGetManyMemberships } from '@core/clients/ecommerce-services-admin/memberships/memberships';
import { useGetOneShipmentRun } from '@core/clients/ecommerce-services-admin/shipment-runs/shipment-runs';
import { setPageMeta } from '@core/stores/core-store';
import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { MembershipRoutePaths } from '../router/membership-routes';

export type MembershipHomePageProps = {};

export const MembershipHomePage: FC<MembershipHomePageProps> = () => {
  setPageMeta('Membership Summary', true);

  const { data: memberships } = useGetManyMemberships({ select: { id: true, endAt: true } });
  const { data: shipmentRun } = useGetOneShipmentRun(
    { select: {}, where: {} },
    { query: { refetchOnWindowFocus: false } },
  );
  const { data: accounts } = useGetManyAccounts({
    select: { id: true },
    where: { OR: [{ zohoCrmAccountRef: null }, { zohoInvContactRef: null }, { stripeCustomerRef: null }] } as any,
  });

  const activeMembershipsCount = useMemo(
    () => memberships?.items?.filter((m) => !m.deletedAt && (!m.endAt || m.endAt > new Date().toISOString())).length,
    [memberships],
  );

  const expiredMembershipsCount = useMemo(
    () => memberships?.items?.filter((m) => !m.deletedAt && m.endAt && m.endAt < new Date().toISOString()).length,
    [memberships],
  );

  const accountsNeedingAttention = useMemo(() => accounts?.items?.length, [accounts]);

  return (
    <div className="membership-home-page w-full h-full p-4">
      <div className="bg-th-membership-50 rounded w-full h-full p-2">
        <div className="flex">
          <Link
            to={`${MembershipRoutePaths.MEMBERSHIPS_INDEX}`}
            className="bg-th-membership-200 rounded p-4 flex flex-col"
          >
            <span className="font-light text-sm text-th-membership-900">Active Memberships</span>
            <span className="text-5xl font-bold text-th-membership-600">{activeMembershipsCount ?? '-'}</span>
          </Link>
          <Link
            to={`${MembershipRoutePaths.MEMBERSHIPS_INDEX}?activeTab=expired`}
            className="bg-th-membership-100 rounded p-4 flex flex-col ml-4"
          >
            <span className="font-light text-sm text-th-membership-800">Expired Memberships</span>
            <span className="text-5xl font-bold text-th-membership-500">{expiredMembershipsCount ?? '-'}</span>
          </Link>
          <Link
            to={`${MembershipRoutePaths.ACCOUNTS_INDEX}?activeTab=expired`}
            className="bg-th-membership-100 rounded p-4 flex flex-col ml-4"
          >
            <span className="font-light text-sm text-th-membership-800">Accounts need attention</span>
            <span className="text-5xl font-bold text-th-membership-500">{accountsNeedingAttention ?? '-'}</span>
          </Link>

          {/* <div className="bg-th-membership-100 rounded p-4 flex flex-col">
            <span className="font-light text-sm text-th-membership-800">Active Memberships</span>
            <span className="text-5xl font-bold text-th-membership-500">5</span>
          </div> */}
        </div>
        {/* <THH2 className="mt-4">Tasks</THH2> */}
      </div>
    </div>
  );
};
