import { usePatchAccountById } from '@core/clients/ecommerce-services-admin/accounts/accounts';
import { Account, AccountUpdate } from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { pick } from 'lodash-es';
import { NebPropsWithStd } from '@villageco/nebula/core';
import {
  Form,
  FormOutput,
  notFoundAuthHandler,
  ResetButton,
  SubmitButton,
  Text,
  Numeric,
  useFormState,
} from '@villageco/nebula/forms';
import { FC, useEffect, useRef, useState } from 'react';
import { membershipFormConfig } from '../../libs/form-config';
import { SelectBox } from '@components/ui/select-box';

export type AccountEditFormProps = {
  account: Account;
  accountsQueryKey: QueryKey;
};

const MINIMUM_PAYMENT_TERM_DAYS = 7;

export const AccountEditForm: FC<NebPropsWithStd<AccountEditFormProps>> = ({ account, accountsQueryKey }) => {
  const queryClient = useQueryClient();
  const [editPaymentTermDays, setEditPaymentTermDays] = useState<boolean>(!!account?.paymentTermDays);
  const hasPaymentTermDays = useRef<boolean>(false);

  const { mutateAsync } = usePatchAccountById({
    mutation: {
      onSuccess: async () => {
        // console.log(accountsQueryKey);

        await queryClient.invalidateQueries(accountsQueryKey);
      },
    },
  });

  useEffect(() => {
    console.log('EditingPaymentDays');
    console.log(editPaymentTermDays);
    hasPaymentTermDays.current = editPaymentTermDays;
  }, [editPaymentTermDays]);

  const formSubmitted = async (data: AccountUpdate) => {
    console.log(hasPaymentTermDays.current);
    console.log(data.paymentTermDays);
    console.log({ ...data, paymentTermDays: hasPaymentTermDays.current ? data.paymentTermDays : null });
    await mutateAsync({
      id: account.id!,
      data: { data: { ...data, paymentTermDays: hasPaymentTermDays.current ? data.paymentTermDays : null } },
    });
  };

  // CREATED ACTIVE DISABLED

  const defaultData = pick(account, [
    'zohoCrmAccountRef',
    'zohoInvContactRef',
    'status',
    'currency',
    'stripeCustomerRef',
    'paymentTermDays',
  ]);

  return (
    <Form
      defaultData={defaultData}
      config={{ ...membershipFormConfig, formErrorHandler: notFoundAuthHandler }}
      onSubmit={formSubmitted}
    >
      <InnerAccountEditForm
        account={account}
        editPaymentTermDays={editPaymentTermDays}
        setEditPaymentTermDays={setEditPaymentTermDays}
      />
    </Form>
  );
};

export type InnerAccountEditFormProps = {
  account: Account;
  editPaymentTermDays: boolean;
  setEditPaymentTermDays: (val: boolean) => void;
};

export const InnerAccountEditForm: FC<NebPropsWithStd<InnerAccountEditFormProps>> = ({
  account,
  editPaymentTermDays,
  setEditPaymentTermDays,
}) => {
  const { setFieldValue, data, setHasChanged } = useFormState();

  // CREATED ACTIVE DISABLED

  const defaultData = pick(account, [
    'zohoCrmAccountRef',
    'zohoInvContactRef',
    'status',
    'currency',
    'stripeCustomerRef',
    'paymentTermDays',
  ]);

  const handleCheckboxChange = (value: boolean) => {
    if (value && !data.paymentTermDays && setFieldValue) {
      setFieldValue('paymentTermDays', MINIMUM_PAYMENT_TERM_DAYS);
    }
    setHasChanged(true);
    setEditPaymentTermDays(!editPaymentTermDays);
  };

  return (
    <>
      <div className="flex">
        <div className="w-1/2 mr-1">
          <Text<typeof defaultData>
            name="zohoCrmAccountRef"
            optional={!account.zohoCrmAccountRef}
            label="Zoho CRM Account ID"
          ></Text>
          <Text<typeof defaultData>
            name="zohoInvContactRef"
            optional={!account.zohoInvContactRef}
            label="Zoho Inventory Contact ID"
          ></Text>
        </div>

        <div className="w-1/2 ml-1">
          <Text<typeof defaultData>
            name="stripeCustomerRef"
            optional={!account.zohoCrmAccountRef}
            label="Stripe Customer ID"
          ></Text>
          {/* <Text<typeof defaultData>
              name="status"
              optional={!account.zohoCrmAccountRef}
              label="Account Status"
            ></Text> */}
          <Text<typeof defaultData> name="currency" optional={!account.zohoCrmAccountRef} label="Currency"></Text>
        </div>
      </div>

      <div className="w-1/2 ml-1 flex items-center">
        <div className="mr-4">
          <p className="-mt-3 text-gray-600">Account Terms Enabled</p>
          <SelectBox
            className="mt-6"
            onClick={() => handleCheckboxChange(!editPaymentTermDays)}
            selected={editPaymentTermDays}
          ></SelectBox>
        </div>

        <Numeric<typeof defaultData>
          disabled={!editPaymentTermDays}
          name="paymentTermDays"
          label="Payment Term Days"
          min={MINIMUM_PAYMENT_TERM_DAYS}
        ></Numeric>
      </div>
      <div className="flex mt-4 justify-end space-x-4">
        <FormOutput className="mr-auto"></FormOutput>
        <SubmitButton></SubmitButton>
        <ResetButton></ResetButton>
      </div>
    </>
  );
};
