import { useState, MouseEvent } from 'react';

export const useSelectedSet = () => {
  const [selectedSet, setSelectedSet] = useState<Set<string>>(new Set());

  const toggleSelect = (key: string) => {
    if (selectedSet.has(key)) {
      selectedSet.delete(key);
      setSelectedSet(new Set(selectedSet));
    } else {
      setSelectedSet(new Set(selectedSet.add(key)));
    }
  };

  const selectClickHandler = (key: string) => (evt: MouseEvent) => {
    evt.preventDefault();
    toggleSelect(key);
  };

  return {
    selectedSet,
    toggleSelect,
    selectClickHandler,
  };
};
