import { ThScrollPage } from '@components/ui/th-scroll-page';
import { setPageMeta } from '@core/stores/core-store';
import { FC } from 'react';

export type PagesIndexPageProps = {};

export const PagesIndexPage: FC<PagesIndexPageProps> = () => {
  setPageMeta('Pages', false);

  return <ThScrollPage id="pages-details-page"></ThScrollPage>;
};
