import { File as CustomFile, FileContentType } from '@core/clients/ecommerce-services-admin/ecommerce-services.schemas';
import classNames, { Argument } from 'classnames';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

import { THImage } from '@components/ui/th-image';
import { THVideo } from '@components/ui/th-video';
import { FaUpload } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

import { Storage } from 'aws-amplify';

const { VITE_S3_BUCKET_NAME } = import.meta.env;

export type FileUploadProps = {
  className?: Argument;
  value?: CustomFile;
  id?: string;
  contentSectionUpload?: boolean;
  onChange?: (file: CustomFile) => void;
};

const getContentTypeFromMimeType = (mimeType: string) => {
  if (mimeType.toLowerCase().includes('video')) {
    return FileContentType.VIDEO;
  } else {
    return FileContentType.IMAGE;
  }
};

const dragFunc = (event: DragEvent) => {
  event.stopPropagation();
  event.preventDefault();
};

const handleFileUpload = async (file: File) => {
  const uuid = uuidv4();
  //Upload file to temp bucket and return uuid
  const uploadResult = await Storage.put(uuid, file);
  const newFileUrl = await Storage.get(uploadResult.key, { level: 'public' });

  return uuid;
};

export const FileUpload: FunctionComponent<FileUploadProps> = ({
  className,
  value,
  id,
  contentSectionUpload = false,
  onChange,
}) => {
  const labelRef = useRef<HTMLLabelElement>(null);
  const dropRef = useRef<HTMLDivElement>(null);
  const [imageKey, setImageKey] = useState<string>('');
  const [dragOver, setDragOver] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('dragenter', dragFunc, false);
    window.addEventListener('dragover', dragFunc, false);
    window.addEventListener('drop', dragFunc, false);

    return () => {
      window.removeEventListener('dragenter', dragFunc, false);
      window.removeEventListener('dragover', dragFunc, false);
      window.removeEventListener('drop', dragFunc, false);
    };
  }, []);

  const handleChange = async (event: React.ChangeEvent) => {
    const eventTarget = event.target as HTMLInputElement;
    const file = eventTarget?.files ? eventTarget?.files[0] : null;

    if (file) {
      const newFileKey = await handleFileUpload(file);
      if (newFileKey) {
        changeFile(file, newFileKey);
      }
    }
  };

  const handleDrop = async (event: React.DragEvent) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.dataTransfer?.files[0];
    const newFileKey = await handleFileUpload(file);
    if (newFileKey) {
      changeFile(file, newFileKey);
    }
    setDragOver(false);
  };

  const handleDragEnter = (event: React.DragEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragExit = (event: React.DragEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setDragOver(false);
  };

  const changeFile = (file: File, key: string) => {
    const newFile: CustomFile = {
      url: `https://${VITE_S3_BUCKET_NAME}.s3.ap-southeast-2.amazonaws.com/public/${key}`,
      contentType: getContentTypeFromMimeType(file?.type),
      extension: file.type,
      oringinalFilename: file.name,
    };
    if (onChange) {
      onChange(newFile);
    }
    setImageKey(new Date().toISOString());
  };

  const handleClick = () => {
    if (labelRef.current) {
      labelRef.current.click();
    }
  };

  const workingFile = value;

  return (
    <div className={classNames('file-upload', className)}>
      <div
        onClick={handleClick}
        className="relative h-full w-full"
        ref={dropRef}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragExit}
        onDrop={handleDrop}
      >
        <div className=" h-full w-full">
          {workingFile ? (
            <>
              {workingFile?.contentType === FileContentType.IMAGE ? (
                <THImage
                  src={workingFile?.url ?? ''}
                  alt={workingFile?.oringinalFilename ?? ''}
                  className="object-contain  h-full w-full"
                />
              ) : (
                workingFile?.contentType === FileContentType.VIDEO &&
                workingFile?.extension && <THVideo src={workingFile?.url ?? ''} extension={workingFile?.extension} />
              )}
            </>
          ) : (
            <div
              className={classNames('flex justify-center items-center top-0 left-0 hover:cursor-pointer group', {
                'opacity-100': dragOver,
                'w-20 h-20': contentSectionUpload,
                'w-12 h-12': !contentSectionUpload,
              })}
            >
              <FaUpload size="40%" className={classNames('text-white block z-10')} />
              <div
                className={classNames('rounded top-0 left-0 absolute bg-black opacity-10 group-hover:opacity-20', {
                  'opacity-20': dragOver,
                  'w-20 h-20': contentSectionUpload,
                  'w-12 h-12': !contentSectionUpload,
                })}
              ></div>
            </div>
          )}
        </div>
        {workingFile && (
          <div
            className={classNames(
              'flex justify-center rounded  items-center top-0 h-full left-0 w-full absolute opacity-0 hover:opacity-100 hover:cursor-pointer group',
              {
                'opacity-100': dragOver,
              },
            )}
          >
            <FaUpload size="40%" className={classNames('text-white block z-10')} />
            <div className={classNames('top-0 left-0 w-full h-full absolute bg-black opacity-20')}></div>
          </div>
        )}
      </div>
      <label ref={labelRef} id={id} htmlFor={`input-${id}`} className="relative"></label>
      <input
        type="file"
        key={imageKey}
        id={`input-${id}`}
        className="hidden"
        onChange={(event) => {
          handleChange(event);
        }}
      />
    </div>
  );
};
