import { FC, PropsWithChildren } from 'react';
import { NebPropsWithStd } from '@villageco/nebula/core';

export type ProductCardSkeletonProps = {};

export const ProductCardSkeleton: FC<PropsWithChildren<ProductCardSkeletonProps>> = () => {
  return (
    <div className="product-card-skeleton bg-slate-100 h-20 rounded p-2">
      <div className="flex animate-pulse">
        <div className="w-16 h-16 flex items-center justify-center">
          <div className="bg-slate-200 h-6 w-6 rounded"></div>
        </div>
        <div className="w-full">
          <div className="w-full h-6 bg-slate-200 rounded"></div>
          <div className="w-1/3  mt-2 h-4 bg-slate-200 rounded"></div>
        </div>
      </div>
    </div>
  );
};
